import { $GQL } from '@GQL/index';
import type { ApplyOfferParams } from '@nextgenleads/banker-driver';

export async function applyOffer(params: ApplyOfferParams) {
	const mut = `
        mutation ApplyOffer($params: JSONObject!) {
            applyOffer(params: $params) {
              txn_id
              current_balance
            }
        }
  `;

	const result = await $GQL.request(mut, { params });
	return result.applyOffer;
}
