<template>
	<div class="build-step send-to-auction">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Send to Auction</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">
			Send the lead to any auction that is configured to handle it or to a specific auction specified in the settings.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Auction Config ID:</label>
					<div class="controls">
						<div class="field">
							<p-input-text
								v-model="modelValue.auction_config_id"
								placeholder="Leave blank to auto-select auction based on lead attributes"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'SendToAuction',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				auction_config_id: '',
			},
		},
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
