import { $GQL } from '@GQL/index';

export async function checkExistingUserEmail(email: string, id?: string): Promise<Boolean> {
	const filters = [[`email = '${email.toLowerCase()}'`]];

	if (id) {
		filters[0].push(`id != '${id}'`);
	}

	const query = `
		query CheckExistingUserEmail($filters: [[Any]]) {
			users(filters: $filters) {
				id
			}
		}
	`;

	const result = await $GQL.request(query, { filters, no_cache: ['users'] });
	return result.users.length > 0;
}
