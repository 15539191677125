import { $GQL } from '@GQL/index';
import type { BlockedNumber } from '@nextgenleads/call-driver';

export async function insertBlockedNumber(data: Omit<BlockedNumber, 'created_at'>): Promise<{ id: string }> {
	const mutation = `
        mutation InsertBlockedNumber($data: JSONObject!) {
            insertBlockedNumber(data: $data) {
                phone
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertBlockedNumber;
}
