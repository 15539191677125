<template>
	<div id="networks-list" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.networksid ? 'Edit' : 'Create' }} Network</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<NetworkForm />
	</div>
</template>

<script>
import NetworkForm from './Form.vue';
export default {
	name: 'NetworkFormPage',
	components: {
		NetworkForm,
	},
	data() {
		return {
			breadcrumb_items: [
				{ label: 'Networks', route: '/networks' },
				{
					label: this.$route.params.network_id ? 'Edit Network' : 'Create Network',
				},
			],
		};
	},
};
</script>
