<template>
	<div class="shipper-settings">
		<div class="control-group">
			<div class="inner">
				<label class="control-label">URL:</label>
				<div class="controls">
					<div class="field xs">
						<p-dropdown
							v-model="shipperSettings.method"
							:options="method_options"
							option-label="label"
							option-value="value"
						/>
					</div>
					<gutter size="10px" />
					<div class="field">
						<p-input-text v-model="shipperSettings.url" />
					</div>
				</div>
			</div>
		</div>

		<div class="control-group">
			<div class="inner">
				<label class="control-label">Request Headers:</label>
				<div class="controls">
					<div class="field">
						<code-editor v-model.parse="shipperSettings.headers" />
						<div class="sub-value">Authorization/Headers will need to be filled here if required</div>
					</div>
				</div>
			</div>
		</div>

		<row>
			<column>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Request Format:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="shipperSettings.format"
									:options="request_options"
									option-label="label"
									option-value="value"
								/>
							</div>
						</div>
					</div>
				</div>
			</column>
			<gutter size="20px" />
			<column>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Response Format:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="shipperSettings.response_type"
									:options="response_options"
									option-label="label"
									option-value="value"
								/>
							</div>
						</div>
					</div>
				</div>
			</column>
		</row>
	</div>
</template>

<script>
export default {
	name: 'ShipperSettingsHttp',
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					method: 'POST',
					url: '',
					headers: {},
					format: 'json',
					response_type: 'json',
				};
			},
		},
	},
	data() {
		return {
			method_options: [
				{ label: 'GET', value: 'GET' },
				{ label: 'POST', value: 'POST' },
			],
			request_options: [
				{ label: 'JSON', value: 'json' },
				{ label: 'XML', value: 'xml' },
				{ label: 'Query String', value: 'querystring' },
				{ label: 'URL Encoded Form', value: 'url-encoded-form' },
				{ label: 'MultiPart Form', value: 'multipart-form' },
				{ label: 'Text', value: 'text' },
			],
			response_options: [
				{ label: 'JSON', value: 'json' },
				{ label: 'Text', value: 'text' },
				{ label: 'Steam', value: 'stream' },
			],
		};
	},
	computed: {
		shipperSettings: {
			get() {
				return this.modelValue;
			},
			set(new_value) {
				this.$emit('update:modelValue', new_value);
			},
		},
	},
};
</script>

<style lang="less"></style>
