<template>
	<p-dialog modal v-model:visible="show_modal" :closable="false" :close-on-esc="false" style="max-width: 600px">
		<template #header>
			<div class="flex align-items-center" style="gap: 10px">
				<icon type="alert" size="24px" /> <strong>Pending Campaigns</strong>
			</div>
		</template>
		<p>
			You had campaigns that were active when you ran out of funds. We will turn those campaigns back on unless you
			choose keep keep them paused.
		</p>
		<template #footer>
			<div class="w-full flex align-items-center justify-content-between">
				<p-button label="Keep Campaigns Paused" text @click="emitSelection('paused')" />
				<p-button icon="pi pi-check" label="Turn Campaigns Back On" @click="emitSelection('active')" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import { updateCampaign } from '@GQL';
import { asyncForEach } from 'modern-async';

export default {
	name: 'PendingCampaignsModal',
	emits: ['select'],
	data() {
		return {
			show_modal: false,
			campaign_ids: [],
		};
	},
	methods: {
		open() {
			this.show_modal = true;
		},
		emitSelection(value) {
			this.show_modal = false;
			this.$emit('select', value);
		},
	},
};
</script>
