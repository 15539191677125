<template>
	<div id="data-leads-report" class="view-content">
		<div class="page-heading">
			<h1>Calls</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<List />
	</div>
</template>

<script lang="ts">
import List from './List.vue';
export default {
	name: 'DataLeads',
	components: {
		List,
	},
	data() {
		return {
			breadcrumb_items: [],
		};
	},
	mounted() {
		this.breadcrumb_items.push(
			{
				label: 'Reports',
				route: '/reports/leads',
			},
			{
				label: 'Calls',
				route: '/reports/call-leads',
			}
		);
	},
};
</script>
