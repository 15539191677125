import { $GQL } from '@GQL';

export async function deleteAd(id: string) {
	const mutation = `
        mutation DeleteAd($id: ID!,) {
			deleteAd(id: $id) {
				id
				name
			}
        }
    `;

	const result = await $GQL.request(mutation, { id });
	return result.deleteAd;
}
