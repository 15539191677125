import { $GQL } from '@GQL/index';

export async function updateSetting(
	id: string,
	data: any,
	mpid?: string | null,
	replace?: boolean
): Promise<UpdateSettingRes> {
	const request = `
        mutation UpdateSetting($id: ID!, $data: Any!, $mpid: String, $replace: Boolean) {
			updateSetting(id: $id, data: $data, mpid: $mpid, replace: $replace) {
				id
				mpid
				data
			}
        }
    `;

	const result = await $GQL.request(request, { id, data, mpid, replace });
	return result.updateSetting;
}
