<template>
	<div class="modifier multiplier">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Multiplier</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">
			Multiply the campaign bid by an amount specified in the <code>bid_multiplier</code> flag of the campaign.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'MultiplierModifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
