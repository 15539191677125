import { $GQL } from '@GQL/index';
import { type Role } from '@nextgenleads/auth-driver';

export async function getRoleById(id: string, mpid: string): Promise<Role> {
	const query = `
		query GetRoleById($id: ID!, $mpid: String!) {
			role(id: $id, mpid: $mpid) {
				id
				name
				mpid
				granted
				revoked
				type
				status
			}
		}
	`;

	const result = await $GQL.request(query, { id, mpid });
	return result.role;
}
