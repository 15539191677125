<template>
	<div id="ads-list" class="view-content">
		<div class="page-heading">
			<h1>Premium Listing Templates</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<row>
				<p-button label="New Premium Listing Template" @click="$router.push({ path: `${$route.path}/new` })">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="plus" size="24px" />
						</div>
					</template>
				</p-button>
			</row>
			<gutter size="20px" />
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table ref="dt" scrollable :value="visibleAdTemplates" :loading="loading">
							<template #empty>
								<div class="dim">No premium listing templates found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="name" header="Ad Template Name">
								<template #body="row">
									<template v-if="row.data.mpid === mpid">
										<router-link :to="`${$route.path}/${row.data.id}`">{{ row.data.name }}</router-link>
									</template>
									<template v-else>
										<router-link :to="`/ad-templates/${row.data.id}`">{{ row.data.name }}*</router-link>
									</template>
								</template>
							</p-column>
							<p-column field="vertical_id" header="Vertical" class="column-align-center">
								<template #body="row">
									<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
								</template>
							</p-column>
							<p-column field="status" header="Status" class="column-align-center">
								<template #body="row">
									{{ capitalize(row.data.status) }}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-if="row.data.mpid === mpid"
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
											<p-button
												v-else
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="$router.push({ path: `/ad-templates/${row.data.id}` })"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button
												v-tooltip.top="'Duplicate'"
												aria-label="Duplicate"
												@click="duplicateAdTemplate(row.data.id)"
											>
												<template #icon>
													<icon type="content-copy" size="20px" />
												</template>
											</p-button>
										</div>
										<delete-action
											message="Are you sure you want to delete this ad?"
											@delete="deleteAdTemplate(row.data)"
										/>
									</div>
								</template>
							</p-column>
						</p-data-table>
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script>
import { remove } from 'lodash-es';
import { deleteAdTemplate, getAdTemplates } from '@GQL';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { capitalize } from '@/lib/Filters';

export default {
	name: 'AdTemplatesListView',
	components: {
		verticalIcon,
	},
	data() {
		return {
			ad_templates: [],
			breadcrumb_items: [],
			loading: false,
			show_archived: false,
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		visibleAdTemplates() {
			if (this.show_archived) {
				return this.ad_templates;
			}
			return this.ad_templates.filter((ad_template) => {
				return ad_template.status !== 'archived';
			});
		},
		...mapStores(useMarketplaceStore),
	},
	methods: {
		capitalize,
		async getAdTemplates(refresh = false) {
			this.loading = true;
			const no_cache = [];
			if (refresh) {
				no_cache.push('adTemplates');
			}

			if (this.mpid === '$NG') {
				this.ad_templates = await getAdTemplates(`mpid = '$NG'`, no_cache);
			} else {
				this.ad_templates = await getAdTemplates(`mpid IN ('$NG', '${this.mpid}')`, no_cache);
			}
			this.loading = false;
		},
		duplicateAdTemplate(ad_template_id) {
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					id: ad_template_id,
				},
			});
		},
		async deleteAdTemplate(ad_template) {
			const delete_result = await deleteAdTemplate(ad_template.id);
			if (delete_result.id) {
				this.$toast.add({
					severity: 'success',
					summary: `The PL template "${ad_template.name}" was deleted.`,
					life: 3000,
				});
				remove(this.ad_templates, (ad) => {
					return ad_template.id === ad.id;
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: `Unable to archive the PL template "${delete_result.name}".`,
					life: 3000,
				});
			}
		},
	},
	async mounted() {
		await this.getAdTemplates();
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Premium Listing Templates',
				},
			];
		} else {
			this.breadcrumb_items = [
				{
					label: 'Premium Listing Templates',
				},
			];
		}
	},
};
</script>

<style lang="less"></style>
