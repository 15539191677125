import { $GQL } from '@GQL';

export async function resetPassword(token: string, password: string, confirm_password: string) {
	const mutation = `
        mutation ResetPassword($token: String!, $password: String!, $confirm_password: String!) {
            resetPassword(token: $token, password: $password, confirm_password: $confirm_password) {
                email
            }
        }
  `;

	const result = await $GQL.request(mutation, { token, password, confirm_password });

	return result.resetPassword;
}
