<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Last {{ submitted_jobs_count }} Submitted Jobs</h1>
		</div>
		<gutter size="20px" />

		<div class="card padded data-request">
			<p-data-table ref="dt" :loading="loading" :rows="10" scrollable :value="statuses">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column field="email" header="Requestor Email" sortable sortField="email"> </p-column>
				<p-column field="account_id" header="Account ID" sortable sortField="account_id"> </p-column>
				<p-column field="report_name" header="Report" sortable sortField="report_name"> </p-column>
				<p-column class="align-center" field="date_requested" header="Date Submitted">
					<template #body="slotProps">
						{{ formatDate(slotProps.data.date_requested) }}
					</template>
				</p-column>
				<p-column class="align-center" field="start_date" header="Start Date Requested">
					<template #body="slotProps">
						{{ formatDate(slotProps.data.start_date) }}
					</template>
				</p-column>
				<p-column class="align-center" field="end_date" header="End Date Requested">
					<template #body="slotProps">
						{{ formatDate(slotProps.data.end_date) }}
					</template>
				</p-column>
				<p-column field="status" header="Current Status">
					<template #body="slotProps">
						<span :class="statusClass(slotProps.data.status)">
							{{ slotProps.data.status }}
						</span>
					</template>
				</p-column>
				<p-column field="last_updated" header="Last Updated">
					<template #body="slotProps">
						{{ formatDate(slotProps.data.last_updated) }}
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script lang="ts">
/**
 * TODO:UI
 * all option bases filters should be multi select drop downs in the query selector
 *  -- network_id can be done later
 */
import { fetchReportStatuses } from '@/lib/GQL/queries/fetchReportStatuses';
import pButton from 'primevue/button';

export default {
	name: 'DataRequestStatusList',
	components: {
		pButton,
	},
	data() {
		return {
			loading: false,
			statuses: [],
			submitted_jobs_count: 10,
		};
	},
	methods: {
		formatDate(timestamp: number): string {
			const date = new Date(timestamp);
			return date.toLocaleString(); // Formats the date according to the user's locale and timezone
		},
		statusClass(status: string): string {
			switch (status.toLowerCase()) {
				case 'submitted':
				case 'pending':
					return 'status-pending';
				case 'complete':
				case 'completed':
					return 'status-completed';
				case 'processing':
				case 'running':
					return 'status-running';
				case 'error':
					return 'status-error';
				default:
					return '';
			}
		},
		async getList() {
			const res = await fetchReportStatuses(this.submitted_jobs_count);

			const body = res.fetchReportStatuses;
			const { success, statuses, message } = body;

			if (!success) {
				this.$toast.add({ severity: 'error', summary: message });
				return;
			}

			this.statuses = statuses;
		},
	},
	async mounted() {
		await this.getList();
	},
};
</script>

<style scoped>
.status-pending {
	background-color: #f59e0b; /* Yellow */
	color: #ffffff; /* White text */
	border-radius: 0.375rem; /* Rounded corners */
	padding: 0.25rem 0.5rem; /* Padding */
}

.status-completed {
	background-color: #10b981; /* Green */
	color: #ffffff; /* White text */
	border-radius: 0.375rem; /* Rounded corners */
	padding: 0.25rem 0.5rem; /* Padding */
}

.status-running {
	background-color: #3b82f6; /* Blue */
	color: #ffffff; /* White text */
	border-radius: 0.375rem; /* Rounded corners */
	padding: 0.25rem 0.5rem; /* Padding */
}

.status-error {
	background-color: #ef4444; /* Red */
	color: #ffffff; /* White text */
	border-radius: 0.375rem; /* Rounded corners */
	padding: 0.25rem 0.5rem; /* Padding */
}
</style>
