<template>
	<div class="content-attribute-filter">
		<template v-if="availableAttributeOptions.length === 0">
			<p-message :closable="false"
				>There are no attribute options available for the vertical and product type of this campaign.</p-message
			>
		</template>
		<template v-else>
			<p>
				Set your campaign to filter out or adjust bidding on various lead attributes. If a lead matches multiple groups,
				the bid adjustments will stack. A lead
				<strong class="text-note">must match all conditions in a group</strong> for that group's bid to apply. To match
				attributes separately, create multiple groups.
			</p>
			<div class="custom-bids">
				<template v-for="custom_bid in sortedCustomBids" :key="custom_bid.id">
					<custom-bid
						:custom-bid="custom_bid"
						:mode="campaignStore.campaign.mode"
						type="source"
						filter-option
						@edit="editCustomBid"
						@delete="deleteCustomBid"
					>
						<strong>{{ custom_bid.name }}</strong>
						<div class="sub-value">{{ custom_bid.match.length }} attributes targeted</div>
					</custom-bid>
				</template>
			</div>
			<template v-if="sortedCustomBids.length === 0">
				<p-message :closable="false">Add filters to target your campaign more precisely.</p-message>
			</template>
			<div class="actions justify-content-between flex gap-2 flex-wrap">
				<p-button icon="pi pi-plus" label="Add Attribute Group" @click="addNewCustomBid" />
			</div>
		</template>
	</div>

	<!-- START MODAL -->
	<p-dialog id="filtering-modal" v-model:visible="show_bid_modal" style="width: 600px" :modal="true">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="target-account" size="24px" style="color: var(--color-b)" />
				<strong>Create Attribute Filtering Group</strong>
			</div>
		</template>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Attribute Group Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text
							id="filtering-group"
							ref="custom_bid_name_input"
							v-model="attribute_bid.name"
							aria-describedby="filtering-group-help"
							autofocus
							placeholder="Enter a group name: I.E. High Income Leads"
						/>
						<div v-if="v$.attribute_bid.name.$error" class="validation-error">
							{{ v$.attribute_bid.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="filters">
			<template v-for="attribute_component in availableAttributeOptions" :key="attribute_component">
				<component
					:ref="(el) => (attribute_refs[attribute_component] = el)"
					:is="attribute_component"
					:mode="campaignStore.campaign.mode"
				/>
			</template>
		</div>
		<template #footer>
			<p-button label="Cancel" text @click="show_bid_modal = false" />
			<p-button label="Save Attribute Group" icon="pi pi-check" @click="saveBid" />
		</template>
	</p-dialog>
	<!-- END MODAL -->
</template>

<script lang="ts">
import { cloneDeep, get, merge, sortBy } from 'lodash-es';
import { nextTick } from 'vue';
import customBid from '@/components/widgets/CustomBid.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import pDialog from 'primevue/dialog';
import pSlider from 'primevue/slider';
import { useCampaignStore } from '@/stores/campaign';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import affordabilityAttribute from '../Attributes/Affordability.vue';
import ageAttribute from '../Attributes/Age.vue';
import hasMedicalConditionAttribute from '../Attributes/HasMedicalCondition.vue';
import householdIncomeAttribute from '../Attributes/HouseholdIncome.vue';
import householdSizeAttribute from '../Attributes/HouseholdSize.vue';
import interestInMedicareAttribute from '../Attributes/InterestInMedicare.vue';
import medicalConditionsAttribute from '../Attributes/MedicalConditions.vue';
import medicareAgeAttribute from '../Attributes/MedicareAge.vue';
import medicareMinimumAgeAttribute from '../Attributes/MedicareMinimumAge.vue';
import medicareEnrollmentAttribute from '../Attributes/MedicareEnrollment.vue';
import pregnantAttribute from '../Attributes/Pregnant.vue';
import spokenLanguageAttribute from '../Attributes/SpokenLanguage.vue';
import tobaccoAttribute from '../Attributes/Tobacco.vue';

const default_attribute_bid = {
	id: null,
	name: '',
	type: 'attributes',
	match: [],
	method: 'multiply',
	amount: 0,
	custom_minimum_bid: false,
	minimum_bid: 0,
	finalize: false,
	status: 'active',
	dq_message: 'Lead excluded from targeting by attribute filters',
};

const attribute_options = {
	health_insurance: {
		data: [
			'ageAttribute',
			'householdSizeAttribute',
			'householdIncomeAttribute',
			'medicalConditionsAttribute',
			'pregnantAttribute',
		],
		call: [],
		live_transfer: [
			'spokenLanguageAttribute',
			'ageAttribute',
			'hasMedicalConditionAttribute',
			'affordabilityAttribute',
			'interestInMedicareAttribute',
		],
		ad: [],
	},
	medicare: {
		data: ['medicareAgeAttribute', 'medicareEnrollmentAttribute'],
		call: [],
		live_transfer: ['spokenLanguageAttribute', 'medicareMinimumAgeAttribute', 'medicareEnrollmentAttribute'],
		ad: [],
	},
};

export default {
	name: 'AttributeFilteringContent',
	components: {
		customBid,
		deleteAction,
		pDialog,
		pSlider,
		affordabilityAttribute,
		ageAttribute,
		hasMedicalConditionAttribute,
		householdIncomeAttribute,
		householdSizeAttribute,
		interestInMedicareAttribute,
		medicalConditionsAttribute,
		medicareAgeAttribute,
		medicareEnrollmentAttribute,
		medicareMinimumAgeAttribute,
		pregnantAttribute,
		spokenLanguageAttribute,
		tobaccoAttribute,
	},
	setup() {
		return {
			campaignStore: useCampaignStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			show_bid_modal: false,
			show_paused: false,
			attribute_bid: cloneDeep(default_attribute_bid),
			attribute_options,
			attribute_refs: {},
			custom_bid_filter_count: 0,
		};
	},
	validations() {
		return {
			attribute_bid: {
				name: {
					required: helpers.withMessage('A name for this attribute group is required', required),
					$lazy: true,
				},
			},
		};
	},
	computed: {
		availableAttributeOptions() {
			return get(
				this.attribute_options,
				`${this.campaignStore.campaign.vertical_id_targeting}.${this.campaignStore.campaign.product_targeting}`,
				''
			);
		},
		sortedCustomBids() {
			return sortBy(
				this.campaignStore.campaign.bids.filter((custom_bid) => custom_bid.type === 'attributes'),
				(custom_bid) => {
					return custom_bid.name;
				}
			);
		},
	},
	methods: {
		resetModalState() {
			this.v$.$reset();
			this.attribute_bid = cloneDeep(default_attribute_bid);
			this.custom_bid_filter_count = 0;
		},
		addNewCustomBid() {
			this.resetModalState();
			this.show_bid_modal = true;

			nextTick(() => {
				this.$refs.custom_bid_name_input.$el.focus();
			});
		},
		editCustomBid(bid_id: string) {
			this.resetModalState();
			this.show_bid_modal = true; // This has to happen first so the stuff below works

			const custom_bid = this.campaignStore.campaign.bids.find((custom_bid) => custom_bid.id === bid_id);
			if (custom_bid) {
				this.attribute_bid = merge({}, default_attribute_bid, custom_bid);
				this.attribute_bid = custom_bid;

				nextTick(() => {
					this.availableAttributeOptions.forEach((attributeOption) => {
						this.attribute_refs[attributeOption].loadAttribute(custom_bid);
					});
				});

				this.custom_bid_filter_count = custom_bid.match.length || 0;
			}

			nextTick(() => {
				this.$refs.custom_bid_name_input.$el.select();
			});
		},
		deleteCustomBid(bid_id: string) {
			return this.campaignStore.deleteBid(bid_id);
		},
		async saveBid() {
			const is_valid = await this.v$.$validate();

			if (is_valid) {
				const match = [];

				this.availableAttributeOptions.forEach((attributeOption) => {
					const value = this.attribute_refs[attributeOption].saveAttribute();
					if (value) {
						match.push(value);
					}
				});

				const custom_bid = cloneDeep(this.attribute_bid);
				custom_bid.match = match;

				if (custom_bid.id) {
					this.campaignStore.saveBid(custom_bid);
				} else {
					this.campaignStore.addBid(custom_bid);
				}

				this.show_bid_modal = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

:deep(.attribute-section) {
	background: var(--gray-05);
	border: 1px solid var(--gray-20);
	border-radius: 3px;
	margin-bottom: 1rem;
	max-width: 600px;

	&:last-of-type {
		margin-bottom: 0;
	}

	.field.slider {
		padding: 0 2em;
	}

	.slider-value {
		width: 40px;
	}

	.large-label {
		font-size: 1rem;
		line-height: 1.15em;

		.sub-value {
			color: var(--gray-50);
			font-weight: normal;
		}
	}

	.header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 1rem;

		.title {
			font-weight: bold;

			.clickable-label {
				cursor: pointer;
				font-size: var(--font-size-base);
				margin-bottom: 0;
			}
		}
	}

	&.active {
		background: var(--white);

		.header {
			border-bottom: 1px solid var(--gray-15);
		}
	}

	&.filter,
	.filter {
		padding: 1rem;
	}
}

p {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

#filtering-modal.p-dialog {
	.p-dialog-content {
		.modal-map {
			margin: 1rem auto;
		}

		.options {
			columns: 3;
			list-style: none;
			margin: 1rem auto;
			padding: 0;

			li {
				font-size: var(--font-size-sm);
			}

			.mobile({
				columns: 2;
			});
		}

		.state-options {
			font-size: var(--font-size-sm);
		}
	}

	&.editing {
		button.p-dialog-header-icon.p-dialog-header-close.p-link {
			display: none !important;
		}
	}
}
</style>
