import { $GQL } from '@GQL';
import type { EmailTemplate } from '@nextgenleads/email-driver';

export async function updateEmailTemplate(id: string, data: Partial<Omit<EmailTemplate>>): Promise<{ id: string }> {
	const mutation = `
		mutation UpdateEmailTemplate($id: ID!, $data: JSONObject!) {
			updateEmailTemplate(id: $id, data: $data) {
				id
			}
		}
	`;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateEmailTemplate;
}
