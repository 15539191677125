<template>
	<div id="notifications-summary">
		<p-card>
			<template #title>
				<div class="flex flex-column pb-2">
					<h3>Notifications</h3>
				</div>
			</template>
			<template #content>
				<p-progress-spinner v-if="loading" style="display: block; margin: 0 auto" />
				<div class="notifications-wrapper">
					<div
						v-for="(receipt, index) in receipts"
						:key="index"
						class="notifcation-container"
						:class="{ read: receipt.action === 'Read' }"
					>
						<div class="details">
							<div class="sender">
								{{ receipt.notification.created_by_user.first_name }}
								{{ receipt.notification.created_by_user.last_name }}
							</div>
							<div class="message">
								{{ receipt.notification.message }}
							</div>
							<div class="date">
								{{ formatDate(new Date(receipt.created_at)) }}
							</div>
						</div>
						<div class="tools">
							<p-button
								v-if="receipt.action !== 'Read'"
								v-tooltip.top="'Mark as Read'"
								icon="pi pi-check"
								text
								@click="markRead(receipt.id)"
							/>
						</div>
					</div>
					<p v-if="receipts.length === 0">No notifications</p>
					<p-paginator
						v-if="receipts.length > pagination.page_size"
						v-model:first="pagination.page"
						:total-records="row_count"
						:rows="pagination.page_size"
						@page="handlePageChange"
					></p-paginator>
				</div>
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import pPaginator from 'primevue/paginator';
import pProgressSpinner from 'primevue/progressspinner';
import { format, formatDistanceToNow, intervalToDuration } from 'date-fns';
import { useSessionStore } from '@/stores/session';
import { getNotificationReceiptList } from '@GQL';
import { GetListParams } from '@nextgenleads/db';

export default {
	name: 'NotificationsSummary',
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	components: {
		pPaginator,
		pProgressSpinner,
	},
	data() {
		return {
			receipts: [],
			row_count: null,
			page: 0,
			pagination: {
				page_size: 5,
				page: 0,
			},
			loading: false,
		};
	},
	async created() {
		await this.getNotificationReceipts();
	},
	methods: {
		async getNotificationReceipts() {
			this.loading = true;
			const notification_params: GetListParams = {
				filters: [
					[
						{
							field: 'user_id',
							op: 'eq',
							value: this.$route.params.parent_account_id,
						},
					],
				],
				pagination: this.pagination,
			};

			try {
				const result = await getNotificationReceiptList(notification_params);
				this.row_count = result.row_count;
				this.receipts = result.rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get notifications',
					detail: err.response.errors[0]?.message || err.message,
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		handlePageChange(data) {
			this.pagination.page = data.page;
			return this.getNotificationReceipts();
		},
		formatDate(date: Date) {
			const currentDate = new Date();
			const difference = intervalToDuration({ start: date, end: currentDate });

			if (difference.days >= 1) {
				return format(date, 'EEE, PPPp');
			} else {
				return `${formatDistanceToNow(date)} ago`;
			}
		},
	},
};
</script>
