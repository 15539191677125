<template>
	<div id="roles-table">
		<p-data-table ref="dt" :key="tableKey" paginator :rows="10" scrollable :value="rolesList">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>
			<p-column header="Name" field="name" sortable sortField="name">
				<template #body="role">
					<router-link :to="{ path: `${$route.path}/${role.data.id}` }"
						><strong>{{ role.data.name }}</strong></router-link
					>
					<br />
					<div class="sub-value">{{ role.data.id }}</div>
				</template>
			</p-column>
			<p-column header="Marketplace ID" class="align-center" sortable sortField="mpid">
				<template #body="role">
					<template v-if="role.data.mpid === '$NG'">Global</template>
					<template v-else>{{ role.data.mpid }}</template>
				</template>
			</p-column>
			<p-column header="Type" class="align-center" field="type" sortable sortField="type" />
			<p-column header="Status" class="align-center" field="status" sortable sortField="status" />
			<p-column header="Tools" class="align-center">
				<template #body="row">
					<div class="toolset">
						<div class="tool">
							<p-button
								v-tooltip.top="'Edit'"
								aria-label="Edit"
								@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
							>
								<template #icon>
									<icon type="square-edit-outline" size="20px" />
								</template>
							</p-button>
						</div>
						<div class="tool">
							<p-button v-tooltip.top="'Duplicate Role'" aria-label="Duplicate" @click="duplicateRole(row.data.id)">
								<template #icon>
									<icon type="content-copy" size="20px" />
								</template>
							</p-button>
						</div>
						<div class="tool">
							<p-button
								v-if="row.data.status === 'active'"
								v-tooltip.top="'Suspend Role'"
								aria-label="Edit"
								@click="suspendRole(row.data.id)"
							>
								<template #icon>
									<icon type="account-off" size="20px" />
								</template>
							</p-button>
							<p-button v-else v-tooltip.top="'Unsuspend Role'" aria-label="Edit" @click="unSuspendRole(row.data.id)">
								<template #icon>
									<icon type="account-star" size="20px" />
								</template>
							</p-button>
						</div>
						<delete-action message="Are you sure you want to delete this role?" @delete="deleteRole(row.data.id)" />
					</div>
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>

<script lang="ts">
import log from '@/lib/Log';
import { getRoles, updateRole, deleteRole } from '@GQL';
import { active_status_options } from '@/lib/Options';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import { sortBy } from 'lodash-es';

export default {
	name: 'RolesList',
	inject: {
		nested_mpid: {
			for: 'nested_mpid',
			default: '$NG',
		},
	},
	components: {
		deleteAction,
	},
	data() {
		return {
			loading: false,
			tableKey: 0,
			query: {
				filters: {},
			},
			active_status_options,
			mpid_options: [
				{
					label: 'NGL',
					value: 'NGL',
				},
			], // TODO - TEMPORARY...
			type_options: [
				{
					label: '',
					value: 'buyer',
				},
				{
					label: '',
					value: 'admin',
				},
				{
					label: '',
					value: 'app',
				},
			],
			roles: [],
		};
	},
	computed: {
		rolesList() {
			return sortBy(this.roles, 'name');
		},
	},
	async mounted() {
		await this.getList(true);
	},
	methods: {
		async getList(no_cache = false) {
			let filters = [[`mpid = '${this.nested_mpid || '$NG'}'`]];

			let options = [];
			if (no_cache) {
				options = ['roles'];
			}

			this.loading = true;
			try {
				// turn the query filters into QueryFilters
				const result = await getRoles(filters, options);
				this.roles = result;
			} catch (e) {
				log.trace('Roles getList', e);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get roles',
				});
			} finally {
				this.loading = false;
			}
		},
		duplicateRole(role_id) {
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					duplicate: role_id,
				},
			});
		},
		async suspendRole(id) {
			try {
				const result = await updateRole(id, this.nested_mpid, {
					status: 'disabled',
				});
				if (result.id) {
					this.$toast.add({
						severity: 'success',
						summary: 'The role was suspended',
						life: 3000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to suspend the role',
					detail: err.response.error.message,
					life: 3000,
				});
			}
			await this.getList(true);
		},
		async unSuspendRole(id: string) {
			try {
				const result = await updateRole(id, this.nested_mpid, {
					status: 'active',
				});
				if (result.id) {
					this.$toast.add({
						severity: 'success',
						summary: 'The role was activated',
						life: 3000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to activate the role',
					detail: err.response.error.message,
					life: 3000,
				});
			}
			await this.getList(true);
		},
		async deleteRole(id: string) {
			try {
				const result = await deleteRole(id, this.nested_mpid);
				if (result.role) {
					this.$toast.add({
						severity: 'success',
						summary: 'The role was deleted',
						life: 3000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to delete the role',
					detail: err.response.error.message,
					life: 3000,
				});
			}
			await this.getList(true);
		},
	},
};
</script>
@GQL/queries/getRoles
