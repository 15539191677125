import { $GQL } from '@GQL';
import { type SendPostReq, SendPostRes } from '@nextgenleads/pingpost-driver';

export async function sendPost(params: SendPostReq): Promise<SendPostRes> {
	const mutation = `
        mutation sendPost($params: SendPostReq!) {
            sendPost(params: $params)
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.sendPost;
}
