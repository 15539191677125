import { $GQL } from '@GQL';

export async function deleteCaptureConfig(id: string) {
	const mutation = `
        mutation DeleteCaptureConfig($id: ID!,) {
			deleteCaptureConfig(id: $id) {
				id
			}
        }
    `;

	const result = await $GQL.request(mutation, { id });
	return result.deleteCaptureConfig;
}
