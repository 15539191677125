<template>
	<div id="transfer-portal-page" class="view-content">
		<div class="page-heading">
			<h1>{{ header }}</h1>
			<breadcrumb :items="breadcrumbItems" />
		</div>
		<component :is="portalComponent" />
	</div>
</template>

<script lang="ts">
import AdminTransferForm from '@/views/Transactions/Record/AdminTransferForm.vue';
import ParentTransferForm from '@/views/Transactions/Record/ParentTransferForm.vue';

export default {
	name: 'TransferPortalPage',
	components: {
		AdminTransferForm,
		ParentTransferForm,
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
		};
	},
	computed: {
		useParentPortal() {
			return this.$route.params.parent_account_id || this.$root.sessionStore.user.account_id;
		},
		header() {
			return 'Transfer Portal';
		},
		breadcrumbItems() {
			if (this.useParentPortal) {
				return [
					{
						label: 'Billing',
					},
					{
						label: 'Transfer Funds Portal',
					},
				];
			} else {
				return [
					{
						label: 'Marketplaces',
						route: '/marketplaces',
					},
					{
						label: this.$route.params.mpid,
						route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
					},
					{
						label: 'Transfer Funds Portal',
					},
				];
			}
		},
		portalComponent() {
			if (this.useParentPortal) {
				// then it's a parent account
				return ParentTransferForm;
			} else {
				return AdminTransferForm;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.account-form-content {
	max-width: 760px;
}
</style>
