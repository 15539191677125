import accounts_routes from './accounts';
import ad_templates_routes from './ad-templates';
import ads_routes from './ads';
import auction_service_routes from './auction-service';
import auth_service_routes from './auth-service';
import billing_routes from './billing';
import campaigns_routes from './campaigns';
import lead_service_routes from './lead-service';
import leadRoutes from './leads';
import notification_routes from './notifications';
import offer_routes from './offers';
import parent_accounts_routes from './parent-accounts';
import promotion_routes from './promotions';
import reports_routes from './marketplace-reports';
import settings_routes from './settings';
import shippers_routes from './shipper-service';
import signup_page_routes from './signup-pages';
import vendors_routes from './vendors';
import UserList from '@/views/Users/List/Page.vue';

export default [
	{
		path: 'users',
		name: 'MarketplaceUsers',
		component: UserList,
		meta: {
			title: 'Users List',
			permissions: 'users',
		},
	},
	...reports_routes,
	...accounts_routes,
	...ads_routes,
	...ad_templates_routes,
	...parent_accounts_routes,
	...auth_service_routes,
	...vendors_routes,
	...leadRoutes('marketplace'),
	...lead_service_routes,
	...auction_service_routes,
	...settings_routes,
	...campaigns_routes,
	...shippers_routes,
	...billing_routes,
	...notification_routes,
	...offer_routes,
	...signup_page_routes,
	...promotion_routes,
];
