<template>
	<div id="phone-number-form" class="view-content with-actions">
		<div class="view-container">
			<page-loader :show="loading" />
			<div class="page-heading">
				<h1>{{ $route.params.phone_number_id ? 'Edit' : 'Create' }} Phone Number</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
			<row>
				<column>
					<p-card>
						<template #content>
							<p class="req">Required Fields</p>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Phone Number:</label>
									<div class="controls">
										<div class="field">
											<p-input-mask v-model="phone_number.phone" disabled mask="(999) 999-9999" />
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Description:</label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="phone_number.description" />
											<div v-if="v$.phone_number.description.$error" class="validation-error">
												{{ v$.phone_number.description.$errors[0].$message }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Marketplace:</label>
									<div class="controls">
										<div class="field">
											<p-dropdown
												v-model="phone_number.mpid"
												:options="marketplaceOptions"
												option-label="label"
												option-value="value"
											/>
											<div v-if="v$.phone_number.mpid.$error" class="validation-error">
												{{ v$.phone_number.mpid.$errors[0].$message }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Select Type:</label>
									<div class="controls gap-20">
										<div v-for="option in type_options" class="field">
											<div
												:class="['type-option-box', { selected: phone_number.type === option.value }]"
												@click="phone_number.type = option.value"
											>
												<strong>{{ option.label }}</strong>
												<div class="description">{{ option.description }}</div>
											</div>
										</div>
										<div v-if="v$.phone_number.type.$error" class="validation-error">
											{{ v$.phone_number.type.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Vertical:</label>
									<div class="controls">
										<div class="field">
											<p-dropdown
												v-model="phone_number.vertical_id"
												:options="vertical_options"
												option-label="label"
												option-value="value"
												placeholder="Select Vertical"
											/>
											<div v-if="v$.phone_number.vertical_id.$error" class="validation-error">
												{{ v$.phone_number.vertical_id.$errors[0].$message }}
											</div>
										</div>
									</div>
								</div>
							</div>
							<p-fieldset legend="Source Details">
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Business Unit:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="phone_number.business_unit"
													:options="business_unit_options"
													option-label="label"
													option-value="value"
													placeholder="Select Business Unit"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Vendor ID:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="phone_number.vendor_id"
													:options="vendor_options"
													option-label="label"
													option-value="value"
													placeholder="Select Vendor"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Call Placement:</label>
										<div class="controls">
											<div class="field">
												<p-input-text v-model="phone_number.call_placement" />
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">Source ID:</label>
										<div class="controls">
											<div class="field">
												<p-input-text v-model="phone_number.source_id" />
												<div v-if="v$.phone_number.source_id.$error" class="validation-error">
													{{ v$.phone_number.source_id.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Sub ID:</label>
										<div class="controls">
											<div class="field">
												<p-input-text v-model="phone_number.sub_id" />
											</div>
										</div>
									</div>
								</div>
							</p-fieldset>
							<p-fieldset legend="Provider Details">
								<div class="control-group">
									<div class="inner">
										<label class="control-label req">Provider:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="phone_number.provider_id"
													:options="provider_options"
													option-label="label"
													option-value="value"
													placeholder="Select Provider"
												/>
												<div v-if="v$.phone_number.provider_id.$error" class="validation-error">
													{{ v$.phone_number.provider_id.$errors[0].$message }}
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Provider Phone ID:</label>
										<div class="controls">
											<div class="field">
												<p-input-text v-model="phone_number.provider_phone_id" disabled />
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Provider Account ID:</label>
										<div class="controls gap-10">
											<div class="field">
												<p-input-text v-model="phone_number.provider_account_id" disabled />
											</div>
										</div>
									</div>
								</div>
								<p-fieldset legend="Endpoints">
									<div class="control-group">
										<div class="inner">
											<label class="control-label">Voice URL:</label>
											<div class="controls">
												<div class="field">
													<p-input-text v-model="phone_number.voice_url" />
												</div>
											</div>
										</div>
									</div>
									<div class="control-group">
										<div class="inner">
											<label class="control-label">Voice Fallback URL:</label>
											<div class="controls">
												<div class="field">
													<p-input-text v-model="phone_number.voice_fallback_url" />
												</div>
											</div>
										</div>
									</div>
									<template
										v-if="Array.isArray(phone_number.capabilities) && phone_number.capabilities.includes('sms')"
									>
										<div class="control-group">
											<div class="inner">
												<label class="control-label">SMS URL:</label>
												<div class="controls">
													<div class="field">
														<p-input-text v-model="phone_number.sms_url" />
													</div>
												</div>
											</div>
										</div>
										<div class="control-group">
											<div class="inner">
												<label class="control-label">SMS Fallback URL:</label>
												<div class="controls">
													<div class="field">
														<p-input-text v-model="phone_number.sms_fallback_url" />
													</div>
												</div>
											</div>
										</div>
									</template>
									<div class="control-group">
										<div class="inner">
											<label class="control-label">Status URL:</label>
											<div class="controls">
												<div class="field">
													<p-input-text v-model="phone_number.status_url" />
												</div>
											</div>
										</div>
									</div>
								</p-fieldset>
							</p-fieldset>
							<p-fieldset legend="Cost Tracking">
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Cost Tracking Type:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="phone_number.cost_tracking"
													:options="cost_tracking_options"
													option-label="label"
													option-value="value"
													placeholder="Select Cost Tracking Type"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Cost Type:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="phone_number.cost_type"
													:options="cost_type_options"
													option-label="label"
													option-value="value"
													placeholder="Select Cost Type"
												/>
											</div>
										</div>
									</div>
								</div>
								<div class="control-group">
									<div class="inner">
										<div class="controls gap-20">
											<div class="field">
												<template v-if="phone_number.cost_type === 'flat'">
													<label class="control-label">Flat Cost:</label>
													<p-input-currency v-model="phone_number.cost" />
												</template>
												<template v-else>
													<label class="control-label">Percentage Cost:</label>
													<p-input-percentage v-model="phone_number.cost_percentage" />
												</template>
											</div>
											<div class="field s">
												<label class="control-label">Cost Duration:</label>
												<div class="p-inputgroup">
													<p-input-number v-model="phone_number.cost_duration" class="input-align-right" />
													<div class="p-inputgroup-addon">s</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</p-fieldset>
							<p-fieldset legend="Other Details">
								<div class="detail-row">
									<div class="detail-label">Phone Capabilties:</div>
									<div class="detail-value">
										<div class="detail-icon">
											<icon type="phone-in-talk" size="20px" />
										</div>
										<div class="detail-icon">
											<icon type="message-text" size="20px" />
										</div>
										<div class="detail-icon">
											<icon type="message-image" size="20px" />
										</div>
									</div>
								</div>
								<div class="detail-row">
									<div class="detail-label">Blocked By Carriers:</div>
									<div class="detail-value">
										{{ blockedBy }}
									</div>
								</div>
							</p-fieldset>
							<gutter size="20px" />
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Data:</label>
									<div class="controls">
										<div class="field">
											<code-editor v-model.parse="phone_number.data" />
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Tags:</label>
									<div class="controls">
										<div class="field">
											<p-chips v-model="phone_number.tags" add-on-blur separator="," />
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label req">Status:</label>
									<div class="controls">
										<div class="field">
											<p-dropdown
												v-model="phone_number.status"
												:options="status_options"
												option-label="label"
												option-value="value"
											/>
											<div v-if="v$.phone_number.status.$error" class="validation-error">
												{{ v$.phone_number.status.$errors[0].$message }}
											</div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</p-card>
				</column>
				<gutter size="20px" />
				<column>
					<p-card>
						<template #content>
							<p-fieldset legend="Voice Route">
								<p>
									Select a voice route template or set up custom logic here. If no route templates are available, try
									setting the vertical for the phone number first.
								</p>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Voice Route Template:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="phone_number.voice_route_template_id"
													:options="voiceRouteOptions"
													option-label="label"
													option-value="value"
													placeholder="Set Custom Logic Below"
												/>
											</div>
										</div>
									</div>
								</div>
								<div v-if="!phone_number.voice_route_template_id" class="control-group">
									<div class="inner">
										<label class="control-label">Voice Route:</label>
										<div class="controls">
											<div class="field">
												<voice-route-builder v-model="phone_number.voice_route" />
											</div>
										</div>
									</div>
								</div>
							</p-fieldset>
							<p-fieldset legend="Voice Route Schedules">
								<div v-for="schedule in phone_number.schedules" class="voice-route-schedule">
									<div class="schedule-info">
										<div class="time">{{ scheduleTimes(schedule) }}</div>
										<div class="days">{{ scheduleDays(schedule) }}</div>
									</div>
									<div class="sub-route-info">
										{{ schedule.sub_route }}
									</div>
									<div class="tools">
										<div class="tool">
											<p-button v-tooltip.top="'Edit Schedule'" @click="editSchedule(schedule)">
												<template #icon>
													<icon type="pencil-box-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button v-tooltip.top="'Delete Schedule'" @click="deleteSchedule(schedule)">
												<template #icon>
													<icon type="delete" size="20px" />
												</template>
											</p-button>
											<p-confirm-popup group="schedule_confirm" />
										</div>
									</div>
								</div>
								<p-button icon="pi pi-plus" label="Add Schedule" @click="addSchedule()" />
							</p-fieldset>
							<!-- <p-fieldset
								v-if="Array.isArray(phone_number.capabilities) && phone_number.capabilities.includes('sms')"
								legend="SMS Route"
							>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">SMS Route Template:</label>
										<div class="controls">
											<div class="field">
												<p-dropdown
													v-model="phone_number.sms_route_template_id"
													:options="voiceRouteOptions"
													option-label="label"
													option-value="value"
													placeholder="Set Custom Logic Below"
												/>
											</div>
										</div>
									</div>
								</div>
								<div v-if="!phone_number.sms_route_template_id" class="control-group">
									<div class="inner">
										<label class="control-label">SMS Route:</label>
										<div class="controls">
											<div class="sms-route">Hello</div>
										</div>
									</div>
								</div>
							</p-fieldset> -->
							<gutter size="20px" />
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Route Data:</label>
									<div class="controls">
										<div class="field">
											<code-editor v-model.parse="phone_number.route_data" />
										</div>
									</div>
								</div>
							</div>
						</template>
					</p-card>
				</column>
			</row>
		</div>
		<div class="view-actions">
			<div class="flex justify-content-between align-items-center">
				<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
				<p-button icon="pi pi-check" label="Save Phone Number" @click.prevent="submit" />
			</div>
		</div>
		<p-dialog ref="schedule_dialog" v-model:visible="show_schedule_modal" modal>
			<template #header>
				<div class="flex align-items-center gap-20">
					<icon type="calendar-clock" size="24px" /><strong>Phone Schedule</strong>
				</div>
			</template>
			<p-fieldset legend="Schedule">
				<div class="quick-select">
					<a @click.prevent="selectNone">Select None</a> | <a @click.prevent="selectAll">Select All</a> |
					<a @click.prevent="selectWeekdays">Select Weekdays</a> |
					<a @click.prevent="selectWeekend">Select Weekend</a>
				</div>
				<div class="days-options">
					<a
						v-for="(day, day_index) in days"
						:key="day"
						class="filter-toggle"
						:class="{ active: selected_schedule.days.includes(day_index + 1) }"
						@click="handleLocalDay(day_index + 1)"
					>
						{{ day }}
					</a>
				</div>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label">Start Time:</label>
								<p-dropdown
									v-model="selected_schedule.start_time"
									style="min-width: 200px"
									class="max-w-20rem"
									placeholder="Select Start Time"
									:options="start_times_options"
									option-label="label"
									option-value="value"
									name="start_time"
								/>
							</div>
							<div class="field">
								<label class="control-label">End Time:</label>
								<p-dropdown
									v-model="selected_schedule.end_time"
									style="min-width: 200px"
									class="max-w-20rem"
									placeholder="Select End Time"
									:options="availableEndTimes"
									option-value="value"
									option-label="label"
									name="end_time"
								/>
							</div>
							<div class="field">
								<label class="control-label">Timezone:</label>
								<p-input-text :value="'Pacific Time'" disabled />
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
			<p-fieldset legend="Sub-Route">
				<p>Select the sub-route to use during the set schedule.</p>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Sub-Route:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="selected_schedule.sub_route"
									:options="voice_sub_route_options"
									placeholder="Select a Sub-Route"
								/>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
			<template #footer>
				<p-button label="Save Schedule" @click="saveSchedule()" />
			</template>
		</p-dialog>
	</div>
</template>
<script>
import { helpers, required, minValue } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { cloneDeep, merge, omit, remove, uniqueId } from 'lodash-es';
import {
	getMarketplacesAsOptions,
	getPhoneNumberById,
	getProvidersAsOptions,
	getRouteTemplates,
	getRouteTemplateById,
	getVendorsAsOptions,
	updatePhoneNumber,
} from '@GQL';
import { computedAsync } from '@vueuse/core';
import codeEditor from '@/components/forms/CodeEditor.vue';
import voiceRouteBuilder from '@/views/Calls/RouteTemplates/VoiceRoute/VoiceRouteBuilder.vue';
import pageLoader from '@/components/elements/Loader.vue';
import pConfirmPopup from 'primevue/confirmpopup';
import STARTTIMES from '@/lib/Data/times.json';
import ENDTIMES from '@/lib/Data/end_times.json';

const default_phone_number = {
	id: null,
	mpid: null,
	business_unit: 'OO',
	vendor_id: 'nextgenleads',
	provider_id: 'twilio',
	provider_phone_id: null,
	provider_account_id: null,
	phone: null,
	type: null,
	description: null,
	call_placement: null,
	source_id: null,
	sub_id: null,
	vertical_id: null,
	data: {},
	capabilities: [],
	bypass_blocklist: false,
	blocked_by: [],
	voice_url: null,
	voice_fallback_url: null,
	voice_route_template_id: null,
	voice_route: null,
	sms_url: null,
	sms_fallback_url: null,
	sms_route_template_id: null,
	sms_route: {},
	route_data: {},
	status_url: null,
	schedules: [],
	tags: [],
	cost_tracking: 'none',
	cost_type: null,
	cost: 0,
	cost_percentage: 0,
	cost_duration: 0,
	status: 'active',
};

const default_schedule = {
	days: [],
	start_time: null,
	end_time: null,
	sub_route: null,
};

export default {
	name: 'PhoneNumberFormPage',
	components: {
		codeEditor,
		voiceRouteBuilder,
		pageLoader,
		pConfirmPopup,
	},
	setup() {
		const voiceRouteOptions = computedAsync(async () => {
			const route_templates = await getRouteTemplates();
			let route_template_options = [];
			if (route_templates) {
				route_template_options = route_templates.map((template) => {
					return {
						label: template.name,
						value: template.id,
					};
				});
			}
			return [{ label: 'Custom Logic', value: null }, ...route_template_options];
		});

		return {
			voiceRouteOptions,
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			phone_number: cloneDeep(default_phone_number),
			marketplace_options: [],
			vendor_options: [],
			vertical_options: [
				{ label: 'None', value: null },
				{ label: 'Health Insurance', value: 'health_insurance' },
				{ label: 'Medicare', value: 'medicare' },
			],
			business_unit_options: [
				{ label: 'Owned & Operated', value: 'OO' },
				{ label: 'Partner Network', value: 'PN' },
			],
			provider_options: [],
			type_options: [
				{ label: 'Leadgen', value: 'leadgen', description: 'This phone number is used for generating new calls' },
				{ label: 'Tracking', value: 'tracking', description: 'This phone number is used for tracking customer calls' },
				{ label: 'Custom', value: 'custom', description: 'This phone number has custom logic attached to it' },
			],
			cost_tracking_options: [
				{ label: 'None', value: 'none' },
				{ label: 'Duration', value: 'duration' },
				{ label: 'Generated', value: 'generated' },
				{ label: 'Transferred', value: 'transferred' },
				{ label: 'Sold', value: 'sold' },
			],
			cost_type_options: [
				{ label: 'Flat', value: 'flat' },
				{ label: 'Percentage', value: 'percentage' },
			],
			status_options: [
				{ label: 'Active', value: 'active' },
				{ label: 'Disabled', value: 'disabled' },
				{ label: 'Archived', value: 'archived' },
				{ label: 'Missing', value: 'missing' },
			],
			show_schedule_modal: false,
			selected_schedule: null,
			start_times_options: STARTTIMES,
			end_times_options: ENDTIMES,
			days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			voice_sub_route_options: [],
			breadcrumb_items: [{ label: 'Phone Numbers', route: '/phone-numbers' }, { label: 'Edit Phone Number' }],
		};
	},
	computed: {
		marketplaceOptions() {
			return [{ label: 'Global Marketplace', value: '$NG' }, ...this.marketplace_options];
		},
		blockedBy() {
			if (Array.isArray(this.phone_number.blocked_by)) {
				if (this.phone_number.blocked_by.length) {
					return this.phone_number.blocked_by.join(', ');
				}
			}
			return 'None';
		},
		availableEndTimes() {
			return this.end_times_options.filter((time) => time.value > this.selected_schedule.start_time);
		},
	},
	validations() {
		return {
			phone_number: {
				description: {
					required: helpers.withMessage('A description is required', required),
				},
				mpid: {
					required: helpers.withMessage('Select a marketplace for this phone number', required),
				},
				type: {
					required: helpers.withMessage('Select which type of phone number this is', required),
				},
				vertical_id: {
					required: helpers.withMessage('A vertical is required', required),
				},
				provider_id: {
					required: helpers.withMessage('A provider is required', required),
				},
				source_id: {
					required: helpers.withMessage('A source_id is required', required),
				},
				status: {
					required: helpers.withMessage('A status is required', required),
				},
			},
		};
	},
	methods: {
		scheduleTimes(schedule) {
			const start_time = STARTTIMES.find((time) => {
				return time.value === schedule.start_time;
			});

			const end_time = ENDTIMES.find((time) => {
				return time.value === schedule.end_time;
			});

			if (start_time && end_time) {
				return start_time.label.replace(' ', '') + ' - ' + end_time.label.replace(' ', '');
			}
			return 'Schedule Error';
		},
		scheduleDays(schedule) {
			const days = ['Sat', 'Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
			return schedule.days
				.map((day) => {
					return days[day];
				})
				.join(', ');
		},
		async addSchedule() {
			await this.getVoiceSubRouteOptions();
			this.selected_schedule = cloneDeep(default_schedule);
			this.show_schedule_modal = true;
		},
		async editSchedule(schedule) {
			await this.getVoiceSubRouteOptions();
			this.selected_schedule = cloneDeep(schedule);
			this.show_schedule_modal = true;
		},
		saveSchedule() {
			const new_schedule = cloneDeep(this.selected_schedule);
			if (!new_schedule.id) {
				new_schedule.id = uniqueId();
				this.phone_number.schedules.push(new_schedule);
			} else {
				const original_schedule = this.phone_number.schedules.find((schedule) => {
					return schedule.id === new_schedule.id;
				});
				if (original_schedule) {
					merge(original_schedule, new_schedule);
				}
			}
			this.show_schedule_modal = false;
		},
		deleteSchedule(schedule) {
			this.$confirm.require({
				target: event.currentTarget,
				group: 'schedule_confirm',
				message: 'Are you sure you want to delete this schedule?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: 'Delete Schedule',
				rejectLabel: 'Cancel',
				accept: async () => {
					remove(this.phone_number.schedules, (existing_schedule) => {
						return existing_schedule.id === schedule.id;
					});
				},
			});
		},
		async getVoiceSubRouteOptions() {
			if (this.phone_number.voice_route_template_id !== null) {
				const voice_route_template = await getRouteTemplateById(this.phone_number.voice_route_template_id);
				this.voice_sub_route_options = Object.keys(voice_route_template.route);
			} else {
				this.voice_sub_route_options = Object.keys(this.phone_number.voice_route);
			}
		},
		handleLocalDay(day) {
			if (this.selected_schedule.days.includes(day)) {
				this.selected_schedule.days = this.selected_schedule.days.filter((item) => item !== day);
			} else {
				this.selected_schedule.days.push(day);
			}
		},
		selectNone() {
			this.selected_schedule.days = [];
		},
		selectAll() {
			this.selected_schedule.days = [1, 2, 3, 4, 5, 6, 7];
		},
		selectWeekdays() {
			this.selected_schedule.days = [2, 3, 4, 5, 6];
		},
		selectWeekend() {
			this.selected_schedule.days = [1, 7];
		},
		async submit() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				try {
					// Clone the phone_number so we can do stuff to it before saving
					const phone_number = cloneDeep(this.phone_number);

					// Add defaults to jsonb fields
					if (!phone_number.voice_route) phone_number.voice_route = {};
					if (!phone_number.sms_route) phone_number.sms_route = {};
					if (!phone_number.route_data) phone_number.route_data = {};

					// Remove IDs from the schedules
					phone_number.schedules = phone_number.schedules.map((schedule) => {
						delete schedule.id;
						return schedule;
					});

					// Remove IDs from the schedules
					phone_number.schedules = phone_number.schedules.map((schedule) => {
						delete schedule.id;
						return schedule;
					});

					console.log(4444, phone_number);

					const result = await updatePhoneNumber(phone_number.id, omit(phone_number, ['id', 'phone', 'created_at']));

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully updated the phone number`,
							detail: `${phone_number.phone}`,
							life: 5000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to update the phone number`,
							detail: `${phone_number.phone}`,
						});
					}
				} catch (err) {
					const msg_snippet = err.message.split(': {')[0];
					this.$toast.add({
						severity: 'error',
						summary: `Unable to update phone number`,
						detail: `${msg_snippet}`,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
	async mounted() {
		try {
			this.loading = true;
			this.marketplace_options = await getMarketplacesAsOptions();
			this.vendor_options = await getVendorsAsOptions();
			this.provider_options = await getProvidersAsOptions();
			if (!this.$route.meta.new) {
				const phone_number = await getPhoneNumberById(this.$route.params.phone_number_id);
				this.phone_number = cloneDeep(phone_number);

				// Add IDs to the schedules
				if (Array.isArray(this.phone_number.schedules) && this.phone_number.schedules.length > 0) {
					this.phone_number.schedules = this.phone_number.schedules.map((schedule) => {
						schedule.id = uniqueId();
						return schedule;
					});
				} else {
					this.phone_number.schedules = [];
				}
			} else {
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
				life: 6000,
			});
		} finally {
			this.loading = false;
		}
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.type-option-box {
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 1em;
	cursor: default;

	strong {
		display: block;
		font-size: 1.125em;
		margin-bottom: 5px;
	}

	&.selected {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-light);
		color: var(--color-b-light);

		strong {
			color: var(--color-b);
		}
	}
}

.detail-row {
	align-items: center;
	color: var(--gray-70);
	display: flex;
	flex-direction: row;
	font-size: 0.875rem;
	height: 2.5em;
}

.detail-label {
	flex: 0 0 200px;
}

.detail-value {
	flex: 1 0;
}

.detail-icon {
	display: inline-block;
	margin-right: 15px;
}

.voice-route-schedule {
	align-items: center;
	display: flex;
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 20px;

	.schedule-info {
		.time {
			margin-bottom: 0.5em;
		}

		.days {
			color: var(--gray-50);
			font-size: 0.875em;
		}
	}

	.sub-route-info {
		color: var(--gray-50);
		font-family: var(--font-mono);
		font-size: 0.875rem;
	}

	.schedule-info,
	.tools {
		flex: 1 0;
	}

	.tools {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
	}
}

.days-options {
	.days-label {
		.mobile({
			flex-basis: 100%;
		});
	}
}

.quick-select {
	font-size: var(--font-size-sm);
	margin-bottom: 1rem;

	a {
		cursor: pointer;
	}
}

.days {
	display: flex;
	flex: 1 0 auto;
	flex-flow: row wrap;

	.active-day {
		background: var(--color-b);
		border-radius: 16px;
		color: white;
		display: inline-block;
		padding: 0.5rem 0.75rem;
	}
}
</style>
