import mapNestedRoutes from '@/lib/Utils/mapNestedRoutes';
import AccountFormPage from '@/views/Accounts/Record/Page.vue';
import EditCampaign from '@/views/Campaigns/Record/Page.vue';
import ChildAccountsList from '@/views/ChildAccounts/List/Page.vue';
import ParentAccountsList from '@/views/ParentAccounts/List/Page.vue';
import ParentAccountsWrapper from '@/views/ParentAccounts/ParentAccountWrapper.vue';
import ParentAccountOverview from '@/views/Reports/ParentAccountReports/Overview/Page.vue';
import CampaignTemplateList from '@/views/Campaigns/List/TemplateList.vue';
import reports_routes from './marketplace-reports';
import billing_routes from './billing';
import account_users_routes from './auth-service';
import account_settings_routes from './settings';

export default [
	{
		path: 'parent-accounts',
		name: 'ParentAccountsList',
		component: ParentAccountsList,
		meta: {
			title: 'Parent Accounts',
			permissions: 'parent_accounts',
		},
	},
	{
		path: 'reports/parent-account-overview',
		name: 'AccountsDashboard',
		component: ParentAccountOverview,
		meta: {
			title: 'Parent Account Overview',
			permissions: 'parent_account_reports',
		},
	},
	{
		path: 'parent-accounts/:parent_account_id',
		name: 'ParentAccountsDetail',
		component: ParentAccountsWrapper,
		children: mapNestedRoutes(
			[
				{
					path: 'reports/parent-account-overview',
					name: 'AccountsDashboard',
					component: ParentAccountOverview,
					meta: {
						title: 'Parent Account Overview',
						permissions: 'parent_account_reports',
					},
				},
				...reports_routes,
				...billing_routes,
				...account_users_routes,
				...account_settings_routes,
				{
					path: 'child-accounts',
					name: 'ChildAccountList',
					component: ChildAccountsList,
					meta: {
						title: 'Child Accounts',
						permissions: 'child_accounts',
					},
				},
				{
					path: 'child-accounts/new',
					name: 'ChildAccountCreate',
					component: AccountFormPage,
					meta: {
						new: true,
						user_type: 'account',
						child_account: true,
						title: 'Create Child Account',
						permissions: 'child_accounts',
					},
				},
				{
					path: 'child-accounts/:account_id',
					name: 'ChildAccountEdit',
					component: AccountFormPage,
					meta: {
						child_account: true,
						title: 'Edit Child Account',
						permissions: 'child_accounts',
					},
				},
				{
					path: 'campaign-templates/new',
					name: 'CreateCampaignTemplate',
					component: EditCampaign,
					meta: {
						new: true,
						template_mode: true,
						title: 'Create Campaign Template',
						permissions: 'campaign_templates',
					},
				},
				{
					path: 'campaign-templates/:campaign_id',
					name: 'EditCampaignTemplate',
					component: EditCampaign,
					meta: {
						template_mode: true,
						title: 'Edit Campaign Template',
						permissions: 'campaign_templates',
					},
				},
				{
					path: 'campaign-templates',
					name: 'CampaignList',
					component: CampaignTemplateList,
					meta: {
						title: 'Campaign Templates',
						permissions: 'campaign_templates',
					},
				},
			],
			'ParentAccountsDetail:',
			{
				meta: {
					nested_account: true,
				},
			}
		),
	},
	{
		path: 'parent-accounts/:account_id/edit',
		name: 'ParentAccountsEdit',
		component: AccountFormPage,
		meta: {
			parent_account: true,
			title: 'Edit Parent Account',
			permissions: 'parent_accounts',
		},
	},
	{
		path: 'parent-accounts/new',
		name: 'ParentAccountNew',
		component: AccountFormPage,
		meta: {
			new: true,
			user_type: 'account',
			parent_account: true,
			title: 'Create Parent Account',
			permissions: 'parent_accounts',
		},
	},
	{
		path: 'child-accounts',
		name: 'ChildAccountList',
		component: ChildAccountsList,
		meta: {
			title: 'Child Accounts',
			permissions: 'child_accounts',
		},
	},
	{
		path: 'child-accounts/new',
		name: 'ChildAccountCreate',
		component: AccountFormPage,
		meta: {
			new: true,
			user_type: 'account',
			child_account: true,
			title: 'New Child Account',
			permissions: 'child_accounts',
		},
	},
	{
		path: 'child-accounts/:account_id',
		name: 'ChildAccountEdit',
		component: AccountFormPage,
		meta: {
			child_account: true,
			title: 'Edit Child Account',
			permissions: 'child_accounts',
		},
	},
];
