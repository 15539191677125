<template>
	<a @click.prevent="handleLeadModal">{{ lead_id }} <icon type="open-in-new" size="1rem" /></a>
	<p-dialog
		v-model:visible="show_lead_modal"
		class="lead-details-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			root: { class: 'w-12 sm:w-6 md:w-5' },
		}"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="account" size="24px" style="color: var(--color-b)" />
				<strong>Lead Details</strong>
			</div>
		</template>
		<div class="lead-details">
			<p-card class="lead-summary max-w-30rem ml-auto mr-auto">
				<template #title>
					<div class="flex">
						<vertical-icon :vertical-id="local_lead.vertical_id" si1e="32px" class="mr-3" />
						<div class="flex flex-column gap-1">
							<div class="name">{{ local_lead.first_name }} {{ local_lead.last_name }}</div>
							<div class="id">
								{{ local_lead.id }}
							</div>
						</div>
						<router-link class="ml-auto" :to="{ path: `/leads/${lead_id}` }" target="_blank">
							<p-button text v-tooltip.top="'Open Lead in New Tab'">
								<template #icon>
									<icon type="open-in-new" size="20px" />
								</template>
							</p-button>
						</router-link>
					</div>
				</template>
				<template #content>
					<ul class="table-list">
						<li>
							<div class="label">Date</div>
							<div class="value">
								{{ formatDate(local_lead.created_at, 'ddd, MMMM D, YYYY [at] h:mm:ssA') || '' }}<br />
								<div class="sub-value">{{ timeAgo(local_lead.created_at) }}</div>
							</div>
						</li>
						<li>
							<div class="label">Vertical</div>
							<div class="value">
								{{ verticalName(local_lead.vertical_id) }}
							</div>
						</li>
						<li>
							<div class="label">Phone</div>
							<div class="value">
								{{ phoneFormat(local_lead.phone) }}
							</div>
						</li>
					</ul>
				</template>
			</p-card>
			<gutter size="20px" />
			<p-card class="lead-information max-w-30rem ml-auto mr-auto">
				<template #content>
					<strong style="color: var(--color-b)">Contact Information</strong>
					<ul class="table-list mt-2">
						<li>
							<div class="label">First Name</div>
							<div class="value">
								{{ local_lead.first_name }}
							</div>
						</li>
						<li>
							<div class="label">Last Name</div>
							<div class="value">
								{{ local_lead.last_name }}
							</div>
						</li>
						<li>
							<div class="label">Phone</div>
							<div class="value">
								{{ phoneFormat(local_lead.phone) }}
							</div>
						</li>
						<li>
							<div class="label">Email</div>
							<div class="value">
								{{ local_lead.email }}
							</div>
						</li>
						<li>
							<div class="label">Address</div>
							<div class="value">{{ local_lead.street_1 }} {{ local_lead.street_2 }}</div>
						</li>
						<li>
							<div class="label">City</div>
							<div class="value">
								{{ local_lead.city }}
							</div>
						</li>
						<li>
							<div class="label">County</div>
							<div class="value">
								{{ local_lead.county }}
							</div>
						</li>
						<li>
							<div class="label">State</div>
							<div class="value">
								{{ local_lead.state }}
							</div>
						</li>
						<li>
							<div class="label">Zipcode</div>
							<div class="value">
								{{ local_lead.zip }}
							</div>
						</li>
					</ul>
					<gutter size="20px" />
					<strong style="color: var(--color-b)">Vertical Information</strong>
					<ul class="table-list mt-2">
						<li>
							<div class="label">Date of Birth</div>
							<div class="value">
								{{ formatDate(new Date(local_lead.data.dob), 'MM/DD/YYYY') }}
							</div>
						</li>
						<li>
							<div class="label">Age</div>
							<div class="value">
								{{ dobToAge(local_lead.data.dob) }}
							</div>
						</li>
						<li>
							<div class="label">Gender</div>
							<div class="value">
								{{ title(local_lead.data.gender) || 'N/A' }}
							</div>
						</li>
						<li>
							<div class="label">Height</div>
							<div class="value">
								{{ local_lead.data.height ? heightFormat(local_lead.data.height) : 'N/A' }}
							</div>
						</li>
						<li>
							<div class="label">Weight</div>
							<div class="value">{{ local_lead.data.weight ? `${local_lead.data.weight} Lbs` : 'N/A' }}</div>
						</li>
						<li>
							<div class="label">Household Size</div>
							<div class="value">
								{{ local_lead.data.household_size || 'N/A' }}
							</div>
						</li>
						<li>
							<div class="label">Household Income</div>
							<div class="value">
								{{ local_lead.data.income ? currency(local_lead.data.income) : 'N/A' }}
							</div>
						</li>
						<li>
							<div class="label">Medical Conditions</div>
							<div class="value">
								{{ local_lead.data.medical_conditions ? formatConditions(local_lead.data.medical_conditions) : 'N/A' }}
							</div>
						</li>
						<li>
							<div class="label">Pregnant/Expecting</div>
							<div class="value">
								{{ local_lead.data.pregnant ? 'Yes' : 'No' }}
							</div>
						</li>
						<li>
							<div class="label">Tobacco Use</div>
							<div class="value">
								{{ local_lead.data.tobacco_user ? 'Yes' : 'No' }}
							</div>
						</li>
						<li>
							<div class="label">Timeframe</div>
							<div class="value">
								{{ local_lead.data.timeframe || 'N/A' }}
							</div>
						</li>
					</ul>
					<gutter size="20px" />
					<strong v-if="is_lt_lead" style="color: var(--color-b)">LT Information</strong>
					<ul v-if="is_lt_lead" class="table-list mt-2">
						<li v-if="local_lead.vertical_id === 'health_insurance'">
							<div class="label">Affordability</div>
							<div class="value">
								{{ local_lead.data.affordability ? 'Yes' : 'No' }}
							</div>
						</li>
						<li v-if="local_lead.vertical_id === 'health_insurance'">
							<div class="label">Has Medical Conditions</div>
							<div class="value">
								{{ local_lead.data.has_medical_condition ? 'Yes' : 'No' }}
							</div>
						</li>
						<li v-if="local_lead.vertical_id === 'medicare'">
							<div class="label">Has Medicare Parts A/B</div>
							<div class="value">
								{{ local_lead.data.has_medicare_parts_a_b ? 'Yes' : 'No' }}
							</div>
						</li>
						<div v-if="local_lead.vertical_id === 'medicare'" class="sub-value">
							*Some states prevent collecting this info
						</div>
					</ul>
					<gutter size="20px" />
					<strong style="color: var(--color-b)">Geographical Information</strong>
					<ul class="table-list mt-2">
						<li>
							<div class="label">County</div>
							<div class="value">
								{{ local_lead.county }}
							</div>
						</li>
						<li>
							<div class="label">City</div>
							<div class="value">
								{{ local_lead.city }}
							</div>
						</li>
						<li>
							<div class="label">State</div>
							<div class="value">
								{{ local_lead.state }}
							</div>
						</li>
						<li>
							<div class="label">Zipcode</div>
							<div class="value">
								{{ local_lead.zip }}
							</div>
						</li>
					</ul>
					<gutter size="20px" />
					<strong style="color: var(--color-b)">Meta Information</strong>
					<ul class="table-list mt-2">
						<li>
							<div class="label">IP Address</div>
							<div class="value">
								{{ local_lead.meta.ip_address }}
							</div>
						</li>
					</ul>
				</template>
			</p-card>
		</div>
	</p-dialog>
</template>

<script lang="ts">
import { startCase, map } from 'lodash-es';
import {
	formatDate,
	displaySelected,
	dobToAge,
	currency,
	verticalName,
	phoneFormat,
	capitalize,
	timeAgo,
	heightFormat,
	title,
} from '@/lib/Filters';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import { getLeadDetail } from '@GQL';

export default {
	name: 'AllLegacyPurchasesList',
	components: {
		verticalIcon,
		typeIcon,
	},
	props: {
		lead_id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			componentKey: 0,
			loading: false,
			show_lead_modal: false,
			local_lead: {} as Record<string, string>,
		};
	},
	async created() {
		await this.fetchLeadDetails();
	},
	computed: {
		is_lt_lead() {
			return this.lead_id.startsWith('T');
		},
	},
	methods: {
		currency,
		formatDate,
		displaySelected,
		verticalName,
		startCase,
		phoneFormat,
		timeAgo,
		map,
		capitalize,
		dobToAge,
		heightFormat,
		title,
		async handleLeadModal() {
			this.show_lead_modal = true;
			await getLeadDetail(this.lead_id);
		},
		async fetchLeadDetails() {
			this.local_lead = (await getLeadDetail(this.lead_id)).lead;
		},
		formatConditions(medical_conditions) {
			const map = {
				aids_hiv: 'HIV/AIDS',
				cancer: 'Cancer',
				diabetes: 'Diabetes',
				heart_attack: 'Heart Attack',
				pulmonary_disease: 'Pulmonary Disease',
				stroke: 'Stroke',
			};

			const conditions_array = [];

			Object.entries(medical_conditions).forEach(([key, value]) => {
				if (value) {
					conditions_array.push(key);
				}
			});

			let conditions = '';

			conditions_array.forEach((condition, index, arr) => {
				if (index === arr.length - 1) {
					conditions += map[condition];
				} else {
					conditions += map[condition] + ', ';
				}
			});

			if (!conditions) {
				conditions = 'None';
			}

			return conditions;
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.name {
	font-size: var(--font-size-h4);
}

.id {
	color: var(--gray-60);
	font-size: var(--font-size-sm);
	font-weight: normal;
}
</style>
