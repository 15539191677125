import { $GQL } from '@GQL';
interface Pagination {
	page: number;
	page_size: number;
}

interface DQReasonParams {
	date_range: [string, string]; // YYYY-MM-DD format
	campaign_id: string;
	dq_reason: string;
	pagination: Pagination;
}

export async function getLeadsByDQReason(params: DQReasonParams) {
	const query = `
        query GetLeadsByDQReason($params: DQReasonParams!) {
            leadsByDQReason(params: $params) {
                rows {
                    created_at
                    lead_id
                }
                row_count
            }
        }
    `;

	const result = await $GQL.request(query, { params });
	return result.leadsByDQReason;
}
