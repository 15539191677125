<template>
	<div class="settings-form">
		<h2>Premium Listing Settings</h2>
		<template v-if="mpid !== '$NG'">
			<p-message v-if="!this.localSettingsList.includes('premium_listings')" :closable="false" severity="warn"
				>These settings are controlled at the global level. To override the global settings for this marketplace only,
				<strong><a href="javascript:void(0)" @click="localizeSetting('premium_listings', mpid)">click here</a></strong
				>.</p-message
			>
			<p-message :closable="false" v-else>
				The Premium Listing settings for this marketplace are currently overriding the global settings. To remove the
				marketplace overrides, <strong><a href="javascript:void(0)" @click="deleteLocalSetting()">click here</a></strong
				>.
			</p-message>
		</template>
		<template v-else-if="overrides.length">
			<p-message :closable="false">
				<div>Some marketplaces are overriding these settings locally:</div>
				<ul class="bulleted">
					<li v-for="mpid in overrides" :key="mpid">{{ mpid }}</li>
				</ul>
			</p-message>
		</template>
		<gutter size="10px" />
		<p-block-ui :blocked="mpid !== '$NG' && !this.localSettingsList.includes('premium_listings')">
			<template v-for="vertical in verticals" :key="vertical.id">
				<p-fieldset :legend="vertical.name">
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Top Performing Ad Template:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="premium_listings[vertical.id].top_performer"
										:options="topPerformerOptions(vertical.id)"
										option-label="label"
										option-value="value"
										placeholder="Select Ad Template"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Price:</label>
							<div class="controls">
								<div class="field xs">
									<p-input-currency class="input-align-right" v-model="premium_listings[vertical.id].price" />
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>
			</template>
			<div class="actions">
				<p-button label="Save" icon="pi pi-check" @click="updateSetting()" />
				<gutter size="20px" />
				<p-button label="Reset" text @click="resetSetting()" />
			</div>
		</p-block-ui>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import CodeEditor from '@/components/forms/CodeEditor.vue';
import {
	getAdTemplates,
	getSettings,
	updateSetting,
	localizeSetting,
	deleteLocalSetting,
	getLocalOverrideSettingsList,
} from '@GQL';
import { useMarketplaceStore } from '@/stores/marketplace';
import 'brace/mode/json';
import 'brace/theme/sqlserver';
import pBlockUi from 'primevue/blockui';
import { mapStores } from 'pinia';

export default {
	name: 'PremiumListingSettings',
	components: {
		pBlockUi,
		CodeEditor,
	},
	props: {
		localSettingsList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			ad_templates: [],
			premium_listings: {
				health_insurance: {
					top_performer: null,
					price: 5,
				},
				medicare: {
					top_performer: null,
					price: 5,
				},
			},
			defaults: null,
			overrides: [],
			verticals: [
				{
					id: 'health_insurance',
					name: 'Health Insurance',
				},
				{
					id: 'medicare',
					name: 'Medicare',
				},
			],
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		...mapStores(useMarketplaceStore),
	},
	methods: {
		topPerformerOptions(vertical_id) {
			return this.ad_templates
				.filter((ad_template) => {
					return ad_template.vertical_id === vertical_id;
				})
				.map((ad_template) => {
					return {
						label: ad_template.name,
						value: ad_template.id,
					};
				});
		},
		async localizeSetting() {
			const setting = await localizeSetting('premium_listings', this.mpid);
			this.premium_listings = setting.data;
			this.$emit('refresh');
		},
		async deleteLocalSetting() {
			const setting = await deleteLocalSetting('premium_listings', this.mpid);
			if (setting.id) {
				await this.getSetting();
			}
			this.$emit('refresh');
		},
		async getSetting() {
			const settings_result = await getSettings(['premium_listings'], this.mpid);
			this.premium_listings = settings_result.settings.premium_listings;
			this.defaults = cloneDeep(this.premium_listings);

			if (this.mpid == '$NG') {
				this.overrides = await getLocalOverrideSettingsList('premium_listings');
			}
		},
		async updateSetting() {
			const settings_result = await updateSetting('premium_listings', {
				data: this.premium_listings,
			});
			if (settings_result.id === 'premium_listings') {
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully saved premium_listings settings',
					life: 3000,
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
				});
			}
		},
		resetSetting() {
			this.premium_listings = cloneDeep(this.defaults);
		},
	},
	async mounted() {
		await this.getSetting();
		this.ad_templates = await getAdTemplates(`status = 'active'`, 'adTemplates');
	},
};
</script>

<style scoped lang="less">
.field {
	flex: 1 0;
}
</style>
