<template>
	<div id="settings" class="view-content">
		<div class="page-heading">
			<h1>Settings</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="global-settings page-content">
			<row>
				<column :span="5">
					<div class="card">
						<row>
							<column :span="1" class="padded">
								<p-menu :model="settingsNav" />
							</column>
							<div class="divider" />
							<column :span="3" class="padded">
								<component :is="selected_component" v-if="selected_component" />
								<template v-else>
									<p>Please select a category on the left to update settings</p>
								</template>
							</column>
						</row>
					</div>
				</column>
				<column :span="2"> </column>
			</row>
		</div>
	</div>
</template>

<script>
import pMenu from 'primevue/menu';
import amw_dialer_ids from '../Components/AMWDialerIDs.vue';
import amw_email_templates from '../Components/AMWEmailTemplates.vue';
import cancellations from '../Components/Cancellations.vue';
import funding_action_options from '../Components/FundingActionOptions.vue';
import navigation from '../Components/Navigation.vue';
import premium_listings from '../Components/PremiumListings.vue';
import shipper_email_templates from '../Components/ShipperEmailTemplates.vue';
import signup_source_ids from '../Components/SignupSourceIDs.vue';
import signup_source_call_scripts from '../Components/SignUpSourceCallScripts.vue';
import twilio from '../Components/Twilio.vue';
import verticals from '../Components/Verticals.vue';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';

export default {
	name: 'GlobalSettings',
	components: {
		pMenu,
		amw_dialer_ids,
		amw_email_templates,
		cancellations,
		funding_action_options,
		navigation,
		premium_listings,
		shipper_email_templates,
		signup_source_ids,
		signup_source_call_scripts,
		twilio,
		verticals,
	},
	data() {
		return {
			breadcrumb_items: [
				{
					label: 'Settings',
				},
			],
			selected_component: null,
			settings_nav: [
				{
					label: 'AMW Dialer Ids',
					id: 'amw_dialer_ids',
				},
				{
					label: 'AMW Email Templates',
					id: 'amw_email_templates',
				},
				{
					label: 'Cancellations',
					id: 'cancellations',
				},
				{
					label: 'Funding Actions',
					id: 'funding_action_options',
				},
				{
					label: 'Navigation',
					id: 'navigation',
				},
				{
					label: 'Premium Listings',
					id: 'premium_listings',
				},
				{
					label: 'Shipper Email Templates',
					id: 'shipper_email_templates',
				},
				{
					label: 'Sign Up Source Ids',
					id: 'signup_source_ids',
				},
				{
					label: 'Sign Up Source Call Scripts',
					id: 'signup_source_call_scripts',
				},
				{
					label: 'Twilio',
					id: 'twilio',
				},
				{
					label: 'Verticals',
					id: 'verticals',
				},
			],
		};
	},
	computed: {
		settingsNav() {
			const filtered_settings_nav = this.settings_nav.filter((nav_item) => {
				if (this.sessionStore.hasPermission([`Settings.${nav_item.id}`])) {
					return true;
				}
				return false;
			});
			return filtered_settings_nav.map((settings_nav_item) => {
				return {
					label: settings_nav_item.label,
					command: () => {
						this.selected_component = settings_nav_item.id;
						this.$router.push({
							query: {
								id: settings_nav_item.id,
							},
						});
					},
				};
			});
		},
		...mapStores(useSessionStore),
	},
	mounted() {
		if (this.$route.query.id) {
			this.selected_component = this.$route.query.id;
		}
	},
};
</script>

<style lang="less">
.global-settings {
	h2 {
		font-size: 1.5rem;
		margin-bottom: 0;
	}

	h3 {
		font-size: 1.25rem;
	}

	.actions {
		display: flex;
		padding: 20px 0 0;
	}
}

.divider {
	border-right: 1px solid var(--gray-10);
}
</style>
