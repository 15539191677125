import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getPhoneNumbers(filters?: QueryFilters, no_cache?: string[]) {
	const query = `
        query GetPhoneNumbers($filters: [[Any]]){
            phoneNumbers(filters: $filters) {
                id
				mpid
				description
				phone
				vertical_id
				type
				provider_phone_id
				provider_account {
					id
					name
					provider_account_id
				}
				status
            }
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.phoneNumbers;
}
