import { $GQL } from '@GQL';

// Hardly a need to filter, just use quick filters if anything
export async function getVendors(no_cache?: string[]) {
	const query = `
        query GetVendors {
            vendors {
                id
                name
                contact {
                    name
                    email
                    phone
                }
				settings {
					icon_url
					logo_url
				}
				status
            }
        }
    `;

	const request = await $GQL.request(query, { no_cache });
	return request.vendors;
}
