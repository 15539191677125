import { $GQL } from '@GQL/index';
import type { CaptureConfig } from '@nextgenleads/lead-driver';

export async function updateCaptureConfig(id: string, data: Partial<CaptureConfig>) {
	const mutation = `
        mutation UpdateCaptureConfig($id: ID!, $data: JSONObject!) {
            updateCaptureConfig(id: $id, data: $data) {
                id
            }
        }
    `;

	return await $GQL.request(mutation, { id, data });
}
