<template>
	<div :class="['attribute-section filter', { active: custom_bid_has_medical_condition }]">
		<div class="control-group">
			<div class="inner">
				<div class="controls">
					<div class="field fit">
						<p-checkbox v-model="custom_bid_has_medical_condition" input-id="has_medical_condition" binary />
					</div>
					<gutter size="10px" />
					<div class="field">
						<label class="large-label" for="has_medical_condition">
							<div>Has Major Medical Condition</div>
							<div v-if="mode === 'basic'" class="sub-value">
								Exclude leads that say they have a major medical condition
							</div>
							<div v-else class="sub-value">Match leads that say they have a major medical condition</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HasMedicalConditionAttribute',
	emits: ['update'],
	props: {
		mode: {
			type: String,
			default: 'basic',
		},
	},
	data() {
		return {
			custom_bid_has_medical_condition: false,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
					invert_basic: false,
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const has_medical_condition_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.has_medical_condition';
			});
			if (has_medical_condition_match) {
				this.custom_bid_has_medical_condition = true;
			}
		},
		saveAttribute() {
			if (this.custom_bid_has_medical_condition) {
				return {
					target: {
						path: 'lead.data.has_medical_condition',
					},
					strategy: 'equals',
					comparator: {
						value: true,
					},
				};
			}
			return null;
		},
	},
};
</script>
