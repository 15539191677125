<template>
	<div class="lt-question" @click="expand">
		<div class="lt-question-heading">
			<div class="lt-question-icon">
				<icon type="comment-text-outline" size="24px" />
			</div>
			<div class="lt-question-body">
				<p>
					And just to confirm, you are looking for <strong>{{ verticalName(lead.vertical_id) }}</strong> options in
					<strong>{{ locationState }}</strong> for the ZIP code <strong>{{ locationZip }}</strong
					>, is that correct?
				</p>
			</div>

			<div class="lt-question-icon expand-button">
				<icon type="open-in-new" size="24px" />
			</div>
		</div>
		<div class="lt-question-content" v-if="expanded">
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-10" style="margin-bottom: 0">
						<div class="field m">
							<label>State:</label>
							<p-dropdown
								:options="state_options"
								v-model="locationState"
								option-label="label"
								option-value="value"
								placeholder="Select State"
							/>
						</div>
						<div class="field xs">
							<label>Zip:</label>
							<p-input-mask v-model="locationZip" mask="99999" unmask />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from 'lodash-es';
import { verticalName } from '@/lib/Filters';
import STATES from '@/lib/Data/states.json';

export default {
	name: 'LTQuestion_Location',
	emits: ['update'],
	props: {
		lead: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			expanded: false,
			state_options: [...STATES],
		};
	},
	methods: {
		verticalName,
		expand() {
			this.expanded = true;
		},
	},
	computed: {
		questionClass() {
			if (this.expanded) {
				return 'expanded';
			}
			return 'collapsed';
		},
		locationState: {
			get() {
				return get(this.lead, 'state', null);
			},
			set(new_value) {
				this.$emit('update', {
					state: new_value,
				});
			},
		},
		locationZip: {
			get() {
				return get(this.lead, 'zip', null);
			},
			set(new_value) {
				const regex = /^\d{5}$/;
				if (new_value.length === 5 && regex.test(new_value)) {
					this.$emit('update', {
						zip: new_value,
					});
				}
			},
		},
	},
};
</script>
