import { $GQL } from '@GQL/index';

export async function getRouteTemplateById(id: string) {
	const query = `
        query GetRouteTemplateById($id: ID!) {
            routeTemplate(id: $id) {
                id
				name
				description
				type
				route
				tags
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.routeTemplate;
}
