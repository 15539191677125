<template>
	<div id="channels-list" class="view-content">
		<div class="flex flex-column mb-1">
			<h1>{{ $route.params.channel_id ? 'Edit' : 'Create' }} Channel</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<ChannelForm />
	</div>
</template>

<script>
import ChannelForm from './Form.vue';
export default {
	name: 'ChannelFormPage',
	components: {
		ChannelForm,
	},
	props: {},
	data() {
		return {
			breadcrumb_items: [
				{ label: 'Channels', route: '/channels' },
				{
					label: this.$route.params.channel_id ? 'Edit Channel' : 'Create Channel',
				},
			],
		};
	},
};
</script>

<style lang="less"></style>
