import { $GQL } from '@GQL';
import type { AdTemplate } from '@nextgenleads/marketplace-driver';

export async function insertAdTemplate(data: Omit<AdTemplate, 'id' | 'created_at'>) {
	const mutation = `
        mutation InsertAdTemplate($data: JSONObject!) {
            insertAdTemplate(data: $data) {
                id
				mpid
				name
				type
				html
				meta
				status
				created_at
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertAdTemplate;
}
