import { $GQL } from '@GQL/index';

export async function getSubAccountById(id?: string, provider_account_id?: string) {
	const query = `
        query GetSubAccountById($id: ID, $provider_account_id: ID) {
            subAccount(id: $id, provider_account_id: $provider_account_id) {
                id
				name
				description
				provider_id
				provider_account_id
				vertical_ids
            }
        }
    `;

	const result = await $GQL.request(query, { id, provider_account_id });
	return result.subAccount;
}
