import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function reportAccountList(
	params: { account_filters: QueryFilters; spend_filters: QueryFilters },
	refresh?: boolean
) {
	const query = `
        query ReportAccountList($params: JSONObject!) {
            reportAccountList(params: $params) {
				rows
				row_count
			}
        }
    `;

	const no_cache = refresh ? ['reportAccountList'] : [];
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportAccountList;
}
