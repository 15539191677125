import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';
import type { Channel } from '@nextgenleads/source-driver';

export async function getChannels(filters?: QueryFilters, no_cache?: string[]): Promise<Channel[]> {
	const query = `
        query GetChannels($filters: [[Any]]) {
            channels(filters: $filters) {
                id
                name
                description
				image_url
                product
				vertical_id
				minimum_bid_adjustment_type
				minimum_bid_adjustment_amount
				minimum_duration
				maximum_duration
                status
                
				campaignsTargeting
			}
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.channels;
}
