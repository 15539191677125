<template>
	<div class="build-step process-ping-bids">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Process Ping Bids</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">Create purchases for the selected ping bids returned in a post request.</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ProcessPingBids',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
