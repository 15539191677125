<template>
	<div :class="['attribute-section filter', { active: custom_bid_pregnant }]">
		<div class="control-group">
			<div class="inner">
				<div class="controls">
					<div class="field fit">
						<p-checkbox v-model="custom_bid_pregnant" input-id="pregnant" binary />
					</div>
					<gutter size="10px" />
					<div class="field">
						<label class="large-label" for="pregnant">
							<div>Pregnant or Expecting</div>
							<div v-if="mode === 'basic'" class="sub-value">
								Exclude leads that are pregnant or expect to be pregnant
							</div>
							<div v-else class="sub-value">Match leads that are pregnant or expect to be pregnant</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PregnantAttribute',
	emits: ['update'],
	props: {
		mode: {
			type: String,
			default: 'basic',
		},
	},
	data() {
		return {
			custom_bid_pregnant: false,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
					invert_basic: false,
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const pregnant_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.pregnant';
			});
			if (pregnant_match) {
				this.custom_bid_pregnant = true;
			}
		},
		saveAttribute() {
			if (this.custom_bid_pregnant) {
				return {
					target: {
						path: 'lead.data.pregnant',
					},
					strategy: 'equals',
					comparator: {
						value: true,
					},
				};
			}
			return null;
		},
	},
};
</script>
