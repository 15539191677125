import { $GQL } from '@GQL';
import type { ShipperTemplate } from '@nextgenleads/shipper-driver';

export async function getShipperTemplateById(
	shipper_template_id: string
): Promise<{ shipperTemplate: ShipperTemplate }> {
	const request = `
        query GetShipper($id: ID!) {
			shipperTemplate(id: $id) {
				id
				name
				label
				type
				settings
				data_map
				cancellations {
					enabled
					pattern
				}
				status
			}
        }
    `;

	return await $GQL.request(request, { id: shipper_template_id });
}
