import { $GQL } from '@GQL/index';
import type { Offer } from '@nextgenleads/marketplace-driver';

export async function insertOffer(data: Omit<Offer, 'created_at'>) {
	const mutation = `
        mutation InsertOffer($data: JSONObject!) {
            insertOffer(data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertOffer;
}
