<template>
	<div id="account" class="view-content">
		<div class="page-heading">
			<h1>Account</h1>
			<breadcrumb :model="items" />
		</div>
		<p-tab-view>
			<p-tab-panel header="Dashboard">
				<Dashboard />
			</p-tab-panel>
		</p-tab-view>
	</div>
</template>

<script lang="ts">
import pTabView from 'primevue/tabview';
import pTabPanel from 'primevue/tabpanel';
import Dashboard from './Dashboard.vue';

export default {
	name: 'AccountDetail',
	components: {
		pTabView,
		pTabPanel,
		Dashboard,
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
			items: [{ label: 'Accounts', route: '/accounts' }, { label: this.$route.params.account_id }],
		};
	},
};
</script>

<style lang="less" scoped>
:deep(h3) {
	font-family: var(--font-heading);
	font-weight: bold;
}
</style>
