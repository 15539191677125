<template>
	<p-dialog v-model:visible="modal_open" :modal="true" style="max-width: 600px" :dismissable-mask="true">
		<loader :show="loading" />
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="account" size="24px" style="color: var(--color-b)" />
				<strong>{{ existing_record ? 'Edit' : 'New' }} DNC</strong>
			</div>
		</template>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Phone:</label>
				<div class="controls">
					<div class="field">
						<p-input-mask v-model="dnc.phone" mask="(999) 999-9999" placeholder="(999) 999-9999" :unmask="true" />
						<div v-if="v$.dnc.phone.$error" class="validation-error">
							{{ v$.dnc.phone.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Type:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="dnc.type"
							name="type"
							:options="typeOptions"
							placeholder="Select Type"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.dnc.type.$error" class="validation-error">
							{{ v$.dnc.type.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Source:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="dnc.source" />
						<div v-if="v$.dnc.source.$error" class="validation-error">
							{{ v$.dnc.source.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Note:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="dnc.note" />
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Save" @click="submit" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import { getDNCById, insertDNC, updateDNC } from '@GQL';
import pDialog from 'primevue/dialog';
import { required, minLength, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import pInputMask from 'primevue/inputmask';
import { useSessionStore } from '@/stores/session';
import { dnc_type_options } from '@/lib/Options';
import { getIP, getIPAddress } from '@/lib/Utils/getIPAddress';

const default_dnc = {
	type: '',
	phone: '',
	note: '',
	source: '',
	user_id: '',
};
// account_id only for adding account level dnc

export default {
	components: {
		pDialog,
		pInputMask,
	},
	props: {
		account_id: {
			type: String,
		},
		phone: {
			type: String,
			default() {
				return '';
			},
		},
	},
	emits: ['refresh'],
	setup() {
		return {
			v$: useVuelidate(),
			sessionStore: useSessionStore(),
		};
	},
	computed: {
		typeOptions() {
			if (this.global) {
				return this.dnc_type_options.filter((row) => {
					return row.value !== 'account';
				});
			} else {
				return this.dnc_type_options;
			}
		},
	},
	data() {
		return {
			modal_open: false,
			global: true,
			dnc_type_options,
			dnc: { ...default_dnc },
			existing_record: false,
			loading: false,
		};
	},
	methods: {
		async submit() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				this.loading = true;
				try {
					// based on new or existing
					let result;
					if (this.existing_record) {
						result = await updateDNC(this.dnc.id, omit(this.dnc, ['id']));
					} else {
						try {
							this.dnc.request_ip = (await getIPAddress()).ip;
						} catch (err) {
							// do nothing, the IP may just be missing in this case
							this.dnc.request_ip = (await getIP()).getIPAddress;
						}

						result = await insertDNC(this.dnc);
					}
					if (result) {
						// then close...
						this.closeModal();
						this.$toast.add({
							severity: 'success',
							summary: 'DNC record saved',
							life: 5000,
						});
						this.$emit('refresh');
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save changes',
						life: 6000,
					});
				} finally {
					this.loading = false;
				}
			}
		},
		/** Param is either the account_id or the ID of the DNC record for edits*/
		async openModal(param) {
			if (this.phone && this.phone !== '') {
				this.dnc.phone = this.phone;
			}
			// the param may either be an account_id so that the type is preset
			// or a dnc_id so that it can be loaded in
			this.dnc.user_id = this.sessionStore.user.id;
			if (param) {
				if (param.indexOf('-') > -1) {
					this.global = false;
					this.dnc.type = 'account';
					this.dnc.account_id = param;
				} else {
					this.loading = true;
					this.existing_record = true;
					try {
						this.dnc = { ...(await getDNCById(param)) };
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to load DNC record',
						});
					} finally {
						this.loading = false;
					}
				}
			}

			this.modal_open = true;
		},
		closeModal() {
			this.v$.$reset();
			this.modal_open = false;
			this.dnc = { ...default_dnc };
		},
	},
	validations() {
		return {
			dnc: {
				phone: {
					required: helpers.withMessage('A phone number is required.', required),
					minLength: minLength(10),
				},
				type: {
					required: helpers.withMessage('A type is required.', required),
				},
				source: {
					required: helpers.withMessage('A source is required.', required),
				},
			},
		};
	},
};
</script>
