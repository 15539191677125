import { $GQL } from '@GQL';

export async function deleteRole(id: string, mpid: string) {
	const mutation = `
        mutation DeleteRole($id: ID!, $mpid: String!) {
			deleteRole(id: $id, mpid: $mpid) {
				id
			}
        }
    `;

	return await $GQL.request(mutation, { id, mpid });
}
