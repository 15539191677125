import { $GQL } from '@GQL/index';
import type { JobConfig } from '@nextgenleads/job-driver';

export async function insertJobConfig(data: Omit<JobConfig, 'created_at'>) {
	const mutation = `
        mutation InsertJobConfig($data: JSONObject!) {
            insertJobConfig(data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertJobConfig;
}
