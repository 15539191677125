import { $GQL } from '@GQL/index';

/**
 * Returns all the marketplaces, works with the list vue which should do front end filtering
 */
export async function getJobConfigs() {
	const query = `
        query GetJobConfigs {
            jobConfigs {
                id
                name
				description
                k8s_job_name
                schedule
                script_name
                script_type
                status
            }
        }
    `;

	const results = await $GQL.request(query);
	return results.jobConfigs;
}
