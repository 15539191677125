// --------------------------------------------------
// FILTER: sourceHash
// Hash a source
// --------------------------------------------------

// Import dependencies
import shortHash from 'shorthash2';

export function sourceHash(source_id: string) {
	return shortHash(source_id);
}
