<template>
	<div class="qualifier source-tags">
		<div class="title">
			<div class="name">Tags</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">
			Disqualify a campaigns with one of the specified support statuses if the lead contains one of the specified tags.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Support Statuses:</label>
					<div class="controls gap-20">
						<div class="field">
							<p-chips add-on-blur v-model="modelValue.statuses" separator="," />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Tags:</label>
					<div class="controls gap-20">
						<div class="field">
							<p-chips add-on-blur v-model="modelValue.tags" separator="," />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pChips from 'primevue/chips';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'SourceTagsQualifier',
	emits: ['remove'],
	components: {
		pChips,
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					statuses: ['new', 'threshold_pending'],
					tags: [],
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
