import { $GQL } from '@GQL/index';

export async function transferPhoneNumber(phone: string, from: string, to: string): Promise<String | null> {
	const mutation = `
        mutation TransferPhoneNumber($phone: String!, $from: String!, $to: String!) {
            transferPhoneNumber(phone: $phone, from: $from, to: $to)
        }
    `;

	const result = await $GQL.request(mutation, { phone, from, to });
	return result.transferPhoneNumber;
}
