<template>
	<div id="vendors-settings" class="view-content">
		<div class="page-heading">
			<h1>Vendor Settings</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<p>Select which vendors and products you want accounts in your marketplace to receive.</p>
			<div class="flex gap-5">
				<p-card class="vendor-card flex-grow-1" v-for="vendor in vendors" :key="vendor.id">
					<template #header>
						<div class="padded border-bottom flex align-items-center">
							<div class="flex-grow-1">
								<strong>{{ vendor.name }}</strong>
							</div>
							<div><p-input-switch v-model="settings[vendor.id].enabled" /></div>
						</div>
					</template>
					<template #content>
						<div v-if="settings[vendor.id].enabled" class="verticals">
							<template v-for="vertical in vertical_options" :key="vendor.id + vertical.id">
								<div v-if="hasProductsForVertical(vendor.verticals[vertical.id])" class="vertical health-insurance">
									<div class="control flex align-items-center">
										<div class="flex-grow-1">
											<strong>{{ vertical.name }}</strong>
										</div>
										<div><p-input-switch v-model="settings[vendor.id].verticals[vertical.id].enabled" /></div>
									</div>
									<div v-if="settings[vendor.id].verticals[vertical.id].enabled" class="products border-top">
										<div v-if="vendor.verticals[vertical.id].data" class="product-row flex align-items-center">
											<div class="name flex-grow-1">Data Leads</div>
											<div>
												<p-input-switch v-model="settings[vendor.id].verticals[vertical.id].products.data" />
											</div>
										</div>
										<div v-if="vendor.verticals[vertical.id].call" class="product-row flex align-items-center">
											<div class="name flex-grow-1">Calls</div>
											<div>
												<p-input-switch v-model="settings[vendor.id].verticals[vertical.id].products.call" />
											</div>
										</div>
										<div v-if="vendor.verticals[vertical.id].live_transfer" class="product-row flex align-items-center">
											<div class="name flex-grow-1">Live Transfers</div>
											<div>
												<p-input-switch v-model="settings[vendor.id].verticals[vertical.id].products.live_transfer" />
											</div>
										</div>
										<div v-if="vendor.verticals[vertical.id].ad" class="product-row flex align-items-center">
											<div class="name flex-grow-1">Ads</div>
											<div>
												<p-input-switch v-model="settings[vendor.id].verticals[vertical.id].products.ad" />
											</div>
										</div>
									</div>
								</div>
							</template>
						</div>
						<div v-else class="disabled-vendor">
							<div class="message">Enable this vendor to select products</div>
						</div>
					</template>
					<template #footer>
						<div class="flex align-items-center">
							<div class="flex-grow-1">Vendor Settings</div>
							<div class="tool">
								<p-button v-tooltip.top="'Edit Vendor Settings'" @click="editVendorSettings(vendor.id)">
									<template #icon>
										<icon type="tune" size="24px" />
									</template>
								</p-button>
							</div>
						</div>
					</template>
				</p-card>
			</div>
			<p-dialog
				id="vendor_setting_modal"
				v-model:visible="show_modal"
				:style="{ width: '760px', maxWidth: '90vw' }"
				:modal="true"
			>
				<template #header>
					<div class="flex gap-2 justify-content-start align-items-center">
						<div>
							<icon type="tune" size="24px" />
						</div>
						<div>
							<strong>Edit Settings for {{ selected_vendor.name }}</strong>
						</div>
					</div>
				</template>
				<p-message :closable="false">No settings are available for this vendor.</p-message>
				<template #footer>
					<div class="flex gap-2 justify-content-end align-items-center">
						<p-button text label="Cancel" @click="closeVendorSettingsModal" />
						<p-button icon="pi pi-check" label="Save Vendor Settings" :loading="saving" @click="saveVendorSettings" />
					</div>
				</template>
			</p-dialog>
		</div>
		<div class="actions">
			<p-button :loading="saving" icon="pi pi-check" label="Save Vendor Settings" @click="saveSettings" />
		</div>
	</div>
</template>

<script>
import { cloneDeep, defaultsDeep, isEmpty, isEqual, merge } from 'lodash-es';
import { initVendorSettings, updateSetting } from '@GQL';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';

// If available vendor settings change, make the updates here
const default_vendor_settings = {};

export default {
	name: 'VendorSettings',
	components: {
		pDialog,
		pMessage,
	},
	data() {
		return {
			loading: false,
			saving: false,
			vertical_options: [
				{ name: 'Health Insurance', id: 'health_insurance' },
				{ name: 'Medicare', id: 'medicare' },
			],
			vendors: [],
			settings: {},
			selected_vendor: {},
			selected_vendor_settings: default_vendor_settings,
			show_modal: false,
			breadcrumb_items: [],
		};
	},
	computed: {
		filteredVendors() {
			return this.vendors.filter((vendor) => {
				return vendor.status === 'enabled';
			});
		},
		...mapStores(useMarketplaceStore),
	},
	methods: {
		hasProductsForVertical(vendor_settings) {
			return Object.values(vendor_settings).includes(true);
		},
		editVendorSettings(vendor_id) {
			this.selected_vendor_settings = default_vendor_settings;
			this.selected_vendor = this.vendors.find((vendor) => {
				return vendor.id === vendor_id;
			});
			if (this.selected_vendor) {
				this.selected_vendor_settings = defaultsDeep(this.settings[vendor_id].settings, default_vendor_settings);
			}
			this.show_modal = true;
		},
		closeVendorSettingsModal() {
			this.show_modal = false;
			this.selected_vendor = {};
			this.selected_vendor_settings = {};
		},
		async saveVendorSettings() {
			this.settings[this.selected_vendor.id].settings = cloneDeep(this.selected_vendor_settings);
			try {
				this.saving = true;
				const save_result = await updateSetting('vendors', { data: this.settings }, this.marketplaceStore.mpid);
				this.$toast.add({
					severity: 'success',
					summary: 'Vendor settings saved!',
					life: 3000,
				});
				this.show_modal = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save the vendor settings!',
					detail: err.response.errors[0].message,
					life: 5000,
				});
			} finally {
				this.saving = false;
			}
		},
		async saveSettings() {
			try {
				this.saving = true;
				const save_result = await updateSetting('vendors', { data: this.settings }, this.marketplaceStore.mpid);
				this.$toast.add({
					severity: 'success',
					summary: 'Vendor settings saved',
					life: 3000,
				});
				this.show_modal = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save the vendor settings',
					detail: err.response.errors[0]?.message,
					life: 5000,
				});
			} finally {
				this.saving = false;
			}
		},
	},
	async mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Vendor Settings',
				},
			];
		} else {
			this.breadcrumb_items = [
				{
					label: 'Vendor Settings',
				},
			];
		}
		this.loading = true;
		try {
			const result = await initVendorSettings(this.marketplaceStore.mpid);
			this.vendors = result.vendors;

			// Sync the vendor settings with available vendors
			const default_settings = {
				enabled: false,
				settings: {},
				verticals: {
					health_insurance: {
						enabled: false,
						products: {
							data: false,
							calls: false,
							live_transfers: false,
							ads: false,
						},
					},
					medicare: {
						enabled: false,
						products: {
							data: false,
							calls: false,
							live_transfers: false,
							ads: false,
						},
					},
				},
			};

			const settings = {};
			this.vendors.forEach((vendor) => {
				settings[vendor.id] = cloneDeep(default_settings);
			});

			if (result.settings.vendors) {
				this.settings = merge(settings, result.settings.vendors);
			} else {
				this.settings = settings;
			}

			this.loading = false;
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to get vendor list',
				lifetime: 3000,
			});
		}
	},
};
</script>

<style scoped lang="less">
:deep(.p-inputswitch) {
	margin-right: 0;
}

.vendor-card {
	display: flex;
	flex-direction: column;
	max-width: 350px;
	min-width: 300px;

	.vertical {
		border: 1px solid var(--gray-15);
		border-radius: 5px;
		margin-bottom: 20px;

		.control,
		.products {
			padding: 10px 20px;
		}

		.product-row {
			border-bottom: 1px dashed var(--gray-15);
			color: var(--gray-65);
			height: 2.5em;

			&:last-of-type {
				border-bottom: 0;
			}
		}
	}
}

:deep(.p-card-body) {
	display: flex;
	flex: 1 0;
	flex-direction: column;
	padding: 20px;

	.p-card-content {
		flex: 1 0;
	}

	.p-card-footer {
		border-top: 1px solid var(--gray-10);
		margin: 0 -20px -20px;
		padding: 20px;
	}
}

.actions {
	background-color: white;
	bottom: 0;
	left: 0;
	padding: 20px;
	position: absolute;
	right: 0;
	z-index: 9999999;
}
</style>
