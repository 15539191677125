<template>
	<iframe id="reporting-embed" src="https://reporting.nextgenleads.app" frameborder="0"></iframe>
</template>

<script lang="ts">
export default {
	name: 'ReportingWrapper',
};
</script>

<style lang="less" scoped>
#reporting-embed {
	height: 100%;
}
</style>
