<template>
	<div class="build-step normalize-source">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Normalize Source</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">Properly format and sanitize the source information in the lead.</div>
	</div>
</template>

<script>
export default {
	name: 'NormalizeSource',
	emits: ['remove'],
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
