<template>
	<div id="ads-list" class="view-content">
		<div class="page-heading">
			<h1>Premium Listings Approvals</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table ref="dt" scrollable :value="visibleAds" :loading="loading">
							<template #empty>
								<div class="dim">No premium listings need approval</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="account_id" header="Account Info">
								<template #body="row">
									{{ row.data.account.name }}<br />
									<div class="sub-value">{{ row.data.account.id }}</div>
								</template>
							</p-column>
							<p-column field="name" header="Ad Details">
								<template #body="row">
									{{ row.data.name }}<br />
									<div class="sub-value">{{ capitalize(row.data.type) }}</div>
								</template>
							</p-column>
							<p-column field="vertical_id" header="Vertical" class="column-align-center">
								<template #body="row">
									<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
								</template>
							</p-column>
							<p-column field="template_id" header="Template">
								<template #body="row">
									<template v-if="row.template">
										{{ row.template.name }}<br />
										<div class="sub-value">{{ row.template_id }}</div>
									</template>
									<template v-else> Best Performing </template>
								</template>
							</p-column>
							<p-column field="created_at" header="Created On">
								<template #body="row">
									{{ formatDate(row.data.created_at, "YYYY-MM-DD 'at' h:mm:ssa ") }}<br />
									<div class="sub-value">{{ timeAgo(row.data.created_at) }}</div>
								</template>
							</p-column>
							<p-column field="approval_status" header="Approval" class="column-align-center">
								<template #body="row">
									<icon
										v-if="row.data.approval_status === 'approved'"
										style="color: var(--green)"
										type="check"
										size="24px"
									/>
									<icon
										v-if="row.data.approval_status === 'rejected'"
										style="color: var(--red)"
										type="cancel"
										size="24px"
										v-tooltip="row.data.rejected_reason"
									/>
									<icon
										v-if="row.data.approval_status === 'pending_approval'"
										style="color: var(--gray-35)"
										type="timer-sand"
										size="24px"
									/>
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Preview and Approve'"
												aria-label="Preview and Approve"
												@click="previewAd(row.data)"
											>
												<template #icon>
													<icon type="check-decagram" size="20px" />
												</template>
											</p-button>
										</div>
									</div>
								</template>
							</p-column>
						</p-data-table>
					</div>
				</div>
			</row>
		</div>
		<p-dialog ref="approval_modal" :style="{ width: '90vw' }" v-model:visible="show_approval_modal">
			<template #header>
				<h4>Approval for {{ selected_ad.name }}</h4>
			</template>
			<div class="text-align-center">
				<p-select-button
					v-model="preview_width"
					:options="responsive_options"
					option-label="label"
					option-value="value"
				/>
			</div>
			<gutter size="20px" />
			<div class="responsive-wrapper">
				<div :class="['ad-preview', preview_width]" v-html="ad_preview"></div>
			</div>
			<p-fieldset legend="Approval Status">
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<label class="control-label">Approval Status:</label>
								<p-dropdown
									v-model="approval_status"
									:options="approval_status_options"
									option-label="label"
									option-value="value"
								/>
							</div>
							<template>
								<gutter size="20px" />
								<div class="field">
									<label class="control-label">Rejected Reason:</label>
									<p-input-text v-model="rejected_reason" />
								</div>
							</template>
						</div>
					</div>
				</div>
			</p-fieldset>
			<template #footer>
				<div class="flex align-items-center justify-content-end">
					<p-button label="Cancel" text @click="show_approval_modal = false" />
					<gutter size="10px" />
					<p-button label="Save Approval" @click="updateAdApproval()" />
				</div>
			</template>
		</p-dialog>
	</div>
</template>

<script>
import { cloneDeep, get } from 'lodash-es';
import { capitalize, formatDate, timeAgo } from '@/lib/Filters';
import { getAdForApprovals, getParsedAdContent, updateAd } from '@GQL';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';
import pDialog from 'primevue/dialog';

export default {
	name: 'AdListView',
	components: {
		pDialog,
		verticalIcon,
	},
	data() {
		return {
			breadcrumb_items: [],
			ad_preview: null,
			approval_status: null,
			approval_status_options: [
				{ label: 'Pending Approval', value: 'pending_approval' },
				{ label: 'Approved', value: 'approved' },
				{ label: 'Rejected', value: 'rejected' },
			],
			ads: [],
			loading: false,
			preview_width: 'desktop',
			rejected_reason: null,
			responsive_options: [
				{ label: 'Desktop', value: 'desktop' },
				{ label: 'Tablet', value: 'tablet' },
				{ label: 'Mobile', value: 'mobile' },
			],
			selected_ad: {},
			show_approval_modal: false,
			show_archived: false,
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		visibleAds() {
			const ads = this.ads.filter((ad) => {
				return ad.account.mpid === this.mpid;
			});
			if (this.show_archived) {
				return ads;
			}
			return ads.filter((ad) => {
				return ad.status !== 'archived';
			});
		},
		...mapStores(useMarketplaceStore),
	},
	methods: {
		capitalize,
		formatDate,
		timeAgo,
		async getAdForApprovals(refresh = false) {
			this.loading = true;
			const no_cache = [];
			if (refresh) {
				no_cache.push('ads');
			}

			const ads = await getAdForApprovals(
				`type = 'premium_listing' AND approval_status = 'pending_approval'`,
				no_cache
			);
			this.ads = ads;
			this.loading = false;
		},
		async previewAd(ad) {
			this.preview_mode = 'mobile';
			this.selected_ad = cloneDeep(ad);
			this.ad_preview = await getParsedAdContent(ad.id);
			this.approval_status = ad.approval_status || 'pending_approval';
			this.rejected_reason = ad.rejected_reason || '';
			this.show_approval_modal = true;
		},
		async updateAdApproval(approval_status) {
			const updates = {
				approval_status: this.approval_status,
				approved_by: this.$root.sessionStore.user.id,
			};

			if (this.approval_status === 'rejected') {
				updates.rejected_reason = this.rejected_reason;
			}

			try {
				const updateResult = await updateAd(this.selected_ad.id, updates);
				if (updateResult.id) {
					this.$toast.add({
						severity: 'success',
						summary: 'The approval for the premium listing was updated',
						life: 3000,
					});
				}
				this.show_approval_modal = false;
				await this.getAdForApprovals(true);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to change approval status of the selected premium listing',
					detail: err.response.errors[0].message,
					life: 8000,
				});
			}
		},
	},
	async mounted() {
		await this.getAdForApprovals();
		if (this.$route.params.mpid) {
			this.breadcrumb_items.push(
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Premium Listing Approvals',
				}
			);
		} else {
			this.breadcrumb_items.push({
				label: 'Premium Listing Approvals',
			});
		}
	},
};
</script>

<style scoped lang="less">
.responsive-wrapper {
	background-color: var(--gray-10);
	padding: 20px;
	width: 100%;

	.ad-preview {
		background-color: white;
		border-radius: 5px;
		box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
		padding: 20px;
		margin: 0 auto;
	}

	.desktop {
		width: 100%;
	}

	.tablet {
		width: 768px;
	}

	.mobile {
		width: 480px;
	}
}
</style>
