import { $GQL } from '@GQL';
import type { ShipperTemplate } from '@nextgenleads/shipper-driver';

export async function updateShipperTemplate(id: string, data: Partial<ShipperTemplate>): Promise<{ id: string }> {
	const mutation = `
		mutation UpdateShipperTemplate($id: ID!, $data: JSONObject!) {
			updateShipperTemplate(id: $id, data: $data) {
				id
			}
		}
	`;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateShipperTemplate;
}
