import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function getLeadsForLTW(params: GetListParams) {
	const query = `
        query GetLeadList($params: GetListParams!) {
        	leadList(params: $params) {
				rows {
					last_name
					first_name
					id
					city
					state
					phone
					vendor_lead_id
					vertical_id
					created_at
				}
        	}
        }
    `;

	const result = await $GQL.request(query, { params });
	return result.leadList;
}
