<template>
	<div class="build-step send-to-auction">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Process Auction Logs</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">Create and ship purchases for winning bids in an auction.</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'processAuctionLogs',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
