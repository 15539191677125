import { $GQL } from '@GQL/index';

export async function migrateAccountData(params: {
	company_id: string;
	mpid: string;
	dry_run?: boolean;
	parent_account_id?: string;
}) {
	const mutation = `
        mutation MigrateAccountData($params: JSONObject!) {
            migrateAccountData(params: $params) {
                company_id
				v4_account_id
                parent_account_id
                v4_id_map
                mpid
                data
                dry_run
                errors
                todos
                accounts_migrated
                users_migrated
                users_found
                shippers_migrated
                shippers_found
                premium_listings_migrated
                premium_listings_found
                campaigns_migrated
                campaigns_found
                payment_methods_migrated
                payment_methods_found
            }
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.migrateAccountData;
}
