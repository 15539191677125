<template>
	<div class="lt-question">
		<div class="lt-question-heading">
			<div class="lt-question-icon">
				<icon type="comment-text-outline" size="24px" />
			</div>
			<div class="lt-question-body">
				<p v-if="has_medicare_parts_a_b === null">
					Have you enrolled in Medicare Parts A & B and received your Red, White and Blue card?
				</p>
				<p v-else>
					<template v-if="has_medicare_parts_a_b === true">
						Can you confirm that you are enrolled in both Medicare Parts A and B?
					</template>
					<template v-else>
						Can you confirm that you are NOT enrolled in both Medicare Parts A and B or are just enrolled in Medicare
						Part A?
					</template>
				</p>
			</div>
		</div>
		<div class="lt-question-content">
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-10" style="margin-bottom: 0">
						<div class="field fit">
							<p-radio-button input-id="has_medicare_parts_a_b_true" v-model="has_medicare_parts_a_b" :value="true" />
						</div>
						<div class="field">
							<label for="has_medicare_parts_a_b_true" class="radio-label">
								Yes, the lead is enrolled in both Medicare Parts A and B
							</label>
						</div>
					</div>
					<div class="controls gap-10">
						<div class="field fit">
							<p-radio-button input-id="has_medicare_parts_a_b_false" v-model="has_medicare_parts_a_b" :value="false" />
						</div>
						<div class="field">
							<label for="has_medicare_parts_a_b_false" class="radio-label">
								No, the lead is NOT enrolled in either or only in Medicare Part A
							</label>
						</div>
					</div>
				</div>

				<div v-if="v$.has_medicare_parts_a_b.$error" class="validation-error">
					{{ v$.has_medicare_parts_a_b.$errors[0].$message }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from 'lodash-es';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

// reqyured

export default {
	name: 'LTQuestion_Enrollment',
	emits: ['update'],
	props: {
		lead: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			// enrollment_options: [
			//   { label: 'Yes, the lead is enrolled in both Medicare Parts A and B', value: true },
			//   { label: 'No, the lead is NOT enrolled in either or only in Medicare Part A', value: false },
			// ]
		};
	},
	computed: {
		has_medicare_parts_a_b: {
			get() {
				return get(this.lead, 'data.has_medicare_parts_a_b', null);
			},
			set(new_value) {
				this.$emit('update', {
					data: {
						has_medicare_parts_a_b: new_value,
					},
				});
			},
		},
	},
	validations() {
		return {
			has_medicare_parts_a_b: {
				required: helpers.withMessage('This question is required', required),
			},
		};
	},
};
</script>
