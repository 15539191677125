<template>
	<div class="modifier discount">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Discount (Filter)</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">Discount the effective bid if the lead matches the customer filter.</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field xs">
							<label class="control-label">Bid Multiplier:</label>
							<input-percentage v-model="modelValue.bid_multiplier" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Custom Filter:</label>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse.lazy="modelValue.match" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import codeEditor from '@/components/forms/CodeEditor.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import inputPercentage from '@/components/forms/InputPercentage.vue';

export default {
	name: 'DiscountModifier',
	emits: ['remove'],
	components: {
		codeEditor,
		deleteAction,
		inputPercentage,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					bid_multiplier: 0.85,
					match: [
						{
							target: {
								path: '',
								transformer: null,
							},
							strategy: 'equals',
							comparator: {
								value: '',
								path: null,
								transformer: null,
								regex_flags: null,
							},
							match_missing: false,
							invert: false,
						},
					],
				};
			},
		},
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
