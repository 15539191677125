<template>
	<div id="admin-transfer-form">
		<AddFundsModal ref="add_funds_modal" :account_id="get(sender_account, 'id', '')" @refresh="confirmTransfers" />
		<p-dialog
			v-model:visible="show_result_modal"
			:modal="true"
			:dismissble-mask="true"
			style="width: 680px"
			@hide="closeResults"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="cash" size="24px" style="color: var(--color-b)" />
					<strong>Transfer Results</strong>
				</div>
			</template>
			<div>
				<p-data-table ref="dt" :loading="loading" :rows="10" scrollable :value="combined_results">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="" header="Account">
						<template #body="row">
							{{ row.data.name }}
							<div class="sub-value">{{ row.data.account_id }}</div>
						</template>
					</p-column>
					<p-column field="" header="Amount">
						<template #body="row">
							<div :class="{ red: row.data.amount < 0, green: row.data.amount > 0 }">
								{{ currency(row.data.amount) }}
							</div>
						</template>
					</p-column>
					<p-column field="" header="New Balance">
						<template #body="row">
							{{ currency(row.data.final_balance) }}
						</template>
					</p-column>
				</p-data-table>
			</div>
			<template #footer>
				<div class="flex justify-content-end">
					<p-button label="Close" text @click="closeResults" />
				</div>
			</template>
		</p-dialog>

		<p-confirm-dialog />
		<loader :show="transfer_loading || loading" />
		<div class="page-content">
			<row gap="20px">
				<column :span="3" class="card padded flex flex-column">
					<div class="flex-1">
						<h2>Step 1: Select Sender Account</h2>
						<p>
							Select the account that will be sending the funds. If the funds exceed the transfer amount, you may fund
							the transfers with the payment methods for this account.
						</p>
						<row class="mb-2">
							<column>
								<div class="control-group">
									<div class="inner">
										<label class="control-label">Transfer funds <strong>FROM</strong> this account:</label>
										<div class="controls">
											<div class="field">
												<p-auto-complete
													id="sender-account"
													v-model="sender_account"
													:suggestions="parent_suggestions"
													placeholder="Search company name or account id"
													optionLabel="label"
													dropdown
													@complete="searchParents"
												>
													<template #option="slotProps">
														<div class="flex align-options-center">
															<div>
																{{ slotProps.option.name }} - {{ slotProps.option.primary_user.first_name }}
																{{ slotProps.option.primary_user.last_name }}
																<div class="sub-value">
																	{{ slotProps.option.id }}
																</div>
															</div>
														</div>
													</template>
												</p-auto-complete>
											</div>
										</div>
									</div>
								</div>
							</column>
						</row>
						<gutter size="10px" />
						<h6 v-if="sender_account !== null">
							Current Balance: <strong class="green">{{ currency(sender_balance) }}</strong>
						</h6>
						<gutter size="40px" />

						<row class="flex flex-column">
							<h2>Step 2: Select Recipients</h2>
							<p>
								Select account(s) and amount of funds you wish to transfer. You can look up accounts or upload a file
								with the accounts to receive funds.
							</p>
							<p-fieldset legend="Add Transfer(s)" style="width: 100%">
								<div class="control-group large">
									<div class="inner">
										<div class="controls">
											<div class="field">
												<label class="control-label" for="transfer-account"
													>Transfer funds <strong>TO</strong> this account:</label
												>
												<p-auto-complete
													id="transfer-account"
													v-model="recipient_account"
													:suggestions="child_suggestions"
													placeholder="Search company name or account id"
													optionLabel="label"
													dropdown
													:disabled="sender_account === null"
													@complete="searchChildren"
												>
													<template #option="slotProps">
														<div class="flex align-options-center">
															<div>
																{{ slotProps.option.name }} - {{ slotProps.option.primary_user.first_name }}
																{{ slotProps.option.primary_user.last_name }}
																<div class="sub-value">
																	{{ slotProps.option.id }} {{ slotProps.option.relationship || '' }}
																</div>
															</div>
														</div>
													</template>
												</p-auto-complete>
											</div>

											<gutter size="10px" />
											<div class="field transfer-holder">
												<label class="control-label">Transfer Amount:</label>
												<p-input-currency
													:allowEmpty="false"
													:min="0.01"
													:disabled="sender_account === null"
													v-model="transfer_amount"
												/>
											</div>
											<gutter size="10px" />
											<div class="field button-holder">
												<label class="control-label">&nbsp</label>
												<!--                        <p-button label="Queue Transfer" @click="addTransfer" />-->
												<p-button v-tooltip.top="'Add Transfer'" @click="addTransfer">
													<template #icon>
														<icon type="plus" size="20px" />
													</template>
												</p-button>
											</div>
										</div>
									</div>

									<gutter size="3px" />
									<p class="text-red-400" v-if="senderIsMP">
										Since you could be sending transfer too all accounts the auto complete feature only works after
										entering 8 characters, and the search is limited to only Account Name and Account ID
									</p>
								</div>

								<div class="">
									<p>Need to upload many transfers all at once? Use the Batch Transfer Function</p>
									<TransferCSVUploader
										:sender_account="sender_account"
										:is_mp="senderIsMP"
										:recipients_list="child_accounts"
										@addTransfers="handleBatchTransfers"
									/>
								</div>
							</p-fieldset>
						</row>
					</div>
				</column>
				<gutter size="10px" />
				<column :span="4" class="card padded flex flex-column">
					<h2>Step 3: Review Transfers</h2>
					<p-fieldset legend="Prepared Transfers">
						<div v-if="transfer_transactions.length === 0">No Transfers Added to List</div>
						<div
							v-for="transfer in transfer_transactions"
							class="flex align-items-center justify-content-between gap-2 transfer"
						>
							<div class="account">{{ transfer.label }}</div>
							<div class="flex">
								<div class="amount flex align-items-center">{{ currency(transfer.amount) }}</div>
								<div class="ml-auto">
									<p-button
										@click="removeTransfer(transfer.id)"
										icon="pi pi-trash"
										severity="danger"
										text
										aria-label="Remove"
									/>
								</div>
							</div>
						</div>
					</p-fieldset>
					<gutter size="20px" />
					<div class="flex-1">
						<ul class="table-list">
							<div class="heading">Transfer Summary</div>
							<li>
								<div class="label">Current Account Balance:</div>
								<div class="value">
									{{ currency(sender_balance) }}
								</div>
							</li>
							<li>
								<label class="label">Total to Transfer:</label>
								<div class="value">
									{{ currency(totalTransferAmount) }}
								</div>
							</li>
							<li v-if="depositsNeeded === 0">
								<label class="label">Balance After Transactions:</label>
								<div class="value">
									<span class="green">
										{{ currency(this.sender_balance - this.totalTransferAmount) }}
									</span>
								</div>
							</li>
							<li v-else>
								<label class="label">Additional Funds Needed:</label>
								<div class="value">
									<span :class="{ red: this.sender_balance < this.totalTransferAmount }">
										{{ depositsNeeded === 0 ? 'None' : currency(depositsNeeded) }}
									</span>
								</div>
							</li>
						</ul>

						<gutter size="20px" />
						<div>
							<h2>Step 4: Choose Payment Mode</h2>
							<p>
								You can choose to use the account's remaining balance or just create a single new deposit for the total
								transfers.
							</p>
							<label>Use remaining balance?</label>
							<gutter size="10px" />
							<p-select-button
								v-model="use_balance"
								optionLabel="label"
								optionValue="value"
								:options="balance_options"
							/>
						</div>
						<gutter size="20px" />
					</div>

					<div class="footer flex justify-content-end">
						<p-button
							@click="openMemoModal"
							:label="depositsNeeded > 0 ? 'Make Deposit + Transfer' : 'Finalize Transfers'"
						/>
					</div>
				</column>
			</row>
		</div>

		<p-dialog v-model:visible="show_memo_modal" :modal="true" :dismissble-mask="true" style="width: 480px">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="cash" size="24px" style="color: var(--color-b)" />
					<strong>Add Memo</strong>
				</div>
			</template>
			<div>
				<p>Add a memo here that will be saved in the transfer record</p>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Memo:</label>
						<div class="controls">
							<div class="field">
								<div class="field">
									<p-text-area v-model="memo" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<template #footer>
				<div class="flex justify-content-end">
					<p-button
						@click="finalizeTransfers"
						:label="depositsNeeded > 0 ? 'Make Deposit + Transfer' : 'Finalize Transfers'"
					/>
				</div>
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
/**
 * This page is meant to list all the accounts to which this current "account" can transfer to
 * will have a dialog csv upload too from this page as well
 * TODO - make the Hide Last Transactions label look like a clickable thing
 * - make space between the transaction tables
 */

import pSelectButton from 'primevue/selectbutton';
import pDialog from 'primevue/dialog';
import pAutoComplete from 'primevue/autocomplete';
import { sumBy, findIndex, find, capitalize, get } from 'lodash-es';
import { transferFunds, getAccountsForTransfers, getBalances, getTransactionsList } from '@GQL';
import { currency, formatDate } from '@/lib/Filters';
import AddFundsModal from '@/views/AddFunds/Modal.vue';
import pTextArea from 'primevue/textarea';
import pConfirmDialog from 'primevue/confirmdialog';
import TransferCSVUploader from './TransferCSVUploader.vue';

export default {
	name: 'AdminTransferToolPage',
	components: {
		pDialog,
		pSelectButton,
		pAutoComplete,
		AddFundsModal,
		pTextArea,
		pConfirmDialog,
		TransferCSVUploader,
	},
	data() {
		return {
			batch_transactions: '',
			transfer_loading: false,
			loading: false,
			loading_history: false,
			show_detailed_history: false,
			show_batch_modal: false,
			use_balance: true,
			show_result_modal: false,
			balance_options: [
				{
					value: true,
					label: 'Use balance as needed',
				},
				{
					value: false,
					label: 'Create new deposits',
				},
			],
			combined_results: [],
			parent_accounts: [],
			parent_suggestions: [],
			child_suggestions: [],
			transfer_transactions: [],
			transfer_amount: 0.0,
			breadcrumbs: [],
			recipient_account: null,
			sender_account: null,
			sender_balance: 0,
			sender_history: [],
			recipient_history: [],
			transfer_history: [],
			child_accounts: [],
			memo: '',
			show_memo_modal: false,
		};
	},
	computed: {
		childAccountIds() {
			return this.child_accounts.map((row) => {
				return row.id;
			});
		},
		mpid() {
			if ('mpid' in this.$route.params) {
				return this.$route.params.mpid;
			} else {
				return this.$root.sessionStore.account.mpid;
			}
		},
		totalTransferAmount() {
			return sumBy(this.transfer_transactions, 'amount');
		},
		depositsNeeded() {
			if (this.use_balance) {
				if (this.sender_balance < this.totalTransferAmount) {
					return this.totalTransferAmount - this.sender_balance;
				} else {
					return 0;
				}
			} else {
				return this.totalTransferAmount;
			}
		},
		senderLoaded() {
			return this.sender_account !== null;
		},
		senderIsMP() {
			return this.sender_account !== null && this.sender_account.id === `${this.mpid}-000-000`;
		},
	},
	watch: {
		async sender_account(new_value, old_value) {
			this.loading = true;
			if (new_value && typeof new_value !== 'string' && 'id' in new_value) {
				try {
					// if this changes, then we must clean out the pending transaction
					this.transfer_transactions.splice(0, this.transfer_transactions.length);
					const balances = await getBalances(new_value.id);
					this.sender_balance = balances.actual;

					// we load in the child accounts
					if (!this.senderIsMP && new_value.is_parent) {
						await this.getChildAccounts(new_value.id);
					}
				} finally {
					this.loading = false;
				}
			}
		},
	},

	async created() {
		this.loading = true;
		// when created load in the parent accounts, no balances...
		try {
			await this.getParentAccounts();
		} catch (err) {
		} finally {
			this.$nextTick(() => {
				this.loading = false;
			});
		}
	},
	methods: {
		get,
		capitalize,
		formatDate,
		openMemoModal() {
			this.memo = '';
			this.show_memo_modal = true;
		},
		async handleBatchTransfers(data) {
			this.loading = true;
			try {
				if (this.senderIsMP) {
					const account_ids = data
						.map((row) => {
							return `'${row.account_id}'`;
						})
						.join(', ');
					// we must add to the child accounts array
					const account_labels = await getAccountsForTransfers([[`id in (${account_ids})`]]);

					data = data.map((row) => {
						const found = find(account_labels, { id: row.account_id });
						if (found) {
							row.label = `[${found.id}] ${found.name} - ${found.primary_user.first_name} ${found.primary_user.last_name}`;
							row.name = found.name;
						} else {
							row.label = row.account_id;
							row.name = found.account_id;
						}
						this.child_accounts.push(row);
						return row;
					});
				}

				data.forEach((row) => {
					const positive_amount = row.amount < 0 ? row.amount * -1 : row.amount;

					this.transfer_transactions.push({
						label: row.label,
						id: row.account_id,
						amount: positive_amount,
					});
				});
			} catch (err) {
			} finally {
				this.loading = false;
			}
		},
		async closeResults() {
			// reset everything, then close the modal
			try {
				this.transfer_transactions = [];
				this.sender_history = [];
				this.recipient_history = [];
				this.transfer_history = [];
				this.combined_results = [];
				//
				this.sender_balance = (await getBalances(this.sender_account.id)).actual;
			} finally {
				this.show_result_modal = false;
			}
		},
		currency,
		async searchParents(event) {
			const to_match = new RegExp(event.query.toLowerCase());

			if (event.query.length >= 8) {
				// we do a parent search
				this.parent_accounts = await this.getAccountsBySearch(event.query.toLowerCase());
				this.parent_suggestions = this.parent_accounts;
			} else {
				const to_match = new RegExp(event.query.toLowerCase());
				const filtered = this.parent_accounts.filter((row) => {
					return (
						(to_match.test(row.label.toLowerCase()) || to_match.test(row.id)) &&
						row.id !== get(this.sender_account, 'id', '')
					);
				});
				this.parent_suggestions = filtered;
			}

			// const filtered = this.parent_accounts.filter((row) => {
			// 	return (
			// 		(to_match.test(row.label.toLowerCase()) || to_match.test(row.id)) &&
			// 		row.id !== get(this.sender_account, 'id', '')
			// 	);
			// });
			// this.parent_suggestions = filtered;
		},
		async searchChildren(event) {
			if (event.query.length >= 8) {
				// we do a child search
				this.child_accounts = await this.getAccountsBySearch(event.query.toLowerCase());
				this.child_suggestions = this.child_accounts.filter((row) => {
					return row.id !== this.sender_account.id;
				});
			} else {
				const to_match = new RegExp(event.query.toLowerCase());
				const filtered = this.child_accounts.filter((row) => {
					return (
						(to_match.test(row.label.toLowerCase()) || to_match.test(row.id)) &&
						row.id !== get(this.sender_account, 'id', '')
					);
				});
				this.child_suggestions = filtered;
			}
		},
		addTransfer() {
			this.transfer_transactions.push({
				label: this.recipient_account.label,
				id: this.recipient_account.id,
				amount: this.transfer_amount,
			});
			this.recipient_account = null;
			this.transfer_amount = 0;
		},
		removeTransfer(id: string) {
			// slice it off thonso.,e. array
			const index = findIndex(this.transfer_transactions, (row) => {
				return row.id === id;
			});
			this.transfer_transactions.splice(index, 1);
		},
		confirmTransfers() {
			const message = `${this.currency(this.totalTransferAmount)} from ${this.sender_account.name} will be transferred to ${this.transfer_transactions.length} account(s)`;

			// do a quick check for balances....

			this.$confirm.require({
				header: 'Confirm Transfers',
				message,
				accept: async () => {
					const requests = this.transfer_transactions.map((row) => {
						return {
							sender_account_id: this.sender_account.id,
							receiver_account_id: row.id,
							amount: row.amount,
							user_id: this.$root.sessionStore.user.id,
						};
					});

					try {
						this.transfer_loading = true;
						const result = await transferFunds(requests, this.memo);
						const no_errors =
							result.filter((row) => {
								return 'sender_current_balance' in row || 'receiver_current_balance' in row;
							}).length === requests.length;

						if (result.length === requests.length && no_errors) {
							// we do something to show summary ?
							this.$toast.add({
								severity: 'success',
								summary: 'Transfer(s) were completed',
								life: 10000,
							});
							this.show_result_modal = true;
							const sender = this.sender_account;
							result.map((row, index) => {
								const req = requests[index];

								// we will not get

								const receiver = find(this.child_accounts, { id: req.receiver_account_id });
								// receiver
								this.combined_results.push({
									name: receiver.name,
									account_id: receiver.id,
									amount: req.amount,
									final_balance: row.receiver_current_balance,
								});

								// sender
								this.combined_results.push({
									name: sender.name,
									account_id: sender.id,
									amount: -1 * req.amount,
									final_balance: row.sender_current_balance,
								});
							});
						} else {
							this.$toast.add({
								severity: 'error',
								summary: 'Unable to complete one more of the transfers...',
								detail: 'See transaction logs to see which went through',
							});
						}
					} catch (err) {
						console.error(err, 'Transfer Issue');
						if (err.message.indexOf('Insufficient Funds') > -1) {
							this.sender_balance = (await getBalances(requests[0].sender_account_id)).actual;
							this.$toast.add({
								severity: 'error',
								summary: 'Insufficient Funds',
								detail: 'Balance of Sender Account is no longer sufficient for this transaction',
							});
						} else {
							this.$toast.add({
								severity: 'error',
								summary: 'Unable to complete one more of the transfers',
								detail: 'Contact Account Manager for further assistance, some transactions may have been successful',
							});
						}
					} finally {
						this.transfer_loading = false;
					}
				},
				reject: () => {
					this.$toast.add({
						severity: 'info',
						summary: 'Cancel',
						detail: 'Transactions not initiated',
						life: 3000,
					});
				},
			});
		},
		async finalizeTransfers() {
			if (this.show_memo_modal) {
				this.show_memo_modal = false;
			}
			// if deposit is required first pull in the deposit modal....
			if (this.totalTransferAmount === 0) {
				this.$toast.add({
					severity: 'warn',
					summary: 'Add Transfers before proceeding',
					life: 6000,
				});
			} else if (this.depositsNeeded === 0) {
				this.confirmTransfers();
			} else {
				this.$refs.add_funds_modal.openAddFundsModal(this.depositsNeeded);
			}
		},
		async getAccountsBySearch(value: string) {
			const filters = [[`LOWER(name) LIKE '%${value.toLowerCase()}%'`], [`LOWER(id) LIKE '${value}%'`]];
			const result = await getAccountsForTransfers(filters);
			return result.map((row) => {
				row.label = `[${row.id}] ${row.name} - ${row.primary_user.first_name} ${row.primary_user.last_name}`;
				return row;
			});
		},
		async getChildAccounts(parent_account_id: string) {
			const account_filters = [`parent_account_id = '${parent_account_id}'`];

			// then lets grab the accounts
			try {
				const result = await getAccountsForTransfers([account_filters]);

				// now map the results into another parent_account_id query;
				const mapped_children = result
					.map((child) => {
						return `'${child.id}'`;
					})
					.join(', ');
				const grandchild_filter = `parent_account_id IN (${mapped_children})`;
				const gchild_accounts = (await getAccountsForTransfers([[grandchild_filter]])).map((row) => {
					row.label = `[${row.id}] ${row.name} - ${row.primary_user.first_name} ${row.primary_user.last_name}`;
					row.relationship = ' - Grand Child';
					return row;
				});

				const child_accounts = result.map((row) => {
					row.label = `[${row.id}] ${row.name} - ${row.primary_user.first_name} ${row.primary_user.last_name}`;
					row.relationship = ' - Child';
					return row;
				});

				this.child_accounts = [...child_accounts, ...gchild_accounts, ...this.parent_accounts].filter((row) => {
					// remove the sender account
					if (this.sender_account !== null) {
						return row.id !== this.sender_account.id;
					}
					return true;
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Accounts',
				});
			} finally {
			}
		},
		async getParentAccounts() {
			const account_filters = [`mpid = '${this.mpid}'`, `is_parent IS TRUE`];

			// then lets grab the accounts
			try {
				const result = await getAccountsForTransfers([[`id = '${this.mpid}-000-000'`], account_filters]);
				this.parent_accounts = result.map((row) => {
					row.label = `[${row.id}] ${row.name} - ${row.primary_user.first_name} ${row.primary_user.last_name}`;
					return row;
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Accounts',
				});
			} finally {
			}
		},
	},
};
</script>

<style lang="less" scoped>
.transfer {
	border: 1px solid var(--gray-30);
	border-radius: 4px;
	box-shadow: var(--shadow-a);
	margin-bottom: 0.5rem;
	padding: 0.25rem 1rem;

	.account {
		font-weight: bold;
	}

	.amount {
		color: var(--gray-50);
	}
}

.label {
	min-width: 200px;
}

.red {
	color: var(--red-500);
}
.green {
	color: var(--green-500);
}
.clickable {
	padding: 20px 0;
	text-decoration: underline;
	cursor: pointer;
}

.footer {
	border-top: 1px solid var(--gray-20);
	padding-top: 1rem;
}
.button-holder {
	max-width: 25px;
}
.transfer-holder {
	max-width: 150px;
}
</style>
