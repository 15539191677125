<template>
	<div id="shipper-template-detail" class="view-content">
		<div class="page-heading">
			<h1>Shipper Template: {{ shipper_template.name }}</h1>
			<breadcrumb :model="breadcrumb_model" />
		</div>
		<div class="page-content">
			<row>
				<column max-width="960px">
					<div class="card padded">
						<shipper-template-form :template="shipper_template" />
					</div>
				</column>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es';
import log from '@/lib/Log';
import { getShipperTemplateById } from '@GQL';
import ShipperTemplateForm from './Form.vue';

export default {
	name: 'ShipperTemplateDetail',
	components: {
		ShipperTemplateForm,
	},
	data() {
		return {
			breadcrumb_model: [
				{ label: 'Shipper Templates', route: '/shipper-templates' },
				{ label: this.shipper_template?.name || 'New Shipper Template' },
			],
			shipper_template: {
				id: null,
				name: '',
				type: null,
				label: '',
				settings: {},
				data_map: '',
				cancellations: {
					enabled: false,
					pattern: '',
				},
				status: 'active',
			},
		};
	},
	async mounted() {
		if (!this.isNew) {
			try {
				const result = await getShipperTemplateById(this.$route.params.shipper_template_id);
				this.shipper_template = cloneDeep(result.shipperTemplate);
			} catch (err) {
				log.error('err', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get template',
					detail: err.response.errors[0]?.message || err.message,
					life: 6000,
				});
			}
		} else {
			if (this.$route.query.duplicate) {
				const result = await getShipperTemplateById(this.$route.query.duplicate);
				this.shipper_template = cloneDeep(result.shipperTemplate);
				this.shipper_template.id = null;
				this.shipper_template.name += ' (Copy)';
				this.breadcrumb_items.push({ label: this.shipper_template.name });
			} else {
				this.breadcrumb_items.push({ label: 'New Shipper Template' });
			}
		}
	},
	computed: {
		isNew() {
			return this.$route.meta.new;
		},
	},
};
</script>

<style lang="less"></style>
