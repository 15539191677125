<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Sources Report: {{ $route.params.source_id }}</h1>
			<breadcrumb :model="breadcrumbs" />
		</div>
		<div class="tabs-wrapper">
			<div class="tabs">
				<div :class="['tab', { active: $route.name === 'SourceDetailByHour' }]">
					<router-link to="hour"><div class="label">Hourly Performance</div></router-link>
				</div>
				<div :class="['tab', { active: $route.name === 'SourceDetailByDay' }]">
					<router-link to="day"><div class="label">Daily Performance</div></router-link>
				</div>
				<div :class="['tab', { active: $route.name === 'SourceDetailBreakdown' }]">
					<router-link to="breakdown"><div class="label">Breakdown</div></router-link>
				</div>
				<div :class="['tab', { active: $route.name === 'SourceDetailBuyers' }]">
					<router-link to="buyers"><div class="label">Buyers</div></router-link>
				</div>
				<div :class="['tab', { active: $route.name === 'SourceDetailLeads' }]">
					<router-link to="leads"><div class="label">Leads</div></router-link>
				</div>
			</div>
			<div class="border"></div>
		</div>
		<div class="no-padding">
			<router-view />
		</div>
	</div>
</template>

<script>
export default {
	name: 'SourceDetailWrapper',
	components: {},
	data() {
		return {
			breadcrumbs: [
				{
					label: 'Sources Report',
					route: '/reports/sources',
				},
				{
					label: this.$route.params.source_id,
				},
			],
		};
	},
};
</script>

<style scoped lang="less">
.tabs-wrapper {
	margin: 0 -40px;
	position: relative;

	.border {
		border-bottom: 1px solid var(--gray-20);
		bottom: 0;
		position: absolute;
		width: 100%;
		z-index: 10;
	}
}

.tabs {
	display: flex;
	padding: 0 40px;
	position: relative;
	z-index: 20;

	.tab {
		background-color: var(--gray-10);
		border: 1px solid var(--gray-20);
		border-left: 0;
		cursor: default;
		font-size: 0.875rem;

		a {
			color: var(--gray-40);
			text-decoration: none;
		}

		.label {
			display: block;
			height: 3em;
			line-height: 3em;
			padding: 0 25px;
		}

		&:first-child {
			border-left: 1px solid var(--gray-20);
		}

		&:hover a {
			color: var(--gray-65);
		}

		&.active {
			border-bottom: 1px solid var(--gray-05);
			background-color: var(--gray-05);

			a {
				color: var(--gray-65);
			}

			.label {
				border-top: 3px solid var(--color-b);
				line-height: calc(3em - 6px);
			}
		}
	}
}

.no-padding {
	margin: 0 -40px;
}
</style>
