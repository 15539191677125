import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function reportLiveTransferHistory(params: GetListParams, refresh?: boolean) {
	const query = `
        query ReportLiveTransferHistory($params: JSONObject!) {
            reportLiveTransferHistory(params: $params) {
				rows
				row_count
				metrics
			}
        }
    `;

	const no_cache = refresh ? ['reportLiveTransferHistory'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportLiveTransferHistory;
}
