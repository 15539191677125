import { $GQL } from '@GQL/index';
import type { RegisterPaymentMethodParams } from '@nextgenleads/banker-driver';

export async function insertPaymentMethod(data: RegisterPaymentMethodParams) {
	const mutation = `
        mutation InsertPaymentMethod($data: JSONObject!) {
            registerPaymentMethod(data: $data) {
                status
                message
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.registerPaymentMethod;
}
