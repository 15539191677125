import { $GQL } from '@GQL/index';

export async function getCampaignStats(
	params: { campaign_id: string; date_range: string[] },
	no_cache: string[]
): Promise<any> {
	const query = `
        query GetCampaignStats($params: JSONObject!) {
            reportCampaignStats(params: $params)
        }
    `;

	const response = await $GQL.request(query, { params, no_cache });
	return response.reportCampaignStats;
}
