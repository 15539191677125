<template>
	<div class="channel-list">
		<p-button icon="pi pi-plus" label="Add Channel" @click.prevent="$router.push(`${$route.path}/new`)" />
		<gutter size="20px" />

		<div class="card padded">
			<p-data-table ref="dt" :loading="loading" :rows="10" scrollable :value="channels">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column field="product" header="Channel Name">
					<template #body="channel">
						<div class="name">
							<router-link :to="{ path: `${$route.path}/${channel.data.id}` }">{{ channel.data.name }}</router-link>
						</div>
						<div class="sub-value">{{ channel.data.description }}</div>
					</template>
				</p-column>
				<p-column class="align-center" field="id" header="Active Campaigns Targeting">
					<template #body="channel">
						{{ channel.data.campaignsTargeting }}
					</template>
				</p-column>
				<p-column class="align-center" field="vertical_id" header="Vertical">
					<template #body="channel">
						<vertical-icon :vertical-id="channel.data.vertical_id" size="30px" />
					</template>
				</p-column>
				<p-column class="align-center" field="product" header="Product">
					<template #body="channel">
						{{ startCase(channel.data.product) }}
					</template>
				</p-column>
				<p-column field="status" header="Status">
					<template #body="channel">
						{{ startCase(channel.data.status) }}
					</template>
				</p-column>
				<p-column header="Tools" class="align-center">
					<template #body="channel">
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Edit'"
									aria-label="Edit"
									@click="$router.push({ path: `${$route.path}/${channel.data.id}` })"
								>
									<template #icon>
										<icon type="square-edit-outline" size="20px" />
									</template>
								</p-button>
							</div>
							<div class="tool">
								<p-button v-tooltip.top="'Copy ID'" aria-label="CopyID" @click="copyToClip(channel.data.id)">
									<template #icon>
										<icon type="scissors-cutting" size="20px" />
									</template>
								</p-button>
							</div>
							<delete-action
								:message="`Are you sure you want to archive this channel? ${channel.data.campaignsTargeting} will be turned off.`"
								@delete="deleteChannel(channel.data.id)"
							/>
						</div>
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script lang="ts">
/**
 * TODO:UI
 * all option bases filters should be multi select drop downs in the query selector
 *  -- network_id can be done later
 */
import { useMarketplaceStore } from '@/stores/marketplace';
import { getChannels, deleteChannel } from '@GQL';
import { capitalize, startCase } from 'lodash-es';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import copy from 'copy-to-clipboard';

export default {
	name: 'ChannelListPage',
	components: {
		deleteAction,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			channels: [],
		};
	},
	methods: {
		capitalize,
		startCase,
		async getList(no_cache = false) {
			const marketplaceStore = useMarketplaceStore();
			this.loading = true;

			try {
				let filters: any[][];
				if (marketplaceStore.mpid) {
					filters = [
						[
							{
								op: 'eq',
								value: this.$route.params.mpid,
								field: 'mpid',
							},
						],
					];
				}

				let options: string[] = [];
				if (no_cache) {
					options = ['getNetworks'];
				}

				const channels = await getChannels(filters, options);
				this.channels = channels;
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to retrieve the channel list',
					detail: e.message,
				});
			} finally {
				this.loading = false;
			}
		},
		async deleteChannel(channel_id) {
			try {
				await deleteChannel(channel_id);
				this.$root.toast.add({
					severity: 'success',
					summary: 'Channel Deleted',
					detail: channel_id,
					life: 3000,
				});
			} catch (err) {
				this.$root.toast.add({
					severity: 'error',
					summary: 'Failed to Delete Channel',
					detail: err.response.data.message,
					life: 3000,
				});
			}
		},
		copyToClip(id) {
			copy(id);
			this.$toast.add({
				severity: 'info',
				summary: 'The Channel ID was copied to clipboard',
				detail: id,
				life: 3000,
			});
		},
	},
	async mounted() {
		await this.getList();
	},
};
</script>
