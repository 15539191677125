import { $GQL } from '@GQL/index';

export interface LoginAsParams {
	email: string;
	password: string;
	mpid: string;
	mimic?: {
		account_id: string;
		role_id: string;
	};
}

export interface LoginAsRes {
	token: string;
	mpid?: string;
}

export async function loginAs(params: LoginAsParams): Promise<LoginAsRes> {
	const req = `
        mutation LoginAs($email: String!, $password: String!, $mpid: String!, $mimic: JSONObject) {
            loginAs(email: $email, password: $password, mpid: $mpid, mimic: $mimic) {
                token
            }
        }
    `;

	const result = await $GQL.request(req, params);
	return result.loginAs;
}
