<template>
	<div id="live-transfer-start" class="view-content">
		<div class="page-heading">
			<h1>Live Transfer Leaderboards</h1>
		</div>
		<gutter size="20px" />
		<query-form v-model:query="query" :loading="loading" @apply="getLeaderboard(query.date_range, 'selected_dates')">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.date_range, 'ddd, MMM D, YYYY') }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getLeaderboard(query.date_range, 'selected_dates')"
				/>
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="font-bold pb-2" style="font-size: var(--font-size-base)">Date Range:</div>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field xl">
										<date-range v-model="form.query.date_range" show-time />
									</div>
								</div>
							</div>
						</div>
					</column>
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Show:</label>
								<div class="controls">
									<div class="field fit">
										<p-input-switch v-model="form.query.no_limit" />
									</div>
									<div class="field caption">
										<span class="label">Show All</span>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<gutter size="20px" />
		<row class="justify-content-start">
			<div class="card padded">
				<h3>Leaderboard for Date Range</h3>
				<p-data-table ref="dt" :loading="loading" scrollable :value="leaderboard.selected_dates">
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<p-column header="Rank" class="align-center">
						<template #body="row">
							{{ row.data.rank }}
						</template>
					</p-column>
					<p-column header="Agent Name" class="s">
						<template #body="row">
							{{ row.data.agent_name }}
						</template>
					</p-column>

					<p-column header="Completed" class="align-center">
						<template #body="row">
							{{ row.data.completed_transfers }}
						</template>
					</p-column>
				</p-data-table>
			</div>

			<gutter size="30px" />

			<div class="card padded">
				<h3>Weekly Leaderboard</h3>

				<p-data-table ref="dt" :loading="loading" scrollable :value="leaderboard.week">
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<template #loading>
						<line-loader :show="loading" />
					</template>

					<p-column header="Rank" class="align-center">
						<template #body="row">
							{{ row.data.rank }}
						</template>
					</p-column>
					<p-column header="Agent Name" class="s">
						<template #body="row">
							{{ row.data.agent_name }}
						</template>
					</p-column>

					<p-column header="Completed" class="align-center">
						<template #body="row">
							{{ row.data.completed_transfers }}
						</template>
					</p-column>
				</p-data-table>
			</div>
			<gutter size="30px" />

			<div class="card padded">
				<h3>Monthly Leaderboard</h3>
				<p-data-table ref="dt" :loading="loading" scrollable :value="leaderboard.month">
					<template #empty>
						<div class="dim">No results</div>
					</template>

					<template #loading>
						<line-loader :show="loading" />
					</template>

					<p-column header="Rank" class="align-center">
						<template #body="row">
							{{ row.data.rank }}
						</template>
					</p-column>
					<p-column header="Agent Name" class="s">
						<template #body="row">
							{{ row.data.agent_name }}
						</template>
					</p-column>

					<p-column header="Completed" class="align-center">
						<template #body="row">
							{{ row.data.completed_transfers }}
						</template>
					</p-column>
				</p-data-table>
			</div>
		</row>
	</div>
</template>

<script lang="ts">
import { reportLiveTransferLeaderboard } from '@GQL';
import { formatDate, timeAgo } from '@/lib/Filters';
import queryForm from '@/components/widgets/QueryForm.vue';
import dayjs from 'dayjs';
import dateRange from '@/components/forms/DateRange.vue';

export default {
	name: 'LiveTransferLeaderboard',
	props: {},
	components: {
		queryForm,
		dateRange,
	},
	data() {
		return {
			loading: false,
			leaderboard: {
				selected_dates: [],
				week: [],
				month: [],
			},
			query: {
				date_range: [dayjs().startOf('day').toISOString(), dayjs().endOf('day').toISOString()],
			},
		};
	},
	methods: {
		timeAgo,
		formatDate,
		async getLeaderboard(date_range, timeframe, limit = false) {
			this.loading = true;
			try {
				const res = await reportLiveTransferLeaderboard({ date_range, limit });

				this.leaderboard[timeframe] = res.rows.map((row, index) => {
					return { ...row, rank: index + 1 };
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: `Unable to load ${timeframe}'s leaderboard data`,
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		const week = [dayjs().startOf('week').toISOString(), new Date().toISOString()];
		const month = [dayjs().startOf('month').toISOString(), new Date().toISOString()];

		this.getLeaderboard(this.query.date_range, 'selected_dates', false).catch((err) => {});
		this.getLeaderboard(week, 'week', true).catch((err) => {});
		this.getLeaderboard(month, 'month', true).catch((err) => {});
	},
};
</script>

<style lang="less" scoped></style>
