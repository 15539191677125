<template>
	<div class="p-inputgroup">
		<p-input-number v-model="formattedValue" :disabled="disabled" class="input-align-right" />
		<div class="p-inputgroup-addon">%</div>
	</div>
</template>

<script>
import pInputNumber from 'primevue/inputnumber';
import { round } from 'lodash-es';

export default {
	name: 'InputPercentage',
	emits: ['update:modelValue'],
	components: {
		pInputNumber,
	},
	props: {
		modelValue: {
			type: Number,
			default: 0,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		formattedValue: {
			get() {
				return this.modelValue * 100;
			},
			set(new_value) {
				this.$emit('update:modelValue', round(new_value / 100, 2));
			},
		},
	},
};
</script>

<style lang="less"></style>
