<template>
	<p-button icon="pi pi-plus" label="Add Queue Action" @click="openModal" />
	<p-dialog
		v-model:visible="show_modal"
		class="add-build-step-modal"
		modal
		:dismissable-mask="true"
		style="width: 400px"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
				<strong>Add Queue Action</strong>
			</div>
		</template>
		<div class="build-step-options">
			<div v-for="action in availableActions" class="build-step-option" @click="addAction(action.value)">
				<div class="content-container flex align-items-center">
					<div>
						<div class="name">{{ action.name }}</div>
						<div class="sub-value">{{ action.description }}</div>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Cancel" text @click="show_modal = false" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';

export default {
	name: 'ActionSelector',
	emits: ['addAction'],
	components: {
		pDialog,
		pMessage,
	},
	props: {
		actions: {
			type: Array,
			default() {
				return [];
			},
		},
		method: {
			type: String,
			default: 'submit',
		},
	},
	data() {
		return {
			show_modal: false,
			action_options: [
				{
					name: 'Send to Lead Build',
					description: 'Send the lead to the lead build step ',
					allowed_on_types: ['submit', 'ping'],
					value: {
						name: 'sendToLeadBuild',
						settings: {
							capture_config: '',
						},
					},
				},
				{
					name: 'Send to Auction',
					description:
						'Send the lead to any auction that is configured to handle it or to a specific auction specified in the settings.',
					allowed_on_types: ['submit', 'ping'],
					value: {
						name: 'sendToAuction',
						settings: {
							auction_config_id: '',
						},
					},
				},
				{
					name: 'Process Auction Logs',
					description: 'Create and ship purchases for winning bids in an auction.',
					allowed_on_types: ['submit'],
					value: {
						name: 'processAuctionLogs',
					},
				},
				{
					name: 'Send to Dialer',
					description: 'Send the lead information directly to the NextGen Leads dialer.',
					allowed_on_types: ['submit', 'post'],
					value: {
						name: 'sendToDialer',
						settings: {
							list_id: '',
						},
					},
				},
				{
					name: 'Send to Partner',
					description: 'Send the lead information to a partner vendor.',
					allowed_on_types: ['submit', 'post'],
					value: {
						name: 'sendToPartners',
						settings: {
							partners: [],
						},
					},
				},
			],
		};
	},
	computed: {
		availableActions() {
			return this.action_options.filter((step_option) => {
				return step_option.allowed_on_types.includes(this.method);
			});
		},
	},
	methods: {
		openModal() {
			this.show_modal = true;
		},
		addAction(value) {
			this.$emit('addAction', cloneDeep(value));
			this.show_modal = false;
		},
	},
};
</script>

<style scoped lang="less">
.build-step-option {
	border: 1px solid var(--gray-20);
	border-radius: 3px;
	margin-bottom: 1rem;
	padding: 1rem;

	&:hover {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-lighter);
		cursor: pointer;
	}

	.name {
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.sub-value {
		line-height: 1.35em;
	}
}
</style>
