import BillingSettings from '@/views/BillingSettings/Record/Form.vue';
import PaymentMethods from '@/views/PaymentMethods/List/List.vue';
import Transactions from '@/views/Transactions/List/Page.vue';
import TransferPortalPage from '@/views/Transactions/Record/TransferPortalPage.vue';

export default [
	{
		path: 'billing-settings',
		name: 'BillingSettings',
		component: BillingSettings,
		meta: {
			title: 'Auto-Fund Settings',
			permissions: 'billing',
		},
	},
	{
		path: 'payment-methods',
		name: 'PaymentMethods',
		component: PaymentMethods,
		meta: {
			title: 'Payment Methods',
			permissions: 'billing',
		},
	},
	{
		path: 'transactions',
		name: 'Transactions',
		component: Transactions,
		meta: {
			title: 'Transaction History',
			permissions: 'billing',
		},
	},
	{
		path: 'transfer-portal',
		name: 'TransferPortalPage',
		component: TransferPortalPage,
		meta: {
			title: 'Transfer Portal',
			permissions: 'billing',
		},
	},
];
