// ----------------------------------------------------------------------------------------------------
// FILTER: percentage
// Format a number as a percentage with precision
// ----------------------------------------------------------------------------------------------------

import { isNumber, round } from 'lodash-es';

export function percentage(value, precision) {
	if (isNumber(value)) {
		const percentage = round(value * 100, precision);
		if (isNaN(percentage)) {
			return '0%';
		}
		return percentage.toString().replace(/(\d)(?=(\d{3})+$)/g, '$1,') + '%';
	}
	return '--';
}
