import { $GQL } from '@GQL/index';
import type { UpdateUser } from '@nextgenleads/auth-driver';

export async function updateUser(id: string, data: Omit<Partial<UpdateUser>, 'created_at' | 'id'>) {
	const mut = `
        mutation UpdateUser($id: ID!, $data: JSONObject!) {
            updateUser(id: $id, data: $data) {
              id
            }
        }
  `;

	const result = await $GQL.request(mut, { id, data });
	return result.updateUser;
}
