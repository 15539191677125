<template>
	<div class="qualifier account-not-interested">
		<div class="title">
			<div class="name">Account Not Interested</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">
			Disqualify a campaign if the company has the lead's phone number in their private DNC list.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'AccountNotInterestedQualifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
