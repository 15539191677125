<template>
	<div id="dashboard">
		<h1>Account</h1>
		<breadcrumb :model="items" />
		<div class="sub-header mb-2">Information for {{ formatDate(new Date(), "ddd, MMM D, YYYY 'at' LT") }}</div>
		<div class="metrics">
			<row>
				<column>
					<metric label="Leads Purchased:" :value="100" :previous-value="120" />
				</column>
				<gutter size="20px" />
				<column>
					<metric label="Spend:" :value="10" is-currency />
				</column>
			</row>
			<gutter size="20px" />
			<row>
				<column>
					<metric label="Cost / Lead:" :value="343.42" :previous-value="21.54" is-currency />
				</column>
				<gutter size="20px" />
				<column>
					<metric label="Balance:" :value="400" is-currency />
				</column>
			</row>
		</div>
		<gutter size="20px" />
		<DailySpend />
		<gutter size="20px" />
		<row>
			<column span="8">
				<Campaigns />
			</column>
			<gutter size="20px" />
			<column span="4">
				<Notifications />
			</column>
		</row>
	</div>
</template>

<script lang="ts">
import { percentage, currency, formatDate } from '@/lib/Filters';
import DailySpend from './DailySpend.vue';
import Campaigns from './CampaignsSummary.vue';
import Notifications from './NotificationsSummary.vue';
import { getAccountDashboardData } from '@GQL';

export default {
	name: 'AccountsDashboard',
	components: {
		DailySpend,
		Campaigns,
		Notifications,
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
			items: [{ label: 'Accounts', route: '/accounts' }, { label: 'Account' }],
			account: {},
		};
	},
	async mounted() {
		// this page is only accessible when account_id is in path....
		this.account = await getAccountDashboardData(this.$route.params.id);
	},
	methods: {
		currency,
		formatDate,
		percentage,
	},
};
</script>
