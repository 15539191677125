import { $GQL } from '@GQL/index';
import type { APIKey } from '@nextgenleads/auth-driver';

export async function initAPIKeyForm(
	id?: string
): Promise<{ apiKey: APIKey; role_options: { label: string; value: string }[] }> {
	const roles_filters = [[`type = 'app'`]];
	const marketplace_filters = [
		[
			{
				field: 'status',
				value: 'archived',
				op: 'ne',
			},
		],
	];

	let query, result;

	if (id) {
		query = `
            query InitAPIKeyForm($id: ID!, $roles_filters: [[Any]], $marketplace_filters: [[Any]]) {
                apiKey(id: $id) {
                    name
                    id
                    key
                    mpid
                    owner_id
                    role_id
                    whitelisted_ips
                    allowed_request_methods
                    data
                    status
                }
                role_options: roles(filters: $roles_filters) {
                    label: name
                    value: id
                }
                marketplace_options: marketplaces(filters: $marketplace_filters) {
                    label: name
                    value: id
                }
            }
        `;
		result = await $GQL.request(query, { id, roles_filters, marketplace_filters });
	} else {
		query = `
            query InitAPIKeyForm($roles_filters: [[Any]], $marketplace_filters: [[Any]]) {
                role_options: roles(filters: $roles_filters) {
                    label: name
                    value: id
                }
                marketplace_options: marketplaces(filters: $marketplace_filters) {
                    label: name
                    value: id
                }
            }
        `;
		result = await $GQL.request(query, { roles_filters, marketplace_filters });
	}

	return result;
}
