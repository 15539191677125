import { $GQL } from '@GQL';
import type { Conversion } from '@nextgenleads/marketplace-driver';

export async function insertConversion(data: Omit<Conversion, 'created_at'>) {
	const mutation = `
        mutation InsertConversion($data: JSONObject!) {
            insertConversion(data: $data) {
                purchase_id
                lead_id
                account_id
                disposition
                source
                created_by
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertConversion;
}
