import { $GQL } from '@GQL/index';

export async function reportPerformanceVelocity(params: { date_range: [string, string]; compare: any }) {
	const query = `
        query ReportPerformanceVelocity($params: JSONObject!) {
            reportPerformanceVelocity(params: $params)
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportPerformanceVelocity;
}
