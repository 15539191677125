<template>
	<div class="lt-question">
		<div class="lt-question-heading">
			<div class="lt-question-icon">
				<icon type="comment-text-outline" size="24px" />
			</div>
			<div class="lt-question-body">
				<p>Are you currently on or looking for either Medicaid or Medicare?</p>
			</div>
		</div>
		<div class="lt-question-content">
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-10" style="margin-bottom: 0">
						<div class="field fit">
							<p-radio-button input-id="medicaid_true" v-model="medicaid" :value="true" />
						</div>
						<div class="field">
							<label for="medicaid_true" class="radio-label">Yes</label>
						</div>
					</div>
					<div class="controls gap-10">
						<div class="field fit">
							<p-radio-button input-id="medicaid_false" v-model="medicaid" :value="false" />
						</div>
						<div class="field">
							<label for="medicaid_false" class="radio-label">No</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from 'lodash-es';

export default {
	name: 'LTQuestion_Medicaid',
	emits: ['update'],
	props: {
		lead: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			medicaid: get(this.lead, 'data.medicare_or_medicaid', false),
		};
	},
	watch: {
		medicaid(new_value) {
			this.$emit('update', {
				data: {
					medicare_or_medicaid: new_value,
				},
			});
		},
	},
};
</script>
