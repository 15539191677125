import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getParsedAdContent(id: String) {
	const query = `
        query GetParsedAdContent($id: ID!) {
            parsedAdContent(id: $id)
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.parsedAdContent;
}
