import { GraphQLClient } from 'graphql-request';
import { parse } from 'graphql';
import Cookies from 'js-cookie';
import log from '@/lib/Log';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import axios, { type AxiosRequestConfig } from 'axios';
import subdomains from '@/lib/Data/subdomains.json';

export * from './queries';
export * from './mutations';

interface APIConfig {
	endpoint: string;
	headers: Record<string, string>;
	is_marketplace: boolean;
}

class APIClient {
	endpoint;
	client;
	is_marketplace: boolean;
	mpid: string;
	token: string;
	tokens: Record<string, string>;

	constructor(config: APIConfig) {
		this.endpoint = config.endpoint;
		this.client = new GraphQLClient(config.endpoint + 'graphql/', config.headers);
		this.is_marketplace = false;
		this.mpid = '';
		this.token = '';
		this.tokens = {};

		const cookie_token = Cookies.get('token');
		if (cookie_token) {
			log.trace('Got Cookie token');
			this.client.setHeader('Authorization', `Bearer ${cookie_token}`);
			this.token = cookie_token;
		}

		if (window.opener) {
			// Get the sub-domain
			let subdomain = window.location.host.split('.')[0];

			if (subdomains[subdomain]) {
				subdomain = subdomains[subdomain].toLowerCase();
			}

			if (subdomain !== 'admin') {
				const mimic_token = Cookies.get(`${subdomain}_token`);
				if (mimic_token) {
					this.client.setHeader('Authorization', `Bearer ${mimic_token}`);
					this.token = mimic_token;
				}
			}
		}

		if (config.is_marketplace) {
			this.is_marketplace = true;
		}
	}

	setTokenFromCookie() {
		const cookie_token = Cookies.get('token');
		if (cookie_token) {
			log.trace('Resetting Cookie token');
			this.client.setHeader('Authorization', `Bearer ${cookie_token}`);
			this.token = cookie_token;
		}
	}

	setTempToken(temp_token) {
		if (temp_token) {
			log.trace('Resetting token');
			this.client.setHeader('Authorization', `Bearer ${temp_token}`);
			this.token = temp_token;
		}
	}

	hasToken() {
		return (
			this.client.requestConfig && this.client.requestConfig.headers && this.client.requestConfig.headers.Authorization
		);
	}

	resetHeaders() {
		this.client.requestConfig.headers = {};
	}

	async setMPID(mpid: string) {
		mpid = mpid.toUpperCase();

		if (this.mpid !== mpid) {
			if (!this.tokens[mpid]) {
				// Make a request to get a token for the mpid
				const query = `
					query GetMarketplaceToken($mpid: String!) {
						getMarketplaceToken(mpid: $mpid) {
							token
							role {
								granted
								id
								mpid
								name
								revoked
								status
								type
							}
						}
					}
				`;

				const auth_response = await this.request(query, { mpid });

				if (auth_response.getMarketplaceToken.token) {
					this.mpid = mpid;
					this.tokens[mpid] = auth_response.getMarketplaceToken.token;

					// Set the role
					const marketplaceStore = useMarketplaceStore();
					marketplaceStore.mpid = mpid;
					marketplaceStore.role = auth_response.getMarketplaceToken.role;
				}
			}
		}
	}

	async request(query: string, variables?: any, auth?: string) {
		// parse the string
		// throws error if query is wrong
		try {
			parse(query);

			log.trace('Current Headers', this.client.requestConfig.headers);
			if (!this.hasToken()) {
				log.trace('Missing Token');
				this.setTokenFromCookie();
			}

			let request_headers = {};
			if (this.is_marketplace) {
				if (this.mpid && this.tokens[this.mpid]) {
					request_headers = {
						Authorization: `Bearer ${this.tokens[this.mpid]}`,
					};
				}
			}

			// Attach a custom auth key
			if (auth) {
				Object.assign(request_headers, {
					Authorization: auth,
				});
			}

			const raw_response = await this.client.rawRequest(query, variables, request_headers);
			log.trace('Raw Response', raw_response);

			let token = '';
			if (raw_response.headers.token) {
				token = raw_response.headers.token;
			} else if (raw_response.data.login) {
				token = raw_response.data.login.token;
			}

			if (token !== '') {
				log.trace('Updating Token');
				Cookies.set('token', token);
				this.token = token;
				this.client.setHeader('Authorization', `Bearer ${token}`);
			}

			return raw_response.data;
		} catch (err) {
			if (err.response && err.response.status === 401) {
				const sessionStore = useSessionStore();
				sessionStore.reauthenticate();
			}

			log.trace('Query with issues', query, variables);
			log.trace(err);
			throw err;
		}
	}

	async upload(files: File[], folder: string = '', filename: string | null = null, progressCallback: () => {}) {
		const data = new FormData();
		let increment = true;
		if (files.length === 1) {
			increment = false;
		}
		for (let n = 0; n < files.length; n++) {
			if (filename) {
				// Remove the extension from the filename
				if ((filename as string).indexOf('.') > -1) {
					filename = filename.substring(0, filename.lastIndexOf('.'));
				}

				if (files.length > 1) {
					filename = filename + '-' + n.toString().padStart(3, '0');
				}

				const extension = files[n].name.substring(files[n].name.lastIndexOf('.'));
				data.append('uploads', files[n], filename + extension);
			} else {
				data.append('uploads', files[n]);
			}
		}

		try {
			const upload_options: AxiosRequestConfig<FormData> = {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${this.token}`,
					'Content-Type': 'multipart/form-data',
				},
				responseType: 'json',
			};
			if (progressCallback) {
				upload_options.onUploadProgress = progressCallback;
			}
			const result = await axios.post(`${this.endpoint}upload?folder=${folder}`, data, upload_options);

			if (result) {
				return result;
			}
			throw new Error('Unable to upload files');
		} catch (err) {
			return err;
		}
	}
}

log.trace('INITIALIZED GQL');

/* Set the endpoint to either VITE_API_ENDPOINT if its set or
the window hostname, replacing the subdomain with api
*/
export const endpoint = (function (): string {
	let apiHostname = import.meta.env.VITE_API_ENDPOINT;
	if (apiHostname) {
		return apiHostname;
	}

	if (window.location.hostname === 'localhost') {
		throw new Error('specify VITE_API_ENDPOINT for localhost');
	}

	let domainParts = window.location.hostname.split('.');

	// remove the preview part of the domain if it exists
	const i = domainParts.indexOf('preview');
	if (i > -1) {
		domainParts.splice(i, 1);
	}

	domainParts[0] = 'api';
	apiHostname = 'https://' + domainParts.join('.') + '/';
	return apiHostname;
})();

log.trace(`using api hostname = ${endpoint}`);

export const $GQL = new APIClient({ endpoint: endpoint, headers: {} });

export const GQLPlugIn = {
	install: (app, options: { gql_client: GraphQLClient }) => {
		app.provide('$GQL', $GQL);
	},
};
