<template>
	<div id="login" :style="{ backgroundImage: `url(${loginBkg})` }">
		<div class="login-container">
			<transition name="drop-fade" mode="out-in">
				<component :is="form" @change-form="changeForm" :loginEmail="loginEmail" />
			</transition>
		</div>
	</div>
</template>

<script lang="ts">
import { get } from 'lodash-es';
import LoginForm from './LoginForm.vue';
import PasswordResetForm from './PasswordResetForm.vue';
import RequestPasswordForm from './RequestPasswordForm.vue';

export default {
	name: 'LoginView',
	components: {
		LoginForm,
		PasswordResetForm,
		RequestPasswordForm,
	},
	inject: ['mq'],
	data() {
		return {
			form: 'LoginForm',
			loginEmail: this.$route.query.loginEmail || '',
		};
	},
	computed: {
		loginBkg() {
			return get(this.$root.appStore, 'marketplace.settings.login_bkg_url', '/assets/img/backgrounds/bg-facets.webp');
		},
	},
	methods: {
		changeForm(form: string, loginEmail?: string) {
			this.form = form;
			if (loginEmail) {
				this.loginEmail = loginEmail;
			}
		},
	},
	mounted() {
		if (this.$route.params.token) {
			this.changeForm(PasswordResetForm);
		}

		if (this.$route.path === '/reset-password') {
			this.changeForm(RequestPasswordForm);
		}
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

#login {
	align-items: center;
	background-size: cover;
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100vw;
}

.login-container {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	padding: 0 1rem;
	width: 50%;

	.mobile({
		width: 100%;
	});
}

.p-card-header {
	border-bottom: 1px solid var(--gray-10);
	padding: 1.5rem;

	.logo {
		width: 100%;
	}
}

.p-button {
	font-weight: 700;
}
</style>
