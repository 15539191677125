<template>
	<div class="qualifier throttling">
		<div class="title">
			<div class="name">Throttling</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">
			Disqualify a campaign if it recently purchased a lead and the throttle timer is still active.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ThrottlingQualifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
