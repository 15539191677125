import { $GQL } from '@GQL';

export async function deleteNetwork(id: string) {
	const mutation = `
        mutation DeleteNetwork($id: ID!,) {
			deleteNetwork(id: $id) {
				id
			}
        }
    `;

	const result = await $GQL.request(mutation, { id });
	return result.deleteNetwork;
}
