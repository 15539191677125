import { $GQL } from '@GQL/index';
import type { SendEmailReq, SendEmailRes } from '@nextgenleads/email-driver';

export async function sendEmail(params: SendEmailReq): Promise<SendEmailRes> {
	const mutation = `
        mutation SendEmail($params: SendEmailReq!) {
            sendEmail(params: $params) {
                status
                error
            }
        }
    `;
	const result = await $GQL.request(mutation, { params });
	return result.sendEmail;
}
