<template>
	<div>
		<h2>AMW Email Templates</h2>
		<p-dialog v-model:visible="show_modal" modal :dismissable-mask="true" style="width: 400px">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
					<strong>Edit AMW Email Template</strong>
				</div>
			</template>
			<div class="content">
				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req">Workflow:</label>
									<p-dropdown
										:disabled="edit_mode"
										v-model="new_workflow"
										:options="workflow_options"
										placeholder="Select Workflow"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.new_workflow.$error" class="validation-error">
										{{ v$.new_workflow.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req" for="role-id">Disposition:</label>
									<p-dropdown
										:disabled="edit_mode"
										v-model="new_disposition"
										:options="disposition_options"
										placeholder="Select Disposition"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.new_disposition.$error" class="validation-error">
										{{ v$.new_disposition.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req">Email Template:</label>
									<p-dropdown
										v-model="new_email_template_id"
										:options="email_template_options"
										option-label="label"
										option-value="value"
										placeholder="Email Template"
									/>
									<div v-if="v$.new_email_template_id.$error" class="validation-error">
										{{ v$.new_email_template_id.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer class="flex justify-content-end">
				<p-button text label="Cancel" @click="cancelChange" />
				<p-button label="Add Change" @click="saveChange" />
			</template>
		</p-dialog>
		<gutter size="20px" />

		<div>
			<p-button label="Add Email Template" @click="openModal" />
			<gutter v-if="dirty" size="20px" />
			<div v-if="dirty" class="flex justify-content-between">
				<div>
					<h3 :style="{ color: 'red', 'font-style': 'italic' }">Changes pending, Save to implement</h3>
				</div>
				<div class="flex justify-content-end">
					<p-button label="Reset" text @click="reset" />
					<p-button :disable="hasPendingChange" label="Save" @click="save" />
				</div>
			</div>
		</div>
		<gutter size="20px" />
		<p>
			The Existing Workflow email templates will also be available when a Account Disposition is changed in the Account
			Overview page.
		</p>
		<gutter size="20px" />
		<p-data-table ref="email_data_table" :value="template_ids" :loading="loading" sortMode="multiple">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>

			<p-column header="Workflow" sortable sortField="workflow">
				<template #body="row">
					{{ startCase(row.data.workflow) }}
				</template>
			</p-column>
			<p-column header="Disposition" sortable sortField="key">
				<template #body="row">
					{{ row.data.key }}
				</template>
			</p-column>
			<p-column header="Email Template">
				<template #body="row">
					{{ getTemplateName(row.data.value) }}
					<div class="sub-value">{{ row.data.value }}</div>
				</template>
			</p-column>
			<p-column header="Tools">
				<template #body="row">
					<p-button v-tooltip.top="'Edit'" text @click="editValue(row.data)">
						<template #icon>
							<icon type="pencil-box-outline" size="24px" />
						</template>
					</p-button>
					<p-button v-tooltip.top="'Delete'" text @click="removeValue(row.data)">
						<template #icon>
							<icon type="trash-can-outline" size="24px" />
						</template>
					</p-button>
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>
<script>
import { getSettings, updateSetting, getEmailTemplatesAsOptions } from '@GQL';
import { cloneDeep, findIndex, startCase } from 'lodash-es';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { disposition_options } from '@/lib/Options';

export default {
	name: 'AMWEmailTemplates',
	emits: ['refresh'],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			show_modal: false,
			loading: false,
			edit_mode: false,
			original_map: null,
			template_ids: [],
			new_workflow: '',
			new_email_template_id: '',
			new_disposition: '',
			disposition_options,
			workflow_options: [
				{ label: 'New Accounts', value: 'new_accounts' },
				{ label: 'Existing Accounts', value: 'existing_accounts' },
			],
			email_template_options: [],
			dirty: false,
		};
	},
	watch: {
		new_disposition(new_value) {
			// we change we get rid of the
		},
	},
	computed: {
		hasPendingChange() {
			return this.dirty;
		},
	},
	async mounted() {
		await this.getSetting();
		// get the email templates

		this.email_template_options = await getEmailTemplatesAsOptions([[`status = 'active'`]], 'amw');
	},
	methods: {
		getTemplateName(id) {
			const index = findIndex(this.email_template_options, { value: id });
			if (index > -1) {
				return this.email_template_options[index].label;
			} else {
				return 'Template name not found';
			}
		},
		cancelChange() {
			this.new_workflow = '';
			this.new_disposition = '';
			this.new_email_template_id = '';
			this.edit_mode = false;
			this.show_modal = false;
			this.v$.$reset();
		},
		async saveChange() {
			this.loading = true;
			try {
				const is_valid = await this.v$.$validate();
				if (is_valid) {
					const new_value = {
						table_key: this.new_workflow + this.new_disposition + this.new_email_template_id,
						workflow: this.new_workflow,
						key: this.new_disposition,
						value: this.new_email_template_id,
					};
					// do a remove just in case
					this.removeValue(new_value);
					this.dirty = true;
					this.template_ids = [new_value, ...this.template_ids];
					this.cancelChange();
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Cannot save change',
						detail: 'One of the required fields is missing',
					});
				}
			} finally {
				this.loading = false;
			}
		},
		async save() {
			try {
				this.loading = true;
				// check that nothhing is pending in the new
				const new_map = {};
				this.template_ids.forEach((row) => {
					if (row.workflow in new_map) {
						if (new_map[row.workflow][row.key]) {
							new_map[row.workflow][row.key].push(row.value);
						} else {
							new_map[row.workflow][row.key] = [row.value];
						}
					} else {
						new_map[row.workflow] = { [row.key]: [row.value] };
					}
				});
				const settings_result = await updateSetting(
					'amw_email_templates',
					{
						data: new_map,
					},
					'$NG',
					true
				);
				if (settings_result.id === 'amw_email_templates') {
					this.dirty = false;
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved AMW Email Template settings',
						life: 5000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save settings',
						life: 7000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getSetting() {
			this.loading = true;
			try {
				this.template_ids = [];
				// only ever global
				const settings_result = await getSettings(['amw_email_templates'], '$NG', true);
				this.original_map = cloneDeep(settings_result.settings.amw_email_templates);
				this.constructTemplateIds();
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to load in AMW email templates',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		constructTemplateIds() {
			Object.keys(this.original_map).forEach((workflow) => {
				Object.keys(this.original_map[workflow]).forEach((key) => {
					// this is an array of EMAIL TEMPLATES
					this.original_map[workflow][key].forEach((value) => {
						this.template_ids.push({
							table_key: workflow + key + value,
							workflow,
							key,
							value,
						});
					});
				});
			});
		},
		reset() {
			this.template_ids.splice(0, this.template_ids.length);
			this.constructTemplateIds();
			this.dirty = false;
			this.cancelChange();
		},
		editValue(row) {
			this.dirty = true;
			this.new_workflow = row.workflow;
			this.new_disposition = row.key;
			this.new_email_template_id = row.value;
			// make the workflow disable
			this.edit_mode = true;
			this.openModal();
		},
		removeValue(row) {
			this.dirty = true;
			this.loading = true;
			let copy = cloneDeep(this.template_ids);
			const index = findIndex(copy, { table_key: row.table_key });
			if (index > -1) {
				copy.splice(index, 1);
			}
			this.template_ids = [...copy];
			this.loading = false;
		},
		openModal() {
			this.show_modal = true;
		},
	},
	validations() {
		return {
			new_email_template_id: {
				required: helpers.withMessage('Email Template is required.', required),
			},
			new_workflow: {
				required: helpers.withMessage('Workflow is required.', required),
			},
			new_disposition: {
				required: helpers.withMessage('Disposition is required.', required),
			},
		};
	},
};
</script>
