<template>
	<div class="attribute-section" :class="{ active: enable_minimum_age_filter }">
		<div class="header">
			<div class="title">
				<label class="clickable-label" for="household-switch">Target by Minimum Age</label>
			</div>
			<div class="switch">
				<p-input-switch v-model="enable_minimum_age_filter" input-id="age-switch" />
			</div>
		</div>
		<div v-if="enable_minimum_age_filter" class="filter">
			<p>Select the minimum age you want to target.</p>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Minimum Age:</label>
					<div class="controls">
						<div class="field flex">
							<p-radio-button
								v-model="custom_bid_minimum_age"
								input-id="minimum_age_64"
								name="medicare_minimum_age"
								:value="64.5"
							/>
							<gutter size="10px" />
							<label for="minimum_age_64">64.5 years old</label>
						</div>
						<div class="field flex">
							<p-radio-button
								v-model="custom_bid_minimum_age"
								input-id="minimum_age_65"
								name="medicare_minimum_age"
								:value="65"
							/>
							<gutter size="10px" />
							<label for="minimum_age_65">65 years old</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pSlider from 'primevue/slider';

export default {
	name: 'MedicareMinimumAgeAttribute',
	emits: ['update'],
	components: {
		pSlider,
	},
	data() {
		return {
			enable_minimum_age_filter: false,
			custom_bid_minimum_age: 64.5,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const age_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.dob';
			});
			if (age_match) {
				this.enable_minimum_age_filter = true;
				this.custom_bid_minimum_age = age_match.comparator.value;
			}
		},
		saveAttribute() {
			if (this.enable_minimum_age_filter) {
				return {
					target: {
						path: 'lead.data.dob',
					},
					strategy: 'greater_than_or_equals',
					comparator: {
						transformer: 'age',
						value: this.custom_bid_minimum_age,
					},
				};
			}
			return null;
		},
	},
};
</script>

<style lang="less">
.field.slider {
	padding: 0 2em;
}

.slider-value {
	width: 40px;
}
</style>
