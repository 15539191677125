import { $GQL } from '@GQL/index';
import type { Note } from '@nextgenleads/marketplace-driver';

export async function insertNote(data: Omit<Note, 'created_at' | 'id'>) {
	const mutation = `
        mutation InsertNote($data: JSONObject!) {
            insertNote(data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertNote;
}
