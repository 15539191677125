<template>
	<div id="ad-form" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.ad_template_id ? 'Edit' : 'Create' }} Premium Listing Template</h1>
			<breadcrumb :items="breadcrumbs" />
		</div>
		<row>
			<column>
				<div class="ad-card card padded">
					<ad-template-form ref="ad_template_form" />
				</div>
			</column>
			<gutter size="20px" />
			<column>
				<div class="ad-variables card padded">
					<h3>Available Variables</h3>
					<p>You can add the following variables into an ad template by wrapping them in double brackets.</p>
					<ul class="bulleted">
						<li><code>agent_name</code></li>
						<li><code>company_name</code></li>
						<li><code>pronouns[0]</code> (he/she/they)</li>
						<li><code>pronouns[1]</code> (him/her/them)</li>
						<li><code>pronouns[2]</code> (his/her/their)</li>
						<li><code>phone</code></li>
						<li><code>email</code></li>
						<li><code>bio</code></li>
						<li><code>photo_url</code></li>
						<li><code>logo_url</code></li>
					</ul>
					<div class="preview">
						<p-button label="Preview Ad" @click="previewAd()" />
					</div>
				</div>
			</column>
		</row>
	</div>
</template>

<script>
import adTemplateForm from './Form.vue';

export default {
	name: 'AdTemplateFormView',
	components: {
		adTemplateForm,
	},
	data() {
		return {
			breadcrumbs: [
				{
					key: 0,
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					key: 1,
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}`,
				},
				{
					key: 2,
					label: 'Premium Listing Templates',
					route: `/marketplaces/manage/${this.$route.params.mpid}/ad-templates`,
				},
				{
					key: 3,
					label: `${this.$route.params.ad_template_id ? 'Edit' : 'Create'} Premium Listing Template`,
				},
			],
		};
	},
	methods: {
		previewAd() {
			this.$refs.ad_template_form.previewAd();
		},
	},
};
</script>

<style lang="less">
.ad-card {
	max-width: 760px;
}

.ad-variables {
	max-width: 350px;
	position: sticky;
	top: 0;

	h3 {
		font-size: 1.125em;
		font-weight: bold;
	}
}

ul.bulleted li {
	margin-bottom: 0.5em;
}

.preview {
	background-color: var(--gray-05);
	border: 2px solid var(--gray-10);
	border-radius: 5px;
	margin-top: 1em;
	padding: 20px;
	text-align: center;
}
</style>
