import { $GQL } from '@GQL/index';
import type { Vendor } from '@nextgenleads/source-driver';

export async function insertVendor(data: Omit<Vendor, 'modified_at' | 'created_at'>): Promise<{ id: string }> {
	const mutation = `
        mutation InsertVendor($data: JSONObject!) {
            insertVendor(data: $data) {
                id
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertVendor;
}
