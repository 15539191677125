import { $GQL } from '@GQL/index';

/**
 * Returns all the marketplaces, works with the list vue which should do front end filtering
 */
export async function getMarketplacesAsOptions() {
	const filters = [
		[
			{
				field: 'status',
				value: 'archived',
				op: 'ne',
			},
		],
	];

	const query = `
        query GetMarketplacesAsOptions($filters: [[Any]]) {
            marketplace_options: marketplaces(filters: $filters) {
                label: name
                value: id
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.marketplace_options;
}
