import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function reportSourceConversions(
	params: { date_range: [string, string]; filters?: QueryFilters; account_id?: string; mpid?: string },
	refresh?: boolean
) {
	const query = `
        query ReportAccountSourceConversions($params: JSONObject!) {
            reportAccountSourceConversions(params: $params) {
				rows
				row_count
			}
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportAccountSourceConversions;
}
