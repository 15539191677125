<template>
	<div id="users-list" class="view-content">
		<div class="page-heading">
			<h1>Users List</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="user-list-toolbar flex align-items-center justify-content-between">
			<div class="flex align-items-center">
				<p-button icon="pi pi-plus" label="Add User" @click.prevent="$router.push(`${$route.path}/new`)" />
				<template v-if="!accountId">
					<gutter size="10px" />
					<p-input-text
						v-model="table_filters['global'].value"
						placeholder="Search Users"
						autocomplete="off"
						style="min-width: 300px"
					/>
				</template>
			</div>
			<show-archived v-model="show_archived" />
		</div>
		<gutter size="10px" />
		<div class="card padded">
			<p-data-table
				:value="show_archived ? sortedUsers : activeSortedUsers"
				v-model:filters="table_filters"
				:global-filter-fields="['first_name', 'last_name', 'email', 'title', 'role_id']"
				:rows="50"
				ref="dt"
				data-key="id"
				paginator
				scrollable
				sortField="first_name"
				:sortOrder="1"
			>
				<template #empty>
					<div class="dim">No users found</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column field="avatar" style="width: 50px; padding-right: 0" class="column-align-center">
					<template #body="user">
						<Avatar :user="user.data" />
					</template>
				</p-column>
				<p-column field="name" header="Name" sortable sortField="first_name">
					<template #body="user">
						<div class="name">
							<router-link
								:to="`${$route.path}/${user.data.id}`"
								:class="{
									suspended: user.data.status === 'suspended',
									archived: user.data.status === 'archived',
								}"
								>{{ user.data.first_name }} {{ user.data.last_name }}</router-link
							>
						</div>
						<div class="sub-value email">
							{{ user.data.email }}
						</div>
					</template>
				</p-column>
				<p-column field="title" header="Title" sortable sortField="title">
					<template #body="user">
						{{ capitalize(user.data.title) }}
					</template>
				</p-column>
				<p-column field="role_id" header="Role ID">
					<template #body="user">
						{{ title(user.data.role_id[appStore.mpid] || user.data.role_id[$route.params.mpid]) }}
					</template>
				</p-column>
				<p-column field="status" header="Status" class="align-center" sortable sortField="status">
					<template #body="user">
						<icon
							v-if="user.data.status === 'active'"
							v-tooltip.top="'Active'"
							type="account-check"
							style="color: var(--green)"
							size="24px"
						/>
						<icon
							v-else-if="user.data.status === 'suspended'"
							v-tooltip.top="'Paused/Suspended'"
							type="account-off"
							style="color: var(--gray-50)"
							size="24px"
						/>
						<icon v-else v-tooltip.top="'Archived'" type="account-cancel" style="color: var(--gray-50)" size="24px" />
					</template>
				</p-column>
				<p-column header="Tools" class="align-center">
					<template #body="user">
						<p-confirm-popup />
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Edit User'"
									aria-label="Edit"
									@click="$router.push({ path: `${$route.path}/${user.data.id}` })"
								>
									<template #icon>
										<icon type="square-edit-outline" size="20px" />
									</template>
								</p-button>
							</div>
							<div v-if="$root.sessionStore.isAdminUser && accountId" class="tool">
								<p-button v-tooltip.top="'Mimic User'" aria-label="Mimic" @click="openMimicUserModal(user.data)">
									<template #icon>
										<icon type="incognito" size="20px" />
									</template>
								</p-button>
								<p-dialog modal v-model:visible="show_mimic_user_modal" style="max-width: 500px">
									<template #header>
										<div class="flex align-items-center justify-start gap-20">
											<icon type="incognito" size="20px" /> <strong>Mimic Account User</strong>
										</div>
									</template>
									<p>
										Enter your password to open the Marketplace for this user in a new window and mimic the
										<strong>{{ mimic_account_role }}</strong> role.
									</p>
									<div class="control-group">
										<div class="inner">
											<label class="control-label">Admin User Password:</label>
											<div class="controls">
												<div class="field">
													<p-password input-id="mimic-password" v-model="mimic_password" :feedback="false" />
												</div>
											</div>
										</div>
									</div>
									<template #footer>
										<p-button text label="Cancel" @click="show_mimic_user_modal = false" />
										<p-button label="Mimic Account User" @click="mimicAccountUser()" />
									</template>
								</p-dialog>
							</div>
							<template v-if="user.data.id !== sessionStore.user.id">
								<div class="tool">
									<template v-if="['suspended', 'archived'].includes(user.data.status)">
										<p-button
											v-tooltip.top="'Activate User'"
											aria-label="Unsuspend"
											@click="handleUpdateUser($event, user.data.id, 'activate')"
										>
											<template #icon>
												<icon type="account-check" size="20px" />
											</template>
										</p-button>
									</template>
									<template v-else>
										<p-button
											v-tooltip.top="'Suspend User'"
											aria-label="Suspend"
											@click="handleUpdateUser($event, user.data.id, 'suspend')"
										>
											<template #icon>
												<icon type="account-cancel" size="20px" />
											</template>
										</p-button>
									</template>
								</div>
								<div class="tool">
									<p-button
										v-tooltip.top="'Archive User'"
										aria-label="Archive"
										@click="handleUpdateUser($event, user.data.id, 'archive')"
									>
										<template #icon>
											<icon type="archive" size="20px" />
										</template>
									</p-button>
								</div>
							</template>
						</div>
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script lang="ts">
import { capitalize, title } from '@/lib/Filters';
import { getUsersList, loginAs, updateUser } from '@GQL';
import { mapStores } from 'pinia';
import { sortBy } from 'lodash-es';
import { useAppStore } from '@/stores/app';
import { useConfirm } from 'primevue/useconfirm';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import Avatar from '@/components/widgets/User/Avatar.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import pConfirmPopup from 'primevue/confirmpopup';
import { FilterMatchMode } from 'primevue/api';
import { nextTick } from 'vue';
import Cookies from 'js-cookie';
import subdomains from '@/lib/Data/subdomains.json';

export default {
	name: 'UsersList',
	components: {
		Avatar,
		pConfirmPopup,
		showArchived,
	},
	data() {
		return {
			breadcrumb_items: [],
			loading: false,
			table_filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			filters: [['account_id IS NULL']],
			show_archived: false,
			users_list: [],
			tableKey: 0,
			confirm: useConfirm(),
			show_mimic_user_modal: false,
			mimic_account_role: '',
			mimic_password: '',
		};
	},
	computed: {
		accountId() {
			const final = this.sessionStore.account ? this.sessionStore.account.id : null;
			return this.$route.params.parent_account_id || this.$route.params.account_id || final;
		},
		sortedUsers() {
			return sortBy(this.users_list, (user) => {
				return user.status === 'active' ? 1 : user.status === 'suspended' ? 2 : 3;
			});
		},
		activeSortedUsers() {
			return sortBy(this.users_list, (user) => {
				return user.status === 'active' ? 1 : user.status === 'suspended' ? 2 : 3;
			}).filter((user) => user.status !== 'archived');
		},
		...mapStores(useAppStore, useMarketplaceStore, useSessionStore),
	},
	async mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Users',
				},
			];
		} else {
			this.breadcrumb_items = [
				{
					label: 'Users',
				},
			];
		}
		this.loading = true;
		if (this.sessionStore.user.role_id) {
		}
		try {
			await this.getUsersList(true);
		} catch (err) {
			// Foo
		} finally {
			this.loading = false;
		}
	},
	methods: {
		capitalize,
		title,
		handleUpdateUser(event, id: string, type: string) {
			this.confirm.require({
				target: event.currentTarget,
				header: type === 'archive' ? 'Archive User' : 'Suspend User',
				message: `Are you sure you want to ${type} this user?`,
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				accept: async () => {
					if (type === 'archive') {
						await updateUser(id, { status: 'archived', modified_at: new Date() });
					} else if (type === 'activate') {
						await updateUser(id, { status: 'active', modified_at: new Date() });
					} else {
						await updateUser(id, { status: 'suspended', modified_at: new Date() });
					}
					await this.getUsersList(true);
					this.tableKey += 1;
					await this.$nextTick();
					this.$toast.add({
						severity: 'info',
						summary: `${capitalize(type)} User Complete`,
						detail: `Updated User`,
						life: 3000,
					});
				},
			});
		},
		async getUsersList(refresh = false) {
			let no_cache = [];
			if (refresh) {
				no_cache = ['users'];
			}

			this.loading = true;
			try {
				// Is an account user
				if (this.accountId) {
					this.users_list = await getUsersList([[`account_id = '${this.accountId}'`]], no_cache);
				}
				// Is a marketplace user
				else if (this.appStore.mpid !== '$NG' || this.marketplaceStore.mpid) {
					this.users_list = await getUsersList(
						[
							[
								`account_id IS NULL AND role_id ? '${
									this.marketplaceStore.mpid || this.appStore.mpid
								}' AND NOT role_id ? '$NG'`,
							],
						],
						no_cache
					);
				}
				// Is an admin user
				else {
					this.users_list = await getUsersList([[`account_id IS NULL AND role_id ? '$NG'`]], no_cache);
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error fetching users',
					detail: err.message,
					// life: 3000,
				});
			}
			this.loading = false;
			return [];
		},
		openMimicUserModal(user) {
			this.mimic_account_role = user.role_id[this.marketplaceStore.mpid];
			this.show_mimic_user_modal = true;
			nextTick(() => {
				document.querySelector('#mimic-password').focus();
			});
		},
		async mimicAccountUser(user) {
			if (this.$root.sessionStore.isAdminUser) {
				try {
					const login_as_response = await loginAs({
						email: this.$root.sessionStore.user.email,
						password: this.mimic_password,
						mpid: this.$route.params.mpid,
						mimic: {
							role_id: this.mimic_account_role,
							account_id: this.accountId,
						},
					});

					if (login_as_response && login_as_response.token) {
						if (window.location.host.indexOf('nextgenleads.app') > -1) {
							Cookies.set(`${this.$route.params.mpid.toLowerCase()}_token`, login_as_response.token, {
								domain: 'nextgenleads.app',
							});
						} else {
							Cookies.set('localhost_token', login_as_response.token);
						}

						const mp_domain = window.location.host
							.split('.')
							.map((domain_segment) => {
								if (domain_segment === 'admin') {
									let correct_subdomain = this.$route.params.mpid.toLowerCase();
									Object.keys(subdomains).forEach((subdomain) => {
										if (subdomains[subdomain] === this.$route.params.mpid) {
											correct_subdomain = subdomain;
										}
									});
									return correct_subdomain;
								}
								return domain_segment;
							})
							.join('.');

						window.open(`${window.location.protocol}//${mp_domain}`, '_blank');
						this.show_mimic_user_modal = false;
						this.mimic_password = '';
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to mimic the user login. Please try again.',
						detail: err.message,
					});
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.name {
	font-size: 1rem;
}

.tool {
	cursor: pointer;
}

.none-to-show {
	border: 1px solid var(--gray-50);
	padding: 1rem;
}

a {
	&.archived {
		color: var(--black);
	}

	&.suspended {
		color: var(--gray-50);
	}
}
</style>
