<template>
	<p-dialog
		v-model:visible="show_modal"
		modal
		header="Conversions by Sub Id"
		:style="{ width: '55vw' }"
		:dismissable-mask="true"
	>
		<p-data-table
			id="sub_id_table"
			ref="dt"
			:loading="loading"
			:rowClass="
				() => {
					return 'conversion_sub_id_row';
				}
			"
			scrollable
			:value="data"
			class="sub-id-table"
		>
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>
			<p-column header="Sub Id" sortable sortField="sub_id">
				<template #body="row">
					{{ row.data.sub_id }}
				</template>
			</p-column>
			<p-column header="Spend" class="column-align-right" sortable sortField="total_spend">
				<template #body="row">
					{{ currency(row.data.total_spend) }}
				</template>
			</p-column>
			<p-column header="Count" class="column-align-right" sortable sortField="total_purchase_count">
				<template #body="row">
					{{ row.data.total_purchase_count }}
				</template>
			</p-column>
			<p-column header="CPA" class="column-align-right" sortable sortField="CPA">
				<template #body="row">
					{{ currency(row.data.CPA) }}
				</template>
			</p-column>
			<p-column header="Sold" class="column-align-right" sortable sortField="Sold">
				<template #body="row">
					{{ row.data.Sold }}
				</template>
			</p-column>
			<p-column header="Returned" class="column-align-right" sortable sortField="Returned">
				<template #body="row">
					{{ row.data.Returned }}
				</template>
			</p-column>
			<p-column header="Contacted" class="column-align-right" sortable sortField="Contacted">
				<template #body="row">
					{{ row.data.Contacted }}
				</template>
			</p-column>
			<p-column header="New" class="column-align-right" sortable sortField="new">
				<template #body="row">
					{{ row.data.New }}
				</template>
			</p-column>
			<p-column-group type="footer">
				<p-row>
					<p-column :footer="`TOTALS: ${totals.source_id}`" footerStyle="text-align:right"> </p-column>
					<p-column footerStyle="text-align:right" :footer="currency(totals.total_spend)" />
					<p-column footerStyle="text-align:right" :footer="totals.total_purchase_count" />
					<p-column footerStyle="text-align:right" :footer="currency(totals.CPA)" />
					<p-column footerStyle="text-align:right" :footer="totals.Sold" />
					<p-column footerStyle="text-align:right" :footer="totals.Returned" />
					<p-column footerStyle="text-align:right" :footer="totals.Contacted" />
					<p-column footerStyle="text-align:right" :footer="totals.New" />
				</p-row>
			</p-column-group>
		</p-data-table>
	</p-dialog>
</template>
<script lang="ts">
import { reportSubIdConversions } from '@GQL';
import { currency } from '@/lib/Filters';
import { sumBy } from 'lodash-es';
import pColumnGroup from 'primevue/columngroup';

export default {
	name: 'ConversionsBySubIdModal',
	components: {
		pColumnGroup,
	},
	data() {
		return {
			show_modal: false,
			loading: false,
			data: [],
			totals: {},
		};
	},
	methods: {
		currency,
		async openModal(params) {
			// this is wjere we calll to ge tdata and open the modal
			this.show_modal = true;
			this.loading = true;

			try {
				const to_send = {
					source_id: params.source_id,
					filters: params.query.filters,
					date_range: params.query.date_range,
				};
				if ('account_id' in params) {
					to_send.account_id = params.account_id;
				} else if ('mpid' in params) {
					to_send.mpid = params.mpid;
				}
				const results = await reportSubIdConversions(to_send);
				this.data = [...results.rows];
				this.totals = {
					source_id: to_send.source_id,
					total_spend: sumBy(this.data, 'total_spend'),
					total_purchase_count: sumBy(this.data, 'total_purchase_count'),
					Sold: sumBy(this.data, 'Sold'),
					Returned: sumBy(this.data, 'Returned'),
					Contacted: sumBy(this.data, 'Contacted'),
					New: sumBy(this.data, 'New'),
				};
				this.totals.CPA = this.totals.Sold !== 0 ? this.totals.total_spend / this.totals.Sold : 0;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get data',
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
