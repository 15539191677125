<template>
	<div id="my-profile" class="view-content">
		<div class="page-heading">
			<h1>My Profile</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div v-if="user" class="content">
			<p-card class="user-card">
				<template #content>
					<div class="w-full">
						<div class="flex pb-1 align-items-center">
							<div class="avatar">
								<Avatar
									:user="{ ...user, avatar_url: sessionStore.user.avatar_url }"
									size="xlarge"
									:key="componentKey"
								/>
							</div>
							<div class="user-info flex flex-column gap-1 pl-3">
								<div class="name">{{ `${user.first_name} ${user.last_name}` }}</div>
								<div class="email">{{ `${user.email}` }}</div>
							</div>
							<div class="flex flex-grow-1 justify-content-end mt-1">
								<p-button size="small" text @click.prevent="edit_avatar_open = true">Edit Avatar</p-button>
							</div>
						</div>
					</div>
				</template>
			</p-card>
			<gutter size="20px" />
			<p-card>
				<template #content>
					<UserForm :user="user" :show_submit="true" />
				</template>
			</p-card>
			<p-dialog v-model:visible="edit_avatar_open" :modal="true" :dismissable-mask="true" class="edit-avatar-modal">
				<template #header>
					<div class="flex justify-content-start align-items-center gap-2">
						<i class="pi pi-user"></i>
						<p class="m-0 text-xl font-bold">Edit Avatar</p>
					</div>
				</template>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<imageUpload :filename="user.id" folder="avatar" @success="uploadAvatar" />
							</div>
						</div>
					</div>
				</div>
				<gutter size="20px" />
				<div class="flex flex-column gap-2 justify-content-center align-items-center m-2 preview" v-if="avatar_url">
					Preview
					<Avatar
						:user="{ ...user, avatar_url: `${avatar_url}?${new Date().getTime}` }"
						:key="componentKey + 1"
						size="xlarge"
					/>
				</div>
				<gutter size="20px" />
				<div class="flex justify-content-end">
					<p-button @click="saveAvatar">Save Changes</p-button>
				</div>
			</p-dialog>
		</div>
	</div>
</template>

<script lang="ts">
import Avatar from '@/components/widgets/User/Avatar.vue';
import pDialog from 'primevue/dialog';
import imageUpload from '@/components/forms/ImageUpload.vue';
import UserForm from '@/views/Users/Record/Form.vue';
import { useSessionStore } from '@/stores/session';
import { reactive } from 'vue';
import { updateUser } from '@GQL';

export default {
	name: 'MyProfile',
	components: {
		Avatar,
		pDialog,
		imageUpload,
		UserForm,
	},
	inject: ['mq'],
	setup() {
		const sessionStore = useSessionStore();
		return { sessionStore };
	},
	data() {
		return {
			edit_avatar_open: false,
			breadcrumb_items: [
				{
					label: 'My Profile',
				},
			],
			avatar_url: null,
			componentKey: 0,
		};
	},
	async created() {
		this.user = reactive({ ...this.sessionStore.user });
	},
	methods: {
		uploadAvatar(result) {
			if (result) {
				this.avatar_url = result[0].location;
			}
		},
		async saveAvatar() {
			if (this.avatar_url) {
				try {
					await updateUser(this.sessionStore.user.id, { avatar_url: this.avatar_url });
					this.edit_avatar_open = false;
					this.sessionStore.user.avatar_url = `${this.avatar_url}?${new Date().getTime}`;
					this.componentKey += 1;
					this.$toast.add({
						severity: 'success',
						summary: 'Updated Avatar',
						detail: 'Successfully updated profile.',
						life: 3000,
					});
				} catch (e) {
					this.$toast.add({
						severity: 'error',
						summary: 'Error updating avatar',
						detail: 'See logs for more details',
						life: 5000,
					});
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.edit-avatar-modal {
	max-width: 500px;
	width: 80%;

	.p-colorpicker-preview {
		height: 2rem;
		width: 2rem;
	}
}

#my-profile {
	.content {
		max-width: 800px;
	}

	.user-card {
		font-size: var(--font-size-sm);

		.content {
			align-items: center;
			display: flex;
			gap: 0.5rem;
			justify-content: flex-start;

			.mobile({
				align-items: flex-start;
				flex-direction: column;
			});
		}

		.name {
			font-weight: bold;
		}

		.email,
		.role {
			color: var(--gray-50);
		}
	}
}
</style>
