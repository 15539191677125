import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function getLeadList(params: GetListParams) {
	const query = `
        query GetLeadList($params: GetListParams!) {
        	leadList(params: $params) {
				rows {
					id
					mpid
					ulid
					vendor_id
					vendor_lead_id
					product
					vertical_id
					first_name
					last_name
					street_1
					street_2
					city
					state
					zip
					county
					lat
					lng
					email
					phone
					data
					meta
					dnc
					leadid_token
					trustedform_id
					trustedform_cert_url
					trustedform_share_url
					elid
					spoken_language
					tcpa
					permanent_id
					session_id
					source_id
					sub_id
					source_tags
					business_unit
					source_type
					source_segments
					site_id
					click_network
					click_token
					tags
					duplicate
					spam
					test
					cost
					created_at
					modified_at
				}
        	}
        }
    `;

	const result = await $GQL.request(query, { params });
	return result.leadList;
}
