<template>
	<div class="select-vendor">
		<line-loader :show="loading" />
		<h2>Select a Vertical</h2>
		<div v-if="!loading" class="vendor-options">
			<template v-for="vendor in vendorOptions" :key="vendor.id">
				<div
					:class="[{ selected: selected_vendor === vendor.id }]"
					class="option shadow-2"
					@click="selectVendor(vertical.value)"
				>
					<div class="card-header" :style="{ 'background-image': `url(${vendor.settings.icon_url})` }"></div>
					<div class="content">
						<div class="name">{{ vendor.name }}</div>
						<p-button v-if="selected_vendor === vendor.id" class="selected w-full" label="SELECTED ✓" />
						<p-button v-else label="SELECT" class="w-full" outlined />
					</div>
				</div>
			</template>
			<div v-if="vendorOptions.length === 0" class="option shadow-2">
				<div class="no-vertical-msg">
					There are no available vendors to target, please contact an administrator to update campaign settings.
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { useCampaignStore } from '@/stores/campaign';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapWritableState, mapStores } from 'pinia';
import { getVendors } from '@GQL';

export default {
	name: 'SelectVendor',
	emits: ['skip', 'select'],
	data() {
		return {
			loading: true,
			vendors: [],
			selected_vendor: null,
		};
	},
	computed: {
		isNew() {
			return this.$route.meta.new;
		},
		vendorOptions() {
			const vendor_settings = this.marketplaceStore.settings.vendors || this.$root.appStore.settings.vendors;
			return this.vendors.filter((vendor) => {
				return vendor_settings[vendor.id] && vendor_settings[vendor.id].enabled;
			});
		},
		...mapWritableState(useCampaignStore, ['campaign']),
		...mapStores(useMarketplaceStore),
	},
	async mounted() {
		this.vendors = await getVendors();
		if (this.isNew) {
			if (this.vendorOptions.length > 0) {
				this.selectVendor(this.vendorOptions[0].id);
				if (this.$route.query.duplicate) {
					this.selectVendor(this.campaign.vendor_id_targeting);
				}
			} else {
				this.selected_vendor = null;
			}
		} else {
			this.selectVendor(this.campaign.vendor_id_targeting);
		}

		if (this.vendorOptions.length < 2) {
			this.$emit('skip', 'select_vertical');
		}

		this.loading = false;
	},
	methods: {
		selectVendor(value) {
			this.selected_vendor = value;
			this.$emit('select');
		},
		next() {
			if (this.selected_vendor) {
				this.campaign.vendor_id_targeting = this.selected_vendor;
				return 'select_vertical';
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'No Vendor Selected',
					detail: 'Please select a vendor.',
					life: 3000,
				});
				return null;
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

h2 {
	font-size: var(--font-size-h4);
}

.select-vendor {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.vendor-options {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1rem 0;

	.mobile({
		justify-content: center;
	});

	.option {
		background: var(--white);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 20px;
		min-height: 320px;
		text-align: center;
		transition: all 150ms ease-out;
		width: 320px;

		.card-header {
			background-position: 50% 50%;
			background-size: cover;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			height: 200px;
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;
			padding: 40px;
		}

		.name {
			font-size: 1.125rem;
			font-weight: bold;
			margin: 0 0 1rem;
		}

		.description {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
			line-height: 1.5;
			margin-bottom: 1rem;
		}

		&.selected {
			box-shadow: 0 0 0 3px var(--color-b) !important;
		}
	}
}
</style>
