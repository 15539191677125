import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';
import { subDays, endOfDay, startOfDay } from 'date-fns';

export async function getNoteList(params: GetListParams, account_id: string, refresh?: boolean) {
	const query = `
    query GetNoteList($params: GetListParams!, $account_id: String!){
        noteList(params: $params) {
            rows {
                id
                account_id
                body
                data
                type
                created_at
                created_by_user {
                    id
                    first_name
                    last_name
                }
            }
        }
        getAccountChangesForNotes(account_id: $account_id)
        getAccountManagerPhoneLogs(params: $params) {
			user {
				first_name
				last_name
			}
			account_manager {
				first_name
				last_name
			}
			duration
			log_type
			created_at
        }
    }       
    `;

	const no_cache = refresh ? ['noteList', 'accountManagerPhoneLogList'] : undefined;

	const result = await $GQL.request(query, {
		params,
		account_id,
		no_cache,
	});

	return result;
}
