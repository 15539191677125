// ----------------------------------------------------------------------------------------------------
// FILTER: displaySelected
// Format the selected items in an array for display
// ----------------------------------------------------------------------------------------------------

export function displaySelected(value: any, options: any[], max_length: number = 25) {
	if (Array.isArray(value)) {
		if (options && Array.isArray(options)) {
			if (value.length === options.length || value.length === 0) {
				return 'All';
			}

			let display_value;
			if (options) {
				display_value = value.map((item_value) => {
					return options.find((option) => {
						return option.value === item_value;
					}).label;
				});

				if (display_value.join(', ').length <= (max_length || 25)) {
					display_value = display_value.join(', ');
				} else {
					display_value = value.length + ' selected';
				}
			}

			return display_value;
		}
	} else {
		// try to find the value in the options
		const findIndex = options.findIndex((option) => {
			if ('value' in option && option.value === value) {
				return true;
			} else {
				return false;
			}
		});
		if (findIndex > -1) {
			return options[findIndex].label;
		} else {
			// ALL
			return 'All';
		}
	}

	return value;
}
