import { $GQL } from '@GQL/index';
import type { Network } from '@nextgenleads/source-driver';

export async function updateNetwork(id: string, data: Partial<Network>) {
	const mutation = `
        mutation UpdateNetwork($id: ID!, $data: JSONObject!) {
            updateNetwork(id: $id, data: $data) {
                id
            }
        }
    `;

	return await $GQL.request(mutation, { id, data });
}
