<template>
	<div id="api-keys-form" class="view-content">
		<div class="page-heading">
			<h1>{{ is_create ? 'Create API Key' : 'Edit API Key' }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<APIKeyForm />
	</div>
</template>

<script>
import APIKeyForm from './Form.vue';
export default {
	name: 'APIKeysList',
	components: {
		APIKeyForm,
	},
	data() {
		return {
			breadcrumb_items: [
				{
					label: 'API Keys',
					route: '/api-keys',
				},
				{
					label: this.is_create ? 'Create' : 'Edit',
				},
			],
		};
	},
	computed: {
		is_create() {
			return this.$route.params.api_key_id ? false : true;
		},
	},
};
</script>
