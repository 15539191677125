<template>
	<div>
		<h2>Funding Actions</h2>
		<p-dialog v-model:visible="show_modal" modal :dismissable-mask="true" style="width: 600px">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
					<strong>Edit Funding Action</strong>
				</div>
			</template>
			<div class="content">
				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req" for="role-id">Funding Action:</label>
									<p-input-text v-model="new_funding_action" class="p-inputtext" placeholder="e.g.Outbound call" />
									<div v-if="v$.new_funding_action.$error" class="validation-error">
										{{ v$.new_funding_action.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req">Action Description:</label>
									<p-input-text
										v-model="new_funding_action_description"
										class="p-inputtext"
										placeholder="e.g. We called the customer and they funded while on the phone with us...."
									/>
									<div v-if="v$.new_funding_action_description.$error" class="validation-error">
										{{ v$.new_funding_action_description.$errors[0].$message }}
									</div>
									<gutter size="5px" />
									<p class="sub-value">
										This description will be shown to anyone editing the Funding Action in the account page
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<gutter size="15px" />

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req"
										>Who can edit value after set to <strong>{{ new_funding_action }}</strong
										>:</label
									>
									<p-select-button
										v-model="new_funding_action_editable"
										:options="[
											{ value: true, label: 'Any User' },
											{ value: false, label: 'Admin Only' },
										]"
										optionLabel="label"
										optionValue="value"
									/>
									<gutter size="5px" />
									<p class="sub-value">
										This options put a "lock" on who can edit the funding action value. The "lock" will be used only if
										the funding action is set to this option. "Only Admin" should be selected for funding actions that
										would be set automatically by some trigger.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req">Allow Custom Text Input:</label>
									<p-select-button
										v-model="new_funding_action_custom_input"
										:options="[
											{ value: false, label: 'Drop Down Only' },
											{ value: true, label: 'Allow Text Input' },
										]"
										optionLabel="label"
										optionValue="value"
									/>
									<gutter size="5px" />
									<p class="sub-value">
										When "Allow Text Input" is set, there will be a text input shown to the funding action editor. This
										is mostly used for "Other" options
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<template #footer class="flex justify-content-end">
				<p-button text label="Cancel" @click="cancelChange" />
				<p-button label="Add Change" @click="saveChange" />
			</template>
		</p-dialog>
		<gutter size="20px" />

		<div>
			<p-button label="Add Funding Action" @click="openModal" />
			<gutter v-if="dirty" size="20px" />
			<div v-if="dirty" class="flex justify-content-between">
				<div>
					<h3 :style="{ color: 'red', 'font-style': 'italic' }">Changes pending, Save to implement</h3>
				</div>
				<div class="flex justify-content-end">
					<p-button label="Reset" text @click="reset" />
					<p-button :disable="hasPendingChange" label="Save" @click="save" />
				</div>
			</div>
		</div>

		<gutter size="20px" />

		<p-data-table ref="dt" :value="funding_action_options" key="key" :loading="loading">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>

			<p-column header="Funding Action">
				<template #body="row">
					{{ row.data.key }}
				</template>
			</p-column>
			<p-column header="Action Description">
				<template #body="row">
					{{ row.data.value.description }}
				</template>
			</p-column>
			<p-column header="Changes">
				<template #body="row">
					{{ row.data.value.editable ? 'Any' : 'Admin Only' }}
				</template>
			</p-column>
			<p-column header="Text Input">
				<template #body="row">
					{{ row.data.value.custom_input ? 'Yes' : 'No' }}
				</template>
			</p-column>
			<p-column class="align-center" header="Tools">
				<template #body="row">
					<p-button v-tooltip.top="'Edit'" text @click="editValue(row.data)">
						<template #icon>
							<icon type="pencil-box-outline" size="24px" />
						</template>
					</p-button>
					<p-button v-tooltip.top="'Delete'" text @click="removeValue(row.data)">
						<template #icon>
							<icon type="trash-can-outline" size="24px" />
						</template>
					</p-button>
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>
<script>
import { getSettings, updateSetting } from '@GQL';
import { cloneDeep, findIndex } from 'lodash-es';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
export default {
	name: 'FundingActionOptions',
	emits: ['refresh'],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			show_modal: false,
			loading: false,
			original_map: null,
			funding_action_options: [],
			new_funding_action: '',
			new_funding_action_description: '',
			new_funding_action_editable: true,
			new_funding_action_custom_input: false,
			dirty: false,
		};
	},
	computed: {
		hasPendingChange() {
			return this.new_funding_action_description !== '' || this.new_funding_action !== '';
		},
	},
	async mounted() {
		await this.getSetting();
	},
	methods: {
		cancelChange() {
			this.new_funding_action = '';
			this.new_funding_action_description = '';
			this.new_funding_action_editable = true;
			this.new_funding_action_custom_input = false;
			this.v$.$reset();
			this.show_modal = false;
		},
		async saveChange() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				this.funding_action_options = [
					{
						key: this.new_funding_action,
						value: {
							description: this.new_funding_action_description,
							editable: this.new_funding_action_editable,
							custom_input: this.new_funding_action_custom_input,
						},
					},
					...this.funding_action_options,
				];
				this.dirty = true;
				this.cancelChange();
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Cannot save change',
					detail: 'One of the required fields is missing',
					life: 7000,
				});
			}
		},
		async save() {
			try {
				this.loading = true;
				// check that nothhing is pending in the new
				const new_map = {};
				this.funding_action_options.forEach((row) => {
					new_map[row.key] = row.value;
				});
				const settings_result = await updateSetting(
					'funding_action_options',
					{
						data: new_map,
					},
					'$NG',
					true
				);
				this.dirty = false;
				if (settings_result.id === 'funding_action_options') {
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved Funding Action Options',
						life: 5000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save settings',
						life: 7000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getSetting() {
			this.loading = true;
			try {
				// only ever global
				const settings_result = await getSettings(['funding_action_options'], '$NG', true);
				this.original_map = cloneDeep(settings_result.settings.funding_action_options);
				this.funding_action_options = Object.keys(this.original_map).map((key) => {
					return {
						key: key,
						value: this.original_map[key],
					};
				});
			} finally {
				this.loading = false;
			}
		},
		reset() {
			this.funding_action_options = Object.keys(this.original_map).map((key) => {
				return {
					key: key,
					value: this.original_map[key],
				};
			});
			this.dirty = false;
			this.cancelChange();
		},
		editValue(row) {
			this.dirty = true;
			this.new_funding_action = row.key;
			this.new_funding_action_description = row.value.description;
			this.new_funding_action_custom_input = row.value.custom_input;
			this.new_funding_action_editable = row.value.editable;
			this.removeValue(row);
			this.show_modal = true;
		},
		removeValue(row) {
			this.dirty = true;
			this.loading = true;
			const index = findIndex(this.funding_action_options, { key: row.key });
			let copy = cloneDeep(this.funding_action_options);
			this.funding_action_options = [...copy];
			this.loading = false;
		},
		openModal() {
			this.cancelChange();
			this.show_modal = true;
		},
	},
	validations() {
		return {
			new_funding_action_description: {
				required: helpers.withMessage('A funding action description is required.', required),
			},
			new_funding_action: {
				required: helpers.withMessage('Funding Action is required.', required),
			},
		};
	},
};
</script>
