<template>
	<div class="qualifier channels">
		<div class="title">
			<div class="name">Channels</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">Disqualify a campaign if it is not targeting the channel of the lead.</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ChannelsQualifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
