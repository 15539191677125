<template>
	<div id="settings" class="view-content">
		<div class="page-heading">
			<h1>Settings</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="marketplace-settings page-content">
			<row>
				<column :span="3">
					<div class="card">
						<row>
							<column :span="1" class="padded">
								<p-menu :model="settingsNav" />
							</column>
							<div class="divider" />
							<column :span="3" class="padded">
								<component
									:is="selected_component"
									v-if="selected_component"
									:local-settings-list="local_settings_list"
									@refresh="getLocalSettingsList()"
								/>
								<template v-else>
									<p>Please select a category on the left to update settings</p>
								</template>
							</column>
						</row>
					</div>
				</column>
				<column :span="2"> </column>
			</row>
		</div>
	</div>
</template>

<script>
import pMenu from 'primevue/menu';
import account_owners from '../Components/AccountOwners.vue';
import auctions from '../Components/MarketplaceAuctions.vue';
import cancellations from '../Components/Cancellations.vue';
import navigation from '../Components/Navigation.vue';
import premiumListings from '../Components/PremiumListings.vue';
import verticals from '../Components/Verticals.vue';
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { getLocalSettingsList } from '@GQL';

export default {
	name: 'MarketplaceSettings',
	components: {
		pMenu,
		account_owners,
		auctions,
		cancellations,
		navigation,
		premiumListings,
		verticals,
	},
	props: {},
	data() {
		return {
			breadcrumb_items: [],
			selected_component: null,
			local_settings_list: [],
			settings_nav: [
				{
					label: 'Account Owners',
					id: 'account_owners',
				},
				{
					label: 'Auctions',
					id: 'auctions',
				},
				{
					label: 'Cancellations',
					id: 'cancellations',
				},
				{
					label: 'Navigation',
					id: 'navigation',
				},
				{
					label: 'Premium Listings',
					id: 'premiumListings',
				},
				{
					label: 'Verticals',
					id: 'verticals',
				},
			],
		};
	},
	computed: {
		settingsNav() {
			const filtered_settings_nav = this.settings_nav.filter((nav_item) => {
				if (this.sessionStore.hasPermission([`Settings.${nav_item.id}`])) {
					return true;
				}
				return false;
			});
			return filtered_settings_nav.map((settings_nav_item) => {
				return {
					label: settings_nav_item.label,
					command: () => {
						this.selected_component = settings_nav_item.id;
						this.$router.push({
							query: {
								id: settings_nav_item.id,
							},
						});
					},
				};
			});
		},
		...mapStores(useAppStore, useMarketplaceStore, useSessionStore),
	},
	methods: {
		async getLocalSettingsList() {
			const mpid = this.marketplaceStore.mpid || this.appStore.mpid;
			if (mpid !== '$NG') {
				this.local_settings_list = await getLocalSettingsList(mpid);
			}
		},
	},
	async mounted() {
		await this.getLocalSettingsList();
		if (this.$route.query.id) {
			this.selected_component = this.$route.query.id;
		}
		if (this.$route.params.mpid) {
			this.breadcrumb_items.push(
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Settings',
				}
			);
		} else {
			this.breadcrumb_items.push({
				label: 'Settings',
			});
		}
	},
};
</script>

<style lang="less">
.marketplace-settings {
	h2 {
		font-size: 1.5rem;
		margin-bottom: 0;
	}

	h3 {
		font-size: 1.25rem;
	}

	.actions {
		display: flex;
		padding: 20px 0 0;
	}
}

.divider {
	border-right: 1px solid var(--gray-10);
}
</style>
