import { $GQL } from '@GQL/index';
import type { PaymentMethod } from '@nextgenlead/banker-driver';

export async function updatePaymentMethod(id: string, data: Partial<PaymentMethod>) {
	const mutation = `
        mutation UpdatePaymentMethod($id: ID!, $data: JSONObject!) {
            updatePaymentMethod(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updatePaymentMethod;
}
