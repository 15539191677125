import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getSubAccountsAsOptions(filters: QueryFilters) {
	const query = `
        query GetSubAccountsAsOptions($filters: [[Any]]) {
            subAccounts(filters: $filters) {
				label:name
                value:provider_account_id
				id
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.subAccounts;
}
