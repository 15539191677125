import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function reportMarketplacePurchases(
	params: { date_range: [string, string]; filters: QueryFilters; mpid: string; page?: number },
	refresh?: boolean
) {
	const query = `
        query ReportMarketplacePurchases($params: JSONObject!) {
            reportMarketplacePurchases(params: $params) {
				metrics
				rows
				row_count
			}
        }
    `;

	const no_cache = refresh ? ['reportMarketplacePurchases'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportMarketplacePurchases;
}
