<template>
	<div id="shipment-logs-list" class="view-content">
		<ShipmentLogDetailsModal ref="shipment_log_details" :shipment_log="selected_shipment" />
		<div class="page-heading">
			<h1>Shipment Logs</h1>
			<breadcrumb />
		</div>
		<div class="page-content">
			<query-form v-model:query="query" :loading="loading" @apply="getShipmentList">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
						</div>
					</div>

					<div class="display-value">
						<div class="label">Mode:</div>
						<div class="value">
							{{ displaySelected([query.mode], shipment_options) }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="getShipmentList(true)"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range v-model="form.query.filters.created_at" hide-labels show-time />
										</div>
									</div>
								</div>
							</div>
						</column>

						<gutter size="20px" />

						<column>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Log Type:</label>
									<div class="controls">
										<div class="field">
											<p-dropdown
												:options="shipment_options"
												v-model="form.query.mode"
												option-label="label"
												option-value="value"
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table
							v-if="query.mode === 'shipments'"
							ref="dt"
							scrollable
							:value="shipment_logs"
							:loading="loading"
						>
							<template #empty>
								<div class="dim">No Logs found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="date" header="Date">
								<template #body="row">
									{{ formatDate(new Date(row.data.created_at), 'YYYY-MM-DD') }}
									<div class="sub-value">
										{{ formatDate(new Date(row.data.created_at), 'h:mm:ssa') }}
									</div>
								</template>
							</p-column>
							<p-column field="shipper" header="Shipper">
								<template #body="row">
									<router-link
										v-if="row.data.shipper_id.trim() !== 'EMAIL_SHIPPER'"
										:to="`shippers/${row.data.shipper_id}`"
										>{{ row.data.shipper.name }}</router-link
									>
									<div v-else>Email</div>
									<div v-if="row.data.shipper_id.trim() !== 'EMAIL_SHIPPER'" class="sub-value">
										{{ row.data.shipper_id }}
									</div>
								</template>
							</p-column>
							<p-column field="campaign" header="Campaign">
								<template #body="row">
									<router-link :to="`campaigns/${row.data.campaign_id}`">{{ row.data.campaign.name }}</router-link>
									<div class="sub-value">
										{{ row.data.campaign_id }}
									</div>
								</template>
							</p-column>
							<p-column field="campaign" header="Lead">
								<template #body="row">
									<router-link :to="`purchases/${row.data.lead_id}`"
										>{{ row.data.lead.first_name }} {{ row.data.lead.last_name }}</router-link
									>
									<div class="sub-value">{{ row.data.lead_id }}: {{ phoneFormat(row.data.lead.phone) }}</div>
								</template>
							</p-column>
							<p-column field="status" header="Status" class="column-align-center">
								<template #body="row">
									{{ startCase(row.data.status) }}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Show'"
												aria-label="Show"
												@click="showDetails(row.data.request, row.data.response)"
											>
												<template #icon>
													<icon type="open-in-new" size="20px" />
												</template>
											</p-button>
										</div>
									</div>
								</template>
							</p-column>
						</p-data-table>
						<!--            THIS IS THE POST LOG TABLE-->
						<p-data-table v-else ref="dt" scrollable :value="post_logs" :loading="loading">
							<template #empty>
								<div class="dim">No Logs found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="date" header="Date">
								<template #body="row">
									{{ formatDate(new Date(row.data.created_at), 'YYYY-MM-DD') }}
									<div class="sub-value">
										{{ formatDate(new Date(row.data.created_at), 'h:mm:ssa') }}
									</div>
								</template>
							</p-column>
							<p-column field="integration" header="Integration Script">
								<template #body="row">
									{{ get(row, 'data.integration.post_settings.script', 'N/A') }}
									<div class="sub-value">Folder: {{ row.data.account_id }}</div>
								</template>
							</p-column>
							<p-column field="campaign" header="Campaign">
								<template #body="row">
									<router-link :to="`campaigns/${row.data.campaign_id}`">{{ row.data.campaign.name }}</router-link>
									<div class="sub-value">
										{{ row.data.campaign_id }}
									</div>
								</template>
							</p-column>
							<p-column field="campaign" header="Lead">
								<template #body="row">
									<router-link :to="`purchases/${row.data.lead_id}`"
										>{{ row.data.lead.first_name }} {{ row.data.lead.last_name }}</router-link
									>
									<div class="sub-value">{{ row.data.lead_id }}: {{ phoneFormat(row.data.lead.phone) }}</div>
								</template>
							</p-column>
							<p-column field="status" header="Status" class="column-align-center">
								<template #body="row">
									{{ startCase(row.data.result) }}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Show'"
												aria-label="Show"
												@click="showDetails(row.data.request, row.data.response)"
											>
												<template #icon>
													<icon type="open-in-new" size="20px" />
												</template>
											</p-button>
										</div>
									</div>
								</template>
							</p-column>
						</p-data-table>
						<p-paginator :rows="query.pagination.page_size" :total-records="total_records" @page="handlePageChange" />
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import pConfirmPopup from 'primevue/confirmpopup';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { getShipmentLogsList, getPostLogList } from '@GQL';
import { startOfDay, endOfDay } from 'date-fns';
import { formatDate, phoneFormat, displaySelected } from '@/lib/Filters';
import dateRange from '@/components/forms/DateRange.vue';
import { cloneDeep, get, startCase } from 'lodash-es';
import queryForm from '@/components/widgets/QueryForm.vue';
import ShipmentLogDetailsModal from '@/views/ShipmentLogs/Record/DetailsModal.vue';

export default {
	name: 'ShipperListView',
	components: {
		ShipmentLogDetailsModal,
		pConfirmPopup,
		queryForm,
		dateRange,
	},
	data() {
		return {
			loading: false,
			shipment_logs: [],
			post_logs: [],
			shipment_options: [
				{
					label: 'Shipper',
					value: 'shipments',
				},
				{
					label: 'Post Logs',
					value: 'post_log',
				},
			],
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
				},
				pagination: {
					page: 0,
					page_size: 100,
				},
				order: {
					field: 'created_at',
					desc: true,
				},
				mode: 'shipments',
			},
			total_records: 0,
			selected_shipment: {},
		};
	},
	computed: {
		accountId() {
			return this.$route.params.account_id || this.sessionStore.account.id;
		},
		basePath() {
			const split = this.$route.path.split('/');
			split.pop();
			return split.join('/');
		},
		...mapStores(useSessionStore),
	},
	async mounted() {
		await this.getShipmentList(true);
	},
	methods: {
		displaySelected,
		get,
		startCase,
		formatDate,
		phoneFormat,
		cloneDeep,
		async handlePageChange(data) {
			this.query.pagination.page = data.page;
			return this.getShipmentList();
		},
		async getShipmentList(refresh?: boolean) {
			this.loading = true;
			const filters = [
				[
					{
						field: 'created_at',
						op: 'bt',
						value: this.query.filters.created_at,
					},
					`account_id = '${this.accountId}'`,
				],
			];

			const query_to_send = { ...this.query, filters };
			delete query_to_send.mode;

			try {
				if (this.query.mode === 'shipments') {
					const { rows, row_count } = await getShipmentLogsList(query_to_send, refresh);
					this.shipment_logs = rows;
					this.total_records = row_count;
				} else {
					const { rows, row_count } = await getPostLogList(query_to_send, refresh);
					this.post_logs = rows;
					this.total_records = row_count;
				}
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get shippers',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		showDetails(request: string, response?: string) {
			this.$refs.shipment_log_details.openModal(request, response);
		},
	},
};
</script>

<style scoped lang="less">
:deep(.tools) {
	max-width: 120px;
}
</style>
