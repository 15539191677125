import { $GQL } from '@GQL';

export async function convertByPhone(params: { account_id: string; disposition: string; phone_numbers: string[] }) {
	const mutation = `
        mutation ConvertByPhone($params: JSONObject!) {
			convertByPhone(params: $params)
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.convertByPhone;
}
