<template>
	<div>
		<div id="offer-details" class="card">
			<div class="card-heading padded">
				<icon type="tag" size="24px" />
				<strong>Offer Details</strong>
				<p-button v-if="'offer' in account_offer" text @click="startFunding" v-tooltip.top="'Claim Offer'">
					<template #icon><icon type="gift-open" size="24px" /></template>
				</p-button>
			</div>

			<div class="padded">
				<div
					v-if="'offer' in account_offer"
					@click="startFunding"
					class="clickable"
					v-tooltip.top="'Click to claim Offer'"
				>
					<div class="line-item">
						<div class="label">Offer:</div>
						<div class="value">{{ account_offer.offer.name }}</div>
					</div>
					<div class="line-item">
						<div class="label">Requirement:</div>
						<div class="value">
							{{
								account_offer.offer.trigger === 'deposit'
									? `Deposit of +${currency(account_offer.offer.required_funding)}`
									: `First Funding of +${currency(account_offer.offer.required_funding)}`
							}}
						</div>
					</div>
					<div class="line-item">
						<div class="label">Credit:</div>
						<div class="value">
							{{
								account_offer.offer.credit_type === 'flat'
									? `${currency(account_offer.offer.credit_amount)} credit`
									: `${percentage(account_offer.offer.credit_amount, 2)} percentage`
							}}
						</div>
					</div>
					<div class="line-item expires clickable" v-if="account_offer.offer.expiration">
						<div class="label">Expires:</div>
						<div class="value">
							{{ formatDate(new Date(account_offer.offer.expiration), 'ddd D, YYYY') }}
						</div>
						<div>{{ timeAgo(account_offer.offer.expiration) }}</div>
					</div>
				</div>
				<div v-else>
					<div>
						<p>Redeem an offer using a code now!</p>
						<div class="control-group">
							<div class="inner">
								<div class="controls gap-10">
									<div class="field">
										<p-input-text v-model="offer_code" placeholder="Offer Code" />
									</div>
									<div class="field fit">
										<p-button
											v-tooltip.top="'Claim'"
											icon="pi pi-check"
											@click="claimOfferWithCode"
											aria-label="Claim"
											class="mr-2"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<AddFundsModal ref="add_funds_modal" />
	</div>
</template>
<script>
import { mapStores } from 'pinia';
import { getAccountOffers, insertAccountOffer, getOffersAsOptions } from '@GQL';
import { currency, capitalize, percentage, formatDate, timeAgo } from '@/lib/Filters';
import { useSessionStore } from '@/stores/session';
import AddFundsModal from '@/views/AddFunds/Modal.vue';

export default {
	components: {
		AddFundsModal,
	},
	props: {
		account_id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			offer_code: '',
			account_offer: {},
		};
	},
	computed: {
		...mapStores(useSessionStore),
	},
	async mounted() {
		await this.loadPendingOffers();
	},
	methods: {
		formatDate,
		currency,
		capitalize,
		percentage,
		timeAgo,
		async loadPendingOffers() {
			const offers = await getAccountOffers(`account_id = '${this.account_id}' AND status = 'pending'`);
			if (offers.length === 1) {
				this.account_offer = offers[0];
			}
		},
		async claimOfferWithCode() {
			this.loading = true;
			try {
				// see if the offer exists
				const offers = await getOffersAsOptions([[`'${this.offer_code}' = ANY(code_words)`, `status = 'active'`]]);

				if (offers.length === 0) {
					this.$toast.add({
						severity: 'error',
						summary: 'That Offer code was invalid',
						detail: 'The code may no longer be active, contact your account manager for further details',
						life: 10000,
					});
				} else {
					// get the first offer... there should only be one
					const offer_id = offers[0].value;

					const applied = await insertAccountOffer({
						offer_id,
						account_id: this.account_id,
						user_id: this.sessionStore.user.id,
						code_word: this.offer_code,
						status: 'pending',
					});

					if ('status' in applied) {
						this.account_offer = applied;
						this.$toast.add({
							severity: 'success',
							summary: 'Offer has been applied to your account',
							life: 7000,
						});
					} else {
						throw new Error('Error when applying offer');
					}
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to add the offer your account, reach out to your Account manager',
					life: 10000,
				});
			} finally {
				this.loading = false;
			}
		},
		startFunding() {
			this.$refs.add_funds_modal.openAddFundsModal(this.account_offer.offer.required_funding);
		},
	},
};
</script>

<style lang="less" scoped>
#offer-details {
	.expires {
		color: red;
	}
	.line-item {
		line-height: 1.35em;
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}

		.label {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
		}

		.value {
			font-weight: bold;
		}
	}
}
.card h2 {
	font-size: 1.25rem;
}

.chart-heading {
	gap: 20px;

	h2 {
		margin: 0;
	}
}

.clickable {
	&:hover {
		cursor: pointer;
	}
}
</style>
