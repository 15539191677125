<template>
	<div id="form-auction_config">
		<p-card class="auction-config-card">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="name">Name:</label>
						<div class="controls">
							<div class="field">
								<p-input-text id="name" v-model="auction_config.name" class="p-inputtext" />
								<div v-if="v$.auction_config.name.$error" class="validation-error">
									{{ v$.auction_config.name.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="description">Description:</label>
						<div class="controls">
							<div class="field">
								<p-textarea
									id="description"
									v-model="auction_config.description"
									class="p-inputtext w-full"
									style="height: 2rem"
								/>
								<div v-if="v$.auction_config.description.$error" class="validation-error">
									{{ v$.auction_config.description.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="vendor-id">Vendor:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="vendor-id"
									v-model="auction_config.vendor_id"
									option-label="label"
									option-value="value"
									:options="vendor_options"
									placeholder="Select vendor"
								/>
								<div v-if="v$.auction_config.vendor_id.$error" class="validation-error">
									{{ v$.auction_config.vendor_id.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="vertical">Vertical:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="vertical"
									v-model="auction_config.vertical_id"
									option-label="label"
									option-value="value"
									:options="$root.appStore.verticalOptions"
									placeholder="Select vertical"
								/>
								<div v-if="v$.auction_config.vertical_id.$error" class="validation-error">
									{{ v$.auction_config.vertical_id.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="product-type">Product Type:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="product-type"
									v-model="auction_config.product"
									option-label="label"
									option-value="value"
									:options="product_type_options"
									placeholder="Select product type"
								/>
								<div v-if="v$.auction_config.product.$error" class="validation-error">
									{{ v$.auction_config.product.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="source-types">Source Types:</label>
						<div class="controls">
							<div class="field">
								<p-multi-select
									id="source-types"
									v-model="auction_config.source_types"
									:options="source_type_options"
									option-label="label"
									option-value="value"
									placeholder="Select all source types"
								/>
								<div v-if="v$.auction_config.source_types.$error" class="validation-error">
									{{ v$.auction_config.source_types.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="lead-tags">Lead Tags:</label>
						<div class="controls">
							<div class="field">
								<p-chips
									add-on-blur
									id="lead-tags"
									v-model="auction_config.lead_tags"
									separator=","
									placeholder="Separate with a comma or by hitting enter"
								/>
								<div class="sub-value">Only handle leads with the included lead tags</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="source-tags">Source Tags:</label>
						<div class="controls">
							<div class="field">
								<p-chips
									add-on-blur
									id="source-tags"
									v-model="auction_config.source_tags"
									separator=","
									placeholder="Separate with a comma or by hitting enter"
								/>
								<div class="sub-value">Only handle leads with the included source tags</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="source-ids">Source IDs:</label>
						<div class="controls">
							<div class="field">
								<p-chips
									add-on-blur
									id="source-ids"
									v-model="auction_config.source_ids"
									separator=","
									placeholder="Separate by comma or by hitting enter"
								/>
							</div>
						</div>
					</div>
				</div>
				<p-fieldset legend="Bid Settings">
					<p>Select which bid types are available for use in this auction.</p>
					<div class="bid-settings">
						<div class="bid-setting-group">
							<div :class="['heading', { selected: auction_config.bid_settings.exclusive.enabled }]">
								<div class="name">Allow Exclusive Bids</div>
								<div class="toggle"><p-input-switch v-model="auction_config.bid_settings.exclusive.enabled" /></div>
							</div>
							<div :class="['bid-options', { disabled: !auction_config.bid_settings.exclusive.enabled }]">
								<template v-if="auction_config.bid_settings.exclusive.enabled">
									<div class="control-group">
										<div class="inner">
											<div class="field-row">
												<div class="field">Max Exclusive Winners</div>
												<div class="field xs">
													<p-input-number v-model="max_exclusive_winners" disabled />
												</div>
											</div>
											<div class="field-row">
												<div class="field">Use Custom Minimum Bid</div>
												<div class="field fit">
													<p-input-switch v-model="auction_config.bid_settings.exclusive.custom_minimum_bid" />
												</div>
											</div>
											<div class="field-row">
												<div class="field">Minimum Exclusive Bid</div>
												<div class="field xs">
													<div class="p-inputgroup">
														<div class="p-inputgroup-addon">$</div>
														<p-input-number
															v-model="auction_config.bid_settings.exclusive.minimum_bid"
															mode="decimal"
															:disabled="!auction_config.bid_settings.exclusive.custom_minimum_bid"
															:max-fraction-digits="2"
															:min-fraction-digits="2"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</template>
								<template v-else>
									<p-message severity="info" :closable="false">Exclusive bids are disabled</p-message>
								</template>
							</div>
						</div>
						<div class="bid-setting-group">
							<div :class="['heading', { selected: auction_config.bid_settings.shared.enabled }]">
								<div class="name">Allow Shared Bids</div>
								<div class="toggle"><p-input-switch v-model="auction_config.bid_settings.shared.enabled" /></div>
							</div>
							<div :class="['bid-options', { disabled: !auction_config.bid_settings.shared.enabled }]">
								<template v-if="auction_config.bid_settings.shared.enabled">
									<div class="control-group">
										<div class="inner">
											<div class="field-row">
												<div class="field">Use Custom Max Winners</div>
												<div class="field fit">
													<p-input-switch v-model="auction_config.bid_settings.shared.custom_max_winners" />
												</div>
											</div>
											<div class="field-row">
												<div class="field">Max Shared Winners</div>
												<div class="field xs">
													<p-input-number
														v-model="auction_config.bid_settings.shared.max_winners"
														:disabled="!auction_config.bid_settings.shared.custom_max_winners"
													/>
												</div>
											</div>
											<div class="field-row">
												<div class="field">Use Custom Minimum Bid</div>
												<div class="field fit">
													<p-input-switch v-model="auction_config.bid_settings.shared.custom_minimum_bid" />
												</div>
											</div>
											<div class="field-row">
												<div class="field">Minimum Shared Bid</div>
												<div class="field xs">
													<div class="p-inputgroup">
														<div class="p-inputgroup-addon">$</div>
														<p-input-number
															v-model="auction_config.bid_settings.shared.minimum_bid"
															mode="decimal"
															:disabled="!auction_config.bid_settings.shared.custom_minimum_bid"
															:max-fraction-digits="2"
															:min-fraction-digits="2"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</template>
								<template v-else>
									<p-message severity="info" :closable="false">Shared bids are disabled</p-message>
								</template>
							</div>
						</div>
					</div>
					<div v-if="v$.bid_settings.$error" class="validation-error spaced">
						{{ v$.bid_settings.$errors[0].$message }}
					</div>
				</p-fieldset>
				<p-fieldset legend="Validators">
					<p>Select all validators you would like to run for leads in this auction.</p>
					<div class="validators">
						<div :class="['validator', { selected: isSelected(auction_config.validators, 'isDNC') }]">
							<div class="validator-icon">
								<icon type="phone-cancel" size="24px" />
							</div>
							<label class="label" for="dnc-validator">
								<div class="name">Exclude DNC Leads</div>
								<div class="description">
									Reject leads that appears on the soft DNC list in the past 90 days or are on the hard DNC list.
								</div>
							</label>
							<div class="control">
								<p-checkbox v-model="auction_config.validators" input-id="dnc-validator" :value="{ script: 'isDNC' }" />
							</div>
						</div>
						<div :class="['validator', { selected: isSelected(auction_config.validators, 'isPing') }]">
							<div class="validator-icon">
								<icon type="swap-horizontal" size="24px" />
							</div>
							<label class="label" for="ping-validator">
								<div class="name">Exclude Ping Leads</div>
								<div class="description">Reject a lead if it is from a ping source.</div>
							</label>
							<div class="control">
								<p-checkbox
									v-model="auction_config.validators"
									input-id="ping-validator"
									:value="{ script: 'isPing' }"
								/>
							</div>
						</div>
						<div :class="['validator', { selected: isSelected(auction_config.validators, 'isDuplicate') }]">
							<div class="validator-icon">
								<icon type="content-copy" size="24px" />
							</div>
							<label class="label" for="spam-validator">
								<div class="name">Exclude Duplicate Leads</div>
								<div class="description">Reject a lead if it is marked as a duplicate.</div>
							</label>
							<div class="control">
								<p-checkbox
									v-model="auction_config.validators"
									input-id="duplicate-validator"
									:value="{ script: 'isDuplicate' }"
								/>
							</div>
						</div>
						<div :class="['validator', { selected: isSelected(auction_config.validators, 'isSpam') }]">
							<div class="validator-icon">
								<icon type="message-alert" size="24px" />
							</div>
							<label class="label" for="spam-validator">
								<div class="name">Exclude Spam Leads</div>
								<div class="description">Reject a lead if it has been flagged as spam.</div>
							</label>
							<div class="control">
								<p-checkbox
									v-model="auction_config.validators"
									input-id="spam-validator"
									:value="{ script: 'isSpam' }"
								/>
							</div>
						</div>
						<div :class="['validator', { selected: isSelected(auction_config.validators, 'isTest') }]">
							<div class="validator-icon">
								<icon type="clipboard-check-outline" size="24px" />
							</div>
							<label class="label" for="test-validator">
								<div class="name">Exclude Test Leads</div>
								<div class="description">Reject a lead if it has been flagged as a test lead.</div>
							</label>
							<div class="control">
								<p-checkbox
									v-model="auction_config.validators"
									input-id="test-validator"
									:value="{ script: 'isTest' }"
								/>
							</div>
						</div>
					</div>
				</p-fieldset>
				<p-fieldset legend="Qualifiers">
					<div class="qualifiers">
						<p-message v-if="sortedQualifiers.length === 0" :closable="false"
							>Add qualifiers to reduce the amount of campaigns that compete in leads for this auction.</p-message
						>
						<template v-for="qualifier in sortedQualifiers" :key="qualifier.script">
							<component
								:is="`${qualifier.script}Qualifier`"
								v-model="qualifier.settings"
								@remove="removeQualifier(qualifier.script)"
							/>
						</template>
					</div>
					<qualifier-selector :auction-config="auction_config" @addQualifier="appendQualifier" />
				</p-fieldset>
				<p-fieldset legend="Sorting Bid Modifiers">
					<div class="modifiers sorting-bid-modifiers" v-sortable="'sorting_bid_modifiers'">
						<p-message v-if="auction_config.sorting_bid_modifiers.length === 0" :closable="false"
							>Add modifiers to change the sorting bid for bid type groups in this auction.</p-message
						>
						<template v-for="modifier in auction_config.sorting_bid_modifiers" :key="modifier.script">
							<component
								:is="`${modifier.script}Modifier`"
								v-model="modifier.settings"
								@remove="removeModifier(modifier.script, 'sorting_bid_modifiers')"
							/>
						</template>
					</div>
					<modifier-selector
						:modifiers="auction_config.sorting_bid_modifiers"
						modifier-type="sorting_bid_modifiers"
						@addModifier="appendModifier($event, 'sorting_bid_modifiers')"
					/>
				</p-fieldset>
				<p-fieldset legend="Effective Bid Modifiers">
					<div class="modifiers effective-bid-modifiers" v-sortable="'bid_modifiers'">
						<p-message v-if="auction_config.bid_modifiers.length === 0" :closable="false"
							>Add modifiers to change the sorting bid for bid type groups in this auction.</p-message
						>
						<template v-for="modifier in auction_config.bid_modifiers" :key="modifier.script">
							<component
								:is="`${modifier.script}Modifier`"
								v-model="modifier.settings"
								@remove="removeModifier(modifier.script, 'bid_modifiers')"
							/>
						</template>
					</div>
					<modifier-selector
						:modifiers="auction_config.bid_modifiers"
						modifier-type="bid_modifiers"
						@addModifier="appendModifier($event, 'bid_modifiers')"
					/>
				</p-fieldset>
				<p-fieldset legend="Group Sorting Bid Modifiers">
					<div class="modifiers group-sorting-bid-modifiers" v-sortable="'group_sorting_bid_modifiers'">
						<p-message v-if="auction_config.group_sorting_bid_modifiers.length === 0" :closable="false"
							>Add modifiers to change the sorting bid for bid type groups in this auction.</p-message
						>
						<template v-for="modifier in auction_config.group_sorting_bid_modifiers" :key="modifier.script">
							<component
								:is="`${modifier.script}Modifier`"
								v-model="modifier.settings"
								@remove="removeModifier(modifier.script, 'group_sorting_bid_modifiers')"
							/>
						</template>
					</div>
					<modifier-selector
						:modifiers="auction_config.group_sorting_bid_modifiers"
						modifier-type="group_sorting_bid_modifiers"
						@addModifier="appendModifier($event, 'group_sorting_bid_modifiers')"
					/>
				</p-fieldset>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="status">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="auction_config.status"
									option-label="label"
									option-value="value"
									:options="active_status_options"
									placeholder="Select status"
								/>
								<div v-if="v$.auction_config.status.$error" class="validation-error">Status is required.</div>
							</div>
						</div>
					</div>
				</div>
				<gutter size="20px" />
			</template>
			<template #footer>
				<div class="flex justify-content-end">
					<p-button icon="pi pi-check" label="Save Changes" @click="save" />
				</div>
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import Sortable from 'sortablejs';
import { active_status_options, product_type_options } from '@/lib/Options';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { cloneDeep, remove, sortBy } from 'lodash-es';
import { ulid } from 'ulid';
import pChips from 'primevue/chips';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import pMultiSelect from 'primevue/multiselect';
import pTextarea from 'primevue/textarea';
import qualifierSelector from './Qualifiers/QualifierSelector.vue';
import modifierSelector from './Modifiers/ModifierSelector.vue';
import { getVendorsAsOptions, getAuctionConfigById, insertAuctionConfig, updateAuctionConfig } from '@GQL/index';
import log from '@/lib/Log';
import {
	AccountNotInterestedQualifier,
	AuctionAttemptsLimitQualifier,
	BalanceQualifier,
	BidTypeQualifier,
	BudgetQualifier,
	CancelRateQualifier,
	ChannelsQualifier,
	ConcurrentCallLimitQualifier,
	CustomFilterMatchQualifier,
	ExplicitCampaignMatchQualifier,
	FiltersQualifier,
	PremiumListingQualifier,
	PreviouslyPurchasedQualifier,
	PromotionQualifier,
	ScheduleQualifier,
	SourceTagsQualifier,
	ThrottlingQualifier,
	UndialableQualifier,
	USHAValidationQualifier,
} from './Qualifiers';
import {
	BidGroupMultiplierModifier,
	BuffBidModifier,
	BuyerRatingModifier,
	CancelRateModifier,
	DiscountModifier,
	DiscountFilterModifier,
	DurationModifier,
	MultiplierModifier,
	NerfBidModifier,
	NullifyModifier,
	PremiumListingModifier,
	ReturnRateModifier,
	SecondPriceModifier,
	TYPAdRevenueModifier,
} from './Modifiers';
import 'highlight.js/styles/atom-one-dark.css';

function lowerCamelCase(value) {
	const regex = /^[a-z0-9_]+$/g;
	return value.indexOf(' ') === -1 && regex.test(value);
}

const default_auction_config = {
	id: '',
	mpid: '', // read from route + userstore.role.mpid
	name: '',
	description: '',
	vendor_id: '',
	product: '', // data call etc
	vertical_id: '',
	lead_tags: [], // array of string
	source_tags: [], //array of string chips
	source_types: [], // array of web, pingpost, straightpost
	source_ids: [],
	bid_settings: {
		exclusive: {
			enabled: false,
			custom_max_winners: false,
			max_winners: 1,
			custom_minimum_bid: false,
			minimum_bid: 10,
		},
		shared: {
			enabled: false,
			custom_max_winners: false,
			max_winners: 4,
			custom_minimum_bid: false,
			minimum_bid: 3.5,
		},
	},
	validators: [],
	qualifiers: [],
	bid_modifiers: [],
	sorting_bid_modifiers: [],
	group_sorting_bid_modifiers: [],
	status: 'active',
};

export default {
	components: {
		pChips,
		pDialog,
		pTextarea,
		pMessage,
		pMultiSelect,
		qualifierSelector,
		modifierSelector,
		AccountNotInterestedQualifier,
		AuctionAttemptsLimitQualifier,
		BalanceQualifier,
		BidTypeQualifier,
		BudgetQualifier,
		CancelRateQualifier,
		ChannelsQualifier,
		ConcurrentCallLimitQualifier,
		CustomFilterMatchQualifier,
		ExplicitCampaignMatchQualifier,
		FiltersQualifier,
		PremiumListingQualifier,
		PreviouslyPurchasedQualifier,
		PromotionQualifier,
		ScheduleQualifier,
		SourceTagsQualifier,
		ThrottlingQualifier,
		UndialableQualifier,
		USHAValidationQualifier,
		BidGroupMultiplierModifier,
		BuffBidModifier,
		BuyerRatingModifier,
		CancelRateModifier,
		DiscountModifier,
		DiscountFilterModifier,
		DurationModifier,
		MultiplierModifier,
		NerfBidModifier,
		NullifyModifier,
		PremiumListingModifier,
		ReturnRateModifier,
		SecondPriceModifier,
		TYPAdRevenueModifier,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		const source_type_options = [
			{ label: 'Web', value: 'web' },
			{ label: 'Ping/Post', value: 'pingpost' },
			{ label: 'Straight Post', value: 'straightpost' },
			{ label: 'API - From LTW', value: 'api' },
		];

		return {
			active_status_options,
			auction_config: cloneDeep(default_auction_config),
			max_exclusive_winners: 1,
			bid_modifier_modal: false,
			bid_settings_modal: false,
			existing_record: false,
			group_sorting_bid_modifier_modal: false,
			loading: false,
			product_type_options,
			qualifiers_modal: false,
			sorting_bid_modifier_modal: false,
			source_type_options,
			validator_modal: false,
			vendor_options: [],
			sortable_options: {
				draggable: '.modifier',
				handle: '.move-handle',
			},
		};
	},
	computed: {
		isNew() {
			return this.$route.meta.new;
		},
		sortedQualifiers() {
			return sortBy(this.auction_config.qualifiers, 'script');
		},
	},
	validations() {
		return {
			auction_config: {
				name: { required },
				description: { required },
				product: { required },
				vendor_id: { required },
				vertical_id: { required },
				source_types: {
					required: helpers.withMessage('At least one source type is required', required),
				},
				status: { required },
			},
			bid_settings: {
				required_bid_type: helpers.withMessage('At least one allowed bid type is required', () => {
					return this.auction_config.bid_settings.exclusive.enabled || this.auction_config.bid_settings.shared.enabled;
				}),
			},
		};
	},
	async mounted() {
		this.loading = true;
		this.vendor_options = await getVendorsAsOptions(); // usese ADMIN GQL
		const marketplaceStore = useMarketplaceStore();

		if (!this.isNew) {
			const result = await getAuctionConfigById(this.$route.params.auction_config_id);
			this.auction_config = cloneDeep(result);
		} else {
			if (this.$route.query.duplicate) {
				const result = await getAuctionConfigById(this.$route.query.duplicate);
				this.auction_config = cloneDeep(result);
				this.auction_config.name += ' (Copy)';
				this.auction_config.id += '_copy';
			}

			// Set the mpid to the route or user role
			this.auction_config.mpid = this.$route.params.mpid || marketplaceStore.mpid || this.$root.appStore.mpid;
		}

		this.loading = false;
	},
	methods: {
		isSelected(collection, value) {
			return collection.some((i) => {
				return i.script === value;
			});
		},

		appendQualifier(value) {
			this.auction_config.qualifiers.push(value);
		},

		removeQualifier(script) {
			remove(this.auction_config.qualifiers, (qualifier) => {
				return qualifier.script === script;
			});
		},

		appendModifier(value, type) {
			this.auction_config[type].push(value);
		},

		removeModifier(script, type) {
			remove(this.auction_config[type], (qualifier) => {
				return qualifier.script === script;
			});
		},

		sortModifiers(old_index, new_index, collection) {
			const item = this.auction_config[collection].splice(old_index, 1)[0];
			this.auction_config[collection].splice(new_index, 0, item);
		},

		async save() {
			this.loading = true;
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					let result;
					const to_push = cloneDeep(this.auction_config);
					if (!this.isNew) {
						result = await updateAuctionConfig(to_push.id, to_push);
					} else {
						to_push.id = ulid();
						result = await insertAuctionConfig(to_push);
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: 'AuctionConfig was updated',
							life: 5000,
						});
						this.$router.back();
					}
				} catch (err) {
					log.error('err', err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save changes',
						life: 6000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'A field is invalid',
					life: 6000,
				});
				this.loading = false;
			}
		},
	},
	directives: {
		sortable: {
			beforeMount(el, binding, vnode) {
				vnode.sortable = Sortable.create(el, {
					draggable: '.modifier',
					handle: '.move-handle',
					onEnd: (event) => {
						binding.instance.sortModifiers(event.oldIndex, event.newIndex, binding.value);
					},
				});
			},
		},
	},
};
</script>

<style lang="less" scoped>
.auction-config-card {
	max-width: 800px;
}

.p-fieldset {
	margin-bottom: 1rem;
}

.bid-settings {
	display: flex;
	flex-direction: row;
	gap: 20px;
}

.bid-setting-group {
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	flex: 1 0;

	.heading {
		align-items: center;
		display: flex;
		flex-direction: row;
		height: 50px;
		justify-content: space-between;
		padding: 0 1rem;

		&.selected {
			background-color: var(--color-b-lightest);
		}

		.name {
			font-weight: bold;
		}
	}

	.bid-options {
		border-top: 1px solid var(--gray-20);
		padding: 1rem;
	}
}

.validator {
	align-items: center;
	border: 1px solid var(--gray-20);
	border-radius: 3px;
	display: flex;
	flex-direction: row;
	line-height: 1.25em;
	margin-top: 1rem;
	padding: 0.75rem;

	&.selected {
		background-color: var(--color-b-lightest);
	}

	.control {
		flex: 0 0 auto;
		margin-left: 2rem;
	}

	.validator-icon {
		align-items: center;
		background-color: var(--color-b-light);
		border-radius: 25px;
		color: white;
		display: flex;
		height: 50px;
		justify-content: center;
		width: 50px;
	}

	.label {
		flex: 1 0;
		font-size: 1rem;
		margin-left: 1rem;
	}

	.description {
		color: var(--gray-50);
		font-size: 0.875em;
		font-weight: normal;
	}
}

.qualifier,
.modifier {
	background-color: white;
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	box-shadow: 0 3px 0 rgb(0 0 0 / 10%);
	margin-bottom: 1rem;
	position: relative;

	:deep(.title) {
		align-items: center;
		background-color: var(--color-b-lightest);
		border-bottom: 1px solid var(--gray-15);
		display: flex;
		flex-direction: row;
		height: 50px;
		padding-left: 20px;

		.name {
			flex: 1 0;
			font-weight: bold;
		}
	}

	:deep(.description) {
		color: var(--gray-50);
		line-height: 1.35em;
		padding: 20px;
	}

	:deep(.step-settings) {
		padding: 0 20px 20px;

		h3 {
			font-size: 1rem;
			font-weight: bold;
			margin-bottom: 0.5em;
		}
	}
}

.modifier {
	:deep(.title) {
		padding: 0;
		padding-right: 10px;

		.move-handle {
			cursor: ns-resize;
			text-align: center;
			width: 50px;
		}
	}
}
</style>
