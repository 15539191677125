<template>
	<p-dialog v-model:visible="show_modal" modal style="max-width: 400px">
		<template #header> <strong>Select Starting Template</strong> </template>
		<div class="templates">
			<p>Please select a starting template to use for the new auction configuration.</p>
			<div class="template" @click="createNewAuction()">
				<div class="name">Blank</div>
				<div class="description">Create a new auction configuration from scratch.</div>
			</div>
			<div v-for="template in templates" class="template" :key="template.id" @click="createNewAuction(template.id)">
				<div class="name">{{ template.name }}</div>
				<div class="description">{{ template.description }}</div>
			</div>
		</div>
	</p-dialog>
</template>

<script>
import { getAuctionConfigs } from '@GQL';
import pDialog from 'primevue/dialog';

export default {
	name: 'NewAuctionConfigModal',
	components: {
		pDialog,
	},
	data() {
		return {
			show_modal: false,
			templates: [],
		};
	},
	methods: {
		async openModal() {
			await this.getTemplates(true);
			this.show_modal = true;
		},
		closeModal() {
			this.show_modal = false;
		},
		async getTemplates(no_cache = false) {
			this.loading = true;
			this.templates = await getAuctionConfigs(
				[
					[
						{
							field: 'mpid',
							op: 'eq',
							value: '$NG',
						},
					],
				],
				no_cache ? ['auctionConfigs'] : []
			);

			this.loading = false;
		},
		createNewAuction(template_id) {
			if (template_id) {
				this.$router.push({
					path: this.$route.path + '/new',
					query: {
						duplicate: template_id,
					},
				});
			} else {
				this.$router.push({
					path: this.$route.path + '/new',
				});
			}
		},
	},
};
</script>

<style scoped lang="less">
.template {
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	cursor: pointer;
	margin-bottom: 1rem;
	padding: 20px;

	.name {
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.description {
		color: var(--gray-50);
		font-size: 0.875em;
	}

	&:last-of-type {
		margin-bottom: 0;
	}

	&:hover {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-lighter);
	}
}
</style>
