<template>
	<div id="edit-role" class="view-content">
		<div class="page-heading">
			<h1>{{ heading }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<RoleForm />
	</div>
</template>

<script lang="ts">
import RoleForm from './Form.vue';
export default {
	name: 'RolesEdit',
	components: {
		RoleForm,
	},
	data() {
		const is_create = this.$route.name === 'CreateRole' ? true : false;
		return {
			is_create,
			heading: is_create ? 'Create Role' : 'Edit Role',
			breadcrumb_items: [{ label: 'Roles List', route: '/roles' }, { label: is_create ? 'Create Role' : 'Edit Role' }],
			loading: false,
			tableKey: 0,
		};
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
