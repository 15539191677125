<template>
	<p-sidebar
		v-model:visible="$root.appStore.show_notifications_drawer"
		position="right"
		class="w-full md:w-20rem lg:w-30rem"
	>
		<NotificationList @dismiss-notification="handleDismissNotification" />
	</p-sidebar>
</template>

<script lang="ts">
import pSidebar from 'primevue/sidebar';
import NotificationList from '@/views/Notifications/List/Receipt/List.vue';

export default {
	name: 'NotificationsDrawer',
	components: {
		pSidebar,
		NotificationList,
	},
	emits: ['dismissNotification'],
	methods: {
		handleDismissNotification(id) {
			this.$emit('dismissNotification', id);
		},
	},
};
</script>
