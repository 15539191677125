import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function getDNCList(params: GetListParams) {
	const query = `
    query getDNCList($params: GetListParams!){
        dncList(params: $params) {
			row_count
            rows {
                id
                type
                source
                phone
                account {
                	name
                }
                user {
                	first_name
                	last_name
                }
                created_at
            }
        }
    }       
    `;

	const result = await $GQL.request(query, {
		params,
	});

	return result.dncList;
}
