import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getOutboundRoutes(filters: QueryFilters) {
	const query = `
        query GetOutboundRoutes($filters: [[Any]]) {
            outboundRoutes(filters: $filters) {
                id
				name
				description
				vertical_id
				phone_number_tag
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.outboundRoutes;
}
