<template>
	<transition name="zoom">
		<div v-if="show" class="loader-container" :style="{ background }">
			<p-progress-spinner
				class="spinner"
				style="height: 130px; width: 130px"
				:pt="{ circle: { style: { stroke: 'var(--color-a)', strokeWidth: 2 } } }"
			/>
		</div>
	</transition>
</template>

<script lang="ts">
import pProgressSpinner from 'primevue/progressspinner';
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Loader',
	components: {
		pProgressSpinner,
	},
	props: {
		show: {
			type: Boolean,
			required: true,
		},
		background: {
			type: String,
			default: 'rgba(255,255,255,0.8)',
		},
	},
	data() {
		return {};
	},
};
</script>

<style lang="less" scoped>
.loader-container {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 9999;
}

.spinner {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%);
}
</style>
