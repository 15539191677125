<template>
	<div :class="['tool', 'delete', { solid }]">
		<p-confirm-popup :group="confirm_id" />
		<p-button v-tooltip.top="tooltip" :text="text" :rounded="rounded" aria-label="Delete" @click="deleteItem($event)">
			<template #icon>
				<icon :type="icon" size="20px" />
			</template>
		</p-button>
	</div>
</template>

<script lang="ts">
import { uniqueId } from 'lodash-es';
import pConfirmPopup from 'primevue/confirmpopup';

export default {
	name: 'DeleteAction',
	components: {
		pConfirmPopup,
	},
	props: {
		icon: {
			type: String,
			default: 'delete',
		},
		message: {
			type: String,
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		solid: {
			type: Boolean,
			default: false,
		},
		text: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: String,
			default: 'Delete',
		},
	},
	emits: ['delete'],
	data() {
		return {
			confirm_id: uniqueId(),
		};
	},
	methods: {
		async deleteItem(event) {
			this.$confirm.require({
				target: event.currentTarget,
				group: this.confirm_id,
				message: this.message || 'Are you sure you want to delete this?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: this.acceptClass || 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: this.acceptLabel || 'Delete',
				rejectLabel: this.rejectLabel || 'Cancel',
				accept: async () => {
					this.$emit('delete');
				},
			});
		},
	},
};
</script>

<style lang="less">
.tool:hover.delete .mdi-icon {
	color: var(--red) !important;
}

.tool:hover.delete.solid .mdi-icon {
	color: white !important;
}

.tool.delete.solid {
	.p-button {
		background-color: var(--red) !important;
		border: 0;

		&:hover {
			background-color: #9d1717 !important;
		}
	}
}
</style>
