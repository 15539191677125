// ----------------------------------------------------------------------------------------------------
// FILTER: displayLabel
// Display the lable of the selected value
// ----------------------------------------------------------------------------------------------------

import { isEqual } from 'lodash-es';

export function displayLabel(value: any, options: any[]) {
	const selected_option = options.find((option) => {
		return isEqual(option.value, value);
	});

	if (selected_option) {
		return selected_option.label;
	}
	return value;
}
