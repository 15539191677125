import { $GQL } from '@GQL/index';
import type { Role } from '@nextgenleads/auth-driver';

export async function insertRole(data: Omit<Role, 'created_at'>): Promise<{ id: string }> {
	const mutation = `
        mutation InsertRole($data: JSONObject!){
            insertRole(data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertRole;
}
