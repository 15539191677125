<template>
	<p-file-upload
		mode="basic"
		name="file_upload"
		:multiple="multiple"
		:file-limit="fileLimit"
		:disabled="disabled"
		:choose-label="chooseLabel"
		:accept="accept"
		auto
		custom-upload
		@uploader="uploadFiles"
	>
		<template #uploadicon>
			<icon :loading="uploading" type="upload" size="20px" />
			<gutter size="10px" />
		</template>
	</p-file-upload>
</template>

<script>
import pFileUpload from 'primevue/fileupload';
import { $GQL } from '@GQL';

export default {
	name: 'CustomUpload',
	components: {
		pFileUpload,
	},
	props: {
		accept: {
			type: String,
		},
		chooseLabel: {
			type: String,
			default: 'Select File',
		},
		disabled: Boolean,
		fileLimit: {
			type: Number,
			default: 1,
		},
		filename: {
			type: String,
		},
		folder: {
			type: String,
			default: '',
		},
		multiple: Boolean,
		accept: {
			default: null,
		},
	},
	emits: ['success'],
	data() {
		return {
			uploading: false,
		};
	},
	methods: {
		reset() {},
		async uploadFiles(event) {
			this.uploading = true;

			let folder = this.folder || '';
			let folder_prefix = '';
			if (!this.$root.sessionStore.isAdminUser) {
				folder_prefix += this.$root.appStore.mpid + '/';
			} else if (this.$route.params.mpid) {
				folder_prefix += this.$route.params.mpid + '/';
			}

			if (!this.$root.sessionStore.isAccountUser && this.$route.params.account_id) {
				folder_prefix += this.$route.params.account_id + '/';
			} else if (!this.$root.sessionStore.isAccountUser && this.$route.params.parent_account_id) {
				folder_prefix += this.$route.params.parent_account_id + '/';
			} else if (this.$root.sessionStore.account.id) {
				// Default to the user's account folder
				folder_prefix += this.$root.sessionStore.account.id + '/';
			}

			// Add the prfix to the folder
			folder = folder_prefix + folder;

			const result = await $GQL.upload(event.files, folder, this.filename);
			if (result) {
				let detail = 'No files were uploaded';
				if (result.data.length === 1) {
					detail = '1 file was uploaded';
				}
				if (result.data.length > 1) {
					detail = `${result.data.length} files were uploaded`;
				}
				this.$toast.add({
					severity: 'success',
					summary: 'File Uploaded Successfully',
					detail,
					life: 3000,
				});
				this.$emit('success', result);
			}
			this.uploading = false;
		},
	},
};
</script>
