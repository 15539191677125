import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';

export async function getSignUpPages(filters: QueryFilters) {
	const query = `
        query GetSignUpPages($filters: [[Any]]) {
            signUpPages(filters: $filters) {
                id
				mpid
				name
				description
				path
				channel
				source
				tags
				parent_account_id
				contact {
					name
					email
				}
				content {
					company_name
					phone
					body
					logo_url
				}
				offer_id
				status
				created_at
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.signUpPages;
}
