<template>
	<div class="action send-to-dialer">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Send to Dialer</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this action?"
				tooltip="Remove Build Step"
				@delete="removeAction"
			/>
		</div>
		<div class="description">Send the lead information directly to the NextGen Leads dialer.</div>
		<div class="action-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">List ID:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="modelValue.list_id" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'SendToDialer',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				list_id: '',
			},
		},
	},
	methods: {
		removeAction() {
			this.$emit('remove');
		},
	},
};
</script>
