<template>
	<p-button
		v-if="!hideButton"
		icon="pi pi-plus"
		label="Adjust Balance"
		severity="danger"
		@click="openAddAdjustBalanceModal"
	/>
	<p-dialog
		v-model:visible="adjust_balance_modal_open"
		class="add-adjust_balance-modal"
		:modal="true"
		:dismissable-mask="true"
		style="width: 500px"
	>
		<loader :show="loading" />
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="cash" size="24px" style="color: var(--color-b)" />
				<strong>Adjust Balance</strong>
			</div>
		</template>
		<div class="flex-flex-column">
			<div class="balance" :class="fundingClass">
				<label for="current_balance"> Current Balance: </label>
				<div name="current_balance" class="balance">
					{{ currency(current_balance) }}
				</div>
			</div>
			<gutter size="20px" />
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">How much would you like to adjust by?</div>
						<div class="field smallish">
							<div class="p-inputgroup">
								<p-input-currency v-model="balance_adjustment" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">Transaction Type</div>
						<div class="field smallish">
							<div class="p-inputgroup">
								<p-select-button
									v-model="transaction_type"
									optionLabel="label"
									optionValue="value"
									:options="transaction_type_options"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="control-label">Reason for Adjustment:</div>
					<div class="controls">
						<p-text-area v-model="reason" />
					</div>
				</div>
			</div>
			<div v-if="transaction_type !== ''" class="warn">
				The final balance will be
				{{
					transaction_type === 'debit'
						? currency(current_balance - balance_adjustment)
						: currency(current_balance + balance_adjustment)
				}}
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Cancel" text @click="adjust_balance_modal_open = false" />
				<p-button label="Submit" @click="adjustBalance" />
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import { currency } from '@/lib/Filters';
import { getBalances, debitFunds, creditFunds } from '@GQL';
import { useSessionStore } from '@/stores/session';
import pSelectButton from 'primevue/selectbutton';
import pTextArea from 'primevue/textarea';
import { useAppStore } from '@/stores/app';

export default {
	name: 'AddAdjustBalanceModal',
	components: {
		pDialog,
		pMessage,
		pSelectButton,
		pTextArea,
	},
	emits: ['refresh'],
	props: {
		hideButton: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
			appStore: useAppStore(),
		};
	},
	data() {
		return {
			loading: false,
			adjust_balance_modal_open: false,
			current_balance: 0,
			balance_adjustment: 0,
			transaction_type: '',
			reason: '',
			transaction_type_options: [
				{
					label: 'Debit',
					value: 'debit',
				},
				{
					label: 'Credit',
					value: 'credit',
				},
			],
		};
	},
	computed: {
		fundingClass() {
			return {
				funded: this.current_balance > 100,
				low: this.current_balance <= 100,
			};
		},
		accountId() {
			return (
				this.$route.params.account_id ||
				this.$route.params.parent_account_id ||
				this.sessionStore.user.account_id ||
				('mpid' in this.$route.params ? `${this.$route.params.mpid}-000-000` : `${this.appStore.mpid}-000-000`)
			);
		},
	},
	async mounted() {
		this.current_balance = (await getBalances(this.accountId)).actual;
	},
	methods: {
		currency,
		async reset() {
			this.balance_adjustment = 0;
			this.transaction_type = '';
			this.reason = '';
		},
		async openAddAdjustBalanceModal() {
			await this.reset();
			const result = await getBalances(this.accountId);
			this.current_balance = result.actual;
			this.adjust_balance_modal_open = true;
		},
		async adjustBalance() {
			this.loading = true;
			// take the payment_method_id and make sale request....
			try {
				let response;
				if (this.transaction_type === 'debit') {
					response = await debitFunds({
						amount: this.balance_adjustment * -1,
						account_id: this.accountId,
						user_id: this.sessionStore.user.id,
						sub_type: this.reason,
					});
				} else {
					response = await creditFunds({
						amount: this.balance_adjustment,
						account_id: this.accountId,
						user_id: this.sessionStore.user.id,
						sub_type: this.reason,
					});
				}

				if ('txn_id' in response) {
					this.$toast.add({
						severity: 'success',
						summary: 'Transaction was successful',
						life: 5000,
					});
				}

				this.$emit('refresh');
				this.adjust_balance_modal_open = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Transaction was not saved',
					detail: err.response.errors[0]?.message || err.message,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style scoped lang="less">
.warn {
	color: red;
	font-style: italic;
}

.add-label {
	font-size: var(--font-size-sm);
}

.label {
	flex: 1;
	font-size: var(--font-size-sm);

	.description {
		color: var(--gray-50);
		line-height: 1.3;
		margin: 8px 0;
	}
}

.smallish {
	max-width: 150px;
}

.balance {
	align-items: center;
	border-radius: 5px;
	display: flex;
	font-size: var(--font-size-sm);
	font-weight: bold;
	justify-content: space-between;
	padding: 10px;

	&.funded {
		background: #eaf5dc;
		border: 1px solid #bade8d;
	}

	&.low {
		background: rgb(238 0 0 / 10%);
		border: 1px solid var(--red);
	}
}
</style>
