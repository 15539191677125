<template>
	<div class="modifier nerf-bid">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Nerf Bid</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">
			Reduce a campaign's bid by 50% <em>x</em> percent of the time. The percentage is set in the
			<code>reduce_dominance</code> flag of the campaign.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'NerfBidModifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
