import { $GQL } from '@GQL/index';
import { type Promotion } from '@nextgenleads/marketplace-driver';

export async function getPromotionById(id: string): Promise<Promotion> {
	const query = `
        query GetPromotion($id: ID!) {
            promotion(id: $id) {
                id
                mpid
                name
                icon_url
                description
				restrictions
                custom_filter
                vertical_id
                product
                bid_types
                start_date
                end_date
                allow_source_filtering
                allow_attribute_filtering
                allow_schedule
                exclusive_minimum_bid
                shared_minimum_bid
                status
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.promotion;
}
