import OffersList from '@/views/Offers/List/Page.vue';
import OfferRecord from '@/views/Offers/Record/Page.vue';

export default [
	{
		path: 'offers',
		name: 'OffersList',
		component: OffersList,
		meta: {
			title: 'Offers',
			permissions: 'admin.offers',
		},
	},
	{
		path: 'offers/new',
		name: 'CreateOffer',
		component: OfferRecord,
		meta: {
			new: true,
			title: 'New Offer',
			permissions: 'admin.offers',
		},
	},
	{
		path: 'offers/:offer_id',
		name: 'EditOffer',
		component: OfferRecord,
		meta: {
			title: 'Edit Offer',
			permissions: 'admin.offers',
		},
	},
];
