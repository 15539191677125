import { $GQL } from '@GQL';
import type { SignUpPage } from '@nextgenleads/marketplace-driver';

export async function insertSignUpPage(data: Omit<SignUpPage, 'id' | 'created_at'>) {
	const mutation = `
        mutation InsertSignUpPage($data: JSONObject!) {
            insertSignUpPage(data: $data) {
                id
				mpid
				name
				description
				path
				channel
				source
				tags
				parent_account_id
				contact {
					name
					email
				}
				content {
					company_name
					phone
					body
					logo_url
				}
				offer_id
				status
				created_at
				modified_at
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertSignUpPage;
}
