import { $GQL } from '@GQL/index';
import type { Account } from '@nextgenleads/marketplace-driver';
import type { User } from '@nextgenleads/auth-driver';

export async function signUp(
	user_params: Omit<User, 'password' | 'id' | 'created_at'>,
	account_params: Omit<Account, 'primary_user_id' | 'created_by'>,
	request_base_url: string
) {
	const mutation = `
        mutation SignUp($user_params: JSONObject!, $account_params: JSONObject!, $request_base_url: String!) {
            signUp(user_params: $user_params, account_params: $account_params, request_base_url: $request_base_url) {
                status
            }
        }
    `;

	const result = await $GQL.request(mutation, { user_params, account_params, request_base_url });
	return result.signUp;
}
