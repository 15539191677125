import { $GQL } from '@GQL/index';
import type { GetListParams, QueryFilters } from '@nextgenleads/db';
import log from '@/lib/Log';

export async function getParentAccountList(account_params: GetListParams, spend_params: QueryFilters) {
	const query = `
        query GetParentAccountList($account_params: GetListParams!, $spend_params: [[Any]]) {
            accountList(params: $account_params) {
                rows {
                    id
                    name
                    primary_user {
						first_name
						last_name
                    }
                    created_at
                    balance {
                    	actual
                    }
                    childAccounts {
                        id
                        spend(params: $spend_params)
						balance {
							actual
						}
                    }
                }
                row_count
            }
        }
    `;

	const result = await $GQL.request(query, { account_params, spend_params });

	return result.accountList;
}
