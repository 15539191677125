import { $GQL } from '@GQL/index';
import log from '@/lib/Log';
import type { GetListParams } from '@nextgenleads/db';

export async function getPostLogList(params: GetListParams, refresh?: boolean) {
	const query = `
    query GetPostLogList($params: GetListParams){
        postLogList(params: $params) {
			row_count
            rows {
                id
                purchase_id
                account_id
                integration {
                    post_settings
                }
                lead_id
                lead {
                    first_name
                    last_name
                    phone
                }
                campaign {
                    name
                }
                request
                response
                created_at
                result
            }
        }
    }
    `;

	const no_cache = refresh ? ['postLogList'] : undefined;
	const result = await $GQL.request(query, {
		params,
		no_cache,
	});

	return result.postLogList;
}
