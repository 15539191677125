<template>
	<p-card>
		<template #title>
			<div class="flex align-items-center">
				<icon type="truck-outline" size="24px" class="mr-2" />
				<span class="name">Shipments</span>
			</div>
		</template>
		<template #content>
			<p-message v-if="filteredShipments.length === 0" :closable="false"
				>There are no shipments for this lead</p-message
			>
			<p-timeline v-else :value="filteredShipments">
				<template #opposite="slotProps">
					<div>
						{{ formatDate(slotProps.item.created_at, 'YYYY-MM-DD [&bull;] h:mm:ssA') }}
						<div class="sub-value">{{ timeAgo(slotProps.item.created_at) }}</div>
					</div>
				</template>
				<template #content="slotProps">
					<ShipmentModal :shipment="slotProps.item" />
				</template>
			</p-timeline>
		</template>
	</p-card>
</template>

<script lang="ts">
import pCard from 'primevue/card';
import pTimeline from 'primevue/timeline';
import ShipmentModal from './modals/ShipmentModal.vue';
import { formatDate, timeAgo } from '@/lib/Filters';
import { getShipmentLogs, getPostLogs } from '@GQL';
import { sortBy } from 'lodash-es';

export default {
	name: 'ShipmentsPanel',
	components: {
		pCard,
		pTimeline,
		ShipmentModal,
	},
	props: {
		leadId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			shipments: [],
		};
	},
	computed: {
		filteredShipments() {
			if (this.$root.sessionStore.isAdminUser) {
				return this.shipments;
			} else {
				return this.shipments.filter((shipment) => {
					return shipment.mpid === this.$root.appStore.mpid;
				});
			}
		},
	},
	async mounted() {
		await this.getShipments();
	},
	methods: {
		formatDate,
		timeAgo,
		async getShipments() {
			const lead_id_filter = `lead_id = '${this.leadId}'`;
			const shipments = await getShipmentLogs([[lead_id_filter]]);

			const posts = await getPostLogs([[lead_id_filter]]);

			this.shipments = sortBy(shipments.concat(posts), 'created_at').reverse();
		},
	},
};
</script>

<style lang="less" scoped>
.p-timeline {
	font-size: 0.875rem;
	margin-top: 2em;
}

:deep(.p-timeline-event-opposite) {
	.sub-value {
		line-height: 1.35em;
		margin-top: 5px;
	}
}
</style>
