import { $GQL } from '@GQL';
import type { DNC } from '@nextgenleads/dnc-driver';

export async function getDNCById(id: string): Promise<Omit<DNC, 'created_at' | 'modified_at'>> {
	const query = `
        query GetDNCById($id: ID!) {
            dnc(id: $id) {
                id
                type
                note
                phone
                request_ip
                source
                user_id
                account_id
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.dnc;
}
