<template>
	<div class="voice-route-step">
		<div class="header move-handle">
			<div class="header-left" @click="expanded = !expanded">
				<div class="flex align-items-center gap-10">
					<icon type="dots-vertical" size="24px" />
					<strong>Say</strong> <span class="sub-value">Use an AI voice to say a message</span>
				</div>
				<div>
					<div v-if="!expanded" class="expand-icon">
						<icon type="arrow-expand" size="20px" />
					</div>
					<div v-if="expanded" class="expand-icon">
						<icon type="arrow-collapse" size="20px" />
					</div>
				</div>
			</div>
			<div class="header-right">
				<div class="delete-icon">
					<icon type="delete" size="20px" @click="deleteStep" />
				</div>
			</div>
		</div>
		<div v-if="expanded" class="content">
			<div class="step-options">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">AI Voice Message:</label>
						<div class="controls">
							<div class="field">
								<p-textarea
									v-model="localRouteStep.params.message"
									auto-resize
									rows="3"
									style="min-height: 100px"
									placeholder="Enter message for the AI voice to say here..."
								/>
							</div>
						</div>
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label">Voice:</label>
								<p-dropdown
									v-model="localRouteStep.params.options.voice"
									:options="voice_options"
									option-label="label"
									option-value="value"
									placeholder="Select Voice"
								/>
							</div>
							<div class="field xs">
								<label class="control-label">Play:</label>
								<div class="p-inputgroup">
									<p-input-number v-model="localRouteStep.params.options.loop" class="input-align-right" />
									<div class="p-inputgroup-addon loose">times</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import codeEditor from '@/components/forms/CodeEditor.vue';
import voice_options from '@/lib/Data/voice_options.json';

export default {
	name: 'VoiceRouteSayHandler',
	emits: ['update', 'delete'],
	components: {
		codeEditor,
	},
	props: {
		routeStep: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			default_config: {
				handler: 'Say',
				params: {
					message: '',
					options: {
						voice: 'Google.en-US-Standard-H',
						loop: 1,
					},
				},
			},
			voice_options,
			expanded: false,
		};
	},
	computed: {
		localRouteStep: {
			get() {
				return this.routeStep;
			},
			set(new_value) {
				this.$emit('update', new_value);
			},
		},
	},
	methods: {
		addOptions() {
			if (!has(this.routeStep.params, 'options')) {
				const new_route = cloneDeep(this.routeStep);
				new_route.params.options = {};
				this.$emit('update', new_route);
			}
		},
		removeOptions() {
			if (has(this.routeStep.params, 'options')) {
				const new_route = cloneDeep(this.routeStep);
				delete new_route.params.options;
				this.$emit('update', new_route);
			}
		},
		deleteStep(event) {
			this.$confirm.require({
				target: event.currentTarget,
				group: 'voice_route_builder',
				message: 'Are you sure you want to delete this route step?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: 'Delete Route Step',
				rejectLabel: 'Cancel',
				accept: async () => {
					this.$emit('delete');
				},
			});
		},
	},
};
</script>
