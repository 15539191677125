import { $GQL } from '@GQL/index';
import type { GetBalancesRes } from '@nextgenleads/banker-driver';

export async function getBalances(account_id: string, sync?: boolean): Promise<GetBalancesRes> {
	const query = `
        query GetBalances($account_id: ID!, $sync: Boolean) {
            balance(account_id: $account_id, sync: $sync) {
                actual
                available
            }
        }
    `;

	const result = await $GQL.request(query, { account_id, sync });
	return result.balance;
}
