<template>
	<div>
		<h2>Signup Source Call Scripts</h2>
		<p-dialog v-model:visible="show_modal" modal :closeOnEscape="false" :dismissable-mask="false" style="width: 900px">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
					<strong>Edit Signup Source Call Scripts</strong>
				</div>
			</template>
			<div class="content">
				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req" for="role-id">Signup Source ID:</label>
									<p-dropdown
										id="source_id"
										v-model="selected_row.source_id"
										:options="signUpSourceIdOptions"
										placeholder="Select Sign Up Source Id"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.selected_row.source_id.$error" class="validation-error">
										{{ v$.selected_row.source_id.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<p-message>
					The available dynamic fields are <strong>client_first_name</strong>, <strong>client_last_name</strong>,
					<strong>account_manager_first_name</strong>, <strong>account_manager_phone</strong>. If the account has an
					offer, then these fields may be available: <strong>offer_credit_type</strong>, <strong>offer_trigger</strong>,
					<strong>offer_amount</strong>, <strong>offer_required_funding</strong>
				</p-message>

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req">Voice-To-Voice Script:</label>
									<p-editor v-model="selected_row.vtv_script" editor-style="height: 250px;">
										<template #toolbar>
											<span class="ql-formats">
												<button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
												<button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
												<button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
												<button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
												<button v-tooltip.bottom="'Bulleted List'" class="ql-list" value="bullet"></button>
												<button v-tooltip.bottom="'Link'" class="ql-link"></button>
											</span>
										</template>
									</p-editor>
									<div v-if="v$.selected_row.vtv_script.$error" class="validation-error">
										{{ v$.selected_row.vtv_script.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label">VoiceMail Script:</label>
									<p-editor v-model="selected_row.lvm_script" editor-style="height: 250px;">
										<template #toolbar>
											<span class="ql-formats">
												<button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
												<button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
												<button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
												<button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
												<button v-tooltip.bottom="'Bulleted List'" class="ql-list" value="bullet"></button>
											</span>
										</template>
									</p-editor>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label">Additional Information:</label>
									<p-editor v-model="selected_row.additional_info" editor-style="height: 250px;">
										<template #toolbar>
											<span class="ql-formats">
												<button v-tooltip.bottom="'Bold'" class="ql-bold"></button>
												<button v-tooltip.bottom="'Italic'" class="ql-italic"></button>
												<button v-tooltip.bottom="'Underline'" class="ql-underline"></button>
												<button v-tooltip.bottom="'Numbered List'" class="ql-list" value="ordered"></button>
												<button v-tooltip.bottom="'Bulleted List'" class="ql-list" value="bullet"></button>
												<button v-tooltip.bottom="'Link'" class="ql-link"></button>
											</span>
										</template>
									</p-editor>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<template #footer class="flex justify-content-end">
				<p-button text label="Cancel" @click="closeModal" />
				<p-button label="Add Change" @click="saveChange" />
			</template>
		</p-dialog>
		<gutter size="20px" />

		<div>
			<p-button label="Add Signup Source Script" @click="newValue()" />
			<template v-if="dirty">
				<p-message severity="warn" :closable="false">Changes pending, Save to implement</p-message>
			</template>

			<template v-if="dirty">
				<gutter size="20px" />
				<div class="flex justify-content-start">
					<p-button icon="pi pi-check" :disable="hasPendingChange" label="Save" @click="save" />
					<gutter size="10px" />
					<p-button label="Reset" text @click="reset" />
				</div>
			</template>
		</div>

		<gutter size="20px" />

		<p-data-table ref="dt" :value="signupSourceCallScripts" key="key" :loading="loading">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>

			<p-column header="Source ID">
				<template #body="row">
					{{ row.data.source_id }}
				</template>
			</p-column>
			<p-column header="VTV Script">
				<template #body="row">
					{{ extractScript(row.data.vtv_script) }}
				</template>
			</p-column>
			<p-column header="LVM Script">
				<template #body="row">
					{{ extractScript(row.data.lvm_script) }}
				</template>
			</p-column>
			<p-column header="Tools">
				<template #body="row">
					<p-button v-tooltip.top="'Preview'" text @click="openPreviewModal(row.data)">
						<template #icon>
							<icon type="eye-outline" size="20px" />
						</template>
					</p-button>
					<p-button v-tooltip.top="'Edit'" text @click="editValue(row.data)">
						<template #icon>
							<icon type="pencil-box-outline" size="20px" />
						</template>
					</p-button>
					<p-button v-tooltip.top="'Duplicate'" text @click="duplicateValue(row.data)">
						<template #icon>
							<icon type="content-copy" size="20px" />
						</template>
					</p-button>
					<p-button v-tooltip.top="'Delete'" text @click="removeValue(row.data)">
						<template #icon>
							<icon type="trash-can-outline" size="20px" />
						</template>
					</p-button>
				</template>
			</p-column>
		</p-data-table>

		<p-dialog
			v-model:visible="show_preview_modal"
			style="width: 700px"
			class="modal"
			:modal="true"
			:dismissable-mask="true"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<strong>Call Scripts</strong>
				</div>
			</template>
			<div class="flex-flex-column script call-script-preview">
				<p-fieldset legend="VTV Script">
					<div v-html="preview_data.vtv_script" />
				</p-fieldset>

				<p-fieldset legend="Voice mail Script" v-if="preview_data.lvm_script.length > 0">
					<div v-html="preview_data.lvm_script" />
				</p-fieldset>

				<p-fieldset legend="Additional Info" v-if="preview_data.additional_info.length > 0">
					<div v-html="preview_data.additional_info" />
				</p-fieldset>
			</div>
		</p-dialog>
	</div>
</template>
<script>
import { getSettings, updateSetting } from '@GQL';
import { cloneDeep, isEqual } from 'lodash-es';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import codeEditor from '@/components/forms/CodeEditor.vue';
import pEditor from 'primevue/editor';
import { mapper } from '@/lib/Utils/mapper';
import { phoneFormat } from '@/lib/Filters';

export default {
	name: 'SignupSourceCallScripts',
	emits: ['refresh'],
	components: {
		codeEditor,
		pEditor,
	},
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			selected_row: {
				source_id: null,
				vtv_script: `<ul>\r\t<li>\r\t</li>\r</ul>`,
				lvm_script: '',
				additional_info: '',
			},
			original_selected_source: null,
			show_modal: false,
			show_preview_modal: false,
			loading: false,
			original_map: null,
			signup_source_ids: [],
			signup_source_call_scripts: {},
			original_value: {},
			dirty: false,
			preview_data: {
				lvm_script: '',
				vtv_script: '',
				additional_info: '',
			},
		};
	},
	computed: {
		hasPendingChange() {},
		signUpSourceIdOptions() {
			return this.signup_source_ids.filter((row) => {
				return this.selected_row.source_id === row.value || !this.signup_source_call_scripts[row.value];
			});
		},
		signupSourceCallScripts() {
			return Object.keys(this.signup_source_call_scripts).map((source_id) => {
				return {
					source_id,
					...this.signup_source_call_scripts[source_id],
				};
			});
		},
		dirty() {
			return !isEqual(this.original_value, this.signup_source_call_scripts);
		},
	},
	async mounted() {
		await this.getSetting();
	},
	methods: {
		openPreviewModal(row) {
			const merge_data = {
				client_first_name: 'James',
				client_last_name: 'Smith',
				account_manager_first_name: this.$root.sessionStore.user.first_name,
				account_manager_phone: phoneFormat(this.$root.sessionStore.user.phone),
				offer_credit_type: 'flat',
				offer_amount: 50,
				offer_trigger: 'first_funding',
				offer_required_funding: 200,
			};

			['lvm_script', 'vtv_script', 'additional_info'].forEach((key) => {
				this.preview_data[key] = mapper.compile(row[key], merge_data);
			});

			this.show_preview_modal = true;
		},
		extractScript(html) {
			const vals = /<li>([\s\S]*?)<\/li>/g.exec(html);
			if (vals && vals.length > 1) {
				return vals[1].trim().slice(0, 15) + '...';
			} else {
				return 'None';
			}
		},
		async saveChange() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				const changed_value = cloneDeep(this.selected_row);
				this.signup_source_call_scripts[changed_value.source_id] = {
					vtv_script: changed_value.vtv_script,
					lvm_script: changed_value.lvm_script,
					additional_info: changed_value.additional_info,
				};
				if (this.original_selected_source !== changed_value.source_id) {
					delete this.signup_source_call_scripts[this.original_selected_source];
				}
				this.closeModal();
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Cannot save change',
					detail: 'One of the required fields is missing',
					life: 7000,
				});
			}
		},
		async save() {
			try {
				this.loading = true;
				const settings_result = await updateSetting(
					'signup_source_call_scripts',
					{
						data: this.signup_source_call_scripts,
					},
					'$NG',
					true
				);
				if (settings_result.id === 'signup_source_call_scripts') {
					this.original_value = cloneDeep(this.signup_source_call_scripts);
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved Signup Source Call Scripts',
						life: 5000,
					});
					await this.getSetting();
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save settings',
						life: 7000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getSetting() {
			this.loading = true;
			try {
				// only ever global
				const settings_result = await getSettings(
					['signup_source_id_options', 'signup_source_call_scripts'],
					'$NG',
					true
				);
				this.signup_source_call_scripts = cloneDeep(settings_result.settings.signup_source_call_scripts);
				this.original_value = cloneDeep(settings_result.settings.signup_source_call_scripts);
				this.signup_source_ids = [
					{
						label: 'Default',
						value: '_default',
					},
					...Object.keys(settings_result.settings.signup_source_id_options).map((key) => {
						return {
							label: `${key}`,
							value: key,
						};
					}),
				];
			} finally {
				this.loading = false;
			}
		},
		reset() {
			this.getSetting();
			this.closeModal();
		},
		newValue() {
			this.selected_row = {
				source_id: null,
				vtv_script: `<ul>\r\t<li>\r\t</li>\r</ul>`,
				lvm_script: '',
				additional_info: '',
			};
			this.show_modal = true;
		},
		editValue(row) {
			this.original_selected_source = row.source_id;
			this.selected_row = cloneDeep(row);
			this.selected_row.source_id = row.source_id;
			this.show_modal = true;
		},
		duplicateValue(row) {
			this.selected_row = cloneDeep(row);
			this.selected_row.source_id = '';
			this.show_modal = true;
		},
		removeValue(source_id) {
			this.loading = true;
			delete this.signup_source_call_scripts[source_id];
		},
		closeModal() {
			this.v$.$reset();
			this.original_selected_source = null;
			this.show_modal = false;
		},
	},
	validations() {
		return {
			selected_row: {
				vtv_script: {
					required: helpers.withMessage('A Voice-To-Voice Script is required', required),
					min_length: helpers.withMessage('Have at least one bullet point', (value) => {
						const regex = /<li>(?!\s*<\/li>\s*<\/ul>|<\/ul>)\s*\S.*\s*<\/li>/g;
						return regex.test(value);
					}),
				},
				source_id: {
					required: helpers.withMessage('Source ID is required.', required),
					spaces: helpers.withMessage('Source IDs should NOT have spaces', (value) => {
						return value.indexOf(' ') === -1;
					}),
				},
			},
		};
	},
};
</script>

<style lang="less">
.call-script-preview {
	ul {
		padding-left: 1.5em;
	}

	ul > li {
		list-style: disc;
		margin-bottom: 1em;
	}
}
</style>
