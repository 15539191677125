<template>
	<div class="control-group">
		<div class="inner">
			<div class="controls">
				<div class="field req">
					<code-editor v-model.parse="shipperSettings" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ShipperSettingsCustom',
	props: {
		modelValue: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {};
	},
	computed: {
		shipperSettings: {
			get() {
				return this.modelValue;
			},
			set(new_value) {
				this.$emit('update:modelValue', new_value);
			},
		},
	},
};
</script>

<style lang="less"></style>
