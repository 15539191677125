import { $GQL } from '@GQL/index';
import type { OutboundRoute } from '@nextgenleads/call-driver';

export async function updateOutboundRoute(id: string, data: OutboundRoute): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateOutboundRoute($id: ID!, $data: JSONObject!) {
            updateOutboundRoute(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateOutboundRoute;
}
