<template>
	<div id="shippers-list" class="view-content">
		<div class="page-heading">
			<h1>Shipper Templates</h1>
			<breadcrumb />
		</div>
		<div class="page-content">
			<row>
				<p-button label="New Shipper Template" @click="$router.push({ path: `${$route.path}/new` })">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="plus" size="24px" />
						</div>
					</template>
				</p-button>
			</row>
			<gutter size="20px" />
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table ref="dt" scrollable :value="shipper_templates" :loading="loading">
							<template #empty>
								<div class="dim">No Shippers found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="name" header="Template Name" sortable sortField="name">
								<template #body="row">
									<router-link :to="`${$route.path}/${row.data.id}`">{{ row.data.name }}</router-link>
								</template>
							</p-column>
							<p-column field="type" header="Template Type" sortable sortField="type">
								<template #body="row">
									{{ row.data.type }}
								</template>
							</p-column>
							<p-column field="label" header="Template Label" sortable sortField="label">
								<template #body="row">
									{{ row.data.label }}
								</template>
							</p-column>
							<p-column field="cancellations" header="Cancellations" class="column-align-center">
								<template #body="row">
									<icon v-if="row.data.cancellations.enabled" type="check" size="24px" />
									<icon v-else type="close-circle-outline" size="24px" />
								</template>
							</p-column>
							<p-column field="status" header="Status" class="column-align-center" sortable sortField="status">
								<template #body="row">
									{{ startCase(row.data.status) }}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
									</div>
								</template>
							</p-column>
						</p-data-table>
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { getShipperTemplates } from '@GQL';

export default {
	name: 'ShipperTemplatesListView',
	components: {},
	data() {
		return {
			loading: false,
			shipper_templates: [],
		};
	},
	async mounted() {
		await this.getShipperTemplates();
	},
	methods: {
		async getShipperTemplates() {
			this.loading = true;
			try {
				const result = await getShipperTemplates([]);
				this.shipper_templates = result;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get shipper_templates',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style scoped lang="less">
:deep(.tools) {
	max-width: 120px;
}
</style>
