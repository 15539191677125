<template>
	<div>
		<div class="content">
			<p-card class="channel-card">
				<template #content>
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<label class="control-label req" for="channel">Channel Name:</label>
									<div class="p-inputgroup">
										<p-input-text v-model="channel.name" />
									</div>
									<div v-if="v$.channel.name.$error" class="validation-error">
										{{ v$.channel.name.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="description">Description:</label>
							<div class="controls">
								<div class="field">
									<p-text-area
										id="description"
										v-model="channel.description"
										class="w-full"
										placeholder="Enter additional description"
										style="min-height: 4rem"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<div class="controls gap-20">
								<div class="field">
									<label class="control-label">Logo:</label>
									<image-upload
										v-model="channel.image_url"
										:filename="channel.id"
										folder="channels/assets"
										mode="advanced"
									/>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Vertical:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="channel.vertical_id"
										:options="vertical_options"
										placeholder="Select Vertical"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.channel.product.$error" class="validation-error">
										{{ v$.channel.product.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req">Product:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="channel.product"
										:options="product_options"
										placeholder="Select Product"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.channel.product.$error" class="validation-error">
										{{ v$.channel.product.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Lead Match Criteria:</label>
							<div class="controls">
								<div class="field">
									<code-editor v-model.parse="channel.match" />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Minimum Bid Adjustment Settings:</label>
							<div class="controls gap-20">
								<div class="field">
									<p-dropdown
										v-model="channel.minimum_bid_adjustment_type"
										:options="minimum_bid_adjustment_type_options"
										option-label="label"
										option-value="value"
									/>
								</div>
								<div class="field xs">
									<template v-if="channel.minimum_bid_adjustment_type === 'multiply'">
										<p-input-percentage v-model="channel.minimum_bid_adjustment_amount" class="input-align-right" />
									</template>
									<template v-else>
										<p-input-currency v-model="channel.minimum_bid_adjustment_amount" class="input-align-right" />
									</template>
								</div>
							</div>
						</div>
					</div>
					<div v-if="channel.product === 'call'" class="control-group">
						<div class="inner">
							<div class="controls gap-20">
								<div class="field">
									<label class="control-label">Minimum Duration:</label>
									<div class="p-inputgroup">
										<p-input-number v-model="channel.minimum_duration" />
										<div class="p-inputgroup-addon">s</div>
									</div>
								</div>
								<div class="field">
									<label class="control-label">Maximum Duration:</label>
									<div class="p-inputgroup">
										<p-input-number v-model="channel.maximum_duration" />
										<div class="p-inputgroup-addon">s</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label req" for="status">Status:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										id="status"
										v-model="channel.status"
										:options="status_options"
										placeholder="Select Status"
										option-label="label"
										option-value="value"
									/>
									<div v-if="v$.channel.status.$error">
										<p v-for="error in v$.channel.status.$errors" :key="error.$uid" class="validation-error">
											{{ error.$message }}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template #footer>
					<div class="flex justify-content-between align-items-center">
						<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
						<p-button icon="pi pi-check" label="Save Channel" @click.prevent="save" />
					</div>
				</template>
			</p-card>
		</div>
	</div>
</template>

<script lang="ts">
import { channel } from 'diagnostics_channel';
import { channel_status_options, product_type_options, vertical_options } from '@/lib/Options';
import { cloneDeep } from 'lodash-es';
import { getChannelById, insertChannel, updateChannel } from '@GQL';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import imageUpload from '@/components/forms/ImageUpload.vue';
import pChips from 'primevue/chips';
import pTextArea from 'primevue/textarea';

const default_channel = {
	id: null,
	mpid: '$NG',
	name: '',
	description: '',
	image_url: null,
	product: null,
	vertical_id: null,
	match: [
		[
			{
				target: {
					path: 'lead.source_id',
				},
				strategy: 'one_of',
				comparator: {
					value: ['SOURCE_ID'],
				},
			},
		],
	],
	minimum_bid_adjustment_type: 'multiply',
	minimum_bid_adjustment_amount: 1,
	minimum_duration: 0,
	maximum_duration: 60,
	status: 'active',
};

export default {
	name: 'ChannelForm',
	components: {
		imageUpload,
		pTextArea,
		pChips,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			channel: cloneDeep(default_channel),
			vertical_options: vertical_options,
			product_options: product_type_options,
			minimum_bid_adjustment_type_options: [
				{ label: 'Multiply', value: 'multiply' },
				{ label: 'Add', value: 'add' },
				{ label: 'Flat', value: 'flat' },
			],
			status_options: channel_status_options,
		};
	},
	validations() {
		return {
			channel: {
				name: { required },
				description: { required },
				product: { required },
				status: { required },
			},
		};
	},
	async mounted() {
		try {
			if (!this.$route.meta.new) {
				this.loading = true;
				const channel = await getChannelById(this.$route.params.channel_id);
				if (channel) {
					this.channel = channel;
				}
				this.loading = false;
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
				detail: err.message,
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async save() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				const new_channel = cloneDeep(this.channel);
				new_channel.sources = this.sources;
				try {
					let result;
					if (this.$route.meta.new) {
						result = await insertChannel(new_channel);
					} else {
						result = await updateChannel(this.channel.id, new_channel);
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully ${this.$route.meta.new ? 'inserted new' : 'updated'} channel`,
							detail: `${new_channel.id}`,
							life: 3000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} channel`,
							detail: `${new_channel.id}`,
							life: 3000,
						});
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} channel`,
						detail: `${new_channel.id}`,
						life: 3000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
};
</script>

<style scoped lang="less">
label {
	font-size: var(--font-size-sm);
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.sub-value {
	color: var(--gray-50);
	display: inline-block;
	font-size: var(--font-size-sm);
}

.channel-card {
	max-width: 800px;
}
</style>
