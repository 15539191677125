import { $GQL } from '@GQL/index';
import sortBy from 'lodash/sortBy';

export async function getProvidersAsOptions() {
	const filters: string[] = [];

	const query = `
        query GetProvidersAsOptions($filters: [[Any]]!) {
            providers(filters: $filters) {
                value: id
                label: name
            }
        }
    `;

	const results = await $GQL.request(query, { filters });
	return sortBy(results.providers, ['label']);
}
