import { getUsersAsOptions } from './getUsersAsOptions';

/**
 * Easily returns all the NGL employes
 * TODO - add a filter to get specific roles (for when admins only should be in the drop down for example
 */
export async function getInternalUsersAsOptions(team?: string) {
	const filters = [
		[
			{
				field: 'account_id',
				op: 'is_null',
			},
			{
				field: 'status',
				op: 'eq',
				value: 'active',
			},
		],
	];

	if (team) {
		filters[0].push({
			field: `settings->>'team'`,
			op: 'eq',
			value: team,
		});
	}

	return getUsersAsOptions(filters);
}
