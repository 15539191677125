import { $GQL } from '@GQL/index';

export async function massMigrateAccounts(params: {
	companies: string[];
	mpid: string;
	dry_run?: boolean;
	parent_account_id?: string;
}) {
	const mutation = `
        mutation massMigrateAccounts($params: JSONObject!) {
            massMigrateAccounts(params: $params) {
                balance_migrated
                data_migrated
                companies
            }
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.massMigrateAccounts;
}
