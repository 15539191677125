// ----------------------------------------------------------------------------------------------------
// FILTER: currency
// Format a number as a currency
// ----------------------------------------------------------------------------------------------------

import { isNumber } from 'lodash-es';

export function currency(value: number, decimal?: number) {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: isNumber(decimal) ? decimal : 2,
	}).format(value);
}
