<template>
	<div class="pending-follow-up">
		<div class="control-group">
			<div class="line-item">
				<div class="label">Pending Follow Up:</div>
				<div class="value" v-if="has_pending_follow_up && 'due_at' in pending_follow_up">
					Due: {{ formatDate(new Date(pending_follow_up.due_at), 'M/DD/YYYY') }}
					<a @click="openResolveModal">
						<icon v-tooltip.top="'Resolve Follow Up'" type="square-edit-outline" size="16px" />
					</a>
				</div>
				<div v-else class="value">
					<div class="inner" v-if="adding_follow_up">
						<label class="control-label req">Due On:</label>
						<div class="controls gap-20 w-full">
							<div class="field m">
								<p-calendar v-model="new_due_at_date" date-format="D, M d, yy" :manual-input="false" />
							</div>
							<p-button @click="createFollowUp" icon="pi pi-check" />
						</div>
					</div>
					<div v-else>
						None
						<a @click="this.adding_follow_up = true">
							<icon v-tooltip.top="'Add Follow Up'" type="plus" size="16px" />
						</a>
					</div>
				</div>
			</div>
		</div>
		<ResolveFollowUpModal
			v-if="pending_follow_up"
			ref="resolve_follow_up_modal"
			:follow_up_id="pending_follow_up.id"
			:account_id="account_id"
			@refresh="updateFollowUp"
		/>
	</div>
</template>

<script>
import { getFollowUps, insertFollowUp } from '@GQL';
import { orderBy } from 'lodash-es';
import { formatDate } from '@/lib/Filters';
import ResolveFollowUpModal from '@/views/FollowUps/Record/ResolveFollowUpModal.vue';
import pCalendar from 'primevue/calendar';

export default {
	name: 'PendingFollowUpAccountComponent',
	props: {
		account_id: {
			type: String,
			required: true,
		},
	},
	components: {
		ResolveFollowUpModal,
		pCalendar,
	},
	data() {
		return {
			loading: false,
			has_pending_follow_up: false,
			pending_follow_up: null,
			new_due_at_date: new Date(),
			adding_follow_up: false,
		};
	},
	async mounted() {
		await this.getPendingFollowUps();
	},
	methods: {
		formatDate,
		async getPendingFollowUps() {
			this.loading = true;
			try {
				const pending_follow_ups = await getFollowUps([[`account_id = '${this.account_id}'`, `status = 'pending'`]]);
				// there should only be one
				this.has_pending_follow_up = true;

				if (pending_follow_ups.length == 1) {
					this.pending_follow_up = pending_follow_ups[0];
				} else if (pending_follow_ups.length > 1) {
					// weird......
					this.pending_follow_up = orderBy(pending_follow_ups, ['due_at'])[0];
				} else {
					this.has_pending_follow_up = false;
				}
			} finally {
				this.loading = false;
			}
		},
		openResolveModal() {
			this.$refs.resolve_follow_up_modal.openModal();
			// open the modal
		},
		updateFollowUp(data) {
			if (data.follow_up && 'due_at' in data.follow_up) {
				this.pending_follow_up = data.follow_up;
			} else {
				this.has_pending_follow_up = false;
				this.pending_follow_up = null;
			}

			this.$emit('refreshDisposition', data.disposition);
		},
		async createFollowUp() {
			// create follow up modal will have a date selector
			try {
				const result = await insertFollowUp({
					account_id: this.account_id,
					due_at: this.new_due_at_date,
					status: 'pending',
				});
				if ('id' in result) {
					this.$toast.add({
						severity: 'success',
						summary: 'New Follow up was created',
						life: 4000,
					});
					await this.getPendingFollowUps();
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to ',
					});
				}
			} catch (err) {}
		},
	},
};
</script>
<style scoped lang="less">
.line-item {
	line-height: 1.35em;
	margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}

	.label {
		color: var(--gray-50);
		font-size: var(--font-size-sm);
	}

	.value {
		font-weight: bold;
		font-size: 16px;
	}
}
</style>
