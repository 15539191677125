// ----------------------------------------------------------------------------------------------------
// FILTER: phoneFormat
// Format a string as a phone number
// ----------------------------------------------------------------------------------------------------

export function phoneFormat(value) {
	if (value && typeof value === 'string') {
		let pn = value;
		if (value.length !== 10) {
			pn = value.substr(value.length - 10, 10);
		}

		return pn.replace(/([0-9X\*]{3})([0-9X\*]{3})([0-9X\*]{4})/, '($1) $2-$3');
	}

	return null;
}
