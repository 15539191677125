import { $GQL } from '@GQL';
import type { PurchaseResult } from '@nextgenleads/broker-driver';

export interface CancelPendingPurchaseParams {
	status_message: string;
	status_changed_by: string; // current agent
}
export async function cancelPendingPurchase(
	purchase_id: string,
	data: CancelPendingPurchaseParams
): Promise<PurchaseResult> {
	const mutation = `
        mutation CancelPendingPurchase($purchase_id: ID!, $data: JSONObject){
            cancelPendingPurchase(purchase_id: $purchase_id, data: $data)
        }
    `;

	const result = await $GQL.request(mutation, { purchase_id, data });
	return result.cancelPendingPurchase;
}
