import { $GQL } from '@GQL/index';
import { startCase, sortBy, groupBy } from 'lodash-es';
import type { QueryFilters } from '@nextgenleads/db';

/**
 * FUNCTION is meant to return User options with their Emails as the value
 */
export async function getUserEmailsAsOptions(filters?: QueryFilters) {
	const query = `
        query GetUsersEmailsAsOptions($filters: [[Any]]!) {
            users(filters: $filters) {
                first_name
                last_name
                email
                role_id
            }
        }
    `;

	const results = await $GQL.request(query, { filters });

	const users = results.users;

	// DIRTY TRICK TO GROUP THE USERS BY THE ROLE AT NGL (MOSTLY USED TO GET INTERNAL USERS, FILTERS ACCOMPLISH THIS)
	const grouped = groupBy(users, (row) => {
		return row.role_id['$NG'];
	});
	const final_options = [];
	Object.keys(grouped).forEach((key) => {
		let group = startCase(key); //?

		const unsorteditems = grouped[key].map((row) => {
			return {
				value: row.email,
				label: row.first_name + ' ' + row.last_name,
			};
		});
		final_options.push({
			label: group,
			items: sortBy(unsorteditems, ['label']),
		});
	});

	return sortBy(final_options, ['label']);
}
