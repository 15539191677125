<template>
	<div class="view-content">
		<div id="terms-and-conditions">
			<h1>TERMS AND CONDITIONS</h1>
			<p>Last Updated: February 7, 2022</p>
			<p>
				THESE TERMS AND CONDITIONS ("TERMS") ARE A LEGAL, BINDING AGREEMENT BETWEEN NEXTGEN LEADS, LLC, 701 B STREET,
				SUITE 1255, SAN DIEGO, CALIFORNIA, 92101, A LEADS AND MARKETING SERVICES PROVIDER ("WE" OR "NEXTGEN"), AND YOU
				(“YOU” OR “PURCHASER”), THE PURCHASER OR RECIPIENT OF NEXTGEN'S PRODUCTS AND SERVICES (“SERVICES”) THROUGH THIS
				WEBSITE OR ANY OTHER DIGITAL PLATFORM WHERE THESE TERMS ARE POSTED (“WEBSITE”).
			</p>
			<h2>ACKNOWLEDGMENT AND ACCEPTANCE</h2>
			<ul>
				<li>
					Please read these Terms carefully before accessing our Website or using our Services. BY USING OUR WEBSITE OR
					OUR SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE TO ANY OR ALL OF THESE TERMS YOU MAY
					NOT USE OUR WEBSITE OR SERVICES.
				</li>
			</ul>
			<h2>CHANGES</h2>
			<ul>
				<li>
					We reserve the right to make changes to these Terms at any time and at our discretion. Any new feature or tool
					added to our Website or Services shall be subject to these Terms, as amended. New versions of these Terms will
					not apply retroactively, but shall immediately replace and supersede the previous Terms upon posting. Your
					continued use of our Website and Services, or any part thereof, following the posting of the revised Terms
					means You accept and agree to the changes.
				</li>
			</ul>
			<h2>ARBITRATION NOTICE AND CLASS ACTION WAIVER</h2>
			<ul>
				<li>
					EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED BELOW, YOU AGREE THAT DISPUTES ARISING UNDER THESE TERMS WILL
					BE RESOLVED BY CONFIDENTIAL BINDING, INDIVIDUAL ARBITRATION, AND BY ACCEPTING THESE TERMS, YOU AND NEXTGEN ARE
					EACH WAITING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION LAWSUIT, CLASS-WIDE
					ARBITRATION, OR OTHER REPRESENTATIVE PROCEEDING. YOU AGREE TO GIVE UP YOUR RIGHT TO GO TO COURT TO ASSERT OR
					DEFEND YOUR RIGHTS UNDER THIS CONTRACT (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT OR ARE
					OTHERWISE EXCEPTED BY LAW). YOUR RIGHTS WILL BE DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY.
					FOR ADDITIONAL DETAILS, SEE THE “DISPUTE RESOLUTION” SECTION BELOW.
				</li>
			</ul>
			<h2>SERVICES</h2>
			<h3><strong>Leads Generation</strong></h3>
			<ul>
				<li>
					NextGen sells leads in the form of contact information submitted through one of NextGen’s digital platforms
					(“Leads”). By purchasing Leads from NextGen, You agree to purchase from data collected from consumers who
					complete an online form and submit contact information and details about their request for information or
					service quotes, and to agree to the following terms:
				</li>
				<li>
					<strong>Data.</strong> A lead will include only information that has been input by the individual and the
					following lead contact information as available, unless otherwise agreed to by You and NextGen in writing: (a)
					first and last name, (b) address, (c) email address, and (d) phone number. NextGen makes no representations
					about the quantity, sufficiency or quality of leads that it provides to You.
				</li>
				<li>
					<strong>Payment.</strong> You will be charged a cost per lead stated at the time of the purchase, and fees may
					vary from purchase to purchase. Fees accrue and are due upon your receipt of the leads. You agree to provide
					us current and valid credit card information (type, name, number, expiration date, etc.) and hereby authorize
					us (directly or through our agents) to charge the credit card you have provided in the amount of all or part
					of the fees payable for the leads purchased plus any taxes we may be required to collect. We may require that
					You fund in advance Your account with us in an amount determined by us from time to time. If applicable, You
					authorize us to make a charge against your account pre-funded balance in the amount of the fees for the leads
					that are delivered to You. Pre-funded balances will need to be replenished to maintain the balance at the
					required minimum amounts established by us from time to time. We reserve the right to not deliver leads if
					there is not a sufficient balance in Your account to pay for the leads.
				</li>
				<li>
					<strong>Cancellation &amp; Refunds.</strong> Purchases of leads are not cancellable, and fees paid are not
					refundable. You are not authorized to use any leads that are not paid for.
				</li>
				<li>
					<strong>Authorized Use &amp; Purpose of Leads.</strong> Leads and related marketing services are provided on a
					non-exclusive basis for a specific type of product or service for which the consumer submitted the online form
					("the specific vertical"), such as for example, healthcare insurance. You are authorized to use the leads and
					related marketing services provided by us solely for your internal marketing purposes of proposing only your
					products and services in the specific vertical to the consumer. You may not use the lead or related marketing
					services for purposes of marketing or offering products and services other than those that the consumer has
					specifically inquired about, or of third parties.
				</li>
				<li>
					<strong>Restrictions.</strong> Leads and their related marketing services are confidential information of
					NextGen. YOU AGREE NOT TO SHARE WITH OTHERS, LICENSE, RESELL, DISTRIBUTE OR OTHERWISE DISCLOSE ANY OR ALL OF
					THE LEADS OR SERVICES OR THE INFORMATION CONTAINED IN THEM, ALONE OR AS PART OF A LIST OR ANY OTHER DATA OR
					LEAD AGGREGATION, TO ANY THIRD PARTY FOR ANY PURPOSE, EXCEPT ONLY TO AN ENTITY ENGAGED BY YOU AS A CONTRACTOR
					FOR THE SOLE PURPOSE OF ASSISTING YOU IN CONNECTION WITH THE AUTHORIZED USE OF THE LEADS BY YOU. YOU FURTHER
					AGREE NOT TO USE CONSUMER INFORMATION SUBMITTED TO NEXTGEN FOR THE MARKETING OR SALE OF MEDICARE ADVANTAGE
					PLANS OR MEDICARE PART D (PRESCRIPTION DRUG PLANS).
				</li>
			</ul>
			<h3><strong>Customer Relationship Management (CRM) System And Services</strong></h3>
			<ul>
				<li>
					NextGen offers a CRM system for purchase. By purchasing our CRM system and all associate software, technical
					support, and documentation (collectively, “CRM System”), You agree to the following terms:
				</li>
				<li>
					<strong>Data Collection &amp; Usage.</strong> Any and all data transmitted, uploaded, or processed through the
					CRM System by You (“Customer Data”) shall be owned by You. The underlying CRM System, including any template
					reports, forms, or other features of the CRM System, remain the sole property of NextGen. The CRM System also
					tracks metadata and other usage data related to Your use of the CRM System (“Usage Data”) and provides such
					data from time to time to You. NextGen shall own such Usage Data, excluding any Customer Data incorporated
					therein. You agree that NextGen shall have the perpetual right to collect, aggregate, use, distribute and sell
					such Usage Data for any legal purpose, including without limitation for the purposes of providing services and
					improving the CRM System and NextGen’s products and services generally. NextGen may retain and use Usage Data
					permanently. To the extent such Usage Data contains any Customer Data, NextGen shall not sell or otherwise
					provide such Usage Data to any third party unless the data has been anonymized and/or de-identified as defined
					under Applicable Law (e.g., no name or address attached to the particular data) and/or aggregated with other
					users’ data, so that it is not identifiable as to any particular person. Notwithstanding the foregoing,
					NextGen may share Usage Data in its original form as necessary or appropriate to provide services to You (for
					example, using a third party to process payments) or to comply with legal obligations or exercise its legal
					rights. You will be responsible for all data inputted into the CRM system, and confirm that you have complied
					with all applicable law prior to uploading any such information to the CRM System. NextGen provides the
					infrastructure and software for the CRM only. For the purposes of the California Consumer Privacy Act of 2018
					and its implementing regulations, as amended from to time (“CCPA”), NextGen will agree to serve as a “service
					provider”, as that phrase is defined under the CCPA, and will not otherwise use any data imported into the CRM
					for any commercial purpose or other purpose other than to service the CRM to You.
				</li>
				<li>
					<strong>Access, Ownership, and Use of CRM System.</strong> In exchange for the payment described below, and
					the other promises and conditions set forth in this section, NextGen hereby grants to You a non-exclusive
					right to access and use the CRM system during the term of your agreement with NextGen. NextGen owns and
					retains all right, title and interest in and to the CRM System. Any rights not expressly granted herein
					relating to the CRM System are reserved by NextGen. Neither these Terms nor any other agreement between You
					and NextGen shall change ownership of any pre-existing software, materials, or other legal rights either You
					or NextGen may possess. You are only authorized to use the CRM System for the limited purposes set forth in
					these Terms. You shall not, and shall not attempt to, directly or indirectly (a) rent, lease, sublicense,
					encumber, distribute, transfer, copy, reproduce, modify or timeshare the CRM System or any portion thereof;
					(b) use any information provided to You by NextGen to create any application, service, software or
					documentation that is similar to the CRM system; (c) use the CRM system or any NextGen Confidential
					Information for any competitive purpose, including to develop any product or service that competes, directly
					or indirectly, with any products or services offered by NextGen; (d) adapt, combine, create derivative works
					of or otherwise modify the CRM system; (e) translate, disassemble, decompile, reverse engineer or otherwise
					attempt to discover any source code, algorithms or trade secrets underlying the CRM system; (f) probe, scan or
					test the vulnerability of the CRM system; (g) breach the security or authorization measures of the CRM system;
					(h) use, or permit the use of, the CRM system in violation of applicable law; (i) use or allow the
					transmission, transfer, export, re-export or other transfer of any product, technology or information it
					obtains or learns pursuant to this Agreement (or any direct product thereof) in violation of any export
					control or other laws and regulations of the United States or any other relevant jurisdiction.
				</li>
				<li>
					<strong>Customer Content.</strong> You hereby grant NextGen a non-exclusive, transferable, sublicensable,
					fully paid-up and royalty-free right and license to access, copy, perform, modify, display, process and
					otherwise use the content, information and materials originated by You that You submit or provide in
					connection with Your use of the CRM System (“Customer Content”) solely for the purpose of performing under
					these Terms and providing the CRM System. You shall reasonably cooperate with NextGen to provide any Customer
					Content necessary for NextGen’s performance under these Terms. You agree that You shall bear all
					responsibility and liability for the accuracy, completeness, quality, integrity, legality, reliability,
					appropriateness, and intellectual property ownership or right of the Customer Content and NextGen’s access,
					possession and use as permitted herein.
				</li>
				<li>
					<strong>Feedback.</strong> You agree that NextGen is free to reuse all general knowledge, experience,
					know-how, works and technologies (including ideas, concepts, processes and techniques) acquired during
					provision of the CRM System (including that which it could have acquired performing the same or similar
					services for another client) to the extent that the same does not include the intentional disclosure of any
					Confidential Information. You hereby grant NextGen a perpetual, irrevocable, royalty-free and fully paid-up
					license to use and exploit all feedback, suggestions and comments provided by You to NextGen with regard to
					the CRM System, including, without limitation, the testing, development, maintenance and improvement of the
					CRM System.
				</li>
				<li>
					<strong>Payment.</strong> You will be charged in the amount stated at the time of the purchase, and fees may
					vary from purchase to purchase. Fees accrue and are due upon your receipt of the leads. You agree to provide
					us current and valid credit card information (type, name, number, expiration date, etc.) and hereby authorize
					us (directly or through our agents) to charge the credit card you have provided in the amount payable for the
					CRM system and related services purchased plus any taxes we may be required to collect. We may require that
					You fund in advance your account with us in an amount determined by us from time to time. If applicable, You
					authorize us to make a charge against your account pre-funded balance in the amount payable for the CRM System
					and related services purchased plus any taxes we may be required to collect. Pre-funded balances will need to
					be replenished to maintain the balance at the required minimum amounts established by us from time to time.
				</li>
				<li>
					<strong>Suspension Rights.</strong> Without limiting any other rights or remedies, NextGen, in its sole
					discretion, may suspend Your rights to access the CRM System if NextGen believes (i) You have violated, or are
					in violation, of these Terms; or (ii) there has been fraud, unlawful or other suspicious activity.
				</li>
				<li>
					<strong>Third-Party Software.</strong> Any open source components of the CRM System are subject to the
					applicable third-party license or subscription terms. Other third-party software embedded into the CRM System
					is provided by NextGen to You pursuant to these Terms, unless a separate third-party license or subscription
					agreement for such third-party software is provided to You in advance. Third-party software is authorized only
					for use in connection with the CRM System, unless otherwise permitted under an open source license.
				</li>
				<li>
					<strong>Technical Support &amp; Maintenance.</strong> NextGen will provide technical support and maintenance
					to the CRM System as reasonably necessary and commercially available. More details about NextGen’s technical
					support and maintenance of the CRM System will be available upon purchase.
				</li>
				<li>
					<strong>Confidential Information.</strong> For the purposes of these Terms, “Confidential Information” means
					the CRM System, Customer Data, either party’s non-public business and technology information, trade secrets,
					NextGen’s pricing, and any written materials marked as confidential and any other information which is clearly
					identified as confidential or proprietary at the time of disclosure or that the receiving party reasonably
					should be understood to be confidential. Confidential Information excludes information that the receiving
					party can document: (i) is or becomes generally available to the public without fault of the receiving party;
					(ii) was rightfully in the receiving party’s possession prior to its disclosure by the other party; (iii) is
					independently developed without the use of any Confidential Information of the disclosing party; or (iv) is
					obtained without obligation of confidentiality from a third party who has the right to disclose it. The
					receiving party also may disclose Confidential Information to the extent required under a judicial or
					legislative order or proceeding or as necessary to comply with open records acts or other freedom of
					information laws or regulations; provided that it gives the disclosing party, if legally permissible,
					reasonable prior notice and an opportunity to respond or object to the disclosure. All Confidential
					Information provided by the disclosing party will be maintained in confidence by the receiving party, and the
					receiving party will not, during the course of these Terms and following the expiration or termination of
					these Terms, divulge to any person or organization, or use in any manner whatsoever, directly or indirectly,
					for any reason whatsoever, any of the Confidential Information without receiving the prior written consent of
					disclosing party. In the event these Terms are terminated for any reason, or at any time upon the request of
					the disclosing party, the receiving party agrees to return or destroy such Confidential Information, at the
					option of the disclosing party, not including any information required for the receiving party to use or
					provide their respective services.
				</li>
			</ul>
			<h2>COMPLIANCE</h2>
			<ul>
				<li>
					It is Your sole responsibility to familiarize Yourself with all laws and regulations applicable to the
					receipt, use, storage and safeguarding of data provided by us or maintained by you in connection with your use
					of our Services. You agree to maintain commercially reasonable physical, electronic and procedural controls
					and safeguards to protect any such information received pursuant to these Terms from unauthorized use or
					disclosure, including the maintenance of appropriate safeguards to restrict access to the consumer data solely
					to carry out the purposes for which the information was disclosed.
				</li>
			</ul>
			<h2>WARRANTIES &amp; DISCLAIMER</h2>
			<h3>
				<strong>Warranties</strong>
			</h3>
			<ul>
				<li>
					You represent and warrant that you have full right, power, and authority to enter into these Terms, and the
					execution, delivery, and performance of these Terms do not conflict with any other agreement to which You are
					a party or by which You are bound by applicable law. You further warrant:
				</li>
				<li>
					Your use of Leads, and any communications arising out of those Leads, will comply with all applicable law,
					including but not limited to the Communications Act of 1934, as amended, the Telephone Consumer Protection Act
					("TCPA") and implementing regulations issued by Federal Communications Commission (as well as all state
					counterparts), the Telemarketing and Consumer Fraud and Abuse Prevention Act, the Federal Trade Commission’s
					Telemarketing Sales Rule, the Controlling the Assault of Non-Solicited Pornography and Marketing Act, and
					other federal and state laws and regulations governing the marketing, promotion, and/or sales of goods or
					services, including general consumer protection laws and regulations, or other consumer protection laws that
					prohibit unfair, deceptive, or misleading acts or practices;
				</li>
				<li>
					You will not make any calls to any individual listed on any federal or state national Do-Not-Call (DNC)
					registry unless an exemption applies;
				</li>
				<li>
					NextGen is not a credit or consumer reporting agency under the Fair Credit Reporting Act (“FCRA”) or related
					state laws. You will not use any Leads for purposes of determining a person’s eligibility for insurance,
					credit, employment or otherwise in any manner that violates the Fair Credit Reporting Act or related state
					laws;
				</li>
				<li>
					You have obtained any and all required licenses, permits, and other authorizations required by any law,
					regulation, or government or regulatory authority to conduct Your business as presently conducted, including
					offering and selling the products and services that are the subject of the Leads;
				</li>
				<li>
					You have disclosed the existence of any federal or state decrees, orders, or consent agreements, and any
					pending formal or informal government investigations, and you further represent and warrant that if You become
					involved or named in any action, investigation, complaint or other proceeding by or before any governmental or
					regulatory authority, or any private party, You will immediately provide notice to NextGen of such action,
					investigation, complaint or other proceeding;
				</li>
				<li>You will not use the CRM System or upload content to the CRM System that:</li>
				<li>You know is false, misleading, untruthful or inaccurate;</li>
				<li>
					You know is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive
					of another’s privacy, tortious, obscene, vulgar, pornographic, offensive, profane, contains or depicts nudity,
					contains or depicts sexual activity, or is otherwise inappropriate as determined by us in our sole discretion;
				</li>
				<li>Constitutes unauthorized or unsolicited advertising, junk or bulk e-mail, or other “spamming”;</li>
				<li>
					Contains software viruses or any other computer codes, ﬁles, or programs that are designed or intended to
					disrupt, damage, limit or interfere with the proper function of any software, hardware, or telecommunications
					equipment or to damage or obtain unauthorized access to any system, data, password or other information of
					ours or of any third party; or
				</li>
				<li>Impersonates any person or entity, including any of our employees or representatives.</li>
				<li>You will ensure that your employees, contractors and agents comply with the foregoing.</li>
			</ul>
			<h3>
				<strong>Disclaimers</strong>
			</h3>
			<ul>
				<li>
					THE WEBSITE AND SERVICES ARE PROVIDED "AS IS", "WHERE IS". EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION,
					NEITHER PARTY MAKES ANY WARRANTIES TO THE OTHER, AND ALL IMPLIED, STATUTORY OR OTHER WARRANTIES ARE DISCLAIMED
					TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
					PURPOSE, COMPLIANCE WITH THE LAWS, NON-INFRINGEMENT, TITLE, OR BASED ON COURSE OF DEALING OR USAGE OF TRADE.
					WE DO NOT GUARANTEE THAT THE INFORMATION PROVIDED BY THE CONSUMER FOR THE PURPOSE OF THE LEADS IS ACCURATE OR
					COMPLETE, OR THE RESULTS TO BE ACHIEVED FROM THE LEADS, INCLUDING ANY SPECIFIC CUSTOMER CONVERSION RATES. WE
					ALSO DO NOT MAKE ANY REPRESENTATION OR WARRANTY: (1) THAT THE CRM SYSTEM WILL MEET YOUR REQUIREMENTS OR BE
					UNINTERRUPTED, ERROR-FREE OR BUG-FREE; (2) REGARDING THE SECURITY, RELIABILITY, TIMELINESS, OR PERFORMANCE OF
					THE CRM SYSTEM; (3) THAT ANY ERRORS IN THE CRM SYSTEM WILL BE CORRECTED; OR (4) THAT THE CRM SYSTEM DOES NOT
					INFRINGE, MISAPPROPRIATE OR OTHERWISE VIOLATE THE INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY. YOU
					UNDERSTAND AND ACCEPT THAT LEADS AND CONTACT INFORMATION COLLECTED HAVE NOT BEEN SCREENED OR VALIDATED BY US
					AND ARE NOT GUARANTEED TO BE ACCURATE OR ERROR-FREE AND ALL RISKS OF CONDITION, USE, QUALITY, DESIGN, OR
					FITNESS ARE YOURS.
				</li>
			</ul>
			<h2>INDEMNIFICATION</h2>
			<ul>
				<li>
					You agree to indemnify, defend and hold harmless NextGen and its members, directors, officers and affiliates,
					successors and assigns (“Indemnified Parties”) from and against any liabilities, damages, costs and expenses
					(including reasonable attorney’s fees) resulting from a third party claim or investigation (“Claims”) in any
					way arising by reason of or relating to You, Your affiliates’, or Your or their employees’, contractors’ or
					agents’ activities with respect to: (1) compliance with these Terms and/or applicable law; (2) any claim that
					Your use of our Services violates, infringes upon, or misappropriates any patent, trademark, copyright, trade
					secret or other intellectual property or proprietary right of any third party; or (2) any taxes arising from
					the transactions relating to these Terms. This section survives termination of these Terms, and remains in
					effect for a period of five (5) years from the date you last purchased our Services.
				</li>
			</ul>
			<h2>LIMITATION OF LIABILITY</h2>
			<ul>
				<li>
					TO THE FULLEST EXTENT PERMITTED BY LAW, NEXTGEN WILL NOT BE LIABLE TO YOU, YOUR AFFILIATES OR RESPECTIVE
					CONTRACTORS OR AGENTS OR ANY THIRD PARTY, REGARDLESS OF THE CAUSE OR ACTION, FOR: (i) ANY INDIRECT,
					CONSEQUENTIAL, EXEMPLARY, SPECIAL OR RELIANCE DAMAGES RESULTING FROM OR ARISING IN CONNECTION WITH WEBSITE OR
					SERVICES, ANY INFORMATION WE PROVIDE TO YOU IN CONNECTION THEREWITH, THESE TERMS OR ANY MATTERS RELATING
					THERETO, INCLUDING WITHOUT LIMITATION LOSS OF REVENUES, PROFITS, BUSINESS OPPORTUNITY OR DATA, EVEN IF ADVISED
					OF THE POSSIBILITY OF SUCH DAMAGES; OR (ii) ANY DAMAGES OF ANY TYPE, IN THE AGGREGATE AT ANY TIME, IN EXCESS
					OF THE AMOUNT PAID BY YOU TO US FOR OUR SERVICES IN THE MOST RECENT THREE (3) MONTH PERIOD. THE LIMITATIONS
					AND EXCLUSIONS OF LIABILITY HEREIN SHALL APPLY EQUALLY FOR THE BENEFIT OF NEXTGEN’S SUPPLIERS, OFFICERS,
					AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES WHEN ACTING IN CONNECTION WITH THE SUBJECT MATTER OF
					THESE TERMS.
				</li>
				<li>
					YOU ACKNOWLEDGE THAT NEXTGEN WOULD NOT HAVE ENTERED INTO THIS AGREEMENT BUT FOR THE LIMITATIONS CONTAINED IN
					THIS SECTION.
				</li>
			</ul>
			<h2>TERMINATION</h2>
			<ul>
				<li>
					You or we may terminate our relationship at any time by discontinuing the provision or purchase of our
					Services. These Terms survive termination for any reason, and continue to apply indefinitely to any Services
					purchased or received hereunder and all matters relating to such Services, including without limitation the
					use restrictions, warranties, indemnification, limitation of liability and mandatory arbitration provided
					herein.
				</li>
			</ul>
			<h2>MANDATORY ARBITRATION</h2>
			<ul>
				<li>
					These terms are governed by the laws of the State of Delaware. You and we agree to resolve any disputes
					relating to these terms, the leads or other marketing services exclusively by binding arbitration, before a
					single arbitrator, pursuant to the rules of the American Arbitration Association. The seat of the arbitration
					will be the jurisdiction where the party in the position of defendant is domiciled. The arbitrator shall be
					selected pursuant to the AAA rules, or otherwise as mutually agreeable to both parties. To begin the
					arbitration process, a party must make a written demand therefor. The decision of the arbitrator may be
					enforced in any court of competent jurisdiction.
				</li>
			</ul>
			<h2>PRIVACY</h2>
			<ul>
				<li>
					The Services are subject to the privacy practices set forth in our Privacy Policy. For the purposes of the CRM
					System, the NextGen Data Protection Addendum applies.
				</li>
			</ul>
			<h2>MISCELLANEOUS</h2>
			<ul>
				<li>
					Force Majeure. Under no circumstances shall NextGen be held responsible or liable for any delay or failure in
					performance resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable
					control, including, without limitation, Internet failures, computer equipment failures, telecommunication
					equipment failures, other equipment failures, electrical power failures, strikes, labor disputes, riots,
					insurrections, civil disturbances, shortages of labor or materials, fires, floods, storms, explosions, acts of
					God, war (including but not limited to cyber related events and/or occurrences attributed to state and/or
					quasi-state actors by either public or privacy organizations and/or entities and/or governments), governmental
					actions, orders of domestic or foreign courts or tribunals, non-performance of third parties, or loss of or
					fluctuations in heat, lighting, or air conditioning.
				</li>
				<li>
					Terms Termination. These Terms are effective until terminated by You or NextGen. NextGen, in our sole
					discretion, may suspend or terminate these Terms at any time with or without notice, and may denay you access
					to the Website and Services as a result. You may also terminate these Terms at any time by discontinuing your
					use of the Website and Services and terminating your account. Upon termination of these Terms by NextGen or
					You, You must destroy all materials obtained by the Website and Services, including any and all copies of such
					materials whether made under these Terms or otherwise.
				</li>
				<li>
					Non-Assignability. You may not assign your rights under these Terms, by operation of law or otherwise, without
					our prior written consent. Subject to that restriction, these Terms will be binding upon, inure to the benefit
					of, and be enforceable against the parties and their respective successors and assigns.
				</li>
				<li>
					Statute of Limitations. You agree that regardless of any statue or law to the contrary, any claim or cause of
					action by You against NextGen arising from or relating to your use of the Website or Services must filed
					within one (1) year after such claim or cause of action arose or such claim or cause of action will be
					permanently barred.
				</li>
				<li>
					Entire Agreement; Severability; Relationship. These Terms constitute the entire agreement between You and
					NextGen. If any part of this Agreement are determined to be invalid or unenforceable, then such invalid or
					unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches
					the intent of the original provision and the allocation or risks, and the remainder of these Terms will
					continue in effect. If any provision(s) are found to be contrary to law, then such provision(s) will be
					construed, as nearly as possible, to reflect the intentions of the parties with the other provisions remaining
					in full force and effect. Any failure to exercise or delay in exercising any right, power or privilege under
					these Terms shall not operate as a waiver; nor shall any single or partial exercise of any right, power or
					privilege preclude any other or further exercise thereof. No agency, partnership, joint venture, fiduciary or
					other special relationship or employment is created as a result of these Terms, and you may not make any
					representations on behalf of or bind NextGen.
				</li>
				<li>
					Contact Us. If you have any questions about these Terms, contact us at
					<a href="mailto:legal@nextgenleads.com">legal@nextgenleads.com</a>.
				</li>
			</ul>
		</div>
	</div>
</template>

<script lang="ts">
export default {
	name: 'Terms-Conditions',
	data() {},
};
</script>

<style lang="less" scoped>
#terms-and-conditions {
	margin: 0 auto;
	max-width: 800px;
}

h1 {
	font-size: var(--font-size-h2);
	margin: 2rem 0;
}

h2 {
	font-size: var(--font-size-h4);
}

h3 {
	font-size: var(--font-size-sm);
	font-weight: bold;
}

p {
	font-size: clamp(0.8rem, 0.15vw + 0.7rem, 0.85rem);
	line-height: 1.5;
}

ul {
	list-style: disc;
	margin: 1rem 0;
	padding-left: 1.5rem;

	li {
		font-size: clamp(0.75rem, 0.15vw + 0.7rem, 0.8rem);
		line-height: 1.5;
		margin-bottom: 0.25rem;
	}
}
</style>
