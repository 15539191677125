export function formatCallDuration(duration: number) {
	if (duration) {
		// from seconds to minutes or hours
		const minutes = Math.floor(duration / 60);
		const seconds = duration % 60;

		return `${minutes ? minutes + 'm ' : ''}${seconds}s`;
	}
	return '0s';
}
