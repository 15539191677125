<template>
	<div class="settings-form">
		<h2>Twilio Settings</h2>
		<gutter size="10px" />
		<p-fieldset legend="Account Synchronization">
			<p>You can synchronize the local DB data with what appears in Twilio here.</p>
			<p-button label="Sync with Twilio" :loading="loading" @click="syncLocalWithTwilio" />
		</p-fieldset>
	</div>
</template>

<script>
import { syncLocalWithTwilio } from '@GQL';
import pBlockUi from 'primevue/blockui';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';

export default {
	name: 'TwilioSettings',
	components: {
		pBlockUi,
	},
	props: {
		localSettingsList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			loading: false,
			dates: null,
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		...mapStores(useMarketplaceStore),
	},
	methods: {
		async syncLocalWithTwilio() {
			this.loading = true;
			try {
				await syncLocalWithTwilio();
				this.$toast.add({
					severity: 'success',
					summary: 'Twilio Sync Complete',
					detail: 'Data from Twilio was successfully synched with local database',
					life: 5000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Twilio Sync Error',
					detail: 'Unable to sync Twilio data with local database',
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
