import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';
import type { AuctionLog } from '@nextgenleads/auction-driver';

export async function getAuctionHistory(filters?: QueryFilters, no_cache?: string[]): Promise<AuctionLog[]> {
	const query = `
        query GetAuctionHistory($filters: [[Any]]) {
            auctionLogs(filters: $filters) {
				id
				mpid
				attempt
                created_at
                status
                auction {
                    name
                }
				bids {
				    bid_type
				    max_bid
				    max_sorting_bid
				    sorting_bid
				    campaigns {
				        purchase {
				            price
				            original_price
				        }
				        max_bid
				        account {
				            name
                        }
                        campaign {
                            name
                            default_bid
                        }
				    }
				}
				participating_campaigns
				disqualifiedCampaigns {
				    dq_reason
				    account_id
				    account {
				        name
                    }
				    campaign {
				        name
				    }
				}
			}
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.auctionLogs;
}
