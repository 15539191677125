<template>
	<div class="modifier return-rate">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Return Rate</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">
			Reduce the bid by the return rate for the company. The return rate is the overall percentage of leads that are
			returned by a company.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field xs">
							<label class="control-label">Min Bid %:</label>
							<input-percentage v-model="modelValue.minimum_multiplier" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import inputPercentage from '@/components/forms/InputPercentage.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ReturnRateModifier',
	emits: ['remove'],
	components: {
		inputPercentage,
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					minimum_multiplier: 0.7,
				};
			},
		},
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
