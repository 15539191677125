import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';

export async function getShipperTemplates(filters: QueryFilters) {
	const query = `
        query GetShipperTemplates($filters: [[Any]]) {
            shipperTemplates(filters: $filters) {
                id
                name
                type
                label
				cancellations {
					enabled
                    pattern
				}
				status
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.shipperTemplates;
}
