import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getCallLogs(filters: QueryFilters) {
	const query = `
        query GetCallLogs($filters: [[Any]]) {
            callLogs(filters: $filters) {
                id
				provider_call_id
				provider_phone_id
				phone_type
				call_type
				duration
				buyer_duration
				ngl_phone
				lead_phone
				started_at
				ended_at
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.callLogs;
}
