<template>
	<div id="account-performance-report" class="view-content">
		<line-loader :show="loading" />
		<div class="page-heading">
			<h1>Account Performance</h1>
		</div>
		<div class="page-content">
			<row>
				<column :span="3">
					<row class="metrics">
						<column :span="3">
							<div class="card padded">
								<row>
									<column>
										<div class="quick-filters">
											<strong>Compare:</strong>
											<gutter size="10px" />
											<quick-filter v-model="metric_type" value="dod">Day</quick-filter>
											<quick-filter v-model="metric_type" value="wow">Week</quick-filter>
											<quick-filter v-model="metric_type" value="mom">Month</quick-filter>
										</div>
									</column>
								</row>
								<gutter size="10px" />
								<row>
									<column>
										<metric
											label="Leads Purchased"
											:value="currentMetric('volume')"
											:previous-value="previousMetric('volume')"
										/>
									</column>
									<gutter size="20px" />
									<column>
										<metric
											label="Total Spend"
											:value="currentMetric('spend')"
											:previous-value="previousMetric('spend')"
											is-currency
										/>
									</column>
									<gutter size="20px" />
									<column>
										<metric
											label="CPL"
											:value="currentMetric('cpl')"
											:previous-value="previousMetric('cpl')"
											is-currency
										>
										</metric>
									</column>
								</row>
							</div>
						</column>
					</row>
					<gutter size="20px" />
					<row class="velocity-charts">
						<column>
							<div class="card padded">
								<h2>Volume</h2>
								<p-chart
									ref="volume_chart"
									type="bar"
									:data="chartData('volume')"
									:height="300"
									:options="chartOptions()"
								/>
							</div>
						</column>
						<gutter size="20px" />
						<column>
							<div class="card padded">
								<h2>Spend</h2>
								<p-chart
									ref="spend_chart"
									type="bar"
									:data="chartData('spend')"
									:height="300"
									:options="chartOptions(true)"
								/>
							</div>
						</column>
						<gutter size="20px" />
						<column>
							<div class="card padded">
								<h2>CPL</h2>
								<p-chart
									ref="cpl_chart"
									type="bar"
									:data="chartData('cpl')"
									:height="300"
									:options="chartOptions(true)"
								/>
							</div>
						</column>
					</row>
					<gutter size="20px" />
					<row>
						<column>
							<div class="card padded">
								<div class="flex align-items-center justify-content-between">
									<h2 style="margin-bottom: 0">Campaign Activity</h2>
									<div class="campaign-quick-filters">
										<quick-filter v-model="metric_type" value="dod">Day</quick-filter>
										<quick-filter v-model="metric_type" value="wow">Week</quick-filter>
										<quick-filter v-model="metric_type" value="mom">Month</quick-filter>
									</div>
								</div>
								<gutter size="10px" />
								<p-data-table ref="dt" :loading="loading" :value="campaignsWithPurchases">
									<template #loading>
										<line-loader :show="loading" />
									</template>
									<template #empty>
										<div class="dim">No campaigns have purchased leads</div>
									</template>
									<p-column header="Campaign Name">
										<template #body="row">
											<div class="name">
												<router-link :to="campaignViewLink(row.data.id)">{{ row.data.name }}</router-link>
											</div>
											<div class="sub-value">
												{{ capitalize(row.data.product_targeting) }} - {{ capitalize(row.data.bid_type) }}
											</div>
										</template>
									</p-column>
									<p-column header="Status">
										<template #body="row">
											{{ capitalize(row.data.status) }}
										</template>
									</p-column>
									<p-column header="Vertical" class="column-align-center">
										<template #body="row">
											<vertical-icon :vertical-id="row.data.vertical_id_targeting" size="30px" />
										</template>
									</p-column>
									<p-column header="Purchases" class="column-align-right">
										<template #body="row">
											{{ row.data['purchases_' + campaignPeriod] || 0 }}
											<div
												:class="[
													'sub-value',
													trendClass(
														row.data['purchases_' + campaignPeriod],
														row.data['purchases_' + campaignPeriodCompare]
													),
												]"
											>
												<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
												{{ row.data['purchases_' + campaignPeriodCompare] }}
											</div>
										</template>
									</p-column>
									<p-column header="Spend" class="column-align-right">
										<template #body="row">
											{{ currency(row.data['spend_' + campaignPeriod] || 0) }}
											<div
												:class="[
													'sub-value',
													trendClass(row.data['spend_' + campaignPeriod], row.data['spend_' + campaignPeriodCompare]),
												]"
											>
												<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
												{{ currency(row.data['spend_' + campaignPeriodCompare]) }}
											</div>
										</template>
									</p-column>
									<p-column header="CPL" class="column-align-right">
										<template #body="row">
											{{ currency(row.data['spend_' + campaignPeriod] / row.data['purchases_' + campaignPeriod] || 0) }}
											<div
												:class="[
													'sub-value',
													trendClass(
														row.data['spend_' + campaignPeriod] / row.data['purchases_' + campaignPeriod] || 0,
														row.data['spend_' + campaignPeriodCompare] /
															row.data['purchases_' + campaignPeriodCompare] || 0
													),
												]"
											>
												<icon type="calendar-arrow-left" style="vertical-align: text-top; margin-top: 1px" />
												{{
													currency(
														row.data['spend_' + campaignPeriodCompare] /
															row.data['purchases_' + campaignPeriodCompare] || 0
													)
												}}
											</div>
										</template>
									</p-column>
								</p-data-table>
							</div>

							<gutter size="20px" />

							<RecentAMPhoneActivity :account_id="accountId" />
						</column>
					</row>
				</column>
				<gutter size="20px" />
				<column>
					<div v-if="account" id="account-details" class="card padded">
						<div class="card-heading unpadded padded flex align-items-center">
							<icon type="information" class="mr-2" size="18px" />
							<h2>Account Details</h2>
						</div>
						<div class="line-item">
							<div class="label">Account ID:</div>
							<div class="value">{{ account.id }}</div>
						</div>
						<div class="line-item">
							<div class="label">Account Email:</div>
							<div class="value">{{ account.contact.email }}</div>
						</div>
						<div class="line-item">
							<div class="label">Primary User:</div>
							<div class="value">{{ account.primary_user.first_name }} {{ account.primary_user.last_name }}</div>
							<div class="sub-value">{{ account.primary_user.email }}</div>
							<div class="sub-value">{{ phoneFormat(account.primary_user.phone) }}</div>
						</div>
						<div v-if="account.support.account_manager.first_name" class="line-item">
							<div class="label">Account Manager:</div>
							<div class="value">
								{{ account.support.account_manager.first_name }} {{ account.support.account_manager.last_name }}
							</div>
						</div>
						<div class="line-item">
							<div class="label">Account Balance:</div>
							<div class="value">{{ currency(account.balance.actual) }}</div>
						</div>
						<div class="line-item">
							<div class="label">Customer Since:</div>
							<div class="value">{{ formatDate(new Date(account.created_at), 'MMM D, YYYY') }}</div>
						</div>
						<div v-if="account.status" class="line-item">
							<div class="label">Status:</div>
							<div class="value">
								{{ capitalize(account.status) }}
								<a @click.prevent="updating_status = !updating_status">
									<icon v-tooltip.top="'Edit'" type="square-edit-outline" size="16px" />
								</a>
							</div>
							<div class="label" v-if="account.status === 'away' && account.support.date_of_return">
								Until: {{ formatDate(new Date(account.support.date_of_return), 'MMM D, YYYY') }}
							</div>
						</div>
						<UpdateAccountStatus
							v-if="account.id && updating_status && sessionStore.isAdminUser"
							:account_id="account.id"
							:status="account.status"
							:date_of_return="account.support.date_of_return"
							@update="updateStatus"
						/>
						<div class="line-item">
							<div class="label">Disposition:</div>
							<div class="value">
								{{ account.support.disposition || 'None' }}
								<a @click="this.$refs.disposition_note_modal.openModal()">
									<icon v-tooltip.top="'Edit Disposition'" type="square-edit-outline" size="16px" />
								</a>
								<!--								<DispositionNoteModal-->
								<!--									ref="disposition_note_modal"-->
								<!--									@refresh="handleDispositionRefresh"-->
								<!--									:account_id="accountId"-->
								<!--									:edit_disposition="true"-->
								<!--								/>-->
								<ActionModal
									ref="disposition_note_modal"
									note_type="dispo_change"
									@refresh="handleDispositionRefresh"
									:account_id="account.id"
									:account_name="account.name"
									:account_support_status="account.support.status || ''"
								/>
							</div>
						</div>
						<PendingFollowUpComponent
							:account_id="accountId"
							@refreshDisposition="
								(disposition) => {
									this.account.support.disposition = disposition;
								}
							"
						/>
					</div>
					<gutter size="20px" />
					<column>
						<div id="purchase-details" class="card padded">
							<div class="card-heading unpadded padded flex align-items-center">
								<icon type="store-clock-outline" size="1.25rem" />
								<h2>Last Purchase</h2>
							</div>
							<div v-if="account.lastPurchase" class="line-item">
								<div class="value">{{ capitalize(timeAgo(account.lastPurchase)) }}</div>
							</div>
							<div v-else>
								<div class="value">No Purchases</div>
							</div>
						</div>
					</column>
					<gutter size="20px" />
					<NoteList ref="note_list" :account_id="accountId" />
				</column>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { cloneDeep, get, groupBy, mapValues, merge, round, sumBy } from 'lodash-es';
import { capitalize, currency, formatDate, displayLabel, phoneFormat, timeAgo } from '@/lib/Filters';
import { mapStores } from 'pinia';
import { reportAccountVelocity } from '@GQL';
import PendingFollowUpComponent from '@/views/FollowUps/List/PendingFollowUp.vue';
import { useSessionStore } from '@/stores/session';
import dateRange from '@/components/forms/DateRange.vue';
import NoteList from '@/views/Notes/List/Component.vue';
import pChart from 'primevue/chart';
import quickFilter from '@/components/widgets/QuickFilter.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import trendClass from '@/lib/Utils/trendClass';
import UpdateAccountStatus from './UpdateAccountStatus.vue';
import DispositionNoteModal from '@/views/Notes/Record/Modal.vue';
import RecentAMPhoneActivity from '@/views/AccountManagerPhoneLogs/List/ListComponent.vue';
import ActionModal from '@/views/AccountManagerWorkstation/ActionModal.vue';

export default {
	name: 'AdminAccountOverview',
	components: {
		ActionModal,
		dateRange,
		pChart,
		NoteList,
		quickFilter,
		verticalIcon,
		UpdateAccountStatus,
		PendingFollowUpComponent,
		DispositionNoteModal,
		RecentAMPhoneActivity,
	},
	data() {
		return {
			loading: false,
			filters: {
				verticals: [],
				products: [],
			},
			metric_type: 'dod',
			follow_ups: [],
			account: {
				id: null,
				balance: {
					actual: 0,
				},
				contact: {
					email: '',
					phone: '',
				},
				support: {
					disposition: '',
					account_manager: {
						first_name: '',
						last_name: '',
					},
					date_of_return: new Date(),
				},
				lastPurchase: null,
				primary_user: {
					first_name: null,
					last_name: null,
					phone: null,
				},
				created_at: new Date(),
				status: '',
			},
			updating_status: false,
			campaigns: [],
			report_data: {
				today: [],
				yesterday: [],
				this_week: [],
				last_week: [],
				this_month: [],
				last_month: [],
			},
			notifications: [],
		};
	},
	computed: {
		campaignPeriod() {
			if (this.metric_type === 'dod') {
				return 'today';
			}
			if (this.metric_type === 'wow') {
				return 'this_week';
			}
			return 'this_month';
		},
		campaignPeriodCompare() {
			if (this.metric_type === 'dod') {
				return 'yesterday';
			}
			if (this.metric_type === 'wow') {
				return 'last_week';
			}
			return 'last_month';
		},
		reportData() {
			const data = cloneDeep(this.report_data);

			// Filter out unselected options
			const filtered_data = mapValues(data, (value, key) => {
				const filtered_values = value.filter((row) => {
					if (this.filters.verticals.length > 0 && !this.filters.verticals.includes(row.vertical_id)) {
						return false;
					}
					if (this.filters.products.length > 0 && !this.filters.products.includes(row.product)) {
						return false;
					}
					return true;
				});

				// Group by product
				let product_groups = groupBy(filtered_values, 'product');
				return mapValues(product_groups, (values, product) => {
					return values.reduce(
						(a, b) => {
							return {
								volume: a.volume + b.volume,
								spend: a.spend + b.spend,
							};
						},
						{
							volume: 0,
							spend: 0,
						}
					);
				});
			});

			// Add totals to each period
			const data_with_totals = mapValues(filtered_data, (products, period) => {
				const total = {
					volume: 0,
					spend: 0,
				};
				mapValues(products, (product_stats, product) => {
					total.volume += product_stats.volume;
					total.spend += product_stats.spend;

					product_stats.cpl = 0;
					if (product_stats.volume > 0) {
						product_stats.cpl = round(product_stats.spend / product_stats.volume, 2);
					}

					return product_stats;
				});

				total.cpl = 0;
				if (total.volume > 0) {
					total.cpl = round(total.spend / total.volume, 2);
				}

				products.total = total;
				return products;
			});

			if (this.$refs.volume_chart) this.$refs.volume_chart.refresh();
			if (this.$refs.spend_chart) this.$refs.spend_chart.refresh();
			if (this.$refs.cpl_chart) this.$refs.cpl_chart.refresh();
			return data_with_totals;
		},
		campaignsWithPurchases() {
			return this.campaigns.filter((campaign) => {
				if (
					campaign['purchases_' + this.campaignPeriod] > 0 ||
					campaign['purchases_' + this.campaignPeriodCompare] > 0
				) {
					return true;
				}
				return false;
			});
		},
		accountId() {
			return this.$route.params.account_id;
		},
		...mapStores(useSessionStore),
	},
	async mounted() {
		await this.getAccountVelocity();
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		displayLabel,
		sumBy,
		trendClass,
		phoneFormat,
		timeAgo,
		async handleDispositionRefresh(data) {
			if ('disposition' in data) {
				this.account.support.disposition = data.disposition;
				// also refresh the notes
			}
			await this.$refs.note_list.loadNotes(true);
		},
		updateStatus(value) {
			this.account.status = value.status;
			if ('date_of_return' in value) {
				this.account.support.date_of_return = value.date_of_return;
			}
			this.updating_status = false;
		},
		currentMetric(metric) {
			if (this.metric_type === 'mom') {
				return get(this.reportData, `this_month.total.${metric}`, 0);
			} else if (this.metric_type === 'wow') {
				return get(this.reportData, `this_week.total.${metric}`, 0);
			} else {
				return get(this.reportData, `today.total.${metric}`, 0);
			}
		},
		previousMetric(metric) {
			if (this.metric_type === 'mom') {
				return get(this.reportData, `last_month.total.${metric}`, 0);
			} else if (this.metric_type === 'wow') {
				return get(this.reportData, `last_week.total.${metric}`, 0);
			} else {
				return get(this.reportData, `yesterday.total.${metric}`, 0);
			}
		},
		campaignViewLink(campaign_id) {
			return `/marketplaces/manage/${this.$route.params.mpid}/accounts/manage/${this.$route.params.account_id}/campaigns/${campaign_id}`;
		},
		chartData(metric) {
			const documentStyle = getComputedStyle(document.documentElement);
			let data = cloneDeep(this.reportData);

			const chart_data = {
				labels: ['MoM', 'WoW', 'DoD'],
				datasets: [
					{
						type: 'bar',
						label: 'Data Leads',
						backgroundColor: documentStyle.getPropertyValue('--green'),
						opacity: 0.5,
						data: [
							get(data, `last_month.data.${metric}`, 0),
							get(data, `last_week.data.${metric}`, 0),
							get(data, `yesterday.data.${metric}`, 0),
						],
						stack: 'previous',
						options: {
							plugins: {
								legend: false,
							},
						},
					},
					{
						type: 'bar',
						label: 'Calls',
						backgroundColor: documentStyle.getPropertyValue('--yellow'),
						opacity: 0.5,
						data: [
							get(data, `last_month.call.${metric}`, 0),
							get(data, `last_week.call.${metric}`, 0),
							get(data, `yesterday.call.${metric}`, 0),
						],
						stack: 'previous',
					},
					{
						type: 'bar',
						label: 'Live Transfers',
						backgroundColor: documentStyle.getPropertyValue('--purple'),
						opacity: 0.5,
						data: [
							get(data, `last_month.live_transfer.${metric}`, 0),
							get(data, `last_week.live_transfer.${metric}`, 0),
							get(data, `yesterday.live_transfer.${metric}`, 0),
						],
						stack: 'previous',
					},
					{
						type: 'bar',
						label: 'Ads',
						backgroundColor: documentStyle.getPropertyValue('--red'),
						opacity: 0.5,
						data: [
							get(data, `last_month.ad.${metric}`, 0),
							get(data, `last_week.ad.${metric}`, 0),
							get(data, `yesterday.ad.${metric}`, 0),
						],
						stack: 'previous',
					},
					{
						type: 'bar',
						label: 'Data Leads',
						backgroundColor: documentStyle.getPropertyValue('--green'),
						data: [
							get(data, `this_month.data.${metric}`, 0),
							get(data, `this_week.data.${metric}`, 0),
							get(data, `today.data.${metric}`, 0),
						],
						stack: 'current',
					},
					{
						type: 'bar',
						label: 'Calls',
						backgroundColor: documentStyle.getPropertyValue('--yellow'),
						data: [
							get(data, `this_month.call.${metric}`, 0),
							get(data, `this_week.call.${metric}`, 0),
							get(data, `today.call.${metric}`, 0),
						],
						stack: 'current',
					},
					{
						type: 'bar',
						label: 'Live Transfers',
						backgroundColor: documentStyle.getPropertyValue('--purple'),
						data: [
							get(data, `this_month.live_transfer.${metric}`, 0),
							get(data, `this_week.live_transfer.${metric}`, 0),
							get(data, `today.live_transfer.${metric}`, 0),
						],
						stack: 'current',
					},
					{
						type: 'bar',
						label: 'Ads',
						backgroundColor: documentStyle.getPropertyValue('--red'),
						data: [
							get(data, `this_month.ad.${metric}`, 0),
							get(data, `this_week.ad.${metric}`, 0),
							get(data, `today.ad.${metric}`, 0),
						],
						stack: 'current',
					},
				],
			};
			return chart_data;
		},
		chartOptions(is_currency = false) {
			const options = {
				plugins: {
					legend: {
						display: false,
					},
				},
				x: {
					stacked: true,
				},
				y: {
					stacked: true,
				},
			};

			if (is_currency) {
				merge(options, {
					plugins: {
						tooltip: {
							callbacks: {
								label(context) {
									return `${context.dataset.label}: ${currency(context.dataset.data[context.dataIndex])}`;
								},
							},
						},
					},
					scales: {
						y: {
							ticks: {
								callback(value, index) {
									return currency(value);
								},
							},
						},
					},
				});
			}

			return options;
		},
		async getAccountVelocity() {
			this.loading = true;
			try {
				const results = await reportAccountVelocity({
					account_id: this.accountId,
				});
				this.report_data = results.reportAccountVelocity;
				this.account = results.account;
				this.campaigns = results.reportAccountVelocity.campaigns;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get account velocity data',
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
		clearQuery() {
			this.loading = false;
		},
	},
};
</script>

<style scoped lang="less">
#account-details,
#purchase-details {
	.line-item {
		line-height: 1.35em;
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}

		.label {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
		}

		.value {
			font-weight: bold;
		}
	}
}

.metrics-link {
	margin-top: 10px;
}

:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

.card h2 {
	font-size: 1.25rem;
}

.chart-heading {
	gap: 20px;

	h2 {
		margin: 0;
	}
}

.product-label {
	font-size: 1.125rem;
}

.velocity-charts {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
}

.campaign-quick-filters {
	font-size: 0.75em;
}
</style>
