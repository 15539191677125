// --------------------------------------------------
// FILTER: chDate
// Fix a date returned from ClickHouse
// --------------------------------------------------

export function chDate(date: string) {
	if (!date || date === '1970-01-01 00:00:00.000000') {
		return null;
	}
	const date_parts = date.split(' ');
	return new Date(date_parts[0] + 'T' + date_parts[1] + 'Z');
}
