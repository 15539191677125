<template>
	<div class="build-step reply-with-auction-result">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Reply with Auction Result</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">Return the auction result with a list of winners and details about each campaign.</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ReplyWithAuctionResult',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
