import { $GQL } from '@GQL/index';
import { type JobConfig } from '@nextgenleads/job-driver';

export async function getJobConfigById(
	id: string
): Promise<Omit<JobConfig, 'created_at' | 'modified_by' | 'created_by'>> {
	const query = `
        query GetJobConfigById($id: ID!) {
            jobConfig(id: $id) {
                id
                name
				description
                schedule
                k8s_job_name
                active_deadline
                script_name
                script_type
                namespace
                parameters
                status
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.jobConfig;
}
