<template>
	<div id="notification-list" class="view-content">
		<div class="page-heading">
			<h1>Notification List</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="flex justify-content-between">
			<p-button icon="pi pi-plus" label="Create Notification" @click="$router.push(`/notifications/new`)" />
			<show-archived v-model="show_archived" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div id="notifications-table">
				<p-data-table
					ref="dt"
					:rows="10"
					scrollable
					:value="show_archived ? notifications : activeNotifications"
					:loading="loading"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="created_at" header="Created" sortable sortField="created_at">
						<template #body="notification">{{
							formatDate(notification.data.created_at, 'ddd, MMM D, YYYY h:mm A')
						}}</template>
					</p-column>
					<p-column field="message" header="Message" sortable sortField="message">
						<template #body="notification">
							<div class="sub-value">
								{{ notification.data.message }}
							</div>
						</template>
					</p-column>
					<p-column field="filters" header="Recipients (Filter)">
						<template #body="notification"
							>{{ title(notification.data.filters.target_type) }}: {{ notification.data.filters.value }}</template
						>
					</p-column>
					<p-column field="type" header="Type">
						<template #body="notification">{{
							notification.data.type
								.map((item) => {
									return title(item);
								})
								.join(', ')
						}}</template>
					</p-column>
					<p-column field="status" header="Status">
						<template #body="notification">{{ title(notification.data.status) }}</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="row">
							<div class="toolset">
								<div class="tool">
									<p-button
										v-tooltip.top="'Edit'"
										aria-label="Edit"
										@click="$router.push({ path: `/notifications/edit/${row.data.id}` })"
									>
										<template #icon>
											<icon type="square-edit-outline" size="20px" />
										</template>
									</p-button>
								</div>
								<div class="tool">
									<p-button
										v-if="row.data.status !== 'archived'"
										v-tooltip.top="'Archive Notification'"
										aria-label="Archive"
										@click="handleArchiveNotification(row.data.id)"
									>
										<template #icon>
											<icon type="archive" size="20px" />
										</template>
									</p-button>
									<p-button
										v-else
										v-tooltip.top="'Activate'"
										aria-label="Activate"
										@click="handleActivateNotification(row.data.id)"
									>
										<template #icon>
											<icon type="archive-off" size="20px" />
										</template>
									</p-button>
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
				<p-paginator :rows="query.pagination.page_size" :total-records="total_records" @page="handlePageChange" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import showArchived from '@/components/widgets/ShowArchived.vue';
import { title, formatDate, capitalize } from '@/lib/Filters';
import { getNotificationList, updateNotification } from '@GQL';
import type { GetListParams } from '@nextgenleads/db';
import log from '@/lib/Log';

export default {
	name: 'NotificationList',
	components: {
		showArchived,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Notification List' }],
			notifications: [],
			query: {
				filters: [],
				pagination: {
					page: 0,
					page_size: 10,
				},
				order: {
					field: 'created_at',
					desc: true,
				},
			},
			total_records: 0,
			loading: false,
			show_archived: false,
		};
	},
	async mounted() {
		return await this.getList();
	},
	computed: {
		activeNotifications() {
			return this.notifications.filter((notification) => notification.status !== 'archived');
		},
	},
	methods: {
		capitalize,
		title,
		formatDate,
		handlePageChange(data) {
			this.query.pagination.page = data.page;
			return this.getList();
		},
		async getList() {
			this.loading = true;
			const list_params: GetListParams = {
				...this.query,
				filters: [...this.query.filters],
			};

			try {
				const result = await getNotificationList(list_params, true);
				this.total_records = result.row_count;
				this.notifications = result.rows;
			} catch (e) {
				log.trace('Notifications getList', e);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get list',
				});
			} finally {
				this.loading = false;
			}
		},
		handleActivateNotification(id) {
			try {
				updateNotification(id, {
					status: 'active',
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully activated notification',
				});
				// Update UI
				this.notifications.find((notification) => notification.id === id).status = 'active';
			} catch (e) {
				log.trace('Error Updating Notification', e);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to activate notification',
				});
			}
		},
		handleArchiveNotification(id) {
			try {
				updateNotification(id, {
					status: 'archived',
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully archived notification',
				});
				// Update UI
				this.notifications.find((notification) => notification.id === id).status = 'archived';
			} catch (e) {
				log.trace('Error Updating Notification', e);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to archive notification',
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.sub-value {
	max-width: 60ch;
	text-wrap: wrap;
}
</style>
