import { $GQL } from '@GQL/index';
import type { Marketplace } from '@nextgenleads/marketplace-driver';

export async function updateMarketplace(id: string, data: Partial<Marketplace>) {
	const mutation = `
        mutation UpdateMarketplace($id: ID!, $data: JSONObject!) {
            updateMarketplace(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateMarketplace;
}
