<template>
	<div class="shipper-settings">
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Options:</label>
				<div class="controls">
					<div class="field">
						<code-editor v-model.parse="shipperSettings.options" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ShipperSettingsGohighlevel',
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					options: {},
				};
			},
		},
	},
	computed: {
		shipperSettings: {
			get() {
				if (!this.modelValue.options) {
					this.modelValue.options = {};
				}

				if (!this.modelValue.options.api_key) {
					this.modelValue.options.api_key = '';
				}

				if (!this.modelValue.options.campaign_id) {
					this.modelValue.options.campaign_id = '';
				}

				if (!this.modelValue.options.source) {
					this.modelValue.options.source = '';
				}

				return this.modelValue;
			},
			set(new_value) {
				this.$emit('update:modelValue', new_value);
			},
		},
	},
};
</script>

<style lang="less"></style>
