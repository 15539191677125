import { $GQL } from '@GQL';
import type { DepositParams, DepositRes } from '@nextgenleads/banker-driver';

export async function depositFunds(data: DepositParams): Promise<DepositRes> {
	const mutation = `
        mutation DepositFunds($data: JSONObject!){
          depositFunds(data: $data) {
            txn_id
            current_balance
          }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.depositFunds;
}
