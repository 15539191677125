import { $GQL } from '@GQL/index';
import { type Account } from '@nextgenleads/marketplace-driver';

export async function getAccountSupportSettings(account_id: string, refresh?: boolean): Promise<Partial<Account>> {
	const request = `
			query GetAccountSupportSettings($account_id: ID!) {
				account(id: $account_id) {
					support {
						account_manager_id
						status
						category
						funding_action
						first_fund_manager_id
						first_fund_date
					}
					settings {
						ngl_v3_id
					}
				}
			}
		`;

	const no_cache = refresh ? ['account'] : undefined;
	return await $GQL.request(request, { account_id, no_cache });
}
