import MyProfile from '@/views/Settings/MyProfile.vue';
import AccountSettings from '@/views/Settings/Account/Page.vue';
import MarketplaceSettings from '@/views/Settings/Marketplace/Page.vue';
import GlobalSettings from '@/views/Settings/Global/Page.vue';

export default [
	{
		path: 'settings/my-profile',
		name: 'MyProfile',
		component: MyProfile,
		meta: {
			profile: true,
			title: 'My Profile',
			permissions: 'settings.profile',
		},
	},
	{
		path: 'settings/account',
		name: 'AccountSettings',
		component: AccountSettings,
		meta: {
			title: 'Account Settings',
			permissions: 'settings.account',
		},
	},
	{
		path: 'settings/marketplace',
		name: 'MarketplaceSettings',
		component: MarketplaceSettings,
		meta: {
			title: 'Marketplace Settings',
			permissions: 'settings.marketplace',
		},
	},
	{
		path: 'settings',
		name: 'GlobalSettings',
		component: GlobalSettings,
		meta: {
			title: 'Global Settings',
			permissions: 'settings',
		},
	},
];
