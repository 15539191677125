<template>
	<div class="view-content">
		<line-loader :show="loading" />
		<p-button type="button" label="New Account" icon="pi pi-plus" @click.prevent="show_new_account_modal = true" />
		<p-dialog
			v-model:visible="show_new_account_modal"
			class="new-account-modal"
			:modal="true"
			:dismissable-mask="true"
			:pt="{
				root: { class: 'w-12 sm:w-9 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<strong>Create New Account</strong>
				</div>
			</template>
			<p>Select the MPID for this new account:</p>
			<div class="flex flex-column">
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Marketplace:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="selected_mpid"
									:options="accessibleMarketplaces"
									:option-label="(option) => `${option.label} - ${option.value}`"
									option-value="value"
									placeholder="Select MPID"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="flex justify-content-between w-full">
					<p-button text label="Cancel" @click="show_new_account_modal = false" />
					<p-button label="Continue Creating Account" @click="goToAccountCreate" />
				</div>
			</template>
		</p-dialog>
		<gutter size="20px" />
		<ResolveFollowUpModal
			ref="resolver_modal"
			:follow_up_id="selected_follow_up"
			:account_id="selected_account_id"
			@refresh="handleRefresh"
		/>
		<p-dialog
			v-model:visible="show_call_script_modal"
			class="call-script-modal"
			:modal="true"
			:dismissable-mask="true"
			:pt="{
				root: { class: 'w-12 sm:w-9 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<strong>Call Scripts</strong>
				</div>
			</template>
			<div class="flex flex-column script">
				<p-fieldset legend="VTV Script">
					<div v-html="callScripts.vtv_script" />
				</p-fieldset>

				<p-fieldset legend="Voice mail Script" v-if="callScripts.lvm_script.length > 0">
					<div v-html="callScripts.lvm_script" />
				</p-fieldset>

				<p-fieldset legend="Additional Info" v-if="callScripts.additional_info.length > 0">
					<div v-html="callScripts.additional_info" />
				</p-fieldset>
			</div>
		</p-dialog>
		<div class="card padded">
			<h4>Pending / Completed Follow Ups this week</h4>
			<div>
				<p-data-table :value="metrics">
					<p-column v-for="metric of metric_headers" class="align-center" :header="metric.header">
						<template #body="row">
							{{ row.data[metric.due_at].pending }} /
							{{ row.data[metric.due_at].complete + row.data[metric.due_at].pending }}
						</template>
					</p-column>
				</p-data-table>
			</div>
			<gutter size="35px" />

			<h2>Pending Follow Ups</h2>

			<query-form v-model:query="query" :loading="loading" @apply="loadFollowUps" @abort="clearQuery">
				<template #display>
					<div class="display-value">
						<div class="label">Latest Due Date:</div>
						<div class="value">{{ formatDate([query.due_at], 'ddd, MMM D, YYYY') }}</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-if="follow_ups.length > 0"
						v-tooltip.top="'Download'"
						icon="pi pi-download"
						aria-label="Download"
						class="mr-2"
						@click="exportCSV"
					/>
				</template>
				<template #form="form">
					<row>
						<div class="control-group" :style="{ width: '300px' }">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<p-calendar v-model="form.query.due_at" date-format="D, M d, yy" :manual-input="false" />
									</div>
								</div>
							</div>
						</div>
					</row>
				</template>
			</query-form>

			<p-data-table
				ref="follow_ups_table"
				scrollable
				data-key="account_id"
				:loading="loading"
				:rows="100"
				:value="follow_ups"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column field="due_at" header="Due Date" sortable sortField="due_at">
					<template #body="row">
						{{ formatDate(row.data.due_at, 'M/D/YYYY') }}
					</template>
				</p-column>
				<p-column field="vertical_id" header="Vertical" sortable sortField="vertical_id" class="column-align-center">
					<template #body="row">
						<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
					</template>
				</p-column>
				<p-column field="name" header="Account" sortable sortField="name">
					<template #body="row">
						<router-link
							:to="`/marketplaces/manage/${row.data.account_id.slice(0, 3)}/accounts/manage/${row.data.account_id}/reports/account-activity`"
						>
							{{ row.data.name }}
						</router-link>
						<div class="sub-value">{{ row.data.account_id }}</div>
					</template>
				</p-column>
				<p-column field="contact" header="Contact">
					<template #body="row">
						{{ row.data.client_first_name }} {{ row.data.client_last_name }}
						<div class="sub-value">{{ row.data.client_email }}</div>
						<div class="sub-value">{{ phoneFormat(row.data.client_phone_number) }}</div>
					</template>
				</p-column>
				<p-column field="sign_up_source" header="Sign Up Info" sortable sortField="sign_up_source">
					<template #body="row">
						<div @click="openCallScriptModal(row.data.sign_up_source, row.data.id)" class="clickable">
							{{ row.data.sign_up_source || 'Missing Source' }}
							{{ row.data.sign_up_sub_id ? `- ${row.data.sign_up_source}` : '' }}
							<div class="sub-value">
								{{ formatDate(row.data.sign_up_date, 'M/D/YYYY') }}
							</div>
						</div>
					</template>
				</p-column>
				<p-column field="last_activity" header="Last Activity" sortable sortField="last_activity">
					<template #body="row">
						{{ formatDate(row.data.last_activity, 'M/D/YYYY') }}
						<div v-if="row.data.last_activity" class="sub-value">
							{{ timeAgo(row.data.last_activity) }}
						</div>
					</template>
				</p-column>
				<p-column field="disposition" header="Disposition" sortable sortField="disposition">
					<template #body="row">
						{{ row.data.disposition }}
					</template>
				</p-column>
				<p-column field="call_count" header="Activity Count">
					<template #body="row"> {{ row.data.call_count }} Calls / {{ row.data.email_count }} Emails</template>
				</p-column>
				<p-column header="Tools" class="align-center s">
					<template #body="row">
						<div class="toolset">
							<div class="tool">
								<p-button v-tooltip.top="'Resolve Follow Up'" aria-label="Resolve" @click="openResolveModal(row.data)">
									<template #icon>
										<icon type="square-edit-outline" size="16px" />
									</template>
								</p-button>
							</div>
						</div>
					</template>
				</p-column>
			</p-data-table>
			<p-data-table
				ref="downloadtable"
				class="hidden"
				:value="follow_ups"
				:exportFilename="`${manager_name}-pending-follow-ups`"
			>
				<!-- Hidden Table for CSV Export -->
				<p-column field="due_at" header="Due Date" />
				<p-column field="account_id" header="Account ID" />
				<p-column field="name" header="Account Name" />
				<p-column field="vertical_id" header="Vertical" />
				<p-column field="client_first_name" header="Client First Name" />
				<p-column field="client_last_name" header="Client Last Name" />
				<p-column field="client_email" header="Client Email" />
				<p-column field="client_phone_number" header="Client Phone Number" />
				<p-column field="sign_up_source" header="Sign Up Source" />
				<p-column field="sign_up_sub_id" header="Sign Up Sub ID" />
				<p-column field="sign_up_date" header="Sign Up Date" />
				<p-column field="last_activity" header="Last Activity" />
				<p-column field="disposition" header="Disposition" />
				<p-column field="call_count" header="Call Count" />
				<p-column field="email_count" header="Email Count" />
			</p-data-table>
		</div>
	</div>
</template>

<script lang="ts">
import { reportFollowUpsForAccountManager, getOfferById, getSettings, getMarketplacesAsOptions, getUser } from '@GQL';
import { formatDate, currency, phoneFormat, timeAgo } from '@/lib/Filters';
import ResolveFollowUpModal from '@/views/FollowUps/Record/ResolveFollowUpModal.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { endOfWeek, startOfWeek, addDays } from 'date-fns';
import QueryForm from '@/components/widgets/QueryForm.vue';
import pCalendar from 'primevue/calendar';
import { mapper } from '@/lib/Utils/mapper';
import { find } from 'lodash-es';

export default {
	name: 'PendingFollowUpsPage',
	components: {
		ResolveFollowUpModal,
		QueryForm,
		pCalendar,
		verticalIcon,
	},
	data() {
		return {
			show_new_account_modal: false,
			show_call_script_modal: false,
			follow_ups: [],
			loading: false,
			row_count: 0,
			metrics: [],
			metric_headers: [],
			selected_account_id: '',
			selected_follow_up: '',
			manager_name: '',
			query: {
				due_at: new Date(),
			},
			signup_source_call_scripts: {},
			script_used: '',
			script_merge_data: {},
			mpid_options: [],
			am_id_options: [],
			selected_mpid: 'NGL',
			selected_am_id: this.$route.params.account_manager_id,
		};
	},
	computed: {
		accountManagerId() {
			if ('account_manager_id' in this.$route.params) {
				return this.$route.params.account_manager_id;
			} else {
				return this.$root.sessionStore.user.id;
			}
		},
		callScripts() {
			let scripts = this.signup_source_call_scripts['_default'];
			if (this.script_used in this.signup_source_call_scripts) {
				scripts = this.signup_source_call_scripts[this.script_used];
			}

			// now we use mapper on all the scripts
			const mapped = {};
			Object.keys(scripts).forEach((key) => {
				mapped[key] = mapper.compile(scripts[key], this.script_merge_data);
			});
			return mapped;
		},
		accessibleMarketplaces() {
			const filtered_marketplaces = this.mpid_options.filter((marketplace) => {
				return ['super_admin', 'mp_admin', 'mp_manager', 'account_manager', 'sales_associate'].includes(
					this.$root.sessionStore.user.role_id[marketplace.value]
				);
			});
			return filtered_marketplaces;
		},
	},
	async mounted() {
		await this.loadFollowUps();
		await this.loadCallScripts();
		this.mpid_options = await getMarketplacesAsOptions();
		const user = await getUser(this.accountManagerId);
		this.manager_name = user.first_name + '-' + user.last_name;
	},
	methods: {
		formatDate,
		currency,
		timeAgo,
		phoneFormat,
		clearQuery() {
			this.loading = false;
		},
		async openCallScriptModal(source, follow_up_id) {
			this.loading = true;
			try {
				this.script_used = source;
				const fu = find(this.follow_ups, { id: follow_up_id });
				if (fu) {
					this.script_merge_data = {
						client_first_name: fu.client_first_name,
						client_last_name: fu.client_last_name,
						account_manager_first_name: this.$root.sessionStore.user.first_name,
						account_manager_phone: phoneFormat(this.$root.sessionStore.user.phone),
					};

					if (fu.offer_id && fu.offer_id !== '') {
						const offer = await getOfferById(fu.offer_id);
						if (offer) {
							this.script_merge_data.offer_credit_type = offer.credit_type;
							this.script_merge_data.offer_credit_amount = offer.credit_amount;
							this.script_merge_data.offer_required_funding = offer.required_funding;
							this.script_merge_data.offer_trigger = offer.trigger;
						}
					}
				} else {
					this.$toast.add({
						severity: 'warn',
						summary: 'Unable to map the call script data',
						life: 4000,
					});
				}
				this.show_call_script_modal = true;
			} finally {
				this.loading = false;
			}
		},
		async handleRefresh() {
			await this.loadFollowUps();
		},
		async loadCallScripts() {
			try {
				const settings_result = await getSettings(['signup_source_call_scripts'], '$NG', true);
				this.signup_source_call_scripts = settings_result.settings.signup_source_call_scripts;
			} catch (err) {}
		},
		openResolveModal(item) {
			this.selected_account_id = item.account_id;
			this.selected_follow_up = item.id;
			this.$refs.resolver_modal.openModal();
		},
		async loadFollowUps() {
			try {
				this.loading = true;
				const result = await reportFollowUpsForAccountManager({
					...this.query,
					account_manager_id: this.accountManagerId,
				});
				this.follow_ups = result.rows.map((row) => {
					row.due_at = new Date(row.due_at);
					row.sign_up_date = new Date(row.sign_up_date);
					return row;
				});

				let iterator = startOfWeek(new Date());
				const metrics = {};
				while (iterator <= endOfWeek(new Date())) {
					const key = iterator.toISOString().slice(0, 10);
					metrics[key] = {
						header: this.formatDate(new Date(iterator), 'ddd MMM D YYYY'),
						due_at: key,
						pending: 0,
						complete: 0,
					};
					iterator = addDays(iterator, 1);
				}

				result.metrics.data.forEach((row) => {
					metrics[row.due_at.slice(0, 10)][row.status] = row.count;
				});
				this.metric_headers = Object.values(metrics);
				this.metrics = [metrics];
				// // make metrics into a table with all the data
				this.row_count = result.row_count;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'An error occurred loading the pending follow ups',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		goToAccountCreate() {
			this.$router.push({
				path: `/marketplaces/manage/${this.selected_mpid || 'NGL'}/accounts/new`,
			});
		},
		exportCSV() {
			this.$refs.downloadtable.exportCSV();
		},
	},
};
</script>

<style lang="less" scoped>
.clickable {
	&:hover {
		cursor: pointer;
	}
}
</style>
