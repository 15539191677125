<template>
	<component :is="pageComponent" />
</template>

<script>
import AdminPage from './AdminPage.vue';
import AccountPage from './AccountPage.vue';

export default {
	name: 'AccountOverviewWrapper',
	components: {
		AdminPage,
		AccountPage,
	},
	computed: {
		pageComponent() {
			if (this.$root.appStore.isAdminApp) {
				return 'AdminPage';
			}
			return 'AccountPage';
		},
	},
};
</script>
