import { $GQL } from '@GQL/index';
import type { User } from '@nextgenleads/auth-driver';
import type { QueryFilters } from '@nextgenleads/db';

export async function getUsersList(filters?: QueryFilters, no_cache?: string[], segments?: string[]): Promise<User[]> {
	const query = `
        query Users($filters: [[Any]]) {
            users(filters: $filters) {
              id
              first_name
              last_name
              email
              title
              role_id
              status
            }
        }
    `;
	const result = await $GQL.request(query, { filters, no_cache, segments });
	return result.users;
}
