import { $GQL } from '@GQL/index';

export async function getAd(id: String) {
	const query = `
        query GetAd($id: ID!) {
            ad(id: $id) {
                id
				account_id
				name
				vertical_id
				template_id
				template {
					id
					name
				}
				type
				data
				assigned_campaigns {
					id
					name
				}
				approval_status
				rejected_reason
				approved_by
				approver {
					id
					first_name
					last_name
				}
				status
				created_at
				modified_at
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.ad;
}
