<template>
	<div v-if="verticals !== null" class="settings-form">
		<h2>Verticals Settings</h2>
		<template v-if="mpid !== '$NG'">
			<p-message v-if="!this.localSettingsList.includes('verticals')" :closable="false" severity="warn"
				>These settings are controlled at the global level. To override the global settings for this marketplace only,
				<strong><a href="javascript:void(0)" @click="localizeSetting('verticals', mpid)">click here</a></strong
				>.</p-message
			>
			<p-message :closable="false" v-else>
				The vertical settings for this marketplace are currently overriding the global settings. To remove the
				marketplace overrides, <strong><a href="javascript:void(0)" @click="deleteLocalSetting()">click here</a></strong
				>.
			</p-message>
		</template>
		<gutter size="10px" />
		<p-block-ui :blocked="mpid !== '$NG' && !this.localSettingsList.includes('verticals')">
			<template v-for="vertical in options.verticals" :key="vertical.value">
				<div class="vertical">
					<div :class="['vertical-heading', { enabled: verticals[vertical.value].enabled }]">
						<strong>Enable {{ vertical.label }} Campaigns</strong>
						<p-input-switch v-model="verticals[vertical.value].enabled" />
					</div>
					<div v-if="verticals[vertical.value].enabled" class="vertical-content">
						<template v-for="product in product_types" :key="product.value">
							<div class="product-type">
								<div
									class="product-heading flex align-items-center justify-content-between"
									:class="{ enabled: verticals[vertical.value][product.value].enabled }"
								>
									<strong>{{ product.label }}</strong>
									<p-input-switch v-model="verticals[vertical.value][product.value].enabled" />
								</div>
								<div v-if="verticals[vertical.value][product.value].enabled" class="product-content">
									<template v-for="bid_type in product.bid_types" :key="bid_type.value">
										<div class="bid-type">
											<div
												class="bid-type-heading flex align-items-center justify-content-between"
												:class="{ enabled: verticals[vertical.value][product.value][bid_type.value].enabled }"
											>
												<strong>{{ bid_type.label }} Bids</strong>
												<p-input-switch v-model="verticals[vertical.value][product.value][bid_type.value].enabled" />
											</div>
											<div
												v-if="verticals[vertical.value][product.value][bid_type.value].enabled"
												class="bid-type-content"
											>
												<div class="control-group">
													<div class="inner">
														<div class="controls">
															<div class="field">
																<div><strong>Minimum Bid</strong></div>
																<div class="sub-value">
																	The minimum amount an {{ bid_type.label.toLowerCase() }} campaign can bid
																</div>
															</div>
															<div class="field xs">
																<p-input-currency
																	v-model="verticals[vertical.value][product.value][bid_type.value].minimum_bid"
																/>
															</div>
														</div>
													</div>
												</div>
												<div class="control-group">
													<div class="inner">
														<div class="controls">
															<div class="field">
																<div><strong>Max Winners</strong></div>
																<div class="sub-value">
																	The max number of {{ bid_type.label.toLowerCase() }} campaigns that can win
																</div>
															</div>
															<div class="field xs">
																<p-input-number
																	v-model="verticals[vertical.value][product.value][bid_type.value].max_winners"
																	show-buttons
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</template>
								</div>
							</div>
						</template>
					</div>
				</div>
			</template>
			<div class="actions">
				<p-button label="Save" icon="pi pi-check" @click="updateSetting()" />
				<gutter size="20px" />
				<p-button label="Reset" text @click="resetSetting()" />
			</div>
		</p-block-ui>
	</div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import pPanel from 'primevue/panel';
import { getSettings, updateSetting, localizeSetting, deleteLocalSetting, getLocalOverrideSettingsList } from '@GQL';
import { title } from '@/lib/Filters';
import { useAppStore } from '@/stores/app';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapState, mapStores } from 'pinia';
import pBlockUi from 'primevue/blockui';

export default {
	name: 'VerticalsSettings',
	components: {
		pBlockUi,
		pPanel,
	},
	props: {
		localSettingsList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	emits: ['refresh'],
	data() {
		return {
			verticals: null,
			defaults: null,
			product_types: [
				{
					label: 'Data Leads',
					value: 'data',
					bid_types: [
						{ label: 'Exclusive', value: 'exclusive' },
						{ label: 'Shared', value: 'shared' },
					],
				},
				{
					label: 'Calls',
					value: 'call',
					bid_types: [{ label: 'Exclusive', value: 'exclusive' }],
				},
				{
					label: 'Live Transfers',
					value: 'live_transfer',
					bid_types: [{ label: 'Exclusive', value: 'exclusive' }],
				},
				{
					label: 'Ads',
					value: 'ad',
					bid_types: [
						{ label: 'Exclusive', value: 'exclusive' },
						{ label: 'Shared', value: 'shared' },
					],
				},
			],
			overrides: [],
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		...mapStores(useMarketplaceStore),
		...mapState(useAppStore, ['options']),
	},
	methods: {
		title,
		async localizeSetting() {
			const setting = await localizeSetting('verticals', this.mpid);
			this.verticals = setting.data;
			this.$emit('refresh');
		},
		async deleteLocalSetting() {
			const setting = await deleteLocalSetting('verticals', this.mpid);
			if (setting.id) {
				await this.getSetting();
			}
			this.$emit('refresh');
		},
		async getSetting() {
			const settings_result = await getSettings(['verticals'], this.mpid);
			this.verticals = settings_result.settings.verticals;
			this.defaults = cloneDeep(this.verticals);
		},
		async updateSetting() {
			const settings_result = await updateSetting(
				'verticals',
				{
					data: this.verticals,
				},
				this.mpid
			);
			if (settings_result.id === 'verticals') {
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully saved vertical settings',
					life: 3000,
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
				});
			}
		},
		resetSetting() {
			this.verticals = cloneDeep(this.defaults);
		},
	},
	async mounted() {
		await this.getSetting();
		if (this.mpid == '$NG') {
			this.overrides = await getLocalOverrideSettingsList('navigation');
		}
	},
};
</script>

<style scoped lang="less">
:deep(.p-panel) {
	margin-bottom: 20px;
}

.vertical {
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	margin-bottom: 1rem;

	&:last-of-type {
		margin-bottom: 0;
	}
}

.vertical-heading,
.product-heading,
.bid-type-heading {
	align-items: center;
	display: flex;
	flex-direction: row;
	height: 50px;
	justify-content: space-between;
	padding: 0 20px;
}

.vertical-heading.enabled {
	background-color: var(--color-b-lightest);
}

.product-type {
	border-top: 1px solid var(--gray-10);

	&:first-of-type {
		border-top: 1px solid var(--gray-20);
	}
}

.bid-type {
	border-top: 1px solid var(--gray-10);
}

.product-heading {
	padding-left: 40px;
}
.bid-type-heading {
	padding-left: 60px;
}

.bid-type-content {
	border-top: 1px dotted var(--gray-20);
	padding: 20px;
	padding-left: 60px;
}
</style>
