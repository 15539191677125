import { $GQL } from '@GQL';

export async function logEvent(event_name: string, data: unknown): Promise<true> {
	const mutation = `
        mutation LogEvent($event_name: String!, $data: JSONObject!){
            logEvent(event_name: $event_name, data: $data)
        }
    `;

	const result = await $GQL.request(mutation, { event_name, data });
	return result.logEvent;
}
