<template>
	<p-card v-if="lead">
		<template #title>
			<div class="flex align-items-center">
				<icon type="web" size="24px" class="mr-2" />
				<span class="name">Meta Information</span>
			</div>
		</template>
		<template #content>
			<h3 class="heading">User Meta</h3>
			<ul class="table-list">
				<li>
					<div class="label">IP Address</div>
					<div v-if="purchase && purchase.status === 'return_accepted'" class="value">UNAVAILABLE</div>
					<div v-else class="value">
						{{ lead.meta.ip_address || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">User Agent</div>
					<div class="value small">
						{{ lead.meta.useragent || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">Device Type</div>
					<div class="value">
						{{ lead.meta.device || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">Device Brand</div>
					<div class="value">
						{{ lead.meta.device_brand || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">Browser</div>
					<div class="value">
						{{ lead.meta.browser || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">OS</div>
					<div class="value">
						{{ lead.meta.os || 'N/A' }}
					</div>
				</li>
			</ul>
			<template v-if="sessionStore.isAdminUser">
				<h3 class="heading">Split Test</h3>
				<ul class="table-list">
					<li>
						<div class="label">Info</div>
						<div class="value">
							{{ lead.meta.split_tests || 'N/A' }}
						</div>
					</li>
				</ul>
			</template>
		</template>
	</p-card>
</template>

<script lang="ts">
import { useSessionStore } from '@/stores/session';
export default {
	name: 'MetaPanel',
	props: {
		lead: {
			type: Object,
		},
		purchase: {
			type: Object,
		},
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
};
</script>

<style lang="less" scoped>
.small {
	font-size: 0.8rem;
}
</style>
