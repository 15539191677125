import { $GQL } from '@GQL/index';
import type { SourceThrottleConfig } from '@nextgenleads/source-driver';

export async function insertSourceThrottleConfig(
	data: Omit<SourceThrottleConfig, 'created_at'>
): Promise<{ id: string }> {
	const mutation = `
        mutation InsertSourceThrottleConfig($data: JSONObject!) {
            insertSourceThrottleConfig(data: $data) {
                id
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertSourceThrottleConfig;
}
