<template>
	<suspense>
		<div id="wrapper">
			<loader :show="!ready" background="rgba(255,255,255,1)" />
			<router-view v-slot="{ Component }">
				<transition name="login" mode="out-in">
					<component :is="Component" />
				</transition>
			</router-view>
			<p-toast>
				<template #icon>
					<icon class="info-icon" type="alert-circle" size="24px" />
					<icon class="success-icon" type="alert-circle" size="24px" />
					<icon class="warn-icon" type="alert-circle" size="24px" />
					<icon class="error-icon" type="alert-circle" size="24px" />
				</template>
			</p-toast>
			<TermsModal />
			<StateModal />
		</div>
	</suspense>
</template>

<script lang="ts">
// Global components only declared once in app, otherwise you will activate multiple modals/toasts if deeply nested or looped
import pToast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import loader from '@/components/elements/Loader.vue';
import TermsModal from '@/views/Terms/Modal.vue';
import StateModal from '@/views/Accounts/AccountStateModal.vue';
import { $GQL } from '@GQL';

export default {
	name: 'NextGenLeads',
	components: {
		pToast,
		loader,
		StateModal,
		TermsModal,
	},
	setup() {
		return {
			toast: useToast(),
		};
	},
	data() {
		return {
			ready: false,
		};
	},
	computed: {
		...mapStores(useAppStore, useSessionStore),
	},
	async created() {
		await this.appStore.initialize();
		setTimeout(() => {
			this.ready = true;
		}, 250);
	},
};
</script>

<style lang="less">
@import url('@/styles/main.less');
</style>
