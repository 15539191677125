import { $GQL } from '@GQL/index';
import type { Campaign } from '@nextgenleads/marketplace-driver';

export async function updateCampaign(id: string, data: Campaign): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateCampaign($id: ID!, $data: JSONObject!) {
            updateCampaign(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateCampaign;
}
