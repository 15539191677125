import { $GQL } from '@GQL/index';
import log from '@/lib/Log';
import type { GetListParams } from '@nextgenleads/db';

export async function getShipmentLogsList(params: GetListParams, refresh?: boolean) {
	const query = `
    query GetShipmentLogList($params: GetListParams!){
        shipmentLogList(params: $params) {
			row_count
            rows {
                id
                shipper_id
                shipper {
                    name
                }
                purchase_id
                lead_id
                lead {
                    first_name
                    last_name
                    phone
                }
                campaign_id
                campaign {
                    name
                }
                request
                response
                created_at
                status
            }
        }
    }       
    `;

	const no_cache = refresh ? ['shipmentLogList'] : undefined;
	const result = await $GQL.request(query, {
		params,
		no_cache,
	});

	return result.shipmentLogList;
}
