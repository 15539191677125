<template>
	<div class="card padded">
		<div class="card-heading padded unpadded flex align-items-center justify-content-between">
			<div class="flex align-items-center">
				<icon type="phone" class="mr-2" size="18px" />
				<h2>Phone Activity</h2>
			</div>
			<p-button v-tooltip.top="'Hard Refresh'" rounded text @click="getRecentActivity(true)">
				<template #icon><alt-icon type="refresh" size="24px" /></template>
			</p-button>
		</div>
		<div>
			<row>
				<column>
					<div class="quick-filters">
						<strong>Time span:</strong>
						<gutter size="10px" />
						<div @click="summary_timespan = 7" class="filter-like-btn" :class="{ active: summary_timespan === 7 }">
							Last 7 days
						</div>
						<div @click="summary_timespan = 14" class="filter-like-btn" :class="{ active: summary_timespan === 14 }">
							Last 2 weeks
						</div>
						<div @click="summary_timespan = 30" class="filter-like-btn" :class="{ active: summary_timespan === 30 }">
							Last Month
						</div>
					</div>
				</column>
			</row>
			<gutter size="10px" />
			<row>
				<column>
					<metric label="Incoming Texts" :value="in_text_count" />
				</column>
				<gutter size="10px" />
				<column>
					<metric label="Outgoing Texts" :value="out_text_count" />
				</column>
				<gutter size="10px" />
				<column>
					<metric label="Inbound Calls" :value="in_call_count" />
				</column>
				<gutter size="10px" />
				<column>
					<metric label="Outbound Calls" :value="out_call_count" />
				</column>
				<gutter size="10px" />
				<column>
					<metric label="AMW Emails Sent" :value="gmail_count" />
				</column>
			</row>
		</div>
		<gutter size="30px" />
		<div class="quick-filters">
			<strong>Call History:</strong>
			<gutter size="10px" />
			<div @click="list_size = 5" class="filter-like-btn" :class="{ active: list_size === 5 }">Last 5</div>
			<div @click="list_size = 10" class="filter-like-btn" :class="{ active: list_size === 10 }">Last 10</div>
			<div @click="list_size = 15" class="filter-like-btn" :class="{ active: list_size === 15 }">Last 15</div>
		</div>
		<gutter size="10px" />
		<p-data-table ref="dt" :loading="loading" :value="callLogs">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No recent calls</div>
			</template>
			<p-column header="Direction" class="align-center">
				<template #body="row">
					<icon
						v-tooltip.top="row.data.log_type === 'inbound_call' ? 'Incoming Call' : 'Outbound Call'"
						:type="row.data.log_type === 'inbound_call' ? 'call_received' : 'call_made'"
						size="30px"
					/>
				</template>
			</p-column>
			<p-column header="Date">
				<template #body="row">
					{{ formatDate(new Date(row.data.created_at), 'LT ddd, MMM D, YYYY') }}
					<div class="sub-value">{{ timeAgo(row.data.created_at) }}</div>
				</template>
			</p-column>
			<p-column header="Call Duration">
				<template #body="row">
					<div :class="{ red: !row.data.duration && row.data.log_type === 'inbound_call' }">
						{{ formatCallDuration(row.data.duration) }}
					</div>
				</template>
			</p-column>
			<p-column header="Customer">
				<template #body="row">
					<div v-tooltip.top="'Called from ' + phoneFormat(row.data.phone)">
						{{ row.data.user_name }}
					</div>
				</template>
			</p-column>
			<p-column header="Account Manager">
				<template #body="row">
					{{ row.data.account_manager_name }}
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>

<script>
import { subDays, endOfDay, startOfDay } from 'date-fns';
import { getRecentAMPhoneLogActivity } from '@GQL';
import { formatDate, formatCallDuration, phoneFormat, timeAgo } from '@/lib/Filters';
import quickFilter from '@/components/widgets/QuickFilter.vue';

export default {
	components: {
		quickFilter,
	},
	props: {
		account_id: {
			type: String,
			required: true,
		},
	},
	watch: {
		summary_timespan(new_value) {
			this.date_range[0] = subDays(startOfDay(new Date()), new_value);
			this.getRecentActivity();
		},
	},
	data() {
		return {
			loading: false,
			date_range: [startOfDay(subDays(new Date(), 7)), endOfDay(new Date())],
			look_back_window: 7,
			call_logs: [],
			out_call_count: 0,
			in_call_count: 0,
			out_text_count: 0,
			in_text_count: 0,
			email_count: 0,
			gmail_count: 0,
			list_size: 5,
			summary_timespan: 7,
		};
	},
	computed: {
		callLogs() {
			return this.call_logs.slice(0, this.list_size);
		},
	},
	async mounted() {
		await this.getRecentActivity();
	},
	methods: {
		timeAgo,
		formatDate,
		phoneFormat,
		formatCallDuration,
		async getRecentActivity(refresh) {
			try {
				this.loading = true;
				const res = await getRecentAMPhoneLogActivity({
					date_range: [...this.date_range],
					account_id: this.account_id,
					refresh,
				});
				this.out_call_count = res.out_call_count;
				this.in_call_count = res.in_call_count;
				this.out_text_count = res.out_text_count;
				this.in_text_count = res.in_text_count;
				this.email_count = res.email_count;
				this.gmail_count = res.gmail_count;
				this.call_logs = res.call_logs;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get recent call activity',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped lang="less">
.card {
	h2 {
		font-size: 1.1rem;
	}
}

.filter-like-btn {
	background: transparent;
	border: 1px solid var(--gray-25);
	border-radius: 1.5rem;
	color: var(--gray-40);
	cursor: pointer;
	display: inline-block;
	margin: 0.25rem;
	padding: 0.5rem 1rem;
	transition: 0.3s;

	&:hover {
		background: var(--color-b-darker);
		border-color: var(--color-b-darker);
		color: white;
		text-decoration: none;
	}

	&.active {
		background: var(--color-b);
		border-color: var(--color-b);
		color: white;

		&:hover {
			background: var(--color-b-darker);
			border-color: var(--color-b-darker);
		}
	}
}
.red {
	color: red;
}
</style>
