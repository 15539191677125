import { $GQL } from '@GQL/index';
import { type Notification } from '@nextgenleads/marketplace-driver';

export async function getNotification(notificationId: string): Promise<Notification> {
	const query = `
        query Query($notificationId: ID!) {
            notification(id: $notificationId) {
            type
            tags
            status
            message
            filters
            expires
            created_by
            }
        }
    `;

	const results = await $GQL.request(query, { notificationId });
	return results.notification;
}
