<template>
	<div class="action send-to-auction">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Send to Lead Build</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this action?"
				tooltip="Remove Build Step"
				@delete="removeAction"
			/>
		</div>
		<div class="description">
			Send the lead to Lead service, there with the matching capture_config unique key, the correct Capture Config will
			process the lead.
		</div>
		<div class="action-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Capture Config:</label>
					<div class="controls">
						<div class="field">
							<p-input-text
								v-model="modelValue.capture_config"
								placeholder="Leave blank to auto-select auction based on lead attributes"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'SendToLeadBuild',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				capture_config: '',
			},
		},
	},
	methods: {
		removeAction() {
			this.$emit('remove');
		},
	},
};
</script>
