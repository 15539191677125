<template>
	<div id="ads-list" class="view-content">
		<div class="page-heading">
			<h1>Premium Listings</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<row>
				<p-button label="New Premium Listing" @click="$router.push({ path: `${$route.path}/new` })">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="plus" size="24px" />
						</div>
					</template>
				</p-button>
				<div class="flex gap-2">
					<show-archived v-model="show_archived" />
					<p-button v-tooltip.top="'Refresh'" icon="refresh" @click="getAds(true)" :loading="loading">
						<icon type="refresh" size="24px" />
					</p-button>
				</div>
			</row>
			<gutter size="20px" />
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table ref="dt" scrollable :value="show_archived ? ads : visibleAds" :loading="loading">
							<template #empty>
								<div class="dim">No premium listings found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="name" header="Ad Name">
								<template #body="row">
									<router-link :to="`${$route.path}/${row.data.id}`">{{ row.data.name }}</router-link>
								</template>
							</p-column>
							<p-column field="vertical_id" header="Vertical" class="column-align-center">
								<template #body="row">
									<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
								</template>
							</p-column>
							<p-column field="template_id" header="Template">
								<template #body="row">
									<template v-if="row.template">
										{{ row.template.name }}<br />
										<div class="sub-value">{{ row.template_id }}</div>
									</template>
									<template v-else> Best Performing </template>
								</template>
							</p-column>
							<p-column field="assigned_campaigns" header="Assigned To" class="column-align-center">
								<template #body="row">
									<div
										class="tooltip-wrapper"
										v-tooltip="{ value: assignedTooltip(row.data.assigned_campaigns), escape: false }"
									>
										{{ row.data.assigned_campaigns.length }}
									</div>
								</template>
							</p-column>
							<p-column field="status" header="Status" class="column-align-center">
								<template #body="row">
									{{ capitalize(row.data.status) }}
								</template>
							</p-column>
							<p-column field="approval_status" header="Approved" class="column-align-center">
								<template #body="row">
									<icon
										v-if="row.data.approval_status === 'approved'"
										style="color: var(--green)"
										type="check"
										size="24px"
									/>
									<icon
										v-if="row.data.approval_status === 'rejected'"
										style="color: var(--red)"
										type="cancel"
										size="24px"
										v-tooltip="row.data.rejected_reason"
									/>
									<icon
										v-if="row.data.approval_status === 'pending_approval'"
										style="color: var(--gray-35)"
										type="timer-sand"
										size="24px"
									/>
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button v-tooltip.top="'Duplicate'" aria-label="Duplicate" @click="duplicateAd(row.data.id)">
												<template #icon>
													<icon type="content-copy" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button
												v-if="row.data.status === 'archived'"
												v-tooltip.top="'Un-archive Ad'"
												aria-label="Unarchive"
												@click="activateAd(row.data.id)"
											>
												<template #icon>
													<icon type="archive-off" size="20px" />
												</template>
											</p-button>
										</div>
										<delete-action
											v-if="row.data.assigned_campaigns.length === 0 && row.data.status !== 'archived'"
											message="Are you sure you want to delete this ad?"
											@delete="deleteAd(row.data.id)"
										/>
									</div>
								</template>
							</p-column>
						</p-data-table>
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { get } from 'lodash-es';
import { capitalize } from '@/lib/Filters';
import { getAds, deleteAd, updateAd } from '@GQL';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';

export default {
	name: 'AdListView',
	components: {
		showArchived,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			show_archived: false,
			ads: [],
			breadcrumb_items: [
				{
					label: 'Premium Listings',
				},
			],
		};
	},
	computed: {
		visibleAds() {
			if (this.show_archived) {
				return this.ads;
			}
			return this.ads.filter((ad) => {
				return ad.status !== 'archived';
			});
		},
	},
	methods: {
		capitalize,
		assignedTooltip(campaigns) {
			let output = '<ul class="bulleted">';
			campaigns.forEach((campaign) => {
				output += `<li>${campaign.name}</li>`;
			});
			if (campaigns.length === 0) {
				output += '<li>No assigned campaigns</li>';
			}
			output += '</ul>';
			return output;
		},
		async getAds(refresh = false) {
			this.loading = true;
			const no_cache = [];
			if (refresh) {
				no_cache.push('ads');
			}

			const account_id = get(this.$route, 'params.account_id', this.$root.sessionStore.user.account_id);
			const ads = await getAds(`account_id = '${account_id}' AND type = 'premium_listing'`, no_cache);
			this.ads = ads;
			this.loading = false;
		},
		duplicateAd(ad_id) {
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					id: ad_id,
				},
			});
		},
		async activateAd(ad_id) {
			const update_result = await updateAd(ad_id, { status: 'disabled' });
			if (update_result.id) {
				this.$toast.add({
					severity: 'success',
					summary: `The ad "${update_result.name}" was un-archived.`,
					life: 3000,
				});
				// Update local UI
				this.ads.find((ad) => ad.id === ad_id).status = 'disabled';
			} else {
				this.$toast.add({
					severity: 'error',
					summary: `Unable to activate the ad "${update_result.name}".`,
					life: 3000,
				});
			}
		},
		async deleteAd(ad_id) {
			const delete_result = await deleteAd(ad_id);
			if (delete_result.id) {
				this.$toast.add({
					severity: 'success',
					summary: `The ad "${delete_result.name}" was archived.`,
					life: 3000,
				});
				// Update local UI
				this.ads.find((ad) => ad.id === ad_id).status = 'archived';
			} else {
				this.$toast.add({
					severity: 'error',
					summary: `Unable to archive the ad "${delete_result.name}".`,
					life: 3000,
				});
			}
		},
	},
	async mounted() {
		await this.getAds();
	},
};
</script>

<style>
.tooltip-wrapper {
	cursor: default;
	display: inline-block;
	min-width: 30px;
}
</style>
