/**
 * MARKETPLACE STORE
 * Contains data relating to the current nested marketplace (or active marketplace for non-admin)
 */

import { defineStore } from 'pinia';
import { initMarketplaceApp } from '@GQL';
import { useSessionStore } from '@/stores/session';
import log from '@/lib/Log';

export const useMarketplaceStore = defineStore('marketplace', {
	state: () => {
		return {
			mpid: '',
			marketplace: {
				name: '',
				settings: {
					logo_url: '',
					favicon_url: '',
					login_bkg_url: '',
					theme: '',
				},
			},
			settings: {},
			role: {
				id: '',
				name: '',
				type: '',
				granted: [],
				revoked: [],
				status: '',
			},
		};
	},
	getters: {
		isAdminUser(): boolean {
			return this.role && ['mp_admin'].includes(this.role.id);
		},
	},
	actions: {
		$reset() {
			this.mpid = '';
			this.marketplace = {
				name: '',
				settings: {
					logo_url: '',
					favicon_url: '',
					login_bkg_url: '',
					theme: '',
				},
			};
			this.settings = {};
			this.role = {
				id: '',
				name: '',
				type: '',
				granted: [],
				revoked: [],
				status: '',
			};
		},
		async initialize(mpid: string) {
			const sessionStore = useSessionStore();

			if (this.mpid !== '' && this.mpid) {
				sessionStore.leaveRoom(this.mpid);
			}

			this.mpid = mpid;
			const init_result = await initMarketplaceApp(mpid);
			this.marketplace = init_result.marketplace;
			this.settings = init_result.settings;

			sessionStore.joinRoom(mpid);

			// lets add listeners for vendors and verticals
			sessionStore.addSocketListener('updateSettings:verticals', (update_res) => {
				this.settings.verticals = update_res.data;
			});

			sessionStore.addSocketListener('updateSettings:vendors', (update_res) => {
				this.settings.vendors = update_res.data;
			});
		},
	},
});
