<template>
	<div id="edit-campaign" class="view-content">
		<Loader :show="!campaign_ready" />
		<template v-if="campaign_ready">
			<h1>{{ campaignName }}</h1>
			<breadcrumb :items="$route.name.indexOf('CreateCampaign') > -1 ? breadcrumbCreate : breadcrumbEdit" />
			<StepView :step="step" />
		</template>
	</div>
</template>

<script lang="ts">
import StepView from './StepView.vue';
import Loader from '@/components/elements/Loader.vue';
import { useCampaignStore } from '@/stores/campaign';
import { mapStores } from 'pinia';

export default {
	name: 'EditCampaign',
	components: {
		Loader,
		StepView,
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
			step: 0,
			campaign_ready: false,
		};
	},
	computed: {
		...mapStores(useCampaignStore),
		campaignName() {
			return this.campaignStore.campaign.name
				? this.campaignStore.campaign.name
				: this.isTemplate
					? 'New Template Campaign'
					: 'New Campaign';
		},
		campaignChanges() {
			return this.campaignStore.campaign;
		},
		isTemplate() {
			return this.$route.meta.template_mode;
		},
		breadcrumbCreate() {
			return [
				{
					label: this.isTemplate ? 'Campaign Templates' : 'Campaigns',
					route: this.isTemplate ? '/campaign-templates' : '/campaigns',
				},
				{ label: this.isTemplate ? 'New Campaign Template' : 'New Campaign' },
			];
		},
		breadcrumbEdit() {
			return [
				{
					label: this.isTemplate ? 'Campaign Templates' : 'Campaigns',
					route: this.isTemplate ? '/campaign-templates' : '/campaigns',
				},
				{ label: this.isTemplate ? 'Edit Campaign Template' : 'Edit Campaign' },
			];
		},
	},
	async mounted() {
		this.campaignStore.$reset();
		if (this.$route.meta.new) {
			if (this.$route.query.duplicate) {
				// we are duplicating
				await this.campaignStore.duplicate(this.$route.query.duplicate);
			}
		} else {
			// we are editing
			await this.campaignStore.fetch(this.$route.params.campaign_id);
		}

		this.campaign_ready = true;

		// Due to weird loading, we wait 5 seconds before watching for changes
		setTimeout(() => {
			const unwatchCampaign = this.$watch(
				'campaignChanges',
				(new_value, old_value) => {
					this.campaignStore.touched = true;
					unwatchCampaign();
				},
				{
					deep: true,
				}
			);
		}, 5000);
	},
	beforeRouteLeave(to, from, next) {
		if (this.campaignStore.touched) {
			this.$confirm.require({
				group: 'default',
				message: `There are unsaved changes to this campaign${this.isTemplate ? ' template' : ''}. Are you sure you want to leave without saving?`,
				header: 'Cancel',
				acceptClass: 'p-button-danger',
				acceptLabel: 'Leave',
				rejectLabel: 'Stay',
				accept: () => {
					next();
				},
				reject: () => {
					next(false);
				},
			});
		} else {
			next();
		}
	},
};
</script>

<style lang="less" scoped>
#edit-campaign {
	display: flex;
	flex-direction: column;
	height: 100%;
	// 100% Viewport Height - Toolbar
	min-height: calc(100vh - 60px);
}

:deep(.input-percentage) {
	max-width: 120px;
}
</style>
