<template>
	<p-card class="edit-role-form">
		<template #content>
			<div class="control-group">
				<div class="inner">
					<div class="controls w-full">
						<div class="field-row gap-20">
							<div class="field">
								<label class="control-label req" for="role-id">Role ID:</label>
								<p-input-text
									id="role-id"
									v-model="role.id"
									class="p-inputtext"
									placeholder="Unique human-readable slug"
								/>
								<div v-if="v$.role.id.$error" class="validation-error">Role ID required.</div>
							</div>
							<div class="field">
								<label class="control-label req" for="role-name">Role Name:</label>
								<p-input-text
									id="role-name"
									v-model="role.name"
									class="p-inputtext"
									placeholder="A unique name for this role"
								/>
								<div v-if="v$.role.name.$error" class="validation-error">Role Name required.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req" for="mpid">MPID:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="role.mpid"
								:options="mpid_options"
								:option-label="(option) => `${option.label} - ${option.value}`"
								option-value="value"
								placeholder="Select MPID"
								:disabled="!$route.meta.new"
							/>
							<div v-if="v$.role.id.$error" class="validation-error">MPID required.</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req" for="type">Type:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="type"
								v-model="role.type"
								:options="type_options"
								option-label="label"
								option-value="value"
								placeholder="Select Type"
							/>
							<div v-if="v$.role.type.$error" class="validation-error">Type required.</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req" for="status">Status:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								class="w-full"
								id="status"
								v-model="role.status"
								:options="active_status_options"
								placeholder="Select Status"
								option-label="label"
								option-value="value"
							/>
							<div v-if="v$.role.status.$error" class="validation-error">Status required.</div>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field-row gap-20 align-items-start">
							<div class="field">
								<label class="control-label" for="granted">Granted:</label>
								<InputList id="granted" v-model="role.granted" />
							</div>
							<div class="field">
								<label class="control-label" for="granted">Revoked:</label>
								<InputList id="granted" v-model="role.revoked" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</template>
		<template #footer>
			<div class="footer flex justify-content-end">
				<p-button label="Submit" @click="submit" />
			</div>
		</template>
	</p-card>
</template>

<script lang="ts">
import { useSessionStore } from '@/stores/session';
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { getRoleById, insertRole, updateRole, getMarketplacesAsOptions } from '@GQL';
import { active_status_options } from '@/lib/Options';
import pButton from 'primevue/button';
import pCard from 'primevue/card';
import pText from 'primevue/inputtext';
import pDropdown from 'primevue/dropdown';
import InputList from '@/components/forms/InputList.vue';
import { cloneDeep } from 'lodash-es';

export default {
	name: 'EditRoles',
	inject: {
		nested_mpid: {
			from: 'nested_mpid',
			default: '$NG',
		},
	},
	components: {
		pButton,
		pCard,
		pDropdown,
		pText,
		InputList,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
			v$: useVuelidate(),
		};
	},
	async created() {
		this.mpid_options = await getMarketplacesAsOptions();
		this.mpid_options.push({ label: 'Global', value: '$NG' });
	},
	data() {
		const default_role = {
			id: '',
			granted: ['.*'],
			revoked: [],
			status: '',
			type: '',
			name: '',
			mpid: this.nested_mpid,
		};

		return {
			existing_role: false,
			active_status_options,
			mpid_options: [],
			type_options: [
				{
					label: 'Account',
					value: 'account',
				},
				{
					label: 'Marketplace',
					value: 'marketplace',
				},
				{
					label: 'App',
					value: 'app',
				},
				{
					label: 'Admin',
					value: 'admin',
				},
			],
			loading: false,
			role: { ...default_role },
		};
	},
	async mounted() {
		if (this.$route.params.role_id) {
			this.existing_role = true; // Used to change how submit works
			const result = await getRoleById(this.$route.params.role_id, this.nested_mpid);
			this.role = cloneDeep(result);
		}

		if (this.$route.query.duplicate) {
			const result = await getRoleById(this.$route.query.duplicate, this.nested_mpid);
			this.role = cloneDeep(result);
			this.role.id += '_copy';
			this.role.name += ' (Copy)';
		}
	},
	methods: {
		/** USED TO MAKE EDIT OR INSERT REQUESTS */
		async submit() {
			if (await this.v$.$validate()) {
				let result;
				if (this.existing_role) {
					// cannot update role.id
					result = await updateRole(this.role.id, this.role.mpid, this.role);
				} else {
					result = await insertRole(this.role);
				}

				if (result.id) {
					this.$toast.add({
						severity: 'success',
						summary: this.existing_role ? 'Role has been updated' : 'Role created',
						life: 3000,
					});
					this.$router.back();
				}
			}
		},
	},
	validations() {
		return {
			role: {
				id: { required },
				type: { required },
				status: { required },
				name: { required },
				mpid: { required },
			},
		};
	},
};
</script>

<style lang="less" scoped>
.edit-role-form {
	max-width: 800px;
}

.sub-value {
	color: var(--gray-50);
	display: inline-block;
	font-size: var(--font-size-sm);
}
</style>
