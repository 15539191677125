import { $GQL } from '@GQL/index';
import type { Queue } from '@nextgenleads/lead-driver';

export async function updateQueue(id: string, data: Partial<Queue>): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateQueue($id: ID!, $data: JSONObject!) {
            updateQueue(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateQueue;
}
