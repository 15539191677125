import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';

export async function getEmailTemplates(filters: QueryFilters, refresh?: boolean) {
	const query = `
        query GetEmailTemplates($filters: [[Any]]) {
            emailTemplates(filters: $filters) {
                created_at
                id
                name
                subject
                tags
                status
            }
        }
    `;

	const no_cache = refresh ? ['emailtemplates'] : undefined;

	const request = await $GQL.request(query, { filters, no_cache });
	return request.emailTemplates;
}
