import { $GQL } from '@GQL';
import type { AccountOffer } from '@nextgenleads/marketplace-driver';

export async function insertAccountOffer(data: Omit<AccountOffer, 'created_at'>) {
	const mutation = `
        mutation InsertAccountOffer($data: JSONObject!) {
            insertAccountOffer(data: $data) {
                offer {
                    id
                    name
                    credit_type
                    credit_amount
                    description
                    required_funding
                    trigger
                    expiration
                }
                status
                claimed_at
				created_at
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertAccountOffer;
}
