import { $GQL } from '@GQL/index';
import { type PaymentMethod } from '@nextgenleads/banker-driver';

export async function getPaymentMethods(account_id: string, refresh?: boolean): Promise<PaymentMethod[]> {
	const query = `query GetPaymentMethods($filters: [[Any]]) {
        paymentMethods(filters: $filters) {
             id
             type
             last_four
             expiration_date
             name_on_card
             street_1
             street_2
             city
             state
             zip
             status
             verified
             verified_at
        }
    }`;

	const filters = [[`account_id = '${account_id}'`]];
	const no_cache = refresh ? ['paymentMethods'] : undefined;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.paymentMethods;
}
