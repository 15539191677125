// --------------------------------------------------
// FILTER: timeAgo
// Use dayjs to show time difference relative to now
// --------------------------------------------------

// Import dependencies
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function timeAgo(date: number | Date) {
	if (!date) {
		return '--';
	}
	return dayjs(date).fromNow();
}
