<template>
	<div class="build-step check-duplicates">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Check Duplicates</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">
			Flag or reject a lead of the same product type and vertical that has the same phone number as a lead that was
			captured within the specified number of seconds.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label">Action:</label>
							<p-dropdown
								v-model="modelValue.action"
								:options="action_options"
								option-label="label"
								option-value="value"
								placeholder="Select Action"
							/>
						</div>
						<div class="field">
							<label class="control-label">Duplicate Window:</label>
							<div class="p-inputgroup">
								<p-input-number v-model="modelValue.window" class="input-align-right" />
								<div class="p-inputgroup-addon">sec</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { cloneDeep, isEqual } from 'lodash-es';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'CheckDuplicates',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				action: 'flag',
			},
		},
	},
	data() {
		return {
			action_options: [
				{ label: 'Flag', value: 'flag' },
				{ label: 'Reject', value: 'reject' },
			],
		};
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
