import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';

export async function getEmailTemplatesAsOptions(filters: QueryFilters, tag?: string, refresh?: boolean) {
	const query = `
        query GetEmailTemplatesAsOptions($filters: [[Any]]) {
            emailTemplates(filters: $filters) {
                id
                name
                tags
            }
        }
    `;

	const no_cache = refresh ? ['emailTemplates'] : undefined;

	const request = await $GQL.request(query, { filters, no_cache });

	return request.emailTemplates
		.filter((row) => {
			if (tag && tag !== '') {
				return row.tags !== null && row.tags.indexOf(tag) > -1;
			} else return true;
		})
		.map((row) => {
			return {
				label: row.name,
				value: row.id,
			};
		});
}
