<template>
	<div id="notifications-menu">
		<a class="notification-toggle" @click="notificationMenuToggle">
			<div v-badge="total_rows" class="icon-wrapper">
				<icon type="bell-outline" size="24px" />
			</div>
		</a>
		<p-overlay ref="notificationMenu" class="notification-menu">
			<div class="header">
				<div class="flex align-items-center gap-2">
					<p-button icon="pi pi-refresh" text @click="getNotifications" />
					<h2>Notifications</h2>
				</div>
				<p-button v-if="notifications.length > 0" label="Mark all as read" text @click="readAllNotifcations" />
			</div>
			<div class="body">
				<div v-for="notification in notifications" :key="notification.id">
					<div class="cursor-pointer hover:surface-100 p-2 mb-2" @click="openNotification(notification)">
						<div class="notification-summary">
							<p class="notification-content" v-html="truncateMsg(notification.notification.message)" />
							<p class="date">{{ formatDate(notification.created_at, 'lll') }}</p>
						</div>
					</div>
				</div>
				<div v-if="notifications.length === 0" class="text-center p-3">No unread notifications</div>
			</div>
			<div class="footer">
				<a @click.prevent="handleNotificationsSidebar">See All Notifications</a>
			</div>
		</p-overlay>
		<p-dialog v-model:visible="notification_modal_open" :modal="true">
			<template #header>
				<div>
					<strong>Notification</strong>
				</div>
			</template>
			<div class="card-section">
				<span v-html="selected_notification.notification.message"></span>
				<gutter size="20px" />
			</div>
			<template #footer>
				<div>
					<p-button label="Mark as read" @click="readNotification" />
				</div>
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import pOverlay from 'primevue/overlaypanel';
import pDialog from 'primevue/dialog';
import icon from '@/components/elements/Icon.vue';
import { formatDate } from '@/lib/Filters';
import { getNotificationReceiptsForDrawer, dismissNotificationReceipt } from '@GQL';
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';
import { asyncForEach } from 'modern-async';
import log from '@/lib/Log';

export default {
	name: 'NotificationsMenu',
	components: {
		pOverlay,
		pDialog,
		icon,
	},
	setup() {
		const appStore = useAppStore();
		const sessionStore = useSessionStore();
		return { appStore, sessionStore };
	},
	data() {
		return {
			notifications: [],
			total_rows: 0,
			selected_notification: {},
			notification_modal_open: false,
		};
	},
	async mounted() {
		await this.getNotifications();
		this.sessionStore.addSocketListener('newNotification', async (data) => {
			this.total_rows += 1;
			this.notifications.unshift(data);
		});
	},
	methods: {
		formatDate,
		async readNotification() {
			try {
				await dismissNotificationReceipt(this.selected_notification.id);
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to dismiss notification',
					life: 3000,
				});
			}
			this.removeNotification(this.selected_notification.id);
			this.notification_modal_open = false;
			this.selected_notification = {};
		},
		removeNotification(id) {
			const idx_to_remove = this.notifications.findIndex((notification) => {
				return notification.id === id;
			});
			this.notifications.splice(idx_to_remove, 1);
			this.total_rows--;
		},
		async readAllNotifcations() {
			try {
				await asyncForEach(this.notifications, async (notification) => {
					await dismissNotificationReceipt(notification.id);
				});

				// Clear notifications drawer
				this.notifications = [];
				this.total_rows = 0;

				this.$toast.add({
					severity: 'success',
					summary: 'Marked all notifications as read',
					life: 6000,
				});
			} catch (err) {
				log.trace('Error dismissing notifications', err);
				this.$toast.add({
					severity: 'error',
					summary: 'An error occured dismissing notifications',
					life: 6000,
				});
			}
		},
		openNotification(notification) {
			this.selected_notification = notification;
			this.notification_modal_open = true;
		},
		async getNotifications() {
			try {
				const { rows, row_count } = await getNotificationReceiptsForDrawer(this.sessionStore.user.id);
				this.total_rows = row_count;
				this.notifications = rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get notification',
					life: 6000,
				});
			}
		},
		notificationMenuToggle(event) {
			this.$refs.notificationMenu.toggle(event);
		},
		truncateMsg(msg) {
			return msg.length > 250 ? `${msg.split('').slice(0, 250).join('')}...` : `${msg}`;
		},
		handleNotificationsSidebar() {
			this.$refs.notificationMenu.toggle();
			this.appStore.show_notifications_drawer = !this.appStore.show_notifications_drawer;
		},
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/themes/default';

.notification-menu {
	.p-overlaypanel-content {
		.header {
			align-items: center;
			display: flex;
			padding-bottom: 1.25rem;
			place-content: center space-between;
			position: relative;
			width: 100%;

			.p-button {
				font-weight: normal;
			}

			h2 {
				font-size: 1.25rem;
				margin: 0 1rem 0 0;
			}

			&::after {
				border-bottom: 1px solid var(--gray-10);
				bottom: 0;
				content: '';
				display: block;
				left: -1.25rem;
				position: absolute;
				width: calc(100% + 2.5rem);
			}
		}

		.body {
			padding: 0.75rem 0;
			h3 {
				align-items: center;
				display: flex;
				font-size: 1rem;
				font-weight: bold;
				margin-bottom: 0.25rem;

				.dot {
					background-color: var(--green);
					border-radius: 50%;
					display: inline-block;
					height: 0.5rem;
					margin-right: 0.5rem;
					width: 0.5rem;
				}
			}

			a {
				color: var(--black);
				cursor: pointer;
				display: block;
				padding: 0.5rem;
				transition: 0.3s;

				&:hover {
					background: var(--gray-05);
					text-decoration: none;
				}
			}

			.notification-content {
				font-size: 0.9rem;
				margin-bottom: 0.25rem;
				max-width: 320px;
			}

			.date {
				color: var(--gray-50);
				font-size: 0.825rem;
				margin-bottom: 0;
				position: relative;
			}

			a:last-child {
				.date {
					margin-bottom: 0;
				}
			}
		}

		.footer {
			align-items: center;
			display: flex;
			padding-top: 1.25rem;
			place-content: center center;
			position: relative;
			width: 100%;

			a {
				color: var(--color-b-dark);
				font-size: 1rem;
				font-weight: bold;

				&:hover {
					color: var(--color-b);
				}
			}

			&::before {
				border-bottom: 1px solid var(--gray-10);
				content: '';
				display: block;
				left: -1.25rem;
				position: absolute;
				top: 0;
				width: calc(100% + 2.5rem);
			}
		}
	}
}

#notifications-menu {
	display: block;
	margin: 0 10px;
	position: relative;

	.notification-toggle {
		color: var(--black);
		cursor: pointer;
	}

	:deep(.p-overlay-badge) .p-badge {
		background: #ea4335;
		bottom: -5px;
		right: 8px;
		top: auto;
	}
}
</style>

<style lang="less">
.notification-menu {
	transform: translateY(10px);
}
</style>
