<template>
	<div class="socket-toast"></div>
</template>

<script lang="ts">
import { mapStores } from 'pinia';
import { useSessionStore } from '@/stores/session';

export default {
	name: 'SocketToast',
	data() {
		return {};
	},
	computed: {
		...mapStores(useSessionStore),
	},
	mounted() {
		this.sessionStore.addSocketListener('toast', (data) => {
			this.$toast.add({
				severity: data.severity,
				summary: data.summary,
				detail: data.detail,
				life: data.life,
			});
		});
	},
	beforeUnmount() {
		this.sessionStore.removeSocketListener('toast');
	},
};
</script>
