<template>
	<div class="settings-form">
		<h2>Navigation Settings</h2>
		<template v-if="mpid !== '$NG'">
			<p-message v-if="!this.localSettingsList.includes('navigation')" :closable="false" severity="warn"
				>These settings are controlled at the global level. To override the global settings for this marketplace only,
				<strong><a href="javascript:void(0)" @click="localizeSetting('navigation', mpid)">click here</a></strong
				>.</p-message
			>
			<p-message :closable="false" v-else>
				The navigation settings for this marketplace are currently overriding the global settings. To remove the
				marketplace overrides, <strong><a href="javascript:void(0)" @click="deleteLocalSetting()">click here</a></strong
				>.
			</p-message>
		</template>
		<template v-else-if="overrides.length">
			<p-message :closable="false">
				<div>Some marketplaces are overriding these settings locally:</div>
				<ul class="bulleted">
					<li v-for="mpid in overrides" :key="mpid">{{ mpid }}</li>
				</ul>
			</p-message>
		</template>
		<gutter size="10px" />
		<p-block-ui :blocked="mpid !== '$NG' && !this.localSettingsList.includes('navigation')">
			<p-fieldset legend="Navigation">
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<code-editor v-model.parse="navigation" />
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
			<div class="actions">
				<p-button label="Save" icon="pi pi-check" @click="updateSetting()" />
				<gutter size="20px" />
				<p-button label="Reset" text @click="resetSetting()" />
			</div>
		</p-block-ui>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import CodeEditor from '@/components/forms/CodeEditor.vue';
import { getSettings, updateSetting, localizeSetting, deleteLocalSetting, getLocalOverrideSettingsList } from '@GQL';
import { useMarketplaceStore } from '@/stores/marketplace';
import 'brace/mode/json';
import 'brace/theme/sqlserver';
import pBlockUi from 'primevue/blockui';
import { mapStores } from 'pinia';

export default {
	name: 'NavigationSettings',
	components: {
		pBlockUi,
		CodeEditor,
	},
	props: {
		localSettingsList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			navigation: null,
			defaults: null,
			overrides: [],
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		...mapStores(useMarketplaceStore),
	},
	methods: {
		async localizeSetting() {
			const setting = await localizeSetting('navigation', this.mpid);
			this.navigation = setting.data;
			this.$emit('refresh');
		},
		async deleteLocalSetting() {
			const setting = await deleteLocalSetting('navigation', this.mpid);
			if (setting.id) {
				await this.getSetting();
			}
			this.$emit('refresh');
		},
		async getSetting() {
			const settings_result = await getSettings(['navigation'], this.mpid);
			this.navigation = settings_result.settings.navigation;
			this.defaults = cloneDeep(this.navigation);

			if (this.mpid == '$NG') {
				this.overrides = await getLocalOverrideSettingsList('navigation');
			}
		},
		async updateSetting() {
			const settings_result = await updateSetting('navigation', {
				data: this.navigation,
			});
			if (settings_result.id === 'navigation') {
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully saved navigation settings',
					life: 3000,
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
				});
			}
		},
		resetSetting() {
			this.navigation = cloneDeep(this.defaults);
		},
	},
	async mounted() {
		await this.getSetting();
	},
};
</script>

<style scoped lang="less">
.field {
	flex: 1 0;
}
</style>
