<template>
	<div class="content-campaign-versions">
		<div class="campaign-versions">
			<div v-if="campaign_versions.length === 0">
				<div class="sub-value">There are no campaign changes.</div>
			</div>
			<div v-else class="note-campaign-changes-container">
				<div
					v-for="campaign_change in campaign_versions"
					@click="openCampaignChangeModal(campaign_change)"
					class="note"
					:class="{ clickable: !campaign_change.change_by }"
				>
					<div>
						<template v-if="campaign_change.change_by">
							Campaign status for "<i>{{ campaign_change.campaign_name }}</i
							>" was set to <strong>{{ capitalize(campaign_change.status_change) }}</strong>
						</template>
						<template v-else>
							Campaign <strong>data</strong> change of "<i>{{ campaign_change.campaign_name }}</i
							>" created <strong>Version {{ campaign_change.version }}</strong
							>. Click to see raw data.
						</template>
						<gutter size="5px" />
						<div class="note-details">
							<span v-if="campaign_change.change_by"> Changed By {{ campaign_change.change_by }} - </span>
							<span v-else-if="campaign_change.modified_by_name">
								Changed By {{ campaign_change.modified_by_name }} -
							</span>
							{{ formatDate(new Date(campaign_change.timestamp), 'ddd, MMM D, YYYY hh:mm A') }}
						</div>
					</div>
					<gutter size="5px" />
				</div>
				<p-paginator
					v-if="total_rows > query.pagination.page_size"
					:rows="query.pagination.page_size"
					:total-records="total_rows"
					@page="handlePageChange"
				/>
			</div>
			<p-dialog modal v-model:visible="show_campaign_change_modal" :style="{ width: '50vw' }">
				<template #header>
					<div class="flex align-items-center" style="gap: 10px">
						<icon type="alert" size="24px" style="color: var(--color-b)" />Changes for Campaign:
						<strong>{{ selected_campaign_change.campaign_name }}</strong>
					</div>
				</template>

				<code-editor :enabled="false" v-model.parse="selected_campaign_change" />

				<!-- <div v-if="campaign_differences.length === 0 && selected_campaign_change.snapshot">
					<code-editor :enabled="false" v-model.parse="selected_campaign_change.snapshot" />
				</div>
				<div v-else-if="campaign_differences.length > 0">
					<gutter size="10px" />
					<row>
						<column>
							<h4>Version {{ selected_campaign_change.version }}</h4>
						</column>
						<column>
							<h4>Version {{ selected_campaign_change.version - 1 }}</h4>
						</column>
					</row>
					<div v-for="diff in campaign_differences">
						<label>{{ diff.msg }}</label>
						<div v-for="path_diff in simplifyDiffObject(diff.isolated)">
							<row>
								<column>
									<div class="sub-value">Path:</div>
									<code>{{ path_diff.path }}</code>
									<code>{{ JSON.stringify(path_diff.next, null, 4) }}</code>
								</column>
								<gutter size="15px" />
								<column>
									<div class="sub-value">Path:</div>
									<code>{{ path_diff.path }}</code>
									<code>{{ JSON.stringify(path_diff.prev, null, 4) }}</code>
								</column>
							</row>
							<gutter size="15px" />
						</div>
						<div v-if="Object.keys(diff.isolated).length === 0">
							<row>
								<column>
									<div v-if="diff.base_path" class="sub-value">Path:</div>
									<code v-if="diff.base_path">{{ diff.base_path }}</code>
									<pre><code v-if="diff.next">{{JSON.stringify(diff.next, null, 4)}}</code></pre>
								</column>
								<gutter size="15px" />
								<column>
									<div v-if="diff.base_path" class="sub-value">Path:</div>
									<code v-if="diff.base_path">{{ diff.base_path }}</code>
									<pre><code v-if="diff.prev">{{JSON.stringify(diff.prev, null, 4)}}</code></pre>
								</column>
							</row>
						</div>
					</div>
				</div>
				<div v-else>Status was changed to {{ capitalize(campaign_change.status_change) }}</div> -->
			</p-dialog>
		</div>
	</div>
</template>

<script lang="ts">
import { formatDate, capitalize } from '@/lib/Filters';
import { reportCampaignActivity } from '@/lib/GQL';
// import { compareCampaigns, simplifyDiffObject } from '@/lib/Utils/campaignCompare';
// import { find } from 'lodash-es';
import pPaginator from 'primevue/paginator';

export default {
	name: 'CampaignVersionsContent',
	components: {
		pPaginator,
	},
	data() {
		return {
			campaign_versions: [],
			campaign_differences: [],
			selected_campaign_change: {},
			show_campaign_change_modal: false,
			total_rows: 0,
			query: {
				pagination: {
					page_size: 5,
					page: 0,
				},
			},
		};
	},
	async mounted() {
		this.getCampaignHistory();
	},
	methods: {
		// simplifyDiffObject,
		capitalize,
		formatDate,
		handlePageChange(data) {
			this.query.pagination.page = data.page;
			return this.getCampaignHistory();
		},
		openCampaignChangeModal(cc) {
			if (cc.snapshot) {
				this.selected_campaign_change = cc;
				this.show_campaign_change_modal = true;
			}
		},
		campaignChangeHelper(cc) {
			// if the campaign change is from event log it will have a change_by field, and status_change
			if ('status_change' in cc) {
				return `Campaign status for '${cc.campaign_name}' set to ${capitalize(cc.status_change)}`;
			} else {
				return `Campaign data was changed for '${cc.campaign_name}'`;
				// its a generic change and we must have a modal to show the change object
			}
		},
		async getCampaignHistory() {
			const report_query = {
				campaign_id: this.$route.params.campaign_id,
				pagination: this.query.pagination,
			};
			try {
				const { rows, row_count } = await reportCampaignActivity(report_query);
				this.campaign_versions = rows;
				this.total_rows = row_count;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get campaign versions',
					life: 6000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';
.clickable {
	&:hover {
		cursor: pointer;
	}
}
.note-campaign-changes-container {
	height: 400px;
	overflow-y: scroll;

	.note-details {
		color: var(--gray-35);
		font-size: 0.75rem;
		line-height: 1.25em;
	}
	.note {
		border-bottom: 1px dashed var(--gray-20);
		font-size: 0.875rem;
		line-height: 1.5em;
		margin-bottom: 10px;
		padding: 0 7px 7px 0;
	}
}
</style>
