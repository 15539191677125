<template>
	<div id="edit-marketplace" class="view-content">
		<div class="page-heading">
			<h1>{{ heading }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<MarketplaceForm />
	</div>
</template>

<script lang="ts">
import MarketplaceForm from './Form.vue';
export default {
	name: 'MarketplacesEdit',
	components: {
		MarketplaceForm,
	},
	data() {
		const is_create = this.$route.name.indexOf('MarketplaceCreate') > -1 ? true : false;
		return {
			is_create,
			heading: is_create ? 'Create Marketplace' : 'Edit Marketplace',
			breadcrumb_items: [{ label: 'Marketplace' }, { label: is_create ? 'Create Marketplace' : 'Edit Marketplace' }],
			loading: false,
			tableKey: 0,
		};
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
