<template>
	<div>
		<p-button label="CSV Upload" @click="openModal">
			<template #icon>
				<icon type="upload" size="20px" />
				<gutter size="10px" />
			</template>
		</p-button>
		<p-dialog v-model:visible="show_modal" class="modal" :modal="true" :dismissable-mask="true">
			<loader :show="loading" />
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<strong>DNC CSV Upload</strong>
				</div>
			</template>
			<div class="flex-flex-column">
				<p>The file must be one phone number without any formatting (10 digits) on each row</p>
				<div class="control-group">
					<div class="inner">
						<label for="type" class="control-label req">DNC Type:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="type"
									name="type"
									:disabled="isAccountUpload"
									:options="typeOptions"
									placeholder="Select Type"
									option-label="label"
									option-value="value"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label for="source" class="control-label req">Note:</label>
						<div class="controls">
							<div class="field">
								<p-text-area v-model="note" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<custom-upload
					:disabled="!isFormReady"
					choose-label="Upload CSV"
					accept="text/csv"
					folder="dnc-csv"
					@success="csvDNC"
				/>
			</template>
		</p-dialog>
	</div>
</template>
<script>
import { processDNCCsv } from '@GQL';
import customUpload from '@/components/forms/CustomUpload.vue';
import { useSessionStore } from '@/stores/session';
import { dnc_type_options } from '@/lib/Options';
import pTextArea from 'primevue/textarea';

export default {
	name: 'DNCCSVUploadModal',
	components: {
		customUpload,
		pTextArea,
	},
	props: {
		account_id: {
			type: String,
			default() {
				return '';
			},
		},
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			loading: false,
			rows: [],
			type: '',
			note: '',
			dnc_type_options,
			show_modal: false,
		};
	},
	computed: {
		isFormReady() {
			return this.note !== '' && this.type !== '';
		},
		typeOptions() {
			return this.dnc_type_options.filter((row) => {
				if (row.value === 'account') {
					return this.isAccountUpload;
				} else {
					return true;
				}
			});
		},
		isAccountUpload() {
			if (this.account_id !== '') {
				this.type = 'account';
			}
			return this.account_id !== '';
		},
	},
	methods: {
		openModal() {
			this.show_modal = true;
		},
		reset() {
			this.note = '';
			this.type = '';
		},
		async csvDNC(upload_results) {
			this.loading = true;
			try {
				if (upload_results.data.length === 1) {
					const file_details = upload_results.data[0];
					const params = {
						user_id: this.sessionStore.user.id,
						dnc_type: this.type,
						note: this.note,
						source: this.source,
						s3_key: file_details.key,
						s3_bucket: file_details.bucket,
					};

					if (this.type === 'account') {
						params.account_id = this.account_id;
					}

					const job_response = await processDNCCsv(params);

					this.$toast.add({
						severity: 'info',
						summary: 'The processing of the CSV has started',
						detail: 'After the background process completes, you will receive an email regarding the results.',
						life: 10000,
					});
					this.show_modal = false;
					this.reset();
				}
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Error in starting csv processing',
					detail: 'Contact Dev team',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
