import { $GQL } from '@GQL';

export async function getResetToken(email: string, base_url: string, signup?: boolean) {
	const query = `
        query GetResetToken($email: String!, $base_url: String!, $signup: Boolean) {
            getResetToken(email: $email, base_url: $base_url, signup: $signup) {
                token
            }
        }
    `;

	const first_login = signup ? true : false;

	const result = await $GQL.request(query, { email, base_url, first_login });
	return result.getResetToken;
}
