import { $GQL } from '@GQL/index';

export async function reportSourceDetailByDay(
	source_id: string,
	params: {
		date_range: [string, string];
		vertical_id: string[];
		product: string[];
		business_unit: string[];
		state: string[];
		bid_type: string[];
	},
	refresh: boolean
) {
	const query = `
        query ReportSourceDetailByDay($source_id: String!, $params: JSONObject!) {
            reportSourceDetailByDay(source_id: $source_id, params: $params)
        }
    `;

	const no_cache = refresh ? ['reportSourceDetailByDay'] : undefined;
	const results = await $GQL.request(query, { source_id, params, no_cache });
	return results.reportSourceDetailByDay;
}
