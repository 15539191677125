import { $GQL } from '@GQL/index';

export async function reportCampaignActivity(params: {
	campaign_id: string;
	pagination: {
		page: number;
		page_size: number;
	};
}) {
	const query = `
        query ReportCampaignActivity($params: JSONObject!) {
            reportCampaignActivity(params: $params) {
                rows
                row_count
            }
        }
    `;

	const results = await $GQL.request(query, {
		params,
	});
	return results.reportCampaignActivity;
}
