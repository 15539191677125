<template>
	<div id="email-template-detail" class="view-content">
		<div class="page-heading">
			<h1>Email Template: {{ email_template.name }}</h1>
			<breadcrumb :model="breadcrumb_items" />
		</div>
		<div class="page-content">
			<email-template-form :template="email_template" />
		</div>
	</div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es';
import { getEmailTemplateById } from '@GQL';
import EmailTemplateForm from './Form.vue';
import log from '@/lib/Log';

export default {
	name: 'EmailTemplateDetail',
	components: {
		EmailTemplateForm,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Email Templates', route: '/email-templates' }],
			email_template: {
				id: '',
				name: '',
				subject: '',
				from_name: '',
				from_email: '',
				reply_to_name: '',
				reply_to_email: '',
				cc: null,
				bcc: null,
				body: '',
				throttle: null,
				wrapper_id: null,
				asm_group_id: null,
				bypass_unsubscribes: null,
				pdf_name: null,
				pdf_content: null,
				tags: null,
				status: 'active',
			},
		};
	},
	async mounted() {
		if (!this.isNew) {
			try {
				const result = await getEmailTemplateById(this.$route.params.email_template_id);
				this.email_template = cloneDeep(result.emailTemplate);
			} catch (err) {
				log.error('err', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get template',
					detail: err.response.errors[0]?.message || err.message,
					life: 6000,
				});
			}
		} else {
			if (this.$route.query.duplicate) {
				const result = await getEmailTemplateById(this.$route.query.duplicate);
				this.email_template = cloneDeep(result.emailTemplate);
				this.email_template.id = null;
				this.email_template.name += ' (Copy)';
				this.breadcrumb_items.push({ label: this.email_template.name });
			} else {
				this.breadcrumb_items.push({ label: 'New Email Template' });
			}
		}
	},
	computed: {
		isNew() {
			return this.$route.meta.new;
		},
	},
};
</script>
