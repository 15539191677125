import { $GQL } from '@GQL/index';
import type { SubAccount } from '@nextgenleads/call-driver';

export async function insertSubAccount(
	data: Omit<SubAccount, 'id' | 'created_at'>
): Promise<{ id: string; provider_account_id: string }> {
	const mutation = `
        mutation InsertSubAccount($data: JSONObject!) {
            insertSubAccount(data: $data) {
                id
				provider_account_id
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertSubAccount;
}
