<template>
	<div id="record-user" class="view-content">
		<div class="page-heading">
			<h1>{{ pageTitle }}</h1>
			<breadcrumb :items="breadcrumbItems" />
		</div>
		<div class="content">
			<p-card>
				<template #content>
					<UserForm :user="user" :show-actions="true" />
				</template>
			</p-card>
		</div>
		<loader :show="loading" />
	</div>
</template>

<script lang="ts">
import UserForm from './Form.vue';
import { reactive } from 'vue';

export default {
	name: 'EditUser',
	components: {
		UserForm,
	},
	inject: ['mq'],
	data() {
		return {
			loading: false,
			user: {},
		};
	},
	computed: {
		breadcrumbItems() {
			if (this.$route.name.indexOf('Admin') > -1) {
				return [
					{ label: 'Admin Users', route: '/admin-users' },
					{ label: this.$route.params.id ? 'Edit Admin User' : 'Create Admin User' },
				];
			} else if (this.$route.name.indexOf('Marketplace') > -1) {
				return [
					{ label: 'Marketplace Users', route: '/marketplace-users' },
					{ label: this.$route.params.id ? 'Edit Marketplace User' : 'Create Marketplace User' },
				];
			}
			return [{ label: 'Users', route: '/users' }, { label: this.$route.params.id ? 'Edit User' : 'Create User' }];
		},
		pageTitle() {
			if (this.$route.params.user_id) {
				return 'Edit User';
			} else {
				return 'Create User';
			}
		},
		existingUser() {
			return !!this.$route.params.user_id;
		},
	},
	created() {
		if (this.$route.params.user_id) {
			this.user = reactive({
				id: this.$route.params.id,
			});
		}
	},
};
</script>

<style lang="less">
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

#record-user {
	.content {
		max-width: 800px;
	}
}
</style>
