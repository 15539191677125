<template>
	<div id="legacy-purchases" class="view-content">
		<div class="page-heading">
			<h1>Legacy Purchases</h1>
		</div>
		<div id="all-leads-list">
			<query-form v-model:query="query" :loading="loading" @apply="getPurchases" @abort="clearQuery">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Vertical:</div>
						<div class="value">
							{{ displaySelected(query.vertical_id, verticals) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Lead Type:</div>
						<div class="value">
							{{ displaySelected(query.auction_type, product_type_options) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Status:</div>
						<div class="value">
							{{ displaySelected(query.status, purchase_status_options) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">State:</div>
						<div class="value">
							{{ displaySelected(query.state, state_options) }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" />
					<p-button
						v-tooltip.top="'Generate as CSV Report'"
						icon="pi pi-download"
						aria-label="Legacy CSV Report"
						class="mr-2"
						@click="generateLegacyCSVReport"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range v-model="form.query.date_range" hide-labels show-time />
										</div>
									</div>
								</div>
							</div>
							<div class="control-group collapse">
								<div class="inner">
									<label class="control-label">Filter By Vertical:</label>
									<div class="controls">
										<div class="field">
											<div
												v-for="vertical of verticals"
												:key="vertical.value"
												class="p-checkbox-item flex align-items-center"
											>
												<p-checkbox
													v-model="query.vertical_id"
													:input-id="vertical.value"
													name="category"
													:value="vertical.value"
												/>
												<label :for="vertical.value">{{ vertical.label }}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</column>
						<gutter size="40px" />
						<column>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Filter By Product:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												id="product-type"
												v-model="form.query.auction_type"
												option-label="label"
												option-value="value"
												:options="product_type_options"
												placeholder="Select product type"
												class="state-select w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="control-group">
								<div class="inner">
									<label class="control-label">States:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="form.query.state"
												filter
												filter-placeholder="Filter States"
												:options="state_options"
												option-label="label"
												option-value="value"
												placeholder="Select States"
												class="state-select w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="control-group">
								<div class="inner">
									<label class="control-label">Purchase Status:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="form.query.status"
												filter
												filter-placeholder="Filter Purchase Status"
												:options="purchase_status_options"
												option-label="label"
												option-value="value"
												placeholder="Select States"
												class="state-select w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<gutter size="20px" />
			<div class="card padded">
				<p-data-table :loading="loading" :rows="page_size" scrollable :value="leads">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="purchased" header="Purchased">
						<template #body="lead">
							<div class="purchased">
								{{ formatDate(new Date(lead.data.created), 'YYYY-MM-DD &bull; h:mm:ssa') }}
							</div>
							<div class="sub-value">
								{{ timeAgo(lead.data.created) }}
							</div>
						</template>
					</p-column>
					<p-column class="align-center" field="vertical_id" header="Vertical">
						<template #body="lead">
							<vertical-icon
								:key="componentKey"
								:vertical-id="lead.data.vertical_id"
								size="30px"
								style="margin: auto"
							/>
						</template>
					</p-column>
					<p-column field="name" data="name" header="Lead Details">
						<template #body="lead">
							<div v-if="['complete', 'return_pending', 'return_rejected'].includes(lead.data.status)">
								<strong
									><a @click.prevent="handleLeadModal(lead.data)"
										>{{ lead.data.name.first }} {{ lead.data.name.last }}</a
									></strong
								><br />
								<div class="sub-value">{{ lead.data.lead_id }} &bull; {{ phoneFormat(lead.data.contact.phone) }}</div>
							</div>
							<div v-else>
								<strong>Lead Returned/Canceled</strong>
								<div class="sub-value">
									{{ lead.data.lead_id }} &bull; {{ `(XXX) XXX - ${lead.data.contact.phone.substring(7)}` }}
								</div>
							</div>
						</template>
					</p-column>
					<p-column class="align-center" field="product" header="Lead Type">
						<template #body="lead">
							<type-icon :key="componentKey" :type="lead.data.auction_type" size="30px" style="margin: auto" />
						</template>
					</p-column>
					<p-column field="lead_state" header="Location">
						<template #body="lead">
							{{ lead.data.lead_state }}
							<div class="sub">{{ lead.data.contact.address.zip }}</div>
						</template>
					</p-column>
					<p-column field="source_id" header="Source">
						<template #body="lead">
							{{ lead.data.source_hash }}
							<div class="sub">{{ lead.data.sub_id_hash }}</div>
						</template>
					</p-column>
					<p-column field="cost" header="Cost">
						<template #body="lead">
							{{ currency(lead.data.full_price) }}
						</template>
					</p-column>
					<p-column field="disposition" header="Disposition">
						<template #body="lead">
							{{ capitalize(lead.data.disposition) }}
						</template>
					</p-column>
					<p-column field="status" header="Status">
						<template #body="lead">
							{{ capitalize(lead.data.status) }}
						</template>
					</p-column>
				</p-data-table>
				<gutter size="20px" />
				<p-paginator :rows="page_size" :total-records="row_count" @page="handlePageChange" />
			</div>
		</div>
		<p-dialog
			v-model:visible="show_lead_modal"
			class="lead-details-modal"
			:modal="true"
			:dismissable-mask="true"
			:pt="{
				root: { class: 'w-12 sm:w-9 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="account" size="24px" style="color: var(--color-b)" />
					<strong>Lead Details</strong>
				</div>
			</template>
			<div class="lead-details">
				<p-card class="lead-summary max-w-30rem ml-auto mr-auto">
					<template #title>
						<div class="flex">
							<vertical-icon :vertical-id="local_lead.vertical_id" si1e="32px" class="mr-3" />
							<div class="flex flex-column gap-1">
								<div class="name">{{ local_lead.name.first }} {{ local_lead.name.last }}</div>
								<div class="id">
									{{ local_lead.lead_id }}
								</div>
							</div>
						</div>
					</template>
					<template #content>
						<ul class="table-list">
							<li>
								<div class="label">Date</div>
								<div class="value">
									{{ formatDate(local_lead.created, 'ddd, MMMM D, YYYY [at] h:mm:ssA') || '' }}<br />
									<div class="sub-value">{{ timeAgo(local_lead.created) }}</div>
								</div>
							</li>
							<li>
								<div class="label">Vertical</div>
								<div class="value">
									{{ verticalName(local_lead.vertical_id) }}
								</div>
							</li>
							<li>
								<div class="label">Phone</div>
								<div class="value">
									{{ phoneFormat(local_lead.contact.phone) }}
								</div>
							</li>
							<li>
								<div class="label">Cost</div>
								<div class="value">
									{{ currency(local_lead.full_price) }}
								</div>
							</li>
						</ul>
					</template>
				</p-card>
				<gutter size="20px" />
				<p-card class="lead-information max-w-30rem ml-auto mr-auto">
					<template #content>
						<strong style="color: var(--color-b)">Contact Information</strong>
						<ul class="table-list mt-2">
							<li>
								<div class="label">First Name</div>
								<div class="value">
									{{ local_lead.name.first }}
								</div>
							</li>
							<li>
								<div class="label">Last Name</div>
								<div class="value">
									{{ local_lead.name.last }}
								</div>
							</li>
							<li>
								<div class="label">Phone</div>
								<div class="value">
									{{ phoneFormat(local_lead.contact.phone) }}
								</div>
							</li>
							<li>
								<div class="label">Email</div>
								<div class="value">
									{{ local_lead.contact.email }}
								</div>
							</li>
							<li>
								<div class="label">Address</div>
								<div class="value">
									{{ local_lead.contact.address.street1 }} {{ local_lead.contact.address.street2 }}
								</div>
							</li>
							<li>
								<div class="label">City</div>
								<div class="value">
									{{ local_lead.contact.address.city }}
								</div>
							</li>
							<li>
								<div class="label">County</div>
								<div class="value">
									{{ local_lead.contact.address.county }}
								</div>
							</li>
							<li>
								<div class="label">State</div>
								<div class="value">
									{{ local_lead.contact.address.state }}
								</div>
							</li>
							<li>
								<div class="label">Zipcode</div>
								<div class="value">
									{{ local_lead.contact.address.zip }}
								</div>
							</li>
						</ul>
						<gutter size="20px" />
						<strong style="color: var(--color-b)">Vertical Information</strong>
						<ul class="table-list mt-2">
							<li>
								<div class="label">Date of Birth</div>
								<div class="value">
									{{ formatDate(new Date(local_lead.data.dob), 'MM/DD/YYYY') }}
								</div>
							</li>
							<li>
								<div class="label">Age</div>
								<div class="value">
									{{ dobToAge(local_lead.data.dob) }}
								</div>
							</li>
							<li>
								<div class="label">Gender</div>
								<div class="value">
									{{ title(local_lead.data.gender) || 'N/A' }}
								</div>
							</li>
							<li>
								<div class="label">Height</div>
								<div class="value">
									{{ local_lead.data.height ? heightFormat(local_lead.data.height) : 'N/A' }}
								</div>
							</li>
							<li>
								<div class="label">Weight</div>
								<div class="value">{{ local_lead.data.weight ? `${local_lead.data.weight} Lbs` : 'N/A' }}</div>
							</li>
							<li>
								<div class="label">Household Size</div>
								<div class="value">
									{{ local_lead.data.household_size || 'N/A' }}
								</div>
							</li>
							<li>
								<div class="label">Household Income</div>
								<div class="value">
									{{ local_lead.data.income ? currency(local_lead.data.income) : 'N/A' }}
								</div>
							</li>
							<li>
								<div class="label">Medical Conditions</div>
								<div class="value">
									{{
										local_lead.data.medical_conditions ? formatConditions(local_lead.data.medical_conditions) : 'N/A'
									}}
								</div>
							</li>
							<li>
								<div class="label">Pregnant/Expecting</div>
								<div class="value">
									{{ local_lead.data.pregnant ? 'Yes' : 'No' }}
								</div>
							</li>
							<li>
								<div class="label">Tobacco Use</div>
								<div class="value">
									{{ local_lead.data.tobacco_user ? 'Yes' : 'No' }}
								</div>
							</li>
							<li>
								<div class="label">Timeframe</div>
								<div class="value">
									{{ local_lead.data.timeframe || 'N/A' }}
								</div>
							</li>
						</ul>
						<gutter size="20px" />
						<strong style="color: var(--color-b)">Geographical Information</strong>
						<ul class="table-list mt-2">
							<li>
								<div class="label">County</div>
								<div class="value">
									{{ local_lead.contact.address.county }}
								</div>
							</li>
							<li>
								<div class="label">City</div>
								<div class="value">
									{{ local_lead.contact.address.city }}
								</div>
							</li>
							<li>
								<div class="label">State</div>
								<div class="value">
									{{ local_lead.contact.address.state }}
								</div>
							</li>
							<li>
								<div class="label">Zipcode</div>
								<div class="value">
									{{ local_lead.contact.address.zip }}
								</div>
							</li>
						</ul>
						<gutter size="20px" />
						<strong style="color: var(--color-b)">Meta Information</strong>
						<ul class="table-list mt-2">
							<li>
								<div class="label">IP Address</div>
								<div class="value">
									{{ local_lead.meta.ip_address }}
								</div>
							</li>
						</ul>
					</template>
				</p-card>
			</div>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import { get, startCase, map } from 'lodash-es';
import { endOfDay, startOfDay } from 'date-fns';
import {
	formatDate,
	displaySelected,
	dobToAge,
	currency,
	verticalName,
	phoneFormat,
	capitalize,
	timeAgo,
	heightFormat,
	title,
} from '@/lib/Filters';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import STATES from '@/lib/Data/states.json';
import { getV3Purchases } from '@GQL';
import { runJob } from '@GQL';

export default {
	name: 'AllLegacyPurchasesList',
	components: {
		dateRange,
		queryForm,
		verticalIcon,
		typeIcon,
	},
	data() {
		return {
			componentKey: 0,
			loading: false,
			query: {
				date_range: [startOfDay(new Date()), endOfDay(new Date())],
				vertical_id: [],
				auction_type: ['data', 'call', 'live_transfer'],
				state: [],
				status: ['complete', 'return_pending', 'return_rejected'],
				page: 1,
			},
			leads: [],
			row_count: 0,
			page_size: 0,
			verticals: this.$root.appStore.verticalOptions,
			state_options: STATES,
			product_type_options: [
				{ label: 'Data', value: 'data' },
				{ label: 'Call', value: 'call' },
				{ label: 'Live Transfer', value: 'live_transfer' },
			],
			purchase_status_options: [
				{ label: 'Complete', value: 'complete' },
				{ label: 'Return Rejected', value: 'return_rejected' },
				{ label: 'Return Pending', value: 'return_pending' },
			],
			show_lead_modal: false,
			local_lead: {} as Record<string, string>,
		};
	},
	computed: {
		v3CompanyId() {
			return this.$root.sessionStore.wasMigratedFromV3;
		},
	},
	async created() {
		await this.getPurchases();
	},
	methods: {
		currency,
		formatDate,
		displaySelected,
		verticalName,
		startCase,
		phoneFormat,
		timeAgo,
		map,
		capitalize,
		dobToAge,
		heightFormat,
		title,
		clearQuery() {
			this.loading = false;
		},
		handlePageChange(data) {
			// data has a page field
			this.query.page = data.page;
			return this.getPurchases();
		},
		async getPurchases() {
			if (this.v3CompanyId) {
				this.loading = true;
				try {
					// might need to translate date_range
					const params = {
						...this.query,
						date_range: [
							this.query.date_range[0].toISOString().slice(0, 10),
							this.query.date_range[1].toISOString().slice(0, 10),
						],
						company_id: this.v3CompanyId,
					};
					const response = await getV3Purchases(params);
					this.leads = get(response, 'result', []);
					this.page_size = get(response, 'pagination.page_size', 100);
					this.row_count = get(response, 'pagination.record_count', 0);
					this.componentKey += 1;
					await this.$nextTick();
				} catch (e) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to get V3 Purchases',
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					summary: 'This account was not migrated from V3',
				});
			}
		},
		handleLeadModal(row) {
			this.show_lead_modal = true;
			this.local_lead = row;
		},
		formatConditions(medical_conditions) {
			const map = {
				aids_hiv: 'HIV/AIDS',
				cancer: 'Cancer',
				diabetes: 'Diabetes',
				heart_attack: 'Heart Attack',
				pulmonary_disease: 'Pulmonary Disease',
				stroke: 'Stroke',
			};

			const conditions_array = [];

			Object.entries(medical_conditions).forEach(([key, value]) => {
				if (value) {
					conditions_array.push(key);
				}
			});

			let conditions = '';

			conditions_array.forEach((condition, index, arr) => {
				if (index === arr.length - 1) {
					conditions += map[condition];
				} else {
					conditions += map[condition] + ', ';
				}
			});

			if (!conditions) {
				conditions = 'None';
			}

			return conditions;
		},
		async generateLegacyCSVReport() {
			try {
				const params = {
					...this.query,
					date_range: [
						this.query.date_range[0].toISOString().slice(0, 10),
						this.query.date_range[1].toISOString().slice(0, 10),
					],
					company_id: this.v3CompanyId,
				};

				const result = await runJob({
					script_name: 'LegacyCSVReport',
					script_type: 'jobs',
					ran_by: this.$root.sessionStore.user.id,
					parameters: {
						user_id: this.$root.sessionStore.user.id,
						v3_purchase_req: params,
					},
				});
				if (result) {
					this.$toast.add({
						severity: 'info',
						summary: 'Generating CSV Report',
						detail: 'Please check your notifications or email for the download link.',
						life: 3000,
					});
				}
			} catch (err) {
				console.error('CSV Report Error', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Error generating CSV Report',
					life: 3000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}

.name {
	font-size: var(--font-size-h4);
}

.id {
	color: var(--gray-60);
	font-size: var(--font-size-sm);
	font-weight: normal;
}
</style>
