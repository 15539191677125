/* eslint-disable vue/multi-word-component-names */
import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { Vue3Mq } from 'vue3-mq';
import primevue from 'primevue/config';
import badge from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import focustrap from 'primevue/focustrap';
import toast from 'primevue/toastservice';
import tooltip from 'primevue/tooltip';
import { PageTitleMixin } from '@/lib/Mixins/PageTitle';
import { MethodsMixin } from '@/lib/Mixins/Methods';
import { createGtm } from '@gtm-support/vue-gtm';

// highlight.js plugin for code blocks
import hljs from 'highlight.js/lib/common';
import hljsVuePlugin from '@highlightjs/vue-plugin';
import javascript from 'highlight.js/lib/languages/javascript';
import json from 'highlight.js/lib/languages/json';
import typescript from 'highlight.js/lib/languages/typescript';
hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('json', json);
hljs.registerLanguage('typescript', typescript);

import App from './App.vue';
import router from './router';
import components from '@/lib/GlobalComponents';

const app = createApp(App);

const pinia = createPinia();
pinia.use(({ store }) => {
	store.router = markRaw(router);
});
pinia.use(piniaPluginPersistedstate);

app
	.use(pinia)
	.use(Vue3Mq, { preset: 'devices' })
	.use(primevue)
	.use(router)
	.use(ConfirmationService)
	.use(toast)
	.use(components)
	.use(hljsVuePlugin)
	.use(
		createGtm({
			id: 'GTM-PHPKXZ',
			vueRouter: router,
		})
	)
	.mixin(PageTitleMixin)
	.mixin(MethodsMixin)
	.mixin({
		data() {
			return {
				ui_type: import.meta.env.VITE_UI,
			};
		},
	})
	.directive('badge', badge)
	.directive('focustrap', focustrap)
	.directive('focus', {
		mounted(el) {
			if (el.tagName === 'INPUT') {
				el.focus();
			} else {
				const input = el.querySelector('input');
				if (input) {
					input.focus();
				}
			}
		},
	})
	.directive('tooltip', tooltip);

app.mount('#app');
