import { $GQL } from '@GQL/index';
import type { Integration } from '@nextgenleads/pingpost-driver';

export async function updateIntegration(id: string, data: Partial<Integration>): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateIntegration($id: ID!, $data: JSONObject!) {
            updateIntegration(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateIntegration;
}
