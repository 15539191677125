import { $GQL } from '@GQL';
import type { Conversion } from '@nextgenleads/marketplace-driver';

export async function updateConversion(purchase_id: string, data: Partial<Conversion>) {
	const mut = `
        mutation UpdateConversion($purchase_id: ID!, $data: JSONObject!) {
            updateConversion(purchase_id: $purchase_id, data: $data) {
                purchase_id
                lead_id
                account_id
                disposition
                source
                created_by
                modified_at
            }
        }
    `;
	const result = await $GQL.request(mut, { purchase_id, data });
	return result.updateConversion;
}
