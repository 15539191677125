import { $GQL } from '@GQL/index';
import type { AccountOffer } from '@nextgenleads/marketplace-driver';
import type { QueryFilters } from '@nextgenleads/db';

export async function updateAccountOffer(id: string, data: Partial<AccountOffer>) {
	const mutation = `
        mutation UpdateAccountOffer($id: ID!, $data: JSONObject!) {
            updateAccountOffer(id: $id, data: $data) {
                offer_id
                account_id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateAccountOffer;
}
