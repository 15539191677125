import { $GQL } from '@GQL';
import type { Purchase } from '@nextgenleads/broker-driver';

export interface CreateCanceledPurchaseParams {
	mpid: string;
	lead_id: string;
	campaign_id: string;
	auction_log_id: string;
	price: number;
	data: {
		agent_id: string; // this is the am that created the LT purchase
	};
}
export async function createCanceledPurchase(params: CreateCanceledPurchaseParams): Promise<Partial<Purchase>> {
	const mutation = `
        mutation CreateCanceledPurchase($params: JSONObject!){
            createCanceledPurchase(params: $params) {
                id
                status
                created_at
            }
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.createCanceledPurchase;
}
