import BlockedNumbersList from '@/views/Calls/BlockedNumbers/List/Page.vue';
import PhoneNumbersList from '@/views/Calls/PhoneNumbers/List/Page.vue';
import PhoneNumberForm from '@/views/Calls/PhoneNumbers/Record/Page.vue';
import OutboundRoutesList from '@/views/Calls/OutboundRoutes/List/Page.vue';
import OutboundRouteForm from '@/views/Calls/OutboundRoutes/Record/Page.vue';
import SubAccountsList from '@/views/Calls/SubAccounts/List/Page.vue';
import SubAccountForm from '@/views/Calls/SubAccounts/Record/Page.vue';
import RouteTemplatesList from '@/views/Calls/RouteTemplates/List/Page.vue';
import RouteTemplateForm from '@/views/Calls/RouteTemplates/Record/Page.vue';

export default [
	{
		name: 'BlockedNumbersList',
		path: '/blocked-numbers',
		component: BlockedNumbersList,
		meta: {
			title: 'Blocked Numbers',
			permissions: 'calls',
		},
	},
	{
		name: 'PhoneNumbersList',
		path: '/phone-numbers',
		component: PhoneNumbersList,
		meta: {
			title: 'Phone Numbers',
			permissions: 'calls',
		},
	},
	{
		name: 'EditPhoneNumber',
		path: '/phone-numbers/:phone_number_id',
		component: PhoneNumberForm,
		meta: {
			title: 'Phone Number',
			permissions: 'calls',
		},
	},
	{
		name: 'OutboundRoutesList',
		path: '/outbound-routes',
		component: OutboundRoutesList,
		meta: {
			title: 'Outbound Routes',
			permissions: 'calls',
		},
	},
	{
		name: 'CreateOutboundRoute',
		path: '/outbound-routes/new',
		component: OutboundRouteForm,
		meta: {
			new: true,
			title: 'Outbound Route',
			permissions: 'calls',
		},
	},
	{
		name: 'EditOutboundRoute',
		path: '/outbound-routes/:outbound_route_id',
		component: OutboundRouteForm,
		meta: {
			title: 'Outbound Route',
			permissions: 'calls',
		},
	},
	{
		name: 'SubAccountsList',
		path: '/sub-accounts',
		component: SubAccountsList,
		meta: {
			title: 'Sub Accounts',
			permissions: 'calls',
		},
	},
	{
		name: 'CreateSubAccount',
		path: '/sub-accounts/new',
		component: SubAccountForm,
		meta: {
			new: true,
			title: 'Sub Account',
			permissions: 'calls',
		},
	},
	{
		name: 'EditSubAccount',
		path: '/sub-accounts/:sub_account_id',
		component: SubAccountForm,
		meta: {
			title: 'Sub Account',
			permissions: 'calls',
		},
	},
	{
		name: 'RouteTemplatesList',
		path: '/route-templates',
		component: RouteTemplatesList,
		meta: {
			title: 'Route Templates',
			permissions: 'calls',
		},
	},
	{
		name: 'CreateRouteTemplate',
		path: '/route-templates/new',
		component: RouteTemplateForm,
		meta: {
			new: true,
			title: 'Route Templates',
			permissions: 'calls',
		},
	},
	{
		name: 'EditRouteTemplate',
		path: '/route-templates/:route_template_id',
		component: RouteTemplateForm,
		meta: {
			title: 'Route Templates',
			permissions: 'calls',
		},
	},
];
