import VendorsListView from '@/views/Vendors/List/Page.vue';
import VendorRecordFormPage from '@/views/Vendors/Record/Page.vue';

export default [
	{
		path: 'vendors',
		name: 'VendorsList',
		component: VendorsListView,
		meta: {
			parent_menu: 'Source Service',
			title: 'Vendors',
			permissions: 'admin.vendors',
		},
	},
	{
		path: 'vendors/new',
		name: 'CreateVendor',
		component: VendorRecordFormPage,
		meta: {
			new: true,
			parent_menu: 'Source Service',
			title: 'New Vendor',
			permissions: 'admin.vendors',
		},
	},
	{
		path: 'vendors/:vendor_id',
		name: 'EditVendor',
		component: VendorRecordFormPage,
		meta: {
			parent_menu: 'Source Service',
			title: 'Edit Vendor',
			permissions: 'admin.vendors',
		},
	},
];
