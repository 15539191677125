import { $GQL } from '@GQL/index';
import type { AutoFundConfig } from '@nextgenleads/banker-driver';

export async function getAutoFundConfigById(account_id: string): Promise<AutoFundConfig | null> {
	const query = `
        query GetAutoFundConfigById($account_id: String!) {
            autoFundConfig(account_id: $account_id) {
                account_id
                payment_method_id
                trigger_amount
                funding_amount
                status
                modified_at
                modified_by
            }
        }
    `;

	const result = await $GQL.request(query, { account_id });
	return result.autoFundConfig;
}
