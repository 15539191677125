<template>
	<div class="voice-route-step">
		<div class="header move-handle">
			<div class="header-left" @click="expanded = !expanded">
				<div class="flex align-items-center gap-10">
					<icon type="dots-vertical" size="24px" />
					<strong>Dial</strong> <span class="sub-value">Make an outbound call to a phone number</span>
				</div>
				<div>
					<div v-if="!expanded" class="expand-icon">
						<icon type="arrow-expand" size="20px" />
					</div>
					<div v-if="expanded" class="expand-icon">
						<icon type="arrow-collapse" size="20px" />
					</div>
				</div>
			</div>
			<div class="header-right">
				<div class="delete-icon">
					<icon type="delete" size="20px" @click.stop="deleteStep" />
				</div>
			</div>
		</div>
		<div v-if="expanded" class="content">
			<div class="step-options">
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field s">
								<label class="control-label">Phone Number:</label>
								<p-input-text v-model="localRouteStep.params.phone" />
							</div>
							<div class="field">
								<label class="control-label">Dial Route:</label>
								<p-dropdown
									v-model="localRouteStep.params.dial_route"
									:options="voiceRouteOptions"
									option-label="label"
									option-value="value"
									placeholder="Select Dial Route"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Status Endpoint:</label>
						<div class="controls gap-20">
							<div class="field">
								<p-input-text v-model="localRouteStep.params.status_url" />
							</div>
							<div class="field fit checkbox">
								<label class="checkbox">
									<div class="flex align-items-center">
										<p-checkbox input-id="dial_record" v-model="localRouteStep.params.record" binary />
										<div>Record call</div>
									</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { computedAsync } from '@vueuse/core';
import { getRouteTemplates } from '@GQL';

export default {
	name: 'VoiceRouteDialHandler',
	emits: ['update', 'delete'],
	props: {
		routeStep: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const voiceRouteOptions = computedAsync(async () => {
			const route_templates = await getRouteTemplates();
			let route_template_options = [];
			if (route_templates) {
				route_template_options = route_templates.filter((template) => {
					return template.type === 'voice';
				});

				route_template_options = route_template_options.map((template) => {
					return {
						label: template.name,
						value: template.id,
					};
				});
			}
			return [...route_template_options];
		});

		return {
			voiceRouteOptions,
		};
	},
	data() {
		return {
			default_config: {
				handler: 'Dial',
				params: {
					phone: '',
					buyer_route: null,
					status_url: 'https://call.nextgenleads.app/twilio/status',
					record: false,
				},
			},
			expanded: false,
		};
	},
	computed: {
		localRouteStep: {
			get() {
				return this.routeStep;
			},
			set(new_value) {
				this.$emit('update', new_value);
			},
		},
	},
	methods: {
		deleteStep(event) {
			this.$confirm.require({
				target: event.currentTarget,
				group: 'voice_route_builder',
				message: 'Are you sure you want to delete this route step?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: 'Delete Route Step',
				rejectLabel: 'Cancel',
				accept: async () => {
					this.$emit('delete');
				},
			});
		},
	},
};
</script>
