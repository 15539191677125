<template>
	<div id="auctions" class="view-content">
		<div class="page-heading">
			<h1>{{ auctionType }}s</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-button icon="pi pi-plus" :label="`New ${auctionType}`" @click.prevent="openTemplateModal()" />
		<gutter size="20px" />
		<div class="card padded">
			<AuctionsList />
		</div>
		<template-modal ref="template_modal" />
	</div>
</template>

<script lang="ts">
import { useMarketplaceStore } from '@/stores/marketplace';
import templateModal from './NewAuctionConfigModal.vue';
import AuctionsList from './List.vue';
import { mapStores } from 'pinia';

export default {
	name: 'AuctionsListPage',
	components: {
		AuctionsList,
		templateModal,
	},
	data() {
		return {
			breadcrumb_items: [],
		};
	},
	computed: {
		...mapStores(useMarketplaceStore),
		auctionType() {
			if (!this.marketplaceStore.mpid) {
				return 'Auction Config Template';
			}
			return 'Auction Config';
		},
	},
	methods: {
		openTemplateModal() {
			this.$refs.template_modal.openModal();
		},
	},
	mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Auction Configs',
				},
			];
		} else {
			this.breadcrumb_items = [{ label: 'Auction Configs' }];
		}
	},
};
</script>
