<template>
	<span :class="['material-symbols-outlined', { spinning: loading }]" :style="{ 'font-size': size }">{{
		iconName
	}}</span>
</template>

<script setup>
import { computed } from 'vue';
import { snakeCase } from 'lodash-es';

const props = defineProps({
	loading: {
		type: Boolean,
	},
	size: {
		type: String,
		default: 'auto',
	},
	type: String,
});

const iconName = computed(() => {
	if (props.loading) {
		return 'refresh';
	}
	return snakeCase(props.type);
});
</script>

<style scoped lang="less">
@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.spinning {
	animation-duration: 500ms;
	animation-iteration-count: infinite;
	animation-name: spin;
	animation-timing-function: linear;
}

.rotate {
	transform: rotate(90deg);
}

.material-symbols-outlined {
	direction: ltr;
	display: inline-block;
	font-family: 'Material Symbols Outlined';
	font-size: 24px;
	font-style: normal;
	font-weight: normal;
	letter-spacing: normal;
	line-height: 1;
	text-transform: none;
	white-space: nowrap;
	word-wrap: normal;
}
</style>
