<template>
	<p-picklist v-model="list_items" :list-style="`height: ${height}`" dataKey="value">
		<template #sourceheader>{{ leftHeading }}</template>
		<template #targetheader>{{ rightHeading }}</template>
		<template #item="option">
			{{ option.item.label }}
		</template>
	</p-picklist>
</template>

<script>
import { isEqual } from 'lodash-es';
import pPicklist from 'primevue/picklist';

export default {
	name: 'PicklistWrapper',
	components: {
		pPicklist,
	},
	emits: ['update:modelValue'],
	props: {
		leftHeading: {
			type: String,
			default: 'Selected',
		},
		rightHeading: {
			type: String,
			default: 'Available',
		},
		options: {
			type: Array,
			required: true,
		},
		modelValue: {
			type: Array,
			required: true,
		},
		height: {
			type: String,
			default: '280px',
		},
	},
	data() {
		return {
			list_items: [[], []],
		};
	},
	watch: {
		modelValue: {
			handler(new_value) {
				const selected_values = this.list_items[0].map((item) => {
					return item.value;
				});

				if (!isEqual(new_value, selected_values)) {
					// Add selected items back into available items
					const all_available_items = this.list_items[1].concat(this.list_items[0]);

					// Update the selected items
					const new_selected_items = new_value.map((value) => {
						return this.options.find((option) => {
							return option.value === value;
						});
					});

					// Update the available items
					const new_available_items = all_available_items.filter((item) => {
						return !new_value.includes(item.value);
					});

					this.list_items = [new_selected_items, new_available_items];
				}
			},
			immediate: true,
			deep: true,
		},
		options: {
			handler(new_value, old_value) {
				if (!isEqual(new_value, old_value)) {
					// Remove selected items that are not in the options
					const new_selected_items = this.list_items[0].filter((item) => {
						const existing_option = new_value.find((option) => {
							return option.value === item.value;
						});
						return existing_option;
					});

					// Filter old selected items
					const new_available_items = this.list_items[1].filter((item) => {
						const existing_option = new_value.find((option) => {
							return option.value === item.value;
						});
						return existing_option;
					});

					// Get new items that were missing
					const all_items = new_selected_items.concat(new_available_items);
					const new_options = new_value.filter((option) => {
						const existing_option = all_items.find((item) => {
							return option.value === item.value;
						});
						return !existing_option;
					});

					// Set the new value
					this.list_items = [new_selected_items, new_available_items.concat(new_options)];
				}
			},
			immediate: true,
			deep: true,
		},
		list_items: {
			handler(new_value) {
				const selected = new_value[0].map((item) => {
					return item.value;
				});

				// Push the updated values to the model
				if (!isEqual(this.modelValue, selected)) {
					this.$emit('update:modelValue', selected);
				}
			},
			deep: true,
		},
	},
	mounted() {
		// Filter the options to get the selected items
		const selected_items = this.modelValue.map((value) => {
			return this.options.find((option) => {
				return option.value === value;
			});
		});

		// Remove selected items from the options
		const available_items = this.options.filter((option) => {
			return !this.modelValue.includes(option.value);
		});

		this.list_items = [selected_items, available_items];
	},
};
</script>
