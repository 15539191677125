import { $GQL } from '@GQL/index';
import type { PhoneNumber } from '@nextgenleads/call-driver';

export async function updatePhoneNumber(id: string, data: PhoneNumber): Promise<{ id: string }> {
	const mutation = `
        mutation UpdatePhoneNumber($id: ID!, $data: JSONObject!) {
            updatePhoneNumber(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updatePhoneNumber;
}
