import { $GQL } from '@GQL/index';
import { type Notification } from '@nextgenleads/marketplace-driver';

export async function insertNotification(data: Omit<Notification, 'id' | 'created_at'>): Promise<Notification> {
	const mut = `
        mutation Mutation($data: JSONObject!) {
            insertNotification(data: $data) {
                expires
                filters
                message
                modified_at
                status
                tags
                type
                id
                created_by
                created_at
            }
        }
    `;

	const results = await $GQL.request(mut, { data });
	return results.notification;
}
