import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function reportAccountPLPurchases(
	params: { date_range: [string, string]; filters: QueryFilters; account_id: string; page?: number; parent?: boolean },
	refresh?: boolean
) {
	const query = `
        query ReportAccountPLPurchases($params: JSONObject!) {
            reportAccountPLPurchases(params: $params) {
				metrics
				rows
				row_count
			}
        }
    `;

	const no_cache = refresh ? ['reportAccountPLPurchases'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportAccountPLPurchases;
}
