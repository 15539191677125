import { $GQL } from '@GQL';
import type { QueryFilters } from '@nextgenleads/db';

export async function bulkAccountReassignment(params: {
	filters: QueryFilters;
	new_users: string[];
	dry_run: boolean;
}): Promise<unknown> {
	const mutation = `
        mutation BulkReassignAccounts($params: JSONObject!){
            bulkReassignAccounts(params: $params)
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.bulkReassignAccounts;
}
