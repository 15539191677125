import { $GQL } from '@GQL/index';
import type { TransferFundsParams, TransferFundsRes } from '@nextgenleads/banker-driver';

export async function transferFunds(data: TransferFundsParams[], description?: string): Promise<TransferFundsRes[]> {
	const mutation = `
        mutation TransferFunds($data: [JSONObject]!, $description: String) {
            transferFunds(data: $data, description: $description) {
				sender_current_balance
                receiver_current_balance
            }
        }
    `;

	const result = await $GQL.request(mutation, { data, description });
	return result.transferFunds;
}
