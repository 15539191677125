import QueueListPage from '@/views/Queues/List/Page.vue';
import QueueFormPage from '@/views/Queues/Record/Page.vue';
import CaptureConfigsListPage from '@/views/CaptureConfigs/List/Page.vue';
import CaptureConfigsFormPage from '@/views/CaptureConfigs/Record/Page.vue';
import VendorSettingsPage from '@/views/Vendors/Settings/Page.vue';
import LeadDetailPage from '@/views/Leads/Page.vue';

export default [
	{
		path: 'capture-configs',
		name: 'CaptureConfigsList',
		component: CaptureConfigsListPage,
		meta: {
			parent_menu: 'Lead Service',
			title: 'Lead Capture Configs',
			permissions: 'admin.capture_configs',
		},
	},
	{
		path: 'capture-configs/new',
		name: 'CreateCaptureConfig',
		component: CaptureConfigsFormPage,
		meta: {
			new: true,
			parent_menu: 'Lead Service',
			title: 'New Lead Capture Config',
			permissions: 'admin.capture_configs',
		},
	},
	{
		path: 'capture-configs/:capture_config_id',
		name: 'EditCaptureConfig',
		component: CaptureConfigsFormPage,
		meta: {
			parent_menu: 'Lead Service',
			title: 'Edit Lead Capture Config',
			permissions: 'admin.capture_configs',
		},
	},
	{
		path: 'queues',
		name: 'QueuesList',
		component: QueueListPage,
		meta: {
			parent_menu: 'Lead Service',
			title: 'Queues',
			permissions: 'admin.queues',
		},
	},
	{
		path: 'queues/new',
		name: 'CreateQueue',
		component: QueueFormPage,
		meta: {
			new: true,
			parent_menu: 'Lead Service',
			title: 'New Queue',
			permissions: 'admin.queues',
		},
	},
	{
		path: 'queues/:queue_id',
		name: 'EditQueue',
		component: QueueFormPage,
		meta: {
			parent_menu: 'Lead Service',
			title: 'Edit Queue',
			permissions: 'admin.queues',
		},
	},
	{
		path: 'vendor-settings',
		name: 'VendorSettings',
		component: VendorSettingsPage,
		meta: {
			parent_menu: 'Lead Capture',
			title: 'Vendor Settings',
			permissions: 'vendor_settings',
		},
	},
];
