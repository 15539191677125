<template>
	<div>
		<p-button label="Batch Upload" @click="openModal" :disabled="isDisabled">
			<template #icon>
				<icon type="upload" size="20px" />
				<gutter size="10px" />
			</template>
		</p-button>

		<p-dialog
			v-model:visible="show_modal"
			class="modal"
			:modal="true"
			:dismissable-mask="true"
			:style="{ width: '40vw' }"
		>
			<loader :show="loading" />
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<strong>Transfer CSV Upload</strong>
				</div>
			</template>
			<div v-if="step === 'upload'">
				<p>You can upload a CSV file or a Excel (XLSX) file with a single tab.</p>
				<p><strong> The file must have the following columns: account_id and amount. </strong></p>
				<p>
					For the best results the "amount" columns should be a number. If there's a leading "$" on the amounts column,
					the uploader will try it's best to extract the number.
				</p>
				<a href="https://ngl-uploads.s3.us-west-2.amazonaws.com/files/bulk_transfers.csv" target="_blank"
					>Download the CSV template here</a
				>
			</div>
			<div v-else>
				<p-data-table ref="dt" :loading="loading" scrollable :value="parsed_data">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="label" header="Account">
						<template #body="txn">
							{{ txn.data.label }}
						</template>
					</p-column>

					<p-column field="amount" header="Amount">
						<template #body="txn">
							{{ currency(txn.data.amount) }}
						</template>
					</p-column>

					<p-column field="can_send" header="Valid">
						<template #body="txn">
							<div v-if="txn.data.can_send">
								<icon v-tooltip.top="'This transfer is valid'" style="color: green" type="check" size="24px" />
							</div>
							<div v-else>
								<icon
									v-tooltip.top="
										'This account is not a child, grand child or sibling. Therefore this transfer will be ignored.'
									"
									style="color: red"
									type="cancel"
									size="24px"
								/>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>

			<template #footer v-if="step === 'upload'">
				<p-file-upload
					mode="basic"
					:file-limit="1"
					accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
					auto
					custom-upload
					@uploader="processTransfers"
					chooseLabel="Upload CSV/Excel"
				>
					<template #uploadicon>
						<icon :loading="loading" type="upload" size="20px" />
						<gutter size="10px" />
					</template>
				</p-file-upload>
			</template>
			<template #footer v-else-if="step === 'review'">
				<p-button text label="Cancel" @click="closeModal" />
				<p-button label="Add Transfers" @click="confirmTransfers" :disabled="transfer_count === 0" />
			</template>
		</p-dialog>
	</div>
</template>

<script>
import Papa from 'papaparse';
import { currency } from '@/lib/Filters';
import pFileUpload from 'primevue/fileupload';
import { read, readFile, utils } from 'xlsx';
import { get, find } from 'lodash';
export default {
	name: 'CSVTransferUploader',
	components: {
		pFileUpload,
	},
	emits: ['addTransfers'],
	props: {
		sender_account: {
			default() {
				return null;
			},
		},
		recipients_list: {
			type: Array,
			default() {
				return [];
			},
		},
		is_mp: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			step: 'upload',
			loading: false,
			show_modal: false,
			parsed_data: null,
			amount_total: 0,
			transfer_count: 0,
		};
	},
	computed: {
		isDisabled() {
			return this.sender_account === null;
		},
	},
	methods: {
		currency,
		closeModal() {
			this.show_modal = false;
			this.amount_total = 0;
			this.transfer_count = 0;
			this.parsed_data = null;
			this.step = 'upload';
		},
		openModal() {
			this.show_modal = true;
			this.amount_total = 0;
			this.transfer_count = 0;
			this.parsed_data = null;
			this.step = 'upload';
		},

		confirmTransfers() {
			const message = `You are adding ${this.transfer_count} transfer(s) for a total of ${currency(this.amount_total)}`;
			/// THIS CONFIRM IS USING THE PARENT'S one
			this.$confirm.require({
				header: 'Confirm Transfers',
				message,
				accept: async () => {
					// we just emit the transfer we want to add...
					this.$emit(
						'addTransfers',
						this.parsed_data.filter((row) => {
							return row.can_send;
						})
					);
					this.closeModal();
				},
				reject: () => {},
			});
		},
		processTransfers(files) {
			this.loading = true;
			const file = get(files, 'files.0');

			if (file.type === 'text/csv') {
				Papa.parse(file, {
					header: true,
					dynamicTyping: true,
					complete: (result) => {
						this.parsed_data = result.data.map((row) => {
							const regex = /^\$?\d{1,3}(,\d{3})*(\.\d{2})?$/;
							const match = row.amount.match(regex);

							if (match) {
								// Remove any commas or dollar signs
								try {
									row.amount = parseFloat(match[0].replace(/[\$,]/g, ''));
								} catch (err) {
									row.amount = 'INVALID';
								}
							} else {
								row.amount = 'INVALID';
							}

							// find and merge in the recipient data....((row) => {
							row.id = row.account_id;
							const found = find(this.recipients_list, { id: row.account_id });
							row.can_send = (!!found || this.is_mp) && typeof row.amount === 'number' && !isNaN(row.amount);
							if (row.can_send) {
								this.amount_total += row.amount;
								this.transfer_count++;
							}

							if (!!found) {
								row.label = found.label;
							} else {
								row.label = row.account_id;
							}

							return row;
						});
						this.loading = false;

						this.step = 'review';

						// we check against the list to say who can and can't receive the Data
					},
					error: (error) => {
						console.error('Error parsing CSV:', error);
						this.loading = false;
					},
				});
			} else {
				const reader = new FileReader();

				// Read the file as binary
				reader.readAsBinaryString(file);
				reader.onload = (e) => {
					const data = e.target.result;

					// Use XLSX to parse the file
					const workbook = read(data, { type: 'binary' });

					// Get the first sheet
					const firstSheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[firstSheetName];

					// Convert the sheet to JSON
					const arr_data = utils.sheet_to_json(worksheet);

					this.parsed_data = arr_data.map((row) => {
						if (typeof row.amount === 'string') {
							const regex = /^\$?\d{1,3}(,\d{3})*(\.\d{2})?$/;
							const match = row.amount.match(regex);

							if (match) {
								// Remove any commas or dollar signs
								try {
									row.amount = parseFloat(match[0].replace(/[\$,]/g, ''));
								} catch (err) {
									row.amount = 'INVALID';
								}
							} else {
								row.amount = 'INVALID';
							}
						}

						// find and merge in the recipient data....((row) => {
						row.id = row.account_id;
						const found = find(this.recipients_list, { id: row.account_id });
						row.can_send = (!!found || this.is_mp) && typeof row.amount === 'number' && !isNaN(row.amount);
						if (row.can_send) {
							this.amount_total += row.amount;
							this.transfer_count++;
						}

						if (!!found) {
							row.label = found.label;
						} else {
							row.label = row.account_id;
						}

						return row;
					});
					this.loading = false;
					this.step = 'review';
				};

				reader.onerror = (error) => {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to process file that was uploaded',
						life: 10000,
					});
					console.error('Error reading file:', error);
				};
			}
		},
	},
};
</script>
