<template>
	<div id="sub-accounts" class="view-content">
		<div class="page-heading">
			<h1>Sub-Accounts</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="flex justify-content-between">
			<p-button icon="pi pi-plus" label="New Sub-Account" @click="$router.push({ path: '/sub-accounts/new' })" />
			<show-archived v-model="show_archived" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div id="sub-accounts-table">
				<p-data-table
					ref="dt"
					paginator
					:rows="50"
					scrollable
					:value="sub_accounts"
					:loading="loading"
					v-model:filters="table_filters"
					:global-filter-fields="['name', 'description', 'provider_account_id', 'provider_account.name']"
					data-key="id"
				>
					<template #header>
						<div class="flex justify-content-between">
							<p-input-text
								v-model="table_filters['global'].value"
								style="width: 100%; max-width: 400px"
								placeholder="Search Sub-Accounts..."
							/>
						</div>
					</template>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">no results</div>
					</template>
					<p-column header="Sub Account" sortable sort-field="name">
						<template #body="row">
							<router-link :to="{ path: `/sub-accounts/${row.data.id}` }">{{ row.data.name }}</router-link
							><br />
							<div class="sub-value">{{ row.data.description }}</div>
						</template>
					</p-column>
					<p-column header="Provider" sortable sort-field="provider_id">
						<template #body="row">
							{{ capitalize(row.data.provider_id) }}<br />
							<div class="sub-value">{{ row.data.provider_account_id }}</div>
						</template>
					</p-column>
					<p-column header="Verticals" class="column-align-center">
						<template #body="row">
							<vertical-icon v-for="vertical_id in row.data.vertical_ids" :vertical-id="vertical_id" size="30px" />
						</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="row">
							<div class="toolset">
								<div class="tool">
									<p-button
										v-tooltip.top="'Edit'"
										aria-label="Edit"
										@click="$router.push({ path: `/sub-accounts/${row.data.id}` })"
									>
										<template #icon>
											<icon type="square-edit-outline" size="20px" />
										</template>
									</p-button>
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script>
import { capitalize, get } from 'lodash-es';
import { getSubAccounts } from '@GQL';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import pConfirmDialog from 'primevue/confirmdialog';
import { FilterMatchMode, FilterOperator } from 'primevue/api';

export default {
	name: 'PhoneNumbers',
	components: {
		pConfirmDialog,
		showArchived,
		verticalIcon,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Phone Numbers' }],
			loading: false,
			show_archived: false,
			sub_accounts: [],
			filters: [],
			table_filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
		};
	},
	async mounted() {
		return await this.getSubAccounts();
	},
	methods: {
		capitalize,
		get,
		async getSubAccounts() {
			this.loading = true;
			try {
				this.sub_accounts = await getSubAccounts([]);
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get sub-accounts',
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
:deep(.vertical-icon) {
	margin: 0 5px;
}
</style>
