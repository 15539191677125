<template>
	<div id="vendors-list" class="view-content">
		<div class="page-heading">
			<h1>Vendors</h1>
			<breadcrumb />
		</div>
		<p-button icon="pi pi-plus" label="Add Vendor" @click.prevent="$router.push(`${$route.path}/new`)" />
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table ref="dt" :loading="loading" :rows="10" scrollable :value="vendors">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column field="product" header="Name" sortable sortField="name">
					<template #body="vendor">
						<div class="name">
							<router-link :to="{ path: `${$route.path}/${vendor.data.id}` }">{{ vendor.data.name }}</router-link>
						</div>
						<div class="sub-value">{{ vendor.data.id }}</div>
					</template>
				</p-column>
				<p-column field="vendor_type" header="Contact" sortable sortField="contact.name">
					<template #body="vendor">
						{{ vendor.data.contact.name }}
						<div class="sub-value">
							{{ vendor.data.contact.phone ? phoneFormat(vendor.data.contact.phone) : 'No Phone' }} -
							{{ vendor.data.contact.email ? vendor.data.contact.email : 'No Email' }}
						</div>
					</template>
				</p-column>
				<p-column field="status" header="Status" sortable sortField="status">
					<template #body="vendor">
						{{ capitalize(vendor.data.status) }}
					</template>
				</p-column>
				<p-column header="Tools" class="align-center">
					<template #body="row">
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Edit'"
									aria-label="Edit"
									@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
								>
									<template #icon>
										<icon type="square-edit-outline" size="20px" />
									</template>
								</p-button>
							</div>
						</div>
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script>
/**
 * TODO:UI - add a quick filter for status, perhaps add two more columns showing the values under the verticals
 * verticals.health_insurance.data : boolean
 * verticals.health_insurance.call : boolean
 * verticals.health_insurance.live_transfer : boolean
 * ETC WITH ICONS OR SOMETHING EASY TO HOVER OVER
 */
import { getVendors } from '@GQL';
import log from '@/lib/Log';
import { phoneFormat } from '@/lib/Filters';
import capitalize from 'lodash/capitalize';

export default {
	name: 'VendorsList',
	data() {
		return {
			vendors: [],
			loading: false,
		};
	},
	async mounted() {
		this.loading = true;
		try {
			this.vendors = await getVendors();
			log.trace('Vend', this.vendors);
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to get Vendor List',
				lifetime: 3000,
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		phoneFormat,
		capitalize,
	},
};
</script>

<style lang="less">
.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}
</style>
