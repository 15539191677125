<template>
	<div class="table-tools" v-tooltip.top="'Additional Tools'">
		<p-button @click="$emit('update:show', !this.show)" class="toggle-switch">
			<template #icon>
				<div class="icon-wrapper">
					<icon type="toolbox" size="20px" />
				</div>
			</template>
		</p-button>
		<div v-if="show" class="tool-buttons">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TableTools',
	emits: ['update:show'],
	props: {
		show: Boolean,
	},
};
</script>

<style lang="less" scoped>
.table-tools {
	background-color: var(--color-b);
	border: 1px solid var(--color-b);
	border-radius: 3px;
	display: flex;

	.toggle-switch {
		box-shadow: none;
		outline: none;
	}
}

.tool-buttons {
	align-items: center;
	display: flex;
	gap: 1px;

	:deep(.icon-wrapper) {
		background-color: white;
	}
}
</style>
