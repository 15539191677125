import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getSubAccounts(filters: QueryFilters) {
	const query = `
        query GetSubAccounts($filters: [[Any]]) {
            subAccounts(filters: $filters) {
                id
				name
				description
				provider_id
				provider_account_id
				vertical_ids
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.subAccounts;
}
