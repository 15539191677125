<template>
	<div class="tool">
		<p-button
			v-tooltip.top="'View Receipt'"
			aria-label="View Receipt"
			@click="transaction_modal_open = !transaction_modal_open"
		>
			<template #icon>
				<icon type="receipt-text" size="20px" />
			</template>
		</p-button>
	</div>
	<p-dialog
		v-model:visible="transaction_modal_open"
		class="transaction-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			header: { class: 'print-hidden' },
			closeButtonIcon: { class: 'print-hidden' },
			footer: { class: 'print-hidden' },
		}"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="receipt-text" size="24px" style="color: #27a69a" />
				<strong>Transaction Receipt</strong>
			</div>
		</template>
		<div class="receipt">
			<table style="border: none; width: 600px">
				<tbody>
					<tr style="border: none">
						<td style="color: #27a69a; padding-bottom: 10px">
							<h1>NextGen Leads Receipt</h1>
						</td>
					</tr>
					<tr style="border: none">
						<td style="border: none; border-bottom: 2px solid #27a69a; padding-bottom: 10px">
							<table>
								<tbody>
									<tr style="border: none">
										<td style="border: none; color: #7f858b; font-size: 16px; width: 300px">
											{{ account_name ? 'BILL TO' : '' }}
										</td>
										<td style="border: none; color: #7f858b; font-size: 16px; padding: 10px; width: 100px">ID #</td>
										<td style="border: none; font-size: 14px; padding: 10px; padding-right: 0; width: 200px">
											{{ transaction.id }}
										</td>
									</tr>
									<tr style="border: none">
										<td style="border: none; font-size: 14px">
											<strong>{{ account_name ? account_name : '' }}</strong>
										</td>
										<td style="border: none; color: #7f858b; font-size: 16px; padding: 10px; padding-right: 0">DATE</td>
										<td style="border: none; font-size: 14px; padding: 10px; padding-right: 0; text-align: right">
											{{ formatDate(transaction.created_at, 'YYYY-MM-DD &bull; h:mm:ssa') }}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr style="border: none">
						<td style="border: none; color: #7f858b; padding-bottom: 5px; padding-top: 20px">PAYMENT METHOD</td>
					</tr>
					<tr style="border: none">
						<td style="border: none; font-size: 14px; padding-bottom: 30px; padding-top: 10px">
							<strong>{{
								`${transaction.payment_method.name_on_card} (${transaction.payment_method.last_four})`
							}}</strong>
						</td>
					</tr>
					<tr style="border: none">
						<td style="border: none">
							<table style="border: none">
								<tbody>
									<tr style="background-color: #d1eeee; border: none; color: #008080">
										<td style="border: none; padding: 10px; width: 500px">DESCRIPTION<br /></td>
										<td style="border: none; width: 100px">AMOUNT</td>
									</tr>
									<tr style="border: none">
										<td style="border: none; padding: 10px">{{ capitalize(transaction.type) }}</td>
										<td style="border: none; padding: 10px">{{ currency(transaction.amount) }}</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr style="border: none">
						<td style="border: none">
							<table style="border-top: 1px dotted #d1eeee">
								<tbody>
									<tr>
										<td>&nbsp;</td>
									</tr>
									<tr>
										<td style="border: none; padding: 10px"></td>
										<td style="border: none; color: #7f858b; padding: 10px">START BALANCE</td>
										<td style="border: none; padding: 10px">{{ currency(transaction.start_balance) }}</td>
									</tr>
									<tr style="border: none">
										<td style="border: none; padding: 10px"></td>
										<td style="border: none; color: #7f858b; padding: 10px">AMOUNT</td>
										<td style="border: none; padding: 10px">{{ currency(transaction.amount) }}</td>
									</tr>
									<tr style="border: none">
										<td style="border: none; padding: 10px; width: 300px"></td>
										<td style="border: none; color: #7f858b; padding: 10px; width: 200px">CURRENT BALANCE</td>
										<td style="border: none; padding: 10px; width: 100px">
											{{ currency(transaction.end_balance) }}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<template #footer>
			<p-button class="print-hidden" icon="pi pi-print" label="Print" @click.prevent="print" />
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { formatDate, currency, capitalize } from '@/lib/Filters';
import pDialog from 'primevue/dialog';
import { getAccountById } from '@GQL';

export default {
	name: 'TransactionReceipt',
	components: {
		pDialog,
	},
	props: {
		transaction: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			account_name: null,
			transaction_modal_open: false,
		};
	},
	methods: {
		formatDate,
		currency,
		capitalize,
		print() {
			window.print();
		},
	},
	async mounted() {
		if ('account_id' in this.$route.params) {
			const account = await getAccountById(this.$route.params.account_id);
			this.account_name = account.name;
		}
	},
};
</script>

<style lang="less">
@media print {
	.print-hidden {
		display: none !important;
		visibility: hidden !important;
	}
}
</style>
