<template>
	<p-dialog v-model:visible="auction_modal_open" class="auction-modal" :modal="true" :dismissable-mask="true">
		<template #header>
			<div class="flex align-items-center w-full">
				<icon type="table-clock" size="1.5rem" style="margin-right: 1rem" />
				<span class="font-bold">{{ lead_id }}: Auction Details - Attempt {{ auction_log.attempt || 1 }}</span>
			</div>
		</template>
		<template v-if="loading">
			<div class="modal-loading">
				<p-progress-spinner
					class="spinner"
					style="height: 130px; width: 130px"
					:pt="{ circle: { style: { stroke: 'var(--color-a)', strokeWidth: 2 } } }"
				/>
			</div>
		</template>
		<template v-else>
			<div class="flex justify-content-between pb-4">
				<div class="flex align-items-center">
					<icon type="clipboard-text-clock-outline" size="36px" class="mr-2" />
					<div class="title line-height-2">
						<strong>
							{{ get(auction_log, 'auction.name') }}
						</strong>
						<div class="sub-value">
							{{ auction_log.id }}
						</div>
					</div>
				</div>
				<div style="color: var(--gray-50); font-size: var(--font-size-sm); line-height: 1.5">
					{{ formatDate(new Date(auction_log.created_at), 'YYYY-MM-DD &bull; h:mm:ssa') }}
					<div class="sub-value">{{ timeAgo(auction_log.created_at) }}</div>
				</div>
			</div>
			<div class="metrics">
				<row>
					<div class="column">
						<metric label="Lead ID" :value="lead_id" />
					</div>
					<gutter size="20px" />
					<div class="column">
						<metric label="Status" :value="capitalize(auction_log.status)" />
					</div>
					<gutter size="20px" />
					<div class="column">
						<metric label="Winning Bid Type" :value="capitalize(get(auction_log, 'bids[0].bid_type')) || 'NA'" />
					</div>
					<gutter size="20px" />
					<div class="column">
						<metric label="Revenue" :value="sumBy(auction_log.purchases, 'price')" is-currency />
					</div>
				</row>
			</div>
			<gutter size="20px" />
			<p-message v-if="auction_log.status === 'errored'" severity="error" :closable="false">{{
				auction_log.errors[0]
			}}</p-message>
			<template v-for="(bid_group, bid_group_index) in auction_log.bids" :key="bid_group.bid_type">
				<p-data-table class="bid-group" :value="bid_group.campaigns">
					<template #header>
						<div class="table-header">
							<strong>
								{{ capitalize(bid_group.bid_type) }} Bids<template v-if="bid_group_index === 0"> (Winner)</template>
							</strong>
							<div
								class="sorting-bid"
								v-tooltip.top="{ value: bidDetails(bid_group.sorting_bid_adjustments), escape: false }"
							>
								Sorting Bid: {{ currency(bid_group.sorting_bid) }}
								<div class="inline-icon"><icon type="help-circle" size="20px" /></div>
							</div>
						</div>
					</template>
					<p-column field="company" header="Account">
						<template #body="campaign">
							<router-link
								:to="`/marketplaces/manage/${campaign.data.account.mpid}/accounts/manage/${campaign.data.account.id}/reports/account-activity`"
								>{{ campaign.data.account.name }}</router-link
							>
						</template>
					</p-column>
					<p-column field="campaign" header="Campaign">
						<template #body="campaign">
							<router-link
								:to="`/marketplaces/manage/${campaign.data.account.mpid}/accounts/manage/${campaign.data.account.id}/campaigns/${campaign.data.campaign.id}`"
								>{{ campaign.data.campaign.name }}</router-link
							>
						</template>
					</p-column>
					<p-column field="sorting_bid" header="Sorting Bid">
						<template #body="campaign">
							<div
								class="bid-wrapper"
								v-tooltip.top="{ value: bidDetails(campaign.data.sorting_bid_adjustments), escape: false }"
							>
								{{ currency(campaign.data.sorting_bid) }}
								<div class="inline-icon"><icon type="help-circle" size="18px" /></div>
							</div>
						</template>
					</p-column>
					<p-column field="max_bid" header="Max Bid">
						<template #body="campaign">
							{{ currency(campaign.data.max_bid) }}
						</template>
					</p-column>
					<p-column field="bid" header="Effective Bid">
						<template #body="campaign">
							<template v-if="!isNil(campaign.data.price)">
								<div
									:class="['bid-wrapper']"
									v-tooltip.top="{ value: bidDetails(campaign.data.bid_adjustments), escape: false }"
								>
									{{ currency(campaign.data.price) }}
									<div class="inline-icon"><icon type="help-circle" size="18px" /></div>
								</div>
							</template>
							<template v-else>--</template>
						</template>
					</p-column>
					<p-column header="Status">
						<template #body="campaign">
							<icon
								v-tooltip.top="'Complete'"
								v-if="campaignPurchase(campaign.data.campaign.id).status === 'complete'"
								class="complete"
								type="receipt-text-check"
								size="24px"
							/>
							<icon
								v-tooltip.top="'Canceled'"
								v-if="campaignPurchase(campaign.data.campaign.id).status === 'canceled'"
								class="canceled"
								type="receipt-text-remove"
								size="24px"
							/>
						</template>
					</p-column>
				</p-data-table>
			</template>
			<gutter size="20px" />
			<row>
				<column :span="2">
					<p-data-table class="disqualified-campaigns" :value="auction_log.disqualifiedCampaigns">
						<template #empty>
							<div class="dim">No campaigns were disqualified</div>
						</template>
						<template #header>
							<div class="flex flex-wrap align-items-center justify-content-between gap-2">
								<strong> Disqualified Campaigns </strong>
							</div>
						</template>
						<p-column header="Campaign">
							<template #body="campaign">
								<router-link
									:to="`/marketplaces/manage/${campaign.data.account.id.slice(0, 3)}/accounts/manage/${campaign.data.account.id}/campaigns/${campaign.data.campaign.id}`"
								>
									{{ campaign.data.campaign.name }}
								</router-link>
								<div class="sub-value">{{ campaign.data.account.id }}: {{ campaign.data.account.name }}</div>
							</template>
						</p-column>
						<p-column header="Disqualification Reason">
							<template #body="campaign">
								{{ campaign.data.dq_reason }}
							</template>
						</p-column>
					</p-data-table>
				</column>
				<gutter size="30px" />
				<column>
					<div class="card disqualification-stats">
						<div class="dq-stats-heading">Disqualifications</div>
						<div class="dq-reasons padded">
							<div v-for="(reason, reason_index) in dqReasonsCount()" class="dq-reason" :key="reason_index">
								<div class="reason">
									<strong>{{ reason.count }}</strong> | {{ reason.description }}
								</div>
								<div class="bar-container">
									<div
										class="bar"
										:style="`width: ${(reason.count / auction_log.disqualifiedCampaigns.length) * 100}%`"
									></div>
								</div>
							</div>
							<div
								v-if="auction_log.disqualifiedCampaigns && auction_log.disqualifiedCampaigns.length === 0"
								class="no-dqs"
							>
								<p-message :closable="false">No campaigns were disqualified</p-message>
							</div>
						</div>
					</div>
				</column>
			</row>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import pColumn from 'primevue/column';
import pDataTable from 'primevue/datatable';
import pDialog from 'primevue/dialog';
import pProgressSpinner from 'primevue/progressspinner';
import { capitalize, find, forIn, get, groupBy, isNil, sortBy, sumBy } from 'lodash-es';
import { formatDate, currency, timeAgo } from '@/lib/Filters';
import { getAuctionLog, getAuctionHistory } from '@GQL';

export default {
	name: 'AuctionModal',
	components: {
		pColumn,
		pDataTable,
		pDialog,
		pProgressSpinner,
	},
	data() {
		return {
			auction_modal_open: false,
			lead_id: null,
			auction_log: {
				bids: [],
				disqualifiedCampaigns: [],
				purchases: [],
			},
			loading: false,
		};
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		find,
		isNil,
		timeAgo,
		get,
		sumBy,
		async open(lead_id, params) {
			this.loading = true;
			this.lead_id = lead_id;
			this.auction_modal_open = true;
			if (params.auction_log_id) {
				this.auction_log = await getAuctionLog(params.auction_log_id);
			} else if (params.attempt) {
				const filters = [`lead_id = '${lead_id}'`, `attempt = ${params.attempt}`];
				const result = await getAuctionHistory([filters]);
				this.auctions = cloneDeep(result);
			}
			this.loading = false;
		},
		handleAuctionModal() {
			this.auction_modal_open = !this.auction_modal_open;
		},
		shared(bids) {
			return bids.filter((bid) => {
				return bid.bid_type === 'shared';
			});
		},
		exclusive(bids) {
			return bids.filter((bid) => {
				return bid.bid_type === 'exclusive';
			});
		},
		campaignPurchase(campaign_id) {
			const purchase = this.auction_log.purchases.find((purchase) => {
				return purchase.campaign_id === campaign_id;
			});

			return purchase || {};
		},
		bidDetails(bid_adjustments) {
			let output = '';
			bid_adjustments.forEach((adjustment) => {
				let line_item = '<div class="detail-line-item"><div class="description">' + adjustment.description + '</div>';
				line_item += '<div class="math"><div class="method-icon">';
				line_item += '<div class="mdi-icon" style="height: 12px; width: 12px;">';
				switch (adjustment.method) {
					case 'flat':
						line_item +=
							'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>equal</title><path d="M19,10H5V8H19V10M19,16H5V14H19V16Z" /></svg>';
						line_item += '</div></div>';
						line_item += currency(adjustment.amount);
						break;
					case 'multiply':
						line_item +=
							'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>close</title><path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" /></svg>';
						line_item += '</div></div>';
						line_item += adjustment.amount;
						break;
					default:
						if (adjustment.amount < 0) {
							line_item +=
								'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>minus</title><path d="M19,13H5V11H19V13Z" /></svg>';
						} else {
							line_item +=
								'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>plus</title><path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" /></svg>';
						}
						line_item += '</div></div>';
						line_item += currency(Math.abs(adjustment.amount));
						break;
				}
				line_item +=
					' | ' + currency(!isNil(adjustment.price_result) ? adjustment.price_result : adjustment.bid_result);
				line_item += '</div></div>';
				output += line_item;
			});
			return output;
		},
		dqReasonsCount() {
			const dq_reasons = [];
			const grouped_dqs = groupBy(this.auction_log.disqualifiedCampaigns, 'dq_reason');

			forIn(grouped_dqs, (campaigns, reason) => {
				dq_reasons.push({
					description: reason,
					count: campaigns.length,
				});
			});
			return sortBy(dq_reasons, 'count').reverse();
		},
	},
};
</script>

<style lang="less">
.auction-modal {
	max-width: 1600px;
	width: 95%;

	.complete {
		color: var(--green);
	}

	.canceled {
		color: var(--yellow);
	}

	.modal-loading {
		align-items: center;
		display: flex;
		justify-content: center;
		min-height: 50vh;
		width: 100%;
	}

	.p-datatable-header {
		padding: 1rem;
	}

	.table-header {
		background-color: transparent;
		align-items: center;
		display: flex;
		justify-content: space-between;
		gap: 2em;
	}

	.bid-group {
		margin-bottom: 20px;
	}

	.bid-wrapper {
		display: inline-flex;

		.mdi-icon {
			color: var(--gray-50);
			margin-left: 5px;
		}

		&.complete,
		&.return_rejected {
			color: var(--green);
		}

		&.canceled,
		&.error,
		&.failed,
		&.return_accepted {
			color: var(--red);
		}
	}

	.dq-stats-heading {
		border-bottom: 1px solid var(--gray-10);
		font-weight: bold;
		height: 50px;
		line-height: 50px;
		padding: 0 20px;
	}

	.dq-reason {
		margin-bottom: 20px;

		.bar-container {
			background-color: var(--gray-10);
			margin-top: 0.5rem;
			width: 100%;

			.bar {
				background-color: var(--color-b);
				height: 5px;
			}
		}
	}
}

.detail-line-item {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 3px 0;

	.description {
		font-weight: bold;
	}
}

.method-icon {
	align-items: center;
	background-color: var(--gray-60);
	border-radius: 3px;
	display: inline-flex;
	height: 16px;
	justify-content: center;
	line-height: 0;
	margin: 0 5px;
	width: 16px;

	.mdi-icon {
		fill: white;
		vertical-align: text-top;
	}
}
</style>
