import { $GQL } from '@GQL';

export async function deleteBlockedNumber(phone: string) {
	const mutation = `
        mutation DeleteBlockedNumber($phone: String!,) {
			deleteBlockedNumber(phone: $phone) {
				phone
			}
        }
    `;

	const result = await $GQL.request(mutation, { phone });
	return result.deleteBlockedNumber;
}
