<template>
	<div class="select-promotion">
		<line-loader :show="loading" />
		<h2>Select a Promotional Campaign Type</h2>
		<div v-if="!loading" class="promotion-options">
			<div
				class="option shadow-2"
				:class="[{ selected: selected_campaign_type === 'standard' }]"
				@click="selectCampaignType('standard')"
			>
				<div
					class="card-header"
					style="background-image: url('/assets/img/backgrounds/card-background-standard.jpg')"
				></div>
				<div class="content">
					<div class="name">Standard Campaign</div>
					<div class="description">
						Set up a campaign that can buy leads without restrictions at any time on any day.
					</div>
					<div class="bid-description">
						<div class="small">Bid as low as:</div>
						<div class="pricing">{{ standardPrice }}</div>
					</div>
					<div class="note" style="color: var(--green)">No restrictions</div>
					<p-button v-if="selected_campaign_type === 'standard'" class="selected w-full" label="SELECTED ✓" />
					<p-button v-else label="SELECT" class="w-full" outlined />
				</div>
			</div>
			<div v-if="!this.promotions.length" class="option shadow-2">
				<div class="no-promo-msg">
					There are currently no available promotions for the selected vertical and product.
				</div>
			</div>
			<template v-for="(promotion, index) in promotions" :key="index">
				<div
					class="option shadow-2"
					:class="[{ selected: selected_campaign_type === promotion.id }]"
					@click="selectCampaignType(promotion.id)"
				>
					<div class="card-header" :style="{ 'background-image': 'url(' + promotion.icon_url + ')' }"></div>
					<div class="content">
						<div class="name">{{ promotion.name }}</div>
						<div class="description">{{ promotion.description }}</div>
						<div class="bid-description">
							<div class="small">Bid as low as:</div>
							<div class="pricing">{{ currency(bidDescription(promotion)) }}</div>
						</div>
						<div
							class="note"
							:style="promotion.id === 'standard' ? { color: 'var(--green)' } : { color: 'var(--red)' }"
						>
							{{ promotion.restrictions }}
						</div>
						<p-button v-if="selected_campaign_type === promotion.id" class="selected w-full" label="SELECTED ✓" />
						<p-button v-else label="SELECT" class="w-full" outlined />
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script lang="ts">
import { cloneDeep, get } from 'lodash-es';
import pToast from 'primevue/toast';
import { currency } from '@/lib/Filters';
import { useCampaignStore } from '@/stores/campaign';
import { useMarketplaceStore } from '@/stores/marketplace';
import { getPromotions, getSettings } from '@GQL';
import { mapStores } from 'pinia';

export default {
	name: 'SelectPromotion',
	components: {
		pToast,
	},
	emits: ['skipPromotion', 'skip', 'select'],
	props: {
		skipPromotion: Boolean,
	},
	data() {
		return {
			loading: true,
			selected_campaign_type: 'standard',
			vertical_settings: {},
			promotions: [],
		};
	},
	computed: {
		mpid() {
			const marketplaceStore = useMarketplaceStore();
			return marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		standardPrice() {
			return currency(
				get(
					this.vertical_settings,
					`${this.campaignStore.campaign.vertical_id_targeting}.${this.campaignStore.campaign.product_targeting}.${this.campaignStore.campaign.bid_type}.minimum_bid`,
					10
				)
			);
		},
		...mapStores(useCampaignStore),
	},
	methods: {
		currency,
		prev() {
			return 'select_product';
		},
		next() {
			if (this.selected_campaign_type) {
				if (!this.$route.query.duplicate) {
					if (this.campaignStore.promotion.name) {
						this.campaignStore.campaign.name += ` - ${this.campaignStore.promotion.name}`;
					}
				}

				return 'select_channel';
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'No Campaign Type Set',
					detail: 'Please select a campaign type.',
					life: 3000,
				});
				return null;
			}
		},
		selectCampaignType(value) {
			this.selected_campaign_type = value;

			if (value !== 'standard') {
				const selected_promotion = this.promotions.find((promotion) => {
					return promotion.id === value;
				});

				if (selected_promotion) {
					this.campaignStore.promotion = cloneDeep(selected_promotion);
				}

				this.campaignStore.campaign.promotion_id = value;
			} else {
				this.campaignStore.promotion = {
					id: null,
					name: null,
					exclusive_minimum_bid: null,
					shared_minimum_bid: null,
				};
			}

			this.$emit('select');
		},
		async getPromotions() {
			this.promotions = await getPromotions([
				[
					`vertical_id = '${this.campaignStore.campaign.vertical_id_targeting}'`,
					`product = '${this.campaignStore.campaign.product_targeting}'`,
					`status = 'active'`,
					`'${this.campaignStore.campaign.bid_type}' = ANY(bid_types)`,
				],
			]);
		},
		async getVerticalSettings() {
			const result = await getSettings(['verticals'], this.mpid);
			this.vertical_settings = result.settings.verticals;
		},
		bidDescription(promotion) {
			return promotion[`${this.campaignStore.campaign.bid_type}_minimum_bid`];
		},
	},
	async mounted() {
		await this.getVerticalSettings();
		await this.getPromotions();

		if (this.promotions.length === 0) {
			this.selectCampaignType('standard');
			this.$emit('skip', 'skip_promotion');
		}

		if (this.$route.query.duplicate && this.campaignStore.campaign.promotion_id) {
			this.selectCampaignType(this.campaignStore.campaign.promotion_id);
		}

		this.$emit('select');
		this.loading = false;
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';

h2 {
	font-size: var(--font-size-h4);
}

.select-promotion {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.promotion-options {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1rem 0;

	.mobile({
		justify-content: center;
	});

	.option {
		background: var(--white);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 20px;
		max-width: 320px;
		min-height: 320px;
		text-align: center;
		transition: all 150ms ease-out;

		.card-header {
			background-position: 50% 50%;
			background-size: cover;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			height: 200px;
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;
			padding: 40px;
		}

		.name {
			font-size: 1.125rem;
			font-weight: bold;
			margin: 0 0 1rem;
		}

		.description,
		.bid-description,
		.note {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
			line-height: 1.5;
			margin-bottom: 1rem;
		}

		&.selected {
			box-shadow: 0 0 0 3px var(--color-b) !important;
		}
	}
}

.no-promo-msg {
	align-items: center;
	border: 3px dashed var(--gray-15);
	color: var(--gray-35);
	display: flex;
	height: 100%;
	line-height: 1.35em;
	padding: 40px;
}

.small {
	font-size: 0.75rem;
}

.pricing {
	color: var(--gray-65);
	font-size: 2em;
	font-weight: bold;
	line-height: 1.15em;
}
</style>
