import { isArray, isNil, isPlainObject, mapValues, omitBy } from 'lodash-es';

export function deepClean(obj: unknown): unknown {
	if (isPlainObject(obj)) {
		const cleaned = omitBy(obj, isNil) as Partial<T>;
		return mapValues(cleaned, (value: any) => {
			if (isPlainObject(value)) {
				return deepClean(value);
			} else if (isArray(value)) {
				return value.map(deepClean);
			} else {
				return value;
			}
		});
	} else if (isArray(obj)) {
		return obj.map(deepClean);
	} else {
		return obj;
	}
}
