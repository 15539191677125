import { $GQL } from '@GQL/index';

export async function reportAccountOverviewChart(params: {
	date_range: [string, string];
	compare: any;
	account_id: string;
}) {
	const query = `
        query ReportAccountOverviewChart($params: JSONObject!) {
            reportAccountOverview(params: $params)
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportAccountOverview;
}
