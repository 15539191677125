<template>
	<loader :show="loading" />
	<icon
		v-tooltip.top="'Edit'"
		class="action"
		size="24px"
		type="square-edit-outline"
		@click="edit_payment_modal_open = !edit_payment_modal_open"
	/>
	<p-dialog
		v-model:visible="edit_payment_modal_open"
		class="edit-payment-method-modal"
		:modal="true"
		:dismissable-mask="true"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="credit-card-edit" size="24px" style="color: var(--color-b)" />
				<strong>Edit Payment Method</strong>
			</div>
		</template>
		<p>Enter your card information below to add it to your account as a new payment method.</p>
		<div class="card-section">
			<div class="header">Card Details</div>
			<div class="flex gap-4 flex-wrap">
				<div class="input-group card-number flex-1 mb-2">
					<label for="card_number">Card Number:</label>
					<p-input-text v-model="cardNumber" disabled name="card_number" placeholder="Card Number" class="w-full" />
				</div>
				<div class="input-group expiration">
					<label for="expiration">Expiration Date:</label>
					<p-input-text v-model="card.expiration_date" name="expiration" placeholder="YYYY-MM" />
				</div>
			</div>
			<gutter size="5px" />
			<div class="flex gap-4 flex-wrap">
				<div class="input-group card-number flex-1 mb-2">
					<label for="name_on_card">Name on Card:</label>
					<p-input-text v-model="card.name_on_card" name="name_on_card" placeholder="Cardholder Name" class="w-full" />
				</div>
				<div class="input-group cvv">
					<label for="expiration">CVV:</label>
					<p-input-text v-model="cvv" name="expiration" disabled placeholder="CVV" />
				</div>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card-section">
			<div class="header">Billing Address</div>
			<div class="flex">
				<div class="input-group card-number flex-1 mb-2">
					<label for="street_1">Address Line 1:</label>
					<p-input-text v-model="card.street_1" name="street_1" placeholder="Address Line 1" class="w-full" />
				</div>
			</div>
			<gutter size="5px" />
			<div class="flex">
				<div class="input-group card-number flex-1 mb-2">
					<label for="street_2">Address Line 2:</label>
					<p-input-text
						v-model="card.street_2"
						name="street_2"
						placeholder="Address Line 2 (optional)"
						class="w-full"
					/>
				</div>
			</div>
			<gutter size="5px" />
			<div class="flex gap-4 flex-wrap">
				<div class="input-group city flex-1 w-6">
					<label for="city">City:</label>
					<p-input-text v-model="card.city" name="city" placeholder="City" class="w-full" />
				</div>
				<div class="input-group state w-3">
					<label for="state">State:</label>
					<p-dropdown
						v-model="card.state"
						name="state"
						:options="state_options"
						option-label="value"
						option-value="value"
						placeholder="State"
					/>
				</div>
				<div class="input-group zip w-3">
					<label for="zip">Zip:</label>
					<p-input-text v-model="card.zip" name="zip" placeholder="Zipcode" class="w-full" />
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Edit Payment Method" @click="editPaymentMethod" />
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import STATES from '@/lib/Data/states.json';
import { required, and, sameAs, minLength } from '@vuelidate/validators';
import pButton from 'primevue/button';
import pDialog from 'primevue/dialog';
import pDropdown from 'primevue/dropdown';
import pText from 'primevue/inputtext';
import { currency } from '@/lib/Filters';
import type { PaymentMethod } from '@nextgenleads/banker-driver';
import { PropType } from 'vue';
import { updatePaymentMethod } from '@GQL';
import { pick } from 'lodash-es';

export default {
	name: 'EditPaymentMethodModal',
	components: {
		pButton,
		pDialog,
		pDropdown,
		pText,
	},
	props: {
		card: Object as PropType<PaymentMethod>,
	},
	data() {
		return {
			loading: false,
			state_options: [...STATES],
			edit_payment_modal_open: false,
			cvv: '***',
			accepted_terms: false, // for a check box
		};
	},
	computed: {
		cardNumber() {
			return '**** **** **** ' + this.card.last_four;
		},
	},
	methods: {
		currency,
		async editPaymentMethod() {
			this.loading = true;
			// we cannot update id and other fields like that
			try {
				const result = await updatePaymentMethod(
					this.card.id,
					pick(this.card, ['street_1', 'street_2', 'city', 'state', 'zip', 'name_on_card', 'expiration_date'])
				);
				this.loading = false;
				this.edit_payment_modal_open = false;
				this.$toast.add({
					summary: 'Card was successfully updated',
					life: 3000,
				});
			} catch (err) {
				this.loading = false;
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update card',
					detail: 'Contact your account manager',
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.card-section {
	max-width: 600px;
}

.header {
	font-size: var(--font-size-base);
	font-weight: bold;
	margin-bottom: 1rem;
}

.input-group {
	label {
		color: var(--gray-50);
	}
}

.action {
	color: var(--gray-50);
	cursor: pointer;

	&:hover {
		color: var(--color-b);
	}
}
</style>
@/lib/GQL/mutations/updatePaymentMethod
