import ShippersList from '@/views/Shippers/List/Page.vue';
import ShipperRecord from '@/views/Shippers/Record/Page.vue';
import ShipmentLogList from '@/views/ShipmentLogs/List/Page.vue';
import ShipperTemplateRecord from '@/views/ShipperTemplates/Record/Page.vue';
import ShipperTemplatesList from '@/views/ShipperTemplates/List/Page.vue';

export default [
	{
		path: 'shippers/new',
		name: 'CreateShipper',
		component: ShipperRecord,
		meta: {
			parent_menu: 'Shipper Service',
			title: 'New Shipper',
			permissions: 'admin.shippers',
		},
	},
	{
		path: 'shippers/:shipper_id',
		name: 'EditShipper',
		component: ShipperRecord,
		meta: {
			parent_menu: 'Shipper Service',
			title: 'Edit Shipper',
			permissions: 'admin.shippers',
		},
	},
	{
		path: 'shippers',
		name: 'ShippersList',
		component: ShippersList,
		meta: {
			parent_menu: 'Shipper Service',
			title: 'Shippers',
			permissions: 'admin.shippers',
		},
	},
	{
		path: 'shipment-logs',
		name: 'ShipmentLogsList',
		component: ShipmentLogList,
		meta: {
			parent_menu: 'Shipper Service',
			title: 'Shipper Logs',
			permissions: 'admin.shippers',
		},
	},
	{
		path: 'shipper-templates',
		name: 'ShipperTemplatesList',
		component: ShipperTemplatesList,
		meta: {
			parent_menu: 'Shipper Service',
			title: 'Shipper Templates',
			permissions: 'admin.shipper_templates',
		},
	},
	{
		path: 'shipper-templates/new',
		name: 'CreateShipperTemplate',
		component: ShipperTemplateRecord,
		meta: {
			new: true,
			parent_menu: 'Shipper Service',
			title: 'New Shipper Template',
			permissions: 'admin.shipper_templates',
		},
	},
	{
		path: 'shipper-templates/:shipper_template_id',
		name: 'EditShipperTemplate',
		component: ShipperTemplateRecord,
		meta: {
			parent_menu: 'Shipper Service',
			title: 'Edit Shipper Template',
			permissions: 'admin.shipper_templates',
		},
	},
];
