import { $GQL } from '@GQL/index';
import type { ProcessConversionCsvParams } from '@nextgenleads/job-driver';

export async function processConversionCsv(params: ProcessConversionCsvParams): Promise<{ id: string }> {
	const mutation = `
        mutation ProcessConversionCsv($params: JSONObject!) {
            processConversionCsv(params: $params) {
                id
                name
            }
        }
    `;
	const result = await $GQL.request(mutation, { params });
	return result.processConversionCsv;
}
