<template>
	<div id="auction-config-table">
		<p-data-table
			paginator
			:always-show-paginator="false"
			:rows="15"
			data-key="id"
			scrollable
			:value="sortedAuctions"
			:loading="loading"
		>
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>
			<p-column field="name" header="Name" sortable sortField="name">
				<template #body="row">
					<div class="name">
						<router-link :to="{ path: `${$route.path}/${row.data.id}` }">{{ row.data.name }}</router-link>
					</div>
					<div class="sub-value">{{ row.data.description }}</div>
				</template>
			</p-column>
			<p-column field="vertical_id" header="Vertical" sortable sortField="vertical_id" class="column-align-center">
				<template #body="row">
					<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
				</template>
			</p-column>
			<p-column field="vendor_id" header="Vendor ID" sortable sortField="vendor_id">
				<template #body="row">
					{{ row.data.vendor_id }}
				</template>
			</p-column>
			<p-column field="product" header="Product" sortable sortField="product">
				<template #body="row">
					{{ capitalize(row.data.product) }}
				</template>
			</p-column>
			<p-column field="status" header="Status" sortable sortField="status">
				<template #body="row">
					{{ capitalize(row.data.status) }}
				</template>
			</p-column>
			<p-column header="Tools" class="align-center">
				<template #body="row">
					<div class="toolset">
						<div class="tool">
							<p-button
								v-tooltip.top="'Edit'"
								aria-label="Edit"
								@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
							>
								<template #icon>
									<icon type="square-edit-outline" size="20px" />
								</template>
							</p-button>
						</div>
						<div class="tool">
							<p-button
								v-tooltip.top="'Duplicate'"
								aria-label="Edit"
								@click="duplicateAuctionConfig($event, row.data.id)"
							>
								<template #icon>
									<icon type="content-copy" size="20px" />
								</template>
							</p-button>
						</div>
						<delete-action
							message="Are you sure you want to delete this shipper?"
							@delete="deleteAuctionConfig(row.data.id)"
						/>
					</div>
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>
<script lang="ts">
import { capitalize, sortBy } from 'lodash-es';
import { useMarketplaceStore } from '@/stores/marketplace';
import { useSessionStore } from '@/stores/session';
import { getAuctionConfigs, deleteAuctionConfig } from '@GQL';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { displaySelected } from '@/lib/Filters';
import { mapStores } from 'pinia';
import { useAppStore } from '@/stores/app';

export default {
	components: {
		deleteAction,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			auction_configs: [],
		};
	},
	computed: {
		sortedAuctions() {
			return sortBy(this.auction_configs, ['name']);
		},
		mpid() {
			// either the path or the current roles'
			if ('mpid' in this.$route.params) {
				return this.$route.params.mpid;
			} else {
				return this.marketplaceStore.mpid || this.$root.appStore.mpid;
			}
		},
		...mapStores(useMarketplaceStore, useSessionStore, useAppStore),
	},
	async mounted() {
		await this.getList();
	},
	methods: {
		displaySelected,
		capitalize,
		async getList(no_cache = false) {
			this.loading = true;

			let options = [];
			if (no_cache) {
				options = ['auctionConfigs'];
			}

			this.auction_configs = await getAuctionConfigs(
				[
					[
						{
							field: 'mpid',
							op: 'eq',
							value: this.mpid,
						},
					],
				],
				options
			);

			this.loading = false;
		},
		async duplicateAuctionConfig(event, auction_config_id) {
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					duplicate: auction_config_id,
				},
			});
		},
		async deleteAuctionConfig(auction_config_id) {
			const delete_result = await deleteAuctionConfig(auction_config_id);
			if (delete_result.deleteAuctionConfig.id) {
				this.$toast.add({
					severity: 'success',
					summary: `The auction_config "${delete_result.deleteAuctionConfig.name}" was deleted.`,
					life: 3000,
				});
				await this.getList(true);
			} else {
				this.$toast.add({
					severity: 'error',
					summary: `Unable to delete the auction config "${delete_result.deleteAuctionConfig[0].name}".`,
					life: 3000,
				});
			}
		},
	},
};
</script>
