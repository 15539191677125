import { $GQL } from '@GQL/index';
import type { Note } from '@nextgenleads/marketplace-driver';

export async function insertCallNote(data: Omit<Note, 'id'>) {
	const mutation = `
        mutation InsertCallNote($data: JSONObject!) {
            insertCallNote(data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertCallNote;
}
