<template>
	<div>
		<row v-if="!loading">
			<div class="w-full flex justify-content-end align-items-center">
				<p-button
					v-tooltip.top="'Refresh'"
					label="Refresh Results"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="refreshReports"
				/>
			</div>
		</row>
		<gutter size="20px" />
		<row>
			<div class="column">
				<metric label="Auctions" is-number :value="totalAuctions" />
			</div>
			<gutter size="20px" />
			<div class="column">
				<metric label="Total Disqualifications" is-number :value="totalAuctions - totalQualifications" />
			</div>
			<gutter size="20px" />
			<div class="column">
				<metric label="Qualified Auctions" is-number :value="totalQualifications" />
			</div>
			<gutter size="20px" />
			<div class="column">
				<metric label="Wins" is-number :value="totalWins" />
			</div>
			<gutter size="20px" />
			<div class="column">
				<metric label="Qualified Win Rate" is-percentage :value="totalWins / totalQualifications" />
			</div>
		</row>
		<gutter size="20px" />
		<p-data-table :value="state_competition" sort-field="state" :sort-order="1" :loading="loading">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No data</div>
			</template>
			<p-column field="state" sortable header="State">
				<template #body="row">
					{{ row.data.state || 'Unknown' }}
				</template>
			</p-column>
			<p-column field="auctions" header="Auctions" sortable class="column-align-right">
				<template #body="row">
					{{ numberFormat(row.data.auctions) }}
				</template>
			</p-column>
			<p-column field="dqs" header="Qualified Auctions" sortable class="column-align-right">
				<template #body="row">
					{{ numberFormat(row.data.qualified_auctions) }}
				</template>
			</p-column>
			<p-column field="wins" header="Wins" sortable class="column-align-right">
				<template #body="row">
					{{ numberFormat(row.data.wins) }}
				</template>
			</p-column>
			<p-column field="win_rate" header="Win Rate" sortable class="column-align-right">
				<template #body="row">
					{{ percentage(row.data.win_rate, 2) }}
				</template>
			</p-column>
			<p-column
				v-if="$root.sessionStore.isAdminUser"
				field="lowest_winning_bid"
				header="Lowest Winning Bid"
				sortable
				class="column-align-right"
			>
				<template #body="row">
					{{ currency(row.data.lowest_winning_bid) }}
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>

<script lang="ts">
import { currency, numberFormat, percentage } from '@/lib/Filters';
import { getCampaignStateCompetition } from '@GQL';
import { sumBy } from 'lodash-es';

export default {
	name: 'CampaignStatsStateCompetition',
	data() {
		return {
			state_competition_loading: false,
			state_competition: [],
			loading: false,
		};
	},
	props: {
		query: {
			type: Object,
			required: true,
		},
	},
	computed: {
		totalAuctions() {
			return sumBy(this.state_competition, (row) => +row.auctions);
		},
		totalWins() {
			return sumBy(this.state_competition, (row) => +row.wins);
		},
		totalQualifications() {
			return sumBy(this.state_competition, (row) => +row.qualified_auctions);
		},
	},
	methods: {
		currency,
		numberFormat,
		percentage,
		async getCampaignStateCompetition(refresh = false) {
			this.state_competition_loading = true;
			try {
				this.loading = true;
				let no_cache = [];
				if (refresh) {
					no_cache = ['reportStateCompetition'];
				}

				const state_competition = await getCampaignStateCompetition(
					{
						campaign_id: this.$route.params.campaign_id,
						date_range: this.query.date_range,
					},
					no_cache
				);

				this.state_competition = state_competition;
				this.loading = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get state competition stats',
					detail: 'Try again or contact dev support',
				});
			} finally {
				this.loading = false;
			}
		},
		async refreshReports() {
			this.getCampaignStateCompetition(true).catch((err) => {});
		},
	},
	mounted() {
		this.getCampaignStateCompetition().catch((err) => {});
	},
};
</script>

<style lang="less" scoped></style>
