import { $GQL } from '@GQL/index';
import type { ResolveFollowUpParams } from '@nextgenleads/marketplace-driver';
export async function resolveFollowUp(params: ResolveFollowUpParams): Promise<{ id: string } | null> {
	const mutation = `
        mutation ResolveFollowUp($params: JSONObject!) {
            resolveFollowUp(params: $params) {
                id
                due_at
            }
        }
    `;
	const result = await $GQL.request(mutation, { params });
	return result.resolveFollowUp;
}
