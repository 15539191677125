import { $GQL } from '@GQL/index';

export async function reportDataLeadVelocity(
	params: {
		date_range: [string, string];
		vertical_id: string[];
		business_unit: string[];
		product: string[];
		source_id: string;
	},
	refresh: boolean
) {
	const query = `
        query ReportDataLeadVelocity($params: JSONObject!) {
            reportDataLeadVelocity(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportDataLeadVelocity'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportDataLeadVelocity;
}
