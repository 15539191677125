import { $GQL } from '@GQL/index';
import type { Vendor } from '@nextgenleads/source-driver';

export async function updateVendor(id: string, data: Partial<Vendor>): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateVendor($id: ID!, $data: JSONObject!) {
            updateVendor(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateVendor;
}
