import { $GQL } from '@GQL';

export async function deleteEmailTemplate(id: string) {
	const mutation = `
        mutation DeleteEmailTemplate($id: ID!) {
			deleteEmailTemplate(id: $id) {
				id
                name
			}
        }
    `;

	return await $GQL.request(mutation, { id });
}
