import { $GQL } from '@GQL/index';
import type { AutoFundConfig } from '@nextgenleads/banker-driver';

export async function insertAutoFundConfig(
	data: Omit<AutoFundConfig, 'created_at' | 'modified_at'>
): Promise<{ account_id: string }> {
	const mutation = `
        mutation InsertAutoFundConfig($data: JSONObject!){
            insertAutoFundConfig(data: $data) {
                account_id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertAutoFundConfig;
}
