import { $GQL } from '@GQL/index';
import type { Marketplace } from '@nextgenleads/marketplace-driver';

export async function insertMarketplace(data: Omit<Marketplace, 'created_at'>) {
	const mutation = `
        mutation InsertMarketplace($data: JSONObject!) {
            insertMarketplace(data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertMarketplace;
}
