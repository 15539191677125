<template>
	<div :class="['quick-filter', { active: isActive }]" @click="toggleFilter()"><slot></slot></div>
</template>

<script>
import { without } from 'lodash-es';

export default {
	name: 'QuickFilter',
	emits: ['toggle', 'update:modelValue'],
	props: {
		allowMultiple: Boolean,
		value: {
			type: String,
			required: true,
		},
		modelValue: {
			type: [Array, String, Number, Boolean],
			required: true,
		},
	},
	data() {
		return {};
	},
	computed: {
		isActive() {
			return this.modelValue.includes(this.value);
		},
	},
	methods: {
		toggleFilter() {
			if (this.allowMultiple) {
				if (this.isActive) {
					this.$emit('update:modelValue', without(this.modelValue, this.value));
				} else {
					this.$emit('update:modelValue', this.modelValue.slice().concat([this.value]));
				}
			} else {
				this.$emit('update:modelValue', this.value);
			}
			this.$emit('toggle');
		},
	},
};
</script>

<style scoped lang="less">
.quick-filter {
	background: transparent;
	border: 1px solid var(--gray-25);
	border-radius: 1.5rem;
	color: var(--gray-40);
	cursor: pointer;
	display: inline-block;
	margin: 0.25rem;
	padding: 0.5rem 1rem;
	transition: 0.3s;

	&:hover {
		background: var(--color-b-darker);
		border-color: var(--color-b-darker);
		color: white;
		text-decoration: none;
	}

	&.active {
		background: var(--color-b);
		border-color: var(--color-b);
		color: white;

		&:hover {
			background: var(--color-b-darker);
			border-color: var(--color-b-darker);
		}
	}
}
</style>
