<template>
	<div id="sources-list" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.source_id ? 'Edit' : 'Create' }} Source</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<SourceForm />
	</div>
</template>

<script>
import SourceForm from './Form.vue';
export default {
	name: 'SourceFormPage',
	components: {
		SourceForm,
	},
	props: {},
	data() {
		return {
			breadcrumb_items: [
				{ label: 'Sources', route: '/sources' },
				{
					label: this.$route.params.source_id ? 'Edit Source' : 'Create Source',
				},
			],
		};
	},
};
</script>

<style lang="less"></style>
