<template>
	<div class="content-campaign-ping-post">
		<p><strong>ADMIN ONLY:</strong> Configure this campaign as a ping/post integration.</p>
		<div class="ping-post-switch">
			<div class="switch">
				<p-input-switch input-id="ping_post_enabled" v-model="campaignStore.campaign.ping_post.enabled" />
			</div>
			<label for="ping_post_enabled" class="clickable"> Enable Ping Post Logic </label>
		</div>
		<template v-if="campaignStore.campaign.ping_post.enabled">
			<div class="flex">
				<div class="integration">
					<template v-if="!(campaignStore.integration || campaignStore.pending_integration)">
						<p-message :closable="false" class="mt-0">
							No Ping/Post integration found for this account. Please create one.
						</p-message>
					</template>
					<template v-else>
						<p-fieldset legend="Integration Details" class="admin">
							<p-message v-if="campaignStore.pending_integration" :closable="false" class="mt-0">
								Ping/Post Integration Pending, save campaign to finalize or test.
							</p-message>
							<div class="flex justify-content-between gap-2">
								<div class="ping-post-details">
									<div class="ping" v-if="local_integration.ping_enabled">
										Ping Script: <strong>{{ local_integration.ping_settings.script }} </strong>
									</div>
									<div class="post" v-if="local_integration.post_enabled">
										Post Script: <strong>{{ local_integration.post_settings.script }} </strong>
									</div>
								</div>
								<div class="toolset">
									<div class="tool">
										<p-button v-tooltip.top="'Edit PingPost Integration'" @click="editPingPost">
											<template #icon>
												<div class="">
													<icon type="square-edit-outline" size="24px" />
												</div>
											</template>
										</p-button>
									</div>
									<gutter size="10px" />
									<div class="tool">
										<p-button
											v-if="local_integration.id"
											v-tooltip.top="'Test Integration'"
											@click="show_test_modal = true"
										>
											<template #icon>
												<icon type="code-tags-check" size="24px" />
											</template>
										</p-button>
									</div>
								</div>
							</div>
						</p-fieldset>
						<gutter size="20px" />
					</template>
				</div>
			</div>
			<p-message v-if="campaignStore.pending_integration" severity="warn" :closable="false">
				Save all changes to test integration!
			</p-message>
			<template v-if="!(campaignStore.integration || campaignStore.pending_integration)">
				<div class="flex flex-start gap-2">
					<p-button icon="pi pi-plus" label="Add Ping/Post Integration" @click="addIntegration" />
				</div>
				<gutter size="20px" />
			</template>
			<p-fieldset legend="Ping Post Settings" class="admin" style="margin-top: 0">
				<div class="flex align-items-center">
					<p-checkbox v-model="campaignStore.campaign.tags" input-id="netagg" name="netagg" value="netagg" />
					<label for="netagg" class="ml-2"> This campaign is a Network Aggregator (NetAgg) </label>
				</div>
			</p-fieldset>
		</template>
		<!-- START INTEGRATION MODAL -->
		<p-dialog
			id="integration-modal"
			v-model:visible="show_integration_modal"
			:modal="true"
			:pt="{
				root: { class: 'w-12 sm:w-12 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="lan-connect" size="24px" style="color: var(--color-b)" />
					<strong>{{
						campaignStore.pending_integration || campaignStore.campaign.ping_post.enabled
							? 'Edit Ping/Post Integration'
							: 'Add Ping/Post Integration'
					}}</strong>
				</div>
			</template>
			<!-- <div class="control-group">
				<div class="inner">
					<label class="control-label req" for="integration_type">Type:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="integration_type"
								v-model="type"
								:options="integration_type_options"
								optionLabel="label"
								optionValue="value"
								placeholder="Select Type"
								class="w-full"
							/>
						</div>
					</div>
				</div>
			</div> -->
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="ping_enabled" v-model="local_integration.ping_enabled" />
						</div>
						<div class="field">
							<label class="control-label clickable" for="ping_enabled"><strong>Enable Ping</strong></label>
						</div>
					</div>
				</div>
			</div>
			<div v-if="local_integration.ping_enabled" class="control-group">
				<div class="inner">
					<label class="control-label">Ping Settings:</label>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse="local_integration.ping_settings" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="post_enabled" v-model="local_integration.post_enabled" />
						</div>
						<div class="field">
							<label class="control-label clickable" for="post_enabled"><strong>Enable Post</strong></label>
						</div>
					</div>
				</div>
			</div>
			<div v-if="local_integration.post_enabled" class="control-group">
				<div class="inner">
					<label class="control-label">Post Settings:</label>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse="local_integration.post_settings" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Options:</label>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse="local_integration.options" />
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="flex justify-content-between">
					<p-button label="Cancel" text @click="show_integration_modal = false" />
					<p-button
						v-if="campaignStore.integration && campaignStore.campaign.id"
						label="Edit Integration"
						icon="pi pi-check"
						@click="updatePingPost(campaignStore.campaign.id)"
					/>
					<p-button
						v-if="campaignStore.pending_integration"
						label="Edit Integration"
						icon="pi pi-check"
						@click="insertPendingPingPost"
					/>

					<p-button
						v-if="!(campaignStore.pending_integration || campaignStore.integration)"
						label="Add Ping/Post"
						icon="pi pi-check"
						@click="insertPendingPingPost"
					/>
				</div>
			</template>
		</p-dialog>
		<!-- END INTEGRATION MODAL -->
		<!-- START TEST MODAL -->
		<p-dialog
			id="test-modal"
			v-model:visible="show_test_modal"
			:modal="true"
			:pt="{
				root: { class: 'w-12 sm:w-12 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="lan-connect" size="24px" style="color: var(--color-b)" />
					<strong>Test Ping/Post Integration</strong>
				</div>
			</template>
			<div>
				<line-loader :loading="test_loading" />
				<div class="sub-value">
					Integration ID: {{ campaignStore.campaign.id }}
					<label>Ping Enabled: {{ this.local_integration.ping_enabled }}</label>
					<label>Post Enabled: {{ this.local_integration.post_enabled }}</label>
				</div>
				<p>
					NOTE: when testing Post Only, modify the lead ID to one that already has an existing purchase record, i.e.
					local use D-KXU2-P4YV
				</p>
				<div class="mt-3 mb-3">
					<div for="location-type" style="font-size: var(--font-size-sm); font-weight: bold">Test with:</div>
					<row>
						<column>
							<div class="flex flex-wrap gap-3 align-items-center">
								<div class="flex align-items-center">
									<p-radio-button v-model="test_type" input-id="lead_id" name="lead-id" value="lead_id" />
									<label for="lead_id" class="ml-2 clickable">Lead ID</label>
								</div>
								<div class="flex align-items-center m-2">
									<p-radio-button v-model="test_type" input-id="lead_data" name="lead-data" value="lead_data" />
									<label for="lead_data" class="ml-2 clickable">Lead JSON</label>
								</div>
							</div>
						</column>
						<column>
							<p-button
								label="Load Lead"
								icon="pi pi-file-import"
								@click="createLeadData(test_lead_id ? test_lead_id : null)"
								class="mt-2"
								:loading="test_loading"
							/>
						</column>
					</row>
				</div>
				<div v-if="test_type === 'lead_id'" class="control-group">
					<div class="inner">
						<label class="control-label">Lead ID:</label>
						<div class="controls">
							<div class="field">
								<p-input-text id="lead-id" v-model="test_lead_id" />
							</div>
						</div>
					</div>
				</div>
				<div class="test-data" v-if="test_data">
					<code-editor v-model.parse="test_data" max-height="300px" />
				</div>
			</div>
			<template #footer>
				<div class="flex justify-content-end gap-2">
					<p-button
						label="Send Post Only"
						:disabled="!test_data || !local_integration.post_enabled"
						:loading="test_loading"
						icon="pi pi-send"
						@click="sendTestPostOnly"
						class="mt-2"
					/>

					<p-button
						label="Send Test Ping/Post"
						:disabled="!test_data || !local_integration.ping_enabled"
						:loading="test_loading"
						icon="pi pi-send"
						@click="sendTestPingPost"
						class="mt-2"
					/>
				</div>
			</template>
		</p-dialog>
		<!-- END TEST MODAL -->
		<!-- START RESPONSE MODAL -->
		<p-dialog
			id="ping-post-response-modal"
			v-model:visible="show_response_modal"
			:modal="true"
			:pt="{
				root: { class: 'w-12 sm:w-12 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="lan-connect" size="24px" style="color: var(--color-b)" />
					<strong>Ping/Post Response</strong>
				</div>
			</template>
			<div class="control-group" v-if="local_integration.ping_enabled">
				<div class="inner">
					<label class="control-label">Ping Response:</label>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse="ping_response" />
						</div>
					</div>
				</div>
			</div>
			<div v-if="post_response" class="control-group">
				<div class="inner">
					<label class="control-label">Post Response:</label>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse="post_response" />
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="flex justify-content-end">
					<p-button label="Close" icon="pi pi-times" @click="show_response_modal = false" />
				</div>
			</template>
		</p-dialog>
		<!-- END RESPONSE MODAL -->
	</div>
</template>

<script lang="ts">
import pMessage from 'primevue/message';
import { useCampaignStore } from '@/stores/campaign';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { updateIntegration, getLead, getRandomLead, sendPing, sendPost, sendPostOnly } from '@GQL';
import { omit, isEqual, cloneDeep } from 'lodash-es';
import { ulid } from 'ulid';
import LineLoader from '@/components/elements/LineLoader.vue';

export default {
	name: 'PingPostContent',
	setup() {
		return { sessionStore: useSessionStore() };
	},
	components: {
		LineLoader,
		pMessage,
	},
	data() {
		// const integration_type_options = [
		// 	{ label: 'Custom', value: 'custom' },
		// 	{ label: 'Boberdoo', value: 'boberdoo' },
		// ];
		return {
			// integration_type_options,
			type: 'custom',
			show_integration_modal: false,
			show_test_modal: false,
			show_response_modal: false,
			local_integration: {
				account_id: this.$route.params.account_id,
				created_by: '',
				ping_enabled: false,
				post_enabled: false,
				options: {},
				ping_settings: null,
				post_settings: null,
			},
			test_type: 'lead_data',
			test_lead_id: '',
			test_data: '',
			test_loading: false,
			ping_response: '',
			post_response: '',
		};
	},
	async mounted() {
		setTimeout(() => {
			if (this.campaignStore.integration) {
				const integration = cloneDeep(this.campaignStore.integration);
				this.local_integration = integration;
			}
		}, 500);
	},
	computed: {
		...mapStores(useCampaignStore),
	},
	methods: {
		addIntegration() {
			this.local_integration = {
				account_id: this.$route.params.account_id,
				created_by: this.$root.sessionStore.user.id,
				ping_enabled: false,
				ping_settings: null,
				post_enabled: false,
				post_settings: null,
				options: {},
			};

			this.show_integration_modal = true;
		},
		async insertPendingPingPost() {
			const copy = cloneDeep(this.local_integration);
			if (!this.local_integration.ping_enabled) {
				copy.ping_settings = null;
			}

			if (!this.local_integration.post_enabled) {
				copy.post_settings = null;
			}

			if (this.local_integration.options === '') {
				copy.options = null;
			}

			this.campaignStore.pending_integration = copy;
			this.show_integration_modal = false;
			this.$toast.add({
				severity: 'success',
				summary: 'Integration is pending',
				detail: 'Save campaign to finalize or test',
				life: 3000,
			});
		},
		async editPingPost() {
			this.show_integration_modal = true;
		},
		async sendTestPostOnly() {
			this.test_loading = true;
			try {
				const post_result = await sendPostOnly({
					integration_id: this.local_integration.id,
					test_lead: this.test_data,
				});
				if (post_result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Sent test Post',
						life: 3000,
					});
					this.post_response = post_result;
				}

				this.show_response_modal = true;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Error sending test Ping',
					detail: err.response.errors[0]?.message || err.message,
					life: 3000,
				});
			} finally {
				this.test_loading = false;
			}
		},
		async sendTestPingPost() {
			this.test_loading = true;
			try {
				const ping_result = await sendPing({
					integration_id: this.local_integration.id,
					lead_data: this.test_data,
				});
				if (ping_result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Sent test Ping',
						life: 3000,
					});
					this.ping_response = ping_result;
					if (ping_result.result === 'bid' && this.local_integration.post_enabled) {
						// Send Post, purchase_id does not matter for tests since it will be faked in pingpost service
						try {
							const post_result = await sendPost({
								integration_id: this.campaignStore.campaign.id,
								purchase_id: ulid(),
							});
							if (post_result) {
								this.$toast.add({
									severity: 'success',
									summary: 'Sent test Post',
									life: 3000,
								});
								this.post_response = post_result;
							}
						} catch (err) {
							this.$toast.add({
								severity: 'error',
								summary: 'Error sending test Post',
								detail: err.response.errors[0]?.message || err.message,
								life: 3000,
							});
						}
					}
				}

				this.show_response_modal = true;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error sending test Ping',
					detail: err.response.errors[0]?.message || err.message,
					life: 3000,
				});
			} finally {
				this.test_loading = false;
			}
		},
		async updatePingPost(id: string) {
			try {
				const copy = omit(this.local_integration, ['id']);
				if (!this.local_integration.ping_enabled) {
					copy.ping_settings = null;
				}

				if (!this.local_integration.post_enabled) {
					copy.post_settings = null;
				}

				if (this.local_integration.options === '' || Object.keys(this.local_integration.options).length === 0) {
					copy.options = null;
				}

				const result = updateIntegration(id, copy);
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Integration has been updated',
						life: 3000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Error updating integration',
					detail: err.response.errors[0]?.message || err.message,
					life: 3000,
				});
			}
			this.show_integration_modal = false;
		},
		async createLeadData(test_id?: string) {
			this.test_loading = true;
			try {
				let test_lead;
				if (test_id) {
					// Use a known lead to test
					test_lead = await getLead(test_id);
				} else {
					// Get a random lead that matches the vertical/product
					test_lead = await getRandomLead(
						this.campaignStore.campaign.vertical_id_targeting,
						this.campaignStore.campaign.product_targeting
					);
				}

				if (test_lead.lat && typeof test_lead.lat === 'string') {
					test_lead.lat = parseFloat(test_lead.lat);
				}

				if (test_lead.lng && typeof test_lead.lng === 'string') {
					test_lead.lng = parseFloat(test_lead.lng);
				}
				// Replace fields with test data
				test_lead.id = 'T-0000-0000';
				test_lead.vendor_lead_id = 'T-0000-0000';
				test_lead.test = true;
				test_lead.first_name = 'John';
				test_lead.last_name = 'Doe';
				test_lead.street_1 = '123 Main Street';
				test_lead.source_id = 'NG_HE_TEST';
				// Generate random phone and email
				test_lead.phone = Math.floor(3234567891 + Math.random() * 1234567891).toString();
				test_lead.email = `test${Math.floor(Math.random() * 1000)}@gmail.com`;
				test_lead.created_at = new Date();

				this.test_data = test_lead;
			} catch (err) {
				console.error(err, 'Loading Lead');
			} finally {
				this.test_loading = false;
			}
		},
	},
	watch: {
		'local_integration.ping_enabled'(new_value, old_value) {
			if (!isEqual(new_value, old_value)) {
				if (new_value && this.local_integration.ping_settings === null) {
					// this.local_integration.ping_enabled = true;
					this.local_integration.ping_settings = {
						debug: false,
						script: '',
					};
				} else if (new_value === false && this.local_integration.ping_settings !== null) {
					this.local_integration.ping_settings = null;
				}
			}
		},
		'local_integration.post_enabled'(new_value, old_value) {
			if (!isEqual(new_value, old_value)) {
				if (new_value && this.local_integration.post_settings === null) {
					this.local_integration.post_settings = {
						debug: false,
						script: '',
					};
				} else if (new_value === false && this.local_integration.post_settings !== null) {
					this.local_integration.post_settings = null;
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.clickable:hover {
	cursor: pointer;
}

p {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

.ping-post-switch {
	align-items: center;
	display: flex;
	flex-direction: row;
	font-size: var(--font-size-sm);
	font-weight: bold;
	gap: 10px;
	justify-content: flex-start;
	margin: 10px 0;
}

.integration,
.integration-detail {
	width: 100%;
}

.ping-post-details {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

.admin {
	background: transparent;
}
</style>
