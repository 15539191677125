import { $GQL } from '@GQL/index';
import { startOfDay, endOfDay } from 'date-fns';
import type { QueryFilters } from '@nextgenleads/db';

export async function getAccountDashboardData(
	id: string,
	base_filters: QueryFilters,
	compare_period_filters: QueryFilters
) {
	const campaign_params = {
		filters: [[`account_id = '${id}'`, "status = 'active'"]],
	};
	// const filters = [
	// 	[
	// 		{
	// 			field: 'created_at',
	// 			op: 'gt',
	// 			value: startOfDay(new Date()),
	// 		},
	// 		{
	// 			field: 'created_at',
	// 			op: 'lt',
	// 			value: endOfDay(new Date()),
	// 		},
	// 	],
	// ];

	const query = `
        query GetAccountDashboardData($id: ID, $filters: [[Any]]!, $campaign_params: GetListParams!, $compare_period_filters: [[Any]]!) {
            account(id: $id) {
                id
                mpid
                name
                display_name
                current_spend: spend(params: $filters)
                previous_spend: spend(params: $compare_period_filters)
                balance {
                    actual
                }
            }
			current_purchase_count: purchaseCount(filters: $filters)
			previous_purchase_count: purchaseCount(filters: $compare_period_filters)
            campaignList(params: $campaign_params) {
            	row_count
            }
        }
    `;

	// const note_params = {
	// 	filters : [[`account_id = '${id}'`]],
	// 	pagination : {
	// 		page: 0,
	// 		page_size: 10
	// 	},
	// 	order : {
	// 		field: 'created_at',
	// 		desc: true
	// 	}
	// }

	const result = await $GQL.request(query, {
		id,
		filters: base_filters,
		compare_period_filters,
		campaign_params,
	});
	return result;
}
