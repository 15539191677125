<template>
	<div id="edit-job" class="view-content">
		<div class="page-heading">
			<h1>{{ heading }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<loader :show="loading" />
		<p-card style="max-width: 960px">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="name">Name:</label>
						<div class="controls">
							<div class="field">
								<p-input-text id="name" v-model="job_config.name" class="p-inputtext" placeholder="Job name" />
								<div v-if="v$.job_config.name.$error" class="validation-error">
									{{ v$.job_config.name.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Description:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="job_config.description" />
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="schedule">Schedule:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="job_config.schedule" placeholder="Cron Job String (e.g. 5 * 5 * *) " />
								<div v-if="v$.job_config.schedule.$error" class="validation-error">
									{{ v$.job_config.schedule.$errors[0].$message }}
								</div>
								<div class="sub-value">
									{{ cronExpression }}
									<a href="https://crontab.guru/" target="_blank">Additional Information</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="script-name">Script Name:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="job_config.script_name" placeholder="Enter script name" />
								<div v-if="v$.job_config.script_name.$error" class="validation-error">
									{{ v$.job_config.script_name.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="script-name">Script Type:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="job_config.script_type"
									:options="script_type_options"
									option-label="label"
									option-value="value"
									placeholder="Select script type"
								/>
								<div v-if="v$.job_config.script_type.$error" class="validation-error">
									{{ v$.job_config.script_type.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="parameters">Parameters:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="parameters" v-model.parse="job_config.parameters" />
							</div>
						</div>
					</div>
				</div>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field-row gap-10">
								<div class="field fit">
									<p-input-switch v-model="has_max_exec_time"></p-input-switch>
								</div>
								<div class="field">
									<label for="active-deadline">Max Execution Time:</label>
									<p class="sub-value mt-1">
										This optional field enforces the maximum execution time of the job. Use only when you need a job to
										end after a designated number of seconds.
									</p>
								</div>
								<gutter size="80px" />
								<div class="field s">
									<div class="p-inputgroup">
										<p-input-number
											v-model="job_config.max_execution_time"
											:disabled="!has_max_exec_time"
											placeholder="Max Exec Time"
											class="input-align-right"
										/>
										<div class="p-inputgroup-addon">sec</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="id">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="job_config.status"
									option-label="label"
									option-value="value"
									:options="status_options"
									placeholder="Select status"
								/>
								<div v-if="v$.job_config.status.$error" class="validation-error">
									{{ v$.job_config.status.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<gutter size="20px" />
			</template>
			<template #footer>
				<div class="flex justify-content-between align-items-center">
					<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
					<div class="flex">
						<p-button label="Run Job" @click="runJob" />
						<gutter size="20px" />
						<p-button icon="pi pi-check" label="Save Changes" @click="submit" />
					</div>
				</div>
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import pCard from 'primevue/card';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { insertJobConfig, updateJobConfig, getJobConfigById, runJob } from '@GQL/index';
import { useSessionStore } from '@/stores/session';
import { cloneDeep } from 'lodash-es';
import { toString as cronstrue } from 'cronstrue';

const default_job_config = {
	name: '',
	description: '',
	schedule: '',
	script_name: '',
	script_type: 'jobs',
	parameters: {},
	status: '',
};

export default {
	name: 'EditJobConfigs',
	components: {
		pCard,
	},
	setup() {
		return {
			v$: useVuelidate(),
			sessionStore: useSessionStore(),
		};
	},
	data() {
		const heading = this.$route.meta.new ? 'Create Job Config' : 'Edit Job Config';
		return {
			loading: false,
			has_max_exec_time: false,
			heading,
			script_type_options: [
				{
					label: 'Job',
					value: 'jobs',
				},
				{
					label: 'Scraper',
					value: 'scrapers',
				},
			],
			status_options: [
				{
					label: 'Active',
					value: 'active',
				},
				{
					label: 'Disabled',
					value: 'disabled',
				},
				{
					label: 'Archived',
					value: 'archived',
				},
			],
			breadcrumb_items: [{ label: 'Job Configs', route: '/job-configs' }, { label: heading }],
			job_config: { ...default_job_config },
		};
	},
	computed: {
		cronExpression() {
			try {
				return cronstrue(this.job_config.schedule) + ' - ';
			} catch (err) {
				return '';
			}
		},
	},
	methods: {
		async runJob() {
			try {
				const res = await runJob({
					script_name: this.job_config.script_name,
					script_type: this.job_config.script_type,
					ran_by: this.sessionStore.user.id,
					parameters: this.job_config.parameters,
				});
				if (res) {
					this.$toast.add({
						summary: 'Job has been queued',
						details: 'The Job Name is: ' + res.name,
						life: 5000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to launch job',
					life: 6000,
				});
			}
		},
		async submit() {
			// for when a value is set but needs to be ignored
			// ensure it isn't in the object
			if (!this.has_max_exec_time) {
				delete this.job_config.max_execution_time;
			}

			const is_valid = await this.v$.$validate();

			// validate
			if (is_valid) {
				try {
					let result;

					if (this.$route.meta.new) {
						// get the type from options
						this.job_config.created_by = this.sessionStore.user.id;
						// we insert
						result = await insertJobConfig(this.job_config);
					} else {
						result = await updateJobConfig(this.job_config.id, this.job_config);
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: 'Changes were saved',
							life: 5000,
						});
						this.$router.back();
					} else {
						throw new Error('Invalid response');
					}
				} catch (err) {
					console.error(err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save',
						life: 6000,
					});
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Form is invalid',
					life: 6000,
				});
			}
		},
	},
	async mounted() {
		if ('job_config_id' in this.$route.params) {
			const result = await getJobConfigById(this.$route.params.job_config_id);
			this.job_config = cloneDeep({ ...default_job_config, ...cloneDeep(result) });
			if ('max_execution_time' in this.job_config && typeof this.job_config.max_execution_time === 'number') {
				this.has_max_exec_time = true;
			}
		}
	},
	validations() {
		return {
			job_config: {
				name: {
					required: helpers.withMessage('A name is required.', required),
				},
				schedule: {
					required: helpers.withMessage('A schedule is required.', required),
					regex: helpers.withMessage('Invalid CRON string.', (value) => {
						const reg = /(((\d+,)+\d+|(\d+(\/|-)\d+)|\d+|\*) ?){5,7}/g;
						return reg.test(value);
					}),
				},
				script_name: {
					required: helpers.withMessage('A script name is required.', required),
				},
				script_type: {
					required: helpers.withMessage('A script type is required.', required),
				},
				status: {
					required: helpers.withMessage('A status is required.', required),
				},
			},
		};
	},
};
</script>
