import { $GQL } from '@GQL/index';
import { orderBy } from 'lodash-es';

export async function getChildAccountsAsOptions(parent_account_id: string) {
	const query = `
    query GetChildAccountsAsOptions($filters: [[Any]]) {
        accounts(filters: $filters) {
          id
          name
          balance {
            actual
          }
          primary_user {
            first_name
            last_name
          }
        }
      }`;

	const filters = [[`parent_account_id = '${parent_account_id}'`]];

	const result = (await $GQL.request(query, { filters, no_cache: ['accounts'] })).accounts;

	const as_options = result.map((row) => {
		return {
			value: row.id,
			label: row.name,
			primary_user: row.primary_user.first_name + ' ' + row.primary_user.last_name,
		};
	});

	return orderBy(as_options, ['label', 'primary_user'], ['asc', 'asc']);
}
