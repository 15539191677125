import LoginView from '@/views/Login/Login.vue';
import SignUpView from '@/views/SignUp/Page.vue';

export default [
	{
		path: 'login',
		name: 'Login',
		component: LoginView,
		meta: {
			public: true,
		},
	},
	{
		path: 'reset-password',
		name: 'ResetPasswordRequest',
		component: LoginView,
		meta: {
			public: true,
		},
	},
	{
		path: 'reset-password/:token',
		name: 'ResetPassword',
		component: LoginView,
		meta: {
			public: true,
		},
	},
	{
		path: 'sign-up',
		name: 'SignUp',
		component: SignUpView,
		meta: {
			public: true,
			show_header: true,
			show_footer: true,
		},
	},
	{
		path: 'sign-up/:sign_up_path',
		name: 'UniqueSignUp',
		component: SignUpView,
		meta: {
			public: true,
			show_header: true,
			show_footer: true,
		},
	},
	{
		path: 'complete-sign-up/:token',
		name: 'CompleteSignUp',
		component: LoginView,
		meta: {
			public: true,
		},
	},
];
