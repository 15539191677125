<template>
	<div class="attribute-section" :class="{ active: enable_age_filter }">
		<div class="header">
			<div class="title">
				<label class="clickable-label" for="household-switch">Target by Age</label>
			</div>
			<div class="switch">
				<p-input-switch v-model="enable_age_filter" input-id="age-switch" />
			</div>
		</div>
		<div v-if="enable_age_filter" class="filter">
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Age:</label>
					<div class="controls">
						<div class="field fit text-align-center">
							<div class="slider-value">
								{{ ageValue(custom_bid_age[0]) }}
							</div>
						</div>
						<div class="field slider">
							<p-slider v-model="custom_bid_age" class="slider" :max="120" :min="18" range @slideend="sortAge" />
						</div>
						<div class="field fit text-align-center">
							<div class="slider-value">
								{{ ageValue(custom_bid_age[1]) }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pSlider from 'primevue/slider';

export default {
	name: 'AgeAttribute',
	emits: ['update'],
	components: {
		pSlider,
	},
	data() {
		return {
			enable_age_filter: false,
			custom_bid_age: [18, 120],
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
				});
			},
			deep: true,
		},
	},
	methods: {
		ageValue(value) {
			if (value >= 120) {
				return '120+';
			}
			return value.toString();
		},
		sortAge() {
			if (this.custom_bid_age[1] < this.custom_bid_age[0]) {
				this.custom_bid_age = [this.custom_bid_age[1], this.custom_bid_age[0]];
			}
		},
		loadAttribute(custom_bid) {
			const age_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.dob';
			});
			if (age_match) {
				this.enable_age_filter = true;
				this.custom_bid_age = age_match.comparator.value;
			}
		},
		saveAttribute() {
			if (this.enable_age_filter) {
				return {
					target: {
						path: 'lead.data.dob',
						transformer: 'age',
					},
					strategy: 'in_range',
					comparator: {
						value: this.custom_bid_age,
					},
				};
			}
			return null;
		},
	},
};
</script>
