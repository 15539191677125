import { $GQL } from '@GQL/index';
import { sortBy } from 'lodash-es';

export async function getNetworksAsOptions() {
	const filters: string[] = [];

	const query = `
        query GetNetworksAsOptions($filters: [[Any]]!) {
            networks(filters: $filters) {
                value:id
                label:name
            }
        }
    `;

	const results = await $GQL.request(query, { filters });
	return sortBy(results.networks, ['label']);
}
