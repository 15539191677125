<template>
	<div class="settings-form">
		<h2>Auction Settings</h2>
		<p-fieldset legend="Grouping Shared Bids">
			<p>
				Select the products below that you want to group shared bids for. By turning on grouped shared bids, all shared
				bids will compete in external auctions as a single exclusive bid, meaning a lead that is won will only be shared
				between campaigns within your own marketplace.
			</p>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field fit">
							<p-checkbox input-id="group-shared-data-bids" v-model="auctions.group_shared_bids.data" binary />
						</div>
						<label for="group-shared-data-bids">Group Shared Bids for Data Leads</label>
					</div>
				</div>
			</div>
			<!-- <div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field fit">
							<p-checkbox input-id="group-shared-ad-bids" v-model="auctions.group_shared_bids.ad" binary />
						</div>
						<label for="group-shared-ad-bids">Group Shared Bids for Ads</label>
					</div>
				</div>
			</div> -->
		</p-fieldset>
		<div class="actions">
			<p-button label="Save" icon="pi pi-check" @click="updateSetting()" />
			<gutter size="20px" />
			<p-button label="Reset" text @click="resetSetting()" />
		</div>
	</div>
</template>

<script>
import { cloneDeep, merge } from 'lodash-es';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';
import { getSettings, updateSetting } from '@GQL';

export default {
	name: 'MarketplaceAuctionsSettings',
	data() {
		return {
			defaults: null,
			auctions: {
				group_shared_bids: {
					data: false,
					ad: false,
				},
			},
		};
	},
	computed: {
		...mapStores(useMarketplaceStore),
	},
	async mounted() {
		const settings_result = await getSettings(['auctions'], this.marketplaceStore.mpid);
		this.auctions = merge(this.auctions, settings_result.settings.auctions);
		this.defaults = cloneDeep(this.auctions);
	},
	methods: {
		async updateSetting() {
			const settings_result = await updateSetting(
				'auctions',
				{
					data: this.auctions,
				},
				this.marketplaceStore.mpid
			);
			if (settings_result.id === 'auctions') {
				this.$toast.add({
					severity: 'success',
					summary: 'Saved Settings',
					detail: 'auctions',
					life: 3000,
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
				});
			}
		},
		resetSetting() {
			this.auctions = cloneDeep(this.defaults);
		},
	},
};
</script>

<style scoped lang="less">
:deep(*) {
	.p-inputnumber.short {
		max-width: 100px;
	}

	.p-inputnumber-input {
		text-align: right;
	}

	.field.caption {
		align-items: center;
		display: flex;
		height: 40px;
	}
}

.field {
	margin-bottom: 0;
}
</style>
