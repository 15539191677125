import { $GQL } from '@GQL/index';
import type { CaptureConfig } from '@nextgenleads/lead-driver';

export async function insertCaptureConfig(data: Omit<CaptureConfig, 'created_at'>) {
	const mutation = `
        mutation InsertCaptureConfig($data: JSONObject!) {
            insertCaptureConfig(data: $data) {
                id
            }
        }
    `;
	return await $GQL.request(mutation, { data });
}
