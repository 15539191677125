import { $GQL } from '@GQL/index';

export async function getCampaignStateCompetition(
	params: { campaign_id: string; date_range: string[] },
	no_cache: string[]
): Promise<any> {
	const query = `
        query GetCampaignStateCompetition($params: JSONObject!) {
            reportCampaignStateCompetition(params: $params)
        }
    `;

	const response = await $GQL.request(query, { params, no_cache });
	return response.reportCampaignStateCompetition;
}
