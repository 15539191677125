<template>
	<div>
		<p-button
			text
			type="button"
			severity="secondary"
			aria-haspopup="true"
			aria-controls="overlay_menu"
			aria-label="Tools"
			@click="toggleTools"
		>
			<icon type="toolbox" size="24px" />
		</p-button>
		<p-menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
		<p-dialog v-model:visible="show_return_modal" :modal="true" class="return-purchase-modal" :dismissable-mask="true">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="cash-refund" size="24px" style="color: var(--color-b)" />
					<strong>Return Lead</strong>
				</div>
			</template>
			<p-message
				v-if="returnable"
				severity="info"
				:pt="{
					root: { class: 'mt-0' },
				}"
			>
				Lead purchases have a return window of 2 weeks.</p-message
			>
			<p-message
				v-else
				severity="error"
				:pt="{
					root: { class: 'mt-0' },
				}"
			>
				{{ return_explanation }}
			</p-message>
			<div v-if="returnable" class="form-group flex-flex-column gap-2">
				<label class="block mb-2 req">Select a reason for your return:</label>
				<p-dropdown id="reason" v-model="reason" :options="return_options" placeholder="Select reason" />
				<div v-if="v$.reason.$error" class="validation-error">
					{{ v$.reason.$errors[0].$message }}
				</div>
			</div>
			<gutter size="20px" />
			<div v-if="returnable" class="form-group flex flex-column gap-2">
				<label>Notes:</label>
				<p-textarea
					id="notes"
					v-model="note"
					style="height: 80px"
					placeholder="Tell us why you would like to return this purchase"
				/>
			</div>
			<template #footer>
				<div class="flex justify-content-end">
					<p-button v-if="returnable" label="Request Return" @click="submitReturn" />
					<p-button v-else label="Close" @click="show_return_modal = false" />
				</div>
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import pMenu from 'primevue/menu';
import pMessage from 'primevue/message';
import pTextarea from 'primevue/textarea';
import { returnPurchase, insertConversion, updateConversion } from '@GQL';
import { addWeeks, isWithinInterval } from 'date-fns';
import log from '@/lib/Log';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { get } from 'lodash-es';

export default {
	name: 'ReturnLead',
	components: {
		pMenu,
		pMessage,
		pTextarea,
	},
	props: {
		purchase: {
			type: Object,
		},
		conversion: {
			type: Object,
		},
	},
	setup() {
		return { v$: useVuelidate() };
	},
	emits: ['updatePurchase'],
	data() {
		return {
			show_return_modal: false,
			return_options: ['Invalid Contact Info', 'Other'],
			reason: null,
			note: null,
			returnable: false,
			return_explanation: '',
			has_bulk_returns_enabled: false,
			items: [
				{
					label: 'Tools',
					items: [
						{
							label: 'Return Lead',
							icon: 'pi pi-refresh',
							command: () => {
								this.show_return_modal = true;
							},
						},
					],
				},
			],
		};
	},
	computed: {
		account_id() {
			if ('account_id' in this.$route.params) {
				return this.$route.params.account_id;
			} else {
				return this.$root.sessionStore.account.id;
			}
		},
	},
	async mounted() {
		if (this.$route.params.account_id) {
			const account = await getAccountById(this.account_id);
			this.has_bulk_returns_enabled = get(account, 'settings.bulk_returns.enabled', false);
		} else {
			this.has_bulk_returns_enabled = get(this.$root.sessionStore, 'account.settings.bulk_returns.enabled', false);
		}
		await this.isReturnable();
	},
	methods: {
		toggleTools(event) {
			this.$refs.menu.toggle(event);
		},
		async submitReturn() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					const result = await returnPurchase(this.purchase.id, this.reason, this.note);

					if (this.conversion) {
						updateConversion(this.purchase.id, {
							disposition: `Return - ${this.reason}`,
						});
					} else {
						insertConversion({
							purchase_id: this.purchase.id,
							lead_id: this.$route.params.lead_id,
							disposition: `Return - ${this.reason}`,
							source: 'UI',
							created_by: this.$root.sessionStore.user.id,
							account_id: this.account_id,
						});
					}
					log.trace('Return Request: ', result);
					this.$toast.add({
						summary: 'Return request has been made',
						life: 5000,
					});
					this.reason = null;
					this.note = null;
					this.show_return_modal = false;
					this.$emit('updatePurchase');
				} catch (err) {
					log.error('Unable to return lead', err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to return lead',
						life: 6000,
					});
				}
			}
		},
		async isReturnable(): boolean {
			this.return_explanation = 'The return window for this lead has passed.';

			const todays_date = new Date();
			const purchase_date = new Date(this.purchase.created_at);
			const two_weeks_after_purchase = addWeeks(purchase_date, 2);
			const is_within_return_window = isWithinInterval(todays_date, {
				start: purchase_date,
				end: two_weeks_after_purchase,
			});

			// see if the campaign/settings have bulk_returns enabled otherwise just check return window
			const campaign_has_bulk_returns_enabled = get(this.purchase, 'campaign.flags.bulk_returns.enabled', false);
			if (campaign_has_bulk_returns_enabled && this.has_bulk_returns_enabled) {
				this.return_explanation = 'This lead will be counted towards your bulk returns';
				this.returnable = false;
			} else {
				this.returnable = is_within_return_window;
			}
		},
	},
	validations() {
		return {
			reason: {
				required: helpers.withMessage('You must specify why you want to return this lead.', required),
			},
		};
	},
};
</script>

<style scoped>
label {
	color: var(--gray-60);
	font-size: var(--font-size-sm);
	font-weight: bold;
}
</style>
