<template>
	<div v-if="menuCollapsed" id="support-nav">
		<div v-tooltip.right="'Support Docs'" class="icon-wrapper">
			<a :href="`${docs_link}`">
				<alt-icon type="unknown_document" />
			</a>
		</div>
		<template v-if="$root.appStore.mpid !== '$NG' || isNested">
			<div v-tooltip.right="`Call Support: ${phoneFormat(phone)}`" class="icon-wrapper">
				<a :href="'tel:+1' + phone">
					<alt-icon type="perm_phone_msg" />
				</a>
			</div>
			<div v-tooltip.right="`Email/SMS Support\n${email}`" class="icon-wrapper">
				<a :href="'mailto:' + email">
					<alt-icon type="mail" />
				</a>
			</div>
		</template>
	</div>
	<div v-else id="support-nav">
		<div v-tooltip.top="'Support Docs'" class="icon-wrapper">
			<a :href="`${docs_link}`">
				<alt-icon type="unknown_document" />
			</a>
		</div>
		<template v-if="$root.appStore.mpid !== '$NG' || isNested">
			<div v-tooltip.top="`Call Support: ${phoneFormat(phone)}`" class="icon-wrapper">
				<a :href="'tel:+1' + phone">
					<alt-icon type="perm_phone_msg" />
				</a>
			</div>
			<div
				v-tooltip.top="{
					value: `Email/SMS Support\n${email}`,
				}"
				class="icon-wrapper"
			>
				<a :href="'mailto:' + email">
					<alt-icon type="mail" />
				</a>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
import { useMarketplaceStore } from '@/stores/marketplace';
import { phoneFormat } from '@/lib/Filters';
import { mapStores } from 'pinia';

export default {
	name: 'SupportMenu',
	props: {
		menuCollapsed: Boolean,
	},
	inject: ['isNested'],
	computed: {
		phone() {
			if (this.$root.sessionStore.account.support) {
				return this.$root.sessionStore.account.support.account_manager.phone;
			}
			return '8587036007';
		},
		email() {
			if (this.$root.sessionStore.account.support) {
				return this.$root.sessionStore.account.support.account_manager.email;
			}
			return 'support@nextgenleads.com';
		},
		...mapStores(useMarketplaceStore),
	},
	data() {
		return {
			docs_link:
				'https://docs.nextgenleads.com/support?jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQnV5ZXIiLCJlbWFpbCI6InN1cHBvcnRAbmV4dGdlbmxlYWRzLmNvbSIsImlhdCI6MTcwOTg1OTcxN30.9Lt-k92n3hjCBlxbBCw6tfFhxcerSrac-9eVD0_Amlk&reload',
		};
	},
	methods: {
		phoneFormat,
	},
};
</script>

<style scoped lang="less">
#support-nav {
	justify-self: flex-end;
	padding: 10px;
	text-align: center;

	a {
		color: var(--gray-35);

		&:hover {
			color: var(--color-b);
		}
	}
}
</style>
