<template>
	<div>
		<h2>Signup Source IDs</h2>
		<p-dialog v-model:visible="show_modal" modal :dismissable-mask="false" :closeOnEscape="false" style="width: 400px">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
					<strong>Edit Signup Source ID</strong>
				</div>
			</template>
			<div class="content">
				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req" for="role-id">Signup Source ID:</label>
									<p-input-text v-model="new_source_id" class="p-inputtext" placeholder="Signup Source ID" />
									<div v-if="v$.new_source_id.$error" class="validation-error">
										{{ v$.new_source_id.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls w-full">
							<div class="field-row gap-20">
								<div class="field">
									<label class="control-label req">Source ID Description:</label>
									<p-input-text
										v-model="new_source_description"
										class="p-inputtext"
										placeholder="ex: LinkedIn PPC campaign targeting Medicare..."
									/>
									<div v-if="v$.new_source_description.$error" class="validation-error">
										{{ v$.new_source_description.$errors[0].$message }}
									</div>
									<gutter size="5px" />
									<p class="sub-value">
										This description will be shown to anyone editing the Source ID in the account page
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<template #footer class="flex justify-content-end">
				<p-button text label="Cancel" @click="cancelChange" />
				<p-button label="Add Change" @click="saveChange" />
			</template>
		</p-dialog>
		<gutter size="20px" />

		<div>
			<p-button label="Add Signup Source ID" @click="openModal" />
			<gutter v-if="dirty" size="20px" />
			<div v-if="dirty" class="flex justify-content-between">
				<div>
					<h3 :style="{ color: 'red', 'font-style': 'italic' }">Changes pending, Save to implement</h3>
				</div>
				<div class="flex justify-content-end">
					<p-button label="Reset" text @click="reset" />
					<p-button :disable="hasPendingChange" label="Save" @click="save" />
				</div>
			</div>
		</div>

		<gutter size="20px" />

		<p-data-table ref="dt" :value="signup_source_ids" key="key" :loading="loading">
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>

			<p-column header="Source ID">
				<template #body="row">
					{{ row.data.key }}
				</template>
			</p-column>
			<p-column header="Source Description">
				<template #body="row">
					{{ row.data.value }}
				</template>
			</p-column>
			<p-column header="Tools">
				<template #body="row">
					<p-button v-tooltip.top="'Edit'" text @click="editValue(row.data)">
						<template #icon>
							<icon type="pencil-box-outline" size="24px" />
						</template>
					</p-button>
					<p-button v-tooltip.top="'Delete'" text @click="removeValue(row.data)">
						<template #icon>
							<icon type="trash-can-outline" size="24px" />
						</template>
					</p-button>
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>
<script>
import { getSettings, updateSetting } from '@GQL';
import { cloneDeep, findIndex } from 'lodash-es';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
export default {
	name: 'SignupSourceIDs',
	emits: ['refresh'],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			show_modal: false,
			loading: false,
			original_map: null,
			signup_source_ids: [],
			new_source_id: '',
			new_source_description: '',
			dirty: false,
		};
	},
	computed: {
		hasPendingChange() {
			return this.new_source_description !== '' || this.new_source_id !== '';
		},
	},
	async mounted() {
		await this.getSetting();
	},
	methods: {
		cancelChange() {
			this.new_source_id = '';
			this.new_source_description = '';
			this.v$.$reset();
			this.show_modal = false;
		},
		async saveChange() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				this.signup_source_ids = [
					{ key: this.new_source_id, value: this.new_source_description },
					...this.signup_source_ids,
				];
				this.dirty = true;
				this.cancelChange();
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Cannot save change',
					detail: 'One of the required fields is missing',
					life: 7000,
				});
			}
		},
		async save() {
			try {
				this.loading = true;
				// check that nothhing is pending in the new
				const new_map = {};
				this.signup_source_ids.forEach((row) => {
					new_map[row.key] = row.value;
				});
				const settings_result = await updateSetting(
					'signup_source_id_options',
					{
						data: new_map,
					},
					'$NG',
					true
				);
				this.dirty = false;
				if (settings_result.id === 'signup_source_id_options') {
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved Signup Source ID settings',
						life: 5000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save settings',
						life: 7000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getSetting() {
			this.loading = true;
			try {
				// only ever global
				const settings_result = await getSettings(['signup_source_id_options'], '$NG', true);
				this.original_map = cloneDeep(settings_result.settings.signup_source_id_options);
				this.signup_source_ids = Object.keys(this.original_map).map((key) => {
					return {
						key: key,
						value: this.original_map[key],
					};
				});
			} finally {
				this.loading = false;
			}
		},
		reset() {
			this.signup_source_ids = Object.keys(this.original_map).map((key) => {
				return {
					key: key,
					value: this.original_map[key],
				};
			});
			this.dirty = false;
			this.cancelChange();
		},
		editValue(row) {
			this.dirty = true;
			this.new_source_id = row.key;
			this.new_source_description = row.value;
			this.removeValue(row);
			this.openModal();
		},
		removeValue(row) {
			this.dirty = true;
			this.loading = true;
			const index = findIndex(this.signup_source_ids, { key: row.key });
			let copy = cloneDeep(this.signup_source_ids);
			this.removed_source = copy.splice(index, 1);
			this.signup_source_ids = [...copy];
			this.loading = false;
		},
		openModal() {
			this.show_modal = true;
		},
	},
	validations() {
		return {
			new_source_description: {
				required: helpers.withMessage('A Source ID description is required.', required),
			},
			new_source_id: {
				required: helpers.withMessage('Source ID is required.', required),
				spaces: helpers.withMessage('Source IDs should NOT have spaces', (value) => {
					return value.indexOf(' ') === -1;
				}),
			},
		};
	},
};
</script>
