import { $GQL } from '@GQL/index';
import type { Network } from '@nextgenleads/source-driver';

export async function getNetworkById(network_id: string): Promise<Network> {
	const query = `
        query GetNetworkById($network_id: ID!) {
            network(id: $network_id) {
                id
                name
				url
				contacts {
					name
					email
					phone
					type	
				}
            }
        }
    `;

	const result = await $GQL.request(query, { network_id });
	return result.network;
}
