import { $GQL } from '@GQL/index';
import { type Account } from '@nextgenleads/marketplace-driver';

export async function getAccountBillingSettings(account_id: string, refresh?: boolean): Promise<Partial<Account>> {
	const request = `
			query GetAccountBillingSettings($account_id: ID!) {
				account(id: $account_id) {
					settings {
						credit_terms {
							enabled
							limit_enabled
							limit
						}
					}
				}
			}
		`;

	const no_cache = refresh ? ['account'] : undefined;
	const result = await $GQL.request(request, { account_id, no_cache });
	return result.account;
}
