import { $GQL } from '@GQL/index';

export async function reportAccountVelocity(params: { account_id: string }) {
	const query = `
        query ReportAccountVelocity($params: JSONObject!) {
            reportAccountVelocity(params: $params)
        }
    `;

	const results = await $GQL.request(query, {
		params,
	});
	return results;
}
