import { $GQL } from '@GQL/index';

export async function getConversion(purchase_id: string) {
	const query = `
        query GetConversion($purchase_id: ID!){
            conversion(purchase_id: $purchase_id) {
                purchase_id
                lead_id
                account_id
                disposition
                source
            }
        }
    `;

	const result = await $GQL.request(query, { purchase_id });
	return result.conversion;
}
