import { $GQL } from '@GQL/index';

export async function getRoundRobinAccountManager(mpid: string, vertical_id?: string): Promise<string> {
	const query = `
		query GetRoundRobinAccountManager($mpid: String!, $vertical_id: String) {
            roundRobinAccountManager(mpid: $mpid, vertical_id: $vertical_id)
        }
	`;

	const result = await $GQL.request(query, { mpid, vertical_id });
	return result.roundRobinAccountManager;
}
