<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Leads By Account ID</h1>
			<breadcrumb :model="breadcrumbs" />
		</div>

		<div class="lead-requests-form">
			<p-card class="api-key-card">
				<template #content>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Account or Company ID:</label>
							<div class="controls">
								<div class="field">
									<div class="p-inputgroup">
										<p-input-text
											id="account-id"
											v-model="leads_report_by_account_id.account_id"
											class="key-input flex-1"
											placeholder="Account or Company ID"
											:pt="{
												root: { class: 'border-gray-500 surface-100 text-black-alpha-90 opacity-90' },
											}"
										/>
									</div>
									<div v-if="v$.leads_report_by_account_id.account_id.$error" class="validation-error">
										{{ v$.leads_report_by_account_id.account_id.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="control-group mt-4">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<date-range v-model="range" :rangeLimit="rangeLimit" hide-labels show-time />
								</div>
							</div>
						</div>
					</div>
					<div class="control-group mt-4" v-tooltip.top="'These fields will be present in all reports'">
						<div class="inner">
							<label class="control-label">
								<div class="switch flex justify-content-start align-items-center">
									<p-input-switch v-model="switches.basic_fields" @change="toggleBasicFields" />
									<span>Basic Fields:</span>
								</div>
							</label>
							<gutter size="15px" />
							<div class="controls">
								<div class="field">
									<div class="checkbox-grid">
										<div v-for="field of leads_report_by_account_id.fields" :key="field.key">
											<pCheckbox
												v-model="leads_report_by_account_id.selected_fields"
												:inputId="field.name"
												name="field"
												:value="field.name"
											/>
											<span :for="field.name">{{ field.name }}</span>
										</div>
									</div>
									<div v-if="v$.leads_report_by_account_id.selected_fields.$error" class="validation-error">
										{{ v$.leads_report_by_account_id.selected_fields.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						class="control-group mt-4 mb-4"
						v-tooltip.top="'These fields may be present, depending on whether the leads are for health or medicare'"
					>
						<div class="inner">
							<label class="control-label">
								<div class="switch flex justify-content-start align-items-center">
									<p-input-switch v-model="switches.vertical_fields" @change="toggleVerticalFields" />
									<span>Vertical Fields:</span>
								</div>
							</label>
							<gutter size="15px" />
							<div class="controls">
								<div class="field">
									<div class="checkbox-grid">
										<div v-for="field of leads_report_by_account_id.vertical_fields" :key="field.key" :input-id="field">
											<pCheckbox
												v-model="leads_report_by_account_id.selected_vertical_fields"
												:inputId="field.name"
												name="field"
												:value="field.name"
											/>
											<span :for="field.name">{{ field.name }}</span>
										</div>
									</div>
									<div v-if="v$.leads_report_by_account_id.selected_fields.$error" class="validation-error">
										{{ v$.leads_report_by_account_id.selected_fields.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template #footer>
					<div class="flex justify-content-end align-items-right">
						<p-button icon="pi pi-check" label="Send Request" @click.prevent="submit()" />
					</div>
				</template>
			</p-card>
		</div>
	</div>
</template>

<script>
import pCard from 'primevue/card';
import pCheckbox from 'primevue/checkbox';
import pInputSwitch from 'primevue/inputswitch';
import { fetchReportRequestFields, initAdminSession, reportRequest } from '@GQL';
import { required, helpers, minLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import dateRange from '@/components/forms/DateRange.vue';
import { endOfDay, startOfDay } from 'date-fns';

const start_date = startOfDay(new Date(2015, 0, 1)); // 2015 earliest year in databricks
const end_date = endOfDay(new Date());

export default {
	name: 'DataRequests',
	components: {
		pCard,
		pCheckbox,
		dateRange,
		pInputSwitch,
	},
	inject: ['mq'],
	async setup() {
		return {
			v$: useVuelidate(),
		};
	},
	methods: {
		onBasicFieldsHover() {
			this.$toast.add({
				severity: 'info',
				summary: 'Basic Fields',
				detail: 'These fields are available for all accounts.',
				life: 3000,
			});
		},
		toggleBasicFields() {
			this.leads_report_by_account_id.selected_fields =
				this.leads_report_by_account_id.selected_fields.length > 0
					? []
					: this.leads_report_by_account_id.fields.map((field) => field.name);
		},
		toggleVerticalFields() {
			this.leads_report_by_account_id.selected_vertical_fields =
				this.leads_report_by_account_id.selected_vertical_fields.length > 0
					? []
					: this.leads_report_by_account_id.vertical_fields.map((field) => field.name);
		},
		async submit() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					const reportParams = {
						report: 'leads_report_by_account_id',
						email: this.leads_report_by_account_id.email,
						account_id: this.leads_report_by_account_id.account_id,
						start_date: new Date(this.range[0].toISOString()),
						end_date: new Date(this.range[1].toISOString()),
						fields: this.leads_report_by_account_id.selected_fields,
						vertical_fields: this.leads_report_by_account_id.selected_vertical_fields,
					};
					const res = await reportRequest(reportParams);

					if (res.success) {
						this.$toast.add({
							summary: `Successfully submitted leads report request. Results will be sent to ${this.leads_report_by_account_id.email}`,
							life: 10000,
						});
						return;
					}
					throw new Error(res.message);
				} catch (err) {
					// redis key not expired
					const err_msg = err.message;
					if (err_msg.includes('Report request already submitted')) {
						this.$toast.add({
							severity: 'error',
							summary: err_msg,
							life: 3000,
						});
						return;
					} else if (err_msg.includes('Invalid')) {
						// bad schema
						this.$toast.add({
							severity: 'error',
							summary: err_msg,
							life: 3000,
						});
						return;
					} else {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to submit leads report request. Please try again later.',
							life: 3000,
						});
					}
				}
			}
		},
	},
	async mounted() {
		const res = await fetchReportRequestFields('leads_report_by_account_id');
		const fields = res.fetchReportRequestFields.fields.map((field) => {
			return {
				name: field,
				key: field,
			};
		});
		this.leads_report_by_account_id.fields = fields;
		this.leads_report_by_account_id.selected_fields = res.fetchReportRequestFields.fields;

		const vertical_fields = res.fetchReportRequestFields.vertical_fields.map((field) => {
			return {
				name: field,
				key: field,
			};
		});
		this.leads_report_by_account_id.vertical_fields = vertical_fields;
		this.leads_report_by_account_id.selected_vertical_fields = res.fetchReportRequestFields.vertical_fields;

		const admin = await initAdminSession();
		this.leads_report_by_account_id.email = admin.initialize.user.email;
	},
	data() {
		return {
			breadcrumbs: [
				{
					label: 'Leads by Account ID',
				},
			],
			range: [start_date, end_date],
			leads_report_by_account_id: {
				account_id: null,
				email: null,
				fields: [],
				selected_fields: [],
			},
			switches: {
				basic_fields: true,
				vertical_fields: true,
			},
			rangeLimit: {
				days: () => {
					const diff = end_date - start_date;
					const days = diff / (1000 * 60 * 60 * 24);
					return days;
				},
			},
		};
	},
	validations() {
		return {
			leads_report_by_account_id: {
				account_id: {
					required: helpers.withMessage('Account ID required.', required),
					minLength: minLength(1),
					format: helpers.withMessage(
						'Invalid format.',
						helpers.regex(/^([a-zA-Z0-9]{1,6})-([a-zA-Z0-9]{1,6})-([a-zA-Z0-9]{1,6})$/)
					),
				},
				selected_fields: {
					required: helpers.withMessage('At least one field required.', required),
					minLength: minLength(1),
				},
			},
		};
	},
};
</script>

<style lang="less">
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.checkbox-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 10px;
}

.lead-requests-form {
	max-width: 1000px;
}
</style>
