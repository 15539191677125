import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getAdTemplates(filters?: QueryFilters, no_cache?: string[]) {
	const query = `
        query GetAdTemplates($filters: [[Any]]){
            adTemplates(filters: $filters) {
                id
				mpid
				name
				type
				vertical_id
				html
				styles
				scripts
				meta
				status
				created_at
				modified_at
            }
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.adTemplates;
}
