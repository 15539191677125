<template>
	<div id="capture-configs-list" class="view-content">
		<div class="page-heading">
			<h1>Lead Capture Configurations</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-button icon="pi pi-plus" label="New Capture Config" @click.prevent="$router.push(`${this.$route.path}/new`)" />
		<gutter size="20px" />
		<div class="card padded">
			<CaptureConfigList />
		</div>
	</div>
</template>

<script lang="ts">
import CaptureConfigList from './List.vue';

export default {
	name: 'LeadCaptureConfigsListPage',
	components: {
		CaptureConfigList,
	},
	props: {},
	data() {
		return {
			breadcrumb_items: [],
		};
	},
	mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Lead CaptureConfigs',
				},
			];
		} else {
			this.breadcrumb_items = [{ label: 'Lead CaptureConfigs' }];
		}
	},
};
</script>

<style lang="less"></style>
