<template>
	<div class="qualifier previously-purchased">
		<div class="title">
			<div class="name">Previously Purchased</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">Disqualify a campaign if an associated account has already purchased a matching lead.</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Check against purchases from the same:</label>
					<div class="controls gap-20">
						<div class="field fit flex align-items-center">
							<p-checkbox input-id="match-account" v-model="modelValue.match_account" binary />
							<label class="inline" for="match-account">Account</label>
						</div>
						<div class="field fit flex align-items-center">
							<p-checkbox input-id="match-parents" v-model="modelValue.match_parents" binary />
							<label class="inline" for="match-parents">Parent</label>
						</div>
						<div class="field fit flex align-items-center">
							<p-checkbox input-id="match-groups" v-model="modelValue.match_groups" binary />
							<label class="inline" for="match-groups">Group</label>
						</div>
						<div class="field fit flex align-items-center">
							<p-checkbox input-id="match-marketplace" v-model="modelValue.match_marketplace" binary />
							<label class="inline" for="match-marketplace">Marketplace</label>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Lookback:</label>
					<div class="controls gap-20">
						<div class="field xs">
							<div class="p-inputgroup">
								<p-input-number class="input-align-right" v-model="modelValue.look_back_window_days" />
								<div class="p-inputgroup-addon">days</div>
							</div>
						</div>
						<div class="field flex align-items-center">
							<p-checkbox input-id="email-dedupe" v-model="modelValue.use_email_in_dedup" binary />
							<label class="inline" for="email-dedupe">Use Email in Dedupe</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'PreviouslyPurchasedQualifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					match_account: true,
					match_parents: false,
					match_groups: false,
					match_marketplace: false,
					use_email_in_dedup: false,
					table: 'purchases',
					look_back_window_days: 30,
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>

<style scoped lang="less">
label.inline {
	margin: 0;
}
</style>
