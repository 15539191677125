import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';
import { type RouteTemplate } from '@nextgenleads/call-driver';

export async function getRouteTemplates(filters?: QueryFilters, no_cache?: string[]): Promise<RouteTemplate[]> {
	const query = `
        query GetRouteTemplates($filters: [[Any]]) {
            routeTemplates(filters: $filters) {
                id
                name
				description
				type
				route
				tags
				created_at
            }
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.routeTemplates;
}
