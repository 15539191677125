import { $GQL } from '@GQL';
import { type SignUpPage } from '@nextgenleads/marketplace-driver';

export async function getSignUpPage(id: string): Promise<SignUpPage> {
	const request = `
        query GetSignUpPage($id: ID!) {
			signUpPage(id: $id) {
				id
				mpid
				name
				description
				path
				channel
				source
				tags
				parent_account_id
				contact {
					name
					email
				}
				content {
					company_name
					phone
					body
					logo_url
				}
				offer_id
				status
				created_at
			}
        }
    `;

	const result = await $GQL.request(request, { id });
	return result.signUpPage;
}
