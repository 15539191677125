import { $GQL } from '@GQL/index';

export async function getAdTemplate(id: string) {
	const query = `
        query GetAdTemplates($id: ID!){
            adTemplate(id: $id) {
                id
				mpid
				name
				type
				vertical_id
				html
				styles
				scripts
				meta
				status
				created_at
				modified_at
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.adTemplate;
}
