<template>
	<div id="transaction-history" class="view-content">
		<div class="page-heading">
			<h1>Transaction History</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<TransactionList />
	</div>
</template>

<script lang="ts">
import TransactionList from './List.vue';
export default {
	name: 'TransactionHistory',
	components: {
		TransactionList,
	},
	data() {
		return {
			breadcrumb_items: [],
		};
	},
	mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Transaction History',
				},
			];
		} else {
			this.breadcrumb_items = [{ label: 'Transaction History' }];
		}
	},
};
</script>

<style lang="less" scoped></style>
