import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function getSourceList(params: GetListParams) {
	const query = `
        query GetSourceList($params: GetListParams!) {
            sourceList(params: $params) {
                rows {
                    source_id
                    source_hash
                    source_type
					network_id
                    biz_unit
                    status
                    description
                }
                row_count
            }
        }
    `;

	const result = await $GQL.request(query, { params });
	return result.sourceList;
}
