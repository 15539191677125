<template>
	<div class="settings-form">
		<h2>Budget Settings</h2>
		<gutter size="20px" />
		<p>Define account-level budgets for your campaigns across the same vertical and product here.</p>
		<div class="budgets">
			<p-message v-if="budgets.length === 0" :closable="false">You do not have any account-level budgets.</p-message>
			<div v-for="budget in sortedBudgets" :class="['budget', { paused: !budget.enabled }]" :key="budget.id">
				<div class="status">
					<p-input-switch v-model="budget.enabled" @change="toggleBudget()" />
				</div>
				<div class="vertical">
					<vertical-icon :vertical-id="budget.vertical_id" />
				</div>
				<div class="product">
					<strong>{{ budgetProduct(budget) }}<template v-if="!budget.enabled"> (Paused)</template> </strong><br />
					<div class="sub-value">{{ budgetType(budget) }}</div>
				</div>
				<div class="limit">{{ budget.type === 'spend' ? currency(budget.limit) : numberFormat(budget.limit) }}</div>
				<div class="toolset">
					<div class="tool">
						<p-button v-tooltip.top="'Edit Budget'" aria-label="Edit Budget" @click="editBudget(budget)">
							<template #icon>
								<icon type="square-edit-outline" size="20px" />
							</template>
						</p-button>
					</div>
					<delete-action message="Are you sure you want to delete this budget?" @delete="deleteBudget(budget.id)" />
				</div>
			</div>
			<div class="row">
				<p-button label="Add Budget" icon="pi pi-plus" @click="addBudget()" />
			</div>
		</div>
		<p-dialog id="budget-modal" v-model:visible="show_budget_modal" :modal="true" style="max-width: 960px">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="bank" size="24px" style="color: var(--color-b)" />
					<strong>Campaign Budget</strong>
				</div>
			</template>
			<div v-if="v$.budget_conflict.$error" class="validation-error" style="height: 2.5em">
				{{ v$.budget_conflict.$errors[0].$message }}
			</div>
			<p-fieldset legend="Set Lead Type">
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label">Lead Vertical:</label>
								<p-dropdown
									v-model="selected_budget.vertical_id"
									:options="$root.appStore.options.verticals"
									option-label="label"
									option-value="value"
								/>
							</div>
							<div class="field">
								<label class="control-label">Lead Type:</label>
								<p-dropdown
									v-model="selected_budget.product"
									:options="$root.appStore.options.products"
									option-label="label"
									option-value="value"
								/>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
			<gutter size="20px" />
			<row>
				<column>
					<p-fieldset legend="Choose a Budget Type">
						<div v-if="v$.selected_budget.type.$error" class="validation-error">
							{{ v$.selected_budget.type.$errors[0].$message }}
						</div>
						<p>
							Select how you want to budget your purchases for this campaign &mdash; by number of purchases or by lead
							cost.
						</p>
						<div class="budget-types">
							<div
								v-for="option in budget_type_options"
								:class="['budget-option', { selected: selected_budget.type === option.value }]"
								@click="selected_budget.type = option.value"
							>
								<div class="description">
									<strong>{{ option.label }}</strong
									><br />
									<div class="sub-value">{{ option.description }}</div>
								</div>
								<div class="selected-icon">
									<icon v-if="selected_budget.type === option.value" type="check" size="24px" />
								</div>
							</div>
						</div>
					</p-fieldset>
				</column>
				<gutter size="20px" />
				<column>
					<p-fieldset legend="Choose a Time Period">
						<div v-if="v$.selected_budget.period.$error" class="validation-error">
							{{ v$.selected_budget.period.$errors[0].$message }}
						</div>
						<p>All time periods are based off the time zone selected for your account.</p>
						<div class="time-periods">
							<div
								v-for="option in period_options"
								:class="['budget-option', { selected: selected_budget.period === option.value }]"
								@click="selected_budget.period = option.value"
							>
								<div class="description">
									<strong>{{ option.label }}</strong
									><br />
									<div class="sub-value">{{ option.description }}</div>
								</div>
								<div class="selected-icon">
									<icon v-if="selected_budget.period === option.value" type="check" size="24px" />
								</div>
							</div>
						</div>
					</p-fieldset>
				</column>
			</row>
			<p-fieldset legend="Set a Limit">
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<template v-if="selected_budget.type && selected_budget.period">
								<template v-if="selected_budget.type === 'spend'">
									<div class="field s">
										<p-input-currency v-model="selected_budget.limit" class="input-align-right" />
									</div>
									<gutter size="10px" />
									<div class="field caption">spend limit per {{ selected_budget.period }}</div>
								</template>
								<template v-else>
									<div class="field s">
										<p-input-number v-model="selected_budget.limit" class="input-align-right" />
									</div>
									<gutter size="10px" />
									<div class="field caption">purchase count limit per {{ selected_budget.period }}</div>
								</template>
							</template>
							<template v-else>
								<p-message :closable="false">Select a budget type and period to set a limit</p-message>
							</template>
						</div>
					</div>
				</div>
			</p-fieldset>
			<template #footer>
				<p-button text label="Cancel" @click="closeBudgetModal()" />
				<p-button label="Save Budget" icon="pi pi-check" @click="saveBudget()" />
			</template>
		</p-dialog>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import sortBy from 'lodash/sortBy';
import { currency, numberFormat, title } from '@/lib/Filters';
import { getAccountSettings, updateAccount } from '@GQL';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import pDialog from 'primevue/dialog';
import { ulid } from 'ulid';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const default_budget = {
	period: null,
	vertical_id: null,
	product: null,
	type: null,
	limit: 0,
	enabled: true,
};

export default {
	name: 'AccountBudgetSettings',
	components: {
		deleteAction,
		verticalIcon,
		pDialog,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			budgets: [],
			selected_budget: cloneDeep(default_budget),
			show_budget_modal: false,
			period_options: [
				{ label: 'Daily', value: 'day', description: '12AM to Midnight' },
				{ label: 'Weekly', value: 'week', description: 'Sunday to Saturday' },
				{
					label: 'Monthly',
					value: 'month',
					description: 'Calendar Month',
				},
			],
			budget_type_options: [
				{
					label: 'Spend',
					value: 'spend',
					description: 'Limit the amount of money that is spent by this campaign for the specified time period.',
				},
				{
					label: 'Purchase Count',
					value: 'purchase_count',
					description: 'Limit the amount of leads that are purchased by this campaign for the specified time period.',
				},
			],
		};
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id || this.$route.params.parent_account_id || this.$root.sessionStore.account.id
			);
		},
		sortedBudgets() {
			return sortBy(this.budgets, ['vertical_id', 'product', 'period']);
		},
	},
	validations() {
		return {
			selected_budget: {
				period: {
					required: helpers.withMessage('A budget period is required', required),
				},
				vertical_id: {
					required: helpers.withMessage('A vertical is required', required),
				},
				product: {
					required: helpers.withMessage('A product is required', required),
				},
				type: {
					required: helpers.withMessage('A budget type is required', required),
				},
			},
			budget_conflict: {
				conflict: helpers.withMessage('This configuration conflicts with an existing budget', (value) => {
					// Check if another budget exists with the same settings
					const existing_budget = this.budgets.find((budget) => {
						return (
							budget.vertical_id === this.selected_budget.vertical_id &&
							budget.product === this.selected_budget.product &&
							budget.type === this.selected_budget.type &&
							budget.period === this.selected_budget.period &&
							budget.id !== this.selected_budget.id
						);
					});

					return !existing_budget;
				}),
			},
		};
	},
	methods: {
		currency,
		numberFormat,
		title,
		budgetType(budget) {
			const period_conversion = {
				day: 'Daily',
				week: 'Weekly',
				month: 'Monthly',
			};

			return period_conversion[budget.period] + ' ' + title(budget.type);
		},
		budgetProduct(budget) {
			const product_conversion = {
				data: 'Data Leads',
				call: 'Calls',
				live_transfer: 'Live Transfers',
				ad: 'Ads',
			};

			return product_conversion[budget.product];
		},
		budgetDescription(budget) {
			return `Limit the ${budget.type === 'spend' ? 'campaign spend' : 'purchase count'} to ${budget.type === 'spend' ? currency(budget.limit) : numberFormat(budget.limit)} per ${budget.period}`;
		},
		async addBudget() {
			this.v$.$reset();
			this.selected_budget = {
				id: ulid(),
				period: null,
				vertical_id: null,
				product: null,
				type: null,
				limit: 0,
				enabled: true,
			};
			this.show_budget_modal = true;
		},
		async editBudget(budget) {
			this.v$.$reset();
			this.selected_budget = cloneDeep(budget);
			this.show_budget_modal = true;
		},
		async saveBudget() {
			const is_valid = await this.v$.$validate();

			if (is_valid) {
				const matching_budget_index = this.budgets.findIndex((budget) => {
					return budget.id === this.selected_budget.id;
				});

				if (matching_budget_index > -1) {
					this.budgets.splice(matching_budget_index, 1, cloneDeep(this.selected_budget));
				} else {
					this.budgets.push(cloneDeep(this.selected_budget));
				}

				this.closeBudgetModal();
				await this.updateAccount();
			}
		},
		closeBudgetModal() {
			this.selected_budget = cloneDeep(default_budget);
			this.show_budget_modal = false;
			this.v$.$reset();
		},
		toggleBudget() {
			setTimeout(() => {
				this.updateAccount();
			}, 100);
		},
		async deleteBudget(budget_id) {
			const budget_index = this.budgets.findIndex((budget) => {
				return budget.id === budget_id;
			});
			if (budget_index > -1) {
				this.budgets.splice(budget_index, 1);
				await this.updateAccount();
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to delete budget',
					life: 3000,
				});
			}
		},
		async getSettings() {
			const result = await getAccountSettings(this.accountId, true);
			if (result) {
				this.budgets = (result.account.settings.budgets || []).map((budget) => {
					budget.id = ulid();
					return budget;
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get budgets',
					life: 3000,
				});
			}
		},
		async updateAccount() {
			const account_result = await updateAccount(this.accountId, { settings: { budgets: this.budgets } });
			if (account_result.updateAccount.id) {
				this.$toast.add({
					severity: 'success',
					summary: 'Budget changes saved',
					life: 3000,
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save budget changes',
					life: 3000,
				});
			}
		},
	},
	async mounted() {
		await this.getSettings();
	},
};
</script>

<style scoped lang="less">
:deep(*) {
	.p-inputnumber.short {
		max-width: 100px;
	}

	.p-inputnumber-input {
		text-align: right;
	}

	.field.caption {
		align-items: center;
		display: flex;
		height: 40px;
	}
}

.field {
	margin-bottom: 0;
}

.budget {
	align-items: center;
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	display: flex;
	gap: 20px;
	justify-content: space-between;
	margin-bottom: 1em;
	padding: 20px;

	.product {
		flex: 1 0;
		line-height: 1.125em;
	}

	.limit {
		flex: 1 0;
	}

	&.paused {
		opacity: 0.5;
	}
}

#budget-modal {
	.row {
		align-items: stretch;

		.column .p-fieldset {
			height: 100%;
		}
	}

	.validation-error {
		margin-bottom: 1em;
	}

	.budget-option {
		align-items: flex-start;
		border: 1px solid var(--gray-20);
		border-radius: 3px;
		cursor: pointer;
		display: flex;
		line-height: 1.125em;
		margin-bottom: 1em;
		padding: 15px;

		&:last-of-type {
			margin-bottom: 0;
		}

		strong {
			line-height: 1.5em;
		}

		&.selected {
			background-color: var(--color-b-lightest);
			border: 2px solid var(--color-b-lighter);
		}

		.description {
			flex: 1 0;
		}

		.selected-icon {
			color: var(--color-b);
			margin-left: 10px;
			min-width: 24px;
		}
	}

	.time-periods .budget-option {
		.description {
			align-items: center;
			display: flex;
		}

		strong {
			flex: 0 0 80px;
		}
	}

	.p-message {
		margin: 0;
		width: 100%;
	}
}
</style>
