<template>
	<div class="build-step reply-with-status">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Reply with Status</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">Return a response that contains the acceptance status and the payout for the leads.</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ReplyWithStatus',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
