<template>
	<div class="attribute-section" :class="{ active: enable_household_filter }">
		<div class="header">
			<div class="title">
				<label class="clickable-label" for="household-switch">Target by Household Size</label>
			</div>
			<div class="switch">
				<p-input-switch v-model="enable_household_filter" input-id="household-switch" />
			</div>
		</div>
		<div v-if="enable_household_filter" class="filter">
			<div class="control-group">
				<div class="inner">
					<label class="control-label mb-2">Household Size:</label>
					<div class="controls flex flex-column justify-content-start align-items-start gap-2">
						<div class="field flex">
							<p-radio-button v-model="custom_bid_household_size" input-id="hh_1" name="single_household" :value="1" />
							<gutter size="10px" />
							<label for="hh_1">I only want to target individuals</label>
						</div>
						<div class="field flex">
							<p-radio-button v-model="custom_bid_household_size" input-id="hh2" name="two_more_household" :value="2" />
							<gutter size="10px" />
							<label for="hh_3">I only want to target families of 2 or more</label>
						</div>
						<div class="field flex">
							<p-radio-button
								v-model="custom_bid_household_size"
								input-id="hh3"
								name="three_more_household"
								:value="3"
							/>
							<gutter size="10px" />
							<label for="hh_3">I only want to target families of 3 or more</label>
						</div>
						<div class="field flex">
							<p-radio-button
								v-model="custom_bid_household_size"
								input-id="hh_4"
								name="four_more_household"
								:value="4"
							/>
							<gutter size="10px" />
							<label for="hh_4">I only want to target families of 4 or more</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pSlider from 'primevue/slider';

export default {
	name: 'HouseholdSizeAttribute',
	emits: ['update'],
	components: {
		pSlider,
	},
	data() {
		return {
			enable_household_filter: false,
			custom_bid_household_size: 1,
			household_has_range: false,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const household_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.household_size';
			});
			if (household_match) {
				this.enable_household_filter = true;
				// If range is set, set the value to the minimum, we convert to a range when saving
				if (Array.isArray(household_match.comparator.value)) {
					if (household_match.comparator.value[0] >= 4) {
						this.custom_bid_household_size = 4;
					} else {
						this.custom_bid_household_size = household_match.comparator.value[0];
					}
				} else {
					this.custom_bid_household_size = household_match.comparator.value;
				}
			}
		},
		convertToRange(number) {
			if (number === 1) {
				return [1, 1];
			} else if (number === 2) {
				return [2, 6];
			} else if (number === 3) {
				return [3, 6];
			} else if (number >= 4) {
				return [4, 6];
			}
			return range;
		},
		saveAttribute() {
			if (this.enable_household_filter) {
				const range = this.convertToRange(this.custom_bid_household_size);
				return {
					target: {
						path: 'lead.data.household_size',
					},
					strategy: 'in_range',
					comparator: {
						value: range,
					},
				};
			}
			return null;
		},
	},
};
</script>

<style lang="less">
#household-slider {
	max-width: 12rem;
}
.household-value {
	display: inline-block;
}
</style>
