import { $GQL } from '@GQL/index';

export async function reportAccountSpendByDay(params: {
	date_range: [string, string];
	compare: any;
	account_id: string;
}) {
	const query = `
        query ReportAccountSpendByDay($params: JSONObject!) {
            reportAccountSpendByDay(params: $params)
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportAccountSpendByDay;
}
