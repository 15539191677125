import { $GQL } from '@GQL/index';

interface UpdateAccountRes {
	updateAccount: {
		id: string;
		name: string;
		settings: {
			timezone: string;
		};
	};
}

export async function updateAccount(id: string, data: any): Promise<UpdateAccountRes> {
	const mutation = `
        mutation UpdateAccount($id: ID!, $data: JSONObject!) {
			updateAccount(id: $id, data: $data) {
				id
				name
				settings {
					timezone
				}
			}
        }
    `;

	return await $GQL.request(mutation, { id, data });
}
