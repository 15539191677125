<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Customers Report</h1>
			<breadcrumb :model="breadcrumbs" />
		</div>
		<query-form v-model:query="query" :loading="loading" @apply="getCustomersReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}</div>
				</div>
				<div class="display-value">
					<div class="label">Verticals:</div>
					<div class="value">{{ displaySelected(query.business_unit, business_unit_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Business Units:</div>
					<div class="value">{{ displaySelected(query.business_unit, business_unit_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Products:</div>
					<div class="value">{{ displaySelected(query.product, product_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">States:</div>
					<div class="value">{{ displaySelected(query.state, state_options) }}</div>
				</div>
			</template>
			<template #form="form">
				<row>
					<column max-width="450px">
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range v-model="form.query.date_range" hide-labels show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Verticals:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.vertical_id"
											:options="vertical_options"
											option-label="label"
											option-value="value"
											placeholder="Select verticals"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="300px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Products:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.product"
											:options="product_options"
											option-label="label"
											option-value="value"
											placeholder="Select products"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Business Units:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.business_unit"
											:options="business_unit_options"
											option-label="label"
											option-value="value"
											placeholder="Select business units"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="300px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.state"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select states"
											filter
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column></column>
				</row>
			</template>
		</query-form>
		<div class="rollup-metrics">
			<div class="flex align-items-stretch gap-20">
				<div class="flex-1 card padded">
					<div class="line-item">
						<div class="label">Customer Count</div>
						<div class="value">{{ numberFormat(report_data.rollup.customer_count) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Total Revenue</div>
						<div class="value">{{ currency(report_data.rollup.total_revenue) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Purchases</div>
						<div class="value">{{ numberFormat(report_data.rollup.total_purchases) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Auctions Won</div>
						<div class="value">{{ numberFormat(report_data.rollup.auctions) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Revenue/Completed Auction</div>
						<div class="value">{{ currency(report_data.rollup.rpp || 0) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Completion Rate</div>
						<div class="value">{{ percentage(report_data.rollup.completion_rate, 2) }}</div>
					</div>
				</div>
				<div class="flex-1 card padded">
					<div class="line-item">
						<div class="label">Health Insurance</div>
						<div class="value">{{ currency(report_data.rollup.health_insurance_revenue) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Medicare</div>
						<div class="value">{{ currency(report_data.rollup.medicare_revenue) }}</div>
					</div>
				</div>
				<div class="flex-1 card padded">
					<div class="line-item">
						<div class="label">Exclusive Data</div>
						<div class="value">{{ currency(report_data.rollup.exclusive_data_revenue) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Shared Data</div>
						<div class="value">{{ currency(report_data.rollup.shared_data_revenue) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Premium Listings</div>
						<div class="value">{{ currency(report_data.rollup.premium_listing_revenue) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Calls</div>
						<div class="value">{{ currency(report_data.rollup.call_revenue) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Live Transfers</div>
						<div class="value">{{ currency(report_data.rollup.live_transfer_revenue) }}</div>
					</div>
				</div>
				<div class="flex-1 card padded">
					<div class="line-item">
						<div class="label">Owned and Operated</div>
						<div class="value">{{ currency(report_data.rollup.oo_revenue) }}</div>
					</div>
					<div class="line-item">
						<div class="label">Partner Network</div>
						<div class="value">{{ currency(report_data.rollup.pn_revenue) }}</div>
					</div>
				</div>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<div class="card-heading unpadded" style="gap: 20px">
				<div><strong>Customers</strong></div>
			</div>
			<p-data-table
				:value="report_data.accounts"
				ref="customers_table"
				data-key="account_id"
				class="customers-table"
				sort-field="revenue"
				:sort-order="-1"
			>
				<template #empty>
					<div class="dim">No Data</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column header="Customer" sortable field="account_id">
					<template #body="row">
						<strong>
							<router-link :to="accountLink(row.data.account_id)">{{ row.data.account_name }}</router-link>
						</strong>
						<div class="sub-value">{{ row.data.account_id }}</div>
					</template>
				</p-column>
				<p-column header="Verticals">
					<template #body="row">
						<div
							v-tooltip.top="{ value: categoryTooltip(row.data, vertical_id), escape: false }"
							class="icon-wrapper"
							v-for="vertical_id in row.data.verticals"
						>
							<vertical-icon :vertical-id="vertical_id" hide-tooltip size="30px" />
						</div>
					</template>
				</p-column>
				<p-column header="Products">
					<template #body="row">
						<div
							v-tooltip.top="{ value: categoryTooltip(row.data, product), escape: false }"
							class="icon-wrapper"
							v-for="product in row.data.products"
						>
							<div class="category-icon">
								<icon :type="categoryIcon(product)" size="20px" />
							</div>
						</div>
					</template>
				</p-column>
				<p-column header="Business Units">
					<template #body="row">
						<div
							v-tooltip.top="{ value: categoryTooltip(row.data, business_unit), escape: false }"
							class="icon-wrapper"
							v-for="business_unit in row.data.business_units"
						>
							<div class="category-icon">
								<icon :type="categoryIcon(business_unit)" size="20px" />
							</div>
						</div>
					</template>
				</p-column>
				<p-column header="Purchases" sortable field="purchases">
					<template #body="row">
						<div class="cell-wrapper" v-tooltip.top="{ value: purchasesTooltip(row.data), escape: false }">
							{{ numberFormat(row.data.purchases) }}
						</div>
					</template>
				</p-column>
				<p-column header="Revenue" sortable field="revenue">
					<template #body="row">
						<div class="cell-wrapper" v-tooltip.top="{ value: revenueTooltip(row.data), escape: false }">
							{{ currency(row.data.revenue) }}
						</div>
					</template>
				</p-column>
				<p-column header="RPP" sortable field="rpp">
					<template #body="row">
						{{ currency(row.data.rpp) }}
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script>
import { round, sumBy } from 'lodash-es';
import dayjs from 'dayjs';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { capitalize, currency, displaySelected, formatDate, numberFormat, percentage } from '@/lib/Filters';
import { reportCustomers } from '@GQL';
import STATES from '@/lib/Data/states.json';

export default {
	name: 'CustomersReport',
	components: {
		dateRange,
		queryForm,
		verticalIcon,
	},
	data() {
		// Round current time to the nearest 5 minutes
		const minute = Math.floor(dayjs().minute() / 5) * 5;

		return {
			breadcrumbs: [
				{
					label: 'Customers Report',
				},
			],
			loading: false,
			query: {
				date_range: [dayjs().startOf('day').toDate(), dayjs().minute(minute).second(0).toDate()],
				vertical_id: [],
				product: [],
				business_unit: [],
				state: [],
			},
			report_data: {
				rollup: {
					customer_count: 0,
					total_purchases: 0,
					total_revenue: 0,
					rpp: 0,
					auctions: 0,
					completion_rate: 0,
					health_insurance_revenue: 0,
					medicare_revenue: 0,
					exclusive_data_revenue: 0,
					shared_data_revenue: 0,
					premium_listing_revenue: 0,
					call_revenue: 0,
					live_transfer_revenue: 0,
					oo_revenue: 0,
					pn_revenue: 0,
				},
				accounts: [],
			},
			vertical_options: [
				{
					label: 'Health Insurance',
					value: 'health_insurance',
				},
				{
					label: 'Medicare',
					value: 'medicare',
				},
			],
			business_unit_options: [
				{
					label: 'Owned and Operated',
					value: 'OO',
				},
				{
					label: 'Partner Network',
					value: 'PN',
				},
			],
			product_options: [
				{
					label: 'Exclusive Data Leads',
					value: 'exclusive_data',
				},
				{
					label: 'Shared Data Leads',
					value: 'shared_data',
				},
				{
					label: 'Calls',
					value: 'call',
				},
				{
					label: 'Live Transfers',
					value: 'live_transfer',
				},
				{
					label: 'Premium Listings',
					value: 'ad',
				},
			],
			state_options: STATES,
		};
	},
	methods: {
		capitalize,
		currency,
		displaySelected,
		formatDate,
		numberFormat,
		percentage,
		accountLink(account_id) {
			const mpid = account_id.split('-')[0].toUpperCase();
			return `/marketplaces/manage/${mpid}/accounts/manage/${account_id}/reports/account-activity`;
		},
		clearQuery() {
			this.loading = false;
		},
		async getCustomersReport() {
			this.loading = true;
			const result = await reportCustomers(this.query);
			const rollup = result.rollup;
			const accounts = result.accounts;

			this.report_data.rollup.customer_count = accounts.length;
			this.report_data.rollup.total_purchases = sumBy(rollup, 'purchases');
			this.report_data.rollup.total_revenue = sumBy(rollup, 'revenue');
			this.report_data.rollup.rpp = round(
				this.report_data.rollup.total_revenue / this.report_data.rollup.total_purchases,
				2
			);
			this.report_data.rollup.auctions = sumBy(rollup, 'auctions');
			const completed_auctions = sumBy(rollup, 'completed_auctions');
			this.report_data.rollup.completion_rate = completed_auctions / this.report_data.rollup.auctions;
			this.report_data.rollup.health_insurance_revenue = sumBy(rollup, (row) => {
				if (row.vertical_id === 'health_insurance') {
					return row.revenue;
				}
				return 0;
			});
			this.report_data.rollup.medicare_revenue = sumBy(rollup, (row) => {
				if (row.vertical_id === 'medicare') {
					return row.revenue;
				}
				return 0;
			});
			this.report_data.rollup.exclusive_data_revenue = sumBy(rollup, (row) => {
				if (row.product === 'exclusive_data') {
					return row.revenue;
				}
				return 0;
			});
			this.report_data.rollup.shared_data_revenue = sumBy(rollup, (row) => {
				if (row.product === 'shared_data') {
					return row.revenue;
				}
				return 0;
			});
			this.report_data.rollup.premium_listing_revenue = sumBy(rollup, (row) => {
				if (row.product === 'ad') {
					return row.revenue;
				}
				return 0;
			});
			this.report_data.rollup.call_revenue = sumBy(rollup, (row) => {
				if (row.product === 'call') {
					return row.revenue;
				}
				return 0;
			});
			this.report_data.rollup.live_transfer_revenue = sumBy(rollup, (row) => {
				if (row.product === 'live_transfer') {
					return row.revenue;
				}
				return 0;
			});
			this.report_data.rollup.oo_revenue = sumBy(rollup, (row) => {
				if (row.business_unit === 'OO') {
					return row.revenue;
				}
				return 0;
			});
			this.report_data.rollup.pn_revenue = sumBy(rollup, (row) => {
				if (row.business_unit === 'PN') {
					return row.revenue;
				}
				return 0;
			});

			this.report_data.accounts = accounts;
			this.loading = false;
		},
		categoryIcon(category) {
			let icon;
			switch (category) {
				case 'exclusive_data':
					icon = 'account-star';
					break;
				case 'shared_data':
					icon = 'account-multiple';
					break;
				case 'ad':
					icon = 'license';
					break;
				case 'call':
					icon = 'phone';
					break;
				case 'live_transfer':
					icon = 'headset';
					break;
				case 'OO':
					icon = 'crown';
					break;
				case 'PN':
					icon = 'handshake';
					break;
			}

			return icon;
		},
		categoryTooltip(row, category) {
			let title = capitalize(category);
			if (category === 'OO') {
				title = 'Owned and Operated';
			} else if (category === 'PN') {
				title = 'Partner Network';
			} else if (category === 'ad') {
				title = 'Premium Listing';
			}
			return `<div class="category-tooltip"><div><strong>${title}</strong></div><div class="flex justify-content-between"><div class="label">Auctions</div><div class="value">${numberFormat(row[category + '_auctions'])}</div></div><div class="flex justify-content-between"><div class="label">Purchases</div><div class="value">${numberFormat(row[category + '_purchases'])}</div></div><div class="flex justify-content-between"><div class="label">Spend</div><div class="value">${currency(row[category + '_spend'])}</div></div></div>`;
		},
		purchasesTooltip(row) {
			return `<div class="category-tooltip"><div class="flex justify-content-between"><div class="label">Auctions</div><div class="value">${numberFormat(+row.auctions)}</div></div><div class="flex justify-content-between"><div class="label">Purchases</div><div class="value">${numberFormat(+row.purchases)}</div></div><div class="flex justify-content-between"><div class="label">Completion Rate</div><div class="value">${percentage(+row.purchases / +row.auctions, 2)}</div></div></div>`;
		},
		revenueTooltip(row) {
			let content = `<div class="category-tooltip"><div class="flex justify-content-between"><div class="label">Lead Revenue</div><div class="value">${currency(+row.revenue - (+row.premium_listing_revenue || 0))}</div></div>`;
			if (row.premium_listing_revenue > 0) {
				content += `<div class="flex justify-content-between"><div class="label">PL Revenue</div><div class="value">${currency(+row.premium_listing_revenue)}</div></div>`;
			}
			content += '</div>';
			return content;
		},
	},
	async mounted() {
		await this.getCustomersReport();
	},
};
</script>

<style scoped lang="less">
.rollup-metrics {
	font-weight: bold;

	.line-item {
		align-items: center;
		border-top: 1px solid var(--gray-10);
		display: flex;
		height: 2.5em;
		justify-content: space-between;

		&:first-child {
			border-top: 0;
		}

		.label {
			color: var(--color-a);
		}

		.value {
			text-align: right;
		}
	}
}

:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

.icon-wrapper {
	display: inline-block;
	height: 30px;
	margin-right: 10px;
	width: 30px;
}

.category-icon {
	align-items: center;
	background-color: var(--color-b);
	border-radius: 15px;
	color: white;
	display: flex;
	height: 30px;
	justify-content: center;
	width: 30px;
}

.details {
	margin-top: 1em;
}

.card-heading strong {
	font-size: 1.125rem;
}

:deep(.cell-wrapper) {
	cursor: default;
	display: inline-block;
	padding: 5px;
}

:deep(.nil) {
	color: #f88a7d;
	font-weight: bold;
}
</style>

<style lang="less">
.category-tooltip {
	min-width: 200px;
}
</style>
