<template>
	<div id="queues-form" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.queue_id ? 'Edit' : 'Create' }} Queue</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card class="queue-card">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="name">Name:</label>
						<div class="controls">
							<div class="field">
								<p-input-text id="name" v-model="queue.name" class="p-inputtext" placeholder="Name" />
								<div v-if="v$.queue.name.$error" class="validation-error">
									{{ v$.queue.name.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="queue-id">Queue ID:</label>
						<div class="controls">
							<div class="field">
								<div class="p-inputgroup">
									<p-input-text
										v-if="!this.$route.meta.new"
										id="queue-id"
										v-model="queue.id"
										class="key-input flex-1"
										:disabled="!this.$route.meta.new"
										:pt="{
											root: { class: 'border-gray-500 surface-100 text-black-alpha-90 opacity-90' },
										}"
									/>
									<p-input-text
										v-else
										id="queue-id"
										v-model="queue.id"
										class="key-input flex-1"
										placeholder="Queue ID"
									/>
									<div v-if="!this.$route.meta.new" class="p-inputgroup-addon">
										<icon type="lock" />
									</div>
								</div>
								<div class="sub-value">Value must be lower camel case i.e. `queue_name`</div>
								<div v-if="v$.queue.id.$error">
									<p v-for="error in v$.queue.id.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="description">Description:</label>
						<div class="controls">
							<div class="field">
								<p-textarea
									id="description"
									v-model="queue.description"
									class="p-inputtext w-full"
									placeholder="Enter additional description"
									style="min-height: 4rem"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label"></label>
						<div class="controls">
							<div class="field">
								<label class="req" for="interval">Interval:</label>
								<div class="sub-value">The interval of seconds for polling for leads</div>
							</div>
							<div class="field max-w-6rem">
								<p-input-number id="interval" v-model="queue.interval" placeholder="in seconds" />
								<div v-if="v$.queue.interval.$error" class="validation-error">
									{{ v$.queue.interval.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<label class="req" for="minimum-delay">Minimum Delay:</label>
								<div class="sub-value">The minimum age of a lead in seconds that can be pulled from the queue</div>
							</div>
							<div class="field max-w-6rem">
								<p-input-number id="minimum-delay" v-model="queue.min_delay" placeholder="in seconds" />
								<div v-if="v$.queue.min_delay.$error" class="validation-error">
									{{ v$.queue.min_delay.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="column">
					<div class="control-group">
						<div class="inner">
							<div class="controls gap-20">
								<div class="field fit">
									<p-input-switch
										id="enable-lead-count-limit"
										v-model="queue_limit_enabled"
										@change="checkLimit(queue_limit_enabled)"
									/>
								</div>
								<div class="field">
									<label for="enable-lead-count-limit" class="mb-0">Enable Lead Count Limit</label>
									<div class="sub-value">Limit the number of leads that can be processed at once.</div>
								</div>
								<div class="field fit">
									<p-input-number
										id="queue-limit-number"
										v-model="queue.limit"
										class="max-w-6rem"
										style="min-width: 6rem"
										placeholder="# of leads to process"
										:disabled="!queue_limit_enabled"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<p-fieldset legend="Actions">
					<div class="actions" v-sortable="sortable_options">
						<template v-for="action in queue.actions" :key="action.id">
							<component :is="action.name" v-model="action.settings" @remove="removeAction(action.id)" />
						</template>
					</div>
					<action-selector :actions="queue.actions" :method="queue.method" @addAction="appendAction" />
					<div v-if="v$.queue.actions.$error" class="validation-error">
						{{ v$.queue.actions.$errors[0].$message }}
					</div>
				</p-fieldset>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="status">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="queue.status"
									:options="active_status_options"
									placeholder="Select Status"
									option-label="label"
									option-value="value"
								/>
								<div v-if="v$.queue.status.$error">
									<p v-for="error in v$.queue.status.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-content-between align-items-center">
					<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
					<p-button icon="pi pi-check" label="Save Queue" @click.prevent="save" />
				</div>
			</template>
		</p-card>
	</div>
</template>

<script>
import { active_status_options } from '@/lib/Options';
import { cloneDeep, merge, omit, remove } from 'lodash-es';
import { getQueueById, insertQueue, updateQueue } from '@GQL';
import { helpers, required, email } from '@vuelidate/validators';
import { ulid } from 'ulid';
import { useVuelidate } from '@vuelidate/core';
import actionSelector from './Actions/ActionSelector.vue';
import log from '@/lib/Log';
import pDialog from 'primevue/dialog';
import processAuctionLogs from './Actions/processAuctionLogs.vue';
import pTextarea from 'primevue/textarea';
import sendToAuction from './Actions/sendToAuction.vue';
import sendToDialer from './Actions/sendToDialer.vue';
import sendToPartners from './Actions/sendToPartners.vue';
import sendToLeadBuild from './Actions/sendToLeadBuild.vue';
import Sortable from 'sortablejs';

function lowerCamelCase(value) {
	const regex = /^[a-z0-9_]+$/g;
	return value.indexOf(' ') === -1 && regex.test(value);
}

const default_queue = {
	id: '',
	name: '',
	description: '',
	interval: 0,
	min_delay: 0, // minimum age in seconds
	actions: [],
	settings: {},
	status: '',
};

export default {
	name: 'QueuesForm',
	components: {
		pDialog,
		pTextarea,
		actionSelector,
		sendToAuction,
		processAuctionLogs,
		sendToDialer,
		sendToPartners,
		sendToLeadBuild,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			queue: cloneDeep(default_queue),
			queue_limit_enabled: false,
			active_status_options,
			breadcrumb_items: [
				{ label: 'Queues', route: '/queues' },
				{ label: this.$route.params.queue_id ? 'Edit Queue' : 'Create Queue' },
			],
			sortable_options: {
				draggable: '.action',
				handle: '.move-handle',
			},
		};
	},
	validations() {
		return {
			queue: {
				id: {
					required,
					custom: helpers.withMessage('Value must be camel case i.e. queue_example', lowerCamelCase),
				},
				interval: {
					required: helpers.withMessage('Interval required.', required),
				},
				min_delay: {
					required: helpers.withMessage('Min Delay required.', required),
				},
				actions: {
					required: helpers.withMessage('Queue Actions required.', required),
				},
				name: {
					required: helpers.withMessage('Name required.', required),
				},
				status: {
					required: helpers.withMessage('Status required.', required),
				},
			},
		};
	},
	async mounted() {
		try {
			this.loading = true;
			const is_new = this.$route.meta.new;
			if (is_new && this.$route.query.duplicate) {
				const queue_result = await getQueueById(this.$route.params.queue_id);
				this.queue = cloneDeep(queue_result);
				this.queue.id = null;
				this.queue.name += ' (Copy)';
			} else if (!is_new) {
				const queue_result = await getQueueById(this.$route.params.queue_id);
				this.queue = cloneDeep(queue_result);
			}

			if (this.queue.limit) {
				this.queue_limit_enabled = true;
			}

			// Map IDs onto the actions
			this.queue.actions = this.queue.actions.map((action) => {
				action.id = ulid();
				return action;
			});
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
				detail: err.message,
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		appendAction(value) {
			value.id = ulid();
			this.queue.actions.push(value);
		},
		removeAction(id) {
			remove(this.queue.actions, (action) => {
				return action.id === id;
			});
		},
		sortValues(old_index, new_index) {
			const action = this.queue.actions.splice(old_index, 1)[0];
			this.queue.actions.splice(new_index, 0, action);
		},
		async save() {
			const is_new = this.$route.meta.new;
			const valid = await this.v$.$validate();
			const new_queue = cloneDeep(this.queue);
			if (valid) {
				this.loading = true;
				try {
					let result;
					new_queue.actions = new_queue.actions.map((action) => {
						delete action.id;
						return action;
					});

					if (!is_new) {
						result = await updateQueue(new_queue.id, omit(new_queue, 'id'));
					} else {
						result = await insertQueue(new_queue);
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully ${!is_new ? 'updated' : 'inserted new'} queue`,
							detail: `${new_queue.name}`,
							life: 5000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to ${!is_new ? 'update' : 'insert new'} queue`,
							detail: `${new_queue.name}`,
							life: 5000,
						});
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: `Unable to ${!is_new ? 'update' : 'insert new'} queue`,
						detail: `${new_queue.name}`,
						life: 5000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
		checkLimit(enabled) {
			if (enabled === false) {
				this.queue.limit = null;
			}
		},
	},
	directives: {
		sortable: {
			beforeMount(el, binding, vnode) {
				let options = merge({}, binding.value, {
					onEnd: (event) => {
						binding.instance.sortValues(event.oldIndex, event.newIndex);
					},
				});

				vnode.sortable = Sortable.create(el, options);
			},
		},
	},
};
</script>

<style lang="less" scoped>
.queue-card {
	max-width: 800px;
}

.sub-value {
	color: var(--gray-50);
	font-size: var(--font-size-sm);
}

label {
	margin-bottom: 0.5rem;
}

.action {
	background-color: white;
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	box-shadow: 0 3px 0 rgb(0 0 0 / 10%);
	margin-bottom: 1rem;
	position: relative;

	:deep(.title) {
		align-items: center;
		background-color: var(--color-b-lightest);
		border-bottom: 1px solid var(--gray-15);
		display: flex;
		flex-direction: row;
		height: 50px;

		.move-handle {
			cursor: ns-resize;
			text-align: center;
			width: 50px;
		}

		.name {
			flex: 1 0;
			font-weight: bold;
		}

		.delete {
			margin-right: 10px;
		}
	}

	:deep(.description) {
		color: var(--gray-50);
		line-height: 1.35em;
		padding: 20px;
	}

	:deep(.action-settings) {
		padding: 0 20px 20px;

		h3 {
			font-size: 1rem;
			font-weight: bold;
			margin-bottom: 0.5em;
		}
	}
}
</style>
