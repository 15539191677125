import { $GQL } from '@GQL/index';
import { id } from 'date-fns/locale';

interface InitializeRes {
	initialize: {
		user: {
			id: string;
			first_name: string;
			last_name: string;
			email: string;
			role_id: unknown;
			phone: string;
			title: string;
			avatar_url: string;
			account_id: string;
			last_login: string;
			status: string;
		};
		role: {
			id: string;
			mpid: string;
			granted: string[];
			revoked: string[];
			type: string;
			status: string;
		};
	};
	marketplaces: {
		id: string;
		name: string;
	};
	settings: {
		navigation: any;
	};
}

export async function initAdminSession(): Promise<InitializeRes> {
	const query = `
        query InitAdminSession {
			initialize {
				user {
					id
					first_name
					last_name
					email
					role_id
					phone
					title
					avatar_url
					account_id
					last_login
					status
					settings {
						calendly_url
						team
					}
				}
				role {
					id
					type
					mpid
					granted
					revoked
					status
				}
			}
			marketplaces {
				id
				name
			}
			settings(paths: ["navigation"])
        }
    `;

	return await $GQL.request(query);
}
