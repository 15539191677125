// ----------------------------------------------------------------------------------------------------
// FILTER: verticalName
// Map a vertical ID to a vertical name
// ----------------------------------------------------------------------------------------------------

// Import dependencies
import { get } from 'lodash-es';

export function verticalName(value) {
	const verticals = {
		auto_insurance: 'Auto Insurance',
		health_insurance: 'Health Insurance',
		life_insurance: 'Life Insurance',
		medicare: 'Medicare',
		solar: 'Solar',
	};

	return get(verticals, value, value);
}
