import { $GQL } from '@GQL';

export async function deletePaymentMethod(payment_method_id: string, user_id: string) {
	const mutation = `
    mutation DeletePaymentMethod($payment_method_id: ID!, $user_id: ID!) {
      deletePaymentMethod(payment_method_id: $payment_method_id, user_id: $user_id) {
        status
        message
      }
    }
  `;

	return await $GQL.request(mutation, { payment_method_id, user_id });
}
