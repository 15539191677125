<template>
	<div class="query-form card padded">
		<div class="display">
			<div class="left">
				<div class="flex flex-row align-items-center flex-wrap gap-2 sm:gap-1 lg:gap-0">
					<div class="filter-button">
						<p-button class="icon-only" @click="toggleDrawer">
							<div v-tooltip.top="'Edit Query'" class="icon-wrapper">
								<icon type="filter" :loading="loading" size="24px" />
							</div>
						</p-button>
					</div>
					<slot name="display"></slot>
				</div>
			</div>
			<div class="right">
				<div class="flex flex-row align-items-center gap-1">
					<slot name="utils"></slot>
				</div>
			</div>
		</div>
		<template v-if="drawer_open">
			<div class="drawer">
				<div class="form">
					<slot name="form" :query="query"> No form elements </slot>
				</div>
				<div class="actions flex">
					<p-button icon="pi pi-check" label="Apply" @click="apply()" />
					<gutter size="10px" />
					<p-button v-tooltip.top="'Reset Options'" icon="pi pi-refresh" @click="reset()" />
					<gutter size="10px" />
					<p-button label="Cancel" link @click="cancel()" />
				</div>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
import { cloneDeep } from 'lodash-es';
import pButton from 'primevue/button';

export default {
	name: 'QueryForm',
	components: {
		pButton,
	},
	props: {
		loading: Boolean,
		query: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			drawer_open: false,
			default_query: {},
		};
	},
	methods: {
		openDrawer() {
			if (this.loading) {
				this.$emit('abort');
			}

			// Save the initial state
			this.default_query = cloneDeep(this.query);
			this.drawer_open = true;
		},
		closeDrawer() {
			this.drawer_open = false;
		},
		toggleDrawer() {
			if (this.drawer_open) {
				this.closeDrawer();
			} else {
				this.openDrawer();
			}
		},
		apply() {
			this.$emit('apply', this.query);
			this.closeDrawer();
		},
		reset() {
			this.$emit('update:query', cloneDeep(this.default_query));
		},
		cancel() {
			this.reset();
			this.closeDrawer();
		},
	},
};
</script>

<style scoped lang="less">
.query-form {
	margin-bottom: 20px;
}

.display {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.filter-button {
	margin-right: 15px;
}

.icon-only {
	padding: 0;
}

:deep(.display-value) {
	border-left: 1px dotted var(--gray-20);
	line-height: 1.2;
	padding: 0 15px;

	.label {
		color: var(--gray-40);
		font-size: 0.75rem;
		margin-bottom: 4px;
		margin-top: 2px;
		text-transform: uppercase;
	}

	.value {
		font-weight: bold;
	}
}

.drawer {
	background: var(--surface-100);
	border-radius: 5px;
	margin-top: 20px;

	.form,
	.actions {
		padding: 20px;
	}

	.actions {
		border-top: 1px solid var(--gray-10);
	}
}
</style>
