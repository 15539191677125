import { $GQL } from '@GQL/index';

interface InitializeRes {
	initialize: {
		user: {
			id: string;
			first_name: string;
			last_name: string;
			email: string;
			role_id: unknown;
			phone: string;
			title: string;
			avatar_url: string;
			account_id: string;
			account: {
				id: string;
				name: string;
				balance: {
					actual: number;
				};
				settings: {
					ngl_v3_id?: string;
					timezone: string;
					allow_advanced_mode: boolean;
				};
				support: {
					first_fund_date: Date;
				};
			};
			status: string;
		};
		role: {
			id: string;
			mpid: string;
			granted: string[];
			revoked: string[];
			type: string;
			status: string;
		};
	};
	marketplaces: {
		id: string;
		name: string;
	};
	settings: {
		navigation: any;
	};
}

export async function initMarketplaceSession(): Promise<InitializeRes> {
	const query = `
        query InitMarketplaceSession {
			initialize {
				user {
					id
					first_name
					last_name
					email
					role_id
					phone
					title
					avatar_url
					terms {
						version
					}
					account_id
					account {
						id
						name
						is_parent
						verified
						balance {
							actual
						}
						contact {
							state
						}
						primary_user_id
						settings {
							timezone
							allow_advanced_mode
							enable_peer_competition
							ngl_v3_id
							budgets {
								period
								vertical_id
								product
								type
								enabled
								limit
							}
							bulk_returns {
								enabled
							}
						}
						support {
							account_manager {
								id
								first_name
								last_name
								email
								phone
								avatar_url
							}
							first_fund_date
						}
						signup_data {
							phone_validation {
								activity_score
							}
							primary_vertical
						}
					}
					status
				}
				role {
					id
					type
					mpid
					granted
					revoked
					type
					status
				}
			}
			settings(paths: ["navigation"])
        }
    `;

	return await $GQL.request(query);
}
