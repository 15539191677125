<template>
	<div id="promotions-list" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.mpid ? `Promotions` : `Global Promotions` }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<div class="flex justify-content-between">
				<p-button label="New Promotion" @click="$router.push({ path: `${$route.path}/new` })">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="plus" size="24px" />
						</div>
					</template>
				</p-button>
				<show-archived v-model="show_archived" />
			</div>
			<gutter size="20px" />
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table ref="dt" scrollable :value="show_archived ? promotions : activePromotions" :loading="loading">
							<template #empty>
								<div class="dim">No Promotions found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="name" header="Promotion Name" sortable sortField="name">
								<template #body="row">
									<router-link :to="`${$route.path}/${row.data.id}`">{{ row.data.name }}</router-link>
								</template>
							</p-column>
							<p-column header="Marketplace" class="column-align-center" sortable sortField="mpid">
								<template #body="row">
									{{ row.data.mpid ? `${row.data.mpid}` : 'Global' }}
								</template>
							</p-column>
							<p-column field="name" header="Vertical" class="column-align-center" sortable sortField="vertical_id">
								<template #body="row">
									<vertical-icon :vertical-id="row.data.vertical_id" size="30px" :key="row.data.vertical_id" />
								</template>
							</p-column>
							<p-column field="name" header="Product" class="column-align-center" sortable sortField="product">
								<template #body="row">
									{{ capitalize(row.data.product) }}
								</template>
							</p-column>
							<p-column field="bid_types" header="Bid Types" class="column-align-center">
								<template #body="row">
									{{ row.data.bid_types }}
								</template>
							</p-column>
							<p-column
								field="exclusive_minimum_bid"
								header="Exclusive Min"
								class="column-align-center"
								sortable
								sortField="exclusive_minimum_bid"
							>
								<template #body="row">
									{{ currency(row.data.exclusive_minimum_bid) }}
								</template>
							</p-column>
							<p-column
								field="shared_minimum_bid"
								header="Shared Min Bid"
								class="column-align-center"
								sortable
								sortField="shared_minimum_bid"
							>
								<template #body="row">
									{{ currency(row.data.shared_minimum_bid) }}
								</template>
							</p-column>
							<p-column field="status" header="Status" class="column-align-center">
								<template #body="row">
									{{ capitalize(row.data.status) }}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="
													row.data.mpid
														? $router.push({ path: `${$route.path}/${row.data.id}` })
														: $router.push({ path: `/promotions/${row.data.id}` })
												"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button
												v-tooltip.top="'Duplicate Promotion'"
												aria-label="Duplicate"
												@click="duplicatePromo(row.data.id)"
											>
												<template #icon>
													<icon type="content-copy" size="20px" />
												</template>
											</p-button>
										</div>
										<div v-if="row.data.status !== 'archived'" class="tool">
											<p-button v-tooltip.top="'Archive'" aria-label="Archive" @click="archivePromo(row.data.id)">
												<template #icon>
													<icon type="archive-off" size="20px" />
												</template>
											</p-button>
										</div>
										<div v-else class="tool">
											<p-button v-tooltip.top="'Activate'" aria-label="A" @click="activatePromo(row.data.id)">
												<template #icon>
													<icon type="play-box-outline" size="20px" />
												</template>
											</p-button>
										</div>
									</div>
								</template>
							</p-column>
						</p-data-table>
						<p-paginator :rows="query.pagination.page_size" :total-records="total_rows" @page="handlePageChange" />
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { useAppStore } from '@/stores/app';
import pPaginator from 'primevue/paginator';
import { capitalize, currency } from '@/lib/Filters';
import { getPromotionsList } from '@/lib/GQL/queries/getPromotionsList';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { deletePromotion, updatePromotion } from '@/lib/GQL';
import showArchived from '@/components/widgets/ShowArchived.vue';
import { type Promotion } from '@nextgenleads/marketplace-driver';

export default {
	name: 'PromotionList',
	setup() {
		return {
			appStore: useAppStore(),
		};
	},
	components: {
		pPaginator,
		verticalIcon,
		showArchived,
	},
	data() {
		return {
			breadcrumb_items: [],
			loading: false,
			show_archived: false,
			promotions: [],
			query: {
				filters: [],
				pagination: {
					page: 0,
					page_size: 10,
				},
			},
			total_rows: 0,
		};
	},
	async mounted() {
		this.getPromotions();
		if (this.$route.params.mpid) {
			this.breadcrumb_items.push(
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Promotions',
				}
			);
		} else {
			this.breadcrumb_items.push({
				label: 'Promotions',
			});
		}
	},
	computed: {
		activePromotions() {
			return this.promotions.filter((promo: Promotion) => promo.status !== 'archived');
		},
	},
	methods: {
		capitalize,
		currency,
		async handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getPromotions();
		},
		async getPromotions() {
			this.loading = true;
			try {
				this.query.filters = [];
				if (this.$route.params.mpid) {
					// Get MPID and Global Level Promos
					this.query.filters = [[`mpid = '${this.$route.params.mpid}' OR mpid IS NULL`]];
				} else {
					// Only Global Promos
					this.query.filters = [[`mpid IS NULL`]];
				}
				const { rows, row_count } = await getPromotionsList(this.query);
				this.promotions = rows;
				this.total_rows = row_count;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get promotions',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		async archivePromo(promo_id) {
			try {
				const result = await deletePromotion(promo_id);
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Promo Archived',
						detail: 'Successfully archived promotion.',
						life: 3000,
					});
					// Update local UI
					this.promotions.find((promotion) => promotion.id === promo_id).status = 'archived';
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to archive promotion',
					life: 6000,
				});
			}
		},
		async activatePromo(promo_id) {
			try {
				const result = await updatePromotion(promo_id, {
					status: 'active',
				});
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Promo Activated',
						detail: 'Successfully activated promotion.',
						life: 3000,
					});
					// Update local UI
					this.promotions.find((promotion) => promotion.id === promo_id).status = 'active';
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to activate promotion',
					life: 6000,
				});
			}
		},
		duplicatePromo(promo_id) {
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					duplicate: promo_id,
				},
			});
		},
	},
};
</script>

<style scoped lang="less"></style>
