<template>
	<div class="user-profile">
		<a @click="userMenuToggle">
			<Avatar :user="sessionStore.user" />
		</a>
		<div class="user-menu">
			<p-menu id="user-menu-overlay" ref="userMenu" :model="userMenu" :popup="true">
				<template #start>
					<div class="w-full flex align-items-center p-3">
						<i class="pi pi-user pr-3"></i>
						<div class="flex flex-column align gap-1">
							<span class="font-bold">{{ sessionStore.fullName }}</span>
							<span class="text-sm">{{ roleId }}</span>
						</div>
					</div>
				</template>
				<template #end>
					<ul>
						<li class="p-menuitem" aria-label="Log Out" @click="sessionStore.logout()">
							<div class="p-menuitem-content" data-pc-section="content">
								<a class="p-menuitem-link" tabindex="-1">
									<span class="p-menuitem-icon pi pi-fw pi-sign-out" data-pc-section="icon"></span>
									<span class="p-menuitem-text" data-pc-section="label">Log Out</span>
								</a>
							</div>
						</li>
					</ul>
				</template>
			</p-menu>
		</div>
	</div>
</template>

<script lang="ts">
import Avatar from '@/components/widgets/User/Avatar.vue';
import pMenu from 'primevue/menu';
import { mapStores } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';

export default {
	name: 'ProfileMenu',
	components: {
		Avatar,
		pMenu,
	},
	data() {
		return {
			user: {},
			userMenu: [
				{ separator: true },
				{
					label: 'Profile Settings',
					icon: 'pi pi-fw pi-cog',
					command: () => {
						this.$router.push('/settings/my-profile');
					},
				},
				{ separator: true },
			],
		};
	},
	computed: {
		...mapStores(useAppStore, useSessionStore),
		roleId() {
			return this.sessionStore.role ? this.startCase(this.sessionStore.role.id) : '';
		},
	},
	methods: {
		userMenuToggle(event) {
			this.$refs.userMenu.toggle(event);
		},
	},
	created() {
		const user_id = this.sessionStore.user ? this.sessionStore.user : null;
	},
};
</script>

<style lang="less">
#user-menu-overlay {
	margin-top: 15px;
}
</style>

<style scoped lang="less">
.user-profile {
	.user-avatar {
		position: relative;

		:deep(.p-overlay-badge) .p-badge {
			bottom: -5px;
			right: 8px;
			top: auto;
		}
	}

	.profile-icon {
		height: 100%;
	}

	.p-avatar {
		background-color: var(--color-b);
		color: var(--white);
		cursor: pointer;
	}
}
</style>
