<template>
	<div class="voice-route-step">
		<div class="header move-handle">
			<div class="header-left" @click="expanded = !expanded">
				<div class="flex align-items-center gap-10">
					<icon type="dots-vertical" size="24px" />
					<strong>Script</strong> <span class="sub-value">Execute a custom script</span>
				</div>
				<div>
					<div v-if="!expanded" class="expand-icon">
						<icon type="arrow-expand" size="20px" />
					</div>
					<div v-if="expanded" class="expand-icon">
						<icon type="arrow-collapse" size="20px" />
					</div>
				</div>
			</div>
			<div class="header-right">
				<div class="delete-icon">
					<icon type="delete" size="20px" @click="deleteStep" />
				</div>
			</div>
		</div>
		<div v-if="expanded" class="content">
			<div class="step-options">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Script:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="localRouteStep.params.script"
									:options="script_options"
									option-label="label"
									option-value="value"
									placeholder="Select from the available scripts..."
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Script Data:</label>
						<div class="controls">
							<div class="field">
								<div class="field"><code-editor v-model.parse="localRouteStep.params.script_data" /></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import codeEditor from '@/components/forms/CodeEditor.vue';

export default {
	name: 'VoiceRouteScriptHandler',
	emits: ['update', 'delete'],
	components: {
		codeEditor,
	},
	props: {
		routeStep: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			default_config: {
				handler: 'Script',
				params: {
					script: null,
					script_data: {},
				},
			},
			script_options: [
				{ label: 'setCallData', value: 'setCallData', script_data: {} },
				{ label: 'setGeoData', value: 'setGeoData', script_data: {} },
			],
			expanded: false,
		};
	},
	computed: {
		localRouteStep: {
			get() {
				return this.routeStep;
			},
			set(new_value) {
				this.$emit('update', new_value);
			},
		},
		selectedScript() {
			return this.localRouteStep.params.script;
		},
	},
	watch: {
		selectedScript(new_value, old_value) {
			const selected_script_option = this.script_options.find((script_option) => {
				return script_option.value === new_value;
			});
			if (selected_script_option) {
				this.localRouteStep.params.script_data = selected_script_option.script_data;
			}
		},
	},
	methods: {
		deleteStep(event) {
			this.$confirm.require({
				target: event.currentTarget,
				group: 'voice_route_builder',
				message: 'Are you sure you want to delete this route step?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: 'Delete Route Step',
				rejectLabel: 'Cancel',
				accept: async () => {
					this.$emit('delete');
				},
			});
		},
	},
};
</script>
