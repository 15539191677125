<template>
	<div id="dncs-list" class="view-content">
		<h1>Privacy Requests</h1>
		<breadcrumb :items="breadcrumb_items" />
		<div>
			<gutter size="20px" />
			<query-form v-model:query="query" :loading="loading" @apply="getList">
				<template #display>
					<div class="display-value">
						<div class="label">Status:</div>
						<div class="value">
							{{ displaySelected(query.filters.status, ccpa_status_options) }}
						</div>
					</div>

					<div class="display-value">
						<div class="label">Request Types:</div>
						<div class="value">
							{{ displaySelected(query.filters.type, ccpa_request_type_options) }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="getList(true)"
					/>
				</template>

				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Filter By Status:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="form.query.filters.status"
												:options="ccpa_status_options"
												option-label="label"
												option-value="value"
												placeholder="Select Status"
												class="w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>

							<div class="control-group">
								<div class="inner">
									<label class="control-label">Filter By Type:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="form.query.filters.type"
												:options="ccpa_request_type_options"
												option-label="label"
												option-value="value"
												placeholder="Select CCPA Request Types"
												class="w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>

			<div class="card padded">
				<p-data-table ref="dt" :loading="loading" :rows="10" scrollable :value="requests">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="created_at" header="Created">
						<template #body="request">
							{{ formatDate(request.data.created_at, 'MM/DD/YYYY') }}
						</template>
					</p-column>

					<p-column field="request_type" header="Type">
						<template #body="request">
							{{ capitalize(request.data.type) }}
						</template>
					</p-column>

					<p-column field="source" header="Request">
						<template #body="request">
							<div v-tooltip="{ value: formatCCPARequest(request.data.request), escape: false }">
								<ul class="inner request-form">
									<li
										class="field"
										:class="{ '-verified': request.data.matches.name }"
										v-if="request.data.request.first_name"
									>
										<span class="label">First Name:</span>
										{{ capitalize(request.data.request.first_name) }}
									</li>
									<li
										class="field"
										:class="{ '-verified': request.data.matches.name }"
										v-if="request.data.request.last_name"
									>
										<span class="label">Last Name:</span>
										{{ capitalize(request.data.request.last_name) }}
									</li>
									<li
										class="field"
										:class="{ '-verified': request.data.matches.phone }"
										v-if="request.data.request.phone"
									>
										<span class="label">Phone:</span>
										{{ phoneFormat(('' + request.data.request.phone).replace(/[^0-9]/g, '')) }}
									</li>
									<li
										class="field"
										:class="{ '-verified': request.data.matches.email }"
										v-if="request.data.request.email"
									>
										<span class="label">Email:</span>
										{{ request.data.request.email }}
									</li>
									<li
										class="field"
										:class="{ '-verified': request.data.matches.address }"
										v-if="request.data.request.address"
									>
										<span class="label">Address:</span>
										{{ request.data.request.address }}
									</li>
								</ul>
							</div>
						</template>
					</p-column>

					<p-column class="align-center" field="lead_ids" header="Leads">
						<template #body="request">
							<label v-for="id in request.data.lead_ids">
								<router-link :to="`/leads/${id}`">{{ id }}</router-link>
							</label>
						</template>
					</p-column>
					<p-column class="align-center" field="user" header="Correspondences">
						<template #body="request">
							{{ request.data.email_log_ids.length }}
						</template>
					</p-column>

					<p-column field="request_type" header="Status">
						<template #body="request">
							{{ capitalize(request.data.status) }}
						</template>
					</p-column>

					<p-column field="request_type" header="Flags">
						<template #body="request">
							<icon
								size="24px"
								style="color: var(--red)"
								v-if="request.data.flags.dnc"
								type="phone-off-outline"
								v-tooltip.top="`Requester was DNCed`"
							/>
							<icon
								size="24px"
								style="color: var(--green)"
								v-if="request.data.flags.verified"
								type="account-check"
								v-tooltip.top="'Request passed verification checks'"
							/>
							<icon
								size="24px"
								style="color: var(--red)"
								v-if="request.data.flags.offline"
								type="cloud-off-outline"
								v-tooltip.top="'Requires offline correspondence'"
							/>
						</template>
					</p-column>

					<p-column header="Tools">
						<template #body="request">
							<div v-if="request.data.status === 'pending' || request.data.status === 'reviewed'">
								<template v-if="!request.data.flags.offline">
									<icon
										v-tooltip.top="'Mark Verified'"
										class="tool"
										type="check"
										size="24px"
										@click="markVerified(request.data.id)"
									/>
									<icon
										v-tooltip.top="'Mark UnVerified'"
										class="tool unverified"
										type="close"
										size="24px"
										@click="markUnVerified(request.data.id)"
									/>
								</template>
								<template v-else>
									<icon
										v-tooltip.top="'Mark Processed'"
										class="tool"
										type="hand"
										size="24px"
										@click="markUnVerified(request.data.id)"
									/>
								</template>
							</div>
						</template>
					</p-column>
				</p-data-table>
				<p-paginator :rows="query.pagination.page_size" :total-records="total_records" @page="handlePageChange" />
			</div>
		</div>

		<p-confirm-dialog />
	</div>
</template>

<script lang="ts">
import queryForm from '@/components/widgets/QueryForm.vue';
import { getCCPARequestList, updateCCPARequest } from '@GQL';
import { formatDate, phoneFormat, title, capitalize, displaySelected } from '@/lib/Filters';
import { ccpa_request_type_options, ccpa_status_options } from '@/lib/Options';
import pConfirmDialog from 'primevue/confirmdialog';
import { find } from 'lodash-es';

export default {
	name: 'CCPARequestList',
	components: {
		queryForm,
		pConfirmDialog,
	},
	props: {},
	data() {
		return {
			breadcrumb_items: [{ label: 'CCPA Requests' }],
			query: {
				filters: {
					type: [],
					status: [],
				},
				pagination: {
					page_size: 50,
					page: 0,
				},
				order: {
					field: 'created_at',
					desc: true,
				},
			},
			ccpa_status_options,
			ccpa_request_type_options,
			requests: [],
			total_records: 0,
			loading: false,
		};
	},
	async mounted() {
		// so get List
		await this.getList();
	},
	methods: {
		displaySelected,
		formatDate,
		phoneFormat,
		capitalize,
		handlePageChange(data) {
			this.query.pagination.page = data.page;
			return this.getList();
		},

		formatCCPARequest(request) {
			const fields = [];
			Object.keys(request).forEach((key) => {
				fields.push(`${title(key)}: "${request[key]}"`);
			});

			return fields.join('<br />');
		},
		markUnVerified(id) {
			this.$confirm.require({
				header: 'Mark UnVerified',
				message:
					'Once aged, this request will be rejected. The request will NOT be completed, and a rejection email will be sent to the requester.',
				accept: async () => {
					this.loading = true;
					try {
						const res = await updateCCPARequest(id, {
							flags: { verified: false },
							status: 'reviewed',
							reviewed_by: this.$root.sessionStore.user.id,
						});
						const found = find(this.requests, { id: res.id });
						if (found) {
							found.status = res.status;
							found.flags = res.flags;
						}

						this.$toast.add({
							severity: 'success',
							summary: 'CCPA Request was updated',
							life: 6000,
						});
					} catch (err) {
						console.error(err, 'Update failed');
						this.$toast.add({
							severity: 'error',
							summary: 'Failed to update CCPA Request',
							life: 6000,
						});
					} finally {
						this.loading = false;
					}
				},
			});
		},
		markProcessed(id) {
			this.$confirm.require({
				header: 'Mark Processed',
				message: 'Mark this request as handled and no longer eligible for processing.',
				accept: async () => {
					this.loading = true;
					try {
						const res = await updateCCPARequest(id, {
							status: 'processed',
							reviewed_by: this.$root.sessionStore.user.id,
						});

						const found = find(this.requests, { id: res.id });

						if (found) {
							found.status = res.status;
							found.flags = res.flags;
						}

						this.$toast.add({
							severity: 'success',
							summary: 'CCPA Request was updated',
							life: 6000,
						});
					} catch (err) {
						console.error(err, 'Update failed');
						this.$toast.add({
							severity: 'error',
							summary: 'Failed to update CCPA Request',
							life: 6000,
						});
					} finally {
						this.loading = false;
					}
				},
			});
		},

		markVerified(id) {
			this.$confirm.require({
				header: 'Mark Verified',
				message:
					'Once aged, this request will be completed as requested. If unable to complete, this request will be marked for offline review.',
				accept: async () => {
					this.loading = true;
					try {
						const res = await updateCCPARequest(id, {
							flags: { verified: true },
							status: 'reviewed',
							reviewed_by: this.$root.sessionStore.user.id,
						});

						const found = find(this.requests, { id: res.id });

						if (found) {
							found.status = res.status;
							found.flags = res.flags;
						}

						this.$toast.add({
							severity: 'success',
							summary: 'CCPA Request was updated',
							life: 6000,
						});
					} catch (err) {
						console.error(err, 'Update failed');
						this.$toast.add({
							severity: 'error',
							summary: 'Failed to update CCPA Request',
							life: 6000,
						});
					} finally {
						this.loading = false;
					}
				},
			});
		},
		async getList(refresh?: boolean) {
			this.loading = true;
			const filters = [];

			if (this.query.filters.type.length !== 0) {
				const types_str = this.query.filters.type
					.map((row) => {
						return `type = '${row}'`;
					})
					.join(' OR ');
				filters.push('(' + types_str + ')');
			}

			if (this.query.filters.status.length !== 0) {
				const types_str = this.query.filters.status
					.map((row) => {
						return `status = '${row}'`;
					})
					.join(' OR ');
				filters.push('(' + types_str + ')');
			}

			if (filters.length == 0) {
				filters.push('id IS NOT NULL');
			}

			try {
				const { rows, row_count } = await getCCPARequestList({ ...this.query, filters: [filters] }, refresh);
				this.total_records = row_count;
				this.requests = rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to load data',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.request-form {
	display: flex;
	flex-flow: row wrap;
	padding: 0.5rem 1rem;
	border-radius: 5px;
	//border: 1px solid var(--gray-10);

	.field {
		border-bottom: 2px solid var(--red);
		flex: auto 0;
		width: auto;
		margin: 0.25rem;
		padding: 0.5rem 1rem;

		.label {
			font-weight: bold;
		}

		&.-verified {
			border-bottom: 2px solid var(--green);
		}
	}
}

.tool {
	&:hover {
		&.unverified {
			color: var(--red);
		}

		color: var(--color-a);
		cursor: pointer;
	}
}
</style>
