<template>
	<ul class="table-list">
		<template v-if="lead.data.dob">
			<li>
				<div class="label">Date of Birth</div>
				<div class="value">
					{{ formatDate(lead.data.dob, 'MM/DD/YYYY') }}
				</div>
			</li>
			<li>
				<div class="label">Age</div>
				<div class="value">
					{{ dobToAge(lead.data.dob) }}
				</div>
			</li>
		</template>
		<li>
			<div class="label">Gender</div>
			<div class="value">
				{{ lead.data.sex || 'N/A' }}
			</div>
		</li>
		<li>
			<div class="label">Has Medicare Parts A &amp; B</div>
			<div class="value">
				{{ !isNil(lead.data.has_medicare_parts_a_b) ? lead.data.has_medicare_parts_a_b : 'Unknown' }}
			</div>
		</li>
	</ul>
</template>

<script>
import { isNil } from 'lodash-es';
import { formatDate, dobToAge } from '@/lib/Filters';

export default {
	name: 'MedicareDetails',
	props: {
		lead: {
			type: Object,
			required: true,
		},
	},
	methods: {
		dobToAge,
		formatDate,
		isNil,
	},
};
</script>
