import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getAccountOffers(filters?: QueryFilters, no_cache?: string[]) {
	const query = `
        query GetAccountOffers($filters: [[Any]]){
            accountOffers(filters: $filters) {
            	id
                offer {
                    id
                    name
                    credit_type
                    credit_amount
                    description
                    required_funding
                    trigger
                    expiration
                }
                status
                claimed_at
				created_at
            }
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.accountOffers;
}
