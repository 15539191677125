import { $GQL } from '@GQL/index';
import type { Source } from '@nextgenleads/source-driver';

export async function insertSource(data: Omit<Source, 'source_hash' | 'created_at'>): Promise<{ id: string }> {
	const mutation = `
        mutation InsertSource($data: JSONObject!) {
            insertSource(data: $data) {
                source_id
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertSource;
}
