import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';
import type { AuctionConfig } from '@nextgenleads/auction-driver';

export async function getAuctionConfigs(filters?: QueryFilters, no_cache?: string[]): Promise<AuctionConfig[]> {
	const query = `
        query GetAuctionConfigs($filters: [[Any]]) {
            auctionConfigs(filters: $filters) {
				id
				name
				vertical_id
				description
				status
				vendor_id
				product
			}
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.auctionConfigs;
}
