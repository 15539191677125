<template>
	<div>
		<line-loader :show="loading" />
		<gutter size="10px" />
		<div v-if="'offer' in account_offer && account_offer.offer.id">
			<div>
				<h3>Applied Offer:</h3>
				<div class="offer-details">
					<div class="sub-value">
						{{ account_offer.offer.id }}
					</div>
					<div class="name">
						{{ account_offer.offer.name }} - {{ capitalize(account_offer.offer.credit_type) }} Credit -
						{{
							account_offer.offer.credit_type === 'flat'
								? currency(account_offer.offer.credit_amount)
								: account_offer.offer.credit_amount * 100 + '%'
						}}
					</div>
					<div class="description">
						{{ account_offer.offer.description }}
					</div>
					<div class="credit-type">
						Trigger requirement: {{ currency(account_offer.offer.required_funding) }} ({{
							account_offer.offer.trigger
						}})
					</div>
					<div v-if="account_offer.offer.expiration" class="expiration flex justify-content-between">
						<div class="label">Expires</div>
						<div class="sub-value">
							{{ formatDate(new Date(account_offer.offer.expiration), 'h:mm A ddd, MMM D, YYYY') }}
						</div>
					</div>
					<div v-if="$root.sessionStore.hasPermission('Offers.ManualApply')">
						<p-button text label="Apply Offer" @click="preApplyOffer" />
					</div>
				</div>
			</div>
			<div class="flex justify-content-end pt-3">
				<p-button icon="remove" label="Remove Offer" @click="updateOffer(account_offer.id, 'archived')" />
			</div>
		</div>
		<div v-else>
			<p-button icon="pi pi-plus" label="Add Offer" @click="openModal" />
		</div>
		<gutter size="25px" />
		<div>
			<h3>Offer History</h3>
			<p-data-table :value="previous_offers" :loading="loading">
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<template #loading>
					<line-loader :show="table_loading" />
				</template>
				<p-column header="Offer">
					<template #body="acc_offer">
						{{ acc_offer.data.offer.name }}
						<div class="sub-value">{{ acc_offer.data.offer.id }}</div>
					</template>
				</p-column>
				<p-column header="Type">
					<template #body="acc_offer">
						{{ capitalize(acc_offer.data.offer.credit_type) }} Credit -
						{{
							acc_offer.data.offer.credit_type === 'flat'
								? currency(acc_offer.data.offer.credit_amount)
								: acc_offer.data.offer.credit_amount * 100 + '%'
						}}
					</template>
				</p-column>
				<p-column header="Status">
					<template #body="acc_offer">
						<div
							class="flex clickable"
							v-tooltip.top="'Click to Activate Offer'"
							@click="updateOffer(acc_offer.data.id, 'pending')"
							v-if="acc_offer.data.status === 'archived' && !account_offer.offer"
						>
							{{ capitalize(acc_offer.data.status) }}
							<div>
								<icon type="refresh" size="20px" />
							</div>
						</div>
						<div v-else-if="acc_offer.data.status === 'claimed'">
							Claimed On {{ formatDate(new Date(acc_offer.data.claimed_at), 'MM/DD/YYYY') }}
						</div>
						<div v-else>
							{{ capitalize(acc_offer.data.status) }}
						</div>
					</template>
				</p-column>
			</p-data-table>
		</div>
		<gutter size="20px" />
		<p-dialog :modal="true" v-model:visible="show_offer_modal">
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="cash" size="24px" style="color: var(--color-b)" />
					<strong>Select an Offer</strong>
				</div>
			</template>
			<div class="flex-flex-column">
				<div class="control-group">
					<div class="inner">
						<div class="control-label">Offers:</div>
						<p-dropdown
							v-model="selected_offer"
							name="offer_id"
							option-label="label"
							option-value="value"
							:options="validOfferOptions"
							placeholder="Select an Offer"
						/>
					</div>
				</div>
			</div>

			<template #footer>
				<div class="flex justify-content-end">
					<p-button :loading="loading" label="Add Offer" @click="addOffer" />
				</div>
			</template>
		</p-dialog>
	</div>
	<p-dialog :modal="true" v-model:visible="show_transaction_amount" :style="{ width: '20vw' }">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="cash" size="24px" style="color: var(--color-b)" />
				<strong>Enter Transaction Amount</strong>
			</div>
		</template>
		<div>
			<p>
				Since this offer is a percentage offer, you must specify the transaction amount to be used in calculating the
				credit the account will receive. <strong>{{ percentage(account_offer.offer.credit_amount) }}</strong> is the
				percentage being offered to this account.
			</p>
			<div class="flex-flex-column">
				<div class="control-group">
					<div class="inner">
						<div class="control-label req">Transaction Amount:</div>
						<p-input-currency v-model="apply_offer_transaction_amount" />
					</div>
				</div>
			</div>
		</div>

		<template #footer>
			<div class="flex justify-content-end">
				<p-button :loading="loading" label="Apply Offer" @click="applyOffer" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import {
	updateAccountOffer,
	getAccountOffers,
	applyOffer,
	getOffersAsOptions,
	insertAccountOffer,
	getTransactionsList,
} from '@GQL';
import { get, capitalize, groupBy } from 'lodash-es';
import { currency, formatDate, percentage } from '@/lib/Filters';
import pDialog from 'primevue/dialog';

export default {
	name: 'AttachOfferModal',
	emits: ['refresh'],
	components: {
		pDialog,
	},
	props: {
		account_id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			apply_offer_loading: false,
			table_loading: false,
			show_offer_modal: false,
			show_transaction_amount: false,
			selected_offer: '',
			options: [],
			account_offer: {},
			apply_offer_transaction_amount: 0,
			previous_offers: [],
		};
	},
	async mounted() {
		this.loading = true;
		try {
			await this.getOffers();
			await this.getAttachedOffers();
		} finally {
			this.loading = false;
		}
	},
	computed: {
		mpid() {
			return this.$route.params.mpid || this.$root.appStore.mpid;
		},
		validOfferOptions() {
			return this.options;
		},
	},
	methods: {
		capitalize,
		currency,
		percentage,
		formatDate,
		async preApplyOffer() {
			if ('offer' in this.account_offer && this.account_offer.offer.credit_type === 'percentage') {
				// if the offer is a percentage then ask for an amount to compute it
				this.show_transaction_amount = true;
			} else {
				return this.applyOffer();
			}
		},
		async applyOffer() {
			// make gql call
			const params = {
				account_offer_id: this.account_offer.id,
				account_id: this.account_id,
				user_id: this.$root.sessionStore.user.id,
			};

			if ('offer' in this.account_offer && this.account_offer.offer.credit_type === 'percentage') {
				if (this.apply_offer_transaction_amount === 0) {
					this.$toast.add({
						severity: 'error',
						summary: 'A Transaction amount is required to continue',
						life: 7000,
					});
					return;
				} else {
					params.transaction_amount = this.apply_offer_transaction_amount;
					this.show_transaction_amount = false;
				}
			}

			this.loading = true;
			try {
				const res = await applyOffer(params);
				if ('txn_id' in res) {
					this.$toast.add({
						severity: 'success',
						summary: 'Offer was applied manually',
						detail: `The new account balance is ${currency(res.current_balance)}`,
						life: 6000,
					});
					this.$emit('refresh');
					await this.getAttachedOffers();
				}
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to apply offer',
				});
			} finally {
				this.loading = false;
			}
		},
		openModal() {
			this.show_offer_modal = true;
			this.selected_offer = '';
		},
		async getAttachedOffers() {
			this.table_loading = true;
			try {
				// search AccountOffers for account_id +
				const all_offers = await getAccountOffers([[`account_id = '${this.account_id}'`]]);

				const offer_obj = groupBy(all_offers, 'status');
				// figure out which if any are pending
				const pending_offers = get(offer_obj, 'pending', []);
				this.previous_offers = [...get(offer_obj, 'claimed', []), ...get(offer_obj, 'archived', [])];

				if (pending_offers.length === 1) {
					this.account_offer = { ...pending_offers[0] };
				} else if (pending_offers.length === 0) {
					this.account_offer = {};
				}
			} finally {
				this.table_loading = false;
			}
		},

		async updateOffer(account_offer_id, status) {
			this.loading = true;
			try {
				// change to archive the offer_id + account_id
				const result = await updateAccountOffer(account_offer_id, { status });
				if (result) {
					this.$toast.add({
						summary: status === 'archived' ? 'Offer has been removed from account' : 'Offer has been activated',
						life: 6000,
					});
					this.show_offer_modal = false;
					this.selected_offer = '';
					this.$emit('refresh');
					await this.getAttachedOffers();
				}
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to remove offer from account',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},

		async addOffer() {
			this.loading = true;
			try {
				const result = await insertAccountOffer({
					account_id: this.account_id,
					user_id: this.$root.sessionStore.user.id,
					offer_id: this.selected_offer,
					status: 'pending',
				});
				// use insertAccountOffer
				if (result) {
					this.$toast.add({
						summary: 'Offer has been added to account',
						life: 6000,
					});
					this.show_offer_modal = false;
					this.selected_offer = '';
					this.offer = { ...result };
					await this.getAttachedOffers();
					this.$emit('refresh');
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to add offer to account',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getOffers() {
			this.loading = true;
			// get the list for options
			try {
				const filters = [
					[
						`mpid = '${this.mpid}'`,
						"status = 'active'",
						`(expiration is NULL OR expiration < '${new Date().toISOString()}')`,
					],
				];
				const results = await getOffersAsOptions(filters);
				this.options = results;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get offers',
					life: 6000,
				});
				console.error(err);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
h3 {
	font-size: var(--font-size-base);
	font-weight: bold;
}

.offer-details {
	border: 1px solid var(--gray-15);
	border-radius: 3px;
	box-shadow: 0 2px 0 var(--gray-10);
	font-size: var(--font-size-sm);
	line-height: 1.5;
	padding: 1rem;

	.name {
		font-size: var(--font-size-base);
		font-weight: bold;
	}

	.description {
		color: var(--gray-50);
	}
}

.clickable {
	&:hover {
		color: var(--color-b);
		cursor: pointer;
	}
}
</style>
