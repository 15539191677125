// ----------------------------------------------------------------------------------------------------
// FILTER: duration
// Show a humanized duration
// ----------------------------------------------------------------------------------------------------

import humanizeDuration from 'humanize-duration';

const shortEnglishHumanizer = humanizeDuration.humanizer({
	language: 'shortEn',
	languages: {
		shortEn: {
			y: () => 'y',
			mo: () => 'mo',
			w: () => 'w',
			d: () => 'd',
			h: () => 'h',
			m: () => 'm',
			s: () => 's',
			ms: () => 'ms',
		},
	},
	spacer: '',
	delimiter: ' ',
});

export function duration(value: number) {
	const value_in_ms = value * 1000;
	return shortEnglishHumanizer(value_in_ms);
}
