<template>
	<div id="account-form" class="view-content">
		<div class="page-heading">
			<h1>{{ header }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card class="account-form-content">
			<template #content>
				<AccountForm ref="account_form" :is-parent="isParentAccount" :parent-account-id="parentAccountId" />
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import AccountForm from '@/views/Accounts/Record/Form.vue';

export default {
	name: 'AccountRecordPage',
	components: {
		AccountForm,
	},
	inject: {
		nested_mpid: {
			from: 'nested_mpid',
			default: null,
		},
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
			breadcrumb_items: [],
		};
	},
	computed: {
		// we will have a meta value for when a parent account is starting the flow of a sub account....
		isCreate() {
			return this.$route.meta.new;
		},
		isParentAccount() {
			return this.$route.meta.parent_account;
		},
		isChildAccount() {
			return this.$route.meta.child_account;
		},
		parentAccountId() {
			if (this.$root.sessionStore.account.is_parent) {
				return this.$root.sessionStore.account.id;
			} else if (this.isChildAccount) {
				return this.$route.params.parent_account_id;
			} else {
				return null;
			}
		},
		header() {
			if (this.isParentAccount) {
				return this.isCreate ? 'New Parent Account' : 'Update Parent Account';
			} else {
				return this.isCreate ? 'New Account' : 'Update Account';
			}
		},
		mpid() {
			return this.nested_mpid || this.$root.appStore.mpid;
		},
	},
	created() {
		let route = '';
		if (this.mpid) {
			route = this.mpid ? `/marketplaces/manage/${this.mpid}/` : '/';
			if (this.isParentAccount) {
				route += 'parent-accounts';
			} else {
				route += 'accounts';
			}
			this.breadcrumb_items.push({
				label: this.isParentAccount ? 'Parent Accounts' : 'Accounts',
				route,
			});
		} else {
			if (this.isParentAccount) {
				route = '/parent-accounts';
			} else {
				route = '/accounts';
			}
			this.breadcrumb_items.push({
				label: this.isParentAccount ? 'Parent Accounts' : 'Accounts',
				route,
			});
		}

		if (this.isCreate) {
			if (this.isParentAccount) {
				this.breadcrumb_items.push({ label: 'New Parent Account' });
			} else {
				this.breadcrumb_items.push({ label: 'New Account' });
			}
		}
	},
};
</script>

<style lang="less" scoped>
.account-form-content {
	max-width: 760px;
}
</style>
