import { $GQL } from '@GQL/index';
import type { DNC } from '@nextgenleads/dnc-driver';

export async function insertDNC(data: Omit<DNC, 'modified_at' | 'created_at'>): Promise<{ id: string }> {
	const mutation = `
        mutation InsertDNC($data: JSONObject!) {
            insertDNC(data: $data) {
                id
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertDNC;
}
