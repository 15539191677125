<template>
	<div class="view-content">
		<div class="privacy-request-form">
			<h1>Privacy Request Form</h1>
			<div class="form-content">
				<p>
					As a resident of certain states, you have the right to make certain requests related to our processing of your
					personal information as explained more fully in our Privacy Policy. Exemptions may apply. You may exercise
					your rights by completing the form below or by emailing us at
					<a href="mailto:privacy@nextgenleads.com">privacy@nextgenleads.com</a>.
				</p>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">I am a resident of...</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="privacy_request.state"
									:options="state_of_residence_options"
									option-label="label"
									option-value="value"
									placeholder="Select state..."
								/>
								<div class="sub-value">
									If you are not a resident of one of the states listed here, we are unable to fulfill your request at
									this time.
								</div>
								<div v-if="v$.privacy_request.state.$error" class="validation-error">
									{{ v$.privacy_request.state.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label req">First Name</label>
								<p-input-text v-model="privacy_request.first_name" />
								<div v-if="v$.privacy_request.first_name.$error" class="validation-error">
									{{ v$.privacy_request.first_name.$errors[0].$message }}
								</div>
							</div>
							<div class="field">
								<label class="control-label req">Last Name</label>
								<p-input-text v-model="privacy_request.last_name" />
								<div v-if="v$.privacy_request.last_name.$error" class="validation-error">
									{{ v$.privacy_request.last_name.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Address</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="privacy_request.address" />
								<div v-if="v$.privacy_request.address.$error" class="validation-error">
									{{ v$.privacy_request.address.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field half">
								<label class="control-label req">City</label>
								<p-input-text v-model="privacy_request.city" />
								<div v-if="v$.privacy_request.city.$error" class="validation-error">
									{{ v$.privacy_request.city.$errors[0].$message }}
								</div>
							</div>
							<div class="field">
								<div class="flex gap-20">
									<div class="field">
										<label class="control-label">State</label>
										<p-dropdown
											v-model="privacy_request.state"
											:options="state_of_residence_options"
											option-label="label"
											option-value="value"
											placeholder="Select state..."
										/>
									</div>
									<div class="field">
										<label class="control-label req">Zip</label>
										<p-input-mask v-model="privacy_request.zip" mask="99999" />
										<div v-if="v$.privacy_request.zip.$error" class="validation-error">
											{{ v$.privacy_request.zip.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label req">Email Address</label>
								<p-input-text v-model="privacy_request.email" />
								<div v-if="v$.privacy_request.email.$error" class="validation-error">
									{{ v$.privacy_request.email.$errors[0].$message }}
								</div>
							</div>
							<div class="field">
								<label class="control-label req">Phone</label>
								<p-input-mask v-model="privacy_request.phone" mask="(999) 999-9999" />
								<div v-if="v$.privacy_request.phone.$error" class="validation-error">
									{{ v$.privacy_request.phone.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<p-fieldset legend="Authorized Agent">
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field fit">
									<p-checkbox input-id="authorized_agent" v-model="privacy_request.authorized_agent" binary />
								</div>
								<div class="field">
									<label class="checkbox" for="authorized_agent">
										I am submitting this request as an authorized agent for the above consumer.
									</label>
								</div>
							</div>
						</div>
					</div>
					<template v-if="privacy_request.authorized_agent">
						<div class="control-group">
							<div class="inner">
								<label class="control-label req">Agent Full Name</label>
								<div class="controls">
									<div class="field">
										<p-input-text v-model="privacy_request.authorized_agent_name" />
										<div v-if="v$.privacy_request.authorized_agent_name.$error" class="validation-error">
											{{ v$.privacy_request.authorized_agent_name.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label req">Agent Address</label>
								<div class="controls">
									<div class="field">
										<p-input-text v-model="privacy_request.authorized_agent_address" />
										<div v-if="v$.privacy_request.authorized_agent_address.$error" class="validation-error">
											{{ v$.privacy_request.authorized_agent_address.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<div class="controls gap-20">
									<div class="field">
										<label class="control-label req">Agent Email Address</label>
										<p-input-text v-model="privacy_request.authorized_agent_email" />
										<div v-if="v$.privacy_request.authorized_agent_email.$error" class="validation-error">
											{{ v$.privacy_request.authorized_agent_email.$errors[0].$message }}
										</div>
									</div>
									<div class="field">
										<label class="control-label req">Agent Phone Number</label>
										<p-input-mask v-model="privacy_request.authorized_agent_phone" mask="(999) 999-9999" />
										<div v-if="v$.privacy_request.authorized_agent_phone.$error" class="validation-error">
											{{ v$.privacy_request.authorized_agent_phone.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</template>
				</p-fieldset>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label">How do you want to received the information you are requesting?</label>
						<div class="controls gap-40">
							<div v-for="option in receipt_options" class="field fit">
								<div class="flex justify-content-start">
									<p-radio-button
										v-model="privacy_request.receipt_type"
										:value="option.value"
										:input-id="`receipt_type_${option.value}`"
									/>
									<label class="control-label checkbox" :for="`receipt_type_${option.value}`">{{ option.label }}</label>
								</div>
							</div>
						</div>
						<div v-if="v$.privacy_request.receipt_type.$error" class="validation-error">
							{{ v$.privacy_request.receipt_type.$errors[0].$message }}
						</div>
					</div>
				</div>
				<p-fieldset legend="Request Details">
					<p>I would like to make the following request:</p>
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">
									<div v-for="option in requestTypeOptions" class="request-option">
										<p-radio-button
											v-model="privacy_request.request_type"
											:value="option.value"
											:input-id="`request_type_${option.value}`"
										/>
										<label class="control-label checkbox" :for="`request_type_${option.value}`">
											{{ option.label }}
										</label>
									</div>
								</div>
							</div>
							<div v-if="v$.privacy_request.request_type.$error" class="validation-error">
								{{ v$.privacy_request.request_type.$errors[0].$message }}
							</div>
						</div>
					</div>
				</p-fieldset>
				<gutter size="20px" />
				<div
					v-if="privacy_request.request_type === 'correction' || privacy_request.request_type === 'appeal'"
					class="control-group"
				>
					<div class="inner">
						<label v-if="privacy_request.request_type === 'correction'" class="control-label"
							>Please explain the information you believe needs correction...</label
						>
						<label v-if="privacy_request.request_type === 'appeal'" class="control-label"
							>Please explain the basis for your appeal request...</label
						>
						<div class="controls">
							<div class="field">
								<p-textarea v-model="privacy_request.notes" />
								<div v-if="v$.privacy_request.notes.$error" class="validation-error">
									{{ v$.privacy_request.notes.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<p>
					By submitting this form, I give my electronic signature and certify that I am the above-named individual or a
					person authorized by the above-named individual to submit this form on their behalf. I understand that NextGen
					Leads LLC may contact me at the phone number I entered above and/or via email to obtain identity verification
					and validate this request.
				</p>
				<div class="action">
					<p-button :loading="loading" label="Submit" icon="pi pi-check" @click="submitPrivacyRequest()" />
				</div>
			</div>
		</div>
	</div>
	<div class="footer">
		<div>&copy;{{ year }} FirstQuote Insure&trade;</div>
		<div>
			<a href="https://firstquote.insure/terms-conditions" target="_blank">Terms &amp; Conditions</a> |
			<a href="https://firstquote.insure/privacy-policy" target="_blank">Privacy Policy</a> |
			<a href="/privacy-request-form">Privacy Request Form</a>
		</div>
	</div>
</template>

<script lang="ts">
import dayjs from 'dayjs';
import { endpoint } from '@GQL';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf, email } from '@vuelidate/validators';
import axios, { type AxiosRequestConfig } from 'axios';

export default {
	name: 'PrivacyRequestForm',
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			year: dayjs().format('YYYY'),
			loading: false,
			privacy_request: {
				first_name: null,
				last_name: null,
				address: null,
				city: null,
				state: null,
				zip: null,
				email: null,
				phone: null,
				authorized_agent: false,
				authorized_agent_name: null,
				authorized_agent_address: null,
				authorized_agent_email: null,
				authorized_agent_phone: null,
				receipt_type: null,
				request_type: null,
				notes: null,
			},
			state_of_residence_options: [
				{ label: 'California', value: 'CA' },
				{ label: 'Colorado', value: 'CO' },
				{ label: 'Connecticut', value: 'CT' },
				{ label: 'Montana', value: 'MT' },
				{ label: 'Oregon', value: 'OR' },
				{ label: 'Texas', value: 'TX' },
				{ label: 'Utah', value: 'UT' },
				{ label: 'Virginia', value: 'VA' },
			],
			receipt_options: [
				{ label: 'Mail', value: 'mail' },
				{ label: 'E-mail', value: 'email' },
			],
			request_type_options: [
				{ label: 'Opt-out of sale or sharing of information', value: 'opt_out' },
				{ label: 'Request to know categories of information', value: 'categories' },
				{ label: 'Request to know specific pieces of information', value: 'specific_info' },
				{ label: 'Request to delete information', value: 'delete' },
				{ label: 'Request to correct information', value: 'correction' },
				{
					label: 'Request to revoke consent to process sensitive personal information',
					value: 'revoke_consent',
				},
				{ label: 'Appeal denial of a privacy request', value: 'appeal' },
			],
		};
	},
	computed: {
		requestTypeOptions() {
			if (this.privacy_request.state === 'CA') {
				return this.request_type_options.filter((o) => o.value !== 'revoke_consent');
			}
			return this.request_type_options;
		},
	},
	validations() {
		return {
			privacy_request: {
				state: {
					required: helpers.withMessage('A state of residency is required', required),
				},
				first_name: {
					required: helpers.withMessage('A first name is required', required),
				},
				last_name: {
					required: helpers.withMessage('A last name is required', required),
				},
				address: {
					required: helpers.withMessage('An address is required', required),
				},
				city: {
					required: helpers.withMessage('A city is required', required),
				},
				zip: {
					required: helpers.withMessage('A zip is required', required),
				},
				email: {
					required: helpers.withMessage('An email address is required', required),
					email: helpers.withMessage('This email address is invalid', email),
				},
				phone: {
					required: helpers.withMessage('A phone number is required', required),
				},
				authorized_agent_name: {
					required: helpers.withMessage(
						'A authorized agent name is required',
						requiredIf(this.privacy_request.authorized_agent)
					),
				},
				authorized_agent_address: {
					required: helpers.withMessage(
						'A authorized agent address is required',
						requiredIf(this.privacy_request.authorized_agent)
					),
				},
				authorized_agent_email: {
					required: helpers.withMessage(
						'A authorized agent email is required',
						requiredIf(this.privacy_request.authorized_agent)
					),
					email: helpers.withMessage('This email address is invalid', email),
				},
				authorized_agent_phone: {
					required: helpers.withMessage(
						'A authorized agent phone is required',
						requiredIf(this.privacy_request.authorized_agent)
					),
				},
				receipt_type: {
					required: helpers.withMessage('A choice is required', required),
				},
				request_type: {
					required: helpers.withMessage('A choice is required', required),
				},
				notes: {
					required: helpers.withMessage(
						'More information is required',
						requiredIf(
							this.privacy_request.request_type === 'correction' || this.privacy_request.request_type === 'appeal'
						)
					),
				},
			},
		};
	},
	methods: {
		async submitPrivacyRequest() {
			const is_valid = await this.v$.$validate();

			if (is_valid && !this.loading) {
				try {
					this.loading = true;

					// Make a request to the API endpoint
					const result = await axios.post(endpoint + 'ccpa', this.privacy_request, {
						responseType: 'json',
					});

					if (result) {
						if (result.data.success) {
							this.$toast.add({
								severity: 'success',
								summary: 'Request Received',
								detail: 'Please allow up to 10 business days for your request to be processed',
								life: 3000,
							});
						}
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Something went wrong',
						detail: 'We were unable to process your request online, please email us at privacy@nextgenleads.com',
						life: 8000,
					});
				} finally {
					this.loading = false;
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
.privacy-request-form {
	margin: 0 auto;
	max-width: 720px;
}

.request-option {
	align-items: start;
	display: flex;
	margin-bottom: 1em;
}

:deep(.p-fieldset) {
	background-color: transparent;
	border-radius: 5px;
}

:deep(.p-fieldset-legend-text) {
	font-size: 0.875rem;
}

.footer {
	background-color: white;
	border-top: 1px solid var(--gray-15);
	display: flex;
	font-size: 0.875rem;
	justify-content: space-between;
	padding: 30px;
}
</style>
