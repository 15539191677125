<template>
	<p-dialog v-model:visible="show_modal" modal style="max-width: 1200px; width: 100%">
		<template #header>
			<div class="flex align-items-center gap-10">
				<icon type="phone-check" size="24px" /><strong>Purchase New Phone Number</strong>
			</div>
		</template>
		<div class="flex align-items-center" style="gap: 10px">
			<p-input-text v-model="area_code" placeholder="Enter area code or leave empty" style="width: 250px" />
			<p-button label="Get Available Numbers" @click="getAvailableNumbers()" />
		</div>

		<gutter size="20px" />

		<div class="results">
			<p-data-table :value="available_phone_numbers" :loading="loading" data-key="phone">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">Run a search above to get a list of available phone number</div>
				</template>
				<p-column header="Phone Number" field="name" sortable sort-field="name"> </p-column>
				<p-column header="City" field="city" sortable sort-field="city"> </p-column>
				<p-column header="State" field="state" sortable sort-field="state"> </p-column>
				<p-column header="ZIP" field="zip" sortable sort-field="zip"> </p-column>
				<p-column header="Capabilities" field="capabilities" class="align-center">
					<template #body="row">
						<div class="capabilities">
							<div v-if="row.data.capabilities.includes('voice')" class="capability">
								<icon type="phone-in-talk" size="18px" />
							</div>
							<div v-if="row.data.capabilities.includes('sms')" class="capability">
								<icon type="message-text" size="18px" />
							</div>
							<div v-if="row.data.capabilities.includes('mms')" class="capability">
								<icon type="message-image" size="18px" />
							</div>
							<div v-if="row.data.capabilities.includes('fax')" class="capability">
								<icon type="fax" size="18px" />
							</div>
						</div>
					</template>
				</p-column>
				<p-column header="Purchase" class="align-center">
					<template #body="row">
						<p-button label="Purchase" @click="purchasePhoneNumber(row.data.phone)">
							<template #icon>
								<div class="inline-icon button">
									<icon type="phone-check" size="16px" />
								</div>
							</template>
						</p-button>
					</template>
				</p-column>
			</p-data-table>
		</div>
		<select-sub-account-modal ref="sub_account_modal" />
	</p-dialog>
</template>

<script>
import { getAvailablePhoneNumbers } from '@/lib/GQL';
import pDialog from 'primevue/dialog';
import selectSubAccountModal from './SelectSubAccount.vue';

export default {
	name: 'PurchasePhoneModal',
	components: {
		pDialog,
		selectSubAccountModal,
	},
	props: {
		subAccount: {
			type: String,
		},
	},
	data() {
		return {
			show_modal: false,
			loading: false,
			available_phone_numbers: [],
			sub_account_id: null,
			area_code: null,
		};
	},
	methods: {
		async getAvailableNumbers() {
			this.loading = true;
			try {
				this.available_phone_numbers = await getAvailablePhoneNumbers(this.area_code);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get available numbers from Twilio',
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
		async purchasePhoneNumber(phone) {
			if (!this.subAccount) {
				this.$refs.sub_account_modal.open(phone);
			}
		},
		open() {
			this.show_modal = true;
		},
	},
};
</script>

<style lang="less" scoped>
.capabilities {
	align-items: center;
	display: flex;
	flex-direction: row;
	gap: 15px;
	justify-content: center;
}
</style>
