import { $GQL } from '@GQL';

export async function changeAccountMP(account_id: string, mpid: string) {
	const mutation = `
    mutation ChangeAccountMP($account_id: String!, $mpid: String!) {
      changeAccountMP(account_id: $account_id, mpid: $mpid)
    }
  `;

	const result = await $GQL.request(mutation, { account_id, mpid });
	return result.changeAccountMP;
}
