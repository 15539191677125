<template>
	<div class="metric card padded">
		<slot name="label">
			<div class="top">
				<div class="label">{{ label }}</div>
				<div
					v-if="!isNil(previousValue)"
					class="trend"
					:class="{ 'trending-up': trendingUp, 'trending-down': trendingDown }"
				>
					{{ getPercentageChange(value, previousValue) }}
					<icon v-if="inverseMetric ? trendingDown : trendingUp" type="trending-up" size="18px" />
					<icon v-if="inverseMetric ? trendingUp : trendingDown" type="trending-down" size="18px" />
				</div>
			</div>
		</slot>
		<slot>
			<div class="value">
				<template v-if="isCurrency">
					{{ currency(value || 0) }}
				</template>
				<template v-else-if="isPercentage">
					{{ percentage(value || 0, 2) }}
				</template>
				<template v-else-if="isNumber">
					{{ numberFormat(value || 0) }}
				</template>
				<template v-else>
					{{ value }}
				</template>
			</div>
		</slot>
		<slot name="compare">
			<div v-if="!isNil(previousValue)" class="compare-value">
				<icon type="calendar-arrow-left" size="18px" />
				<div class="v-middle inline-block">
					<template v-if="isCurrency">
						{{ currency(previousValue || 0) }}
					</template>
					<template v-else-if="isPercentage">
						{{ percentage(previousValue || 0, 2) }}
					</template>
					<template v-else-if="isNumber">
						{{ numberFormat(previousValue || 0) }}
					</template>
					<template v-else="">
						{{ previousValue }}
					</template>
				</div>
			</div>
		</slot>
	</div>
</template>

<script lang="ts">
import { isNil, round } from 'lodash-es';
import { currency, numberFormat, percentage } from '@/lib/Filters';
export default {
	name: 'MetricCard',
	props: {
		label: {
			type: String,
			default: 'Label',
		},
		value: {
			type: [Number, String, null],
			required: true,
		},
		previousValue: {
			type: [Number, null],
		},
		isNumber: {
			type: Boolean,
			default: false,
		},
		isCurrency: {
			type: Boolean,
			default: false,
		},
		isPercentage: {
			type: Boolean,
			default: false,
		},
		inverseMetric: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		trendingUp() {
			if (this.inverseMetric) {
				return this.value < this.previousValue;
			} else {
				return this.value > this.previousValue;
			}
		},
		trendingDown() {
			if (this.inverseMetric) {
				return this.value > this.previousValue;
			} else {
				return this.value < this.previousValue;
			}
		},
	},
	methods: {
		currency,
		isNil,
		numberFormat,
		percentage,
		round,
		getPercentageChange(current, previous) {
			if (previous > 0) {
				return this.round(100 * ((current - previous) / Math.abs(previous)), 2) + '%';
			}
			return '--';
		},
	},
};
</script>

<style scoped lang="less">
.metric {
	height: 100%;
}

.panel {
	background: var(--surface-a);
	border-radius: 8px;
	box-shadow: var(--shadow-default);
	height: 100%;
	position: relative;
}

.top {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.label {
	color: var(--gray-50);
	font-size: 0.875rem;
	font-weight: bold;
	line-height: 1.3;
	max-width: 25ch;
}

.trend {
	color: var(--gray-35);
	font-size: var(--font-size-sm);
	margin-left: auto;
}

.value {
	font-size: 1.5rem;
	font-weight: bold;
	margin-top: 10px;
}

.compare-value {
	color: var(--gray-35);
	margin-top: 10px;

	> * {
		vertical-align: middle;
	}

	.mdi-icon {
		margin-right: 5px;
	}
}

.trending-up {
	color: var(--green);
}

.trending-down {
	color: var(--red);
}
</style>
