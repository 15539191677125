import { $GQL } from '@GQL/index';

export async function getAuctionLog(id: string) {
	const query = `
        query GetAuctionLog($id: ID!) {
            auctionLog(id: $id) {
				id
				mpid
				attempt
				auction_id
				auction {
					id
					mpid
					name
					description
				}
				vertical_id
				lead_id
				lead_ulid
				product
				channel {
					id
					name
				}
				tags
				bids {
					bid_id
					bid_type
					max_winners
					max_bid
					max_sorting_bid
					sorting_bid
					sorting_bid_adjustments {
						description
						method
						amount
						bid_result
					}
					campaigns {
						bid_id
						price
						campaign {
							id
							mpid
							name
						}
						account {
							id
							mpid
							name
						}
						version
						max_bid
						bid
						bid_adjustments {
							description
							method
							amount
							bid_result
							price_result
						}
						minimum_bid
						sorting_bid
						sorting_bid_adjustments {
							description
							method
							amount
							bid_result
						}
						forwarding_phone
						purchase {
							id
							price
							status
						}
						error
					}
				}
				participating_campaigns
				status
				errors
				created_at
				disqualifiedCampaigns {
					campaign {
						id
						name
					}
					account {
						id
						name
					}
					dq_reason
				}
				purchases {
					account_id
					campaign_id
					status
					price
				}
			}
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.auctionLog;
}
