import AdsList from '@/views/Ads/List/Page.vue';
import AdRecord from '@/views/Ads/Record/Page.vue';

export default [
	{
		path: 'ads/new',
		name: 'CreateAd',
		component: AdRecord,
		meta: {
			new: true,
			title: 'New Ad',
			permissions: 'ads',
		},
	},
	{
		path: 'ads/:ad_id',
		name: 'EditAd',
		component: AdRecord,
		meta: {
			title: 'Edit Ad',
			permissions: 'ads',
		},
	},
	{
		path: 'ads',
		name: 'AdsList',
		component: AdsList,
		meta: {
			title: 'Ads',
			permissions: 'ads',
		},
	},
];
