import { $GQL } from '@GQL/index';
import { currency, capitalize, percentage } from '@/lib/Filters';
import type { GetListParams, QueryFilters } from '@nextgenleads/db';

export async function getOffersAsOptions(filters?: QueryFilters) {
	const query = `
    query GetOffersAsOptions($filters: [[Any]]){
        offers(filters: $filters) {
            id
            name
            trigger
            required_funding
            credit_amount
            credit_type
        }
    }       
    `;

	const result = await $GQL.request(query, {
		filters,
	});

	return result.offers.map((row) => {
		return {
			value: row.id,
			label: `${row.name} - ${capitalize(row.credit_type)}, Amount: ${row.credit_type === 'percentage' ? percentage(row.credit_amount, 2) : currency(row.credit_amount)}, Min: ${currency(row.required_funding, true)}`,
		};
	});
}
