<template>
	<div class="qualifier bid-type">
		<div class="title">
			<div class="name">Bid Type</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">Disqualify a campaign if it has a bid type that is not allowed by this auction.</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Allowed Bid Types:</label>
					<div class="controls gap-20">
						<div class="field">
							<p-checkbox v-model="modelValue.allowed_bid_types" input-id="exclusive-bid-option" value="exclusive" />
							<label for="exclusive-bid-option">Exclusive Bids</label>
						</div>
						<div class="field">
							<p-checkbox v-model="modelValue.allowed_bid_types" input-id="shared-bid-option" value="shared" />
							<label for="shared-bid-option">Shared Bids</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'BidTypeQualifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					allowed_bid_types: ['exclusive'],
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
