import { $GQL } from '@GQL/index';
import log from '@/lib/Log';
import type { GetListParams } from '@nextgenleads/db';

export async function getTransactionsList(params: GetListParams, refresh?: boolean) {
	const query = `
    query getTransactionList($params: GetListParams!){
        transactionList(params: $params) {
			row_count
            rows {
                id
                account {
                	id
                	name
                }
                created_at
                created_by
                created_by_user {
                    first_name
                    last_name
                }
                type
                sub_type
                amount
                start_balance
                end_balance
                payment_method_id
                pg_txn_id
                pg_transaction {
                	request
                	response
                	created_at
                }
                payment_method {
                    name_on_card
                    last_four
                }
            }
        }
    }       
    `;

	const no_cache = refresh ? ['transactionList'] : undefined;

	const result = await $GQL.request(query, {
		params,
		no_cache,
	});

	return result.transactionList;
}
