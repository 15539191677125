<template>
	<div id="capture-config-table">
		<p-data-table
			paginator
			:always-show-paginator="false"
			:rows="15"
			data-key="id"
			scrollable
			:value="sortedCaptureConfigs"
			:loading="loading"
		>
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<template #empty>
				<div class="dim">No results</div>
			</template>
			<p-column field="name" header="Name" sortable sortField="name">
				<template #body="row">
					<div class="name">
						<router-link :to="{ path: `capture-configs/${row.data.id}` }">{{ row.data.name }}</router-link>
					</div>
					<div class="sub-value">{{ row.data.id }}</div>
				</template>
			</p-column>
			<p-column field="vendor_id" header="Vendor ID" sortable sortField="vendor_id">
				<template #body="row">
					{{ row.data.vendor_id }}
				</template>
			</p-column>
			<p-column field="vertical_id" header="Vertical" sortable sortField="vertical_id">
				<template #body="row">
					{{ startCase(row.data.vertical_id) }}
				</template>
			</p-column>
			<p-column field="product" header="Product" class="align-center" sortable sortField="product">
				<template #body="row">
					{{ row.data.product }}
				</template>
			</p-column>
			<p-column field="method" header="Method" class="align-center" sortable sortField="method">
				<template #body="row">
					{{ row.data.method }}
				</template>
			</p-column>
			<p-column field="status" header="Status" class="align-center" sortable sortField="status">
				<template #body="row">
					{{ startCase(row.data.status) }}
				</template>
			</p-column>
			<p-column header="Tools" class="align-center">
				<template #body="row">
					<div class="toolset">
						<div class="tool">
							<p-button v-tooltip.top="'Edit'" @click.prevent="$router.push({ path: `${$route.path}/${row.data.id}` })">
								<template #icon>
									<icon type="pencil-box-outline" size="24px" />
								</template>
							</p-button>
						</div>
						<div class="tool">
							<p-button
								v-tooltip.top="'Duplicate'"
								@click.prevent="$router.push({ path: `${$route.path}/new`, query: { capture_config_id: row.data.id } })"
							>
								<template #icon>
									<icon type="content-copy" size="24px" />
								</template>
							</p-button>
						</div>
						<delete-action
							message="Are you sure you want to delete this capture config?"
							@delete="deleteConfig(row.data.id)"
						/>
					</div>
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>
<script lang="ts">
import { deleteCaptureConfig, getCaptureConfigs } from '@GQL';
import { sortBy, startCase } from 'lodash-es';
import { useSessionStore } from '@/stores/session';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	components: {
		deleteAction,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			loading: false,
			capture_configs: [],
		};
	},
	computed: {
		sortedCaptureConfigs() {
			return sortBy(this.capture_configs, ['name']);
		},
		mpid() {
			return this.$route.params.mpid ? this.$route.params.mpid : this.$root.appStore.mpid;
		},
	},
	async mounted() {
		await this.getList();
	},
	methods: {
		startCase,
		async getList(no_cache = false) {
			this.loading = true;

			let options;
			if (no_cache) {
				options = ['captureConfigs'];
			}

			try {
				const capture_configs = await getCaptureConfigs([[`mpid = '${this.mpid}'`]], options);
				this.capture_configs = capture_configs;
			} catch (err) {
				this.$toast.add({
					summary: 'Unable to get Capture Configs',
					detail: 'Please contact the dev team if the problem persists',
					severity: 'error',
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
		async deleteConfig(config_id) {
			try {
				const capture_config = await deleteCaptureConfig(config_id);
				if (capture_config.id) {
					this.$toast.add({
						severity: 'success',
						summary: 'Capture Config Deleted',
						details: capture_config.id,
						life: 3000,
					});
					await this.getList(true);
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Operation Failed',
					details: err.message,
					life: 3000,
				});
			}
		},
	},
};
</script>
<style lang="less"></style>
