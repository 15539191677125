import { $GQL } from '@GQL/index';

export async function fetchReportStatuses(list_size: number | null = null) {
	const query = `
        query FetchReportStatuses($list_size: Int!) {
            fetchReportStatuses(list_size: $list_size) {
                success,
                statuses{
                    email,
                    account_id,
                    report_name,
                    status,
                    start_date,
                    end_date,
                    date_requested,
                    last_updated
                }
            }
        }`;

	list_size = list_size || 10;
	const result = await $GQL.request(query, { list_size });
	return result;
}
