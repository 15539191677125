import { $GQL } from '@GQL/index';

export async function getFiles(folder: string) {
	const query = `
        query GetFiles($folder: String!) {
            files(folder: $folder) {
				folders {
					name
					path
				}
				files {
					key
					name
					size
					url
					last_modified
				}
            }
        }
    `;

	const result = await $GQL.request(query, { folder });
	return result.files;
}
