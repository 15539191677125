import { $GQL } from '@GQL';

export async function deleteAdTemplate(id: string) {
	const mutation = `
        mutation DeleteAdTemplate($id: ID!,) {
			deleteAdTemplate(id: $id) {
				id
			}
        }
    `;

	const result = await $GQL.request(mutation, { id });
	return result.deleteAdTemplate;
}
