import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';

export async function getShippers(filters: QueryFilters): Promise<{ id: string; name: string }[]> {
	const query = `
        query GetShippers($filters: [[Any]]!) {
            shippers(filters: $filters) {
                id
                name
                type
				campaigns {
					id
					name
				}
				cancellations {
					enabled
					pattern
				}
				status
            }
        }
    `;

	const request = await $GQL.request(query, { filters });
	return request.shippers;
}
