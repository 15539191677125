import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getSources(filters: QueryFilters) {
	const query = `
        query GetSources($filters: [[Any]]!) {
            sources(filters: $filters) {
				source_id
				network_id
				source_hash
				source_type
				biz_unit
				status
				description
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.sources;
}
