<template>
	<p-button icon="pi pi-plus" label="Add Build Step" @click="openModal" />
	<p-dialog
		v-model:visible="show_modal"
		class="add-build-step-modal"
		modal
		:dismissable-mask="true"
		style="width: 400px"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
				<strong>Add Build Step</strong>
			</div>
		</template>
		<div class="build-step-options">
			<div v-for="build_step in availableBuildSteps" class="build-step-option" @click="addBuildStep(build_step.value)">
				<div class="content-container flex align-items-center">
					<div>
						<div class="name">{{ build_step.name }}</div>
						<div class="sub-value">{{ build_step.description }}</div>
					</div>
				</div>
			</div>
			<p-message v-if="!method" :closable="false">
				Please select a capture method to view available build steps.
			</p-message>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Cancel" text @click="show_modal = false" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';

export default {
	name: 'BuildStepSelector',
	emits: ['addStep'],
	components: {
		pDialog,
		pMessage,
	},
	props: {
		buildSteps: {
			type: Array,
			default() {
				return [];
			},
		},
		method: {
			type: String,
			default: 'submit',
		},
	},
	data() {
		return {
			show_modal: false,
			build_step_options: [
				{
					name: 'Apply Scripts',
					description: 'Apply a script to the build data for a lead',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'applyScripts',
						settings: {
							scripts: [],
						},
					},
				},
				{
					name: 'Audit Tokens',
					description: 'Audit tokens that are included in the lead data',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'auditTokens',
						settings: {
							audit_trustedform: true,
							audit_jornaya: false,
						},
					},
				},
				{
					name: 'Check DNC',
					description:
						'Check if the lead\s phone number appears as a hard DNC or is on the soft DNC list within the past x number of days.',
					allowed_on_types: ['submit', 'post'],
					value: {
						script: 'checkDNC',
						settings: {
							lookback: 90,
							action: 'flag',
						},
					},
				},
				{
					name: 'Check Duplicates',
					description: 'Check if a lead with the same contact information was recently captured.',
					allowed_on_types: ['submit', 'post'],
					value: {
						script: 'checkDuplicates',
						settings: {
							action: 'flag',
						},
					},
				},
				{
					name: 'Check Spam',
					description: 'Check if a lead with the same contact information was recently captured.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'checkSpam',
						settings: {
							action: 'flag',
						},
					},
				},
				{
					name: 'Check Tests',
					description: 'Check if a lead contains the word "Test" as the first or last name',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'checkTests',
					},
				},
				{
					name: 'Check Vendor Permissions',
					description: 'Check if the vendor submitting the lead has permissions to do so for this Marketplace.',
					allowed_on_types: ['submit', 'ping'],
					value: {
						script: 'checkVendorPermissions',
					},
				},
				{
					name: 'Create Ping Call Record',
					description: 'Create a call record in redis for a ping that was won (required for tracking active calls).',
					allowed_on_types: ['post'],
					value: {
						script: 'createPingCallRecord',
					},
				},
				{
					name: 'Delay',
					description: 'Delay for a set amount of seconds before continuing the build process.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'delay',
						settings: {
							seconds: 10,
						},
					},
				},
				{
					name: 'Forward to Partners',
					description: "Send the lead information to one of NextGen's partners.",
					allowed_on_types: ['submit', 'post'],
					value: {
						script: 'forwardToPartners',
						settings: {
							partners: [],
						},
					},
				},
				{
					name: 'Normalize PII',
					description: 'Properly format and sanitize the PII information in the lead.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'normalizePII',
					},
				},
				{
					name: 'Normalize Data',
					description: 'Properly format and sanitize the data information in the lead.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'normalizeData',
					},
				},
				{
					name: 'Normalize Source',
					description: 'Properly format and sanitize the source information in the lead.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'normalizeSource',
					},
				},
				{
					name: 'Process Auction Logs',
					description: 'Create and ship purchases for winning bids in an auction.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'processAuctionLogs',
					},
				},
				{
					name: 'Process Ping Bids',
					description: 'Create purchases for the selected ping bids returned in a post request.',
					allowed_on_types: ['post'],
					value: {
						script: 'processPingBids',
					},
				},
				{
					name: 'Reply with Auction Result',
					description: 'Return the auction result with a list of winners and details about each campaign.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'replyWithAuctionResult',
					},
				},
				{
					name: 'Reply with Auction Winners',
					description:
						'Return a list of winners of the lead auction. This step MUST be preceeded by the "Send To Auctions" step.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'replyWithAuctionWinners',
					},
				},
				{
					name: 'Reply with Bids',
					description:
						'Return the bids that were collected while auctioning the lead. This step MUST be preceded by the "Send To Auctions" step.',
					allowed_on_types: ['ping', 'submit'],
					value: {
						script: 'replyWithBids',
						settings: {
							group_shared_bids: false,
							bid_multiplier: 1,
						},
					},
				},
				{
					name: 'Reply with Legacy Ping Response',
					description: 'Return the highest bid from all auctions in the same format that V3 would.',
					allowed_on_types: ['ping'],
					value: {
						script: 'replyWithLegacyPingResponse',
						settings: {
							bid_multiplier: 1,
						},
					},
				},
				{
					name: 'Reply with Legacy Post Response',
					description: 'Return the payout in the same format that v3 would.',
					allowed_on_types: ['post'],
					value: {
						script: 'replyWithLegacyPostResponse',
					},
				},
				{
					name: 'Reply with LT Bids',
					description:
						'Return the bids that were collected while auctioning the LT lead. This step MUST be preceded by the "Send To Auctions" step.',
					allowed_on_types: ['ping', 'submit'],
					value: {
						script: 'replyWithLTBids',
						settings: {
							group_shared_bids: false,
							bid_multiplier: 1,
						},
					},
				},
				{
					name: 'Reply with Status',
					description: 'Return a response that contains the acceptance status and the payout for the leads.',
					allowed_on_types: ['submit', 'post'],
					value: {
						script: 'replyWithStatus',
					},
				},
				{
					name: 'Save Lead to DB',
					description: 'Save the lead information that was submitted to the Postgres database.',
					allowed_on_types: ['submit', 'ping', 'post'],
					value: {
						script: 'saveLeadToDB',
					},
				},
				{
					name: 'Save Lead to Redis',
					description: 'Save the lead information that was submitted to Redis memory store.',
					allowed_on_types: ['submit', 'ping'],
					value: {
						script: 'saveLeadToRedis',
						settings: {
							ttl: 300,
						},
					},
				},
				{
					name: 'Save Ping to DB',
					description: 'Save the ping information to the Postgres database.',
					allowed_on_types: ['ping'],
					value: {
						script: 'savePingToDB',
					},
				},
				{
					name: 'Save Post to DB',
					description: 'Save the post information to the Postgres database.',
					allowed_on_types: ['post'],
					value: {
						script: 'savePostToDB',
					},
				},
				{
					name: 'Send to Auction',
					description:
						'Send the lead to any auction that is configured to handle it or to a specific auction specified in the settings.',
					allowed_on_types: ['submit', 'ping'],
					value: {
						script: 'sendToAuction',
						settings: {
							auction_config_id: '',
						},
					},
				},
				{
					name: 'Send to Dialer',
					description: 'Send the lead information directly to the NextGen Leads dialer.',
					allowed_on_types: ['submit', 'post'],
					value: {
						script: 'sendToDialer',
						settings: {
							list_id: '',
						},
					},
				},
				{
					name: 'Send to Queue',
					description: 'Send the lead information to a queue for processing outside of the lead service.',
					allowed_on_types: ['submit', 'post'],
					value: {
						script: 'sendToQueue',
						settings: {
							queue_id: null,
						},
					},
				},
			],
		};
	},
	computed: {
		availableBuildSteps() {
			return this.build_step_options.filter((step_option) => {
				return step_option.allowed_on_types.includes(this.method);
			});
		},
	},
	methods: {
		openModal() {
			this.show_modal = true;
		},
		addBuildStep(value) {
			this.$emit('addStep', cloneDeep(value));
			this.show_modal = false;
		},
	},
};
</script>

<style scoped lang="less">
.build-step-option {
	border: 1px solid var(--gray-20);
	border-radius: 3px;
	margin-bottom: 1rem;
	padding: 1rem;

	&:hover {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-lighter);
		cursor: pointer;
	}

	.name {
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.sub-value {
		line-height: 1.35em;
	}
}
</style>
