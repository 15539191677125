import { $GQL } from '@GQL';
import Fuse from 'fuse.js';

interface search_result {
	id: string;
	type: string;
	data: any;
}

export async function universalSearch(value: string, role_id: string, account_id?: string) {
	const query = `
        query universalSearch($value: String!, $role_id: String!, $account_id: String) {
            search(value: $value, role_id: $role_id, account_id : $account_id) {
                id
                type
                data
            }
        }
    `;

	const request = await $GQL.request(query, { value, role_id, account_id });

	// Sort the results if it is not of type 'lead', those are to be ordered by created_at, the rest should be ordered by relevance
	const lead_results = request.search.filter((item) => item.type === 'lead');
	const non_lead_results = request.search.filter((item) => item.type !== 'lead');
	const options = {
		threshold: 0.5,
		keys: [
			'id',
			'data.account_id',
			{
				name: 'data.account_name',
				weight: 5,
			},
			{
				name: 'data.account_email',
				weight: 4,
			},
			{
				name: 'data.user_name',
				weight: 3,
			},
			'data.lead_name',
			{
				name: 'data.contact_email',
				weight: 2,
			},
			'data.account_phone',
			'data.user_phone',
			'data.contact_phone',
		],
	};
	const fuse = new Fuse(non_lead_results, options);
	const sorted_non_lead_results = fuse.search(value).map((item) => item.item);
	const result = sorted_non_lead_results.concat(lead_results);
	return result;
}
