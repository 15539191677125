import { $GQL } from '@GQL/index';

export async function testBid(params: { custom_bid: unknown; leads: unknown[] }) {
	const query = `
        query TestBid($params: JSONObject!) {
            testBid(params: $params)
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.testBid;
}
