<template>
	<p-dialog id="raw-purchase-modal" v-model:visible="show_modal" modal style="max-width: 1000px; width: 100%">
		<template #header>
			<div class="flex flex-start align-items-center" style="gap: 10px">
				<icon type="receipt-text" size="24px" /><strong>Raw Purchase</strong>
			</div>
		</template>
		<div class="raw-purchase">
			<pre>{{ purchase }}</pre>
		</div>
	</p-dialog>
</template>

<script>
import pDialog from 'primevue/dialog';

export default {
	name: 'RawPurchaseModal',
	components: {
		pDialog,
	},
	props: {
		visible: Boolean,
		purchase: {
			type: Object,
		},
	},
	data() {
		return {
			show_modal: false,
		};
	},
};
</script>

<style lang="less" scoped>
.raw-purchase {
	background-color: var(--gray-10);
	border: 1px solid var(--gray-15);
	border-radius: 5px;
	font-family: var(--font-mono);
	line-height: 1.25em;
	padding: 1em;
}
</style>
