import { $GQL } from '@GQL/index';
import { type Notification } from '@nextgenleads/marketplace-driver';

export async function updateNotification(id: string, data: JSONObject): Promise<Notification> {
	const mut = `
        mutation Mutation($id: ID!, $data: JSONObject!) {
            updateNotification(id: $id, data: $data) {
                expires
                filters
                message
                modified_at
                status
                tags
                type
            }
        }
    `;

	const results = await $GQL.request(mut, { id, data });
	return results.notification;
}
