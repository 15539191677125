<template>
	<div id="settings" class="view-content">
		<div class="page-heading">
			<h1>Settings</h1>
		</div>
		<div class="account-settings page-content">
			<row>
				<column max-width="960px">
					<div class="card">
						<row>
							<column :span="1" class="padded">
								<p-menu :model="settingsNav">
									<template #item="item">
										<li
											:class="['p-menuitem', { selected: item.label === selected_component.label }]"
											role="menuitem"
											aria-label="General"
											data-pc-section="menuitem"
											data-p-focused="false"
											data-p-disabled="false"
										>
											<div class="p-menuitem-content" data-pc-section="content">
												<a
													class="p-menuitem-link"
													tabindex="-1"
													aria-hidden="true"
													data-pc-section="action"
													data-pd-ripple="true"
													><span class="p-menuitem-text" data-pc-section="label">{{ item.label }}</span></a
												>
											</div>
										</li>
									</template>
								</p-menu>
							</column>
							<div class="divider" />
							<column :span="3" class="padded">
								<component :is="selected_component.component" v-if="selected_component" />
								<template v-else>
									<p>Please select a category on the left to update settings</p>
								</template>
							</column>
						</row>
					</div>
				</column>
			</row>
		</div>
	</div>
</template>

<script>
import pMenu from 'primevue/menu';
import AccountSettings from '../Components/Account.vue';
import AuctionSettings from '../Components/AccountAuction.vue';
import BudgetSettings from '../Components/AccountBudgets.vue';
import ConversionsSettings from '../Components/Conversions.vue';
import MergeAccounts from '../Components/MergeAccounts.vue';
import SignUpSettings from '../Components/SignUp.vue';
import SupportSettings from '../Components/Support.vue';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';

export default {
	name: 'AccountSettings',
	components: {
		pMenu,
		AccountSettings,
		AuctionSettings,
		BudgetSettings,
		ConversionsSettings,
		SignUpSettings,
	},
	props: {},
	data() {
		return {
			selected_component: {},
			settings_nav: [
				{
					label: 'Account',
					id: 'account',
					component: AccountSettings,
				},
				{
					label: 'Auction',
					id: 'auction',
					component: AuctionSettings,
				},
				{
					label: 'Budgets',
					id: 'budgets',
					component: BudgetSettings,
				},
				{
					label: 'Conversions',
					id: 'conversions',
					component: ConversionsSettings,
				},
				{
					label: 'Merge Accounts',
					id: 'merge_accounts',
					component: MergeAccounts,
				},
				{
					label: 'Sign Up',
					id: 'sign_up',
					component: SignUpSettings,
				},
				{
					label: 'Support',
					id: 'support',
					component: SupportSettings,
				},
			],
		};
	},
	computed: {
		settingsNav() {
			const filtered_settings_nav = this.settings_nav.filter((nav_item) => {
				if (this.sessionStore.hasPermission([`Settings.${nav_item.id}`])) {
					return true;
				}
				return false;
			});
			return filtered_settings_nav.map((settings_nav_item) => {
				return {
					label: settings_nav_item.label,
					command: () => {
						this.selected_component = settings_nav_item;
						this.$router.push({
							query: {
								id: settings_nav_item.id,
							},
						});
					},
				};
			});
		},
		...mapStores(useSessionStore),
	},
	mounted() {
		const section = this.$route.query.id || 'account';
		const selected = this.settings_nav.find((n) => {
			return n.id === section;
		});

		if (selected) {
			this.selected_component = selected;
		}
	},
};
</script>

<style scoped lang="less">
:deep(.settings-form) {
	h2 {
		font-size: 1.5rem;
		margin-bottom: 0;
	}

	h3 {
		font-size: 1.25rem;
	}

	.actions {
		border-top: 1px solid var(--gray-10);
		display: flex;
		margin: 30px -20px -20px;
		padding: 20px;
	}
}

.p-menuitem.selected {
	background-color: var(--gray-05);
	font-weight: bold;
}

.divider {
	border-right: 1px solid var(--gray-10);
}
</style>
