import { $GQL } from '@GQL/index';

export async function reportSubIdConversions(
	params: {
		date_range: [string, string];
		filters?: QueryFilters;
		source_id: string;
		account_id?: string;
		mpid?: string;
	},
	refresh?: boolean
) {
	const query = `
        query ReportAccountSubIdConversions($params: JSONObject!) {
            reportAccountSubIdConversions(params: $params) {
				rows
				row_count
			}
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportAccountSubIdConversions;
}
