import { $GQL } from '@GQL/index';
import { type QueryParams } from '@GQL/queryParams';

interface Purchase {
	id: string;
	mpid: string;
	vendor_id: string;
	vertical_id: string;
	account_id: string;
	lead_id: string;
	lead_phone: string;
	lead_email: string;
	lead_state: string;
	product: string;
	source_type: string;
	source_id: string;
	sub_id: string;
	bid_type: string;
	price: string;
	status: string;
	created_at: string;
}

export async function getPurchasesList(params?: QueryParams): Promise<Purchase[]> {
	const query = `
        query GetPurchasesList($params: GetListParams) {
            purchaseList(params: $params) {
                rows {
                    id
                    mpid
                    vendor_id
                    vertical_id
                    sub_id
                    status
                    source_type
                    source_id
                    product
                    price
                    lead_zip
                    lead_state
                    lead_phone
                    lead_id
                    lead_email
                    created_at
                    campaign_id
                    bid_type
                }
                row_count
            }
        }
    `;
	const result = await $GQL.request(query, { params });
	return result.purchaseList;
}
