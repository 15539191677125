<template>
	<div v-if="show" class="line-loader">
		<div class="bar"></div>
	</div>
</template>

<script>
export default {
	name: 'LineLoader',
	props: {
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {};
	},
};
</script>

<style scoped lang="less">
@keyframes line-loader-animation {
	0% {
		left: -100%;
	}

	100% {
		left: 100%;
	}
}

.line-loader {
	height: 3px;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;

	.bar {
		animation-duration: 2s;
		animation-iteration-count: infinite;
		animation-name: line-loader-animation;
		animation-timing-function: ease-in-out;
		background-color: var(--color-a);
		height: 5px;
		position: relative;
		width: 100%;
	}
}
</style>
