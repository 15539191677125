<template>
	<div class="qualifier promotion">
		<div class="title">
			<div class="name">Promotions</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">
			Disqualify a campaign if the promotion is not active or it is using a promotion that is not included in the
			specified list (leave promotions blank to allow any promotion).
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Limit Promotion IDs:</label>
					<div class="controls gap-20">
						<div class="field">
							<p-chips add-on-blur v-model="modelValue.promotion_ids" separator="," />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import pChips from 'primevue/chips';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'PromotionQualifier',
	emits: ['remove'],
	components: {
		pChips,
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					promotion_ids: [],
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
