import { $GQL } from '@GQL';

export async function deleteSignUpPage(id: string) {
	const mutation = `
        mutation DeleteSignUpPage($id: ID!,) {
			deleteSignUpPage(id: $id) {
				id
				name
			}
        }
    `;

	const result = await $GQL.request(mutation, { id });
	return result.deleteSignUpPage;
}
