<template>
	<div class="voice-route-builder">
		<div class="sub-routes-wrapper">
			<div v-if="isEmpty(modelValue)" class="sub-route" style="color: var(--gray-50)">
				There are no sub-routes for this voice route. Please add one now.
			</div>
			<div v-for="(route_config, sub_route) in modelValue" class="sub-route" :key="sub_route">
				<div class="sub-route-header">
					<div class="sub-route-name">
						<icon type="subdirectory-arrow-right" size="20px" style="margin: -5px 5px 0 0" />{{ sub_route }}
					</div>
					<div v-if="sub_route !== 'default'" class="sub-route-icons">
						<div class="icon-wrapper tight">
							<icon type="pencil-box-outline" size="20px" @click="openRenameSubRouteModal(sub_route)" />
						</div>
						<div class="icon-wrapper delete tight">
							<icon type="delete" size="20px" @click="deleteSubRoute(sub_route)" />
						</div>
					</div>
				</div>
				<div class="sub-route-steps" v-sortable="sortableOptions(sub_route)">
					<div
						v-for="(step, index) in route_config"
						class="route-step"
						:key="`${sub_route}_step_${step.handler}_${index}`"
					>
						<component
							:is="step.handler"
							:route-step="step"
							@update="updateRouteStep($event, sub_route, index)"
							@delete="deleteStep(sub_route, index)"
						/>
					</div>
				</div>
				<div class="route-step add flex align-items-center" @click="openAddRouteStepModal(sub_route)">
					<icon type="plus" size="24px" />
					<div class="label">Add Sub-Route Step</div>
				</div>
			</div>
		</div>
		<div class="actions">
			<template v-if="!modelValue">
				<p-button icon="pi pi-plus" label="Add Default Sub-Route" @click="addDefaultSubRoute()" />
			</template>
			<template v-else>
				<p-button v-if="allowAddSubRoute" icon="pi pi-plus" label="Add Sub-Route" @click="openAddSubRouteModal()" />
			</template>
		</div>
		<p-dialog modal v-model:visible="show_add_step_modal" style="width: 90%; max-width: 600px">
			<template #header>
				<strong>Add Sub-Route Step</strong>
			</template>
			<p>
				Select one of the steps below to add it to the <strong>{{ selected_sub_route }}</strong> sub-route.
			</p>
			<div class="step-option" @click="addRouteStep('BuildLead')">
				<div class="step-option-title">Build Lead</div>
				<p>Create a new call lead</p>
			</div>
			<div class="step-option" @click="addRouteStep('ConnectCall')">
				<div class="step-option-title">Connect Call</div>
				<p>Connect the call to the auction winner</p>
			</div>
			<div class="step-option" @click="addRouteStep('Dial')">
				<div class="step-option-title">Dial</div>
				<p>Make an outbound call to a phone number</p>
			</div>
			<div class="step-option" @click="addRouteStep('DNC')">
				<div class="step-option-title">DNC</div>
				<p>DNC the caller's phone number in the DB</p>
			</div>
			<div class="step-option" @click="addRouteStep('Gather')">
				<div class="step-option-title">Gather</div>
				<p>Gather keypad input from the caller</p>
			</div>
			<div class="step-option" @click="addRouteStep('Hangup')">
				<div class="step-option-title">Hang Up</div>
				<p>Hang up the current call</p>
			</div>
			<div class="step-option" @click="addRouteStep('Menu')">
				<div class="step-option-title">Menu</div>
				<p>Redirect a caller via a menu</p>
			</div>
			<div class="step-option" @click="addRouteStep('Pause')">
				<div class="step-option-title">Pause</div>
				<p>Pause for a set amount of time before continuing</p>
			</div>
			<div class="step-option" @click="addRouteStep('Play')">
				<div class="step-option-title">Play</div>
				<p>Play an audio file</p>
			</div>
			<div class="step-option" @click="addRouteStep('Record')">
				<div class="step-option-title">Record</div>
				<p>Start recording the call</p>
			</div>
			<div class="step-option" @click="addRouteStep('Redirect')">
				<div class="step-option-title">Redirect</div>
				<p>Redirect to a new TwiML endpoint</p>
			</div>
			<div class="step-option" @click="addRouteStep('Reject')">
				<div class="step-option-title">Reject</div>
				<p>Reject and end the call immediately</p>
			</div>
			<div class="step-option" @click="addRouteStep('Ring')">
				<div class="step-option-title">Ring</div>
				<p>Play a ringing sound</p>
			</div>
			<div class="step-option" @click="addRouteStep('Say')">
				<div class="step-option-title">Say</div>
				<p>Use an AI voice to say a message</p>
			</div>
			<div class="step-option" @click="addRouteStep('Script')">
				<div class="step-option-title">Script</div>
				<p>Execute a custom script</p>
			</div>
			<div class="step-option" @click="addRouteStep('StartDuration')">
				<div class="step-option-title">Start Duration</div>
				<p>Start the timer for duration bids</p>
			</div>
			<div class="step-option" @click="addRouteStep('Twiml')">
				<div class="step-option-title">TwiML</div>
				<p>Execute custom TwiML</p>
			</div>
			<template #footer>
				<p-button text label="cancel" @click="show_add_step_modal = false" />
			</template>
		</p-dialog>
		<p-dialog modal v-model:visible="show_add_sub_route_modal" style="max-width: 400px">
			<template #header>
				<strong>Add Sub-Route</strong>
			</template>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Sub-Route Name:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="new_sub_route_name" />
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<p-button text label="Cancel" @click="show_add_sub_route_modal = false" />
				<p-button icon="pi pi-plus" label="Add Sub-Route" @click="addSubRoute()" />
			</template>
		</p-dialog>
		<p-dialog modal v-model:visible="show_rename_sub_route_modal" style="max-width: 400px">
			<template #header>
				<strong>Add Sub-Route</strong>
			</template>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Sub-Route Name:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="edit_sub_route_name" />
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<p-button text label="Cancel" @click="show_rename_sub_route_modal = false" />
				<p-button icon="pi pi-plus" label="Add Sub-Route" @click="renameSubRoute()" />
			</template>
		</p-dialog>
		<p-confirm-popup group="voice_route_builder" />
	</div>
</template>

<script>
import Sortable from 'sortablejs';
import { cloneDeep, isEmpty, merge, uniqueId } from 'lodash-es';
import pConfirmPopup from 'primevue/confirmpopup';
import pDialog from 'primevue/dialog';

import BuildLead from './Handlers/BuildLead.vue';
import ConnectCall from './Handlers/ConnectCall.vue';
import Dial from './Handlers/Dial.vue';
import DNC from './Handlers/DNC.vue';
import Gather from './Handlers/Gather.vue';
import Hangup from './Handlers/Hangup.vue';
import Menu from './Handlers/Menu.vue';
import Pause from './Handlers/Pause.vue';
import Play from './Handlers/Play.vue';
import Record from './Handlers/Record.vue';
import Redirect from './Handlers/Redirect.vue';
import Reject from './Handlers/Reject.vue';
import Ring from './Handlers/Ring.vue';
import Say from './Handlers/Say.vue';
import Script from './Handlers/Script.vue';
import StartDuration from './Handlers/StartDuration.vue';
import Twiml from './Handlers/Twiml.vue';

export default {
	name: 'VoiceRouteBuilder',
	emits: ['update:modelValue'],
	components: {
		pConfirmPopup,
		pDialog,
		BuildLead,
		ConnectCall,
		Dial,
		DNC,
		Gather,
		Hangup,
		Menu,
		Pause,
		Play,
		Record,
		Redirect,
		Reject,
		Ring,
		Say,
		Script,
		StartDuration,
		Twiml,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					default: [],
				};
			},
		},
		allowAddSubRoute: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			show_add_step_modal: false,
			selected_sub_route: null,
			sortable_options: {
				draggable: '.route-step',
				handle: '.move-handle',
			},
			new_sub_route_name: null,
			edit_sub_route_name: null,
			selected_sub_route_name: null,
			show_add_sub_route_modal: false,
			show_rename_sub_route_modal: false,
		};
	},
	methods: {
		isEmpty,
		openAddSubRouteModal() {
			this.new_sub_route_name = null;
			this.show_add_sub_route_modal = true;
		},
		addDefaultSubRoute() {
			this.$emit('update:modelValue', { default: [] });
		},
		addSubRoute() {
			const voice_route_config = cloneDeep(this.modelValue);
			voice_route_config[this.new_sub_route_name] = [];
			this.$emit('update:modelValue', voice_route_config);
			this.show_add_sub_route_modal = false;
		},
		deleteSubRoute(sub_route) {
			this.$confirm.require({
				target: event.currentTarget,
				group: 'voice_route_builder',
				message: `Delete the sub-route named "${sub_route}"?`,
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: 'Delete Sub-Route',
				rejectLabel: 'Cancel',
				accept: async () => {
					const voice_route_config = cloneDeep(this.modelValue);
					delete voice_route_config[sub_route];
					this.$emit('update:modelValue', voice_route_config);
				},
			});
		},
		openRenameSubRouteModal(sub_route) {
			this.selected_sub_route_name = sub_route;
			this.edit_sub_route_name = sub_route;
			this.show_rename_sub_route_modal = true;
		},
		renameSubRoute() {
			const voice_route_config = cloneDeep(this.modelValue);
			voice_route_config[this.edit_sub_route_name] = voice_route_config[this.selected_sub_route_name].slice();
			delete voice_route_config[this.selected_sub_route_name];
			this.$emit('update:modelValue', voice_route_config);
			this.show_rename_sub_route_modal = false;
		},
		openAddRouteStepModal(sub_route) {
			this.selected_sub_route = sub_route;
			this.show_add_step_modal = true;
		},
		addRouteStep(step_type) {
			const new_step = cloneDeep(this.$options.components[step_type].data().default_config);
			const new_config = cloneDeep(this.modelValue);
			new_config[this.selected_sub_route].push(new_step);
			this.$emit('update:modelValue', new_config);
			this.show_add_step_modal = false;
		},
		updateRouteStep(data, sub_route, index) {
			const new_config = cloneDeep(this.modelValue);
			new_config[sub_route].splice(index, 1, data);
			this.$emit('update:modelValue', new_config);
		},
		deleteStep(sub_route, index) {
			const new_config = cloneDeep(this.modelValue);
			new_config[sub_route].splice(index, 1);
			this.$emit('update:modelValue', new_config);
		},
		sortValues(old_index, new_index, sub_route) {
			console.log('SORT:', sub_route);
			const config = cloneDeep(this.modelValue);
			const route_step = config[sub_route].splice(old_index, 1)[0];
			config[sub_route].splice(new_index, 0, route_step);
			this.$emit('update:modelValue', config);
		},
		sortableOptions(sub_route) {
			return merge({}, this.sortable_options, {
				sub_route,
			});
		},
	},
	directives: {
		sortable: {
			beforeMount(el, binding, vnode) {
				let options = merge({}, binding.value, {
					onEnd: (event) => {
						binding.instance.sortValues(event.oldIndex, event.newIndex, binding.value.sub_route);
					},
				});

				vnode.sortable = Sortable.create(el, options);
			},
		},
	},
};
</script>

<style lang="less" scoped>
.sub-routes-wrapper {
	background-color: var(--gray-05);
	border: 1px solid var(--gray-20);
	border-radius: 3px;
}

.sub-route {
	border-top: 1px solid var(--gray-15);
	padding: 1em;

	.sub-route-header {
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 1em;
	}

	.sub-route-name {
		font-weight: bold;
		padding: 0 0.25em;
	}

	.sub-route-icons {
		display: flex;
		gap: 10px;

		.delete:hover {
			color: red;
		}
	}

	&:first-child {
		border-top: 0;
	}
}

.voice-route-builder {
	position: relative;
}

.sub-route-steps {
	margin-bottom: 1em;
}

.route-step {
	background-color: var(--white);
	border: 1px solid var(--gray-20);
	border-radius: 3px;
	box-shadow: 0 3px 0 var(--gray-15);
	margin-bottom: 1em;

	&::after {
		content: '';
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 11px solid var(--gray-15);
		left: 50%;
		margin-top: 4px;
		position: absolute;
		transform: translateX(-50%);
		width: 20px;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:nth-last-child(1)::after {
		display: none;
	}

	:deep(.header) {
		align-items: center;
		cursor: default;
		display: flex;
		justify-content: space-between;

		.header-left,
		.header-right {
			align-items: center;
			display: flex;
			flex: 0 0 auto;
			gap: 10px;
			height: 50px;
			padding: 0 1em;
		}

		.header-left {
			flex: 1 0;
			justify-content: space-between;
			padding-right: 0;
		}

		.delete-icon:hover {
			color: red;
		}
	}

	:deep(.content) {
		border-top: 1px solid var(--gray-10);
		padding: 1em;

		.divider {
			margin: 1em -1em;
			border-top: 1px solid var(--gray-10);
		}

		.step-options {
			margin-bottom: 1em;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.remove-button {
			color: red;
		}
	}

	&.add {
		border-style: dashed;
		box-shadow: none;
		color: var(--gray-35);
		cursor: pointer;
		gap: 10px;
		padding: 1em;

		&:hover {
			border-color: var(--color-b-lighter);
			color: var(--color-b);
		}

		&::after {
			display: none;
		}
	}
}

.step-option {
	align-items: center;
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	box-shadow: 0 3px 0 var(--gray-15);
	cursor: default;
	display: flex;
	gap: 10px;
	margin-bottom: 1em;
	padding: 1em;

	.step-option-title {
		font-weight: bold;
		flex: 0 0 120px;
	}

	p {
		margin-bottom: 0;
	}

	&:hover {
		background-color: var(--color-b-lightest);
		border: 1px solid var(--color-b-lighter);

		.step-option-title {
			color: var(--color-b);
		}

		p {
			color: var(--color-b-light);
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.actions {
	margin-top: 1em;
}
</style>
