import { $GQL } from '@GQL/index';
import type { Promotion } from '@nextgenleads/marketplace-driver';

export async function insertPromotion(data: Omit<Promotion, 'id' | 'created_at' | 'modified_at'>) {
	const mutation = `
        mutation InsertPromotion($data: JSONObject!) {
            insertPromotion(data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertPromotion;
}
