import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function getCCPARequestList(params: GetListParams, refresh?: boolean) {
	const query = `
    query GetCCPARequestList($params: GetListParams!){
        ccpaRequestList(params: $params) {
			row_count
            rows {
                id
                request
                lead_ids
                email_log_ids
                flags
                matches
                type
                status
                created_at
            }
        }
    }       
    `;

	const no_cache = refresh ? ['ccpaRequestList'] : undefined;
	const result = await $GQL.request(query, {
		params,
		no_cache,
	});

	return result.ccpaRequestList;
}
