import { $GQL } from '@GQL/index';

export async function verifyPaymentMethod(id: string, data: { account_id: string; documents_checked: string[] }) {
	const mutation = `
        mutation VerifyPaymentMethod($id: ID!, $data: JSONObject!) {
            verifyPaymentMethod(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.verifyPaymentMethod;
}
