import { $GQL } from '@GQL/index';

export async function reportCustomerVelocityByCampaignId(
	account_id: string,
	params: { date_range: [string, string]; compare: any },
	refresh: boolean
) {
	const query = `
        query ReportCustomerVelocityByCampaignId($account_id: String!, $params: JSONObject!) {
            reportCustomerVelocityByCampaignId(account_id: $account_id, params: $params)
        }
    `;

	const no_cache = refresh ? ['reportCustomerVelocityByCampaignId'] : undefined;
	const results = await $GQL.request(query, { account_id, params, no_cache });
	return results.reportCustomerVelocityByCampaignId;
}
