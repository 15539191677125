import LiveTransferStartPage from '@/views/LTWorkstation/Start/Page.vue';
import LiveTransferCreatePage from '@/views/LTWorkstation/Create/Page.vue';
import LiveTransferFormPage from '@/views/LTWorkstation/Form/Page.vue';
import LiveTransferHistoryPage from '@/views/LTWorkstation/History/Page.vue';
import LiveTransferLeaderboardPage from '@/views/LTWorkstation/Leaderboard/Page.vue';
import LiveTransferResultsPage from '@/views/LTWorkstation/Results/Page.vue';
import LiveTransferReturnsPage from '@/views/LTWorkstation/Returns/List/Page.vue';
import LiveTransferEventHistoryPage from '@/views/LTWorkstation/EventHistory/List/Page.vue';

export default [
	{
		path: 'lt-workstation',
		name: 'LiveTransferStart',
		component: LiveTransferStartPage,
		meta: {
			parent_menu: 'LT Workstation',
			title: 'LT Workstation',
			permissions: 'admin.lt_workstation',
		},
	},
	{
		path: 'lt-workstation/create',
		name: 'LiveTransferCreate',
		component: LiveTransferCreatePage,
		meta: {
			parent_menu: 'LT Workstation',
			title: 'LT Workstation - New Lead',
			permissions: 'admin.lt_workstation',
		},
	},
	{
		path: 'lt-workstation/lead/:vertical_id/:lead_id',
		name: 'LiveTransferForm',
		component: LiveTransferFormPage,
		meta: {
			parent_menu: 'LT Workstation',
			title: 'LT Workstation - Lead Form',
			permissions: 'admin.lt_workstation',
		},
	},
	{
		path: 'lt-workstation/results/:vertical_id/:lead_id',
		name: 'LiveTransferResults',
		component: LiveTransferResultsPage,
		meta: {
			parent_menu: 'LT Workstation',
			title: 'LT Workstation - Results',
			permissions: 'admin.lt_workstation',
		},
	},
	{
		path: 'lt-workstation/history',
		name: 'LiveTransferHistory',
		component: LiveTransferHistoryPage,
		meta: {
			parent_menu: 'LT Workstation',
			title: 'LT Workstation - History',
			permissions: 'admin.lt_workstation',
		},
	},
	{
		path: 'lt-workstation/leaderboard',
		name: 'LiveTransferLeaderboard',
		component: LiveTransferLeaderboardPage,
		meta: {
			parent_menu: 'LT Workstation',
			title: 'LT Workstation - Leaderboard',
			permissions: 'admin.lt_workstation',
		},
	},
	{
		path: 'lt-workstation/returns',
		name: 'LiveTransferReturns',
		component: LiveTransferReturnsPage,
		meta: {
			parent_menu: 'LT Workstation',
			title: 'LT Workstation - Returns',
			permissions: 'admin.lt_returns',
		},
	},
	{
		path: 'lt-workstation/event-history',
		name: 'LiveTransferEventHistory',
		component: LiveTransferEventHistoryPage,
		meta: {
			parent_menu: 'LT Workstation',
			title: 'LT Workstation - Event History',
			permissions: 'admin.lt_event_history',
		},
	},
];
