<template>
	<div class="source-list">
		<query-form v-model:query="query" :loading="loading" @apply="getList" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Network ID:</div>
					<div class="value">
						{{ displaySelected(query.filters.network_id, network_options, 3) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Source Type:</div>
					<div class="value">
						{{ displaySelected(query.filters.source_type, source_type_options, 3) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Source Manager</div>
					<div class="value">{{ displaySelected(query.filters.source_manager, user_options, 3) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Biz Unit</div>
					<div class="value">
						{{ displaySelected(query.filters.biz_unit, biz_unit_options, 3) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Search:</div>
					<div class="value">{{ source_search || 'Any' }}</div>
				</div>
				<div class="display-value">
					<div class="label">Status:</div>
					<div class="value">
						{{ displaySelected(query.filters.status, source_status_options, 3) }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" />
			</template>
			<template #form="form">
				<row>
					<column max-width="350px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Filter By Network:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.network_id"
											:options="network_options"
											option-label="label"
											option-value="value"
											placeholder="Select Networks"
											class="source-type-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Source Type:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.source_type"
											:options="source_type_options"
											option-label="label"
											option-value="value"
											placeholder="Select Source Type"
											class="source-type-select w-full md:w-20rem"
										>
										</p-multi-select>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="20px" />
					<column max-width="350px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Source Manager:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.source_manager"
											:options="user_options"
											option-label="label"
											option-value="value"
											placeholder="Select Source Manager"
											class="source-type-select w-full md:w-20rem"
										>
										</p-multi-select>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Biz Unit:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.biz_unit"
											filter
											filter-placeholder="Filter Biz Unit"
											:options="biz_unit_options"
											option-label="label"
											option-value="value"
											placeholder="Select Biz Unit"
											class="state-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="20px" />
					<column max-width="350px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Status:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.status"
											filter
											filter-placeholder="Filter Status"
											:options="source_status_options"
											option-label="label"
											option-value="value"
											placeholder="Select Status"
											class="state-select w-full"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Search:</label>
								<div class="controls">
									<div class="field">
										<p-input-text
											v-model="source_search"
											class="search-query"
											type="text"
											placeholder="Search source codes"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<column />
				</row>
			</template>
		</query-form>

		<div class="flex justify-content-between">
			<p-button icon="pi pi-plus" label="Add Source" @click.prevent="$router.push(`${$route.path}/new`)" />
			<show-archived v-model="show_archived" />
		</div>
		<gutter size="20px" />

		<div class="card padded">
			<p-data-table
				ref="dt"
				paginator
				:loading="loading"
				:rows="100"
				scrollable
				:value="show_archived ? sources : activeSources"
				v-model:filters="table_filters"
				:global-filter-fields="['source_id', 'network_id', 'source_hash']"
			>
				<template #header>
					<div class="flex justify-content-between">
						<p-input-text
							v-model="table_filters['global'].value"
							style="width: 100%; max-width: 400px"
							placeholder="Search Sources..."
						/>
					</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column field="product" header="Source ID">
					<template #body="source">
						<div class="name">
							<router-link :to="{ path: `${$route.path}/${source.data.source_id}` }">{{
								source.data.source_id
							}}</router-link>
						</div>
						<div class="sub-value">{{ getNetwork(source.data.network_id) }}</div>
					</template>
				</p-column>
				<p-column class="align-center" field="product" header="Hash">
					<template #body="source">
						{{ source.data.source_hash }}
					</template>
				</p-column>
				<p-column field="source_type" header="Type">
					<template #body="source">
						{{ source.data.source_type }}
					</template>
				</p-column>
				<p-column class="align-center" field="biz_unit" header="Biz Unit">
					<template #body="source">
						{{ source.data.biz_unit }}
					</template>
				</p-column>
				<p-column field="status" header="Status">
					<template #body="source">
						{{ capitalize(source.data.status) }}
					</template>
				</p-column>
				<p-column header="Tools" class="align-center">
					<template #body="row">
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Edit'"
									aria-label="Edit"
									@click="$router.push({ path: `${$route.path}/${row.data.source_id}` })"
								>
									<template #icon>
										<icon type="square-edit-outline" size="20px" />
									</template>
								</p-button>
							</div>
							<div class="tool">
								<p-button
									v-tooltip.top="'Duplicate'"
									aria-label="Edit"
									@click="duplicateSource($event, row.data.source_id)"
								>
									<template #icon>
										<icon type="content-copy" size="20px" />
									</template>
								</p-button>
							</div>
							<delete-action
								v-if="row.data.status !== 'archived'"
								message="Are you sure you want to delete this source? Note: Any source with lead data will be archived instead."
								@delete="deleteSource(row.data.source_id)"
							/>
							<div v-if="row.data.status === 'archived'" class="tool">
								<p-button v-tooltip.top="'Activate'" aria-label="Activate" @click="activateSource(row.data.source_id)">
									<template #icon>
										<icon type="play-box-outline" size="20px" />
									</template>
								</p-button>
							</div>
						</div>
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script lang="ts">
/**
 * TODO:UI
 * all option bases filters should be multi select drop downs in the query selector
 *  -- network_id can be done later
 */
import { biz_unit_options, source_status_options, source_type_options } from '@/lib/Options';
import { deleteSource, getInternalUsersAsOptions, getNetworksAsOptions, getSourceList, updateSource } from '@GQL';
import { capitalize } from 'lodash-es';
import { displaySelected } from '@/lib/Filters';
import pPaginator from 'primevue/paginator';
import queryForm from '@/components/widgets/QueryForm.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import { type Source } from '@nextgenleads/source-driver';
import { FilterMatchMode, FilterOperator } from 'primevue/api';

export default {
	name: 'SourceListPage',
	components: {
		pPaginator,
		queryForm,
		deleteAction,
		showArchived,
	},
	data() {
		return {
			biz_unit_options,
			source_status_options,
			source_type_options,
			user_options: [],
			network_options: [],
			source_search: '',
			query: {
				filters: {
					// THESE KEYS MUST MATCH THE SCHEMA KEYS
					source_type: [],
					network_id: [],
					source_manager: [],
					biz_unit: [],
					status: [],
				},
				order: {
					field: 'created_at',
					desc: true,
				},
			},
			sources: [],
			total_records: 0,
			loading: false,
			show_archived: false,
			table_filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
		};
	},
	async mounted() {
		const users = await getInternalUsersAsOptions();
		const networks = await getNetworksAsOptions();

		this.user_options = users;
		this.network_options = networks;

		await this.getList();
	},
	methods: {
		capitalize,
		displaySelected,
		async activateSource(source_id) {
			try {
				const result = await updateSource(source_id, {
					status: 'active',
				});
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Source Activated',
						detail: 'Successfully activated source.',
						life: 3000,
					});
					// Update local UI
					this.sources.find((source) => source.source_id === source_id).status = 'active';
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to activate job config.',
					life: 6000,
				});
			}
		},
		getNetwork(network_id) {
			const network = this.network_options.find((network) => {
				return network.value === network_id;
			});
			return network ? network.label : 'Unknown';
		},
		clearQuery() {
			this.loading = false;
		},
		async getList() {
			this.loading = true;
			// convert the query.filters into QueryFilters
			const filters = [];
			Object.keys(this.query.filters).forEach((key) => {
				if (this.query.filters[key].length > 0) {
					filters.push({
						op: 'in',
						value: this.query.filters[key],
						field: key,
					});
				}
			});

			if (this.source_search) {
				filters.push(`source_id like '%${this.source_search}%'`);
			}

			try {
				const { rows, row_count } = await getSourceList({ ...this.query, filters });

				this.sources = rows;
				this.total_records = row_count;
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to retrieve the source list',
				});
			} finally {
				this.loading = false;
			}
		},
		duplicateSource(event, source_id) {
			this.$router.push({
				path: this.$route.path + '/new',
				query: {
					source_id,
				},
			});
		},
		async deleteSource(source_id) {
			try {
				const result = await deleteSource(source_id);
				if (result) {
					this.$root.toast.add({
						severity: 'success',
						summary: 'Source Deleted/Archived',
						detail: source_id,
						life: 3000,
					});
					// Update local UI
					if (result.status === 'archived') {
						this.sources.find((source) => source.source_id === source_id).status = 'archived';
					} else {
						this.sources = this.sources.filter((source) => source.source_id !== 'source_id');
					}
				}
			} catch (err) {
				this.$root.toast.add({
					severity: 'error',
					summary: 'Failed to Delete or Archive Source',
					detail: err.response.data.message,
					life: 3000,
				});
			}
		},
	},
	computed: {
		activeSources() {
			return this.sources.filter((source: Source) => source.status !== 'archived');
		},
	},
};
</script>
