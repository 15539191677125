import { $GQL } from '@GQL/index';

export async function dismissNotificationReceipt(id: string) {
	const mutation = `
        mutation DismissNotificationReceipt($id: ID!) {
            dismissNotificationReceipt(id: $id) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id });
	return result.dismissNotificationReceipt;
}
