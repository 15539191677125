import { $GQL } from '@GQL';
import type { BalanceAdjustmentRes, ExplicitBalanceAdjustmentParams } from '@nextgenleads/banker-driver';

export async function creditFunds(params: ExplicitBalanceAdjustmentParams): Promise<BalanceAdjustmentRes> {
	const mutation = `
        mutation DepositFunds($params: ExplicitBalanceAdjustmentParams!){
          creditFunds(params: $params) {
            txn_id
            current_balance
          }
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.creditFunds;
}
