<template>
	<p-dialog v-model:visible="show_modal" class="" :modal="true" :style="{ width: '90vw' }">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<strong>ALL Pending returns for {{ row.agent_name }}</strong>
			</div>
		</template>
		<div>
			<div class="">
				<p-data-table
					ref="dt"
					:loading="loading"
					:rows="100"
					scrollable
					:value="pending_returns"
					key="refreshKey"
					data-key="id"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column header="Transfer Date">
						<template #body="row">
							{{ formatDate(new Date(row.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
						</template>
					</p-column>
					<p-column header="Vertical" class="column-align-center">
						<template #body="row">
							<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
						</template>
					</p-column>
					<p-column header="Lead">
						<template #body="row">
							{{ row.data.lead_name }}
							<div class="sub-value">{{ row.data.lead_id }}</div>
						</template>
					</p-column>
					<p-column header="Buyer">
						<template #body="row">
							{{ row.data.account_name }}
							<div class="sub-value">{{ row.data.account_id }}</div>
						</template>
					</p-column>
					<p-column header="Price">
						<template #body="row">
							{{ currency(row.data.price) }}
						</template>
					</p-column>
					<p-column header="Recording" class="column-align-center">
						<template #body="row">
							<div @click="selectAudio(row.data)" class="clickable">
								<icon type="open-in-new" size="24px" />
							</div>
						</template>
					</p-column>
					<p-column header="Reason">
						<template #body="row">
							{{ row.data.return_reason }}
						</template>
					</p-column>
					<p-column header="Note" class="column-align-center">
						<template #body="row">
							<div v-tooltip.top="row.data.return_notes">
								<icon type="tooltip" size="24px" />
							</div>
						</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="row">
							<div class="toolset">
								<div class="tool">
									<p-button v-tooltip.top="'Accept Return'" aria-label="Accept" @click="acceptReturn(row.data.id)">
										<template #icon>
											<icon type="check" size="20px" />
										</template>
									</p-button>
								</div>
								<div class="tool">
									<p-button v-tooltip.top="'Reject Return'" aria-label="Reject" @click="rejectReturn(row.data.id)">
										<template #icon>
											<icon type="close" size="20px" />
										</template>
									</p-button>
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>

			<p-dialog v-model:visible="show_audio_modal" class="" :modal="true">
				<template #header>
					<div class="flex gap-2 align-content-center align-items-center">
						<strong>Recordings for {{ selected_audio.lead_name }}</strong>
					</div>
				</template>
				<row class="column-align-center">
					<column v-for="recording_url in selected_audio.urls">
						<audio controls>
							<source :src="recording_url.url" type="audio/mpeg" />
						</audio>

						<gutter size="10px" />
						<div>From: {{ formatDate(new Date(recording_url.created_at), 'YYYY-MM-DD | h:mm:ssa') }}</div>
					</column>
					<div v-if="selected_audio.urls.length === 0">No Recordings found</div>
				</row>
			</p-dialog>
		</div>

		<p-confirm-dialog />
	</p-dialog>
</template>

<script>
import { getLTRecordings, updateLTReturn } from '@GQL';
import { asyncForEach } from 'modern-async';
import { currency, formatDate } from '@/lib/Filters';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import pConfirmDialog from 'primevue/confirmdialog';

export default {
	components: {
		verticalIcon,
		pConfirmDialog,
	},
	data() {
		return {
			row: {},
			show_audio_modal: false,
			selected_audio: {
				url: '',
				agent_name: '',
				lead_name: '',
			},
			loading: false,
			show_modal: false,
			pending_returns: [],
		};
	},
	methods: {
		formatDate,
		currency,
		selectAudio(row) {
			this.selected_audio.urls = row.recording_urls;
			this.selected_audio.agent_name = this.row.agent_name;
			this.selected_audio.lead_name = row.lead_name;
			this.show_audio_modal = true;
		},
		async acceptReturn(id) {
			this.$confirm.require({
				header: 'Accept Return',
				message: `After listening to the recording, do you still want to "Accept" the Return?`,
				accept: async () => {
					// set the verified_by field and update account
					this.loading = true;
					try {
						const update_res = await updateLTReturn(id, 'return_accepted');
						this.$toast.add({
							severity: 'success',
							summary: 'The Return was accepted',
							life: 6000,
						});
						this.pending_returns = this.pending_returns.filter((row) => {
							return row.id !== id;
						});
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to accept the return, refresh and try again',
							life: 6000,
						});
					} finally {
						this.loading = false;
					}
				},
			});
		},
		async rejectReturn(id) {
			this.$confirm.require({
				header: 'Reject Return',
				message: `After listening to the recording, do you still want to "Reject" the Return?`,
				accept: async () => {
					this.loading = true;
					try {
						const update_res = await updateLTReturn(id, 'return_rejected');
						this.$toast.add({
							severity: 'success',
							summary: 'The Return was "rejected" successfully',
							life: 6000,
						});
						this.pending_returns = this.pending_returns.filter((row) => {
							return row.id !== id;
						});
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to reject the return, refresh and try again',
							life: 6000,
						});
					} finally {
						this.loading = false;
					}
				},
			});
		},
		async open(row) {
			this.loading = true;
			this.row = { ...row };
			this.show_modal = true;
			// row has agent information and also a returns array, must get all the recordings for each
			// for each return get the recording
			try {
				this.pending_returns = [];

				await asyncForEach(row.returns, async (pending_return) => {
					const res = await getLTRecordings(pending_return.phone);
					this.pending_returns.push({ ...pending_return, recording_urls: res });
				});
			} finally {
				this.loading = false;
				this.show_modal = true;
			}
		},
	},
};
</script>
