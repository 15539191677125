<template>
	<img v-once v-tooltip="startCase(type)" :src="iconImg(type)" :class="['type-icon', type]" :style="style" />
</template>

<script lang="ts">
import { startCase } from 'lodash-es';

export default {
	props: {
		type: {
			type: String,
		},
		size: {
			type: String,
			default: '40px',
		},
	},
	computed: {
		style() {
			return {
				height: this.size,
				width: this.size,
			};
		},
	},
	methods: {
		startCase,
		iconImg(type) {
			switch (type) {
				case 'live_transfer':
					return '/assets/img/icons/icon-lead-live-transfer.svg';
				case 'call':
					return '/assets/img/icons/icon-lead-call.svg';
				case 'data':
					return '/assets/img/icons/icon-lead-data.svg';
				case 'exclusive':
					return '/assets/img/icons/icon-lead-data-exclusive.svg';
				default:
					return '/assets/img/icons/icon-lead-data.svg';
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
