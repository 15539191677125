import { $GQL } from '@GQL/index';

export async function shipPurchases(purchase_ids: string[]) {
	const mutation = `
        mutation ShipPurchases($purchase_ids: [String]!) {
            shipPurchases(purchase_ids: $purchase_ids)
        }
  `;

	const result = await $GQL.request(mutation, { purchase_ids });
	return result.shipPurchases;
}
