import { $GQL } from '@GQL/index';
import type { User } from '@nextgenleads/auth-driver';

export type UI_User = Omit<User, 'created_at' | 'id'>;

export async function insertUser(data: UI_User) {
	const mut = `
        mutation InsertUser($data: JSONObject!) {
            insertUser(data: $data) {
              id
            }
        }
  `;

	const result = await $GQL.request(mut, { data });
	return result.insertUser;
}
