<template>
	<div class="content-lead-delivery">
		<loader :show="loading" />
		<template
			v-if="
				campaignStore.campaign.product_targeting === 'call' ||
				campaignStore.campaign.product_targeting === 'live_transfer'
			"
		>
			<p-fieldset legend="Forwarding Phone Settings">
				<p>Configure where you want to forward active calls to when you win an auction.</p>
				<div class="flex align-items-center">
					<div v-if="sessionStore.isAdminUser" style="margin-right: 40px">
						<div class="control-group" style="margin-bottom: 0">
							<div class="inner">
								<label class="control-label">Resolution Type (Admin Only):</label>
								<div class="controls">
									<div class="field s">
										<p-dropdown
											v-model="campaignStore.campaign.forwarding_phone.type"
											:options="forwarding_phone_type_options"
											option-label="label"
											option-value="value"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="campaignStore.campaign.forwarding_phone.type === 'static'">
						<div class="control-group">
							<div class="inner">
								<label class="control-label req">Phone Number:</label>
								<div class="controls">
									<div class="field s">
										<p-input-mask
											v-model="campaignStore.campaign.forwarding_phone.number"
											mask="(999) 999-9999"
											unmask
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<template v-if="campaignStore.campaign.forwarding_phone.type === 'script'">
					<p-fieldset v-if="sessionStore.isAdminUser" legend="Dynamic Forwarding Phone Settings">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Script Name:</label>
								<div class="controls">
									<div class="field m">
										<p-input-text v-model="campaignStore.campaign.forwarding_phone.script" />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Script Settings:</label>
								<div class="controls">
									<div class="field">
										<code-editor v-model="campaignStore.campaign.forwarding_phone.script_settings" />
									</div>
								</div>
							</div>
						</div>
					</p-fieldset>
					<p-message v-else severity="info" :closable="false"
						>This campaign is using dynamic forwarding phone resolution. Please contact your account manager to update
						it.</p-message
					>
				</template>
				<template v-if="sessionStore.isAdminUser">
					<gutter size="1rem" />
					<div class="control-group admin-only">
						<div class="inner">
							<label class="control-label admin-only">Custom Confirmation Route:</label>
							<div class="controls">
								<div class="field l">
									<p-input-text
										v-model="campaignStore.campaign.forwarding_phone.confirmation_route"
										placeholder="Leave blank to use the settings on the phone number"
									/>
								</div>
							</div>
						</div>
					</div>
				</template>
				<p-fieldset legend="Concurrency Settings">
					<p>
						You can set call concurrency settings here to limit the number of active calls we send you. When your
						concurrent call count is at the limit you set, you will not bid on new leads until the concurrent call count
						decreases.
					</p>
					<div class="flex align-items-center">
						<div>
							<div class="control-group">
								<div class="inner">
									<div class="controls gap-20">
										<div class="field fit">
											<div class="flex align-items-center">
												<p-input-switch
													input-id="concurrent-calls"
													v-model="campaignStore.campaign.forwarding_phone.concurrent_call_limit.enabled"
												/>
												<label for="concurrent-calls" class="control-label">Enable Concurrent Call Limit</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<gutter size="40px" />
						<div>
							<div v-if="campaignStore.campaign.forwarding_phone.concurrent_call_limit.enabled" class="control-group">
								<div class="inner">
									<div class="controls gap-10">
										<label class="control-label">Call Limit:</label>
										<div class="field xxs">
											<p-input-number
												v-model.number="campaignStore.campaign.forwarding_phone.concurrent_call_limit.limit"
												class="input-align-right"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>
			</p-fieldset>
		</template>

		<p-fieldset legend="Email Delivery Settings">
			<p>
				Have your leads sent to one or more email addresses; emails are sent immediately upon purchase. Make sure to
				whitelist <strong>leads@nextgenleads.com</strong> to ensure proper delivery.
			</p>
			<p-fieldset legend="Recipients">
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-10">
							<div class="field">
								<p-chips
									v-model="campaignStore.campaign.delivery_emails"
									separator=","
									placeholder="Add email addresses to send leads to"
									add-on-blur
									@add="handleAddEmail"
								/>
								<div v-if="v$.deliveryEmails.$errors.length > 0" class="validation-error">
									{{ v$.deliveryEmails.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
			<gutter size="10px" />
			<div class="flex justify-content-end">
				<p-button
					v-tooltip.top="campaignStore.campaign.delivery_emails.length === 0 ? 'Enter recipients' : ''"
					:disabled="campaignStore.campaign.delivery_emails.length === 0"
					icon="pi pi-send"
					label="Send Test Email"
					class="pointer-events-auto"
					:class="{ 'cursor-pointer': campaignStore.campaign.delivery_emails.length > 0 }"
					@click="sendEmailTest"
				/>
			</div>
		</p-fieldset>
		<p-fieldset legend="CRM Integrations">
			<p>
				Send your leads in real-time to a CRM or dialer. Your Account Manager can assist in setting up these
				integrations.
			</p>
			<div class="integrations">
				<p-message v-if="sortedIntegrations.length === 0" :closable="false"
					>You do not have any available CRM integrations on your account. Please contact your account manager for
					assistance setting one up.</p-message
				>
				<div
					v-for="(integration, index) in sortedIntegrations"
					:key="index"
					:class="['integration', { disabled: !integrationStatus[integration.id] }]"
				>
					<div class="status">
						<p-input-switch v-model="integrationStatus[integration.id]" @change="toggleShipper(integration.id)" />
					</div>
					<div class="title">
						<div class="name" :class="{ active: integration.shipper_id }">
							{{ getIntegrationName(integration.shipper_id) }}
						</div>
						<div class="sub-value">
							{{ getIntegrationType(integration.shipper_id) }}
						</div>
					</div>
					<div>
						<div class="toolset">
							<div v-if="sessionStore.isAdminUser && integrationStatus[integration.id]" class="tool">
								<p-button v-tooltip.top="'Edit Custom Settings'" @click="editShipperOptions(integration.shipper_id)">
									<template #icon>
										<icon type="cog" size="24px" />
									</template>
								</p-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<p-dialog v-model:visible="show_settings_modal" modal>
			<loader :show="loading" />
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="truck-outline" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold">'{{ local_shipper_name }}' Params</span>
				</div>
			</template>
			<div>
				<label>Custom Options</label>
				<code-editor v-model.parse="local_custom_settings" />
			</div>
			<template #footer>
				<div class="flex justify-content-end">
					<p-button label="Save Options" @click="saveShipperOptions" />
				</div>
			</template>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import { findIndex, sortBy, get, cloneDeep } from 'lodash-es';
import pMessage from 'primevue/message';
import { useSessionStore } from '@/stores/session';
import { useCampaignStore } from '@/stores/campaign';
import { mapStores } from 'pinia';
import { getRandomLead, getShippers, sendEmail, getSettings } from '@GQL';
import log from '@/lib/Log';
import pChips from 'primevue/chips';
import pDialog from 'primevue/dialog';
import { useVuelidate } from '@vuelidate/core';
import { helpers, email } from '@vuelidate/validators';

export default {
	name: 'LeadDeliveryContent',
	components: {
		pMessage,
		pChips,
		pDialog,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			available_shippers: [],
			local_shipper_id: '',
			local_custom_settings: {},
			local_shipper_name: '',
			show_settings_modal: false,
			forwarding_phone_type_options: [
				{ label: 'Static', value: 'static' },
				{ label: 'Script', value: 'script' },
			],
			em_templates: {},
		};
	},
	computed: {
		...mapStores(useCampaignStore, useSessionStore),
		accountId() {
			let account_id = this.sessionStore.user.account_id;
			if ('account_id' in this.$route.params) {
				account_id = this.$route.params.account_id;
			}
			return account_id;
		},
		deliveryEmails() {
			return this.campaignStore.campaign.delivery_emails;
		},
		sortedIntegrations() {
			return sortBy(this.available_shippers, (shipper) => shipper.name);
		},
		integrationStatus() {
			const status_tree = {};
			this.campaignStore.campaign.shippers.forEach((shipper) => {
				status_tree[shipper.shipper_id] = true;
			});
			return status_tree;
		},
	},
	validations: {
		deliveryEmails: {
			valid_email: helpers.withMessage('Please enter only valid email addresses', (value) => {
				return value.every((v) => {
					return email.$validator(v);
				});
			}),
		},
	},
	async mounted() {
		await this.loadShippers();
		await this.getShipperEmailTemplates();
	},
	methods: {
		async getShipperEmailTemplates() {
			const settings_result = await getSettings(['shipper_email_templates'], '$NG', true);
			this.shipper_email_templates = cloneDeep(settings_result.settings.shipper_email_templates);
		},
		async handleAddEmail(e) {
			this.campaignStore.campaign.delivery_emails = this.campaignStore.campaign.delivery_emails.map((email) =>
				email.trim()
			);
			await this.v$.deliveryEmails.$validate();
		},
		async loadShippers() {
			const filters = [
				[
					{
						field: 'status',
						value: 'active',
						op: 'eq',
					},
					{
						field: 'account_id',
						value: this.accountId,
						op: 'eq',
					},
				],
			];
			const shippers = await getShippers(filters);
			log.trace('Loading shippers', [...shippers]);
			this.available_shippers = shippers.map((row) => {
				return { ...row, shipper_id: row.id }; // simpler, just use shipper_id everywhere
			});
		},
		toggleShipper(shipper_id) {
			const shipper_index = this.campaignStore.campaign.shippers.findIndex((shipper) => {
				return shipper.shipper_id === shipper_id;
			});

			// Remove the shipper
			if (shipper_index > -1) {
				this.campaignStore.campaign.shippers.splice(shipper_index, 1);
			}

			// Add the shipper
			else {
				this.campaignStore.campaign.shippers.push({
					shipper_id,
					custom_settings: {},
				});
			}
		},
		editShipperOptions(shipper_id: string) {
			// USED TO GET NAME ETC
			const integration = this.available_shippers.filter((item) => {
				return item.shipper_id === shipper_id;
			});

			const attached_shipper = this.available_shippers.filter((item) => {
				return item.shipper_id === shipper_id;
			});

			if (integration.length > 0 && attached_shipper.length > 0) {
				this.local_shipper_id = shipper_id;
				this.local_shipper_name = integration[0].name;
				// see if there's any
				this.local_custom_settings = attached_shipper[0].custom_settings;
				this.show_settings_modal = true;
			}
		},
		getIntegrationName(shipper_id: string) {
			const found = this.available_shippers.find((row) => {
				return row.shipper_id === shipper_id;
			});
			if (found) {
				return found.name || 'DNE';
			} else {
				return 'DNE';
			}
		},
		getIntegrationType(shipper_id: string) {
			const found = this.available_shippers.find((row) => {
				return row.shipper_id === shipper_id;
			});
			if (found) {
				return found.type || 'UNK';
			} else {
				return 'DNE';
			}
		},
		async saveShipperOptions() {
			// find the corresponding record in the shipper array
			const index = findIndex(this.campaignStore.campaign.shippers, { shipper_id: this.local_shipper_id });

			this.campaignStore.campaign.shippers.splice(index, 1);

			// get the local
			this.campaignStore.$patch({
				campaign: {
					shippers: [
						...this.campaignStore.campaign.shippers,
						{
							shipper_id: this.local_shipper_id,
							custom_settings: this.local_custom_settings,
						},
					],
				},
			});

			this.show_settings_modal = false;

			// resetting...
			this.local_shipper_id = '';
			this.local_custom_settings = {};
		},
		toggleIntegration(shipper_id: string) {
			const index = findIndex(this.campaignStore.campaign.shippers, { shipper_id: shipper_id });
			log.trace('Index in this.shippers', index);
			log.trace('shippers', this.shippers);

			if (index === -1) {
				// We must push to the shippers array of campaignStore
				this.campaignStore.$patch({
					campaign: {
						shippers: [
							...this.campaignStore.campaign.shippers,
							{
								shipper_id: shipper_id,
								custom_settings: {},
							},
						],
					},
				});
			} else {
				// We must this.splice()
				// might splice
				this.campaignStore.campaign.shippers.splice(index, 1);
			}
		},
		async sendEmailTest() {
			// For Test Email Send
			let sample_lead_data = {
				campaign: {
					name: this.campaignStore.campaign.name,
					bid_type: this.campaignStore.campaign.bid_type,
					mpid: this.campaignStore.campaign.mpid,
				},
				lead: {},
			};

			try {
				let vertical = this.campaignStore.campaign.vertical_id_targeting;
				let product = this.campaignStore.campaign.product_targeting;

				let test_lead = await getRandomLead(vertical, product);

				if (test_lead) {
					// Replace identifiable info
					test_lead.id = 'T-0000-0000';
					test_lead.vendor_lead_id = 'T-0000-0000';
					test_lead.first_name = 'John';
					test_lead.last_name = 'Doe';
					test_lead.street_1 = '4321 Main Street';
					test_lead.source_id = 'NG_HE_TEST';
					test_lead.phone = Math.floor(3234567891 + Math.random() * 1234567891).toString();
					test_lead.email = `nextgentest${Math.floor(Math.random() * 1000)}@gmail.com`;
					test_lead.created_at = new Date();
					test_lead.data.gender = 'male';
					sample_lead_data.lead = test_lead;
				}

				// Send Email
				let formatted_recipients = this.campaignStore.campaign.delivery_emails.map((address) => {
					return {
						name: address,
						email: address,
					};
				});

				let template = get(this.em_templates, vertical[product], '01HRWBNSRBZVF5RNX793YEEMMH');

				let email_params = {
					recipients: formatted_recipients,
					template: {
						id: template,
						data: sample_lead_data,
					},
					account_id: this.$route.params.account_id || this.sessionStore.account?.id,
					sender_user_id: this.sessionStore.user.id,
				};

				try {
					await sendEmail(email_params);
					this.$toast.add({
						severity: 'success',
						summary: 'Sent Email',
						life: 3000,
					});
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Error Sending Email',
						detail: err,
						life: 3000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to generate random lead',
					detail: err,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

p {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

.integrations,
.paused-integrations {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 1em;

	h3 {
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: 0;
	}

	.integration {
		align-items: center;
		border: 1px solid var(--gray-15);
		border-radius: 3px;
		box-shadow: 0 2px 0 var(--gray-10);
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		justify-content: space-between;
		padding: 10px 20px;
		width: 100%;

		.title {
			flex: 1;
			line-height: 1.5em;
		}

		.edit {
			align-items: center;
			display: flex;
			font-size: var(--font-size-sm);

			img {
				margin-right: 0.25rem;
			}

			.tablet-down({
				width: 100%;
			});
		}

		&.disabled {
			background-color: var(--gray-05);
			color: var(--gray-35);
		}
	}
}
</style>
