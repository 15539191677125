<template>
	<div class="qualifier cancel-rate">
		<div class="title">
			<div class="name">Cancel Rate</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">Disqualify a campaign if it has a cancel rate that exceeds that max allowable rate.</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Max Allowable Rate:</label>
					<div class="controls gap-20">
						<div class="field">
							<input-percentage v-model="modelValue.max_rate" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import inputPercentage from '@/components/forms/InputPercentage.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'CancelRateQualifier',
	emits: ['remove'],
	components: {
		inputPercentage,
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					max_rate: 0.15,
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
