import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function initUserForm(params: { user_id: string; roles_filters: QueryFilters }) {
	let query: string | undefined;
	if (params.user_id === 'new') {
		query = `
			query InitUserForm($roles_filters: [[Any]]) {
				roles(filters: $roles_filters) {
					value: id
					label: name
					mpid
					type
				}
			}
		`;
	} else {
		query = `
			query InitUserForm($user_id: ID!, $roles_filters: [[Any]]) {
				user(id: $user_id) {
					id
					first_name
					last_name
					email
					phone
					title
					role_id
					account_id
					mfa_enabled
					mfa_delivery
					status
					settings {
						calendly_url
						team
					}
				}
				roles(filters: $roles_filters) {
					value: id
					label: name
					mpid
					type
				}
			}
		`;
	}

	return await $GQL.request(query, params);
}
