import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getFollowUps(filters?: QueryFilters) {
	const query = `
        query GetFollowUps($filters: [[Any]]) {
            followUps(filters: $filters) {
                id
                due_at
                status
                created_at
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.followUps;
}
