<template>
	<div class="select-product">
		<h2>Select a Lead Type</h2>
		<div class="product-options">
			<template v-for="product in productOptions" :key="`${product.id}_${product.bid_type}`">
				<div
					:class="[
						{
							selected: selected_product.id === product.id && selected_product.bid_type === product.bid_type,
						},
					]"
					class="option shadow-2"
					@click="selectProduct(product)"
				>
					<div class="card-header" :style="{ 'background-image': `url(${product.card_bg})` }"></div>
					<div class="content">
						<div class="name">{{ product.name }}</div>
						<div class="description">{{ product.description }}</div>
						<div class="bid-description text-green">Bid as low as {{ productPrice(product.id, product.bid_type) }}</div>
						<p-button
							v-if="selected_product.id === product.id && selected_product.bid_type === product.bid_type"
							class="selected w-full"
							label="SELECTED ✓"
						/>
						<p-button v-else label="SELECT" class="w-full" outlined />
					</div>
				</div>
			</template>
			<div v-if="productOptions.length === 0" class="option shadow-2">
				<div class="no-product-msg">
					There are no available products for this type of campaign, please contact an administrator to update campaign
					settings.
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { currency, title } from '@/lib/Filters';
import { get } from 'lodash-es';
import { mapWritableState, mapStores } from 'pinia';
import { useCampaignStore } from '@/stores/campaign';
import { useMarketplaceStore } from '@/stores/marketplace';
import { getPromotions } from '@GQL';

export default {
	name: 'SelectProduct',
	emits: ['select'],
	data() {
		return {
			bid_type: null,
			selected_product: {
				id: null,
			},
			product_options: [
				{
					id: 'data',
					name: 'Exclusive Data Leads',
					description: 'Data leads sold to a single buyer. Higher quality and higher cost compared to Shared.',
					bid_type: 'exclusive',
					card_bg: '/assets/img/backgrounds/card-background-exclusive.jpg',
				},
				{
					id: 'data',
					name: 'Shared Data Leads',
					description: 'ADMIN ONLY: Data leads sold to 2-4 buyers. Lower quality and lower cost compared to Exclusive.',
					bid_type: 'shared',
					card_bg: '/assets/img/backgrounds/card-background-shared.jpg',
				},
				{
					id: 'call',
					name: 'Calls',
					description: 'Inbound calls that ring directly to you, sold on duration.',
					bid_type: 'exclusive',
					card_bg: '/assets/img/backgrounds/card-background-calls.jpg',
				},
				{
					id: 'live_transfer',
					name: 'Live Transfers',
					description: 'A pre-screened call with a warm transfer from one of our US-based transfer agents.',
					bid_type: 'exclusive',
					card_bg: '/assets/img/backgrounds/card-background-transfers.jpg',
				},
			],
			promotions: [],
			lowest_promo_price: {
				exclusive: 999,
				shared: 999,
				call: 999,
				live_transfer: 999,
			},
		};
	},
	computed: {
		isNew() {
			return this.$route.meta.new;
		},
		productOptions() {
			// const vendor_settings = (this.$root.appStore.settings.vendors || this.marketplaceStore.settings.vendors)[
			// 	this.campaign.vendor_id_targeting
			// ];
			const vertical_settings = this.$root.appStore.settings.verticals || this.marketplaceStore.settings.verticals;

			return this.product_options.filter((product) => {
				// Filter out shared options for non-admins
				if (!this.$root.sessionStore.isAdminUser) {
					return (
						// get(vendor_settings, `verticals.${this.campaign.vertical_id_targeting}.products.${product.id}`, false) &&
						product.bid_type !== 'shared' &&
						get(vertical_settings, `${this.campaign.vertical_id_targeting}.${product.id}.enabled`, false)
					);
				}
				return get(vertical_settings, `${this.campaign.vertical_id_targeting}.${product.id}.enabled`, false);
			});
		},
		...mapWritableState(useCampaignStore, ['campaign']),
		...mapStores(useMarketplaceStore),
	},
	async mounted() {
		await this.getPromoPrices();
		if (this.isNew) {
			if (this.productOptions.length > 0) {
				this.selectProduct(this.productOptions[0]);
				if (this.$route.query.duplicate) {
					if (!this.$root.sessionStore.isAdminUser) {
						// Change the bid_type to exclusive for non-admins
						if (this.campaign.bid_type === 'shared') this.campaign.bid_type = 'exclusive';
					}
					const product = this.product_options.find((option) => {
						return option.id === this.campaign.product_targeting && option.bid_type === this.campaign.bid_type;
					});
					if (product) {
						this.selectProduct(product);
					}
				}
			} else {
				this.selected_product = {};
			}
		} else {
			const product = this.product_options.find((option) => {
				return (option.product = this.campaign.product_targeting && option.bid_type === this.campaign.bid_type);
			});
			this.selectProduct(product);
		}
	},
	methods: {
		selectProduct(value) {
			this.selected_product = value;
			this.$emit('select');
		},
		prev() {
			return 'select_vertical';
		},
		next() {
			if (this.selected_product) {
				this.campaign.product_targeting = this.selected_product.id;
				this.campaign.bid_type = this.selected_product.bid_type;
				if (!this.$route.query.duplicate) {
					this.campaign.name +=
						' ' +
						title(
							` ${this.campaign.product_targeting === 'data' ? this.campaign.bid_type : ''} ${this.campaign.product_targeting} Leads`
						);
				}

				return 'select_promotion';
			} else {
				this.$root.$toast.add({
					severity: 'error',
					summary: 'No Lead Type Set',
					detail: 'Please select a lead type.',
					life: 3000,
				});
				return null;
			}
		},
		productPrice(product, bid_type) {
			const vertical_settings = this.$root.appStore.settings.verticals || this.marketplaceStore.settings.verticals;
			const product_price = get(
				vertical_settings,
				`${this.campaign.vertical_id_targeting}.${product}.${bid_type}.minimum_bid`,
				10
			);
			let promo_price;
			if (product === 'data') {
				if (bid_type === 'shared') {
					promo_price = this.lowest_promo_price.shared;
				} else {
					promo_price = this.lowest_promo_price.exclusive;
				}
			} else {
				promo_price = this.lowest_promo_price[product];
			}
			return promo_price && promo_price < product_price ? currency(promo_price) : currency(product_price);
		},
		async getPromoPrices() {
			// Get all promotions
			this.promotions = await getPromotions([
				[`vertical_id = '${this.campaign.vertical_id_targeting}'`, `status = 'active'`],
			]);
			// Replace lowest promo price map, if a promo exists
			if (this.promotions.length > 0) {
				this.promotions.forEach((promotion) => {
					if (promotion.product === 'data') {
						if (promotion.bid_types.includes('shared')) {
							promotion.shared_minimum_bid < this.lowest_promo_price.shared
								? (this.lowest_promo_price.shared = promotion.shared_minimum_bid)
								: '';
						}
						if (promotion.bid_types.includes('exclusive')) {
							promotion.exclusive_minimum_bid < this.lowest_promo_price.exclusive
								? (this.lowest_promo_price.exclusive = promotion.exclusive_minimum_bid)
								: '';
						}
					}
					if (promotion.product === 'live_transfer') {
						promotion.exclusive_minimum_bid < this.lowest_promo_price.live_transfer
							? (this.lowest_promo_price.live_transfer = promotion.exclusive_minimum_bid)
							: '';
					}
					if (promotion.product === 'call') {
						promotion.exclusive_minimum_bid < this.lowest_promo_price.call
							? (this.lowest_promo_price.call = promotion.exclusive_minimum_bid)
							: '';
					}
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

h2 {
	font-size: var(--font-size-h4);
}

.select-product {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.product-options {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1rem 0;

	.mobile({
		justify-content: center;
	});

	.option {
		background: var(--white);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 20px;
		max-width: 320px;
		min-height: 320px;
		text-align: center;
		transition: all 150ms ease-out;

		img {
			margin: 0 auto;
		}

		.description,
		.bid-description {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
			line-height: 1.5;
			margin-bottom: 1rem;
		}

		.card-header {
			background-position: 50% 50%;
			background-size: cover;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			height: 200px;
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;
			padding: 40px;
		}

		.name {
			font-size: 1.125rem;
			font-weight: bold;
			margin: 0 0 1rem;
		}

		&.selected {
			box-shadow: 0 0 0 3px var(--color-b) !important;
		}
	}

	.vertical-icon {
		margin-bottom: 10px;
	}
}

.no-product-msg {
	align-items: center;
	border: 3px dashed var(--gray-15);
	color: var(--gray-35);
	display: flex;
	height: 100%;
	line-height: 1.35em;
	padding: 40px;
}
</style>
