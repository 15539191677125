import { $GQL } from '@GQL/index';

export async function reportExistingAccountsForAccountManager(params: { account_manager_id: string }) {
	const query = `
        query ReportExistingAccountsForAccountManager($params: JSONObject!) {
            reportExistingAccountsForAccountManager(params: $params) {
            	row_count
            	rows
            	metrics
            }
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportExistingAccountsForAccountManager;
}
