import { $GQL } from '@GQL';

export async function deleteAccount(account_id: string) {
	const mutation = `
    mutation DeleteAccount($account_id: ID!) {
      deleteAccount(id: $account_id) {
        archived
		deleted
      }
    }
  `;

	return await $GQL.request(mutation, { account_id });
}
