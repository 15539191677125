<template>
	<div id="top-campaigns">
		<p-card>
			<template #title>
				<div class="flex flex-column p-3 pb-0">
					<h3>Top Campaigns</h3>
					<div class="sub-value">By Spend for Today</div>
				</div>
			</template>
			<template #content>
				<div class="padded">
					<p-data-table :value="campaigns">
						<p-column>
							<template #header>
								<div class="center">Vertical</div>
							</template>
							<template #body="{ data }">
								<div class="center">
									<vertical-icon :vertical-id="data.vertical_id" size="30px" />
								</div>
							</template>
						</p-column>
						<p-column header="Campaign Info">
							<template #body="{ data }">
								<div class="campaign-name">
									{{ data.name }}
								</div>
								<div class="campaign-info">
									<div class="item">{{ data.auction_type }}</div>
									<div v-if="data.bid_type" class="item">{{ data.bid_type }}</div>
								</div>
							</template>
						</p-column>
						<p-column>
							<template #header>
								<div class="center">Leads</div>
							</template>
							<template #body="{ data }">
								<div class="trend-wrapper">
									<b>{{ data.lead_count }}</b>
									<Trend :value="data.lead_count" :previous-value="30" />
								</div>
							</template>
						</p-column>
						<p-column>
							<template #header>
								<div class="center">Cost</div>
							</template>
							<template #body="{ data }">
								<div class="trend-wrapper">
									<b>{{ currency(data.cost) }}</b>
									<Trend :value="data.cost" :previous-value="250" />
								</div>
							</template>
						</p-column>
						<p-column>
							<template #header>
								<div class="center">Cost/Lead</div>
							</template>
							<template #body="{ data }">
								<div class="trend-wrapper">
									<b>{{ currency(data.cpl) }}</b>
									<Trend :value="data.cost" :previous-value="250" :inverse-metric="true" />
								</div>
							</template>
						</p-column>
						<p-column>
							<template #body>
								<div class="center tools">
									<icon type="exit-to-app" size="24px" />
								</div>
							</template>
						</p-column>
					</p-data-table>
				</div>
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import { currency } from '@/lib/Filters';
import pCard from 'primevue/card';
import pDataTable from 'primevue/datatable';
import pColumn from 'primevue/column';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import Trend from '@/components/elements/Trend.vue';

export default {
	name: 'CampaignsSummary',
	components: {
		pCard,
		pColumn,
		pDataTable,
		verticalIcon,
		Trend,
	},
	data() {
		return {
			campaigns: [
				{
					name: 'High Quality Exclusive',
					vertical_id: 'health_insurance',
					auction_type: 'data',
					bid_type: 'exclusive',
					lead_count: 23,
					cost: 263.58,
					cpl: 11.46,
				},
				{
					name: 'Weeknight Shared',
					vertical_id: 'health_insurance',
					auction_type: 'data',
					bid_type: 'shared',
					lead_count: 42,
					cost: 212.28,
					cpl: 5.26,
				},
				{
					name: 'Aging In Medicare',
					vertical_id: 'medicare',
					auction_type: 'data',
					bid_type: 'exclusive',
					lead_count: 13,
					cost: 186.14,
					cpl: 15.24,
				},
			],
		};
	},
	methods: {
		currency,
	},
};
</script>

<style lang="less">
#top-campaigns {
	.p-datatable .p-datatable-thead > tr > th {
		background: var(--gray-10);
		color: var(--gray-50);
	}

	.p-datatable .p-datatable-tbody > tr:nth-child(odd) {
		background: none;
	}

	.p-card > .p-card-body {
		padding: 0;
	}

	.trend-wrapper {
		display: flex;
		justify-content: center;
	}

	.sub-header {
		color: var(--gray-45);
	}

	.campaign-name {
		font-size: 1.35rem;
		font-weight: bold;
		line-height: 2rem;
	}

	.campaign-info {
		display: flex;
		margin-top: 5px;
		text-transform: uppercase;

		.item {
			background: var(--gray-15);
			border-radius: 12px;
			color: var(--gray-50);
			display: flex;
			font-size: 0.85rem;
			margin-right: 10px;
			padding: 4px 10px;
		}
	}

	.center {
		text-align: center;
		width: 100%;
	}
}
</style>
