<template>
	<div id="promotion-form">
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="name">Name:</label>
				<div class="controls">
					<div class="field">
						<p-input-text id="name" v-model="promotion.name" placeholder="A name for this promotion" />
						<div v-if="v$.promotion.name.$error" class="validation-error">
							{{ v$.promotion.name.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label" for="image">Image:</label>
				<div class="sub-value mb-2">Image to use for Promotion Select in Campaign Edit (optional)</div>
				<div class="controls">
					<div class="field">
						<image-upload
							id="image"
							v-model="promotion.icon_url"
							:filename="`promotion-bg-${promotion.name.replace(/\s+/g, '')}`.trim()"
							folder="promotions"
							mode="advanced"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Description:</label>
				<div class="controls">
					<div class="field">
						<p-text-area
							id="description"
							v-model="promotion.description"
							class="w-full"
							placeholder="Add a description about this promotion for campaign edit"
						/>
						<div v-if="v$.promotion.description.$error" class="validation-error">
							{{ v$.promotion.description.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Restrictions:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="promotion.restrictions" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="vertical">Vertical:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							id="vertical"
							v-model="promotion.vertical_id"
							:options="vertical_options"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.promotion.vertical_id.$error" class="validation-error">
							{{ v$.promotion.vertical_id.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="product">Product:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							id="product"
							v-model="promotion.product"
							:options="product_options"
							option-label="label"
							option-value="value"
						/>
						<div v-if="v$.promotion.product.$error" class="validation-error">
							{{ v$.promotion.product.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="bid-type">Bid Types:</label>
				<div class="controls">
					<div class="field">
						<p-multi-select
							v-model="promotion.bid_types"
							:options="bid_type_options"
							option-label="label"
							option-value="value"
							placeholder="Select Bid Types"
							class="w-full"
						/>
						<div v-if="v$.promotion.bid_types.$error" class="validation-error">
							{{ v$.promotion.bid_types.$errors[0].$message }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label visually-hidden" for="start-date">Start Date:</label>
				<div class="controls">
					<div class="field">
						<date-picker
							id="start-date"
							v-model="promotion.start_date"
							hide-label
							show-time
							:showButtonBar="true"
							label="Promotion Start Date (optional)"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label visually-hidden" for="start-date">End Date:</label>
				<div class="controls">
					<div class="field">
						<date-picker
							id="end-date"
							v-model="promotion.end_date"
							show-time
							:showButtonBar="true"
							label="Promotion End Date (optional)"
							:default-time="{ hours: '23', minutes: '59', seconds: '59' }"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Matching Criteria:</label>
				<div class="controls">
					<div class="field">
						<code-editor v-model.parse.lazy="promotion.custom_filter" />
					</div>
				</div>
			</div>
		</div>
		<p-fieldset legend="Campaign Restrictions">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="allow_source_filtering" v-model="promotion.allow_source_filtering" />
						</div>
						<div class="field caption">
							<label for="allow_source_filtering" class="control-label">Allow Source Filtering</label>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="allow_attribute_filtering" v-model="promotion.allow_attribute_filtering" />
						</div>
						<div class="field caption">
							<label for="allow_attribute_filtering" class="control-label">Allow Attribute Filtering</label>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit">
							<p-input-switch input-id="allow_schedule" v-model="promotion.allow_schedule" />
						</div>
						<div class="field caption">
							<label for="allow_schedule" class="control-label">Allow Campaign Schedule</label>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<gutter size="20px" />
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="exclusive-bid">Exclusive Minimum Bid:</label>
				<div class="controls">
					<div class="field">
						<p-input-currency id="exclusive-bid" v-model="promotion.exclusive_minimum_bid" />
					</div>
				</div>
				<div v-if="v$.promotion.exclusive_minimum_bid.$error" class="validation-error">
					{{ v$.promotion.exclusive_minimum_bid.$errors[0].$message }}
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="shared-bid">Shared Minimum Bid:</label>
				<div class="controls">
					<div class="field">
						<p-input-currency id="shared-bid" v-model="promotion.shared_minimum_bid" />
					</div>
					<div v-if="v$.promotion.shared_minimum_bid.$error" class="validation-error">
						{{ v$.promotion.shared_minimum_bid.$errors[0].$message }}
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Status:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="promotion.status"
							:options="active_status_options"
							option-label="label"
							option-value="value"
							placeholder="Select Status"
						/>
					</div>
					<div v-if="v$.promotion.status.$error" class="validation-error">
						{{ v$.promotion.status.$errors[0].$message }}
					</div>
				</div>
			</div>
		</div>
	</div>
	<gutter size="20px" />
	<div class="footer flex justify-content-end">
		<p-button :label="$route.meta.new ? 'Create Promotion' : 'Update Promotion'" @click="handleSave" />
	</div>
</template>

<script lang="ts">
import imageUpload from '@/components/forms/ImageUpload.vue';
import { useAppStore } from '@/stores/app';
import { required, helpers, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { vertical_options, active_status_options } from '@/lib/Options';
import pTextArea from 'primevue/textarea';
import datePicker from '@/components/forms/DatePicker.vue';
import { cloneDeep, get } from 'lodash-es';
import TIMES from '@/lib/Data/times.json';
import TIMEZONES from '@/lib/Data/timezones.json';
import { insertPromotion, updatePromotion, getPromotionById } from '@GQL';

const product_options = [
	{
		label: 'Data Leads',
		value: 'data',
	},
	{
		label: 'Live Transfers',
		value: 'live_transfer',
	},
	{
		label: 'Calls',
		value: 'call',
	},
	{
		label: 'Ads',
		value: 'ad',
	},
];

const bid_type_options = [
	{
		label: 'Shared',
		value: 'shared',
	},
	{
		label: 'Exclusive',
		value: 'exclusive',
	},
];

const default_promotion = {
	name: '',
	icon_url: null,
	mpid: null,
	description: '',
	restrictions: '',
	custom_filter: [
		[
			{
				target: {
					path: '',
					transformer: null,
				},
				strategy: 'equals',
				comparator: {
					value: '',
					path: null,
					transformer: null,
					regex_flags: null,
				},
				match_missing: false,
				invert: false,
			},
		],
	],
	vertical_id: '',
	product: '',
	bid_types: ['exclusive'],
	start_date: null,
	end_date: null,
	allow_source_filtering: false,
	allow_attribute_filtering: false,
	allow_schedule: true,
	exclusive_minimum_bid: 0,
	shared_minimum_bid: 0,
	status: 'active',
};

export default {
	name: 'PromotionForm',
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	components: {
		imageUpload,
		pTextArea,
		datePicker,
	},
	async mounted() {
		if (this.$route.meta.new && this.$route.query.duplicate) {
			const result = await getPromotionById(this.$route.query.duplicate);
			let duplicate = cloneDeep(result);
			delete duplicate.id;
			delete duplicate.created_at;
			duplicate.name += ' (Copy)';
			this.promotion = duplicate;
		}
		if (!this.$route.meta.new || this.$route.query.id) {
			this.promotion = await getPromotionById(this.$route.params.promotion_id);

			// NOT USED AT THE MOMENT
			// if (this.promotion.schedule && this.promotion.schedule.enabled) {
			// 	this.add_custom_schedule = true;
			// }
			if (Array.isArray(this.promotion.custom_filter) && this.promotion.custom_filter.length > 0) {
				this.add_custom_filter = true;
			}
		} else {
			this.promotion.mpid = this.$route.params.mpid || null;
		}
	},
	data() {
		return {
			vertical_options,
			product_options,
			bid_type_options,
			active_status_options,
			loading: false,
			promotion: cloneDeep(default_promotion),
			timezone: get(this.sessionStore, 'account.settings.timezone', 'America/Los_Angeles'),
			timezone_options: TIMEZONES,
			times_options: TIMES,
			days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
		};
	},
	methods: {
		async handleSave() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				if (this.$route.meta.new) {
					// Insert
					try {
						const result = await insertPromotion(this.promotion);
						if (result) {
							this.$toast.add({
								summary: 'Created Promotion',
								life: 5000,
							});
							this.$router.back();
						}
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to create Promotion',
							life: 6000,
						});
					}
				} else {
					// Update
					try {
						const result = await updatePromotion(this.$route.params.promotion_id, this.promotion);
						if (result) {
							this.$toast.add({
								summary: 'Updated Promotion',
								life: 5000,
							});
							this.$router.back();
						}
					} catch (err) {
						this.$toast.add({
							severity: 'error',
							summary: 'Unable to update Promotion',
							life: 6000,
						});
					}
				}
			}
		},
		handleCustomFiltering(val) {
			if (val === true) {
				// Reset
				this.promotion.custom_filter = null;
			} else {
				// Set a default schedule
				this.promotion.custom_filter = [
					{
						target: {
							path: '',
							transformer: null,
						},
						strategy: 'equals',
						comparator: {
							value: '',
							path: null,
							transformer: null,
							regex_flags: null,
						},
						match_missing: false,
						invert: false,
					},
				];
			}
		},
		handleSchedule(val) {
			if (val === true) {
				// Empty schedule
				this.promotion.schedule = {
					enabled: false,
					timezone: '',
					periods: [],
				};
			} else {
				// Set a default schedule
				this.promotion.schedule = {
					enabled: true,
					timezone: 'America/Los_Angeles',
					periods: [
						{
							days: [1, 2, 3, 4, 5],
							start_time: 800,
							end_time: 1700,
						},
					],
				};
			}
		},
		handleDay(day) {
			if (this.promotion.schedule[0].days.includes(day)) {
				this.promotion.schedule[0].days = this.promotion.schedule[0].days.filter((item) => item !== day);
			} else {
				this.promotion.schedule[0].days.push(day);
			}
		},
		selectWeekdays() {
			this.promotion.schedule[0].days = [1, 2, 3, 4, 5];
		},
		selectWeekend() {
			this.promotion.schedule[0].days = [6, 7];
		},
	},
	computed: {
		availableEndTimes() {
			return this.times_options.filter((time) => time.value > this.promotion.schedule.periods[0].start_time);
		},
	},
	validations() {
		return {
			promotion: {
				name: {
					required: helpers.withMessage('A name is required.', required),
				},
				description: {
					required: helpers.withMessage('A description is required.', required),
				},
				vertical_id: {
					required: helpers.withMessage('A vertical is required.', required),
				},
				product: {
					required: helpers.withMessage('A product is required.', required),
				},
				bid_types: {
					required: helpers.withMessage('At least one bid type is required.', required),
					minLength: minLength(1),
				},
				exclusive_minimum_bid: {
					required: helpers.withMessage('A bid value is required.', required),
				},
				shared_minimum_bid: {
					required: helpers.withMessage('A bid value is required.', required),
				},
				status: {
					required: helpers.withMessage('Status is required.', required),
				},
			},
		};
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';
.days-options {
	.days-label {
		.mobile({
			flex-basis: 100%;
		});
	}
}

.quick-select {
	font-size: var(--font-size-sm);
	margin: 1rem 0;

	a {
		cursor: pointer;
	}
}

.time-options {
	font-size: var(--font-size-sm);
	padding: 1rem 0;

	.timezone .name {
		font-weight: bold;
		padding: 0.75rem 0;
	}
}

.days {
	display: flex;
	flex: 1 0 auto;
	flex-flow: row wrap;

	.active-day {
		background: var(--color-b);
		border-radius: 16px;
		color: white;
		display: inline-block;
		padding: 0.5rem 0.75rem;
	}
}
</style>
