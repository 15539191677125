import { $GQL } from '@GQL/index';
import type { AuctionConfig } from '@nextgenleads/auction-driver';

export async function insertAuctionConfig(data: Omit<AuctionConfig, 'created_at'>) {
	const mutation = `
        mutation InsertAuctionConfig($data: JSONObject!) {
            insertAuctionConfig(data: $data) {
                id
            }
        }
    `;
	return await $GQL.request(mutation, { data });
}
