// --------------------------------------------------
// FILTER: heightFormat
// Convert height in inches to feet and inches
// --------------------------------------------------

export function heightFormat(height: number) {
	if (typeof height === 'number') {
		const feet = Math.floor(height / 12).toString();
		const inches = (height % 12).toString();
		return `${feet}'${inches}"`;
	} else {
		throw Error('Not a number');
	}
}
