import axios from 'axios';
import { $GQL } from '@GQL';

export async function getIPAddress() {
	const response = await axios.get('https://api.ipify.org/?format=json');
	return response.data;
}

export async function getIP() {
	const query = `
		query GetIP {
			getIPAddress
		}
	`;
	return await $GQL.request(query);
}
