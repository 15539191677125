import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function getPromotionsList(params: GetListParams) {
	const query = `
    query GetPromotionsList($params: GetListParams!) {
        promotionsList(params: $params) {
			row_count
            rows {
                id
                mpid
                name
                icon_url
                description
				restrictions
                custom_filter
                vertical_id
                product
                bid_types
                start_date
                end_date
                allow_source_filtering
                allow_attribute_filtering
                exclusive_minimum_bid
                shared_minimum_bid
                status
                created_at
            }
        }
    }       
    `;

	const result = await $GQL.request(query, {
		params,
	});

	return result.promotionsList;
}
