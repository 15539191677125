<template>
	<p-card id="request-password-form">
		<template #header>
			<div class="flex w-full p-2 justify-content-center align-items-center text-center">
				<img class="logo" src="/assets/img/logos/ngl-logo.svg" alt="NextGen Leads Logo" />
			</div>
		</template>
		<template #title>
			<h4>Let's get you a new password...</h4>
		</template>
		<template #subtitle>
			<p>
				Please enter the email address associated with your account below and we will send you an email with
				instructions to reset your password.
			</p>
		</template>
		<template #content>
			<div v-if="!token_sent" class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<div class="p-inputgroup">
								<span class="p-inputgroup-addon">
									<alt-icon type="email" size="1.25rem" />
									<label class="visually-hidden" for="email">Email</label>
								</span>
								<p-input-text
									id="email"
									v-model.trim="email"
									name="email"
									class="p-inputtext-sm flex-1"
									aria-labelledby="email"
									placeholder="Email"
									v-focus
									@keypress.enter="requestPasswordReset"
								/>
							</div>
							<div v-if="v$.email.$error" class="validation-error">
								{{ v$.email.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<p-message v-else severity="success" :closable="false" icon="email">
				<template #messageicon>
					<icon class="mr-3" type="email" size="20px" />
				</template>
				We have sent an email with reset instructions. The reset link will expire after 10 minutes.
			</p-message>
		</template>
		<template #footer>
			<div class="flex flex-column justify-content-center w-full">
				<p-button v-if="!token_sent" class="mb-2" label="REQUEST PASSWORD RESET" @click="requestPasswordReset()" />
				<p-button text label="CANCEL" @click="$emit('changeForm', 'LoginForm')" />
			</div>
		</template>
	</p-card>
</template>

<script lang="ts">
import pMessage from 'primevue/message';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { getResetToken } from '@GQL';

export default {
	name: 'RequestPasswordForm',
	components: {
		pMessage,
	},
	setup() {
		return { v$: useVuelidate() };
	},
	props: {
		loginEmail: {
			type: String,
		},
	},
	data() {
		return {
			email: this.loginEmail || '',
			token_sent: false,
		};
	},
	methods: {
		async requestPasswordReset() {
			const is_valid = await this.v$.$validate();
			if (!is_valid) return;
			try {
				await getResetToken(this.email, this.base_url);
				this.$toast.add({
					severity: 'success',
					summary: 'Password Reset Requested',
					detail: `You should be receiving an email shortly with instructions to update your password`,
					life: 3000,
				});
				this.token_sent = true;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Password Reset Error',
					detail: 'A user with the specified email does not exist',
					life: 3000,
				});
			}
		},
	},
	computed: {
		base_url() {
			return window.location.origin;
		},
	},
	validations() {
		return {
			email: { required, email },
		};
	},
};
</script>

<style lang="less">
h2 {
	margin-bottom: 1rem;
}

#request-password-form {
	border-radius: 20px;
	box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
	padding: 30px 30px 10px;
	width: 450px;

	.logo {
		max-height: 200px;
		max-width: 100%;
	}

	.p-card-footer {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
}
</style>
