import { $GQL } from '@GQL/index';

export async function authenticateReporting(): Promise<{ token: string; url: string }> {
	const query = `
		query AuthenticateReporting {
			authenticateReporting {
				token
				url
			}
		}
	`;

	const result = await $GQL.request(query);
	return result.authenticateReporting;
}
