import { $GQL } from '@GQL/index';

export async function getAccountSignUpSettings(account_id: string, refresh?: boolean) {
	const request = `
			query GetAccountSignUpSettings($account_id: ID!) {
				account(id: $account_id) {
					name
					mpid
					signup_data {
                        primary_vertical
                        products
                        agency_size
                        referred_from
                        signup_score
                        phone_validation {
                        	phone_number
							is_valid
							activity_score
							line_type
							carrier
							is_prepaid
							error
							warnings
                        }
					}
					signup_source {
                        source_id
                        s1
                        s2
                        s3
                        s4
                        s5
                        internal_referral
					}
				}
			}
		`;

	const no_cache = refresh ? ['account'] : undefined;
	return await $GQL.request(request, { account_id, no_cache });
}
