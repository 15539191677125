import { $GQL } from '@GQL/index';
import type { Source } from '@nextgenleads/source-driver';

export async function updateSource(id: string, data: Partial<Source>): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateSource($id: ID!, $data: JSONObject!) {
            updateSource(id: $id, data: $data) {
                source_id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateSource;
}
