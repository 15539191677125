<template>
	<div id="campaign-purchase-report" class="view-content">
		<line-loader :show="loading" />
		<List />
	</div>
</template>

<script lang="ts">
import List from './List.vue';
export default {
	name: 'Campaign Purchase Report',
	components: {
		List,
	},
	data() {
		return {
			breadcrumbs: [
				{ label: this.$route.params.mpid, route: `/marketplaces/manage/${this.$route.params.mpid}` },
				{
					label: this.$route.params.account_id,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts/manage/${this.$route.params.account_id}`,
				},
			],
		};
	},
};
</script>
