import { $GQL } from '@GQL';

export async function deleteRouteTemplate(id: string, mpid: string) {
	const mutation = `
        mutation DeleteRouteTemplate($id: ID!) {
			deleteRouteTemplate(id: $id) {
				id
			}
        }
    `;

	return await $GQL.request(mutation, { id });
}
