import { $GQL } from '@GQL/index';
import type { AuctionConfig } from '@nextgenleads/auction-driver';

export async function getAuctionConfigById(id: string): Promise<Omit<AuctionConfig, 'created_at' | 'modified_at'>> {
	const query = `
        query GetAuctionConfigById($id: ID!) {
            auctionConfig(id: $id) {
                id
                mpid
                name
                description
                vendor_id
                product
                vertical_id
                lead_tags
                source_tags
                source_types
                source_ids
                bid_settings
                validators
                qualifiers
                bid_modifiers
                sorting_bid_modifiers
                group_sorting_bid_modifiers
                status
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.auctionConfig;
}
