import LeadDetailMarketplace from '@/views/Leads/MarketplacePage.vue';
import LeadDetailAdmin from '@/views/Leads/AdminPage.vue';

export default (type: string) => {
	if (type === 'admin') {
		return [
			{
				name: 'LeadDetail',
				path: 'leads/:lead_id',
				component: LeadDetailAdmin,
				meta: {
					title: 'Lead Detail',
					permissions: 'admin.lead_detail',
				},
			},
		];
	} else {
		return [
			{
				name: 'LeadDetail',
				path: 'leads/:lead_id',
				component: LeadDetailMarketplace,
				meta: {
					title: 'Lead Detail',
					permissions: 'lead_detail',
				},
			},
		];
	}
};
