<template>
	<div id="shippers-list" class="view-content">
		<div class="page-heading">
			<h1>Shippers</h1>
			<breadcrumb />
		</div>
		<div class="page-content">
			<row>
				<p-button label="New Shipper" @click="$router.push({ path: `${$route.path}/create` })">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="plus" size="24px" />
						</div>
					</template>
				</p-button>
			</row>
			<gutter size="20px" />
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table ref="dt" scrollable :value="shippers" :loading="loading">
							<template #empty>
								<div class="dim">No Shippers found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="name" header="Shipper Name" sortable sortField="name">
								<template #body="row">
									<router-link :to="`${$route.path}/${row.data.id}`">{{ row.data.name }}</router-link>
								</template>
							</p-column>
							<p-column field="type" header="Shipper Type" sortable sortField="type" class="column-align-center">
								<template #body="row">
									{{ row.data.type }}
								</template>
							</p-column>
							<p-column field="campaigns" header="Assigned Campaigns">
								<template #body="row">
									<ul v-if="row.data.campaigns.length > 0">
										<li v-for="campaign in row.data.campaigns" :key="campaign.id">
											<router-link
												:to="
													$root.sessionStore.isAdminUser
														? `/marketplaces/manage/${$route.params.mpid}/accounts/manage/${$route.params.account_id}/campaigns/${campaign.id}`
														: `/campaigns/${campaign.id}`
												"
												>{{ campaign.name }}
												<br />
											</router-link>
										</li>
									</ul>
									<div v-else>None</div>
								</template>
							</p-column>
							<p-column field="cancellations" header="Cancellations Enabled" class="column-align-center">
								<template #body="row">
									<icon
										v-if="row.data.cancellations.enabled"
										type="check-circle-outline"
										size="24px"
										style="color: var(-green)"
										v-tooltip.top="`Pattern: ${row.data.cancellations.pattern}`"
									/>
									<icon v-else type="close" size="24px" style="color: var(--red-400)" />
								</template>
							</p-column>
							<p-column field="status" header="Status" class="column-align-center" sortable sortField="status">
								<template #body="row">
									{{ startCase(row.data.status) }}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<div class="tool">
											<p-button
												v-tooltip.top="'Duplicate'"
												aria-label="Duplicate"
												@click="duplicateShipper($event, row.data.id)"
											>
												<template #icon>
													<icon type="content-copy" size="20px" />
												</template>
											</p-button>
										</div>
										<delete-action
											message="Are you sure you want to delete this shipper?"
											@delete="deleteShipper(row.data.id)"
										/>
									</div>
								</template>
							</p-column>
						</p-data-table>
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { remove } from 'lodash-es';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';
import { getShippers, deleteShipper } from '@GQL';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ShipperListView',
	components: {
		deleteAction,
	},
	data() {
		return {
			loading: false,
			shippers: [],
		};
	},
	computed: {
		accountId() {
			return this.$route.params.account_id || this.sessionStore.account.id;
		},
		shipperList() {
			return this.shippers.filter((shipper) => {
				return shipper.status === 'active';
			});
		},
		...mapStores(useSessionStore),
	},
	async mounted() {
		await this.getShippers();
	},
	methods: {
		async getShippers() {
			this.loading = true;
			try {
				const shippers_result = await getShippers([[`account_id = '${this.accountId}'`]]);
				this.shippers = shippers_result;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get shippers',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		async duplicateShipper(event, shipper_id) {
			this.$router.push({
				path: `${this.$route.path}/create`,
				query: {
					duplicate: shipper_id,
				},
			});
		},
		async deleteShipper(shipper_id) {
			const delete_result = await deleteShipper(shipper_id);
			if (delete_result.deleteShipper.id) {
				this.$toast.add({
					severity: 'success',
					summary: `The shipper "${delete_result.deleteShipper.name}" was deleted.`,
					life: 3000,
				});
				remove(this.shippers, (shipper) => {
					return shipper.id === shipper_id;
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: `Unable to delete the shipper "${delete_result.deleteShipper[0].name}".`,
					life: 3000,
				});
			}
		},
	},
};
</script>

<style scoped lang="less">
:deep(.tools) {
	max-width: 120px;
}
</style>
