import mapNestedRoutes from '@/lib/Utils/mapNestedRoutes';
import AccountsList from '@/views/Accounts/List/Page.vue';
import AccountFormPage from '@/views/Accounts/Record/Page.vue';
import AccountsWrapper from '@/views/Accounts/AccountWrapper.vue';
import AccountDetail from '@/views/Accounts/Detail/Page.vue';
import reports_routes from './marketplace-reports';
import campaigns_routes from './campaigns';
import leadRoutes from './leads';
import vendors_routes from './vendors';
import shippers_routes from './shipper-service';
import billing_routes from './billing';
import account_ads_routes from './ads';
import account_files_routes from './files';
import account_users_routes from './auth-service';
import account_settings_routes from './settings';
import leadRoutes from './leads';

export default [
	{
		path: 'accounts',
		name: 'AccountsList',
		component: AccountsList,
		meta: {
			title: 'Accounts',
			permissions: 'accounts',
		},
	},
	{
		path: 'accounts/new',
		name: 'AccountNew',
		component: AccountFormPage,
		meta: {
			new: true,
			user_type: 'account',
			permissions: 'accounts',
		},
	},
	{
		path: 'accounts/manage/:account_id',
		name: 'AccountsDetail',
		component: AccountsWrapper,
		children: mapNestedRoutes(
			[
				{
					path: '',
					name: 'AccountsDashboard',
					component: AccountDetail,
					meta: {
						title: 'Account Dashboard',
						permissions: 'accounts_admin',
					},
				},
				...reports_routes,
				...campaigns_routes,
				...leadRoutes('marketplace'),
				...vendors_routes,
				...shippers_routes,
				...billing_routes,
				...account_ads_routes,
				...account_users_routes,
				...account_files_routes,
				...account_settings_routes,
			],
			'AccountsDetail:',
			{
				meta: {
					nested_account: true,
				},
			}
		),
	},
	{
		path: 'accounts/:account_id',
		name: 'AccountEdit',
		component: AccountFormPage,
		meta: {
			title: 'Edit Account',
			permissions: 'accounts',
		},
	},
];
