import { $GQL } from '@GQL/index';

export async function getCancelRate(account_id: string): Promise<number> {
	const query = `
        query GetCancelRate($account_id: String!) {
            getCancelRate(account_id: $account_id)
        }
    `;

	const result = await $GQL.request(query, { account_id });
	return result.getCancelRate;
}
