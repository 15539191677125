import { $GQL } from '@GQL/index';
import type { Account } from '@nextgenleads/marketplace-driver';

export async function createAccount(data: {
	account: Omit<Account, 'id' | 'primary_user'>;
	user: Omit<User, 'id' | 'created_at'>;
}) {
	const mutation = `
        mutation CreateAccount($data: JSONObject!) {
            createAccount(data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.createAccount;
}
