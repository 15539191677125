// --------------------------------------------------
// FILTER: formatDate
// Use dayjs to format a date string
// --------------------------------------------------

// Import dependencies
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

export function formatDate(date: number | number[] | Date | Date[], date_format: string) {
	if (!date) {
		return '--';
	}
	if (Array.isArray(date)) {
		const dates_to_format = date.map((date_to_format) => {
			return dayjs(date_to_format).format(date_format);
		});

		if (dates_to_format[0] === dates_to_format[1]) {
			return dates_to_format[0];
		}
		return dates_to_format.join(' - ');
	}
	return dayjs(date).format(date_format);
}
