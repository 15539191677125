<template>
	<div class="inline-block">
		<div class="mt-1 mb-1">
			<a @click.prevent="shipper_variables_modal = true"
				>Lead Variables <icon type="open-in-new" style="display: inline-table"
			/></a>
			and
			<a @click.prevent="helpers_modal = true">Helpers <icon type="open-in-new" style="display: inline-table" /></a>
		</div>
		<p-dialog
			id="shipper-variables-modal"
			v-model:visible="shipper_variables_modal"
			:modal="true"
			:dismissable-mask="true"
			:pt="{
				root: { class: 'w-12 sm:w-9 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="code-json" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold">Lead Variables</span>
				</div>
			</template>
			<strong>Usage</strong>
			<p class="mt-2 mb-2">
				Wrap these variables in handlebars when mapping to an object to send I.E.
				<code style="color: var(--orange-600)">{{ '{' + '{lead.first_name}' + '}' }}</code>
			</p>
			<gutter size="20px" />
			<p class="mt-2">
				<strong>General Lead Variables</strong>
			</p>
			<ul class="list-disc pl-3 mt-3 ml-3 columned">
				<li>
					<code>lead.id</code>
				</li>
				<li>
					<code>lead.mpid</code>
				</li>
				<li>
					<code>lead.ulid</code>
				</li>
				<li>
					<code>lead.vendor_id</code>
				</li>
				<li>
					<code>lead.vendor_lead_id</code>
				</li>
				<li>
					<code>lead.product</code>
				</li>
				<li>
					<code>lead.vertical_id</code>
				</li>
				<li>
					<code>lead.first_name</code>
				</li>
				<li>
					<code>lead.last_name</code>
				</li>
				<li>
					<code>lead.street_1</code>
				</li>
				<li>
					<code>lead.street_2</code>
				</li>
				<li>
					<code>lead.city</code>
				</li>
				<li>
					<code>lead.state</code>
				</li>
				<li>
					<code>lead.zip</code>
				</li>
				<li>
					<code>lead.county</code>
				</li>
				<li>
					<code>lead.lat</code>
				</li>
				<li>
					<code>lead.lng</code>
				</li>
				<li>
					<code>lead.email</code>
				</li>
				<li>
					<code>lead.phone</code>
				</li>
				<li>
					<code>lead.data</code>
				</li>
				<li>
					<code>lead.meta.useragent</code>
				</li>
				<li>
					<code>lead.meta.ip_address</code>
				</li>
				<li>
					<code>lead.meta.device</code>
				</li>
				<li>
					<code>lead.meta.browser</code>
				</li>
				<li>
					<code>lead.meta.os</code>
				</li>
				<li>
					<code>lead.meta.device_brand</code>
				</li>
				<li>
					<code>lead.meta.ngl_phone</code>
				</li>
				<li>
					<code>lead.meta.vendor_phone_id</code>
				</li>
				<li>
					<code>lead.meta.call_placement</code>
				</li>
				<li>
					<code>lead.dnc</code>
				</li>
				<li>
					<code>lead.leadid_token</code>
				</li>
				<li>
					<code>lead.trustedform_id</code>
				</li>
				<li>
					<code>lead.trustedform_cert_url</code>
				</li>
				<li>
					<code>lead.trustedform_share_url</code>
				</li>
				<li>
					<code>lead.elid</code>
				</li>
				<li>
					<code>lead.spoken_language</code>
				</li>
				<li>
					<code>lead.tcpa.id</code>
				</li>
				<li>
					<code>lead.tcpa.text</code>
				</li>
				<li>
					<code>lead.tcpa.consent.call</code>
				</li>
				<li>
					<code>lead.tcpa.consent.email</code>
				</li>
				<li>
					<code>lead.tcpa.consent.sms</code>
				</li>
				<li>
					<code>lead.tcpa.consent.mms</code>
				</li>
				<li>
					<code>lead.tcpa.consent.autodial</code>
				</li>
				<li>
					<code>lead.permanent_id</code>
				</li>
				<li>
					<code>lead.session_id</code>
				</li>
				<li>
					<code>lead.business_unit</code>
				</li>
				<li>
					<code>lead.source_type</code>
				</li>
				<li>
					<code>lead.source_hash</code>
				</li>
				<li>
					<code>lead.sub_id_hash</code>
				</li>
				<li>
					<code>lead.site_id</code>
				</li>
				<li>
					<code>lead.click_network</code>
				</li>
				<li>
					<code>lead.click_token</code>
				</li>
				<li>
					<code>lead.tags</code>
				</li>
				<li>
					<code>lead.duplicate</code>
				</li>
				<li>
					<code>lead.spam</code>
				</li>
				<li>
					<code>lead.test</code>
				</li>
				<li>
					<code>lead.created_at</code>
				</li>
				<li>
					<code>lead.modified_at</code>
				</li>
			</ul>
			<gutter size="20px" />
			<p class="mt-2">
				<strong>General Purchase Variables</strong>
			</p>
			<ul class="list-disc pl-3 mt-3 ml-3 columned">
				<li>
					<code>purchase.campaign_id</code>
				</li>
				<li>
					<code>purchase.created_at</code>
				</li>
				<li>
					<code>purchase.price</code>
				</li>
			</ul>
			<gutter size="20px" />
			<p>
				<strong>Health Insurance Lead Variables</strong>
			</p>
			<ul class="list-disc pl-3 mt-3 ml-3 columned">
				<li>
					<code>lead.data.can_afford_insurance</code>
				</li>
				<li>
					<code>lead.data.has_medical_condition</code>
				</li>
				<li>
					<code>lead.data.has_prescription</code>
				</li>
				<li>
					<code>lead.data.height</code>
				</li>
				<li>
					<code>lead.data.household_size</code>
				</li>
				<li>
					<code>lead.data.income</code>
				</li>
				<li>
					<code>lead.data.insurance_timeframe</code>
				</li>
				<li>
					<code>lead.data.interested_in_medicare</code>
				</li>
				<li>
					<code>lead.data.medical_conditions</code>
				</li>
				<li>
					<code>lead.data.military</code>
				</li>
				<li>
					<code>lead.data.pregnant</code>
				</li>
				<li>
					<code>lead.data.qualifying_life_event</code>
				</li>
				<li>
					<code>lead.data.tobacco_user</code>
				</li>
				<li>
					<code>lead.data.under_65_medicare</code>
				</li>
				<li>
					<code>lead.data.weight</code>
				</li>
				<li>
					<code>lead.data.dob</code>
				</li>
				<li>
					<code>lead.data.gender</code>
				</li>
			</ul>
			<gutter size="20px" />
			<p>
				<strong>Medicare Variables</strong>
			</p>
			<ul class="list-disc pl-3 mt-3 ml-3">
				<li>
					<code>lead.data.has_medicare_parts_a_b</code>
				</li>
				<li>
					<code>lead.data.dob</code>
				</li>
				<li>
					<code>lead.data.gender</code>
				</li>
			</ul>
		</p-dialog>
		<p-dialog
			id="helpers-modal"
			v-model:visible="helpers_modal"
			:modal="true"
			:dismissable-mask="true"
			:pt="{
				root: { class: 'w-12 sm:w-9 md:w-6' },
			}"
		>
			<template #header>
				<div class="flex align-items-center w-full">
					<icon type="code-json" size="1.5rem" style="margin-right: 1rem" />
					<span class="font-bold">Helpers</span>
				</div>
			</template>
			<p>Helpers can be used to modify the data placed into a data map.</p>

			<gutter size="20px" />

			<h2>age</h2>
			<p>Turns date of birth <code>lead.data.dob</code> into an age</p>
			<highlightjs language="javascript" :code="age_code" style="white-space: pre-line" />

			<gutter size="20px" />

			<h2>bool</h2>
			<p>Returns <code>Yes</code> or <code>No</code> instead of true/false</p>
			<highlightjs language="javascript" :code="bool_code" style="white-space: pre-line" />

			<gutter size="20px" />

			<h2>currency</h2>
			<p>Formats a number into currency</p>
			<highlightjs language="javascript" :code="currency_code" style="white-space: pre-line" />

			<gutter size="20px" />

			<h2>formatDate</h2>
			<p>
				Uses date-fns to format a date, read
				<a href="https://date-fns.org/docs/format" target="_blank">https://date-fns.org/docs/format</a> for more details
			</p>
			<highlightjs language="javascript" :code="date_format_code" style="white-space: pre-line" />

			<gutter size="20px" />

			<h2>formatPhone</h2>
			<p>
				Format a 10-digit string as a phone number. Default format, "(###) ###-####". Or pass 'dashed' for ###-###-####
			</p>
			<highlightjs language="javascript" :code="phone_format_code" style="white-space: pre-line" />

			<gutter size="20px" />

			<h2>heightFeet</h2>
			<p>Gets the amount of feet in a height number</p>
			<highlightjs language="javascript" :code="height_feet_code" style="white-space: pre-line" />

			<gutter size="20px" />

			<h2>heightInches</h2>
			<p>Returns the remaining inches in a height number</p>
			<highlightjs language="javascript" :code="height_inches_code" style="white-space: pre-line" />

			<gutter size="20px" />

			<h2>sex</h2>
			<p>Takes <code>male</code> or <code>female</code> and returns one of the next two strings</p>
			<highlightjs language="javascript" :code="sex_format_code" style="white-space: pre-line" />

			<gutter size="20px" />

			<h2>transform</h2>
			<p>
				Universal transform that takes an expected value and formats to the string provided I.E. if passing the
				condition <code>male='M'</code>, if the <code>lead.data.gender</code> is <code>male</code> it will return
				<code>M</code>
			</p>
			<highlightjs language="javascript" :code="transform_code" style="white-space: pre-line" />
		</p-dialog>
	</div>
</template>

<script lang="ts">
import 'highlight.js/styles/stackoverflow-light.css';
export default {
	name: 'VariablesHelpersModal',
	data() {
		return {
			shipper_variables_modal: false,
			helpers_modal: false,
			age_code: `// Before:
				{
					"age": {{age lead.data.dob}}
				}

				// After:
				{
				"age": 30
			}`,
			bool_code: `// Before:
				{
					"condition": {{bool lead.data.has_medical_conditions}}
				}
					
				// After:
				{
					"condition": "Yes"
			}`,
			currency_code: `// Before:
				{
				"income": {{currency lead.data.income}}
				}

				// After:
				{
					"income": "$50,000.00"
			}`,
			height_feet_code: `// Before:
				{
					"height_ft": {{heightFeet lead.data.height}}
				}

				// After:
				{
					"height_ft": "5"
			}`,
			height_inches_code: `// Before:
				{
					"height_inches": {{heightInches lead.data.height}}
				}

				// After:
				{
					"height_inches": "8"
			}`,
			date_format_code: `// Before:
				{
					"date": {{formatDate lead.data.dob 'MM/dd/yyyy'}}
				}

				// After:
				{
					"date": "01/01/1990"
			}`,
			phone_format_code: `// Before:
				{
					"phone": {{formatPhone lead.phone}}
				}

				OR

				{
					"phone": {{formatPhone lead.phone 'dashed'}}
				}

				// After:
				{
					"phone": "(555) 555-5555"
				}

				OR

				{
					"phone": "555-555-5555"
			}`,
			sex_format_code: `// Before	:
				{
					"sex": {{sex lead.data.gender 'M' 'F'}}
				}

				// After:	
				{
					"sex": 'M'
			}`,
			transform_code: `// Before	:
				{
					"gender" : "{{transform lead.data.gender male='M' female='F'}}",
				}

				// After:	
				{
					"gender": 'M'
			}`,
		};
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';

code {
	font-size: var(--font-size-sm);
	color: var(--teal-600);
}

.columned {
	.laptop({
		columns: 2;
	});
	.desktop-up({
		columns: 3;
	});
}
</style>
