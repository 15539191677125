<template>
	<ul class="table-list">
		<template v-if="lead.data.dob">
			<li>
				<div class="label">Date of Birth</div>
				<div class="value">
					{{ formatDate(lead.data.dob, 'MM/DD/YYYY') }}
				</div>
			</li>
			<li>
				<div class="label">Age</div>
				<div class="value">
					{{ dobToAge(lead.data.dob) }}
				</div>
			</li>
		</template>
		<li>
			<div class="label">Gender</div>
			<div class="value">
				{{ title(lead.data.gender) || 'N/A' }}
			</div>
		</li>
		<li>
			<div class="label">Height</div>
			<div class="value">
				{{ lead.data.height ? heightFormat(lead.data.height) : 'N/A' }}
			</div>
		</li>
		<li>
			<div class="label">Weight</div>
			<div class="value">{{ lead.data.weight ? `${Math.round(lead.data.weight)} Lbs` : 'N/A' }}</div>
		</li>
		<li>
			<div class="label">Household Size</div>
			<div class="value">
				{{ lead.data.household_size || 'N/A' }}
			</div>
		</li>
		<li>
			<div class="label">Household Income</div>
			<div class="value">
				{{ lead.data.income ? incomeString(lead.data.income, lead.data.household_size) : 'N/A' }}
				<div v-if="$root.sessionStore.isAdminUser && lead.data.income" class="sub-value">
					({{ currency(lead.data.income) }})
				</div>
			</div>
		</li>
		<li>
			<div class="label">Medical Conditions</div>
			<div class="value">
				{{ lead.data.medical_conditions ? formatConditions(lead.data.medical_conditions) : 'N/A' }}
			</div>
		</li>
		<li>
			<div class="label">Pregnant/Expecting</div>
			<div class="value">
				{{ lead.data.pregnant ? 'Yes' : 'No' }}
			</div>
		</li>
		<li>
			<div class="label">Tobacco Use</div>
			<div class="value">
				{{ lead.data.tobacco_user ? 'Yes' : 'No' }}
			</div>
		</li>
		<li>
			<div class="label">Interested in</div>
			<div class="value">
				{{ lead.data.interested_in ? formatInterests(lead.data.interested_in) : 'N/A' }}
			</div>
		</li>
		<li>
			<div class="label">Timeframe</div>
			<div class="value">
				{{ lead.data.timeframe || 'N/A' }}
			</div>
		</li>
	</ul>
</template>

<script>
import { currency, dobToAge, formatDate, heightFormat, title } from '@/lib/Filters';

export default {
	name: 'HealthInsuranceDetails',
	props: {
		lead: {
			type: Object,
			required: true,
		},
	},
	methods: {
		currency,
		dobToAge,
		formatDate,
		heightFormat,
		title,
		formatConditions(medical_conditions) {
			let conditions = '';

			medical_conditions.forEach((condition, index, arr) => {
				if (index === arr.length - 1) {
					conditions += condition;
				} else {
					conditions += condition + ', ';
				}
			});

			if (medical_conditions.length === 0) {
				conditions = 'None';
			}

			return conditions;
		},
		formatInterests(interests) {
			const mapping = {
				dental_insurance: 'Dental Insurance',
				life_insurance: 'Life Insurance',
				pet_insurance: 'Pet Insurance',
				telehealth_coverage: 'Telehealth',
				vision_insurance: 'Vision Insurance',
			};

			const formatted = interests.map((interest) => {
				return mapping[interest];
			});

			if (formatted.length > 0) {
				return formatted.join(',');
			}
			return 'None';
		},
		incomeString(income, household_size = 1) {
			let income_string = 'Unknown';
			if (income) {
				switch (household_size) {
					case 1:
						if (income < 12000) income_string = 'Less than $12,000';
						if (income >= 12000 && income < 29000) income_string = '$12,000 - $28,999';
						if (income >= 29000 && income < 47000) income_string = '$29,000 - $46,999';
						if (income >= 47000) income_string = '$47,000 or Over';
						break;
					case 2:
						if (income < 16000) income_string = 'Less than $16,000';
						if (income >= 16000 && income < 39000) income_string = '$16,000 - $38,999';
						if (income >= 39000 && income < 64000) income_string = '$39,000 - $63,999';
						if (income >= 64000) income_string = '$64,000 or Over';
						break;
					case 3:
						if (income < 21000) income_string = 'Less than $21,000';
						if (income >= 21000 && income < 49000) income_string = '$21,000 - $48,999';
						if (income >= 49000 && income < 80000) income_string = '$49,000 - $79,999';
						if (income >= 80000) income_string = '$80,000 or Over';
						break;
					default:
						if (income < 25000) income_string = 'Less than $25,000';
						if (income >= 25000 && income < 61000) income_string = '$25,000 - $60,999';
						if (income >= 61000 && income < 97000) income_string = '$61,000 - $96,999';
						if (income >= 97000) income_string = '$97,000 or Over';
						break;
				}
			}
			return income_string;
		},
	},
};
</script>
