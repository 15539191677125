import { $GQL } from '@GQL/index';
import { type QueryParams } from '@GQL/queryParams';
import { type Purchase } from '@nextgenleads/broker-driver';

export async function getPurchaseHistory(params: QueryParams): Promise<Purchase[]> {
	const query = `
        query GetPurchasesList($params: GetListParams) {
            purchaseList(params: $params) {
				rows {
					id
					account {
						id
						name
					}
					campaign {
						id
						name
						flags {
							bulk_returns {
								enabled
							}
						}
					}
					mpid
					product
					bid_type
					tags
					price
					original_price
					status
					created_at
				}
            }
        }
    `;
	const result = await $GQL.request(query, { params });
	return result.purchaseList;
}
