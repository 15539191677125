<template>
	<p-dialog v-model:visible="show_modal" :modal="true" :dismissable-mask="true" style="width: 800px">
		<template #header>
			<div>
				<strong>Shipment Log Details</strong>
			</div>
		</template>
		<h4>Request Sent</h4>
		<highlightjs autodetect :code="request" />
		<div v-if="response !== ''">
			<gutter size="20px" />
			<h4>Response</h4>
			<highlightjs autodetect :code="response" />
		</div>
	</p-dialog>
</template>

<script lang="ts">
import pDialog from 'primevue/dialog';

export default {
	name: 'ShipmentLogDetailsModal',
	components: {
		pDialog,
	},
	props: {},
	data() {
		return {
			show_modal: false,
			request: '',
			response: '',
		};
	},
	methods: {
		openModal(request: string | unknown, response?: string) {
			if (typeof request !== 'string') {
				this.request = JSON.stringify(request, null, 2);
			} else {
				this.request = request;
			}
			if (response) {
				this.response = response;
			} else {
				this.response = '';
			}
			this.show_modal = true;
		},
	},
};
</script>
