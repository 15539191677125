<template>
	<div id="networks-list" class="view-content">
		<div class="page-heading">
			<h1>Networks</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<NetworkList />
	</div>
</template>

<script>
import NetworkList from './List.vue';

export default {
	name: 'NetworkListView',
	components: {
		NetworkList,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Networks' }],
		};
	},
};
</script>
