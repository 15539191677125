import { $GQL } from '@GQL/index';
import log from '@/lib/Log';
import type { GetListParams, QueryFilters } from '@nextgenleads/db';

export async function getPostLogs(filters: QueryFilters) {
	const query = `
    query getPostLogs($filters: [[Any]]){
        postLogs(filters: $filters) {
            id
            integration_id
            purchase_id
            lead_id
            lead {
                first_name
                last_name
                phone
            }
            ping_log_id
            request
            request_format
            response
            response_format
            result
            message
            created_at
            account {
                name
            }
            integration {
                post_settings
            }
        }
    }       
    `;

	const result = await $GQL.request(query, {
		filters,
	});

	return result.postLogs;
}
