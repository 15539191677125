import NotificationsList from '@/views/Notifications/List/Page.vue';
import CreateNotification from '@/views/Notifications/Record/Page.vue';

export default [
	{
		path: 'notifications',
		name: 'Notifications',
		component: NotificationsList,
		meta: {
			title: 'My Notifications',
			permissions: 'admin.notifications',
		},
	},
	{
		path: 'notifications/new',
		name: 'CreateNotification',
		component: CreateNotification,
		meta: {
			new: true,
			title: 'Create Notification',
			permissions: 'admin.notifications',
		},
	},
	{
		path: 'notifications/edit/:notification_id',
		name: 'EditNotification',
		component: CreateNotification,
		meta: {
			title: 'Edit Notification',
			permissions: 'admin.notifications',
		},
	},
];
