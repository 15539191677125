import AccountOverview from '@/views/Reports/AccountReports/Overview/Page.vue';
import MarketplaceOverview from '@/views/Reports/MarketplaceReports/Overview/Page.vue';
import MarketplaceDataLeads from '@/views/Reports/MarketplaceReports/Data/Page.vue';
import MarketplaceCalls from '@/views/Reports/MarketplaceReports/Calls/Page.vue';
import MarketplaceLiveTransfers from '@/views/Reports/MarketplaceReports/LiveTransfers/Page.vue';
import AccountAllLeads from '@/views/Reports/AccountReports/All/Page.vue';
import AccountActivity from '@/views/Reports/AccountReports/Overview/AccountActivity.vue';
import AccountPerformance from '@/views/Reports/AccountReports/Overview/AccountPerformance.vue';
import AccountDataLeads from '@/views/Reports/AccountReports/Data/Page.vue';
import AccountAds from '@/views/Reports/AccountReports/Ads/Page.vue';
import AccountCallLeads from '@/views/Reports/AccountReports/Calls/Page.vue';
import AccountLiveTransfers from '@/views/Reports/AccountReports/LiveTransfers/Page.vue';
import AccountConversionsBySourceReport from '@/views/Reports/AccountReports/ConversionsBySource/Page.vue';
import MarketplaceConversionsBySourceReport from '@/views/Reports/MarketplaceReports/ConversionsBySource/Page.vue';
import LegacyV3PurchasesPage from '@/views/LegacyData/Purchases/List/PurchasesPage.vue';

export default [
	{
		path: '',
		name: 'Home',
	},
	{
		path: 'reports/account-overview',
		name: 'AccountOverview',
		component: AccountOverview,
		meta: {
			title: 'Account Overview',
			permissions: 'account_reports',
		},
	},
	{
		path: 'reports/account-activity',
		name: 'AccountActivity',
		component: AccountActivity,
		meta: {
			title: 'Account Activity',
			permissions: 'account_reports',
		},
	},
	{
		path: 'reports/account-performance',
		name: 'AccountPerformance',
		component: AccountPerformance,
		meta: {
			title: 'Account Performance',
			permissions: 'account_reports',
		},
	},
	{
		path: 'reports/marketplace-overview',
		name: 'MarketplaceOverview',
		component: MarketplaceOverview,
		meta: {
			title: 'Marketplace Overview',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'reports/marketplace-conversions-source',
		name: 'MarketplaceConversionsBySource',
		component: MarketplaceConversionsBySourceReport,
		meta: {
			title: 'Conversions',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'reports/conversions-source',
		name: 'AccountConversionsBySource',
		component: AccountConversionsBySourceReport,
		meta: {
			title: 'Conversions',
			permissions: 'account_reports',
		},
	},
	{
		path: 'reports/data-leads',
		name: 'MarketplaceDataLeads',
		component: MarketplaceDataLeads,
		meta: {
			title: 'Marketplace Data Leads',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'reports/calls',
		name: 'MarketplaceCalls',
		component: MarketplaceCalls,
		meta: {
			title: 'Marketplace Calls',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'reports/live-transfers',
		name: 'MarketplaceLiveTransfers',
		component: MarketplaceLiveTransfers,
		meta: {
			title: 'Marketplace Live Transfers',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'legacy-purchases',
		name: 'Legacy Purchase',
		component: LegacyV3PurchasesPage,
		meta: {
			title: 'Purchases - Legacy',
			permissions: 'account_reports',
		},
	},
	{
		path: 'purchases',
		name: 'AllLeads',
		component: AccountAllLeads,
		meta: {
			title: 'Purchases - All Leads',
			permissions: 'account_reports',
		},
	},
	{
		path: 'purchases/data-leads',
		name: 'DataPurchases',
		component: AccountDataLeads,
		meta: {
			title: 'Data Lead Purchases',
			permissions: 'account_reports',
		},
	},
	{
		path: 'purchases/ads',
		name: 'AccountAds',
		component: AccountAds,
		meta: {
			title: 'Account Premium Listings',
			permissions: 'marketplace_reports',
		},
	},
	{
		path: 'purchases/call-leads',
		name: 'CallPurchases',
		component: AccountCallLeads,
		meta: {
			title: 'Call Lead Purchases',
			permissions: 'account_reports',
		},
	},
	{
		path: 'purchases/live-transfers',
		name: 'LiveTransferPurchases',
		component: AccountLiveTransfers,
		meta: {
			title: 'Live Transfer Purchases',
			permissions: 'account_reports',
		},
	},
];
