<template>
	<div id="account-wrapper">
		<row>
			<div class="page-heading">
				<h1>{{ account_name }}</h1>
				<breadcrumb :items="breadcrumb_items" />
			</div>
		</row>
		<row class="tab-row">
			<p-tab-menu v-model:activeIndex="active_tab" :model="tabs">
				<template #item="{ label, item }">
					<template v-if="item.route">
						<div class="account-tab">
							<router-link :to="`${routePrefix}/${item.route}`">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
							</router-link>
						</div>
					</template>
					<template v-else>
						<div class="account-tab" @click.stop>
							<div class="tab-submenu-wrapper" @click="toggleSubMenu($event, item.ref)">
								<div class="tab-icon">
									<alt-icon :type="item.icon" />
								</div>
								<div class="tab-label">
									{{ label }}
								</div>
								<div class="tab-submenu-toggle">
									<alt-icon type="expand_more" />
								</div>
							</div>
							<p-menu :ref="item.ref" class="account-submenu" :model="item.items" :popup="true">
								<template #item="{ item }">
									<router-link :to="`${routePrefix}/${item.route}`">
										<div class="tab-label">
											{{ item.label }}
										</div>
									</router-link>
								</template>
							</p-menu>
						</div>
					</template>
				</template>
			</p-tab-menu>
		</row>
		<row class="account-content">
			<div class="top-fade" />
			<router-view>
				<template #default="{ Component, route }">
					<transition name="drop-fade" mode="out-in">
						<component :is="Component" />
					</transition>
				</template>
			</router-view>
		</row>
	</div>
</template>

<script>
import pTabMenu from 'primevue/tabmenu';
import pMenu from 'primevue/menu';
import { getAccountById } from '@GQL';
import { useAppStore } from '@/stores/app';

export default {
	name: 'ParentAccountWrapper',
	components: {
		pTabMenu,
		pMenu,
	},
	setup() {
		return {
			appStore: useAppStore(),
		};
	},
	data() {
		return {
			active_tab: 0,
			account_name: 'Account Name',
			breadcrumb_items: [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Parent Accounts',
					route: this.$route.params.mpid
						? `/marketplaces/manage/${this.$route.params.mpid}/parent-accounts`
						: '/parent-accounts',
				},
			],
		};
	},
	computed: {
		routePrefix() {
			// make different prefixes if not in ADMIN APP....
			if (this.appStore.isAdminApp) {
				return `/marketplaces/manage/${this.$route.params.mpid}/parent-accounts/${this.$route.params.parent_account_id}`;
			} else {
				return `/parent-accounts/${this.$route.params.parent_account_id}`;
			}
		},
		tabs() {
			if (this.$root.sessionStore.isAdminUser) {
				return [
					{
						label: 'Overview',
						icon: 'insert_chart',
						route: 'reports/parent-account-overview',
					},
					{
						label: 'Campaign Templates',
						icon: 'flag_circle',
						route: 'campaign-templates',
					},
					{
						label: 'Child Accounts',
						icon: 'account_tree',
						route: 'child-accounts',
					},
					{
						label: 'Leads',
						icon: 'demography',
						ref: 'leads',
						items: [
							{
								label: 'All Leads',
								route: 'purchases',
							},
							{
								label: 'Data Leads',
								route: 'purchases/data-leads',
							},
							{
								label: 'Call Leads',
								route: 'purchases/call-leads',
							},
							{
								label: 'Live Transfer Leads',
								route: 'purchases/live-transfers',
							},
						],
					},
					{
						label: 'Billing',
						icon: 'credit_card',
						ref: 'billing',
						items: [
							{
								label: 'Payment Methods',
								route: 'payment-methods',
							},
							{
								label: 'Transactions',
								route: 'transactions',
							},
							{
								label: 'Billing Settings',
								route: 'billing-settings',
							},
							{
								label: 'Transfer Portal',
								route: 'transfer-portal',
							},
						],
					},
					{
						label: 'Users',
						icon: 'account_circle',
						route: 'users',
					},
					{
						label: 'Settings',
						icon: 'tune',
						route: 'settings/account',
					},
				];
			} else {
				return [
					{
						label: 'Overview',
						icon: 'insert_chart',
						route: 'reports/parent-account-overview',
					},
					{
						label: 'Campaign Templates',
						icon: 'flag_circle',
						route: 'campaign-templates',
					},
					{
						label: 'Child Accounts',
						icon: 'account_tree',
						route: 'child-accounts',
					},
					{
						label: 'Leads',
						icon: 'demography',
						ref: 'leads',
						items: [
							{
								label: 'All Leads',
								route: 'purchases',
							},
							{
								label: 'Data Leads',
								route: 'purchases/data-leads',
							},
							{
								label: 'Call Leads',
								route: 'purchases/call-leads',
							},
							{
								label: 'Live Transfer Leads',
								route: 'purchases/live-transfers',
							},
						],
					},
					{
						label: 'Billing',
						icon: 'credit_card',
						ref: 'billing',
						permissions: 'AccountTabs.billing',
						items: [
							{
								label: 'Transactions',
								route: 'transactions',
							},
							{
								label: 'Transfer Portal',
								route: 'transfer-portal',
							},
						],
					},
					{
						label: 'Users',
						icon: 'account_circle',
						route: 'users',
					},
					{
						label: 'Settings',
						icon: 'tune',
						route: 'settings/account',
					},
				];
			}
		},
	},
	watch: {
		$route: {
			handler(new_value) {
				this.active_tab = this.tabs.findIndex((tab) => {
					if (tab.items) {
						return tab.items.some((sub_item) => {
							return new_value.path.indexOf(sub_item.route) > 0;
						});
					}
					return new_value.path.indexOf(tab.route) > 0;
				});
			},
			immediate: true,
		},
	},
	async mounted() {
		// get the account info for name etc
		if ('parent_account_id' in this.$route.params) {
			const account = await getAccountById(this.$route.params.parent_account_id);
			this.account_name = account.name;
			this.breadcrumb_items.push({
				label: account.name,
			});
		}
	},
	methods: {
		toggleSubMenu(event, sub_menu_ref) {
			this.$refs[sub_menu_ref].toggle(event);
		},
		transitionType(route) {
			return route.matched[route.matched.length - 1].meta.transition || 'drop-fade';
		},
	},
};
</script>

<style scoped lang="less">
#account-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;

	.row {
		flex: 0 0 auto;
	}

	.account-content {
		display: flex;
		flex: 1 0;
		flex-direction: column;
		overflow-y: hidden;

		.view-content {
			min-height: auto;
		}
	}

	.top-fade {
		background: linear-gradient(
			180deg,
			rgb(255 255 255 / 100%) 0%,
			rgb(255 255 255 / 100%) 10%,
			rgb(255 255 255 / 0%) 100%
		);
		height: 50px;
		position: absolute;
		width: 100%;
		z-index: 100;
	}
}

.page-heading {
	margin-bottom: -20px;
	padding: 40px;
}

.account-content {
	background: linear-gradient(
		180deg,
		rgb(255 255 255 / 100%) 0%,
		rgb(255 255 255 / 0%) 15%,
		rgb(255 255 255 / 0%) 100%
	);

	:deep(h1) {
		font-size: 1.75rem;
		margin-bottom: 1rem;
	}

	:deep(.page-heading) {
		margin-bottom: 1em;
	}

	:deep(.p-breadcrumb) {
		display: none;
	}
}
</style>
