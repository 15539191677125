import { $GQL } from '@GQL/index';

export async function initMarketplaceApp(mpid: string) {
	const mp_filters = [[`id <> '$NG'`]];

	const query = `
        query InitMarketplaceApp($mpid: ID!, $mp_filters: [[Any]]) {
            marketplace(id: $mpid) {
				name
				settings {
					logo_url
					favicon_url
					login_bkg_url
					theme
				}
            }
			vendor_options: vendors {
				value: id
				label: name
			}
			marketplace_options: marketplaces(filters: $mp_filters) {
				value: id
				label: name
			}
			settings(paths: ["vendors", "verticals"], mpid: "${mpid}")
        }
    `;

	const init_result = await $GQL.request(
		query,
		{ mpid, mp_filters },
		`API-Key ${import.meta.env.VITE_MARKETPLACE_API_KEY}`
	);
	return init_result;
}
