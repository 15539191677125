import { $GQL } from '@GQL/index';
import { type Offer } from '@nextgenleads/marketplace-driver';

export async function getOfferById(id: string): Promise<Omit<Offer, 'created_at' | 'modified_at' | 'created_by'>> {
	const query = `
        query GetOffer($id: ID!) {
            offer(id: $id) {
                id
                mpid
                name
                description
                credit_type
                credit_amount
				max_credit_amount
                expiration
                trigger
                code_words
                tags
                required_funding
                status
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.offer;
}
