import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';
import { capitalize } from '@/lib/Filters';
import { orderBy } from 'lodash-es';

export async function getCampaignsAsOptions(
	filters: QueryFilters
): Promise<{ value: string; label: string; vertical: string }[]> {
	const query = `
        query GetCampaignsAsOptions($filters: [[Any]]!) {
            campaigns(filters: $filters) {
                id
                name
                vertical_id_targeting
                product_targeting
            }
        }
    `;

	const response = await $GQL.request(query, { filters });

	const as_options = response.campaigns.map((row) => {
		return {
			value: row.id,
			label: `${capitalize(row.product_targeting)} | ${capitalize(row.vertical_id_targeting)} - ${row.name}`,
			vertical: row.vertical_id_targeting,
		};
	});

	return orderBy(as_options, ['label'], ['asc']);
}
