<template>
	<div class="attribute-section" :class="{ active: enable_language_filter }">
		<div class="header">
			<div class="title">
				<label class="clickable-label" for="condition-switch">Target by Spoken Language</label>
			</div>
			<div class="switch">
				<p-input-switch v-model="enable_language_filter" input-id="condition-switch" />
			</div>
		</div>
		<div v-if="enable_language_filter" class="filter">
			<p>Select the languages you would like to match leads for.</p>
			<ul class="options">
				<li v-for="language of language_options" :key="language.value">
					<div class="flex align-items-center">
						<p-checkbox
							v-model="custom_bid_spoken_languages"
							:input-id="language.label"
							name="spoken_language"
							:value="language.value"
						/>
						<label :for="language.label" :style="{ 'margin-bottom': 0 }">{{ language.label }}</label>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SpokenLanguageAttribute',
	emits: ['update'],
	data() {
		return {
			language_options: [
				{ label: 'English', value: 'english' },
				{ label: 'Spanish', value: 'spanish' },
			],
			enable_language_filter: false,
			custom_bid_spoken_languages: null,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const spoken_language_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.spoken_language';
			});
			if (spoken_language_match) {
				this.enable_language_filter = true;
				this.custom_bid_spoken_languages = spoken_language_match.comparator.value;
			}
		},
		saveAttribute() {
			if (this.enable_language_filter) {
				return {
					target: {
						path: 'lead.spoken_language',
					},
					strategy: 'equals_some',
					comparator: {
						value: this.custom_bid_spoken_languages,
					},
				};
			}
			return null;
		},
	},
};
</script>
