<template>
	<div class="settings-form">
		<h2>Cancellation Settings</h2>
		<template v-if="mpid !== '$NG'">
			<p-message v-if="!this.localSettingsList.includes('cancellations')" :closable="false" severity="warn"
				>These settings are controlled at the global level. To override the global settings for this marketplace only,
				<strong><a href="javascript:void(0)" @click="localizeSetting('cancellations', mpid)">click here</a></strong
				>.</p-message
			>
			<p-message :closable="false" v-else>
				The cancellations settings for this marketplace are currently overriding the global settings. To remove the
				marketplace overrides, <strong><a href="javascript:void(0)" @click="deleteLocalSetting()">click here</a></strong
				>.
			</p-message>
		</template>
		<gutter size="10px" />
		<p-block-ui :blocked="mpid !== '$NG' && !this.localSettingsList.includes('cancellations')">
			<p-fieldset legend="Lookback">
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field caption">The number of days to lookback when determining the cancellation rate.</div>
							<gutter size="20px" />
							<div class="field xs">
								<p-input-number v-model="cancellations.lookback" show-buttons></p-input-number>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
			<p-fieldset legend="Threshold">
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field caption">
								The maximum threshold for cancellations before triggering logic to either pause campaigns or disable
								cancels.
							</div>
							<gutter size="20px" />
							<div class="field xs">
								<div class="p-inputgroup flex-1">
									<p-input-number v-model="threshold"></p-input-number>
									<span class="p-inputgroup-addon">%</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
			<div class="actions">
				<p-button label="Save" icon="pi pi-check" @click="updateSetting()" />
				<gutter size="20px" />
				<p-button label="Reset" text @click="resetSetting()" />
			</div>
		</p-block-ui>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';
import { getSettings, updateSetting, localizeSetting, deleteLocalSetting, getLocalOverrideSettingsList } from '@GQL';
import pBlockUi from 'primevue/blockui';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';

export default {
	name: 'CancellationsSettings',
	components: {
		pBlockUi,
	},
	props: {
		localSettingsList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			cancellations: {
				lookback: null,
				threshold: null,
			},
			defaults: {
				lookback: null,
				threshold: null,
			},
			overrides: [],
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		threshold: {
			get() {
				return this.cancellations.threshold * 100;
			},
			set(value) {
				this.cancellations.threshold = round(value / 100, 2);
			},
		},
		...mapStores(useMarketplaceStore),
	},
	methods: {
		async localizeSetting() {
			const setting = await localizeSetting('cancellations', this.mpid);
			this.cancellations = setting.data;
			this.$emit('refresh');
		},
		async deleteLocalSetting() {
			const setting = await deleteLocalSetting('cancellations', this.mpid);
			if (setting.id) {
				await this.getSetting();
			}
			this.$emit('refresh');
		},
		async getSetting() {
			const settings_result = await getSettings(['cancellations'], this.mpid);
			this.cancellations = settings_result.settings.cancellations;
			this.defaults = cloneDeep(this.cancellations);
		},
		async updateSetting() {
			const settings_result = await updateSetting('cancellations', {
				data: this.cancellations,
			});
			if (settings_result.id === 'cancellations') {
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully saved cancellation settings',
					life: 3000,
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
				});
			}
		},
		resetSetting() {
			this.cancellations = cloneDeep(this.defaults);
		},
	},
	async mounted() {
		await this.getSetting();
		if (this.mpid == '$NG') {
			this.overrides = await getLocalOverrideSettingsList('navigation');
		}
	},
};
</script>

<style scoped lang="less">
:deep(*) {
	.p-inputnumber.short {
		max-width: 100px;
	}

	.p-inputnumber-input {
		text-align: right;
	}

	.field.caption {
		align-items: center;
		display: flex;
		height: 40px;
	}
}

.field {
	margin-bottom: 0;
}
</style>
