import { $GQL } from '@GQL/index';
import type { Role } from '@nextgenleads/auth-driver';

export async function updateRole(id: string, mpid: string, data: Partial<Role>): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateRole($id: ID!, $mpid: String!, $data: JSONObject!) {
            updateRole(id: $id, mpid: $mpid, data:$data) {
                id
				mpid
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, mpid, data });
	return result.updateRole;
}
