<template>
	<div class="network-list">
		<div class="flex align-items-center justify-content-between">
			<p-button icon="pi pi-plus" label="Add Network" @click.prevent="$router.push(`${$route.path}/new`)" />
			<p-button icon="pi pi-refresh" @click.prevent="getList(true)" />
		</div>
		<gutter size="20px" />

		<div class="card padded">
			<p-data-table ref="dt" :loading="loading" :rows="10" scrollable :value="networks">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column field="product" header="Network Name">
					<template #body="network">
						<div class="name">
							<router-link :to="{ path: `${$route.path}/${network.data.id}` }">{{ network.data.name }}</router-link>
						</div>
						<div class="sub-value">{{ network.data.id }}</div>
					</template>
				</p-column>
				<p-column class="align-center" field="id" header="URL">
					<template #body="network">
						{{ network.data.url }}
					</template>
				</p-column>
				<p-column class="align-center" field="contacts" header="Contacts">
					<template #body="network">
						<template v-if="network.data.contacts && network.data.contacts.length > 0">
							{{ network.data.contacts[0].name }} ({{ startCase(network.data.contacts[0].type) || 'Unknown' }})
						</template>
						<template v-else> No contacts </template>
					</template>
				</p-column>
				<p-column header="Tools" class="align-center">
					<template #body="row">
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Edit'"
									aria-label="Edit"
									@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
								>
									<template #icon>
										<icon type="square-edit-outline" size="20px" />
									</template>
								</p-button>
							</div>
							<delete-action
								message="Are you sure you want to delete this network?"
								@delete="deleteNetwork(row.data.id)"
							/>
						</div>
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script lang="ts">
/**
 * TODO:UI
 * all option bases filters should be multi select drop downs in the query selector
 *  -- network_id can be done later
 */
import { getNetworks, deleteNetwork } from '@GQL';
import { startCase } from 'lodash-es';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'NetworkListPage',
	components: {
		deleteAction,
	},
	data() {
		return {
			loading: false,
			networks: [],
		};
	},
	methods: {
		startCase,
		async getList(no_cache = false) {
			this.loading = true;

			let options: string[] = [];
			if (no_cache) {
				options = ['getNetworks'];
			}

			try {
				const networks = await getNetworks([], options);
				this.networks = networks;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to retrieve the network list',
					detail: err.response.message,
					life: 3000,
				});
			} finally {
				this.loading = false;
			}
		},
		async deleteNetwork(network_id) {
			try {
				await deleteNetwork(network_id);
				this.$root.toast.add({
					severity: 'success',
					summary: 'Network Deleted',
					detail: network_id,
					life: 3000,
				});
			} catch (err) {
				this.$root.toast.add({
					severity: 'error',
					summary: 'Failed to Delete Network',
					detail: err.response.data.message,
					life: 3000,
				});
			}
		},
	},
	async mounted() {
		await this.getList(true);
	},
};
</script>
