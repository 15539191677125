import { $GQL } from '@GQL/index';
import log from '@/lib/Log';
import type { GetListParams } from '@nextgenleads/db';

export async function getNotificationList(params: GetListParams, refresh?: boolean) {
	const query = `
    query GetNotificationList($params: GetListParams!){
        notificationList(params: $params) {
			row_count
            rows {
                id
                message
                filters
                type
                tags
                expires
                status
                created_by
                created_at
                created_by_user {
                    first_name
                    last_name
                }
            }
        }
    }       
    `;

	const no_cache = refresh ? ['notificationList'] : undefined;
	const result = await $GQL.request(query, {
		params,
		no_cache,
	});

	return result.notificationList;
}
