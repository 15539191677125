import { $GQL } from '@GQL';

export async function returnPurchase(purchase_id: string, reason: string, note: string): Promise<Boolean> {
	const mutation = `
    mutation ReturnPurchase($purchase_id: ID!, $reason: String, $note: String) {
        returnPurchase(id: $purchase_id, reason: $reason, note: $note)
    }
  `;

	const result = await $GQL.request(mutation, { purchase_id, reason, note });
	return result.returnPurchase;
}
