import { $GQL } from '@GQL/index';
import type { Account } from '@nextgenleads/marketplace-driver';

export async function deleteFiles(keys: string[]) {
	const mutation = `
        mutation DeleteFiles($keys: [String]!) {
            deleteFiles(keys: $keys) {
                key
				name
            }
        }
    `;

	const result = await $GQL.request(mutation, { keys });
	return result.deleteFiles;
}
