<template>
	<div class="qualifier auction-attempts-limit">
		<div class="title">
			<div class="name">Auction Attempts Limit</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">
			Disqualify a campaign from the auction if the auction attempt exceeds the limit set on the campaign or company.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label">Minimum Attempts:</label>
							<p-input-number v-model="modelValue.minimum_attempts" />
						</div>
						<div class="field">
							<label class="control-label">Maximum Lead Age:</label>
							<div class="p-inputgroup">
								<p-input-number v-model="modelValue.max_lead_age_for_reclaim" />
								<div class="p-inputgroup-addon">min</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'AuctionAttemptsLimitQualifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					minimum_attempts: 1,
					max_lead_age_for_reclaim: 30,
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
