import { $GQL } from '@GQL/index';
import type { Campaign } from '@nextgenleads/marketplace-driver';
import type { Lead } from '@nextgenleads/lead-driver';
import type { Purchase } from '@nextgenleads/broker-driver';

export async function getShipperTestData(
	params: unknown
): Promise<{ campaign: Campaign; purchase: Purchase; lead: Lead }> {
	const query = `
        query GetShipperTestData($params: JSONObject!) {
            getShipperTestData(params: $params)
        }
    `;

	const result = await $GQL.request(query, { params });
	return result.getShipperTestData;
}
