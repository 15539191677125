import { $GQL } from '@GQL/index';

export async function testMigratedShippers(account_id: string) {
	const mutation = `
        mutation TestMigratedShippers($account_id: ID!) {
            testMigratedShippers(account_id: $account_id)
        }
    `;

	const result = await $GQL.request(mutation, { account_id });
	return result.testMigratedShippers;
}
