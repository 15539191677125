import UsersList from '@/views/Users/List/Page.vue';
import UserForm from '@/views/Users/Record/Page.vue';
import RolesList from '@/views/Roles/List/Page.vue';
import RolesForm from '@/views/Roles/Record/Page.vue';
import APIKeysList from '@/views/APIKeys/List/Page.vue';
import APIKeysForm from '@/views/APIKeys/Record/Page.vue';

export default [
	{
		path: 'users',
		name: 'AccountUsersList',
		component: UsersList,
		meta: {
			parent_menu: 'Auth Service',
			title: 'Users',
			permissions: 'users',
		},
	},
	{
		path: 'users/new',
		name: 'CreateAccountUser',
		component: UserForm,
		meta: {
			new: true,
			user_type: 'account',
			parent_menu: 'Auth Service',
			title: 'New User',
			permissions: 'users',
		},
	},
	{
		path: 'users/:user_id',
		name: 'EditAccountUser',
		component: UserForm,
		meta: {
			user_type: 'account',
			parent_menu: 'Auth Service',
			title: 'Edit User',
			permissions: 'users',
		},
	},
	{
		path: 'admin-users',
		name: 'AdminUsersList',
		component: UsersList,
		meta: {
			parent_menu: 'Auth Service',
			title: 'Admin Users',
			permissions: 'admin.admin_users',
		},
	},
	{
		path: 'admin-users/new',
		name: 'CreateAdminUser',
		component: UserForm,
		meta: {
			new: true,
			user_type: 'admin',
			parent_menu: 'Auth Service',
			title: 'New Admin User',
			permissions: 'admin.admin_users',
		},
	},
	{
		path: 'admin-users/:user_id',
		name: 'EditAdminUser',
		component: UserForm,
		meta: {
			user_type: 'admin',
			parent_menu: 'Auth Service',
			title: 'Edit Admin User',
			permissions: 'admin.admin_users',
		},
	},
	{
		path: 'marketplace-users',
		name: 'MarketplaceUsersList',
		component: UsersList,
		meta: {
			parent_menu: 'Auth Service',
			title: 'Marketplace Users',
			permissions: 'admin.marketplace_users',
		},
	},
	{
		path: 'marketplace-users/new',
		name: 'CreateMarketplaceUser',
		component: UserForm,
		meta: {
			new: true,
			user_type: 'marketplace',
			parent_menu: 'Auth Service',
			title: 'New Marketplace User',
			permissions: 'admin.marketplace_users',
		},
	},
	{
		path: 'marketplace-users/:user_id',
		name: 'EditMarketplaceUser',
		component: UserForm,
		meta: {
			user_type: 'marketplace',
			parent_menu: 'Auth Service',
			title: 'Edit Marketplace User',
			permissions: 'admin.marketplace_users',
		},
	},
	{
		path: 'roles',
		name: 'RolesList',
		component: RolesList,
		meta: {
			parent_menu: 'Auth Service',
			title: 'Roles',
			permissions: 'admin.roles',
		},
	},
	{
		path: 'roles/new',
		name: 'CreateRole',
		component: RolesForm,
		meta: {
			parent_menu: 'Auth Service',
			title: 'New Role',
			permissions: 'admin.roles',
		},
	},
	{
		path: 'roles/:role_id',
		name: 'EditRole',
		component: RolesForm,
		meta: {
			parent_menu: 'Auth Service',
			title: 'Edit Role',
			permissions: 'admin.roles',
		},
	},
	{
		path: 'api-keys',
		name: 'APIKeysList',
		component: APIKeysList,
		meta: {
			parent_menu: 'Auth Service',
			title: 'API Keys',
			permissions: 'api_keys',
		},
	},
	{
		path: 'api-keys/new',
		name: 'CreateAPIKey',
		component: APIKeysForm,
		meta: {
			new: true,
			parent_menu: 'Auth Service',
			title: 'New API Key',
			permissions: 'api_keys',
		},
	},
	{
		path: 'api-keys/:api_key_id',
		name: 'EditAPIKey',
		component: APIKeysForm,
		meta: {
			parent_menu: 'Auth Service',
			title: 'Edit API Key',
			permissions: 'api_keys',
		},
	},
];
