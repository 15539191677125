<template>
	<div class="download-file hidden"></div>
</template>

<script lang="ts">
// Used to trigger a file download from a socket event
export default {
	name: 'DownloadFile',
	methods: {
		downloadFile(blob: string) {
			const link = document.createElement('a');
			var csvData = new Blob([blob], { type: 'text/csv;charset=utf-8;' });
			var csvURL = window.URL.createObjectURL(csvData);
			link.href = csvURL;
			link.download = 'report.csv';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
	},
	mounted() {
		this.$root.sessionStore.addSocketListener('downloadFile', (data) => {
			this.downloadFile(data.blob);
		});
	},
	beforeUnmount() {
		this.$root.sessionStore.removeSocketListener('downloadFile');
	},
};
</script>
