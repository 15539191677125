import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function getSourceThrottleConfigList(params: GetListParams) {
	const query = `
        query GetSourceThrottleConfigList($params: GetListParams!) {
            sourceThrottleConfigList(params: $params) {
                rows {
                    id
                    source_id
                    limit
                    interval
                    enabled
					enable_max_pings
					max_pings
					enable_max_posts
					max_posts
                    source_id
                    vertical_id
                    product
                }
                row_count
            }
        }
    `;

	const result = await $GQL.request(query, { params });
	return result.sourceThrottleConfigList;
}
