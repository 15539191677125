import { $GQL } from '@GQL/index';

export async function clearCampaignThrottling(campaign_id: string) {
	const mutation = `
        mutation ClearCampaignThrottling($campaign_id: ID!) {
            resetThrottling(id: $campaign_id)
        }
    `;

	const result = await $GQL.request(mutation, { campaign_id });
	return result.resetThrottling;
}
