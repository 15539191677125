import { $GQL } from '@GQL';
import { type Shipper } from '@nextgenleads/shipper-driver';

export async function getShipper(shipper_id: string): Promise<{ shipper: Shipper }> {
	const request = `
        query GetShipper($id: ID!) {
			shipper(id: $id) {
				id
				account_id
				name
				type
				settings
				data_map
				cancellations {
					enabled
					pattern
				}
				status
			}
        }
    `;

	return await $GQL.request(request, { id: shipper_id });
}
