import { $GQL } from '@GQL/index';
import type { OutboundRoute } from '@nextgenleads/call-driver';

export async function insertOutboundRoute(
	data: Omit<OutboundRoute, 'id' | 'created_at'>
): Promise<{ id: string; provider_account_id: string }> {
	const mutation = `
        mutation InsertOutboundRoute($data: JSONObject!) {
            insertOutboundRoute(data: $data) {
                id
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertOutboundRoute;
}
