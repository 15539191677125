<template>
	<div class="modifier nullify">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Nullify</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">
			Nullify (zero out) the purchase price of a lead for campaigns that have the <code>nullify_purchases</code> flag
			enabled.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'NullifyModifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
