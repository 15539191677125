import { $GQL } from '@GQL/index';

export async function getUser(id: string) {
	const query = `
    query GetUser($id: ID!) {
      user(id: $id) {
          id
          first_name
          last_name
          email
          phone
          title
          role_id
          account_id
          mfa_enabled
          mfa_delivery
          status
          created_at
          modified_at
		  terms {
			version
		  }
        }
    }`;

	const result = await $GQL.request(query, { id });
	return result.user;
}
