import { $GQL } from '@GQL/index';

export async function updateLTReturn(purchase_id: string, status: string) {
	const mutation = `
        mutation UpdateLTReturn($purchase_id: String!, $status: String!) {
            updateLTReturn(purchase_id: $purchase_id, status: $status)
        }
    `;

	const result = await $GQL.request(mutation, { purchase_id, status });
	return result.updateLTReturn;
}
