<template>
	<p-button icon="pi pi-plus" label="Add Modifier" @click="openModal" />
	<p-dialog v-model:visible="show_modal" class="add-modifier-modal" modal :dismissable-mask="true" style="width: 400px">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
				<strong>Add Modifier</strong>
			</div>
		</template>
		<div class="modifier-options">
			<div v-for="modifier in availableModifiers" class="modifier-option" @click="addModifier(modifier.value)">
				<div class="content-container flex align-items-center">
					<div>
						<div class="name">{{ modifier.name }}</div>
						<div class="sub-value">{{ modifier.description }}</div>
					</div>
				</div>
			</div>
			<p-message v-if="availableModifiers.length === 0" :closable="false">
				There are no other available modifiers to add.
			</p-message>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Cancel" text @click="show_modal = false" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';

export default {
	name: 'ModifierSelector',
	emits: ['addModifier'],
	components: {
		pDialog,
		pMessage,
	},
	props: {
		modifiers: {
			type: Array,
			default() {
				return [];
			},
		},
		modifierType: String,
	},
	data() {
		return {
			show_modal: false,
			modifier_options: [
				{
					name: 'Bid Group Multiplier',
					description: 'Multiply the total bid amount of a bid group by a specified multiplier.',
					allowed_on_types: ['group_sorting_bid_modifiers'],
					value: {
						script: 'BidGroupMultiplier',
						settings: {
							exclusive: 1,
							shared: 1,
						},
					},
				},
				{
					name: 'Buff Bid',
					description: 'Multiply the total bid amount of a bid group by a specified multiplier.',
					allowed_on_types: ['sorting_bid_modifiers'],
					value: {
						script: 'BuffBid',
					},
				},
				{
					name: 'Buyer Rating',
					description:
						'Multiply the bid by the buyer rating for the company. A buyer rating is the overall percentage of transfers the company accepts.',
					allowed_on_types: ['sorting_bid_modifiers'],
					value: {
						script: 'BuyerRating',
					},
				},
				{
					name: 'Cancel Rate',
					description:
						'Reduce the bid by the cancel rate for the company. The cancel rate is the overall percentage of leads that are canceled by a company.',
					allowed_on_types: ['sorting_bid_modifiers'],
					value: {
						script: 'CancelRate',
						settings: {
							minimum_multiplier: 0.7,
						},
					},
				},
				{
					name: 'Discount',
					description:
						'Discount the effective bid of a campaign by multiplying the bid by the amount set in the discount_bids flag on the campaign.',
					allowed_on_types: ['bid_modifiers', 'sorting_bid_modifiers'],
					value: {
						script: 'Discount',
					},
				},
				{
					name: 'Discount (Filter)',
					description: 'Discount the effective bid if the lead matches the customer filter.',
					allowed_on_types: ['bid_modifiers'],
					value: {
						script: 'DiscountFilter',
						settings: {
							bid_multiplier: 0.85,
							match: [
								{
									target: {
										path: '',
										transformer: null,
									},
									strategy: 'equals',
									comparator: {
										value: '',
										path: null,
										transformer: null,
										regex_flags: null,
									},
									match_missing: false,
									invert: false,
								},
							],
						},
					},
				},
				{
					name: 'Duration',
					description: 'Buff or nerf a call bid based on duration of the campaign.',
					allowed_on_types: ['sorting_bid_modifiers'],
					value: {
						script: 'Duration',
					},
				},
				{
					name: 'Multiplier',
					description: 'Multiply the campaign bid by an amount specified in the bid_multiplier flag of the campaign.',
					allowed_on_types: ['sorting_bid_modifiers'],
					value: {
						script: 'Multiplier',
					},
				},
				{
					name: 'Nerf Bid',
					description:
						"Reduce a campaign's bid by 50% x percent of the time. The percentage is set in the reduce_dominance flag of the campaign.",
					allowed_on_types: ['sorting_bid_modifiers'],
					value: {
						script: 'NerfBid',
					},
				},
				{
					name: 'Nullify',
					description:
						'Nullify (zero out) the purchase price of a lead for campaigns that have the nullify_purchases flag enabled.',
					allowed_on_types: ['bid_modifiers'],
					value: {
						script: 'Nullify',
					},
				},
				{
					name: 'Premium Listing',
					description: 'Consider the price of a Premium Listing when sorting campaign bids.',
					allowed_on_types: ['sorting_bid_modifiers'],
					value: {
						script: 'PremiumListing',
					},
				},
				{
					name: 'Return Rate',
					description:
						'Reduce the bid by the return rate for the company. The return rate is the overall percentage of leads that are returned by a company.',
					allowed_on_types: ['sorting_bid_modifiers'],
					value: {
						script: 'ReturnRate',
						settings: {
							minimum_multiplier: 0.7,
						},
					},
				},
				{
					name: 'Second Price',
					description:
						'Second price the bids for a campaign if second pricing for the campaign is not disabled and it is the highest bid in the auction.',
					allowed_on_types: ['bid_modifiers'],
					value: {
						script: 'SecondPrice',
						settings: {
							threshold: 3.5,
						},
					},
				},
				{
					name: 'TYP Ad Revenue',
					description: 'Consider the potential revenue from TYP ad clicks when sorting campaign bids.',
					allowed_on_types: ['sorting_bid_modifiers', 'group_sorting_bid_modifiers'],
					value: {
						script: 'TYPAdRevenue',
					},
				},
			],
		};
	},
	computed: {
		availableModifiers() {
			return this.modifier_options.filter((option) => {
				return (
					!this.modifiers.some((modifier) => {
						return modifier.script === option.value.script;
					}) && option.allowed_on_types.includes(this.modifierType)
				);
			});
		},
	},
	methods: {
		openModal() {
			this.show_modal = true;
		},
		addModifier(value) {
			this.$emit('addModifier', cloneDeep(value));
			this.show_modal = false;
		},
	},
};
</script>

<style scoped lang="less">
.modifier-option {
	border: 1px solid var(--gray-20);
	border-radius: 3px;
	margin-bottom: 1rem;
	padding: 1rem;

	&:hover {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-lighter);
		cursor: pointer;
	}

	.name {
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.sub-value {
		line-height: 1.35em;
	}
}
</style>
