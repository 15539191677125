import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';

export interface Route {
	path: string;
	name: string;
	component: unknown;
	meta?: Record<string, unknown>;
	children?: unknown[];
	[key: string]: unknown;
}

export default function mapNestedRoutes(routes: Route[], prefix: string, merge_values?: any) {
	return cloneDeep(routes).map((route) => {
		route.name = prefix + route.name;
		merge(route, merge_values);
		return route;
	});
}
