<template>
	<div id="live-transfer-start" class="view-content">
		<div class="page-heading">
			<h1>Live Transfer Workstation</h1>
		</div>
		<gutter size="20px" />
		<div id="live-transfer-leads-list">
			<query-form v-model:query="query" :loading="loading" @apply="getLiveTransferHistory">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Vertical:</div>
						<div class="value">
							{{ displaySelected(query.filters.vertical_id, $root.appStore.verticalOptions) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">State:</div>
						<div class="value">
							{{ displaySelected(query.filters.state, state_options) }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="getLiveTransferHistory(true)"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range
												:rangeLimit="{
													days: 62,
												}"
												v-model="form.query.filters.created_at"
												hide-labels
												show-time
											/>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group collapse">
								<div class="inner">
									<label class="control-label">Filter By Vertical:</label>
									<div class="controls">
										<div class="field">
											<div
												v-for="vertical of $root.appStore.verticalOptions"
												:key="vertical.value"
												class="p-checkbox-item flex align-items-center"
											>
												<p-checkbox
													v-model="query.filters.vertical_id"
													:input-id="vertical.value"
													name="category"
													:value="vertical.value"
												/>
												<label :for="vertical.value">{{ vertical.label }}</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</column>
						<gutter size="40px" />
						<column>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">States:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="query.filters.state"
												filter
												filter-placeholder="Filter States"
												:options="state_options"
												option-label="label"
												option-value="value"
												placeholder="Select States"
												class="state-select w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<div class="metrics">
				<row>
					<div class="column">
						<metric label="Transfers Today" :value="completed_transfers.today || 0" />
					</div>
					<gutter size="20px" />
					<div class="column">
						<metric label="Transfers This Week" :value="completed_transfers.week || 0" />
					</div>
					<gutter size="20px" />
					<div class="column">
						<metric label="Transfers This Month" :value="completed_transfers.month || 0" />
					</div>
				</row>
			</div>
			<gutter size="20px" />
			<div class="card padded">
				<p-data-table ref="dt" :loading="loading" :rows="100" scrollable :value="rows" key="refreshKey" data-key="id">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column header="Created On">
						<template #body="transfer">
							<div class="capture-date">
								{{ formatDate(new Date(transfer.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
							</div>
							<div class="sub-value">{{ timeAgo(transfer.data.created_at) }}</div>
						</template>
					</p-column>
					<p-column header="Vertical" class="column-align-center">
						<template #body="transfer">
							<vertical-icon :vertical-id="transfer.data.vertical_id" size="30px" />
						</template>
					</p-column>
					<p-column header="Lead">
						<template #body="transfer">
							{{ phoneFormat(transfer.data.lead_phone) }}
							<div class="sub-value">{{ transfer.data.lead_name }} | {{ transfer.data.lead_id }}</div>
						</template>
					</p-column>
					<p-column header="Account Info">
						<template #body="transfer">
							<div v-if="'display_name' in transfer.data">
								{{ transfer.data.display_name }}
								<div class="sub-value">{{ transfer.data.campaign_name }}</div>
							</div>
							<div v-else>
								{{ transfer.data.account_id }}
								<div class="sub-value">{{ transfer.data.campaign_id }}</div>
							</div>
						</template>
					</p-column>
					<p-column header="Buyer Phone">
						<template #body="transfer">
							{{ phoneFormat(transfer.data.phone) }}
						</template>
					</p-column>

					<p-column header="Result">
						<template #body="transfer">
							<div v-tooltip="createReasonToolTip(transfer.data)">
								{{ capitalize(transfer.data.result || 'UNKNOWN') }}
							</div>
						</template>
					</p-column>
				</p-data-table>
				<p-paginator :rows="query.pagination.page_size" :total-records="total_records" @page="handlePageChange" />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { endOfDay, startOfDay } from 'date-fns';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import STATES from '@/lib/Data/states.json';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { formatDate, displaySelected, currency, verticalName, phoneFormat, capitalize, timeAgo } from '@/lib/Filters';
import type { FilterParams, GetListParams } from '@nextgenleads/db';
import { reportLiveTransferHistory } from '@GQL';

export default {
	name: 'LiveTransferHistory',
	components: {
		dateRange,
		queryForm,
		verticalIcon,
	},
	data() {
		return {
			state_options: [...STATES],
			loading: false,
			completed_transfers: {},
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
					vertical_id: [],
					state: [],
				},
				pagination: {
					page_size: 50,
					page: 0,
				},
			},

			rows: [],
			total_records: 0,
		};
	},
	async mounted() {
		await this.getLiveTransferHistory();
	},
	methods: {
		capitalize,
		currency,
		formatDate,
		displaySelected,
		phoneFormat,
		timeAgo,
		verticalName,
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getLiveTransferHistory();
		},
		createReasonToolTip(data) {
			if (data.result === 'completed_transfer') {
				return '';
			} else if ('reason' in data) {
				return 'Reason: ' + data.reason + '\n Notes: ' + data.notes;
			}
		},
		async getLiveTransferHistory(refresh = false) {
			this.loading = true;
			try {
				const query_params = {
					agent_id: this.$root.sessionStore.user.id,
					date_range: [this.query.filters.created_at[0].toISOString(), this.query.filters.created_at[1].toISOString()],
					pagination: this.query.pagination,
					states: this.query.filters.state,
					vertical_id: this.query.filters.vertical_id,
				};

				const result = await reportLiveTransferHistory(query_params, refresh);
				this.rows = result.rows;
				this.total_records = result.row_count;
				this.completed_transfers = result.metrics; // today, week, month
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get the transfer records',
					life: 6500,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped></style>
