<template>
	<div class="update-account-status">
		<div class="control-group">
			<div class="inner">
				<label class="control-label req" for="status">Update Status:</label>
				<div class="controls gap-20 w-full">
					<div class="field m">
						<p-dropdown
							id="status"
							v-model="new_status"
							:options="account_status_options"
							placeholder="Select Status"
							option-label="label"
							option-value="value"
						/>
					</div>
					<p-button icon="pi pi-check" @click="updateAccountStatus" />
				</div>
				<div v-if="v$.status.$error" class="validation-error">
					{{ v$.status.$errors[0].$message }}
				</div>
			</div>
		</div>

		<div class="inner" v-if="new_status === 'away'">
			<label class="control-label" for="status">Date Of Return:</label>
			<div class="controls">
				<div class="field">
					<p-calendar v-model="new_date_of_return" date-format="D, M d, yy" :manual-input="false" />
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { account_status_options } from '@/lib/Options';
import { updateAccount } from '@GQL';
import pCalendar from 'primevue/calendar';

export default {
	name: 'UpdateAccountStatus',
	props: {
		account_id: {
			type: String,
			required: true,
		},
		status: {
			type: String,
			required: true,
		},
		date_of_return: {
			type: Date,
			required: false,
		},
	},
	components: {
		pCalendar,
	},
	emits: ['update'],
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			account_status_options,
			new_status: this.status,
			new_date_of_return: new Date(),
		};
	},
	methods: {
		async updateAccountStatus() {
			const is_valid = await this.v$.$validate();

			if (is_valid) {
				const update_data = {
					status: this.new_status,
				};
				if (this.new_status === 'away') {
					update_data.support = { date_of_return: this.new_date_of_return };
				}

				const account_result = await updateAccount(this.account_id, update_data);
				if (account_result.updateAccount.id) {
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully Updated Account Status',
						life: 3000,
					});
					const to_emit = { status: this.new_status };
					if (this.new_status === 'away') {
						to_emit.date_of_return = this.new_date_of_return;
					}
					this.$emit('update', to_emit);
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to Update Account Status',
						life: 3000,
					});
				}
			}
		},
	},
	validations() {
		return {
			status: {
				required: helpers.withMessage('A status is required', required),
			},
		};
	},
};
</script>
