import { $GQL } from '@GQL/index';
import log from '@/lib/Log';
import type { GetListParams, QueryFilters } from '@nextgenleads/db';

export async function getShipmentLogs(filters: QueryFilters) {
	const query = `
    query getShipmentLogs($filters: [[Any]]){
        shipmentLogs(filters: $filters) {
            id
			mpid
            shipper_id
            account {
                name
            }
            shipper {
                name
                type
            }
            purchase_id
            lead_id
            lead {
                first_name
                last_name
                phone
            }
            campaign {
                name
            }
            request
            response
            created_at
            status
        }
    }       
    `;

	const result = await $GQL.request(query, {
		filters,
	});

	return result.shipmentLogs;
}
