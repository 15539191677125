import { $GQL } from '@GQL/index';

export async function checkExistingAccountId(account_id: string): Promise<Boolean> {
	const query = `
		query CheckExistingAccountId($account_id: ID!) {
			account(id: $account_id) {
				id
			}
		}
	`;

	const result = await $GQL.request(query, { account_id });
	return !!result.account;
}
