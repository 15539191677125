<template>
	<p-breadcrumb class="pb-3" :home="home" :model="items ? items : currentPath">
		<template #item="{ label, item, props }">
			<router-link v-if="item.route" v-slot="routerProps" :to="item.route" custom>
				<a :href="routerProps.href" v-bind="props.action">
					<span v-bind="props.icon" />
					<span v-bind="props.label">{{ label }}</span>
				</a>
			</router-link>
			<a v-else :href="item.url" :target="item.target" v-bind="props.action">
				<span v-if="item.icon" v-bind="props.icon" />
				<span v-bind="props.label">{{ label }}</span>
			</a>
		</template>
		<template #separator> / </template>
	</p-breadcrumb>
</template>

<script lang="ts">
import { startCase } from 'lodash-es';
import pBreadcrumb from 'primevue/breadcrumb';
import { mapStores } from 'pinia';
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';
import { useAccountStore } from '@/stores/account';

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Breadcrumb',
	components: {
		pBreadcrumb,
	},
	props: {
		items: {
			type: Array,
		},
	},
	data() {
		return {
			home: {
				icon: 'pi pi-home',
				route: {
					name: 'Home',
				},
			},
		};
	},
	mounted() {
		this.home.route = this.homeRoute;
	},
	computed: {
		...mapStores(useAccountStore, useAppStore, useSessionStore),
		currentPath() {
			const path = this.$route.path.split('/').slice(1);
			const pathItems = [];
			path.forEach((item) => {
				let itemLabel = startCase(item);
				pathItems.push({
					label: itemLabel,
				});
			});
			return pathItems;
		},
		homeRoute() {
			const isParentAccountAdmin: boolean =
				this.sessionStore.isParentAccount ||
				this.sessionStore.isParentAccountAdmin ||
				this.sessionStore.user.role_id[this.mpid] === 'parent_account_admin';

			let path = '/campaigns';

			if (this.sessionStore.isAdminUser) {
				path = '/reports/velocity';
			}

			if (this.sessionStore.isMarketplaceUser) {
				path = '/reports/marketplace-overview';
			}

			if (this.$route.params.parent_account_id || isParentAccountAdmin) {
				path = '/reports/parent-account-overview';
			}

			return path.replace('//', '/');
		},
		mpid() {
			return this.$route.params.mpid || this.accountStore.mpid || this.appStore.mpid;
		},
	},
};
</script>

<style scoped lang="less">
:deep(.mdi-icon) {
	display: block;
}

:deep(a) {
	cursor: pointer;
	text-decoration: none !important;
}
</style>
