import { $GQL } from '@GQL/index';

export async function getNotificationReceiptsForDrawer(user_id: string) {
	const query = `
    query GetNotificationReceiptsForDrawer($user_id: ID!){
        notificationReceiptsForDrawer(user_id: $user_id) {
			row_count
            rows {
                id
                notification {
                    message
                }
                created_at
            }
        }
    }       
    `;

	const result = await $GQL.request(query, {
		user_id,
	});

	return result.notificationReceiptsForDrawer;
}
