import { $GQL } from '@GQL';
import type { Ad } from '@nextgenleads/marketplace-driver';

export async function insertAd(data: Omit<Ad, 'id' | 'created_at'>) {
	const mutation = `
        mutation InsertAd($data: JSONObject!) {
            insertAd(data: $data) {
                id
				account_id
				name
				template_id
				type
				data
				approval_status
				approved_by
				status
				created_at
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertAd;
}
