import { $GQL } from '@GQL';
import type { APIKey } from '@nextgenleads/auth-driver';

export async function insertAPIKey(data: Omit<APIKey, 'id' | 'created_at' | 'key'>) {
	const mutation = `
        mutation InsertAPIKey($data: JSONObject!) {
            insertAPIKey(data: $data) {
                name
                id
                key
                mpid
                owner_id
                role_id
                whitelisted_ips
                allowed_request_methods
                data
                status
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertAPIKey;
}
