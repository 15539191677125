<template>
	<div class="view-content">
		<query-form v-model:query="query" :loading="loading" @apply="getSourceDetailByHourReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}</div>
				</div>
				<div class="display-value">
					<div class="label">Products:</div>
					<div class="value">{{ displaySelected(query.product, product_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">States:</div>
					<div class="value">{{ displaySelected(query.state, state_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Include Call Center:</div>
					<div class="value">{{ query.include_cc ? 'Yes' : 'No' }}</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getSourceDetailByHourReport(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column max-width="450px">
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range v-model="form.query.date_range" hide-labels show-time />
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="300px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Products:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.product"
											:options="product_options"
											option-label="label"
											option-value="value"
											placeholder="Select products"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="300px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.state"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select states"
											filter
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">&nbsp;</label>
								<div class="controls">
									<div class="field fit">
										<p-checkbox input-id="include_cc" v-model="form.query.include_cc" binary />
									</div>
									<div class="field"><label for="include_cc">Include CC Purchases</label></div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="rollup-metrics">
			<metric label="Leads Generated" :value="rollupLeadsGenerated" is-number />
			<metric label="Leads Sold" :value="rollupLeadsSold" is-number />
			<metric label="Percent Sold" :value="rollupPercentSold" is-percentage />
			<metric label="Revenue" :value="rollupRevenue" is-currency />
			<metric label="RPL" :value="rollupRPL" is-currency />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				:value="report_data"
				ref="report_data_table"
				data-key="hour"
				class="report-data-table"
				sort-field="revenue"
				:sort-order="-1"
			>
				<template #empty>
					<div class="dim">No Data</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column header="Hour" sortable field="hour">
					<template #body="row">
						{{ row.data.hour }}
					</template>
				</p-column>
				<p-column header="Leads Generated">
					<template #body="row">{{ numberFormat(leadsGenerated(row.data)) }}</template>
				</p-column>
				<p-column header="Leads Sold">
					<template #body="row">{{ numberFormat(leadsSold(row.data)) }}</template>
				</p-column>
				<p-column header="Percent Sold">
					<template #body="row">{{ percentage(percentSold(row.data), 2) }}</template>
				</p-column>
				<p-column header="Revenue" sortable field="revenue">
					<template #body="row">{{ currency(revenue(row.data)) }}</template>
				</p-column>
				<p-column header="RPL" sortable field="rpl">
					<template #body="row">{{ currency(rpl(row.data)) }}</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script>
import { sumBy } from 'lodash-es';
import dayjs from 'dayjs';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import { capitalize, currency, displaySelected, formatDate, numberFormat, percentage } from '@/lib/Filters';
import { reportSourceDetailByHour } from '@GQL';
import STATES from '@/lib/Data/states.json';

export default {
	name: 'SourceDetailByHourReport',
	components: {
		dateRange,
		queryForm,
	},
	data() {
		// Round current time to the nearest 5 minutes
		const minute = Math.floor(dayjs().minute() / 5) * 5;

		return {
			loading: false,
			query: {
				date_range: [dayjs().startOf('day').toDate(), dayjs().minute(minute).second(0).toDate()],
				product: [],
				state: [],
				include_cc: true,
			},
			report_data: [],
			product_options: [
				{
					label: 'Exclusive Data Leads',
					value: 'exclusive_data',
				},
				{
					label: 'Shared Data Leads',
					value: 'shared_data',
				},
				{
					label: 'Calls',
					value: 'exclusive_call',
				},
				{
					label: 'Live Transfers',
					value: 'exclusive_live_transfer',
				},
				{
					label: 'Premium Listings',
					value: 'exclusive_ad',
				},
			],
			state_options: STATES,
		};
	},
	computed: {
		rollupLeadsGenerated() {
			return sumBy(this.report_data, (row) => {
				return this.leadsGenerated(row);
			});
		},
		rollupLeadsSold() {
			return sumBy(this.report_data, (row) => {
				return this.leadsSold(row);
			});
		},
		rollupPercentSold() {
			return this.rollupLeadsSold / this.rollupLeadsGenerated;
		},
		rollupRevenue() {
			return sumBy(this.report_data, 'total_revenue');
		},
		rollupRPL() {
			return this.rollupRevenue / this.rollupLeadsGenerated;
		},
	},
	methods: {
		capitalize,
		currency,
		displaySelected,
		formatDate,
		numberFormat,
		percentage,
		clearQuery() {
			this.loading = false;
		},
		async getSourceDetailByHourReport(refresh) {
			this.loading = true;

			try {
				const result = await reportSourceDetailByHour(this.$route.params.source_id, this.query, refresh);
				this.report_data = result;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get hourly data',
					detail: err.message,
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
		leadsGenerated(data) {
			return data.data_leads + data.calls + data.live_transfers;
		},
		leadsSold(data) {
			return data.sold_data_leads + data.sold_calls + data.sold_live_transfers;
		},
		percentSold(data) {
			return this.leadsSold(data) / this.leadsGenerated(data);
		},
		revenue(data) {
			return data.total_revenue;
		},
		rpl(data) {
			return data.total_revenue / this.leadsGenerated(data);
		},
	},
	async mounted() {
		await this.getSourceDetailByHourReport();
	},
};
</script>

<style scoped lang="less">
.rollup-metrics {
	font-weight: bold;
	display: flex;
	gap: 20px;

	.metric {
		flex: 1 0;
	}
}

:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

:deep(.nil) {
	color: #f88a7d;
	font-weight: bold;
}
</style>
