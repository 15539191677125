import { $GQL } from '@GQL/index';
import type { Shipper } from '@nextgenleads/shipper-driver';

export async function insertShipper(data: Omit<Shipper, 'id' | 'created_at'>): Promise<{ id: string }> {
	const mutation = `
        mutation InsertShipper($data: JSONObject!) {
            insertShipper(data : $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertShipper;
}
