<template>
	<div class="lt-question">
		<div class="lt-question-heading">
			<div class="lt-question-icon">
				<icon type="comment-text-outline" size="24px" />
			</div>
			<div class="lt-question-body">
				<p>Are you looking for a quote for yourself or for family?</p>
			</div>
		</div>
		<div class="lt-question-content">
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-10" style="margin-bottom: 0">
						<div class="field fit">
							<p-radio-button input-id="primary_covered_true" v-model="primary_covered" :value="true" />
						</div>
						<div class="field">
							<label for="primary_covered_true" class="radio-label"
								>This policy is for the person on the phone or their family</label
							>
						</div>
					</div>
					<div class="controls gap-10">
						<div class="field fit">
							<p-radio-button input-id="primary_covered_false" v-model="primary_covered" :value="false" />
						</div>
						<div class="field">
							<label for="primary_covered_false" class="radio-label">This policy is for someone else</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<p-dialog v-model:visible="show_abort_modal" modal style="max-width: 400px" dismissable-mask>
			<template #header>
				<div class="flex align-items-center gap-10">
					<icon type="alert-circle" size="24px" class="abort-icon" />
					<strong>Abort Transfer</strong>
				</div>
			</template>
			<div class="lt-script">
				<div class="lt-script-content">
					<div class="lt-script-icon">
						<icon type="comment-text-outline" size="24px" />
					</div>
					<div class="lt-script-body">
						<p>Not a problem! Are they available to speak on the phone?</p>
					</div>
				</div>
			</div>
			<gutter size="20px" />
			<p>
				Transfers cannot be completed if the Primary Covered is unavailable. Schedule a callback with the lead if the
				primary covered is not available.
			</p>
			<template #footer>
				<p-button text label="Do Not Abort" @click="show_abort_modal = false" />
				<p-button label="Abort Transfer" severity="danger" @click="abortTransfer()" />
			</template>
		</p-dialog>
	</div>
</template>

<script>
export default {
	name: 'LTQuestion_PrimaryCovered',
	data() {
		return {
			primary_covered: true,
			show_abort_modal: false,
		};
	},
	props: {
		loaded: {
			type: Boolean,
			required: true,
		},
	},
	watch: {
		primary_covered(new_value) {
			if (!new_value) {
				this.show_abort_modal = true;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.abort-icon {
	color: var(--red);
}
</style>
