import { $GQL } from '@GQL/index';

export async function mergeAccounts(params: object) {
	const mutation = `
        mutation MergeAccounts($params: JSONObject!) {
            mergeAccounts(params: $params)
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.mergeAccounts;
}
