import { $GQL } from '@GQL/index';

export async function migrateBalance(account_id: string) {
	const mutation = `
        mutation MigrateBalance($account_id: ID!) {
            migrateBalance(account_id: $account_id) {
				account_id
				company_id
				errors
				funds_transferred
				v3_funds
			}
        }
    `;

	const result = await $GQL.request(mutation, { account_id });
	return result.migrateBalance;
}
