<template>
	<div class="p-component p-inputlist" tabindex="-1" @focusout="setValue">
		<div class="sortable" v-sortable="sortable_options">
			<template v-for="(item, index) in local_value" :key="item.key">
				<div class="p-inputgroup sort-item">
					<div class="p-inputgroup-addon icon handle">
						<icon type="dots-vertical" size="20px" />
					</div>
					<p-input-text v-model="item.value" @keydown="hotkeyRow($event, index)" />
					<div class="p-inputgroup-addon icon button delete" @click="removeValue(index)">
						<icon type="close" size="20px" />
					</div>
				</div>
			</template>
			<div class="p-inputgroup add-button" tabindex="0" @click="addValue" @keydown="hotkeys">
				<div class="caption">{{ addLabel }}</div>
				<div class="p-inputgroup-addon icon add">
					<icon type="plus" size="20px" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { nextTick } from 'vue';
import { ulid } from 'ulid';
import pInputText from 'primevue/inputtext';
import { compact, isEqual, merge } from 'lodash-es';
import Sortable from 'sortablejs';

export default {
	name: 'InputList',
	components: {
		pInputText,
	},
	emits: ['update:modelValue'],
	props: {
		modelValue: {
			type: Array,
		},
		addLabel: {
			type: String,
			default: 'Add Value',
		},
	},
	data() {
		return {
			local_value: [],
			sortable_options: {
				draggable: '.sort-item',
				handle: '.handle',
			},
		};
	},
	watch: {
		modelValue: {
			handler(new_value, old_value) {
				if (Array.isArray(new_value) && !isEqual(new_value, old_value)) {
					this.local_value = new_value.map((row) => {
						return {
							key: ulid(),
							value: row,
						};
					});
				}
			},
			deep: true,
			immediate: true,
		},
	},
	methods: {
		setValue(event) {
			// Check if focus has left the component
			if (!this.$el.contains(event.relatedTarget)) {
				this.$emit(
					'update:modelValue',
					this.local_value.map((row) => row.value)
				);
			}
		},
		addValue() {
			this.local_value.push({
				key: ulid(),
				value: '',
			});
			nextTick(() => {
				const els = this.$el.querySelectorAll('.sort-item input');
				els[els.length - 1].focus();
			});
		},
		removeValue(index) {
			this.local_value.splice(index, 1);
		},
		sortValues(old_index, new_index) {
			const new_value = this.local_value.slice();
			new_value.splice(new_index, 0, new_value.splice(old_index, 1)[0]);
			this.$emit(
				'update:modelValue',
				new_value.map((row) => row.value)
			);
		},
		hotkeyRow(event, index) {
			if (event.metaKey && event.key === 'Backspace') {
				event.preventDefault();
				this.removeValue(index);
				nextTick(() => {
					const els = this.$el.querySelectorAll('.sort-item input');
					if (els[index]) {
						els[index].select();
					} else {
						els[index - 1].select();
					}
				});
			}
			if ((event.shiftKey || event.metaKey) && event.key === 'Enter') {
				this.local_value.splice(index + 1, 0, {
					key: ulid(),
					value: '',
				});
				nextTick(() => {
					const els = this.$el.querySelectorAll('.sort-item input');
					els[index + 1].focus();
				});
			}
		},
		hotkeys(event) {
			switch (event.key) {
				case ' ':
				case 'Enter':
					this.addValue();
					break;
			}
		},
	},
	directives: {
		sortable: {
			beforeMount(el, binding, vnode) {
				let options = merge({}, binding.value, {
					onEnd: (event) => {
						binding.instance.sortValues(event.oldIndex, event.newIndex);
					},
				});

				vnode.sortable = Sortable.create(el, options);
			},
		},
	},
};
</script>

<style scoped lang="less">
.p-inputlist:focus-within {
	box-shadow: 0 0 0 var(--focus-border-width) var(--focus-color);
}

.handle {
	cursor: ns-resize;
}

.p-inputgroup-addon {
	&.icon {
		padding: 0;
	}

	&.button {
		background-color: var(--color-b);
		border-color: var(--color-b-dark);
		cursor: pointer;

		&:hover {
			background-color: var(--color-b-light);
		}

		&.delete {
			background-color: white;
			border-color: var(--gray-25);
			border-left: 0;

			&:hover .mdi-icon {
				color: var(--red);
			}
		}
	}

	.mdi-icon {
		align-items: center;
		display: flex !important;
		height: 100%;
		justify-content: center;
		width: 100%;
	}
}

.p-inputlist,
.sortable {
	border-radius: 3px;

	.caption {
		align-items: center;
		background-color: var(--gray-10);
		border: 1px solid var(--gray-20);
		border-right: 0;
		color: var(--gray-50);
		cursor: pointer;
		display: flex;
		flex: 1 0;
		height: 2.5em;
		padding: 0 0.75em;
	}

	input {
		border-right: 0;
	}

	.p-inputgroup {
		.caption,
		.p-inputgroup-addon,
		input {
			border-bottom-width: 0;
			border-radius: 0;
		}
	}

	.p-inputgroup:first-of-type {
		.caption:first-child,
		.p-inputgroup-addon:first-child,
		input:first-child {
			border-top-left-radius: 3px;
		}

		.caption:last-child,
		.p-inputgroup-addon:last-child,
		input:last-child {
			border-top-right-radius: 3px;
		}
	}

	.p-inputgroup:last-child {
		.caption,
		.p-inputgroup-addon,
		input {
			border-bottom-width: 1px;
		}

		.caption:first-child,
		.p-inputgroup-addon:first-child,
		input:first-child {
			border-bottom-left-radius: 3px;
		}

		.caption:last-child,
		.p-inputgroup-addon:last-child,
		input:last-child {
			border-bottom-right-radius: 3px;
		}

		.p-inputgroup-addon:last-child {
			border-left: 0;
		}
	}
}

.add-button {
	.p-inputgroup-addon {
		border-left: 0;
	}

	&:hover,
	&:focus {
		.caption,
		.p-inputgroup-addon {
			background-color: var(--gray-15);
		}

		.mdi-icon {
			color: var(--color-b);
		}
	}

	&:focus {
		box-shadow: 0;
		outline: 0;
	}
}

.p-inputgroup input:focus {
	box-shadow: none;
}
</style>
