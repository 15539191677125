<template>
	<div
		:class="['show-archived', { show: modelValue }]"
		@click.prevent="$emit('update:modelValue', !modelValue)"
		v-tooltip.left="archiveMessage"
	>
		<template v-if="modelValue"><icon type="archive" size="20px" /></template>
		<template v-else><icon type="archive-off" size="20px" /></template>
	</div>
</template>

<script>
export default {
	name: 'ShowArchivedButton',
	emit: ['update:modelValue'],
	props: {
		modelValue: Boolean,
	},
	computed: {
		iconType() {
			if (this.modelValue) {
				return 'eye';
			} else {
				return 'eye-off';
			}
		},
		archiveMessage() {
			if (this.modelValue) {
				return 'Showing Archived Records';
			}
			return 'Hiding Archived Records';
		},
	},
	methods: {
		toggle() {
			this.this.$nextTick(() => {
				this.$forceUpdate();
			});
		},
	},
};
</script>

<style lang="less">
.show-archived {
	align-items: center;
	background-color: var(--gray-25);
	border-radius: 20px;
	color: white;
	cursor: default;
	display: inline-flex;
	height: 2.5em;
	padding: 0 1em;

	&.show {
		background-color: var(--color-b);
		border: 1px solid var(--color-b);
		color: white;
	}
}
</style>
