import { $GQL } from '@GQL/index';
import type { AdTemplate } from '@nextgenleads/marketplace-driver';

export async function previewAdTemplate(html: string, styles: string, scripts: string, data: Record<string, any>) {
	const mutation = `
        mutation PreviewAdTemplate($html: String, $styles: String, $scripts: String, $data: JSONObject) {
            previewAdTemplate(html: $html, styles: $styles, scripts: $scripts,  data: $data)
        }
    `;

	const result = await $GQL.request(mutation, { html, styles, scripts, data });
	return result.previewAdTemplate;
}
