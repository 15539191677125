<template>
	<div id="shipper-detail" class="view-content">
		<div class="page-heading">
			<h1>Shipper: {{ shipper.name }}</h1>
			<breadcrumb :model="breadcrumb_model" />
		</div>
		<div class="page-content">
			<row>
				<column max-width="960px">
					<div class="card padded">
						<shipper-form :shipper="shipper" />
					</div>
				</column>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { getShipper } from '@GQL';
import ShipperForm from './Form.vue';
import { mapStores } from 'pinia';
import { useSessionStore } from '@/stores/session';

export default {
	name: 'ShipperDetailView',
	components: {
		ShipperForm,
	},
	data() {
		return {
			breadcrumb_model: [{ label: 'Shippers', route: '/shippers' }, { label: this.shipper?.name || 'New Shipper' }],
			shipper: {
				id: null,
				account_id: '',
				name: '',
				type: null,
				settings: {},
				data_map: '',
				cancellations: {
					enabled: false,
					pattern: '',
				},
				status: 'active',
			},
		};
	},
	computed: {
		...mapStores(useSessionStore),
	},
	methods: {
		async initPage(shipper_id: string, duplicate: boolean) {
			const query_result = await getShipper(shipper_id);
			if (query_result.shipper) {
				this.shipper = query_result.shipper;

				if (duplicate) {
					this.shipper.id = null;
					this.shipper.name += ' (Copy)';
				}
			}
		},
	},
	async beforeRouteEnter(to, from, next) {
		if (to.query.duplicate) {
			return next(async (vm) => {
				await vm.initPage(to.query.duplicate, true);
			});
		}
		if (to.params.shipper_id) {
			return next(async (vm) => {
				await vm.initPage(to.params.shipper_id);
			});
		}
		return next();
	},
	async beforeRouteUpdate(to, from) {
		if (to.query.duplicate) {
			await vm.initPage(to.query.duplicate, true);
		}
		if (to.params.shipper_id || to.params.shipper_id !== from.params.shipper_id) {
			await this.initPage(to.params.shipper_id);
		}
	},
};
</script>
