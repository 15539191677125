<template>
	<p-dialog v-model:visible="show_modal" class="modal" :modal="true" :dismissable-mask="true">
		<template #header>
			<div class="flex align-items-center" style="gap: 10px">
				<icon type="target" size="24px" style="color: var(--color-b)" />
				<strong>KPI Targets</strong>
			</div>
		</template>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Data CPA:</label>
				<div class="controls">
					<div class="field">
						<p-input-currency v-model="kpi_targets.data_cpa" name="data_cpa" placeholder="200.00" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Call CPA:</label>
				<div class="controls">
					<div class="field">
						<p-input-currency v-model="kpi_targets.call_cpa" name="call_cpa" placeholder="200.00" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Live Transfer CPA:</label>
				<div class="controls">
					<div class="field">
						<p-input-currency v-model="kpi_targets.live_transfer_cpa" name="live_transfer_cpa" placeholder="200.00" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Products Sold:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="kpi_targets.products_sold" name="products_sold" placeholder="I.E. ACA/Limited Med" />
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button :label="'Save KPI Targets'" @click="saveKPIs" />
			</div>
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { updateAccount } from '@/lib/GQL';
export default {
	name: 'KPIModal',
	props: {
		account_id: {
			type: String,
			required: true,
		},
		kpi_targets: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			show_modal: false,
			kpi_targets: {
				data_cpa: null,
				call_cpa: null,
				live_transfer_cpa: null,
				products_sold: null,
			},
		};
	},
	methods: {
		async saveKPIs() {
			this.show_modal = false;
			try {
				await updateAccount(this.account_id, {
					support: {
						kpi_targets: this.kpi_targets,
					},
				});
				this.$toast.add({ severity: 'success', summary: 'Success', detail: 'KPI Targets Updated' });
				this.$emit('update', this.kpi_targets);
			} catch (e) {
				this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to update KPI Targets' });
				console.error(e);
			}
		},
	},
};
</script>
