import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getAPIKeys(filters?: QueryFilters) {
	const query = `
        query GetAPIKeys($filters: [[Any]]){
            apiKeys(filters: $filters){
                id
                name
                key
                mpid
                owner_id
                role_id
                status
            }
        }
    `;

	let to_send = null;
	if (filters) {
		to_send = { filters };
	}

	return (await $GQL.request(query, to_send)).apiKeys;
}
