<template>
	<div class="voice-route-step">
		<div class="header move-handle">
			<div class="header-left" @click="expanded = !expanded">
				<div class="flex align-items-center gap-10">
					<icon type="dots-vertical" size="24px" />
					<strong>Gather</strong> <span class="sub-value">Gather keypad input from the caller</span>
				</div>
				<div>
					<div v-if="!expanded" class="expand-icon">
						<icon type="arrow-expand" size="20px" />
					</div>
					<div v-if="expanded" class="expand-icon">
						<icon type="arrow-collapse" size="20px" />
					</div>
				</div>
			</div>
			<div class="header-right">
				<div class="delete-icon">
					<icon type="delete" size="20px" @click="deleteStep" />
				</div>
			</div>
		</div>
		<div v-if="expanded" class="content">
			<div class="step-options">
				<div v-if="!routeStep.params.say" class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field" @click="addSay()">
								<div class="add-button">
									<icon type="plus" size="20px" />
									<span>Add an AI voice message (this will remove any audio files)</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!routeStep.params.play" class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field" @click="addPlay()">
								<div class="add-button">
									<icon type="plus" size="20px" />
									<span>Play an audio file (this will remove any AI voice messages)</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="routeStep.params.say" class="control-group flex align-items-start">
					<div class="inner flex-1">
						<label class="control-label">Say Something:</label>
						<div class="controls">
							<div class="field">
								<p-textarea
									v-model="localRouteStep.params.say.message"
									auto-resize
									rows="3"
									style="min-height: 100px"
								/>
							</div>
						</div>
						<div class="controls gap-10">
							<div class="field">
								<p-dropdown
									v-model="localRouteStep.params.say.options.voice"
									:options="voice_options"
									option-label="label"
									option-value="value"
									placeholder="Select Voice"
								/>
							</div>
						</div>
					</div>
					<div class="remove-button" style="margin-top: 10px" v-tooltip.top="'Remove AI Voice Message'">
						<div class="icon-wrapper error" @click="removeSay()">
							<icon type="minus-circle" size="20px" />
						</div>
					</div>
				</div>
			</div>
			<div class="step-options">
				<div v-if="routeStep.params.play" class="control-group">
					<div class="inner">
						<label class="control-label">Play Audio File:</label>
						<div class="controls gap-10">
							<div class="field">
								<p-input-text v-model="localRouteStep.params.play.url" />
							</div>
							<div class="field fit">
								<custom-upload
									folder="ngl4-call-audio"
									accept="audio/*"
									@success="localRouteStep.params.play.url = $event.data[0].location"
								/>
							</div>
							<div class="field fit" v-tooltip.top="'Remove Audio File'">
								<div class="icon-wrapper remove-button" @click="removePlay()">
									<icon type="minus-circle" size="20px" />
								</div>
							</div>
						</div>
						<div v-if="localRouteStep.params.play.url" class="controls">
							<div class="field">
								<audio controls><source :src="localRouteStep.params.play.url" /></audio>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="step-options">
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label">Input Field:</label>
								<p-input-text v-model="localRouteStep.params.input_field" />
							</div>
							<div class="field xs">
								<label class="control-label">Input Length:</label>
								<p-input-text v-model="localRouteStep.params.options.numDigits" />
							</div>
							<div class="field xs">
								<label class="control-label">Timeout:</label>
								<div class="p-inputgroup">
									<p-input-text v-model="localRouteStep.params.options.timeout" />
									<div class="p-inputgroup-addon">s</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field fit">
								<p-checkbox input-id="confirm_input" binary v-model="localRouteStep.params.confirm_input" />
							</div>
							<div class="field caption">
								<label class="checkbox" for="confirm_input">Confirm Input</label>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field fit">
								<p-checkbox input-id="loop" binary v-model="localRouteStep.params.loop" />
							</div>
							<div class="field caption">
								<label class="checkbox" for="loop">Loop Continuously</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="step-options">
				<div v-if="!routeStep.params.value_map" class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field" @click="addValueMap()">
								<div class="add-button">
									<icon type="plus" size="20px" />
									<span>Add a value map</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="control-group flex align-items-start">
					<div class="inner flex-1">
						<label class="control-label">Value Map:</label>
						<div class="controls">
							<div class="field"><code-editor v-model.parse="localRouteStep.params.value_map" /></div>
						</div>
					</div>
					<div class="remove-button" style="margin-top: 10px" v-tooltip.top="'Remove Value Map'">
						<div class="icon-wrapper error" @click="removeValueMap()">
							<icon type="minus-circle" size="20px" />
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="step-options">
				<div v-if="!routeStep.params.options" class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field" @click="addOptions()">
								<div class="add-button">
									<icon type="plus" size="20px" />
									<span>Add custom gather options for Twilio</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="control-group flex align-items-start">
					<div class="inner flex-1">
						<label class="control-label">Gather Options:</label>
						<div class="controls">
							<div class="field"><code-editor v-model.parse="localRouteStep.params.options" /></div>
						</div>
					</div>
					<div class="remove-button" style="margin-top: 10px" v-tooltip.top="'Remove Twilio Options'">
						<div class="icon-wrapper error" @click="removeOptions()">
							<icon type="minus-circle" size="20px" />
						</div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import { cloneDeep, has, uniqueId } from 'lodash-es';
import customUpload from '@/components/forms/CustomUpload.vue';
import codeEditor from '@/components/forms/CodeEditor.vue';
import voice_options from '@/lib/Data/voice_options.json';
import pConfirmPopup from 'primevue/confirmpopup';

export default {
	name: 'VoiceRouteGatherHandler',
	emits: ['update', 'delete'],
	components: {
		customUpload,
		codeEditor,
		pConfirmPopup,
	},
	props: {
		routeStep: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			default_config: {
				handler: 'Gather',
				params: {
					say: {
						message: '',
						options: {
							voice: 'Google.en-US-Standard-H',
						},
					},
					input_field: null,
					loop: true,
					confirm_input: true,
					options: {
						numDigits: 5,
						timeout: 5,
					},
				},
			},
			voice_options,
			confirm_id: uniqueId(),
			expanded: false,
		};
	},
	computed: {
		localRouteStep: {
			get() {
				return this.routeStep;
			},
			set(new_value) {
				this.$emit('update', new_value);
			},
		},
	},
	methods: {
		addSay() {
			if (!has(this.routeStep.params, 'say')) {
				const new_route = cloneDeep(this.routeStep);
				new_route.params.say = {
					message: '',
					options: {
						voice: 'en-US-Standard-H',
					},
				};
				this.$emit('update', new_route);
				this.$nextTick(() => {
					this.removePlay();
				});
			}
		},
		removeSay() {
			if (has(this.routeStep.params, 'say')) {
				const new_route = cloneDeep(this.routeStep);
				delete new_route.params.say;
				this.$emit('update', new_route);
			}
		},
		addPlay() {
			if (!has(this.routeStep.params, 'play')) {
				const new_route = cloneDeep(this.routeStep);
				new_route.params.play = {
					url: '',
					options: {
						digits: '',
						loop: 1,
					},
				};
				this.$emit('update', new_route);
				this.$nextTick(() => {
					this.removeSay();
				});
			}
		},
		removePlay() {
			if (has(this.routeStep.params, 'play')) {
				const new_route = cloneDeep(this.routeStep);
				delete new_route.params.play;
				this.$emit('update', new_route);
			}
		},
		addValueMap() {
			if (!has(this.routeStep.params, 'value_map')) {
				const new_route = cloneDeep(this.routeStep);
				new_route.params.value_map = {};
				this.$emit('update', new_route);
			}
		},
		removeValueMap() {
			if (has(this.routeStep.params, 'value_map')) {
				const new_route = cloneDeep(this.routeStep);
				delete new_route.params.value_map;
				this.$emit('update', new_route);
			}
		},
		// addOptions() {
		// 	if (!has(this.routeStep.params, 'options')) {
		// 		const new_route = cloneDeep(this.routeStep);
		// 		new_route.params.options = {};
		// 		this.$emit('update', new_route);
		// 	}
		// },
		// removeOptions() {
		// 	if (has(this.routeStep.params, 'options')) {
		// 		const new_route = cloneDeep(this.routeStep);
		// 		delete new_route.params.options;
		// 		this.$emit('update', new_route);
		// 	}
		// },
		deleteStep(event) {
			this.$confirm.require({
				target: event.currentTarget,
				group: 'voice_route_builder',
				message: 'Are you sure you want to delete this route step?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: 'Delete Route Step',
				rejectLabel: 'Cancel',
				accept: async () => {
					this.$emit('delete');
				},
			});
		},
	},
};
</script>

<style lang="less" scoped>
.add-button {
	align-items: center;
	border: 1px dashed var(--gray-15);
	border-radius: 3px;
	color: var(--gray-50);
	cursor: default;
	display: flex;
	flex-direction: row;
	gap: 10px;
	height: 2.5em;
	padding: 0 1em;

	&:hover {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-light);
		color: var(--color-b);
	}
}
</style>
