<template>
	<div class="tool">
		<p-button v-tooltip.top="'Refund Transaction'" aria-label="Refund" @click="refund_modal_open = !refund_modal_open">
			<template #icon>
				<icon type="credit-card-refresh" size="20px" />
			</template>
		</p-button>
	</div>
	<p-dialog
		v-model:visible="refund_modal_open"
		class="transaction-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			root: { class: 'w-12 sm:w-9 md:w-6' },
		}"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="receipt-text" size="24px" style="color: var(--color-b)" />
				<strong>Refund Transaction</strong>
			</div>
		</template>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Refund to Payment Method:</label>
				<div class="controls">
					<div class="field">
						<strong>{{
							`${transaction.payment_method.name_on_card} (${transaction.payment_method.last_four})`
						}}</strong>
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label req">Amount to Refund:</label>
				<div class="controls">
					<div class="field">
						<p-input-currency v-model="refund_params.amount" />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Reason:</label>
				<div class="controls">
					<div class="field">
						<p-text-area v-model="refund_params.reason" />
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<p-button class="print-hidden" icon="pi pi-undo" label="Refund" @click="requestRefund(refund_params)" />
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { currency, capitalize } from '@/lib/Filters';
import pDialog from 'primevue/dialog';
import pTextArea from 'primevue/textarea';
import { refund } from '@GQL';
import type { RefundParams } from '@nextgenleads/banker-driver';
import { useSessionStore } from '@/stores/session';
import { useAppStore } from '@/stores/app';
import log from '@/lib/Log';

export default {
	name: 'RefundTransaction',
	components: {
		pDialog,
		pTextArea,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
			appStore: useAppStore(),
		};
	},
	emits: ['refresh'],
	props: {
		transaction: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			local_transaction: {},
			refund_modal_open: false,
			refund_params: {
				amount: null,
				account_id: null,
				user_id: null,
				pg_txn_id: null,
				payment_method_id: null,
				reason: '',
			},
		};
	},
	methods: {
		currency,
		capitalize,
		async requestRefund(refund_req: RefundParams) {
			try {
				const result = await refund(refund_req);
				this.$toast.add({
					severity: 'success',
					summary: 'Refund submitted',
					life: 6000,
				});
				this.refund_modal_open = !this.refund_modal_open;
				this.$emit('refresh');
			} catch (err) {
				log.trace('Unable to refund transaction', err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to refund this transaction',
					life: 6000,
				});
				this.refund_modal_open = !this.refund_modal_open;
			}
		},
	},
	mounted() {
		this.refund_params.amount = this.transaction.amount;
		this.refund_params.pg_txn_id = this.transaction.pg_txn_id;
		this.refund_params.payment_method_id = this.transaction.payment_method_id;
		this.refund_params.user_id = this.sessionStore.user.id;
		this.refund_params.account_id = this.$route.params.account_id || this.$route.params.parent_account_id;
	},
};
</script>
