<template>
	<div id="sources-list" class="view-content">
		<div class="page-heading">
			<h1>Source Throttle Configs</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="source-list">
			<query-form v-model:query="query" :loading="loading" @apply="getList" @abort="clearQuery">
				<template #display>
					<div class="display-value">
						<div class="label">Vertical:</div>
						<div class="value">
							{{ displaySelected(query.filters.vertical_id, vertical_options, 3) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Product:</div>
						<div class="value">
							{{ displaySelected(query.filters.product, product_type_options, 3) }}
						</div>
					</div>
					<div class="display-value">
						<div class="label">Search:</div>
						<div class="value">{{ source_search || 'Any' }}</div>
					</div>
					<div class="display-value">
						<div class="label">Enabled:</div>
						<div class="value">
							{{ displaySelected(query.filters.enabled, enabled_options, 3) }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" />
				</template>
				<template #form="form">
					<row>
						<column max-width="350px">
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Filter By Vertical:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="query.filters.vertical_id"
												:options="vertical_options"
												option-label="label"
												option-value="value"
												placeholder="Select Verticals"
												class="source-type-select w-full md:w-20rem"
											/>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Product Type:</label>
									<div class="controls">
										<div class="field">
											<p-multi-select
												v-model="query.filters.product"
												:options="product_type_options"
												option-label="label"
												option-value="value"
												placeholder="Select Product Type"
												class="source-type-select w-full md:w-20rem"
											>
											</p-multi-select>
										</div>
									</div>
								</div>
							</div>
						</column>
						<gutter size="20px" />
						<column max-width="350px">
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Search:</label>
									<div class="controls">
										<div class="field">
											<p-input-text
												v-model="source_search"
												class="search-query"
												type="text"
												placeholder="Search source codes"
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
						<column />
					</row>
				</template>
			</query-form>

			<div class="flex justify-content-between">
				<p-button
					icon="pi pi-plus"
					label="Add Source Throttle Config"
					@click.prevent="$router.push(`source-throttle-config/new`)"
				/>
			</div>
			<gutter size="20px" />

			<div class="card padded">
				<p-data-table
					ref="dt"
					paginator
					:loading="loading"
					:rows="100"
					scrollable
					:value="sources"
					v-model:filters="table_filters"
					:global-filter-fields="['source_id']"
				>
					<template #header>
						<div class="flex justify-content-between">
							<p-input-text
								v-model="table_filters['global'].value"
								style="width: 100%; max-width: 400px"
								placeholder="Search Source Throttle Configs..."
							/>
						</div>
					</template>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="product" header="Source ID">
						<template #body="source">
							<div class="name">
								<router-link :to="{ path: `source-throttle-config/${source.data.id}` }">{{
									source.data.source_id
								}}</router-link>
							</div>
						</template>
					</p-column>
					<p-column field="vertical_id" header="Vertical" class="column-align-center">
						<template #body="row">
							<vertical-icon :vertical-id="row.data.vertical_id" size="30px" />
						</template>
					</p-column>
					<p-column class="align-center" field="product" header="Product">
						<template #body="source">
							{{ capitalize(source.data.product) }}
						</template>
					</p-column>
					<p-column class="align-center" field="limits" header="Limits">
						<template #body="source">
							<template v-if="source.data.enabled">
								<div>
									Allow up to <strong>{{ source.data.limit }}</strong> every
									<strong>{{ source.data.interval }}</strong> seconds
								</div>
							</template>
							<template v-if="source.data.enable_max_pings">
								<div></div>
								Allow up to <strong>{{ numberFormat(source.data.max_pings) }}</strong> pings each day
							</template>
							<template v-if="source.data.enable_max_posts">
								<div>
									Allow up to <strong>{{ numberFormat(source.data.max_posts) }}</strong> posts each day
								</div>
							</template>
							<template v-if="!source.data.enabled && !source.data.enable_max_pings && !source.data.enable_max_posts">
								<div>No active limits in place</div>
							</template>
						</template>
					</p-column>
					<p-column header="Tools" class="align-center">
						<template #body="row">
							<div class="toolset">
								<div class="tool">
									<p-button
										v-tooltip.top="'Edit'"
										aria-label="Edit"
										@click="$router.push({ path: `source-throttle-config/${row.data.id}` })"
									>
										<template #icon>
											<icon type="square-edit-outline" size="20px" />
										</template>
									</p-button>
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
/**
 * TODO:UI
 * all option bases filters should be multi select drop downs in the query selector
 *  -- network_id can be done later
 */
import { vertical_options, product_type_options } from '@/lib/Options';
import { getSourceThrottleConfigList, updateSourceThrottleConfig } from '@GQL';
import { capitalize } from 'lodash-es';
import { displaySelected, numberFormat } from '@/lib/Filters';
import pPaginator from 'primevue/paginator';
import queryForm from '@/components/widgets/QueryForm.vue';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import showArchived from '@/components/widgets/ShowArchived.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { FilterMatchMode } from 'primevue/api';

export default {
	name: 'SourceThrottleConfigListPage',
	components: {
		verticalIcon,
		pPaginator,
		queryForm,
		deleteAction,
		showArchived,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Source Throttle Configs' }],
			vertical_options,
			product_type_options,
			enabled_options: [
				{ label: 'Enabled', value: true },
				{ label: 'Disabled', value: false },
			],
			source_search: '',
			query: {
				filters: {
					vertical_id: [],
					product: [],
					enabled: [],
				},
				order: {
					field: 'created_at',
					desc: true,
				},
			},
			source_throttle_configs: [],
			total_records: 0,
			loading: false,
			show_archived: false,
			table_filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
		};
	},
	async mounted() {
		await this.getList();
	},
	methods: {
		capitalize,
		displaySelected,
		numberFormat,
		async activateSourceThrottleConfig(source_id) {
			try {
				const result = await updateSourceThrottleConfig(source_id, {
					status: 'active',
				});
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'SourceThrottleConfig Activated',
						detail: 'Successfully activated source.',
						life: 3000,
					});
					// Update local UI
					this.sources.find((source) => source.source_id === source_id).status = 'active';
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to activate job config.',
					life: 6000,
				});
			}
		},
		clearQuery() {
			this.loading = false;
		},
		async getList() {
			this.loading = true;
			// convert the query.filters into QueryFilters
			const filters = [];
			Object.keys(this.query.filters).forEach((key) => {
				if (this.query.filters[key].length > 0) {
					filters.push({
						op: 'in',
						value: this.query.filters[key],
						field: key,
					});
				}
			});

			if (this.source_search) {
				filters.push(`source_id like '%${this.source_search}%'`);
			}

			try {
				const { rows, row_count } = await getSourceThrottleConfigList({ ...this.query, filters });
				this.sources = rows;
				this.total_records = row_count;
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to retrieve the source throttle config list',
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
		duplicateSourceThrottleConfig(event, source_id) {
			this.$router.push({
				path: this.$route.path + '/new',
				query: {
					source_id,
				},
			});
		},
	},
	computed: {},
};
</script>
