import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function reportMarketplaceOverview(
	params: { mpid: string; vertical_id: string[]; product: string[] },
	refresh: boolean
) {
	const query = `
        query ReportMarketplaceOverview($params: JSONObject!) {
            reportMarketplaceOverview(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportMarketplaceOverview'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportMarketplaceOverview;
}
