<template>
	<div class="card">
		<p-chart type="line" :data="chartData" :options="options" class="chart-style" />
	</div>
</template>

<script lang="ts">
import pChart from 'primevue/chart';
import { currency } from '@/lib/Filters';
import { defaultsDeep } from 'lodash-es';

export default {
	components: {
		pChart,
	},
	props: {
		chartData: {
			type: Object,
		},
		customOptions: {
			type: Object,
		},
	},
	computed: {
		options() {
			const documentStyle = getComputedStyle(document.documentElement);
			const textColor = documentStyle.getPropertyValue('--text-color');
			const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
			const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

			return defaultsDeep({}, this.customOptions, {
				maintainAspectRatio: false,
				aspectRatio: 0.6,
				plugins: {
					legend: {
						labels: {
							color: textColor,
						},
					},
				},
				scales: {
					x: {
						ticks: {
							color: textColorSecondary,
						},
						grid: {
							color: surfaceBorder,
						},
					},
					y: {
						ticks: {
							color: textColorSecondary,
						},
						grid: {
							color: surfaceBorder,
						},
					},
				},
			});
		},
	},
	methods: {
		currency,
	},
};
</script>

<style lang="less">
.chart-style {
	height: 480px;
	margin: 0 auto;
	max-width: 100%;
	position: relative;
	width: 100% !important;
	canvas {
		width: 100% !important;
	}
}
</style>
