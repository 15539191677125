import { $GQL } from '@GQL/index';
import type { Network } from '@nextgenleads/source-driver';

export async function insertNetwork(data: Omit<Network, 'created_at'>) {
	const mutation = `
        mutation InsertNetwork($data: JSONObject!) {
            insertNetwork(data: $data) {
                id
            }
        }
    `;
	return await $GQL.request(mutation, { data });
}
