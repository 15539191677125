<template>
	<div id="all-leads-list">
		<query-form v-model:query="query" :loading="loading" @apply="getPurchases" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">
						{{ formatDate(query.filters.created_at, 'ddd, MMM DD, YYYY') }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Vertical:</div>
					<div class="value">
						{{ displaySelected(query.filters.vertical_id, verticals) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Lead Type:</div>
					<div class="value">
						{{ displaySelected(query.filters.product, product_type_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Source Type:</div>
					<div class="value">
						{{ displaySelected(query.filters.source_type, source_type_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">Source Search:</div>
					<div class="value">{{ query.filters.source_search || 'Any' }}</div>
				</div>
				<div class="display-value">
					<div class="label">Status:</div>
					<div class="value">
						{{ displaySelected(query.filters.purchase_status, purchase_status_options) }}
					</div>
				</div>
				<div class="display-value">
					<div class="label">State:</div>
					<div class="value">
						{{ displaySelected(query.filters.state, state_options) }}
					</div>
				</div>
			</template>
			<template #utils>
				<p-button v-tooltip.top="'Refresh'" icon="pi pi-refresh" aria-label="Refresh" class="mr-2" />
			</template>
			<template #form="form">
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range v-model="form.query.filters.created_at" hide-labels show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Filter By Vertical:</label>
								<div class="controls">
									<div class="field">
										<div
											v-for="vertical of verticals"
											:key="vertical.value"
											class="p-checkbox-item flex align-items-center"
										>
											<p-checkbox
												v-model="query.filters.vertical_id"
												:input-id="vertical.value"
												name="category"
												:value="vertical.value"
											/>
											<label :for="vertical.value">{{ vertical.label }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Source Type:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.source_type"
											:options="source_type_options"
											option-label="label"
											option-value="value"
											placeholder="Select Source Type"
											class="source-type-select w-full md:w-20rem"
										>
										</p-multi-select>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group collapse">
							<div class="inner">
								<label class="control-label">Query By Source Code:</label>
								<div class="controls">
									<div class="field">
										<p-input-text v-model="query.filters.source_search" class="search-query" type="text" />
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.state"
											filter
											filter-placeholder="Filter States"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select States"
											class="state-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Purchase Status:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.filters.purchase_status"
											filter
											filter-placeholder="Filter Purchase Status"
											:options="purchase_status_options"
											option-label="label"
											option-value="value"
											placeholder="Select States"
											class="state-select w-full md:w-20rem"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</template>
		</query-form>
		<div class="metrics">
			<row>
				<div class="column">
					<metric label="Data Lead Count" :value="dataLeadCount" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Live Transfer Count" :value="lt_count" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Call Count" :value="call_lead_count" />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Cost Per Lead" :value="cost_per_lead" is-currency inverse-metric />
				</div>
				<gutter size="20px" />
				<div class="column">
					<metric label="Total Lead Cost" :value="total_cost" is-currency inverse-metric />
				</div>
			</row>
		</div>
		<gutter size="20px" />
		<div class="quick-filters">
			<span class="m-2">Quick Filter:</span>
			<div class="toggles">
				<a
					class="filter-toggle"
					:class="{
						active: map(filter_options, 'vertical_id').includes('health_insurance'),
					}"
					@click="handleFilterToggle({ vertical_id: 'health_insurance' })"
					>Health Insurance</a
				>
				<a
					class="filter-toggle"
					:class="{ active: map(filter_options, 'vertical_id').includes('medicare') }"
					@click="handleFilterToggle({ vertical_id: 'medicare' })"
					>Medicare</a
				>
				<a
					class="filter-toggle"
					:class="{ active: map(filter_options, 'product').includes('data') }"
					@click="handleFilterToggle({ product: 'data' })"
					>Data</a
				>
				<a
					class="filter-toggle"
					:class="{ active: map(filter_options, 'product').includes('call') }"
					@click="handleFilterToggle({ product: 'call' })"
					>Call</a
				>
				<a
					class="filter-toggle"
					:class="{ active: map(filter_options, 'product').includes('live_transfer') }"
					@click="handleFilterToggle({ product: 'live_transfer' })"
					>Live Transfer</a
				>
			</div>
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				:loading="loading"
				:rows="10"
				scrollable
				:value="filter_options.length > 0 ? filtered_leads : leads"
			>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column field="purchased" header="Purchased">
					<template #body="lead">
						<div class="purchased">
							{{ formatDate(new Date(lead.data.created_at), 'YYYY-MM-DD &bull; h:mm:ssa') }}
						</div>
						<div class="sub-value">
							{{ timeAgo(lead.data.created_at) }}
						</div>
					</template>
				</p-column>
				<p-column class="align-center" field="vertical_id" header="Vertical">
					<template #body="lead">
						<vertical-icon :key="componentKey" :vertical-id="lead.data.vertical_id" size="30px" style="margin: auto" />
					</template>
				</p-column>
				<p-column field="name" data="name" header="Lead Details">
					<template #body="lead">
						<strong>
							<router-link :to="`/leads/${lead.data.lead_id}`">{{ lead.data.lead_id }}</router-link> </strong
						><br />
						<div class="sub-value">{{ lead.data.lead_id }} &bull; {{ phoneFormat(lead.data.lead_phone) }}</div>
					</template>
				</p-column>
				<p-column class="align-center" field="product" header="Lead Type">
					<template #body="lead">
						<type-icon :key="componentKey" :type="lead.data.product" size="30px" style="margin: auto" />
					</template>
				</p-column>
				<p-column field="lead_state" header="Location">
					<template #body="lead">
						{{ lead.data.lead_state }}
						<div class="sub">{{ lead.data.lead_zip }}</div>
					</template>
				</p-column>
				<p-column field="source_id" header="Source">
					<template #body="lead">
						{{ `${lead.data.source_type} : ${lead.data.source_id}` }}
						<div class="sub">{{ lead.data.sub_id }}</div>
					</template>
				</p-column>
				<p-column field="price" header="Price">
					<template #body="lead">
						{{ currency(lead.data.price) }}
					</template>
				</p-column>
				<p-column field="status" header="Status">
					<template #body="lead">
						{{ capitalize(lead.data.status) }}
					</template>
				</p-column>
			</p-data-table>
			<gutter size="20px" />
			<p-paginator :rows="query.pagination.page_size" :total-records="row_count" @page="handlePageChange" />
		</div>
	</div>
</template>

<script lang="ts">
import { startCase, map, isEqual, filter, sumBy } from 'lodash-es';
import { endOfDay, startOfDay } from 'date-fns';
import { formatDate, displaySelected, currency, verticalName, phoneFormat, capitalize, timeAgo } from '@/lib/Filters';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import typeIcon from '@/components/widgets/TypeIcon.vue';
import STATES from '@/lib/Data/states.json';
import { source_type_options, purchase_status_options } from '@/lib/Options';
import { getPurchasesList } from '@GQL';

export default {
	name: 'AllLeadsList',
	components: {
		dateRange,
		queryForm,
		verticalIcon,
		typeIcon,
	},
	data() {
		return {
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
					vertical_id: [],
					source_type: [],
					source_search: '',
					state: [],
					purchase_status: [],
				},
				pagination: {
					page_size: 10,
					page: 0,
				},
			},
			row_count: null,
			verticals: this.$root.appStore.verticalOptions,
			state_options: STATES,
			product_type_options: [
				{ label: 'Data', value: 'data' },
				{ label: 'Call', value: 'call' },
				{ label: 'Live Transfer', value: 'live_transfer' },
				{ label: 'Ad', value: 'ad' },
			],
			source_type_options,
			purchase_status_options,
			componentKey: 0,
			filter_options: [],
			filtered_leads: [],
			leads: [],
		};
	},
	computed: {
		mpid() {
			if (this.$route.params && this.$route.params.mpid) {
				return this.$route.params.mpid;
			} else {
				return this.$root.appStore.mpid;
			}
		},
		parentAccountId() {
			return this.$route.params.parent_account_id;
		},
		accountId() {
			return this.$route.params.account_id || this.$root.sessionStore.account?.id;
		},
		dataLeadCount() {
			if (this.filter_options.length > 0) {
				return this.filtered_leads.filter((lead) => lead.product === 'data').length;
			} else {
				return this.leads.filter((lead) => lead.product === 'data').length;
			}
		},
		lt_count() {
			if (this.filter_options.length > 0) {
				return this.filtered_leads.filter((lead) => lead.product === 'live_transfer').length;
			} else {
				return this.leads.filter((lead) => lead.product === 'live_transfer').length;
			}
		},
		call_lead_count() {
			if (this.filter_options.length > 0) {
				return this.filtered_leads.filter((lead) => lead.product === 'call').length;
			} else {
				return this.leads.filter((lead) => lead.product === 'call').length;
			}
		},
		cost_per_lead() {
			const total_leads: number = this.filter_options.length > 0 ? this.filtered_leads.length : this.leads.length;
			if (this.filter_options.length > 0) {
				return sumBy(this.filtered_leads, 'price') / total_leads || 0;
			} else {
				return sumBy(this.leads, 'price') / total_leads || 0;
			}
		},
		total_cost() {
			if (this.filter_options.length > 0) {
				return sumBy(this.filtered_leads, 'price');
			} else {
				return sumBy(this.leads, 'price');
			}
		},
	},
	async created() {
		await this.getPurchases();
	},
	methods: {
		currency,
		formatDate,
		displaySelected,
		verticalName,
		startCase,
		phoneFormat,
		timeAgo,
		map,
		capitalize,
		clearQuery() {
			this.loading = false;
		},
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getPurchases();
		},
		async getPurchases() {
			this.loading = true;
			// Convert query values into QueryParams
			const purchaseListParams = {
				filters: [
					[
						{
							field: 'created_at',
							op: 'gt',
							value: this.query.filters.created_at[0],
						},
						{
							field: 'created_at',
							op: 'lt',
							value: this.query.filters.created_at[1],
						},
					],
				],
				pagination: this.query.pagination,
			};

			if (this.parentAccountId) {
				purchaseListParams.filters[0].push({
					value: this.parentAccountId,
					op: 'eq',
					field: 'parent_account_id',
				});
			}

			if (this.accountId) {
				purchaseListParams.filters[0].push({
					value: this.accountId,
					op: 'eq',
					field: 'account_id',
				});
			}

			if (this.query.filters.vertical_id.length > 0) {
				purchaseListParams.filters[0].push({
					op: 'in',
					field: 'vertical_id',
					value: this.query.filters.vertical_id,
				});
			}

			if (this.query.filters.source_type.length > 0) {
				purchaseListParams.filters[0].push({
					op: 'in',
					field: 'source_type',
					value: this.query.filters.source_type,
				});
			}

			if (this.query.filters.source_search) {
				purchaseListParams.filters[0].push({
					op: 'eq',
					field: 'source_id',
					value: this.query.filters.source_search,
				});
			}

			if (this.query.filters.state.length > 0) {
				purchaseListParams.filters[0].push({
					op: 'in',
					field: 'lead_state',
					value: this.query.filters.state,
				});
			}

			if (this.query.filters.purchase_status.length > 0) {
				purchaseListParams.filters[0].push({
					op: 'in',
					field: 'status',
					value: this.query.filters.purchase_status,
				});
			}

			try {
				const result = await getPurchasesList(purchaseListParams);
				this.row_count = result.row_count;
				this.leads = result.rows;
				this.componentKey += 1;
				await this.$nextTick();
			} catch (e) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Purchases',
				});
			} finally {
				this.loading = false;
			}
		},
		async handleFilterToggle(toggle) {
			// Disable or enable filter toggle
			const idx = this.filter_options.findIndex((item) => {
				return isEqual(item, toggle);
			});

			if (idx > -1) {
				// Remove if object already exists in options
				this.filter_options.splice(idx, 1);
			} else {
				// Replace if type or vertical is already selected, otherwise push
				const category = Object.keys(toggle);
				const categoryIdx = this.filter_options.findIndex((item) => {
					return isEqual(Object.keys(item), category);
				});
				categoryIdx > -1 ? this.filter_options.splice(categoryIdx, 1, toggle) : this.filter_options.push(toggle);
			}

			// Reduce filters to 1 object
			const combined_filter = this.filter_options.reduce((acc, item) => {
				return Object.assign({}, acc, item);
			}, {});

			// Filter out leads due to options
			this.filtered_leads = filter(this.leads, combined_filter);

			this.componentKey += 1;
			await this.$nextTick();
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.table-wrapper {
	max-width: 100%;
}

.search-query {
	max-width: 20rem;
}
</style>
