<template>
	<div class="modifier bid-group-multiplier">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Bid Group Multiplier</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">Multiply the total bid amount of a bid group by a specified multiplier.</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label">Exclusive Multiplier:</label>
							<div class="p-inputgroup">
								<p-input-number v-model="modelValue.exclusive" />
								<div class="p-inputgroup-addon">x</div>
							</div>
						</div>
						<div class="field">
							<label class="control-label">Shared Multiplier:</label>
							<div class="p-inputgroup">
								<p-input-number v-model="modelValue.shared" />
								<div class="p-inputgroup-addon">x</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'BidGroupMultiplierModifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					exclusive: 1,
					shared: 1,
				};
			},
		},
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
