<template>
	<div id="offers-form" class="view-content">
		<div class="page-heading">
			<h1>{{ $route.params.offer_id ? 'Edit' : 'Create' }} Offer</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card class="offer-card">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Offer Name:</label>
						<div class="controls">
							<div class="field">
								<p-input-text id="offer" v-model="offer.name" class="key-input flex-1" placeholder="Name" />
								<div v-if="v$.offer.name.$error" class="validation-errors">
									<div v-for="error in v$.offer.name.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Description:</label>
						<div class="controls">
							<div class="field">
								<p-text-area
									id="offer"
									v-model="offer.description"
									class="w-full"
									placeholder="Add description about this offer and who should be using it"
								/>
								<div v-if="v$.offer.description.$error" class="validation-errors">
									<div v-for="error in v$.offer.description.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label req">Trigger:</label>
								<p-dropdown
									id="status"
									v-model="offer.trigger"
									:options="trigger_options"
									placeholder="Select Trigger"
									option-label="label"
									option-value="value"
								/>
								<div v-if="v$.offer.trigger.$error">
									<p v-for="error in v$.offer.trigger.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</p>
								</div>
							</div>
							<div class="field s">
								<label class="control-label req">Required Funding:</label>
								<p-input-currency
									v-model="offer.required_funding"
									name="required_funding"
									input-id="required-funding"
								/>
								<div v-if="v$.offer.required_funding.$error">
									<p v-for="error in v$.offer.required_funding.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label req">Credit Type:</label>
								<p-dropdown
									id="credit_type"
									v-model="offer.credit_type"
									:options="credit_type_options"
									placeholder="Select Credit Type"
									option-label="label"
									option-value="value"
								/>
								<div v-if="v$.offer.credit_type.$error">
									<p v-for="error in v$.offer.credit_type.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</p>
								</div>
							</div>

							<div class="field s">
								<label class="control-label req">Bonus Amount:</label>
								<template v-if="offer.credit_type === 'flat'">
									<p-input-currency
										v-model="offer.credit_amount"
										name="credit_amount"
										placeholder="100.00"
										input-id="currency-us"
									/>
								</template>
								<template v-else>
									<p-input-percentage v-model="offer.credit_amount" name="credit_amount" placeholder="20%" />
								</template>
								<div v-if="v$.offer.credit_amount.$error">
									<p v-for="error in v$.offer.credit_amount.$errors" :key="error.$uid" class="validation-error">
										{{ error.$message }}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="offer.credit_type === 'percentage'" class="control-group">
					<div class="inner">
						<label class="control-label req">Max Credit Amount:</label>
						<div class="controls">
							<div class="field">
								<p-input-currency
									v-model="offer.max_credit_amount"
									name="max_credit_amount"
									placeholder="200"
									input-id="max_credit_amount"
								/>
							</div>
							<div class="field"></div>
						</div>
					</div>
				</div>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Offer Redemption Codes:</label>
						<p>
							These code words must be unique across all other offers, these code words can be used instead of the offer
							id when applying it to an account
						</p>
						<div class="controls">
							<div class="field">
								<p-chips add-on-blur v-model="codeWords" separator="," />
							</div>
						</div>
					</div>
				</div>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Expiration:</label>
						<div class="controls">
							<div class="field">
								<p-input-switch v-model="has_expiration" />
							</div>
							<div class="field" v-if="has_expiration">
								<date-picker v-model="selected_date" hide-label show-time label="Date of Expiration" />
							</div>
						</div>
					</div>
				</div>

				<gutter size="20px" />

				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="tags">Tags:</label>
						<div class="controls">
							<div class="field">
								<p-chips
									add-on-blur
									id="tags"
									v-model="offer.tags"
									separator=","
									placeholder="Separate by comma or by hitting enter"
								/>
							</div>
						</div>
					</div>
				</div>

				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label req">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="offer.status"
									:options="active_status_options"
									placeholder="Select Status"
									option-label="label"
									option-value="value"
								/>
							</div>
						</div>
						<div v-if="v$.offer.status.$error">
							<p v-for="error in v$.offer.status.$errors" :key="error.$uid" class="validation-error">
								{{ error.$message }}
							</p>
						</div>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-content-between align-items-center">
					<p-button severity="secondary" label="Cancel" text @click.prevent="$router.back()" />
					<p-button icon="pi pi-check" label="Save Offer" @click.prevent="submit" />
				</div>
			</template>
		</p-card>
	</div>
</template>
<script>
import { active_status_options } from '@/lib/Options';
import { helpers, required, requiredIf, minValue } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import pInputPercentage from '@/components/forms/InputPercentage.vue';
import pTextArea from 'primevue/textarea';
import { omit } from 'lodash-es';
import { getOfferById, insertOffer, updateOffer } from '@GQL';
import pChips from 'primevue/chips';

import datePicker from '@/components/forms/DatePicker.vue';

const default_offer = {
	name: '',
	description: '',
	credit_type: '',
	credit_amount: 0,
	max_credit_amount: null,
	trigger: '',
	required_funding: 0,
	status: '',
	code_words: [],
	tags: [],
};

export default {
	name: 'OfferFormPage',
	components: {
		pInputPercentage,
		pChips,
		pTextArea,
		datePicker,
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			offer: { ...default_offer },
			has_expiration: false,
			selected_date: new Date(),
			trigger_options: [
				{
					label: 'First Funding',
					value: 'first_funding',
				},
				{
					label: 'Deposit',
					value: 'deposit',
				},
				{
				  label: 'Manual', value: 'manual'
				},
			],
			credit_type_options: [
				{
					label: 'Flat',
					value: 'flat',
				},
				{
					label: 'Percentage',
					value: 'percentage',
				},
			],
			active_status_options,
			breadcrumb_items: [{ label: 'Offers' }],
		};
	},
	computed: {
		mpid() {
			return this.$route.params.mpid || this.$root.appStore.mpid;
		},
		codeWords: {
			get() {
				return this.offer.code_words;
			},
			set(new_value) {
				this.offer.code_words = new_value.map((value) => {
					return value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
				});
			},
		},
	},
	async mounted() {
		try {
			this.loading = true;
			if (!this.$route.meta.new) {
				const offer = await getOfferById(this.$route.params.offer_id);
				this.offer = { ...offer };
				if ('expiration' in this.offer && this.offer.expiration !== null) {
					this.has_expiration = true;
					this.selected_date = new Date(this.offer.expiration);
				}
				if (!this.offer.tags) {
					this.offer.tags = [];
				}
			}
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to properly initialize page',
				life: 6000,
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		async submit() {
			const valid = await this.v$.$validate();
			if (valid) {
				this.loading = true;
				try {
					let result;
					if (this.has_expiration) {
						this.offer.expiration = this.selected_date;
					} else {
						// clear it out just in case...
						this.offer.expiration = null;
					}

					if (this.offer.code_words && this.offer.code_words.length === 0) {
						this.offer.code_words = null;
					}

					if (this.offer.tags && this.offer.tags.length === 0) {
						this.offer.tags = null;
					}

					if (this.$route.meta.new) {
						this.offer.mpid = this.mpid;
						this.offer.created_by = this.$root.sessionStore.user.id;
						result = await insertOffer(this.offer);
					} else {
						result = await updateOffer(this.offer.id, omit(this.offer, 'id'));
					}

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: `Successfully ${this.$route.meta.new ? 'inserted new' : 'updated'} offer`,
							detail: `${this.offer.name}`,
							life: 5000,
						});
						// DO NAVIGATION
						this.$router.back();
					} else {
						this.$toast.add({
							severity: 'error',
							summary: `Unable to ${this.$route.meta.new ? 'update' : 'insert new'} offer`,
							detail: `${this.offer.name}`,
						});
					}
				} catch (err) {
					const msg_snippet = err.message.split(': {')[0];
					this.$toast.add({
						severity: 'error',
						summary: `Unable to ${this.$route.meta.new ? 'insert new' : 'update'} offer`,
						detail: `${msg_snippet}`,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'One or more fields are invalid',
					life: 5000,
				});
			}
		},
	},
	validations() {
		return {
			offer: {
				name: { required },
				description: { required },
				credit_type: { required },
				credit_amount: {
					required,
					minVal: helpers.withMessage(
						'Enter a dollar amount higher than $0 for flat or higher than 0.00 for percentage',
						minValue(0.01)
					),
				},
				trigger: { required },
				required_funding: {
          required,
          // minVal: helpers.withMessage('Enter a dollar value higher than $0', minValue(0.01).requiredIf(({ offer }) => offer.trigger !== 'manual')),
          minVal: helpers.withMessage(
              'Enter a dollar value higher than $0',
              (value) => {
                if (this.offer.trigger === 'manual') {
                  return true;
                }
                return value > 0.01;
              })
        },
				status: { required },
			},
		};
	},
};
</script>

<style lang="less">
.offer-card {
	max-width: 800px;
}

.description p {
	color: var(--gray-50);
}

.toggle-set {
	font-size: var(--font-size-sm);
}
</style>
