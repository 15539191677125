import { $GQL } from '@GQL/index';
import sortBy from 'lodash/sortBy';

export async function getVendorsAsOptions() {
	const filters: string[] = [];

	const query = `
        query GetVendorsAsOptions($filters: [[Any]]!) {
            vendors(filters: $filters) {
                value: id
                label: name
            }
        }
    `;

	const results = await $GQL.request(query, { filters });
	return sortBy(results.vendors, ['label']);
}
