import { $GQL } from '@GQL/index';

export async function reportLiveTransferVelocity(
	params: {
		date_range: [string, string];
		vertical_id: string[];
		business_unit: string[];
		source_id: string;
	},
	refresh: boolean
) {
	const query = `
        query ReportLiveTransferVelocity($params: JSONObject!) {
            reportLiveTransferVelocity(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportLiveTransferVelocity'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportLiveTransferVelocity;
}
