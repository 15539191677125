import { $GQL } from '@GQL/index';

export async function initSourceForm(id: string) {
	const users_filters = [
		[
			{
				field: 'account_id',
				op: 'is_null',
			},
		],
	];

	const query = `
        query InitSourceForm($id: ID!) {
            networks {
                value:id
                label:name
            }
            source(id: $id) {
                source_id
                source_hash
                sub_id_map
                network_id
                source_type
                biz_unit
                source_tags
                status
                source_manager
                description
            }
        }
    `;

	const init_result = await $GQL.request(query, { id, users_filters });
	return { ...init_result };
}
