// --------------------------------------------------
// FILTER: capitalize
// capitalize first letter in all words
// --------------------------------------------------

// Import dependencies
import { upperFirst } from 'lodash-es';

export function capitalize(input: string) {
	if (typeof input === 'string') {
		const words = input.replace(/_/g, ' ').split(' ');
		return words
			.map((word) => {
				return upperFirst(word);
			})
			.join(' ');
	} else {
		throw Error('Not a string');
	}
}
