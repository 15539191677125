import { $GQL } from '@GQL';
import { type SendPingReq, type SendPingRes } from '@nextgenleads/pingpost-driver';

export async function sendPing(params: SendPingReq): Promise<SendPingRes> {
	const mutation = `
        mutation sendPing($params: SendPingReq!) {
            sendPing(params: $params)
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.sendPing;
}
