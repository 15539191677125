<template>
	<div id="marketplaces-list" class="view-content">
		<div class="page-heading">
			<h1>Marketplaces</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-button icon="pi pi-plus" label="New Marketplace" @click.prevent="$router.push('/marketplaces/new')" />
		<gutter size="20px" />
		<div class="card padded-table">
			<marketplace-list />
		</div>
	</div>
</template>

<script lang="ts">
import MarketplaceList from './List.vue';
export default {
	name: 'MarketplacesList',
	components: {
		MarketplaceList,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Marketplaces' }],
		};
	},
};
</script>
