import { $GQL } from '@GQL/index';

export async function getDQReasons(
	params: { campaign_id: string; date_range: string[]; show_internal_reasons?: boolean },
	no_cache: string[]
): Promise<any> {
	const query = `
        query GetDQReasons($params: JSONObject!) {
            reportDQReasons(params: $params) 
        }
    `;

	const response = await $GQL.request(query, { params, no_cache });
	return response.reportDQReasons;
}
