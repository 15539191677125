import LeadsByAccountID from '@/views/DataRequests/LeadsByAccountID/Page.vue';
import DataRequests from '@/views/DataRequests/Page.vue';
import BillingHistory from '@/views/DataRequests/BillingHistory/Page.vue';
import StatusList from '@/views/DataRequests/List/Page.vue';
import mapNestedRoutes from '@/lib/Utils/mapNestedRoutes';

//@ts-ignore
export default [
	{
		path: '/data-requests',
		name: 'DataRequests',
		component: DataRequests,
		children: mapNestedRoutes(
			[
				{
					path: 'billing-history',
					name: 'BillingHistory',
					component: BillingHistory,
				},
				{
					path: 'leads-by-account-id',
					name: 'LeadsByAccountID',
					component: LeadsByAccountID,
				},
				{
					path: 'statuses',
					name: 'StatusList',
					component: StatusList,
				},
			],
			'DataRequests:',
			{}
		),
	},
];
