<template>
	<div v-if="ready" class="select-channel">
		<h2>Select a Channel</h2>
		<div class="channel-type-options">
			<div v-if="channels.length === 0">
				<h3>
					There are no channels available for this vertical & product combination, please contact your account manager
				</h3>
			</div>
			<template v-for="(channel, index) in channels" :key="index">
				<div
					class="option shadow-2"
					:class="[{ selected: selected_channel.id === channel.id }]"
					@click="selectChannel(channel)"
				>
					<div class="card-header" :style="{ 'background-image': `url(${channel.image_url})` }"></div>
					<div class="content">
						<div class="name">{{ channel.name }}</div>
						<div class="description">{{ channel.description }}</div>
						<div class="minimum-bid">Bid as low as: {{ channelMinBid(channel) }}</div>
						<p-button v-if="selected_channel.id === channel.id" class="selected w-full" label="SELECTED ✓" />
						<p-button v-else label="SELECT" class="w-full" outlined />
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import { nextTick } from 'vue';
import ActionBar from '../Components/ActionBar.vue';
import { currency } from '@/lib/Filters';
import { useCampaignStore } from '@/stores/campaign';
import { mapWritableState } from 'pinia';
import { getChannels } from '@GQL';

export default {
	name: 'SelectChannelType',
	components: {
		ActionBar,
	},
	emits: ['skipChannel', 'disableNext'],
	props: {
		skipPromotion: Boolean,
	},
	data() {
		return {
			selected_channel: {
				id: null,
			},
			channels: [],
			ready: false,
		};
	},
	computed: {
		verticalId() {
			return this.campaign.vertical_id;
		},
		isNew() {
			return this.$route.meta.new;
		},
		...mapWritableState(useCampaignStore, ['campaign', 'channel']),
	},
	async mounted() {
		this.channels = await getChannels([
			[
				`mpid IN ('$NG', '${this.campaign.mpid}')`,
				`product = '${this.campaign.product_targeting}'`,
				`vertical_id = '${this.campaign.vertical_id_targeting}'`,
				`status = 'active'`,
			],
		]);

		if (this.campaign.channel_targeting) {
			this.selectChannel(this.channel_targeting);
		} else if (this.$route.meta.new) {
			this.selectChannel(this.channels[0]);
		}

		// Skip if only one channel option exists
		if (this.channels.length < 2) {
			this.selectChannel(this.channels[0]);
			nextTick(() => {
				this.$emit('skip', 'skip_channel');
			});
		} else if (this.channels.length === 0) {
			// we cannot continue and there's no way to proceed
			this.ready = true;
			this.$emit('disableNext');
		} else {
			this.ready = true;
		}
	},
	methods: {
		prev() {
			if (this.skipPromotion) {
				return 'select_product';
			}
			return 'select_promotion';
		},
		next() {
			if (this.selected_channel.id) {
				// MAY REQUIRE OTHER CHANGES or this to be a different type of submit
				this.campaign.channel_targeting = this.selected_channel.id;
				return 'select_mode';
			} else {
				this.$root.$toast.add({
					severity: 'error',
					summary: 'No Channel Set',
					detail: 'Please select a channel for your campaign.',
					life: 3000,
				});
				return null;
			}
		},
		selectChannel(channel) {
			this.selected_channel = channel;
			this.channel = channel;
			this.$emit('select');
		},
		channelMinBid(channel) {
			const campaignStore = useCampaignStore();
			let min_bid = campaignStore.minimumBid;

			switch (channel.minimum_bid_adjustment_type) {
				case 'multiply':
					min_bid = min_bid * channel.minimum_bid_adjustment_amount;
					break;
				case 'add':
					min_bid = min_bid + channel.minimum_bid_adjustment_amount;
					break;
				default:
					min_bid = channel.minimum_bid_adjustment_amount;
					break;
			}

			return currency(min_bid);
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

h2 {
	font-size: var(--font-size-h4);
}

.select-channel {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.channel-type-options {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1rem 0;

	.mobile({
		justify-content: center;
	});

	.option {
		background: var(--white);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 20px;
		max-width: 320px;
		min-height: 320px;
		text-align: center;
		transition: all 150ms ease-out;

		img {
			margin: 0 auto;
		}

		.description,
		.bid-description,
		.minimum-bid {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
			line-height: 1.5;
			margin-bottom: 1rem;
		}

		.card-header {
			background-position: 50% 50%;
			background-size: cover;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			height: 200px;
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;
			padding: 40px;
		}

		.name {
			font-size: 1.125rem;
			font-weight: bold;
			margin: 0 0 1rem;
		}

		&.selected {
			box-shadow: 0 0 0 3px var(--color-b) !important;
		}
	}
}
</style>
