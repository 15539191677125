import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getPhoneNumberById(id: string) {
	const query = `
        query GetPhoneNumberById($id: ID!){
            phoneNumber(id: $id) {
                id
				mpid
				business_unit
				vendor_id
				provider_id
				provider_phone_id
				provider_account_id
				phone
				type
				description
				call_placement
				source_id
				sub_id
				vertical_id
				data
				capabilities
				bypass_blocklist
				blocked_by
				voice_url
				voice_fallback_url
				voice_route_template_id
				voice_route
				sms_url
				sms_fallback_url
				sms_route_template_id
				sms_route
				route_data
				status_url
				schedules {
					days
					start_time
					end_time
					sub_route
				}
				tags
				cost_tracking
				cost_type
				cost
				cost_percentage
				cost_duration
				status
				created_at
            }
        }
    `;

	const result = await $GQL.request(query, { id });

	const phone_number = result.phoneNumber;
	if (!phone_number.data) {
		phone_number.data = {};
	}
	if (!phone_number.route_data) {
		phone_number.route_data = {};
	}

	return result.phoneNumber;
}
