import AdApprovalsList from '@/views/Ads/Approvals/List/Page.vue';
import AdTemplatesList from '@/views/AdTemplates/List/Page.vue';
import AdTemplateRecord from '@/views/AdTemplates/Record/Page.vue';

export default [
	{
		path: 'ads/approvals',
		name: 'AdApprovalsList',
		component: AdApprovalsList,
		meta: {
			title: 'PL Approvals',
			permissions: 'admin.ad_approvals',
		},
	},
	{
		path: 'ad-templates/new',
		name: 'CreateAdTemplate',
		component: AdTemplateRecord,
		meta: {
			new: true,
			title: 'New Premium Listing Template',
			permissions: 'admin.ad_templates',
		},
	},
	{
		path: 'ad-templates/:ad_template_id',
		name: 'EditAdTemplate',
		component: AdTemplateRecord,
		meta: {
			title: 'Edit Premium Listing Template',
			permissions: 'admin.ad_templates',
		},
	},
	{
		path: 'ad-templates',
		name: 'AdTemplatesList',
		component: AdTemplatesList,
		meta: {
			title: 'Premium Listings',
			permissions: 'admin.ad_templates',
		},
	},
];
