<template>
	<div class="column" :style="style">
		<slot />
	</div>
</template>

<script lang="ts">
export default {
	props: {
		maxWidth: {
			type: String,
		},
		minWidth: {
			type: String,
		},
		span: {
			type: Number,
		},
		auto: {
			type: Boolean,
		},
	},
	computed: {
		style() {
			let column_size = '1 0 0';
			if (this.auto) {
				column_size = '0 0 auto';
			}

			if (this.minWidth || this.maxWidth || this.span) {
				if (this.span) {
					column_size = this.span + ' 0 0';
					if (this.auto) {
						column_size = this.span + ' 0 auto';
					}
				}

				let output = {
					flex: ['-webkit-box', '-moz-box', '-webkit', '-ms', column_size],
				};

				if (this.maxWidth) {
					output.maxWidth = this.maxWidth;
				}

				if (this.minWidth) {
					output.minWidth = this.minWidth;
				}

				return output;
			}
			return null;
		},
	},
};
</script>
