import AuctionConfigsList from '@/views/AuctionConfigs/List/Page.vue';
import AuctionConfigFormPage from '@/views/AuctionConfigs/Record/Page.vue';

export default [
	{
		path: 'auction-configs',
		name: 'AuctionConfigsList',
		component: AuctionConfigsList,
		meta: {
			parent_menu: 'Auction Service',
			title: 'Auction Configs',
			permissions: 'admin.auction_configs',
		},
	},
	{
		path: 'auction-configs/new',
		name: 'AuctionConfigCreate',
		component: AuctionConfigFormPage,
		meta: {
			new: true,
			parent_menu: 'Auction Service',
			title: 'New Auction Config',
			permissions: 'admin.auction_configs',
		},
	},
	{
		path: 'auction-configs/:auction_config_id',
		name: 'EditAuctionConfig',
		component: AuctionConfigFormPage,
		meta: {
			parent_menu: 'Auction Service',
			title: 'Edit Auction Config',
			permissions: 'admin.auction_configs',
		},
	},
];
