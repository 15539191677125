import { $GQL } from '@GQL';
import type { EmailTemplate } from '@nextgenleads/email-driver';

export async function getEmailTemplateById(email_template_id: string): Promise<{ emailTemplate: EmailTemplate }> {
	const request = `
        query GetEmailTemplate($id: ID!) {
			emailTemplate(id: $id) {
				id
				name
				subject
				from_name
				from_email
				reply_to_name
				reply_to_email
				cc
				bcc
				body
				throttle
				wrapper_id
				asm_group_id
				bypass_unsubscribes
				pdf_name
				pdf_content
				tags
				status
			}
        }
    `;

	return await $GQL.request(request, { id: email_template_id });
}
