<template>
	<div class="qualifier premium-listing">
		<div class="title">
			<div class="name">Premium Listing</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">Disqualify a campaign if it has a premium listing attached.</div>
		<div class="step-settings">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field flex align-items-center">
							<p-checkbox input-id="invert" v-model="modelValue.invert" binary />
							<label class="inline" for="invert">
								Invert this Rule<br />
								<span class="sub-value">Only allow PL campaigns to compete</span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'PremiumListingQualifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					invert: false,
				};
			},
		},
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>

<style lang="less" scoped>
label.inline {
	line-height: 1.25em;
	margin-left: 10px;

	.sub-value {
		font-weight: normal;
	}
}
</style>
