<template>
	<div id="parent-accounts" class="view-content">
		<div class="page-heading">
			<h1>Parent Accounts</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<row>
			<column>
				<p-button
					type="button"
					label="New Parent"
					icon="pi pi-plus"
					@click="$router.push({ path: `${$route.path}/new` })"
				/>
			</column>
		</row>
		<gutter size="20px" />
		<row>
			<column>
				<query-form v-model:query="query" :loading="loading" @apply="getList">
					<template #display>
						<div class="display-value">
							<div class="label">Date Range:</div>
							<div class="value">
								{{ formatDate(query.filters.created_at, 'ddd, MMM D, YYYY') }}
							</div>
						</div>
					</template>
					<template #form="form">
						<row>
							<column>
								<div class="control-group">
									<div class="inner">
										<div class="controls">
											<div class="field">
												<date-range v-model="form.query.filters.created_at" hide-labels show-time />
											</div>
										</div>
									</div>
								</div>
							</column>
						</row>
					</template>
				</query-form>
			</column>
		</row>
		<div class="card padded">
			<p-data-table :value="parent_accounts">
				<template #empty>
					<div class="dim">No data found</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<p-column field="name" header="Company Name">
					<template #body="parent_account">
						<router-link
							class="account-name row-id"
							:to="{
								path: `${$route.path}/${parent_account.data.id}/reports/parent-account-overview`,
							}"
						>
							{{ parent_account.data.name }}
						</router-link>
						<br />
						<span class="sub-value">{{ parent_account.data.id }}</span>
					</template>
				</p-column>
				<p-column
					class="align-left"
					field="balance"
					header="Childs' Balance"
					sortable
					sortField="children_total_balance"
				>
					<template #body="parent_account">
						<div v-if="mq.phone" class="mobile-th">Balance</div>
						{{ currency(parent_account.data.children_total_balance) }}
					</template>
				</p-column>
				<p-column class="align-left" field="spend" header="Childs' Spend" sortable sortField="children_total_spend">
					<template #body="parent_account">
						<div v-if="mq.phone" class="mobile-th">Children Spend</div>
						{{ currency(parent_account.data.children_total_spend) }}
					</template>
				</p-column>
				<p-column class="align-center" field="sub_accounts" header="Sub-Accounts" sortable sortField="children_count">
					<template #body="parent_account">
						<div v-if="mq.phone" class="mobile-th">Sub-Accounts</div>
						{{ parent_account.data.children_count }}
					</template>
				</p-column>
				<p-column class="align-center" field="tools">
					<template #header>
						<div style="text-align: center; width: 100%">Tools</div>
					</template>
					<template #body="parent_account">
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Edit'"
									aria-label="Edit"
									@click="$router.push({ path: `${$route.path}/${parent_account.data.id}/edit` })"
								>
									<template #icon>
										<icon type="square-edit-outline" size="20px" />
									</template>
								</p-button>
							</div>
							<!--							<div class="tool">-->
							<!--								<p-button v-tooltip.top="'Suspend'" aria-label="Suspend" @click="suspend(parent_account.data.id)">-->
							<!--									<template #icon>-->
							<!--										<icon type="cancel" size="20px" />-->
							<!--									</template>-->
							<!--								</p-button>-->
							<!--							</div>-->
							<!--							<div class="tool">-->
							<!--								<p-button v-tooltip.top="'Archive'" aria-label="Archive" @click="archive(parent_account.data.id)">-->
							<!--									<template #icon>-->
							<!--										<icon type="archive-outline" size="20px" />-->
							<!--									</template>-->
							<!--								</p-button>-->
							<!--							</div>-->
						</div>
					</template>
				</p-column>
			</p-data-table>
			<p-paginator :rows="query.pagination.page_size" :total-records="total_count" @page="handlePageChange" />
		</div>
	</div>
</template>

<script lang="ts">
import { endOfDay, startOfDay } from 'date-fns';
import { formatDate, currency } from '@/lib/Filters';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import pDataTable from 'primevue/datatable';
import pColumn from 'primevue/column';
import { getParentAccountList } from '@GQL';
import { sumBy, cloneDeep } from 'lodash-es';
import { useAppStore } from '@/stores/app';

export default {
	name: 'ParentAccountsList',
	components: {
		dateRange,
		queryForm,
		pDataTable,
		pColumn,
	},
	setup() {
		return {
			appStore: useAppStore(),
		};
	},
	inject: ['mq'],
	data() {
		return {
			breadcrumb_items: [],
			loading: false,
			query: {
				filters: {
					created_at: [startOfDay(new Date()), endOfDay(new Date())],
				},
				pagination: {
					page_size: 50,
					page: 0,
				},
			},
			total_count: 0,
			parent_accounts: [],
			parent_accounts_with_totals: [],
		};
	},
	async mounted() {
		await this.getList();
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Parent Accounts',
				},
			];
		} else {
			this.breadcrumb_items = [
				{
					label: 'Parent Accounts',
					route: '/parent-accounts',
				},
			];
		}
	},
	computed: {
		mpid() {
			return this.$route.params.mpid || this.appStore.mpid;
		},
	},
	methods: {
		calculateBalance(row) {
			return sumBy(row.childAccounts, 'balance.actual');
		},
		calculateSpend(row) {
			return sumBy(row.childAccounts, 'spend');
		},
		updateMetrics() {
			this.parent_accounts.forEach((account) => {
				account.children_total_balance = this.calculateBalance(account);
				account.children_total_spend = this.calculateSpend(account);
				account.children_count = account.childAccounts.length;
			});
		},
		async handlePageChange(data) {
			this.query.pagination.page = data.page;
			return this.getList();
		},
		async getList() {
			this.loading = true;
			const spend_filters = [
				[
					{
						field: 'created_at',
						op: 'gt',
						value: this.query.filters.created_at[0],
					},
					{
						field: 'created_at',
						op: 'lt',
						value: this.query.filters.created_at[1],
					},
					{
						field: 'status',
						op: 'in',
						value: ['complete', 'return_rejected'], // only get spend for completed purchases
					},
				],
			];
			const account_params = {
				filters: [['is_parent = true', `mpid = '${this.mpid}'`]],
				pagination: this.query.pagination,
			};

			try {
				const { rows, row_count } = await getParentAccountList(account_params, spend_filters);
				this.parent_accounts = rows;
				this.total_count = row_count;
				this.updateMetrics();
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get data',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		currency,
		formatDate,
	},
};
</script>
