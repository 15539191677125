import { $GQL } from '@GQL/index';

export async function deleteLocalSetting(id: string, mpid: string) {
	const mutation = `
        mutation DeleteLocalSetting($id: ID!, $mpid: String!) {
            deleteLocalSetting(id: $id, mpid: $mpid) {
                id
            }
        }
    `;
	const result = await $GQL.request(mutation, { id, mpid });
	return result.deleteLocalSetting;
}
