import { $GQL } from '@GQL/index';

export async function getOutboundRouteById(id: string) {
	const query = `
        query GetOutboundRoutes($id: ID!) {
            outboundRoute(id: $id) {
                id
				name
				description
				vertical_id
				phone_number_tag
				route_template_id
				route
				route_data
				created_at
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.outboundRoute;
}
