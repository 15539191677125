import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';
import { type Role } from '@nextgenleads/auth-driver';

export async function getRoles(filters?: QueryFilters, no_cache?: string[]): Promise<Partial<Role>> {
	const query = `
        query GetRoles($filters: [[Any]]) {
            roles(filters: $filters) {
                id
                name
                type
                mpid
                status
            }
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.roles;
}
