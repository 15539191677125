import { $GQL } from '@GQL/index';
import { orderBy } from 'lodash-es';

export async function getSignUpSourceIdsAsOptions() {
	const request = `
        query GetSettings($paths: [ID]!, $mpid: String) {
			settings(paths: $paths, mpid: $mpid)
        }
    `;

	const no_cache = ['settings'];

	const response = await $GQL.request(request, { paths: ['signup_source_id_options'], mpid: '$NG', no_cache });

	return orderBy(
		Object.keys(response.settings.signup_source_id_options).map((key) => {
			return {
				value: key,
				description: response.settings.signup_source_id_options[key],
			};
		}),
		['value'],
		['asc']
	);
}
