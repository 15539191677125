<template>
	<div class="qualifier channels">
		<div class="title">
			<div class="name">Concurrent Call Limit</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this qualifier?"
				tooltip="Remove Qualifier"
				@delete="removeQualifier"
			/>
		</div>
		<div class="description">
			Disqualify a campaign if the concurrent call limit on the campaign has already been reached.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ConcurrentCallLimitQualifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeQualifier() {
			this.$emit('remove');
		},
	},
};
</script>
