import { $GQL } from '@GQL/index';
import type { SendGmailReq, SendEmailRes } from '@nextgenleads/email-driver';

export async function sendGmail(params: SendGmailReq): Promise<SendEmailRes> {
	const mutation = `
        mutation SendGmail($params: SendGmailReq!) {
            sendGmail(params: $params) {
                status
                error
            }
        }
    `;
	const result = await $GQL.request(mutation, { params });
	return result.sendGmail;
}
