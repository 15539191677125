import { $GQL } from '@GQL/index';
import type { Queue } from '@nextgenleads/lead-driver';

export async function insertQueue(data: Omit<Queue, 'modified_at' | 'created_at'>): Promise<{ id: string }> {
	const mutation = `
        mutation InsertQueue($data: JSONObject!) {
            insertQueue(data: $data) {
                id
            }
        }
    `;
	const result = await $GQL.request(mutation, { data });
	return result.insertQueue;
}
