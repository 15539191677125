<template>
	<div :class="['image-uploader', { uploaded: modelValue }]">
		<template v-if="modelValue">
			<div class="remove-value">
				<delete-action solid message="Are you sure you want to remove this file?" @delete="removeValue" />
			</div>
		</template>
		<p-file-upload
			ref="upload_button"
			mode="basic"
			name="file_upload"
			:disabled="disabled"
			auto
			:multiple="multiple_files"
			:accept="accept"
			custom-upload
			@uploader="uploadFiles"
			:style="size"
		>
			<template #uploadicon>
				<div class="dropzone" @drop.prevent="uploadFiles" @dragenter.prevent @dragover.prevent>
					<template v-if="uploading">
						<p-progress-bar :value="upload_progress * 100" :showValue="false" />
					</template>
					<template v-else>
						<div class="preview" v-if="modelValue">
							<img :src="modelValue" alt="Uploaded file" />
						</div>
						<template v-else>
							<alt-icon type="upload-file" size="60px" />
							<div>Click or drop file here to upload</div>
						</template>
					</template>
				</div>
			</template>
		</p-file-upload>
		<div v-if="showUrl && modelValue" v-tooltip.top="modelValue" class="url" @click.stop="copyUrlToClipboard">
			<div class="value">{{ modelValue }}</div>
			<div class="icon-wrapper"><icon type="content-copy" size="24px" /></div>
		</div>
	</div>
</template>

<script>
import copy from 'copy-to-clipboard';
import pFileUpload from 'primevue/fileupload';
import pProgressBar from 'primevue/progressbar';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import { $GQL } from '@GQL';
import log from '@/lib/Log';

export default {
	name: 'CustomUpload',
	components: {
		pFileUpload,
		pProgressBar,
		deleteAction,
	},
	props: {
		accept: {
			type: String,
			default: 'image/*',
		},
		disabled: Boolean,
		filename: {
			type: String,
		},
		folder: {
			type: String,
		},
		height: {
			type: String,
			default: '200px',
		},
		multiple_files: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: [String, null],
			default: null,
		},
		showUrl: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String,
			default: '100%',
		},
	},
	emits: ['update:modelValue', 'success'],
	data() {
		return {
			uploading: false,
			upload_progress: 0,
		};
	},
	computed: {
		size() {
			return {
				minHeight: this.height,
				width: this.width,
			};
		},
	},
	methods: {
		async uploadFiles(event) {
			this.upload_progress = 0;
			this.uploading = true;

			let files = event.files || event.dataTransfer.files;

			let folder = this.folder || '';
			let folder_prefix = '';
			if (!this.$root.sessionStore.isAdminUser) {
				folder_prefix += this.$root.appStore.mpid + '/';
			} else if (this.$route.params.mpid) {
				folder_prefix += this.$route.params.mpid + '/';
			}

			if (!this.$root.sessionStore.isAccountUser && this.$route.params.account_id) {
				folder_prefix += this.$route.params.account_id + '/';
			} else if (!this.$root.sessionStore.isAccountUser && this.$route.params.parent_account_id) {
				folder_prefix += this.$route.params.parent_account_id + '/';
			} else if (this.$root.sessionStore.account.id) {
				// Default to the user's account folder
				folder_prefix += this.$root.sessionStore.account.id + '/';
			}

			// Add the prfix to the folder
			folder = folder_prefix + folder;

			try {
				const result = await $GQL.upload(files, folder, this.filename, this.updateProgress);
				if (result && result.data) {
					let detail = 'No files were uploaded';
					if (result.data.length === 1) {
						detail = '1 file was uploaded';
						this.$emit('update:modelValue', result.data[0].location);
					}
					if (result.data.length > 1) {
						detail = `${result.data.length} files were uploaded`;
					}
					this.$toast.add({
						severity: 'success',
						summary: 'File Uploaded Successfully',
						detail,
						life: 3000,
					});
					this.$emit('success', result.data);
				}
			} catch (err) {
				log.error('Image Upload Error', 'error', { page: 'ImageUpload', error: err });
				this.$toast.add({
					severity: 'error',
					summary: 'Something went wrong',
					detail: `Unable to upload file: ${err.message}`,
					life: 3000,
				});
			} finally {
				this.uploading = false;
			}
		},
		removeValue() {
			this.$emit('update:modelValue', null);
		},
		updateProgress(event) {
			this.upload_progress = event.progress;
		},
		copyUrlToClipboard(event) {
			copy(this.modelValue);
			this.$toast.add({
				severity: 'info',
				summary: 'The URL was copied to the clipboard',
				detail: this.modelValue,
				life: 3000,
			});
		},
	},
};
</script>

<style scoped lang="less">
:deep(.p-fileupload) {
	line-height: 0;
}

.image-uploader {
	border: 2px dashed var(--gray-20);
	border-radius: 5px;
	color: var(--gray-40);
	padding: 5px;
	position: relative;

	&.uploaded {
		border: 2px solid var(--gray-20);
	}

	.remove-value {
		background-color: var(--red);
		border-radius: 10px;
		position: absolute;
		right: 15px;
		top: 15px;
		z-index: 100;

		.mdi-icon {
			color: var(--white);
		}
	}

	.url {
		cursor: pointer;
		display: flex;
		flex-direction: row;
		height: 40px;
		line-height: 40px;
		padding: 0 0 0 0.75rem;

		.value {
			display: block;
			flex: 1 0;
			max-width: 100%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.icon-wrapper {
			flex: 0 0 auto;
		}
	}
}

.dropzone {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	width: 100%;

	.material-symbols-outlined {
		margin-bottom: 20px;
	}

	.preview {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		width: 100%;

		img {
			display: block;
			max-width: 100%;
		}
	}

	.p-progressbar {
		height: 5px;
		width: 90%;
	}
}

:deep(.p-button.p-fileupload-choose) {
	background-color: transparent;
	border: 0;
	color: var(--gray-35);
	padding: 0;

	.p-button-label {
		display: none;
	}
}
</style>
