import Files from '@/views/Files/Page.vue';

export default [
	{
		name: 'Files',
		path: 'files',
		component: Files,
		meta: {
			title: 'Files',
			permissions: 'admin.files',
		},
	},
];
