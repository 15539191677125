<template>
	<div class="settings-form">
		<h2>Merge Accounts</h2>
		<gutter size="10px" />
		<template v-if="matching_accounts.length > 0">
			<p>
				There are other accounts with users that have similar phone numbers as this one. This account may be a
				duplicate. You can merge this account into one of the previous accounts below.
			</p>
			<div class="matching-accounts">
				<div v-for="account in matching_accounts" class="matching-account">
					<div class="account-info">
						<div class="name">{{ account.name }}</div>
						<div class="sub-value">{{ account.id }}</div>
					</div>
					<div class="created-at">
						{{ formatDate(account.created_at, 'YYYY-MM-DD [&bull;] h:mmA') }}
						<div class="sub-value">{{ timeAgo(account.created_at) }}</div>
					</div>
					<div class="merge-button">
						<p-button @click="openMergeDialog(account)" v-tooltip="'Merge into this Account'">
							<template #icon>
								<icon type="merge" size="20px" />
							</template>
						</p-button>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<p-message :closable="false">This account does not match any other accounts and cannot be merged.</p-message>
		</template>
		<p-dialog modal v-model:visible="show_merge_dialog" style="max-width: 600px">
			<template #header>
				<div class="flex align-items-center">
					<icon type="merge" size="20px" style="margin-right: 10px" /> <strong>Merge Accounts</strong>
				</div>
			</template>
			<p>
				Continuing this action will merge the account with ID <strong>{{ accountId }}</strong> into the account with ID
				<strong>{{ selected_account.id }}</strong
				>.
			</p>
			<p>Merging accounts will do the following:</p>
			<ul class="bulleted">
				<li>
					Move all purchases to <strong>{{ selected_account.id }}</strong>
				</li>
				<li>
					Move all campaigns to <strong>{{ selected_account.id }}</strong>
				</li>
				<li>
					Move all shippers to <strong>{{ selected_account.id }}</strong>
				</li>
				<li>
					Move all premium listings to <strong>{{ selected_account.id }}</strong>
				</li>
				<li>
					Move all payment methods to <strong>{{ selected_account.id }}</strong>
				</li>
				<li>
					Move all users to <strong>{{ selected_account.id }}</strong>
				</li>
				<li>
					Transfer funds to <strong>{{ selected_account.id }}</strong>
				</li>
				<li>
					Delete or archive <strong>{{ accountId }}</strong>
				</li>
			</ul>
			<p-message severity="warn" :closable="false">This action is not reversible!</p-message>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Which name would you like to keep?</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								v-model="new_account_name"
								:options="accountNameOptions"
								option-label="label"
								option-value="value"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Type MERGE ACCOUNTS to continue:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="confirm" />
							<div v-if="v$.confirm.$error" class="validation-error">
								{{ v$.confirm.$errors[0].$message }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<p-button text @click="show_merge_dialog = false">CANCEL</p-button>
				<gutter size="10px" />
				<p-button severity="warning" @click="mergeAccounts()">MERGE ACCOUNTS</p-button>
			</template>
		</p-dialog>
	</div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import { formatDate, timeAgo } from '@/lib/Filters';
import { getAccountSettings, mergeAccounts } from '@GQL';
import { useAccountStore } from '@/stores/account';
import { mapStores } from 'pinia';
import { helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

export default {
	name: 'MergeAccountsSettings',
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			matching_accounts: [],
			new_account_name: null,
			selected_account: {},
			show_merge_dialog: false,
			confirm: null,
		};
	},
	computed: {
		accountId() {
			return this.$route.params.account_id;
		},
		accountNameOptions() {
			return [
				{
					label: this.selected_account.name,
					value: this.selected_account.name,
				},
				{
					label: this.accountStore.account.name,
					value: this.accountStore.account.name,
				},
			];
		},
		...mapStores(useAccountStore),
	},
	validations: {
		confirm: {
			exactMatch: helpers.withMessage('You must type MERGE ACCOUNTS to complete this action', (value) => {
				return value === 'MERGE ACCOUNTS';
			}),
		},
	},
	async beforeMount() {
		const result = await getAccountSettings(this.accountId);
		this.matching_accounts = result.account.support.matching_accounts || [];
	},
	methods: {
		formatDate,
		timeAgo,
		openMergeDialog(account) {
			this.confirm = null;
			this.selected_account = account;
			this.new_account_name = this.selected_account.name;
			this.show_merge_dialog = true;
		},
		async mergeAccounts() {
			const is_valid = await this.v$.$validate();

			if (is_valid) {
				try {
					const result = await mergeAccounts({
						merge_from: this.accountId,
						merge_to: this.selected_account.id,
						user_id: this.$root.sessionStore.user.id,
						account_name: this.new_account_name,
					});

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: 'SUCCESS',
							detail: 'Successfully merged accounts. Redirecting you to the merged account...',
							life: 5000,
						});

						setTimeout(() => {
							const account_mpid = this.selected_account.id.split('-')[0];
							this.$router.push(
								`/marketplaces/manage/${account_mpid}/accounts/manage/${this.selected_account.id}/reports/account-activity`
							);
						}, 3000);
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'ERROR',
						detail: 'Unable to merge companies. Please contact dev support.',
						life: 5000,
					});
				}
			}
		},
	},
};
</script>

<style scoped lang="less">
.matching-account {
	align-items: center;
	border: 1px solid var(--gray-15);
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	line-height: 1.35em;
	padding: 20px;

	.name {
		font-weight: bold;
	}
}
</style>
