import { $GQL } from '@GQL/index';
import type { Channel } from '@nextgenleads/source-driver';

export async function getChannelById(channel_id: string): Promise<Channel> {
	const query = `
        query GetChannelById($channel_id: ID!) {
            channel(id: $channel_id) {
                id
				mpid
                name
                description
				image_url
				vertical_id
                product
				match
				minimum_bid_adjustment_type
				minimum_bid_adjustment_amount
				minimum_duration
				maximum_duration
                status
            }
        }
    `;

	const result = await $GQL.request(query, { channel_id });
	return result.channel;
}
