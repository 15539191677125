<template>
	<div :class="['attribute-section filter', { active: custom_bid_medicare }]">
		<div class="control-group">
			<div class="inner">
				<div class="controls">
					<div class="field fit">
						<p-checkbox v-model="custom_bid_medicare" input-id="medicare" binary />
					</div>
					<gutter size="10px" />
					<div class="field">
						<label class="large-label" for="medicare">
							<div>Interest in Medicare or Medicaid</div>
							<div class="sub-value">
								Match leads that say they are currently enrolled in or interested in Medicare or Medicaid.
							</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InterestInMedicareAttribute',
	emits: ['update'],
	data() {
		return {
			custom_bid_medicare: false,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const medicare_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.interested_in_medicare';
			});
			if (medicare_match) {
				this.custom_bid_medicare = true;
			}
		},
		saveAttribute() {
			if (this.custom_bid_medicare) {
				return {
					target: {
						path: 'lead.data.interested_in_medicare',
					},
					strategy: 'equals',
					comparator: {
						value: true,
					},
				};
			}
			return null;
		},
	},
};
</script>
