<template>
	<div class="balance-widget">
		<icon type="bank" v-tooltip.top="'Current Balance'" size="20px" class="bank-icon" />
		<transition name="flip">
			<span :key="balance">
				{{ currency(balance) }}
			</span>
		</transition>
		<icon type="plus" v-tooltip.top="'Add Funds'" @click.prevent="handleFundsModal" class="add-icon" size="24px" />
	</div>
	<add-funds-modal ref="add_funds_modal" />
</template>

<script lang="ts">
import { debounce } from 'lodash-es';
import { currency } from '@/lib/Filters';
import { depositFunds, getPaymentMethodsAsOptions, getBalances } from '@GQL';
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';
import addFundsModal from '@/views/AddFunds/Modal.vue';
import log from '@/lib/Log';

export default {
	name: 'BalanceWidget',
	components: {
		addFundsModal,
	},
	async setup() {
		return {
			sessionStore: useSessionStore(),
			appStore: useAppStore(),
		};
	},
	data() {
		return {
			loading: false,
			add_balance: 0,
			payment_method_options: [],
			payment_method_id: '',
			balance: 0,
		};
	},
	computed: {
		fundingClass() {
			return {
				funded: this.balance > 100,
				low: this.balance <= 100,
			};
		},
		accountId() {
			return (
				this.$route.params.account_id ||
				this.$route.params.parent_account_id ||
				this.sessionStore.user.account_id ||
				`${this.appStore.mpid}-000-000`
			);
		},
	},
	async mounted() {
		await this.reset();
		try {
			const balance_result = await getBalances(this.accountId);
			this.balance = balance_result.actual;
		} catch (err) {
			log.trace('Error getting balance', err);
			this.$toast.add({
				severity: 'error',
				summary: 'An error occurred getting balance',
				life: 6000,
			});
		}
		this.sessionStore.addSocketListener('balanceUpdate', (data) => {
			this.balance = data.balance;
		});
	},
	methods: {
		currency,
		async reset() {
			this.add_balance = 0;
			this.payment_method_id = '';
			this.$refs.add_funds_modal.funds_modal_open = false;
			this.payment_method_options = await getPaymentMethodsAsOptions(this.accountId);
		},
		async handleFundsModal() {
			await this.reset();
			this.$refs.add_funds_modal.funds_modal_open = !this.$refs.add_funds_modal.funds_modal_open;
		},
		addFunds: debounce(async function () {
			this.loading = true;
			// Take the payment_method_id and make sale request....
			try {
				const response = await depositFunds({
					payment_method_id: this.payment_method_id,
					account_id: this.accountId,
					user_id: this.sessionStore.user.id,
					amount: this.add_balance,
				});

				if ('txn_id' in response) {
					this.$toast.add({
						severity: 'info',
						summary: 'Funds have been added',
						life: 3000,
					});
				} else {
					throw new Error('Txn failed');
				}
				this.$refs.add_funds_modal.funds_modal_open = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Transaction was not saved',
					detail: err.response.errors[0]?.message || err.message,
				});
			} finally {
				this.loading = false;
			}
		}, 1000),
		goToPaymentMethods() {
			this.$router.push('/payment-methods');
			this.$refs.add_funds_modal.funds_modal_open = !this.$refs.add_funds_modal.funds_modal_open;
		},
	},
};
</script>

<style lang="less" scoped>
.balance-widget {
	align-items: center;
	background-color: rgb(255 255 255 / 85%);
	border: 1px solid var(--gray-30);
	border-radius: 2rem;
	display: flex;
	font-size: var(--font-size-sm);
	gap: 1rem;
	justify-content: space-between;
	padding: 0.5rem 1rem;
}

.bank-icon {
	color: var(--gray-80);
}

.add-icon {
	&:hover {
		cursor: pointer;
	}
	background-color: var(--color-b);
	border: 1px solid var(--gray-30);
	border-radius: 4px;
	color: var(--white);
}

.label {
	flex: 1;
	font-size: var(--font-size-sm);

	.description {
		color: var(--gray-50);
		line-height: 1.3;
		margin: 8px 0;
	}
}

.smallish {
	max-width: 150px;
}

.balance {
	align-items: center;
	border-radius: 4px;
	display: flex;
	font-size: var(--font-size-sm);
	font-weight: bold;
	justify-content: space-between;
	padding: 10px;

	&.funded {
		background: #eaf5dc;
		border: 1px solid #bade8d;
	}

	&.low {
		background: rgb(238 0 0 / 10%);
		border: 1px solid var(--red);
	}
}

@keyframes flip-horizontal {
	0% {
		-webkit-transform: rotateX(0);
		transform: rotateX(0);
	}
	100% {
		-webkit-transform: rotateX(180deg);
		transform: rotateX(180deg);
	}
}

.flip-enter-active {
	animation: flip-horizontal 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
.flip-enter-active,
.flip-leave-active {
	transition: opacity 0.1s;
}

.flip-enter-from,
.flip-leave-to {
	display: none;
	opacity: 0;
}
</style>
