<template>
	<p-dialog modal v-model:visible="show_call_log_modal" style="width: 1200px; max-width: 95%">
		<template #header>
			<div class="flex align-items-center w-full">
				<icon type="phone-log" size="var(--font-size-h4)" class="mr-2" />
				<strong>Call Log Details: {{ callLogId || '' }}</strong>
			</div>
		</template>

		<div v-if="$root.sessionStore.isAdminUser" class="call-log-content">
			<line-loader :show="call_log_loading" />
			<div class="padded">
				<template v-if="!call_log_loading && call_log">
					<div class="flex">
						<div class="call-log-details">
							<p-fieldset legend="Call Details">
								<div class="line-item">
									<div class="label">Call Log ID:</div>
									<div class="value">{{ call_log.id }}</div>
								</div>
								<div class="line-item">
									<div class="label">Call Type:</div>
									<div class="value">{{ capitalize(call_log.call_type) }}</div>
								</div>
								<div class="line-item">
									<div class="label">Started At:</div>
									<div class="value">
										{{ formatDate(call_log.started_at, 'ddd, MMM d, YYYY [at] h:mm:ssA') }}
									</div>
								</div>
								<div class="line-item">
									<div class="label">Ended At:</div>
									<div class="value">
										{{ formatDate(call_log.ended_at, 'ddd, MMM d, YYYY [at] h:mm:ssA') }}
									</div>
								</div>
								<div v-if="call_log.data?.purchase_duration" class="line-item">
									<div class="label">Campaign Duration:</div>
									<div class="value">{{ duration(call_log.data?.purchase_duration) }}</div>
								</div>
								<div class="line-item">
									<div class="label">Buyer Duration:</div>
									<div
										:class="[
											'value',
											{
												sold: call_log.buyer_duration >= call_log.data?.purchase_duration,
												canceled: call_log.buyer_duration < call_log.data?.purchase_duration,
											},
										]"
									>
										{{ duration(call_log.buyer_duration) }}
									</div>
								</div>
								<div class="line-item">
									<div class="label">Total Duration:</div>
									<div class="value">{{ duration(call_log.duration) }}</div>
								</div>
							</p-fieldset>
							<p-fieldset legend="Phone Details">
								<div class="line-item">
									<div class="label">NGL Phone:</div>
									<div class="value">{{ phoneFormat(call_log.ngl_phone) }}</div>
								</div>
								<div class="line-item">
									<div class="label">Lead Phone:</div>
									<div class="value">{{ phoneFormat(call_log.lead_phone) }}</div>
								</div>
								<div class="line-item">
									<div class="label">NGL Phone ID:</div>
									<div class="value">{{ call_log.provider_phone_id }}</div>
								</div>
								<div class="line-item">
									<div class="label">Phone Type:</div>
									<div class="value">{{ call_log.phone_type }}</div>
								</div>
							</p-fieldset>
							<p-fieldset v-if="call_log.recording_url" legend="Recording">
								<audio controls>
									<source :src="call_log.recording_url" type="audio/mpeg" />
									Your browser does not support the audio element.
								</audio>
							</p-fieldset>
							<p-fieldset legend="Call Data">
								<div class="call-data">
									<pre>{{ call_log.data }}</pre>
								</div>
							</p-fieldset>
						</div>
						<div class="call-log-timeline">
							<div class="call-date">{{ formatDate(call_log.started_at, 'ddd, MMM d, YYYY') }}</div>
							<div class="call-events">
								<p-timeline :value="call_log.events">
									<template #opposite="events">
										<div class="status">{{ capitalize(events.item.status) }}</div>
										<div class="sub-value">{{ formatDate(events.item.created_at, 'h:mm:ssA') }}</div>
									</template>
									<template #marker="events">
										<div :class="['p-timeline-event-marker', { child: events.item.type === 'child' }]"></div>
									</template>
									<template #content="events">
										<div :class="['event-container', { child: events.item.type === 'child' }]">
											<div class="to">To: {{ phoneFormat(events.item.to) }}</div>
											<div class="direction">{{ events.item.direction }}</div>
											<div v-if="events.item.sub_route" class="route">
												Route: {{ events.item.sub_route }}.{{ events.item.step }}
											</div>
											<div v-if="events.item.handler" class="handler">Handler: {{ events.item.handler }}</div>
											<div v-if="events.item.message" class="message">{{ events.item.message }}</div>
											<div v-if="events.item.input" class="input">Input: {{ events.item.input }}</div>
										</div>
									</template>
								</p-timeline>
							</div>
						</div>
					</div>
				</template>
				<template v-if="call_log_error">
					<p-message severity="error" :closable="false">Unable to load call log</p-message>
				</template>
			</div>
		</div>
	</p-dialog>
</template>

<script>
import { getCallLogById } from '@GQL';
import pTimeline from 'primevue/timeline';
import { capitalize, duration, formatDate, phoneFormat } from '@/lib/Filters';

export default {
	name: 'CallLogModal',
	components: {
		pTimeline,
	},
	data() {
		return {
			show_call_log_modal: false,
			call_log_loading: false,
			call_log_error: false,
			call_log: {},
		};
	},
	methods: {
		capitalize,
		duration,
		formatDate,
		phoneFormat,
		async open(call_log_id) {
			this.show_call_log_modal = true;
			try {
				this.call_log_loading = true;
				this.call_log = await getCallLogById(call_log_id);
			} catch (err) {
				this.call_log_error = true;
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to load call log',
					life: 8000,
				});
			} finally {
				this.call_log_loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.call-log-content {
	position: relative;

	.line-item {
		align-items: flex-start;
		border-top: 1px solid var(--gray-10);
		display: flex;
		font-size: 0.875rem;
		height: 2.5em;
		line-height: 2.5em;

		&:first-child {
			border-top: none;
		}

		.label {
			font-weight: bold;
			min-width: 150px;
		}

		.value {
			color: var(--gray-50);

			&.sold {
				color: var(--green);
			}

			&.canceled {
				color: var(--red);
			}
		}
	}

	.call-log-details,
	.call-log-timeline {
		flex: 1 0 auto;
	}
}

.call-data {
	color: var(--gray-50);
	font-family: var(--font-mono);
	font-size: 0.875rem;
	line-height: 1.35em;
}

.call-log-timeline {
	line-height: 1.25em;
}

.call-date {
	font-weight: bold;
	margin-bottom: 1.5em;
	text-align: center;
}

:deep(.p-timeline-event-marker.child) {
	background-color: var(--teal);
	// border-color: var(--purple);
}

.event-container {
	color: var(--gray-40);
	font-size: 0.875em;
	margin-bottom: 1em;

	.to {
		color: var(--gray-65);
		font-weight: bold;
	}

	&.child {
		padding-left: 1em;
	}
}
</style>
