import { $GQL } from '@GQL/index';

export async function getLocalSettingsList(mpid: string): Promise<string[]> {
	const query = `
        query GetLocalSettingsList($mpid: String!) {
            localSettingsList(mpid: $mpid)
        }
    `;

	const result = await $GQL.request(query, { mpid });
	return result.localSettingsList;
}
