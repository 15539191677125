<template>
	<div id="offers-list" class="view-content">
		<div class="page-heading">
			<h1>Offers</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<row>
				<p-button label="New Offer" @click="$router.push({ path: `${$route.path}/new` })">
					<template #icon>
						<div class="icon-wrapper button-icon">
							<icon type="plus" size="24px" />
						</div>
					</template>
				</p-button>
			</row>
			<gutter size="20px" />
			<row>
				<div class="column">
					<div class="card padded">
						<p-data-table ref="dt" scrollable :value="offers" :loading="loading">
							<template #empty>
								<div class="dim">No Offers found</div>
							</template>
							<template #loading>
								<line-loader :show="loading" />
							</template>
							<p-column field="name" header="Offer Name">
								<template #body="row">
									<router-link :to="`${$route.path}/${row.data.id}`">{{ row.data.name }}</router-link>
								</template>
							</p-column>
							<p-column field="credit_amount" header="Bonus Amount">
								<template #body="row">
									{{
										row.data.credit_type === 'flat'
											? currency(row.data.credit_amount)
											: percentage(row.data.credit_amount)
									}}
									<div class="sub-value">{{ capitalize(row.data.credit_type) }}</div>
								</template>
							</p-column>
							<p-column field="trigger" header="Trigger">
								<template #body="row">
									{{ startCase(row.data.trigger) }}
								</template>
							</p-column>
							<p-column field="required_funding" header="Required Funding">
								<template #body="row">
									{{ currency(row.data.required_funding) }}
								</template>
							</p-column>
							<p-column field="status" header="Status" class="column-align-center" sortable sortField="status">
								<template #body="row">
									{{ capitalize(row.data.status) }}
								</template>
							</p-column>
							<p-column header="Tools" class="column-align-center">
								<template #body="row">
									<div class="toolset">
										<div class="tool">
											<p-button
												v-tooltip.top="'Edit'"
												aria-label="Edit"
												@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
											>
												<template #icon>
													<icon type="square-edit-outline" size="20px" />
												</template>
											</p-button>
										</div>
										<!--                    <div class="tool">-->
										<!--                      <p-button-->
										<!--                          v-tooltip.top="'Duplicate'"-->
										<!--                          aria-label="Edit"-->
										<!--                          @click="duplicateOffer($event, row.data.id)"-->
										<!--                      >-->
										<!--                        <template #icon>-->
										<!--                          <icon type="content-copy" size="20px" />-->
										<!--                        </template>-->
										<!--                      </p-button>-->
										<!--                    </div>-->
									</div>
								</template>
							</p-column>
						</p-data-table>
						<p-paginator :rows="query.pagination.page_size" :total-records="total_rows" @page="handlePageChange" />
					</div>
				</div>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { capitalize, startCase } from 'lodash-es';
import { currency, percentage } from '@/lib/Filters';
import { useSessionStore } from '@/stores/session';
import { getOfferList } from '@GQL';
import { useAppStore } from '@/stores/app';
import pPaginator from 'primevue/paginator';
import { isThisISOWeek } from 'date-fns';

export default {
	name: 'OfferListView',
	setup() {
		return {
			appStore: useAppStore(),
			sessionStore: useSessionStore(),
		};
	},
	components: {
		pPaginator,
	},
	data() {
		return {
			loading: false,
			breadcrumb_items: [],
			offers: [],
			query: {
				filters: [],
				pagination: {
					page: 0,
					page_size: 10,
				},
			},
			total_rows: 0,
		};
	},
	computed: {
		mpid() {
			return this.$route.params.mpid || this.appStore.mpid;
		},
	},
	async mounted() {
		await this.getOffers();
		if (this.$route.params.mpid) {
			this.breadcrumb_items.push(
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Offers',
				}
			);
		} else {
			this.breadcrumb_items.push({
				label: 'Offers',
			});
		}
	},
	methods: {
		capitalize,
		startCase,
		currency,
		percentage,
		async handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getOffers();
		},
		async getOffers() {
			this.loading = true;
			try {
				this.query.filters = [[`mpid = '${this.mpid}'`]];
				const { rows, row_count } = await getOfferList(this.query);
				this.offers = rows;
				this.total_rows = row_count;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get offers',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style scoped lang="less">
:deep(.tools) {
	max-width: 120px;
}
</style>
