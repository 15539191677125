<template>
	<div id="job_configs-list" class="view-content">
		<div class="page-heading">
			<h1>JobConfigs</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<div class="page-content">
			<div class="flex justify-content-between">
				<p-button icon="pi pi-plus" label="Add Job" @click.prevent="$router.push(`${$route.path}/new`)" />
				<show-archived v-model="show_archived" />
			</div>
			<gutter size="20px" />
			<div class="card padded">
				<p-data-table
					ref="dt"
					:loading="loading"
					:rows="50"
					scrollable
					:value="show_archived ? job_configs : activeJobConfigs"
					:row-class="rowClass"
					sort-field="name"
					:sort-order="1"
				>
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column field="product" header="Name" sortable sortField="name">
						<template #body="job_config">
							<router-link :to="`${$route.path}/${job_config.data.id}`">{{ job_config.data.name }}</router-link>
							<div class="sub-value">{{ job_config.data.description }}</div>
						</template>
					</p-column>
					<p-column field="job_config_type" header="Script Name" sortable sortField="script_name">
						<template #body="job_config">
							{{ job_config.data.script_name }}
							<div class="sub-value">Type: {{ job_config.data.script_type }}</div>
						</template>
					</p-column>
					<p-column field="job_config_schedule" header="Schedule" sortable sortField="schedule">
						<template #body="job_config">
							{{ job_config.data.schedule }}
							<div class="sub-value">{{ cronstrue(job_config.data.schedule) }}</div>
						</template>
					</p-column>
					<p-column field="job_config_schedule" header="k8s Job Name" sortable sortField="k8s_job_name">
						<template #body="job_config">
							{{ job_config.data.k8s_job_name }}
						</template>
					</p-column>
					<p-column field="status" header="Status" sortable sortField="status">
						<template #body="job_config">
							{{ capitalize(job_config.data.status) }}
						</template>
					</p-column>
					<p-column header="Tools">
						<template #body="job_config">
							<div class="toolset">
								<div class="tool">
									<p-button
										v-tooltip.top="'Edit Job Config'"
										aria-label="Edit"
										@click.prevent="$router.push({ path: `job-configs/${job_config.data.id}` })"
									>
										<template #icon>
											<icon type="square-edit-outline" size="20px" />
										</template>
									</p-button>
								</div>
								<div v-if="job_config.data.status !== 'archived'" class="tool">
									<p-button
										v-tooltip.top="'Archive'"
										aria-label="Archive"
										@click="archiveJobConfig(job_config.data.id)"
									>
										<template #icon>
											<icon type="archive" size="20px" />
										</template>
									</p-button>
								</div>
								<div v-else class="tool">
									<p-button
										v-tooltip.top="'Activate'"
										aria-label="Activate"
										@click="activateJobConfig(job_config.data.id)"
									>
										<template #icon>
											<icon type="archive-off" size="20px" />
										</template>
									</p-button>
								</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { getJobConfigs, updateJobConfig } from '@GQL';
import { capitalize } from 'lodash-es';
import { type JobConfig } from '@nextgenleads/job-driver';
import showArchived from '@/components/widgets/ShowArchived.vue';
import { toString as cronstrue } from 'cronstrue';

export default {
	name: 'JobConfigsList',
	components: {
		showArchived,
	},
	data() {
		return {
			job_configs: [],
			loading: false,
			breadcrumb_items: [{ label: 'Job Configs', route: '/job-configs' }],
			show_archived: false,
		};
	},
	computed: {
		activeJobConfigs() {
			return this.job_configs.filter((job_config: JobConfig) => job_config.status !== 'archived');
		},
	},
	async mounted() {
		this.loading = true;
		try {
			this.job_configs = await getJobConfigs();
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to get Job Config List',
			});
		} finally {
			this.loading = false;
		}
	},
	methods: {
		capitalize,
		cronstrue,
		rowClass(data) {
			return data.status;
		},
		async archiveJobConfig(job_config_id) {
			try {
				const result = await updateJobConfig(job_config_id, {
					status: 'archived',
				});
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Job Config Archived',
						detail: 'Successfully archived job config.',
						life: 3000,
					});
					// Update local UI
					this.job_configs.find((job_config) => job_config.id === job_config_id).status = 'archived';
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to archive job config',
					life: 6000,
				});
			}
		},
		async activateJobConfig(job_config_id) {
			try {
				const result = await updateJobConfig(job_config_id, {
					status: 'active',
				});
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Job Config Activated',
						detail: 'Successfully activated job config.',
						life: 3000,
					});
					// Update local UI
					this.job_configs.find((job_config) => job_config.id === job_config_id).status = 'active';
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to activate job config.',
					life: 6000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.sub-value {
	max-width: 60ch;
	text-wrap: wrap;
}
:deep(tr.disabled) td {
	font-style: italic;
	opacity: 0.65;
}
</style>
