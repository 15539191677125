import { $GQL } from '@GQL/index';

export async function reportCallVelocity(
	params: {
		date_range: [string, string];
		vertical_id: string[];
		source_id: string;
		data_source_id: string;
	},
	refresh: boolean
) {
	const query = `
        query ReportCallVelocity($params: JSONObject!) {
            reportCallVelocity(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportCallVelocity'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportCallVelocity;
}
