<template>
	<div id="auto-fund-settings" class="view-content">
		<div class="page-heading">
			<h1>Billing Settings</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card>
			<template #content>
				<p-fieldset legend="Auto-Fund Settings">
					<p>
						This feature automatically replenishes your account by an amount you specify when your funds run low. This
						ensures that leads will continue to flow without having to constantly remember to fund your account. Editing
						these settings will not charge your credit card. This feature is optional, but we recommend you use it.
					</p>
					<div class="auto-fund-section">
						<div class="header" :class="{ 'has-border': auto_fund_enabled }">
							<div class="title">
								<div class="name">Enable Auto-Funding</div>
							</div>
							<div class="switch">
								<p-input-switch v-model="auto_fund_config.status" true-value="enabled" false-value="disabled" />
							</div>
						</div>
						<div v-if="auto_fund_config.status === 'enabled'" class="content">
							<div class="flex flex-column md:flex-row md:align-items-center flex-wrap gap-2">
								<div class="label">
									<label for="trigger_amount" class="mb-2 mt-1 font-bold">Trigger Threshold:</label>
									<div class="description">
										When your account balance goes below this number, auto-funding will be triggered.
									</div>
								</div>
								<div class="input-width">
									<p-input-currency v-model="auto_fund_config.trigger_amount" />
								</div>
							</div>
							<div v-if="v$.auto_fund_config.trigger_amount.$error" class="validation-error">
								{{ v$.auto_fund_config.trigger_amount.$errors[0].$message }}
							</div>
							<gutter size="10px" />
							<div class="flex flex-column md:flex-row md:align-items-center flex-wrap gap-2">
								<div class="label">
									<label for="funding_amount" class="mb-2 mt-1 font-bold">Auto-Fund Amount:</label>
									<div class="description">
										When auto-funding is triggered, this is the amount that will be deposited into your account.
									</div>
								</div>
								<div class="input-width">
									<p-input-currency v-model="auto_fund_config.funding_amount" />
								</div>
							</div>
							<div v-if="v$.auto_fund_config.funding_amount.$error" class="validation-error">
								{{ v$.auto_fund_config.funding_amount.$errors[0].$message }}
							</div>
							<gutter size="10px" />
							<div class="flex flex-column md:flex-row md:align-items-center flex-wrap gap-2">
								<div class="label">
									<label for="payment_method" class="mb-2 mt-1 font-bold">Payment Method:</label>
									<div class="description">Select a payment method to use for auto-fund deposits</div>
								</div>
								<p-dropdown
									v-model="auto_fund_config.payment_method_id"
									name="payment_method"
									option-label="label"
									option-value="value"
									:options="payment_method_options"
									placeholder="Select a payment method"
								/>
								<div v-if="v$.auto_fund_config.payment_method_id.$error" class="validation-error">
									{{ v$.auto_fund_config.payment_method_id.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</p-fieldset>
				<template v-if="$root.sessionStore.isAdminUser">
					<p-fieldset legend="Credit Terms" class="admin-only">
						<p>
							This is a very special setting. Contracts should be uploaded and payment periods should be established
							before turning this on. Another requirement is for the account to start with a zero balance. Once those
							conditions are met, the balance for this account will only be negative, signaling the amount owed by this
							account. If you have any questions contact the development team before turning this on.
						</p>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field fit">
										<p-input-switch input-id="credit-terms" v-model="account.settings.credit_terms.enabled" />
									</div>
									<div class="gap" />
									<div class="field caption">
										<label for="credit-terms" class="text">Enable Credit Terms</label>
									</div>
								</div>
							</div>
						</div>
						<p-fieldset v-if="account.settings.credit_terms.enabled" legend="Credit Term Settings" class="inner">
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field fit">
											<p-input-switch input-id="credit-terms" v-model="account.settings.credit_terms.limit_enabled" />
										</div>
										<div class="gap" />
										<div class="field caption fit">
											<label for="credit-terms" class="text">Limit Credit Amount</label>
										</div>
									</div>
								</div>
							</div>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Credit Limit:</label>
									<div class="controls">
										<div class="field m">
											<p-input-currency
												:disabled="!account.settings.credit_terms.limit_enabled"
												v-model="account.settings.credit_terms.limit"
											/>
										</div>
									</div>
								</div>
							</div>
						</p-fieldset>
					</p-fieldset>
				</template>
				<div class="footer flex justify-content-end">
					<p-button label="Save" @click="updateFundConfig" />
				</div>
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import { useVuelidate } from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import pCard from 'primevue/card';
import pDropdown from 'primevue/dropdown';
import pInputSwitch from 'primevue/inputswitch';
import pButton from 'primevue/button';
import {
	getAutoFundConfigById,
	getAccountBillingSettings,
	getPaymentMethodsAsOptions,
	insertAutoFundConfig,
	updateAutoFundConfig,
	updateAccount,
} from '@GQL';
import { useSessionStore } from '@/stores/session';
import { cloneDeep } from 'lodash-es';
import { useAppStore } from '@/stores/app';

const default_auto_config = {
	account_id: '',
	payment_method_id: '',
	trigger_amount: 0,
	funding_amount: 0,
	modified_by: '',
	status: 'disabled',
};

export default {
	name: 'BillingSettings',
	components: {
		pCard,
		pButton,
		pDropdown,
		pInputSwitch,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
			appStore: useAppStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			auto_fund_enabled: false,
			breadcrumb_items: [],
			auto_fund_config: cloneDeep(default_auto_config),
			payment_method_options: [],
			new_config: false,
			account: {
				settings: {
					credit_terms: {
						enabled: false,
						limit_enabled: false,
						limit: 0,
					},
				},
			},
		};
	},
	computed: {
		accountId() {
			return (
				this.$route.params.account_id ||
				this.$route.params.parent_account_id ||
				this.sessionStore.user.account_id ||
				`${this.appStore.mpid}-000-000`
			);
		},
	},
	async mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Billing Settings',
				},
			];
		} else {
			this.breadcrumb_items = [
				{
					label: 'Billing Settings',
				},
			];
		}
		await this.setPaymentMethodOptions();
		try {
			const fund_config = await getAutoFundConfigById(this.accountId);
			if (fund_config) {
				this.auto_fund_config = cloneDeep(fund_config);
			} else {
				this.new_config = true;
				this.resetConfig();
			}

			// Get account settings
			if (this.$root.sessionStore.isAdminUser) {
				const account = await getAccountBillingSettings(this.accountId, true);
				if (account.settings?.credit_terms !== null) {
					this.account.settings.credit_terms = account.settings?.credit_terms;
				}
			}
		} catch (e) {
			this.resetConfig();
		}
	},
	methods: {
		async updateFundConfig() {
			const is_valid = await this.v$.$validate();
			let change_occurred = false;
			if (is_valid) {
				try {
					if (this.new_config) {
						if (this.auto_fund_config.status !== 'disabled') {
							// means we can insert... new
							const result = await insertAutoFundConfig({
								...this.auto_fund_config,
								modified_by: this.sessionStore.user.id,
								account_id: this.accountId,
							});
						}
					} else {
						const result = await updateAutoFundConfig(this.accountId, {
							...this.auto_fund_config,
							modified_by: this.sessionStore.user.id,
						});
					}

					// Update the credit terms
					if (this.$root.sessionStore.isAdminUser) {
						const account = await updateAccount(this.accountId, {
							settings: this.account.settings,
						});
					}

					this.$toast.add({
						severity: 'success',
						summary: 'Billing settings were updated',
						life: 5000,
					});
				} catch (err) {
					console.error(err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to update billing settings',
					});
				}
			}
		},
		resetConfig() {
			this.auto_fund_config = { ...default_auto_config };
		},
		async setPaymentMethodOptions() {
			this.payment_method_options = await getPaymentMethodsAsOptions(this.accountId);
		},
	},
	validations() {
		return {
			auto_fund_config: {
				payment_method_id: {
					requiredWhen: helpers.withMessage('When enabled, Payment Method is required', () => {
						// must have a valid payment_method_id if an existing autofund is being updated
						return (
							this.auto_fund_config.status === 'disabled' ||
							(this.auto_fund_config.status === 'enabled' && this.auto_fund_config.payment_method_id !== '')
						);
					}),
				},
				funding_amount: {
					minNeededWhen: helpers.withMessage('When enabled, Funding Amount must be greater than $1', () => {
						return (
							this.auto_fund_config.status === 'disabled' ||
							(this.auto_fund_config.status === 'enabled' && this.auto_fund_config.funding_amount > 0)
						);
					}),
				},
				trigger_amount: {
					minNeededWhen: helpers.withMessage('When enabled, Trigger amount must be greater than $1', () => {
						return (
							this.auto_fund_config.status === 'disabled' ||
							(this.auto_fund_config.status === 'enabled' && this.auto_fund_config.trigger_amount > 0)
						);
					}),
				},
			},
		};
	},
};
</script>

<style lang="less" scoped>
.p-card {
	max-width: 800px;
}

.footer {
	margin-top: 20px;
}

.input-width {
	max-width: 150px;
}

.auto-fund-section {
	border: 1px solid var(--gray-15);
	border-radius: 4px;
	font-size: var(--font-size-sm);

	.header,
	.footer {
		align-items: center;
		display: flex;
		flex-direction: row;
		gap: 10px;
		justify-content: space-between;
		padding: 10px 15px;
		width: 100%;

		&.has-border {
			border-bottom: 1px solid var(--gray-30);
		}

		.title {
			.name {
				color: var(--color-b);
				display: inline-block;
				font-size: var(--font-size-base);
				line-height: 1.5;
				min-width: 150px;
			}
		}
	}

	.content {
		padding: 20px 15px;

		.label {
			flex: 1;

			.description {
				color: var(--gray-50);
				line-height: 1.5;
				margin: 10px 0;
			}
		}

		.currency-input {
			margin-bottom: 10px;
			max-width: 150px;
		}
	}
}
</style>
