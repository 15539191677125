import { $GQL } from '@GQL/index';
import type { Channel } from '@nextgenleads/source-driver';

export async function updateChannel(id: string, data: Partial<Channel>) {
	const mutation = `
        mutation UpdateChannel($id: ID!, $data: JSONObject!) {
            updateChannel(id: $id, data: $data) {
                id
            }
        }
    `;

	return await $GQL.request(mutation, { id, data });
}
