<template>
	<div class="modifier second-price">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Second Price</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">
			Second price the bids for a campaign if second pricing for the campaign is not disabled and it is the highest bid
			in the auction.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<label class="control-label">Threshold:</label>
				<div class="inner">
					<div class="controls gap-20">
						<div class="field xs">
							<p-input-currency v-model="modelValue.threshold" />
						</div>
						<div class="field caption">Bids below this threshold will not be second-priced</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'SecondPriceModifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					threshold: 3.5,
				};
			},
		},
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
