import { $GQL } from '@GQL';
import type { Shipper } from '@nextgenleads/shipper-driver';

export async function updateShipper(id: string, data: Partial<Shipper>): Promise<{ id: string }> {
	const mutation = `
		mutation UpdateShipper($id: ID!, $data: JSONObject!) {
			updateShipper(id: $id, data: $data) {
				id
			}
		}
	`;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateShipper;
}
