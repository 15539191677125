<template>
	<div class="build-step forward-to-partners">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Forward to Partners</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">Send the lead information to one of NextGen's partners.</div>
		<div class="step-settings">
			<p-button @click="openModal" label="Add" />
			<gutter size="20px" />
			<h4 v-if="modelValue.partners.length === 0">No partners added yet.</h4>

			<div v-for="(partner, index) in modelValue.partners">
				<p-fieldset :legend="startCase(partner.name)">
					<div class="flex justify-content-between">
						<div>
							<label>Params:</label>
							<highlightjs autodetect :code="JSON.stringify(partner.settings, null, 2)" />
						</div>
						<div>
							<p-button text v-tooltip.top="'Delete'" @click="removePartner(index)" aria-label="Delete">
								<template #icon>
									<icon type="close" size="16px" />
								</template>
							</p-button>
						</div>
					</div>
				</p-fieldset>
				<gutter size="10px" />
			</div>
		</div>

		<p-dialog v-model:visible="show_modal" modal :dismissable-mask="true" style="width: 400px">
			<template #header>
				<div>
					<strong>New Partner Configuration</strong>
				</div>
			</template>
			<div class="content">
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<label class="control-label req">Partner:</label>
								<p-dropdown
									v-model="new_partner_name"
									:options="partner_options"
									option-label="label"
									option-value="value"
									placeholder="Select Partner"
								/>
							</div>
						</div>
					</div>
				</div>

				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field">
								<label class="control-label req">Settings:</label>
								<code-editor v-model.parse="new_partner_settings" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div>
					<p-button @click="addNewPartner" label="Add" />
				</div>
			</template>
		</p-dialog>
	</div>
</template>

<script>
import { startCase } from 'lodash-es';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ForwardToPartners',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				partners: [],
			},
		},
	},
	data() {
		return {
			show_modal: false,
			new_partner_name: '',
			new_partner_settings: {},
			partner_options: [
				{ label: 'Drips', value: 'drips' },
				{ label: 'LiftLogic', value: 'lift_logic' },
			],
		};
	},
	methods: {
		startCase,
		removePartner(index) {
			this.modelValue.partners.splice(index, 1);
		},
		openModal() {
			this.resetModal();
			this.show_modal = true;
		},
		resetModal() {
			this.new_partner_name = '';
			this.new_partner_settings = {};
		},
		addNewPartner() {
			this.modelValue.partners.push({
				name: this.new_partner_name,
				settings: this.new_partner_settings,
			});
			this.resetModal();
			this.show_modal = false;
		},
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
