<template>
	<div>
		<p-button label="CSV Upload" @click="openModal">
			<template #icon>
				<icon type="upload" size="20px" />
				<gutter size="10px" />
			</template>
		</p-button>
		<p-dialog v-model:visible="show_modal" class="modal" :modal="true" :dismissable-mask="true">
			<loader :show="loading" />
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<strong>Conversion CSV Upload</strong>
				</div>
			</template>
			<div class="flex-flex-column">
				<p>The file can be a single column of phone numbers (10 digits) or the Lead ID, no headers</p>
				<p>Additionally if you have dispositions other than 'Sold', those can be in the second column</p>
			</div>
			<template #footer>
				<custom-upload choose-label="Upload CSV" accept="text/csv" folder="conversions-csv" @success="csvConversion" />
			</template>
		</p-dialog>
	</div>
</template>
<script>
import { processConversionCsv } from '@GQL';
import customUpload from '@/components/forms/CustomUpload.vue';
import { dnc_type_options } from '@/lib/Options';
import pTextArea from 'primevue/textarea';

export default {
	name: 'ConversionCSVUploadModal',
	components: {
		customUpload,
		pTextArea,
	},
	data() {
		return {
			loading: false,
			rows: [],
			type: '',
			note: '',
			dnc_type_options,
			show_modal: false,
		};
	},
	computed: {
		isFormReady() {
			return this.note !== '' && this.type !== '';
		},
		accountId() {
			return this.$route.params.account_id || this.sessionStore.user.account_id;
		},
	},
	methods: {
		openModal() {
			this.show_modal = true;
		},
		reset() {
			this.note = '';
			this.type = '';
		},
		async csvConversion(upload_results) {
			this.loading = true;
			try {
				if (upload_results.data.length === 1) {
					const file_details = upload_results.data[0];
					const params = {
						user_id: this.$root.sessionStore.user.id,
						account_id: this.accountId,
						s3_key: file_details.key,
						s3_bucket: file_details.bucket,
					};

					const job_response = await processConversionCsv(params);

					this.$toast.add({
						severity: 'info',
						summary: 'The processing of the CSV has started',
						detail: 'After the background process completes, you will receive an email regarding the results.',
						life: 10000,
					});
					this.show_modal = false;
					this.reset();
				}
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Error in starting csv processing',
					detail: 'Contact Dev team',
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
