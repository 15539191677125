import { $GQL } from '@GQL/index';
import log from '@/lib/Log';
import type { GetListParams } from '@nextgenleads/db';

export async function getNotificationReceiptList(params: GetListParams, refresh?: boolean) {
	const query = `
    query GetNotificationReceiptList($params: GetListParams!){
        notificationReceiptList(params: $params) {
			row_count
            rows {
                id
                notification {
                    message
                    tags
                    created_by_user {
                        first_name
                        last_name
                    }
                }
                action
                created_at
                dismissed_at
            }
        }
    }       
    `;

	const no_cache = refresh ? ['notificationReceiptList'] : undefined;
	const result = await $GQL.request(query, {
		params,
		no_cache,
	});

	return result.notificationReceiptList;
}
