import { $GQL } from '@GQL/index';

export async function getAncestors(parent_account_id: string, no_cache?: string[]) {
	const query = `
        query AncestorAccountsList($parent_account_id: ID!) {
			ancestorAccountsList(parent_account_id: $parent_account_id) {
				id
				name
				parent_account_id
				is_parent
				status
				created_at
			  }
        }
    `;

	const result = await $GQL.request(query, {
		parent_account_id,
		no_cache,
	});

	return result.ancestorAccountsList;
}
