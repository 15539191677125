import { $GQL } from '@GQL';

// Hardly a need to filter, just use quick filters if anything
export async function getQueues() {
	const query = `
        query GetQueues {
            queues {
                id
                name
                limit
				status
            }
        }
    `;

	const request = await $GQL.request(query);
	return request.queues;
}
