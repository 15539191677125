<template>
	<div id="amw-admin-overview" class="view-content">
		<div class="page-heading">
			<h1>Overview Report</h1>
		</div>
		<div>
			<div class="card padded">
				<query-form v-model:query="query" :loading="loading" @apply="loadReport" @abort="clearQuery">
					<template #display>
						<div class="display-value">
							<div class="label">Date Range:</div>
							<div class="value">
								{{ formatDate(query.date_range, 'ddd, MMM D, YYYY') }}
							</div>
						</div>
					</template>

					<template #form="form">
						<row>
							<column>
								<div class="control-group">
									<div class="inner">
										<div class="controls">
											<div class="field">
												<date-range v-model="form.query.date_range" hide-labels :show-time="false" />
											</div>
										</div>
									</div>
								</div>
							</column>
						</row>
					</template>
					<template #utils>
						<p-button
							@click="loadReport(true)"
							v-tooltip.top="'Refresh'"
							icon="pi pi-refresh"
							aria-label="Refresh"
							class="mr-2"
						/>
					</template>
				</query-form>
				<p-data-table ref="dt" :loading="loading" :rows="100" scrollable :value="rows" key="account_manager_id">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column-group type="header">
						<p-row>
							<p-column header="Account Manager" :rowspan="2" sortable sortField="name" />
							<p-column header="Existing Accounts" :colspan="4" class="left-bordered" />
							<p-column header="New Accounts" :colspan="4" class="left-bordered" />
						</p-row>
						<p-row>
							<p-column header="All Accounts" sortable sortField="total_accounts_count" />
							<p-column header="Spend" class="left-bordered" sortable sortField="existing_account_spend" />
							<p-column header="Funded Accounts" sortable sortField="existing_account_count" />
							<p-column header="Spend / Account" sortable sortField="existing_spend_per_account" />
							<p-column header="Spend" class="left-bordered" sortable sortField="initial_account_spend" />
							<p-column header="Total Fundings" class="left-bordered" sortable sortField="initial_fundings" />
							<p-column header="Initial Fundings" sortable sortField="inital_funding_account_count" />
							<p-column header="Fundings / Account" sortable sortField="initial_funding_per_account" />
						</p-row>
					</p-column-group>

					<p-column header="Account Manager">
						<template #body="row">
							<router-link :to="`/amw-manager/${row.data.account_manager_id}/pending-follow-ups`">
								{{ row.data.name }}
							</router-link>
							<div class="sub-value">
								{{ row.data.title }}
							</div>
						</template>
					</p-column>
					<p-column>
						<template #body="row">
							{{ row.data.total_accounts_count }}
						</template>
					</p-column>
					<p-column>
						<template #body="row">
							{{ currency(row.data.existing_account_spend) }}
						</template>
					</p-column>
					<p-column>
						<template #body="row">
							{{ row.data.existing_account_count }}
						</template>
					</p-column>
					<p-column>
						<template #body="row">
							{{ currency(row.data.existing_spend_per_account) }}
						</template>
					</p-column>
					<p-column>
						<template #body="row">
							{{ currency(row.data.initial_account_spend) }}
						</template>
					</p-column>
					<p-column>
						<template #body="row">
							{{ currency(row.data.initial_funding) }}
						</template>
					</p-column>
					<p-column>
						<template #body="row">
							{{ row.data.initial_funding_account_count || 0 }}
						</template>
					</p-column>
					<p-column>
						<template #body="row">
							{{ currency(row.data.initial_funding_per_account) }}
						</template>
					</p-column>
				</p-data-table>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import { reportAccountManagerWorkstationOverview } from '@GQL';
import { currency, formatDate } from '@/lib/Filters';
import { startOfWeek, endOfWeek } from 'date-fns';
import queryForm from '@/components/widgets/QueryForm.vue';
import dateRange from '@/components/forms/DateRange.vue';

export default {
	name: 'AMWOverviewReportPage',
	components: {
		queryForm,
		dateRange,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'AMW Overview' }],
			loading: false,
			rows: [],
			query: {
				date_range: [startOfWeek(new Date()), endOfWeek(new Date())],
			},
		};
	},
	async mounted() {
		await this.loadReport();
	},
	methods: {
		formatDate,
		currency,
		clearQuery() {
			this.loading = false;
		},
		async loadReport(refresh?: boolean) {
			this.loading = true;
			if (refresh) {
				this.query.refresh = true;
			}
			try {
				const result = await reportAccountManagerWorkstationOverview(this.query);
				this.rows = result.rows;
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped lang="less">
:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

.card h2 {
	font-size: 1.25rem;
}
</style>
