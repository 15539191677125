import MarketplacesList from '@/views/Marketplaces/List/Page.vue';
import MarketplaceWrapper from '@/views/Marketplaces/MarketplaceWrapper.vue';
import marketplace_routes from './marketplace-root';
import MarketplaceForm from '@/views/Marketplaces/Record/Page.vue';
import mapNestedRoutes from '@/lib/Utils/mapNestedRoutes';

export default [
	{
		path: 'marketplaces',
		name: 'MarketplacesList',
		component: MarketplacesList,
		meta: {
			title: 'Marketplaces',
			parent_menu: 'Marketplaces',
			permissions: 'marketplaces',
		},
	},
	{
		path: 'marketplaces/new',
		name: 'MarketplaceCreate',
		component: MarketplaceForm,
		meta: {
			title: 'Create Marketplace',
			parent_menu: 'Marketplaces',
			permissions: 'marketplaces',
		},
	},
	{
		path: 'marketplaces/manage/:mpid/',
		name: 'MarketplacesDetail',
		component: MarketplaceWrapper,
		meta: {
			admin: true,
			nested_marketplace: true,
			transition: 'fade-right',
			parent_menu: 'Marketplaces',
		},
		children: mapNestedRoutes([...marketplace_routes], 'MarketplaceDetail:'),
	},
	{
		path: 'marketplaces/:mpid',
		name: 'MarketplaceEdit',
		component: MarketplaceForm,
		meta: {
			title: 'Edit Marketplace',
			parent_menu: 'Marketplaces',
		},
	},
];
