<template>
	<div class="modifier premium-listing">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">TYP Ad Revenue</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">Consider the potential revenue from TYP ad clicks when sorting campaign bids.</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'TYPAdRevenueModifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
