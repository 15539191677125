<template>
	<div class="call-logs">
		<p-card>
			<template #title>
				<div class="flex align-items-center w-full">
					<icon type="phone-log" size="var(--font-size-h4)" class="mr-2" />
					<span class="name">Call Logs</span>
				</div>
			</template>
			<template #content>
				<template v-if="call_logs.length">
					<table class="call-logs">
						<thead>
							<tr>
								<th>Started at</th>
								<th>Type</th>
								<th class="call-duration">Total</th>
								<th class="buyer-duration">Buyer</th>
								<th class="column-align-center">Log</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="log in call_logs" class="call-log" :key="log.id">
								<td class="started-at">
									{{ formatDate(log.started_at, 'ddd, MMM d, YYYY') }}
									<div class="sub-value">{{ formatDate(log.started_at, 'h:mm:ssA') }}</div>
								</td>
								<td class="call-type">{{ capitalize(log.call_type) }}</td>
								<td class="call-duration">{{ duration(log.duration) }}</td>
								<td class="buyer-duration">{{ duration(log.buyer_duration) }}</td>
								<td class="column-align-center">
									<a href="javascript:void(0)" @click="openCallLog(log.id)">
										<icon type="open-in-new" size="20px" />
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</template>
				<template v-else>
					<p-message :closable="false">There are no call logs for this lead</p-message>
				</template>
			</template>
		</p-card>
		<call-log-modal ref="call_log_modal" />
	</div>
</template>

<script>
import pCard from 'primevue/card';
import lineLoader from '@/components/elements/LineLoader.vue';
import callLogModal from './modals/CallLogModal.vue';
import { getCallLogs } from '@GQL';
import { capitalize, duration, formatDate } from '@/lib/Filters';

export default {
	name: 'CallLogsPanel',
	components: {
		pCard,
		lineLoader,
		callLogModal,
	},
	props: {
		leadId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			call_logs: [],
		};
	},
	methods: {
		capitalize,
		duration,
		formatDate,
		openCallLog(call_log_id) {
			this.$refs.call_log_modal.open(call_log_id);
		},
	},
	async mounted() {
		// Get the call logs
		try {
			this.call_logs = await getCallLogs([[`lead_id = '${this.leadId}' OR data_lead_id = '${this.leadId}'`]]);
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to get call logs',
				life: 8000,
			});
		}
	},
};
</script>

<style lang="less">
.call-logs {
	width: 100%;

	tbody tr {
		border-top: 1px solid var(--gray-10);
	}

	th,
	td {
		line-height: 1.25em;
		padding: 0.5em 0;
		text-align: left;
		vertical-align: middle;
	}

	th {
		color: var(--color-b);
		font-size: 0.875rem;
		height: 2em;
		line-height: 2em;
	}

	.call-duration,
	.buyer-duration {
		text-align: center;
	}
}
</style>
