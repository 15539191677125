import { $GQL } from '@GQL/index';
import type { CCPARequest } from '@nextgenleads/dnc-driver';

export async function updateCCPARequest(id: string, data: Partial<CCPARequest>): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateCCPARequest($id: ID!, $data: JSONObject!) {
            updateCCPARequest(id: $id, data: $data) {
                id
                flags
                status
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateCCPARequest;
}
