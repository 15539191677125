import Terms from '@/views/Legal/TermsAndConditions/Page.vue';
import PrivacyPolicy from '@/views/Legal/PrivacyPolicy/Page.vue';
import PrivacyRequestForm from '@/views/Legal/PrivacyRequestForm/Page.vue';

export default [
	{
		name: 'TermsAndConditions',
		path: '/terms',
		component: Terms,
		meta: {
			public: true,
			show_header: true,
			show_footer: true,
		},
	},
	{
		name: 'PrivacyPolicy',
		path: '/privacy-policy',
		component: PrivacyPolicy,
		meta: {
			public: true,
			show_header: true,
			show_footer: true,
		},
	},
	{
		name: 'PrivacyRequestForm',
		path: '/privacy-request-form',
		component: PrivacyRequestForm,
		meta: {
			public: true,
			show_header: false,
			show_footer: false,
		},
	},
];
