<template>
	<p-message v-if="show" :severity="severity" :closable="false">{{ message }}</p-message>
</template>

<script>
import pMessage from 'primevue/message';
import { useAppStore } from '@/stores/app';

export default {
	name: 'FlashMessage',
	components: {
		pMessage,
	},
	data() {
		return {
			severity: 'info',
			message: '',
			show: false,
		};
	},
	mounted() {
		const appStore = useAppStore();
		appStore.flash_message && appStore.flash_message.summary;

		if (appStore.flash_message && appStore.flash_message.summary) {
			this.severity = appStore.flash_message.severity;
			this.message = appStore.flash_message.summary;
			this.show = true;
			appStore.resetFlashMessage();
		}
	},
};
</script>
