<template>
	<div id="roles-list" class="view-content">
		<div class="page-heading">
			<h1>Roles List</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-button icon="pi pi-plus" label="Create Role" class="cursor-pointer" @click.prevent="$router.push('roles/new')" />
		<gutter size="20px" />
		<div class="card padded">
			<RolesTable />
		</div>
	</div>
</template>

<script>
import RolesTable from './List.vue';
import pButton from 'primevue/button';
export default {
	name: 'RolesList',
	components: {
		RolesTable,
		pButton,
	},
	data() {
		return {
			breadcrumb_items: [],
		};
	},
	mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Roles List',
				},
			];
		} else {
			this.breadcrumb_items = [
				{
					label: 'Roles List',
				},
			];
		}
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
