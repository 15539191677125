<template>
	<div id="live-transfer-start" class="view-content">
		<line-loader :show="loading" />
		<div class="flex align-items-center justify-content-between">
			<div class="page-heading">
				<h1>Live Transfer Workstation</h1>
				<div v-if="lead && lead.vertical_id" class="lead-info">
					<vertical-icon :vertical-id="lead.vertical_id" size="30px" />
					{{ lead.id }}: {{ lead.first_name }} {{ lead.last_name }}
				</div>
			</div>
			<div>
				<div
					v-if="lead && 'id' in lead"
					class="lead-details flex clickable lead-detail-button align-items-center"
					@click="show_lead_details = !show_lead_details"
				>
					<div class="">{{ phoneFormat(lead.phone || '') }}</div>
					<gutter size="15px" />
					<div class="location">{{ lead.city || 'Unknown City' }}, {{ lead.state }}</div>
					<gutter size="15px" />
					<div>
						<icon type="account" size="24px" />
					</div>
				</div>
				<div>
					<div class="lead-details flex">
						<div class="lead-details-dropdown fit">
							<div v-if="show_lead_details" class="lead-details-box">
								<div class="lead-content">
									<div class="contact-info">
										<h3>Contact Info</h3>
										<ul class="unstyled table-list">
											<li>
												<span class="label">First Name:</span>
												<span class="value">{{ lead.first_name }}</span>
											</li>
											<li>
												<span class="label">Last Name:</span>
												<span class="value">{{ lead.last_name }}</span>
											</li>
											<li>
												<span class="label">Phone:</span>
												<span class="value">{{ phoneFormat(lead.phone) }}</span>
											</li>
											<li>
												<span class="label">Email:</span>
												<span class="value">{{ lead.email }}</span>
											</li>
											<li>
												<span class="label">Address:</span>
												<span class="value">{{ lead.street_1 }}</span>
											</li>
											<li>
												<span class="label">&nbsp;</span>
												<span class="value">{{ lead.city }}, {{ lead.state }} {{ lead.zip }}</span>
											</li>
										</ul>
									</div>
									<div class="vertical-info">
										<h3>Vertical Info</h3>
										<component :is="verticalDataComponent" :lead="lead" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="">
			<div class="questions">
				<h4>Script for Lead:</h4>
				<div class="lt-script">
					<div class="lt-script-content">
						<div class="lt-script-icon">
							<icon type="comment-text-outline" size="24px" />
						</div>
						<div class="lt-script-body" v-if="script === 'initial'">
							<p>
								Great, those are all the questions I have for you. Let's get the agent on the line. I'll introduce you
								and drop off.
							</p>
						</div>
						<div class="lt-script-body" v-else-if="script === 'no_bids'">
							<p>
								Unfortunately we're having a problem connecting with an agent at this time. Can I schedule a time to
								call you back when one is available so you don't have to wait on the phone?
							</p>
						</div>
						<div class="lt-script-body" v-else-if="script === 'skipped'">
							<p>
								Okay. No problem. I can find another agent that can help you out instead. I'll go ahead and transfer you
								now.
							</p>
						</div>
					</div>
				</div>
			</div>

			<gutter size="30px" />
			<div class="questions" v-if="script === 'initial' && step === 'auction_loaded'">
				<h4>Script for Agent:</h4>
				<div class="lt-script">
					<div class="lt-script-content">
						<div class="lt-script-icon">
							<icon type="comment-text-outline" size="24px" />
						</div>
						<div class="lt-script-body">
							<p v-if="selected_bid && selected_bid.hand_off_script && selected_bid.hand_off_script !== ''">
								{{ selected_bid.hand_off_script }}
							</p>
							<!--              back up buyer-->
							<p v-else>
								Hi there, I currently have <strong>{{ lead.first_name }}</strong> from
								<strong>{{ lead.state }}</strong> on the line. Are you able to assist them with their {{}} Insurance
								search? Great, I will drop off the line.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<gutter size="15px" />

		<div class="card padded">
			<template v-if="step === 'auctioning'">
				<p>Auction Results Loading....</p>
			</template>
			<template v-else-if="step === 'no_bids'">
				<h3>No More bids for this lead...</h3>
			</template>
			<template v-else-if="selected_bid && 'bid_id' in selected_bid">
				<row>
					<div>
						<h3>{{ selected_bid.display_name }}</h3>
						<p class="campaign_name">
							Campaign Name: <strong>{{ selected_bid.campaign_name }}</strong>
						</p>
						<p class="last_bid" v-if="!this.canSkip">This is the last bid...</p>
					</div>
					<h4 class="clickable-phone" @click="copyData" v-tooltip.top="'Copy Phone'">
						{{ phoneFormat(selected_bid.phone) }}

						<icon type="clipboard-plus-outline" size="25px" />
					</h4>
				</row>
				<gutter size="15px" />
				<row class="justify-content-start">
					<p-button
						icon="pi pi-check"
						class="bolder"
						:disabled="loading"
						severity="success"
						v-tooltip.top="'Complete Transfer'"
						@click="completeTransfer"
					/>
					<gutter size="10px" />
					<p-button
						icon="pi pi-fast-forward"
						class="bolder"
						severity="info"
						v-tooltip.top="'Skip Account'"
						@click="$refs.skip_modal.open"
						:disabled="loading"
					/>
					<gutter size="10px" />

					<p-button
						icon="pi pi-times"
						class="bolder"
						:disabled="loading"
						v-tooltip.top="'Fail Transfer'"
						severity="warning"
						@click="$refs.failed_modal.open"
					/>

					<gutter size="10px" />
					<p-button
						icon="pi pi-exclamation-triangle"
						class="larger-icon"
						:disabled="loading"
						v-tooltip.top="'Report Buyer'"
						severity="danger"
						@click="$refs.report_modal.open"
					/>
				</row>
			</template>
		</div>

		<gutter size="50px" />
		<row class="justify-content-center">
			<p-button label="Back To Start" v-if="step === 'no_bids'" @click="backToStart" />
			<p-button severity="secondary" v-else label="Back to Questions" @click="backToForm" />
		</row>

		<SkipModal ref="skip_modal" @skip="skipTransfer" />
		<FailedTransferModal ref="failed_modal" @failed="failTransfer" />
		<ReportAccountModal
			ref="report_modal"
			@report="reportBuyer"
			:display_name="get(selected_bid, 'display_name', 'Display Name Missing')"
		/>

		<p-confirm-dialog />
	</div>
</template>

<script>
import { phoneFormat } from '@/lib/Filters';
import pConfirmDialog from 'primevue/confirmdialog';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import copyToClipboard from 'copy-to-clipboard';
import {
	cancelPendingPurchase,
	completePendingPurchase,
	createPendingPurchase,
	getLead,
	logEvent,
	sendEmail,
	getAccountSupportSettings,
	runLTAuction,
	createCanceledPurchase,
} from '@GQL';
import get from 'lodash/get';
import LineLoader from '@/components/elements/LineLoader.vue';
import SkipModal from './SkipModal.vue';
import FailedTransferModal from './FailedTransferModal.vue';
import ReportAccountModal from '@/views/LTWorkstation/Results/ReportAccountModal.vue';
import log from '@/lib/Log';
import HealthInsuranceDetails from '@/views/Leads/Verticals/HealthInsuranceDetails.vue';
import MedicareDetails from '@/views/Leads/Verticals/MedicareDetails.vue';

export default {
	name: 'LiveTransferResults',
	components: {
		LineLoader,
		verticalIcon,
		SkipModal,
		FailedTransferModal,
		ReportAccountModal,
		pConfirmDialog,
		MedicareDetails,
		HealthInsuranceDetails,
	},
	computed: {
		canSkip() {
			return this.auction_results.length > 0;
		},
		verticalDataComponent() {
			if (this.$route.params.vertical_id === 'health_insurance') {
				return 'HealthInsuranceDetails';
			} else {
				return 'MedicareDetails';
			}
		},
	},
	data() {
		return {
			show_lead_details: false,
			step: '',
			script: 'initial',
			loading: false,
			lead: null,
			auction_results: [],
			selected_bid: null,
			pending_purchase_id: '',
			auction_log_id: '',
			ping_id: '',
		};
	},
	async beforeRouteLeave(to, from) {
		try {
			if (this.selected_bid && this.pending_purchase_id && this.pending_purchase_id !== '') {
				const reason = `Leaving Result page for: ${to.name}`;

				const data = {
					...this.selected_bid,
					agent_id: this.$root.sessionStore.user.id,
					reason,
					notes: 'Pending Purchase existed and needed to be cleared out',
					lead_name: this.lead.first_name + ' ' + this.lead.last_name,
					lead_phone: this.lead.phone,
					lead_state: this.lead.state,
					vertical_id: this.$route.params.vertical_id,
					lead_id: this.$route.params.lead_id,
					purchase_id: this.pending_purchase_id,
				};
				this.cancelOutstandingPurchase(this.pending_purchase_id, 'skip', reason).catch((err) => {});
				logEvent('skipped_transfer', data).catch((err) => {
					console.error(err);
				});
			}
		} catch (err) {
			log.error('LTResults.beforeRouteLeave', 'error', {
				error: err,
				data: {
					...this.selected_bid,
					lead_id: this.$route.params.lead_id,
					pending_purchase: this.pending_purchase_id,
					agent_id: this.$root.sessionStore.user.id,
				},
			});
		}
	},
	methods: {
		get,
		phoneFormat,
		async backToForm() {
			this.$confirm.require({
				header: 'Back to Lead Question Form',
				message: `Going back to the question's page will invalidate auction results. Would you like to go back?`,
				accept: async () => {
					if (this.pending_purchase_id && this.pending_purchase_id !== '') {
						const reason = 'Going back to the Question form';
						const data = {
							...this.selected_bid,
							agent_id: this.$root.sessionStore.user.id,
							reason,
							notes: 'Not a real skip, just making an event for when we needed to go back to the questions.',
							lead_name: this.lead.first_name + ' ' + this.lead.last_name,
							lead_phone: this.lead.phone,
							lead_state: this.lead.state,
							vertical_id: this.$route.params.vertical_id,
							lead_id: this.$route.params.lead_id,
							purchase_id: this.pending_purchase_id,
						};
						this.cancelOutstandingPurchase(this.pending_purchase_id, 'skip', reason).catch((err) => {});

						logEvent('skipped_transfer', data).catch((err) => {
							console.error(err);
						});
					}

					const lead_route = {
						name: 'LiveTransferForm',
						params: {
							lead_id: this.lead.id,
							vertical_id: this.lead.vertical_id,
						},
					};
					this.$router.push(lead_route);
				},
			});
		},
		backToStart() {
			const lead_route = {
				name: 'LiveTransferStart',
			};
			this.$router.push(lead_route);
		},
		copyData() {
			copyToClipboard(this.selected_bid.phone);
			this.$toast.add({
				severity: 'success',
				summary: 'Copied to Clipboard',
				detail: `${this.selected_bid.phone}`,
				life: 3000,
			});
		},
		async completeTransfer() {
			this.loading = true;
			await this.initiatePurchase();
			this.loading = false;
			if (this.pending_purchase_id !== '') {
				this.$confirm.require({
					header: 'Confirm Transfer',
					message: `Was the hand off successful with ${this.selected_bid.display_name}?`,
					accept: async () => {
						try {
							this.loading = true;

							const res = await completePendingPurchase(this.pending_purchase_id, {
								status_changed_by: this.$root.sessionStore.user.id,
							});

							logEvent('completed_transfer', {
								...this.selected_bid,
								purchase_id: this.pending_purchase_id,
								agent_id: this.$root.sessionStore.user.id,
								lead_name: this.lead.first_name + ' ' + this.lead.last_name,
								lead_phone: this.lead.phone,
								lead_state: this.lead.state,
								vertical_id: this.$route.params.vertical_id,
								lead_id: this.$route.params.lead_id,
							}).catch((err) => {});

							this.selected_bid = null;

							this.$toast.add({
								severity: 'success',
								summary: 'Completed Transfer',
								life: 6000,
							});
							this.backToStart();
						} catch (err) {
							log.error('LTResults.completeTransfer', {
								error: err,
								data: {
									...this.selected_bid,
									purchase_id: this.pending_purchase_id,
									agent_id: this.$root.sessionStore.user.id,
									lead_id: this.$route.params.lead_id,
								},
							});

							if (err.message.indexOf('Pending transaction not found') > -1) {
								const data = {
									...this.selected_bid,
									agent_id: this.$root.sessionStore.user.id,
									reason: 'AUTO - Pending Transaction expired',
									notes: 'Time between the creation of the pending transaction and the completion',
									lead_name: this.lead.first_name + ' ' + this.lead.last_name,
									lead_phone: this.lead.phone,
									lead_state: this.lead.state,
									vertical_id: this.$route.params.vertical_id,
									lead_id: this.$route.params.lead_id,
								};
								if (this.pending_purchase_id && this.pending_purchase_id !== '') {
									data.purchase_id = this.pending_purchase_id;
									this.cancelOutstandingPurchase(this.pending_purchase_id, 'skip', 'AUTO - Timed Out').catch(
										(err) => {}
									);
								}
								logEvent('skipped_transfer', data)
									.then(() => {
										return this.handleNextBid();
									})
									.catch((err) => {
										console.error(err);
									});

								this.$toast.add({
									severity: 'error',
									summary: 'Unable to complete transfer',
									detail: `The time out for the auction result for ${this.selected_bid.display_name} has expired, auto skipping to the next bid, if possible`,
									life: 15000,
								});
							} else {
								const data = {
									...this.selected_bid,
									agent_id: this.$root.sessionStore.user.id,
									reason: 'ERROR - In Complete Purchase',
									notes: err.message,
									lead_name: this.lead.first_name + ' ' + this.lead.last_name,
									lead_phone: this.lead.phone,
									lead_state: this.lead.state,
									vertical_id: this.$route.params.vertical_id,
									lead_id: this.$route.params.lead_id,
								};
								if (this.pending_purchase_id && this.pending_purchase_id !== '') {
									data.purchase_id = this.pending_purchase_id;
									this.cancelOutstandingPurchase(this.pending_purchase_id, 'skip', 'AUTO - UNKNOWN ERROR').catch(
										(err) => {}
									);
								}
								logEvent('skipped_transfer', data).catch((err) => {
									console.error(err);
								});

								this.$toast.add({
									severity: 'error',
									summary: 'Unable to complete transfer',
									life: 10000,
								});
							}
						} finally {
							this.loading = false;
						}

						// go back to start
					},
					reject: async () => {
						this.loading = true;
						const data = {
							...this.selected_bid,
							agent_id: this.$root.sessionStore.user.id,
							reason: 'Rejected On Complete Transfer',
							notes: 'Agent hit "No" on the Completed Transfer window',
							lead_name: this.lead.first_name + ' ' + this.lead.last_name,
							lead_phone: this.lead.phone,
							lead_state: this.lead.state,
							vertical_id: this.$route.params.vertical_id,
							lead_id: this.$route.params.lead_id,
						};
						try {
							if (this.pending_purchase_id && this.pending_purchase_id !== '') {
								data.purchase_id = this.pending_purchase_id;
								this.cancelOutstandingPurchase(this.pending_purchase_id, 'skip', 'Confirmation Window Rejection').catch(
									(err) => {}
								);
							}
							logEvent('skipped_transfer', data).catch((err) => {
								console.error(err);
							});
						} catch (err) {
							log.error('LTResults.rejectedCompleteTransfer', 'error', {
								error: err,
								data: {
									...data,
									pending_purchase: this.pending_purchase_id,
									agent_id: this.$root.sessionStore.user.id,
									lead_id: this.$route.params.lead_id,
								},
							});
						} finally {
							this.loading = false;
							await this.handleNextBid();
						}
					},
				});
			}
		},
		async failTransfer(data) {
			this.loading = true;
			try {
				const log_data = {
					...this.selected_bid,
					agent_id: this.$root.sessionStore.user.id,
					reason: data.reason,
					notes: data.notes,
					lead_name: this.lead.first_name + ' ' + this.lead.last_name,
					lead_phone: this.lead.phone,
					lead_state: this.lead.state,
					vertical_id: this.$route.params.vertical_id,
					lead_id: this.$route.params.lead_id,
				};
				if (this.pending_purchase_id && this.pending_purchase_id !== '') {
					log_data.purchase_id = this.pending_purchase_id;
					// we will cancel with failed_ since complete window was lclick
					const canceled_pur = await this.cancelOutstandingPurchase(
						this.pending_purchase_id,
						'failed_transfer',
						data.reason
					);
				} else {
					const canceled_pur = await this.handleFailedPurchase('failed_transfer', data.reason);
					log_data.purchase_id = canceled_pur.id;
				}

				logEvent('failed_transfer', log_data)
					.then(() => {
						return this.handleNextBid();
					})
					.catch((err) => {});

				// emit the email to Buyer
				sendEmail({
					recipients: [{ account_id: this.selected_bid.account_id }],
					template: {
						id: '01J86APAYS6M4QFR0MKNJ84SPY',
						data: {
							campaign_name: this.selected_bid.campaign_name,
							date_time: new Date().toString(),
							phone: this.selected_bid.phone,
							lead_id: this.lead.id,
						},
					},
				}).catch((err) => {
					/// might not be worth to log this
				});
			} finally {
				this.loading = false;
			}
		},
		async skipTransfer(data) {
			this.loading = true;
			try {
				const log_data = {
					...this.selected_bid,
					agent_id: this.$root.sessionStore.user.id,
					reason: data.reason,
					notes: data.notes,
					lead_name: this.lead.first_name + ' ' + this.lead.last_name,
					lead_phone: this.lead.phone,
					lead_state: this.lead.state,
					vertical_id: this.$route.params.vertical_id,
					lead_id: this.$route.params.lead_id,
				};

				if (this.pending_purchase_id && this.pending_purchase_id !== '') {
					log_data.purchase_id = this.pending_purchase_id;
					this.cancelOutstandingPurchase(this.pending_purchase_id, 'skip', 'Skipping after Confirm Window').catch(
						(err) => {}
					);
				}

				logEvent('skipped_transfer', log_data)
					.then(() => {
						return this.handleNextBid();
					})
					.catch((err) => {
						console.error(err);
					});
			} catch (err) {
				log.error('LTResults.skipTransfer', {
					error: err,
					data: {
						...data,
						...this.selected_bid,
						pending_purchase: this.pending_purchase_id,
						agent_id: this.$root.sessionStore.user.id,
						lead_id: this.$route.params.lead_id,
					},
				});
			} finally {
				this.loading = false;
			}
		},
		async handleNextBid() {
			// if there was a pending purchase, lets clear it out, it should have been canceled by now
			this.pending_purchase_id = '';

			if (this.canSkip) {
				this.selected_bid = this.auction_results.shift();
				this.$toast.add({
					severity: 'info',
					summary: 'Moving on to the next buyer',
					detail: 'Contact the new buyer and let the Lead know that you are still trying to find an agent',
					life: 10000,
				});
			} else {
				// this should log an event

				logEvent('unsold_transfer', {
					agent_id: this.$root.sessionStore.user.id,
					ping_id: this.ping_id,
					auction_log_id: this.auction_log_id,
					lead_name: this.lead.first_name + ' ' + this.lead.last_name,
					lead_phone: this.lead.phone,
					lead_state: this.lead.state,
					vertical_id: this.$route.params.vertical_id,
					lead_id: this.$route.params.lead_id,
				}).catch((err) => {
					console.error(err);
				});

				this.step = 'no_bids';
				this.script = 'no_bids';
				this.selected_bid = null;
				this.$toast.add({
					severity: 'warn',
					summary: 'There are no more bids for this lead....',
					life: 20000,
				});
			}
		},
		async reportBuyer(data) {
			// close out the pending purchase and add information
			this.loading = true;
			try {
				// send email to AMsk
				const account_res = await getAccountSupportSettings(this.selected_bid.account_id);
				sendEmail({
					recipients: [{ user_id: get(account_res, 'account.support.account_manager_id') }],
					template: {
						id: '01J86B0BNW40QTNZZDHPYZSP3M',
						data: {
							lta_name: `${this.$root.sessionStore.user.first_name} ${this.$root.sessionStore.user.last_name}`,
							message: data.notes,
							mpid: this.selected_bid.account_id.slice(0, 3),
							campaign_id: this.selected_bid.campaign_id,
							account_id: this.selected_bid.account_id,
							account_name: this.selected_bid.display_name,
						},
					},
				}).catch((err) => {
					/// might not be worth to log this
				});

				const log_data = {
					...this.selected_bid,
					agent_id: this.$root.sessionStore.user.id,
					reason: 'reported_buyer',
					notes: data.notes,
					lead_name: this.lead.first_name + ' ' + this.lead.last_name,
					lead_phone: this.lead.phone,
					vertical_id: this.$route.params.vertical_id,
					lead_state: this.lead.state,
					lead_id: this.$route.params.lead_id,
				};

				if (this.pending_purchase_id && this.pending_purchase_id !== '') {
					log_data.purchase_id = this.pending_purchase_id;
					// we will cancel with failed_ since complete window was lclick
					const canceled_pur = await this.cancelOutstandingPurchase(
						this.pending_purchase_id,
						'report_buyer',
						'Details in Log'
					);
				} else {
					const canceled_pur = await this.handleFailedPurchase('report_buyer');
					log_data.purchase_id = canceled_pur.id;
				}

				logEvent('report_buyer', log_data)
					.then(() => {
						return this.handleNextBid();
					})
					.catch((err) => {
						console.error(err);
					});
			} catch (err) {
				log.error('LTResults.reportBuyer', {
					error: err,
					data: {
						...data,
						...this.selected_bid,
						pending_purchase: this.pending_purchase_id,
						agent_id: this.$root.sessionStore.user.id,
						lead_id: this.$route.params.lead_id,
					},
				});
			} finally {
				this.loading = false;
			}
		},
		async handleFailedPurchase(type, reason) {
			try {
				const res = await createCanceledPurchase({
					mpid: this.selected_bid.account_id.slice(0, 3),
					lead_id: this.$route.params.lead_id,
					campaign_id: this.selected_bid.campaign_id,
					auction_log_id: this.selected_bid.auction_log_id,
					price: this.selected_bid.bid,
					data: {
						agent_id: this.$root.sessionStore.user.id,
						status_message: `${type} ${reason ? ' - ' + reason : ''}`,
						status_changed_by: this.$root.sessionStore.user.id,
					},
				});
				return res;
			} catch (err) {
				log.error('LTResults.handleFailedPurchase', {
					error: err,
					data: {
						...this.selected_bid,
						type,
						reason,
						agent_id: this.$root.sessionStore.user.id,
						lead_id: this.$route.params.lead_id,
					},
				});
			}
		},
		async cancelOutstandingPurchase(purchase_id, type, reason) {
			// this gets called when you want to move on to the next bid
			try {
				const data_to_merge = {
					status_changed_by: this.$root.sessionStore.user.id,
					status_message: `${type} - ${reason}`,
				};

				if (type === 'skip') {
					data_to_merge.ignore = true; // since the AM is skipping the pendingPurchase, we should not count it toward Cancel Rate
				}
				const res = await cancelPendingPurchase(purchase_id, data_to_merge);
				return res;
			} catch (err) {
				log.error('LTResults.cancelOutstandingPurchase', {
					error: err,
					data: {
						purchase_id,
						type,
						reason,
						agent_id: this.$root.sessionStore.user.id,
						lead_id: this.$route.params.lead_id,
					},
				});
				this.$toast.add({
					severity: 'error',
					summary: `Pending Purchase ${this.purchase_id} was not canceled correctly`,
				});
			}
		},
		async initiatePurchase() {
			// this.script = 'initial';
			const params = {
				mpid: this.selected_bid.account_id.slice(0, 3),
				lead_id: this.$route.params.lead_id,
				campaign_id: this.selected_bid.campaign_id,
				auction_log_id: this.selected_bid.auction_log_id,
				price: this.selected_bid.bid,
				data: {
					agent_id: this.$root.sessionStore.user.id,
				},
			};
			try {
				const res = await createPendingPurchase(params);

				this.pending_purchase_id = res.id;
			} catch (err) {
				log.error('LTResults.initiatePurchase', {
					error: err,
					data: {
						...params,
						agent_id: this.$root.sessionStore.user.id,
					},
				});

				if (err.message.indexOf('Insufficient') > -1) {
					this.$toast.add({
						severity: 'error',
						summary: 'Buyer did not have enough funds',
						detail: `Auto skipping to the next buyer if we can`,
						life: 20000,
					});

					await this.skipTransfer({ notes: '', reason: 'Insufficient Funds' });
				} else if (err.message.indexOf('purchase already exists') > -1) {
					this.$toast.add({
						severity: 'error',
						summary: 'Buyer already purchased this lead previously',
						detail: `Auto skipping to the next buyer if we can`,
						life: 20000,
					});

					await this.skipTransfer({ notes: '', reason: 'Duplicate Purchase' });
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to create purchase for buyer',
						detail: `Auto skipping from ${this.selected_bid.display_name} to the next buyer if we can`,
						life: 20000,
					});
					await this.skipTransfer({ notes: err.message, reason: 'Unknown Error' });
				}
			}
		},
		async runAuction() {
			this.loading = true;
			this.step = 'auctioning';
			try {
				const data = {
					lead_id: this.$route.params.lead_id,
					vertical_id: this.$route.params.vertical_id,
				};

				const res = await runLTAuction(data);

				const status = get(res, 'status');
				this.ping_id = get(res, 'details.ping_id', 'UNKNOWN');
				this.auction_results = get(res, 'details.bids', []);
				if (status === 'NO_BIDS' && this.auction_results.length === 0) {
					this.step = 'no_bids';
					this.script = 'no_bids';
					logEvent('unsold_transfer', {
						agent_id: this.$root.sessionStore.user.id,
						ping_id: this.ping_id,
						lead_name: this.lead.first_name + ' ' + this.lead.last_name,
						lead_phone: this.lead.phone,
						lead_state: this.lead.state,
						vertical_id: this.$route.params.vertical_id,
						lead_id: this.$route.params.lead_id,
					}).catch((err) => {
						console.error(err);
					});
				} else {
					this.script = 'initial';
					this.ping_id = get(res, 'data.details.ping_id');
					this.selected_bid = this.auction_results.shift();
					this.step = 'auction_loaded';

					this.auction_log_id = get(this.selected_bid, 'auction_log_id', 'UNKNOWN');
					// NO LONGER CREATING PENDING PURCHASES UNTIL THE Dialog step...
				}
			} catch (err) {
				log.error('LTResults.runAuction', {
					error: err,
					data: {
						lead_id: this.$route.params.lead_id,
						vertical_id: this.$route.params.vertical_id,
						agent_id: this.$root.sessionStore.user.id,
					},
				});
				this.$toast.add({
					severity: 'error',
					summary: 'Auction had an error, refresh page to attempt again',
					life: 10000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getLead() {
			try {
				const res = await getLead(this.$route.params.lead_id);
				this.lead = res;
			} catch (err) {
				log.error('LTResults.getLead', {
					error: err,
					data: { lead_id: this.$route.params.lead_id, agent_id: this.$root.sessionStore.user.id },
				});
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to load the lead...',
					life: 4000,
				});
			}
		},
	},
	async mounted() {
		this.runAuction().catch((err) => {});
		this.getLead().catch((err) => {});
	},
};
</script>

<style lang="less" scoped>
.lead-info {
	align-items: center;
	color: var(--gray-50);
	display: flex;
	font-weight: bold;
	gap: 10px;
}
.clickable {
	&:hover {
		cursor: pointer;
	}

	&-phone {
		&:hover {
			cursor: pointer;
		}
		text-decoration: underline;
		color: var(--color-b);
	}
}
.campaign_name {
	font-size: 1.15em;
}

.lead-details-box {
	//box-shadow: 2px 2px 3px 0 fade(var(--black), 15%);
	box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
	position: absolute;
	right: -340px;
	//top: 0;
	z-index: 200;

	.lead-tab {
		background-color: var(--white);
		border-radius: 3px 3px 0 0;
		border: 2px solid var(--gray-15);
		border-bottom: 0;
		padding: 0 10px;
		position: absolute;
		right: -2px;
		top: -2px;
	}

	.lead-content {
		background-color: var(--white);
		border-radius: 3px 0 3px 3px;
		box-shadow: 0 0 0 2px var(--gray-15);
		//margin-top: 44px;
		padding: 20px;
		width: 600px;

		h3 {
			//color: ;
			//font-family: @body-font;
			font-size: 1.125rem;
			font-weight: bold;
			margin-bottom: 0.5em;
			text-transform: uppercase;
		}

		.contact-info {
			margin-bottom: 2em;
		}

		.line-item {
			align-items: flex-start;
			color: var(--gray-65);
			font-size: 0.875rem;
			line-height: 1.5em;
			padding: 0.25em 0;

			.label {
				flex: 0 0 200px;
				font-weight: bold;
			}

			&.address {
				margin-top: 1em;
			}
		}
	}
}

.lead-detail-button {
	display: inline-block;
	padding: 10px 20px;
	font-size: 16px;
	font-weight: bold;
	//color: var(--white);
	background-color: var(--white);
	//background-color: #007bff; /* Button background color */
	border: 2px solid var(--gray-15); /* Border color */
	border-radius: 5px; /* Rounded corners */
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
	cursor: pointer; /* Pointer cursor to mimic button */
	text-align: center;
	transition:
		background-color 0.3s ease,
		box-shadow 0.3s ease;

	&:hover {
		//background-color: #0056b3; /* Darken the button on hover */
		box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); /* Slightly increase shadow on hover */
	}
}

.last_bid {
	font-size: 16px;
	font-style: italic;
	font-weight: bold;
	color: var(--red);
}
.lead-details-dropdown {
	border-left: 1px solid var(--gray-15);
	color: var(--gray-85);
	padding-left: 15px;
	position: relative;
}
</style>
<style>
.larger-icon {
	.p-button-icon {
		font-size: 1.5rem !important;
		font-weight: bold;
	}
}
.bolder {
	.p-button-icon {
		font-size: 1.15rem;
		font-weight: bold;
	}
}
</style>
