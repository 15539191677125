import SocketTest from '@/views/Socket/Page.vue';

export default [
	{
		path: 'socket-test',
		name: 'SocketTest',
		component: SocketTest,
		meta: {
			user_type: 'admin',
			title: 'Socket Tester',
			permissions: 'admin.sockets',
		},
	},
];
