import { $GQL } from '@GQL/index';
export async function reportRecentSignUps(
	params: { date_range: [string, string]; pagination: { page: number; page_size: number } },
	refresh?: boolean
) {
	const query = `
        query ReportRecentSignUps($params: JSONObject!) {
            reportRecentSignUps(params: $params) {
				rows
				row_count
			}
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportRecentSignUps;
}
