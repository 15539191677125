import { $GQL } from '@GQL/index';
import type { AutoFundConfig } from '@nextgenleads/banker-driver';

export async function updateAutoFundConfig(
	account_id: string,
	data: Omit<AutoFundConfig, 'created_at' | 'modified_at'>
): Promise<{ account_id: string }> {
	const mutation = `
        mutation UpdateAutoFundConfig($account_id: String!, $data: JSONObject!){
            updateAutoFundConfig(account_id: $account_id, data: $data) {
                account_id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data, account_id });
	return result.updateAutoFundConfig;
}
