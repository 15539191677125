<template>
	<div id="edit-role" class="view-content">
		<div class="page-heading">
			<h1>{{ heading }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<CaptureConfigForm />
	</div>
</template>

<script lang="ts">
import CaptureConfigForm from './Form.vue';
export default {
	name: 'CaptureConfigsRecordPage',
	components: {
		CaptureConfigForm,
	},
	data() {
		const is_create = this.$route.path.indexOf('create') > -1 ? true : false;
		return {
			is_create,
			heading: is_create ? 'New Capture Config' : 'Edit Capture Config',
			breadcrumb_items: [
				{ label: 'Lead Capture Configs', route: '/capture-configs' },
				{ label: is_create ? 'New Capture Config' : 'Edit Capture Config' },
			],
			loading: false,
			tableKey: 0,
		};
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
