<template>
	<div class="lt-question dashed">
		<div class="lt-question-content">
			<p>What language does the person on the phone speak?</p>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-40" style="margin-bottom: 0">
						<div v-for="option in language_options" class="field fit">
							<div class="flex align-items-center gap-10">
								<p-radio-button
									:input-id="`spoken_language_${option.value}`"
									v-model="spoken_language"
									:value="option.value"
								/>
								<label :for="`spoken_language_${option.value}`" class="radio-label">{{ option.label }}</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import get from 'lodash/get';
export default {
	name: 'LTQuestion_SpokenLanguage',
	emits: ['update'],
	props: {
		lead: {
			type: Object,
			required: true,
		},
		loaded: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			language_options: [
				{ label: 'English', value: 'english' },
				{ label: 'Spanish', value: 'spanish' },
				{ label: 'Other', value: 'other' },
			],
		};
	},
	computed: {
		spoken_language: {
			get() {
				return get(this.lead, 'spoken_language', null);
			},
			set(new_value) {
				this.$emit('update', {
					spoken_language: new_value,
				});
			},
		},
	},
	watch: {
		loaded(new_value, old_value) {
			if (new_value && old_value !== new_value) {
				if (!this.lead.spoken_language) {
					this.$emit('update', {
						spoken_language: 'english',
					});
				}
			}
		},
	},
	async mounted() {},
};
</script>

<style lang="less" scoped>
.dashed {
	border: 2px dashed var(--gray-15);
}
</style>
