import { $GQL } from '@GQL';
import type { Queue } from '@nextgenleads/lead-driver';

export async function getQueueById(id: string): Promise<Omit<Queue, 'created_at' | 'modified_at'>> {
	const query = `
        query GetQueueById($id: ID!) {
            queue(id: $id) {
                id
                name
                description
                interval
                limit
                min_delay
                actions
                settings
                status
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.queue;
}
