import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';
import { type Promotion } from '@nextgenleads/marketplace-driver';

export async function getPromotions(filters: QueryFilters): Promise<Promotion[]> {
	const query = `
    query GetPromotions($filters: [[Any]]!) {
        promotions(filters: $filters) {
			id
			mpid
			name
			icon_url
			description
			restrictions
			custom_filter
			vertical_id
			product
			bid_types
			start_date
			end_date
			allow_source_filtering
			allow_attribute_filtering
			allow_schedule
			exclusive_minimum_bid
			shared_minimum_bid
			status
			created_at
        }
    }`;

	const request = await $GQL.request(query, { filters });
	return request.promotions;
}
