<template>
	<p-card>
		<template #title>
			<div class="flex flex-column pb-2">
				<h3>Account Performance</h3>
				<div class="sub-value">Trailing 2 Weeks</div>
			</div>
		</template>
		<template #content>
			<LineChart :chart-data="chart_data" :custom-options="options" />
		</template>
	</p-card>
</template>

<script lang="ts">
import LineChart from '@/components/charts/Line.vue';
import pCard from 'primevue/card';

export default {
	name: 'SpendVelocity',
	components: {
		LineChart,
		pCard,
	},
	data() {
		return {
			dataset: {
				last_quarter: 1042.44,
				last_month: 1232,
				last_week: 582,
				yesterday: 3086,
				today: 2308,
			},
			options: {
				plugins: {
					legend: {
						display: false,
					},
				},
				layout: {
					padding: 0,
				},
			},
		};
	},
	computed: {
		chart_data() {
			const documentStyle = getComputedStyle(document.documentElement);

			return {
				labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
				datasets: [
					{
						label: 'First Dataset',
						data: [65, 59, 80, 81, 56, 55, 40],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--blue-500'),
						tension: 0.4,
					},
					{
						label: 'Second Dataset',
						data: [28, 48, 40, 19, 86, 27, 90],
						fill: false,
						borderColor: documentStyle.getPropertyValue('--pink-500'),
						tension: 0.4,
					},
				],
			};
		},
	},
};
</script>
