<template>
	<div id="signup">
		<AttachOfferModal :account_id="accountId" @refresh="loadSettings(true)" />
		<gutter size="10px" />
		<div class="content">
			<div class="control-group">
				<div class="inner">
					<label class="control-label" for="source-id">Source ID:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="source-id"
								v-model="account.signup_source.source_id"
								:options="source_id_options"
								optionValue="value"
							>
								<template #value="opt">
									{{ opt.value }}
								</template>
								<template #option="opt" class="flex">
									<div>
										{{ opt.option.value }} <br />
										<div class="sub-value">{{ opt.option.description }}</div>
									</div>
								</template>
							</p-dropdown>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label" for="source-id">Primary Vertical:</label>
					<div class="controls">
						<div class="field">
							<p-dropdown
								id="primary-vertical"
								v-model="account.signup_data.primary_vertical"
								option-label="label"
								option-value="value"
								:options="vertical_options"
								placeholder="Select vertical"
							>
							</p-dropdown>
						</div>
					</div>
				</div>
			</div>
			<ul class="table-list">
				<div class="heading">Sign Up Source</div>
				<li v-if="account.signup_source.s1">
					<div class="label">Sub 1</div>
					<div class="value">
						{{ account.signup_source.s1 || 'N/A' }}
					</div>
				</li>
				<li v-if="account.signup_source.s2">
					<div class="label">Sub 2</div>
					<div class="value">
						{{ account.signup_source.s2 || 'N/A' }}
					</div>
				</li>
				<li v-if="account.signup_source.s3">
					<div class="label">Sub 3</div>
					<div class="value">
						{{ account.signup_source.s3 || 'N/A' }}
					</div>
				</li>
				<li v-if="account.signup_source.s4">
					<div class="label">Sub 4</div>
					<div class="value">
						{{ account.signup_source.s4 || 'N/A' }}
					</div>
				</li>
				<li v-if="account.signup_source.s5">
					<div class="label">Sub 5</div>
					<div class="value">
						{{ account.signup_source.s5 || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">Internal Referral</div>
					<div class="value">
						{{ account.signup_source.internal_referral || 'N/A' }}
					</div>
				</li>
			</ul>
			<ul class="table-list" v-if="account.signup_data.phone_validation">
				<div class="heading">Phone Validation</div>
				<li>
					<div class="label">Phone Checked</div>
					<div class="value">
						{{ account.signup_data.phone_validation.phone_number || 'N/A' }}
					</div>
				</li>
				<li
					v-if="account.signup_data.phone_validation.activity_score"
					v-tooltip.top="{ value: activity_score_explanation, escape: false }"
				>
					<div class="label">Activity Score</div>
					<div
						class="value"
						:class="{
							red: account.signup_data.phone_validation.activity_score < 60,
							green: account.signup_data.phone_validation.activity_score >= 60,
						}"
					>
						{{ account.signup_data.phone_validation.activity_score || 'N/A' }}
					</div>
				</li>
				<li v-if="account.signup_data.phone_validation.carrier">
					<div class="label">Carrier</div>
					<div class="value">
						{{ account.signup_data.phone_validation.carrier || 'N/A' }}
					</div>
				</li>
				<li
					v-if="account.signup_data.phone_validation.line_type"
					v-tooltip.top="{ value: line_type_explanation, escape: false }"
				>
					<div class="label">Phone Line Type</div>
					<div class="value">
						{{ account.signup_data.phone_validation.line_type || 'N/A' }}
					</div>
				</li>
				<li v-if="account.signup_data.phone_validation.is_prepaid">
					<div class="label">Is Prepaid</div>
					<div class="value">
						{{ account.signup_data.phone_validation.is_prepaid || 'N/A' }}
					</div>
				</li>
				<li
					v-if="
						account.signup_data.phone_validation.warnings && account.signup_data.phone_validation.warnings.length > 0
					"
				>
					<div class="label">Warnings</div>
					<div class="value">
						{{ (account.signup_data.phone_validation.warnings || []).join(',') || 'N/A' }}
					</div>
				</li>
			</ul>
			<ul class="table-list">
				<div class="heading">Sign Up Data Details</div>
				<li>
					<div class="label">Primary Vertical</div>
					<div class="value">
						{{ capitalize(account.signup_data.primary_vertical || 'N/A') }}
					</div>
				</li>
				<li>
					<div class="label">Lead Types</div>
					<div class="value">
						{{ (account.signup_data.products || []).join(', ') || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">Agency Size</div>
					<div class="value">
						{{ account.signup_data.agency_size || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">Offer ID</div>
					<div class="value">
						{{ account.signup_data.offer_id || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">Referred From</div>
					<div class="value">
						{{ account.signup_data.referred_from || 'N/A' }}
					</div>
				</li>
				<li>
					<div class="label">Signup Score</div>
					<div class="value">
						{{ account.signup_data.signup_score || 'N/A' }}
					</div>
				</li>
			</ul>
		</div>
		<div class="footer">
			<p-button label="SAVE SETTINGS" icon="pi pi-check" @click="updateSettings" />
		</div>
	</div>
</template>

<script lang="ts">
import '@/lib/Utils/isDst';
import { useSessionStore } from '@/stores/session';
import { getAccountSignUpSettings, getSignUpSourceIdsAsOptions, updateAccount } from '@GQL';
import AttachOfferModal from '@/views/Offers/Modals/AttachOffer.vue';
import log from '@/lib/Log';
import { capitalize } from '@/lib/Filters';
import { vertical_options } from '@/lib/Options';
import { type Account } from '@nextgenleads/marketplace-driver';

const line_type_explanation = `<ul>
  <li><strong>Landline</strong> - Traditional wired phone line.</li>
  <li><strong>Mobile</strong> - Wireless phone line.</li>
  <li><strong>FixedVOIP</strong> - VOIP number connected to a physical address, such as Vonix or Comcast.</li>
  <li><strong>NonFixedVOIP</strong> - VOIP number unconnected to a fixed physical address, such as Google Voice or Skype.</li>
  <li><strong>Premium</strong> - Caller pays a premium for the call–e.g. 976 area code.</li>
  <li><strong>TollFree</strong> - Callee pays for call.</li>
  <li><strong>Voicemail</strong> - Voicemail-only service.</li>
  <li><strong>Other</strong> - Line type is unclear or does not match current categories.</li>
</ul>`;
const activity_score_explanation = `<ul>
<li>Prioritize dialing good numbers with consistent activity: Scores 70 or above.</li>
<li>Weed out leads with disconnected/inactive numbers: Scores of 30 or below.</li>
</ul>`;
export default {
	name: 'AccountSignUp',
	components: {
		AttachOfferModal,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	data() {
		return {
			vertical_options,
			account: {
				name: null,
				mpid: null,
				signup_data: {
					primary_vertical: null,
					products: null,
					agency_size: null,
					referred_from: null,
					signup_score: null,
					phone_validation: null,
				},
				signup_source: {
					source_id: null,
					s1: null,
					s2: null,
					s3: null,
					s4: null,
					s5: null,
					referred_form: null,
					internal_referral: false,
				},
			},
			line_type_explanation,
			activity_score_explanation,
			source_id_options: [],
			offer_id: null,
		};
	},
	computed: {
		canModifySourceId() {
			// based on type of sign up source set, it can be immutable by non ADMINS
		},
		accountId() {
			return this.$route.params.account_id || this.$route.params.parent_account_id || this.sessionStore.account.id;
		},
	},
	async created() {
		this.source_id_options = await getSignUpSourceIdsAsOptions();
		return this.loadSettings();
	},
	methods: {
		capitalize,
		async loadSettings(refresh?: boolean) {
			try {
				const results = await getAccountSignUpSettings(this.accountId, refresh);
				this.account = results.account;
				if (this.account.signup_data) {
					this.offer_id = this.account.signup_data.offer_id;
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Sign Up settings',
				});
				log.trace(err);
			}
		},
		async updateSettings() {
			const update_obj: Partial<Account> = {};

			if (this.account.signup_source.source_id) {
				update_obj.signup_source = {
					internal_referral: this.account.signup_source.internal_referral,
					source_id: this.account.signup_source.source_id,
				};
			}

			if (this.account.signup_data.primary_vertical) {
				update_obj.signup_data = {
					primary_vertical: this.account.signup_data.primary_vertical,
				};
			}

			try {
				const result = await updateAccount(this.accountId, update_obj);
				if (result) {
					this.$toast.add({
						severity: 'success',
						summary: 'Sign Up Data Updated',
						life: 3000,
					});
					this.loadSettings(true);
				}
			} catch (err) {
				log.trace(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to update account Sign Up Data',
					life: 6000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
.field {
	width: 100%;
}

.account-closure {
	color: var(--gray-50);
}

.account-closure-button {
	display: flex;
	justify-content: center;
}

.red {
	color: var(--red);
}
.green {
	color: var(--green);
}

.footer {
	padding-top: 1.5rem;
	text-align: right;
	width: 100%;
}
</style>
