<template>
	<div class="notifications-list">
		<div class="notifications-heading flex align-items-center">
			<icon type="bell-outline" size="1.5rem" />
			<h2>My Notifications</h2>
		</div>
		<line-loader :show="loading" />
		<div class="notifications-wrapper">
			<div
				v-for="(receipt, index) in receipts"
				:key="index"
				class="notifcation-container"
				:class="{ read: receipt.action === 'Read' }"
			>
				<div class="details">
					<div class="sender">
						{{ receipt.notification.created_by_user.first_name }} {{ receipt.notification.created_by_user.last_name }}
					</div>
					<div class="message">
						<span v-html="receipt.notification.message"></span>
					</div>
					<div class="date">
						{{ formatDate(new Date(receipt.created_at)) }}
					</div>
				</div>
				<div class="tools">
					<p-button
						v-if="receipt.action !== 'Read'"
						v-tooltip.top="'Mark as Read'"
						icon="pi pi-check"
						text
						@click="markRead(receipt.id)"
					/>
				</div>
			</div>
			<p v-if="receipts.length === 0">No notifications</p>
			<p-paginator
				v-model:first="page"
				:rows="pagination.page_size"
				:total-records="row_count"
				@page="handlePageChange"
			/>
		</div>
	</div>
</template>

<script lang="ts">
import pPaginator from 'primevue/paginator';
import pProgressSpinner from 'primevue/progressspinner';
import { dismissNotificationReceipt, getNotificationReceiptList } from '@GQL';
import { useSessionStore } from '@/stores/session';
import { format, formatDistanceToNow, intervalToDuration } from 'date-fns';
import { GetListParams } from '@nextgenleads/db';

export default {
	name: 'NotificationsList',
	setup() {
		return {
			sessionStore: useSessionStore(),
		};
	},
	components: {
		pPaginator,
		pProgressSpinner,
	},
	emits: ['dismissNotification'],
	data() {
		return {
			receipts: [],
			row_count: null,
			page: 0,
			pagination: {
				page_size: 5,
				page: 0,
			},
			loading: false,
		};
	},
	async mounted() {
		await this.getNotificationReceipts(true);
	},
	methods: {
		async getNotificationReceipts(refresh?: boolean) {
			this.loading = true;
			const notification_params: GetListParams = {
				filters: [
					[
						{
							field: 'user_id',
							op: 'eq',
							value: this.sessionStore.user.id,
						},
					],
				],
				order: {
					field: 'created_at',
					desc: true,
				},
				pagination: this.pagination,
			};

			try {
				const result = await getNotificationReceiptList(notification_params, refresh);
				this.row_count = result.row_count;
				this.receipts = result.rows;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get notifications',
					detail: err.response.errors[0]?.message || err.message,
					life: 6000,
				});
			} finally {
				this.loading = false;
			}
		},
		handlePageChange(data) {
			this.pagination.page = data.page;
			return this.getNotificationReceipts(true);
		},
		formatDate(date: Date) {
			const currentDate = new Date();
			const difference = intervalToDuration({ start: date, end: currentDate });

			if (difference.days >= 1) {
				return format(date, 'EEE, PPPp');
			} else {
				return `${formatDistanceToNow(date)} ago`;
			}
		},
		async markRead(id) {
			try {
				await dismissNotificationReceipt(id);
				this.$toast.add({
					severity: 'success',
					summary: 'Marked Notification as Read',
					life: 3000,
				});
				const dismissed_receipt = this.receipts.find((receipt) => {
					return receipt.id === id;
				});
				dismissed_receipt.action = 'Read';
				this.$emit('dismissNotification', id);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to dismiss notifications',
					detail: err.response.errors[0]?.message || err.message,
					life: 6000,
				});
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.notifications-heading {
	margin-bottom: 1rem;

	.mdi-icon {
		color: var(--color-a);
	}

	h2 {
		font-size: 1.25rem;
		margin: 2px 0 0 10px;
	}
}

.notifications-list {
	padding: 20px;
}

.notifcation-container {
	align-items: center;
	background-color: var(--white);
	border-radius: 4px;
	box-shadow: var(--shadow-1);
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	justify-content: space-between;
	margin-bottom: 1rem;
	max-width: 400px;
	padding: 1rem;

	&.read {
		background-color: var(--surface-a);

		.message {
			color: var(--gray-50);
		}
	}
}

.details {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.sender {
	font-size: var(--font-size-base);
	font-weight: bold;
}

.message {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}

.date {
	color: var(--gray-50);
	font-size: var(--font-size-sm);
	font-weight: normal;
	margin: 0;
}

.tools {
	min-width: 40px;
}
</style>
