<template>
	<div class="select-mode">
		<h2>Select an Editing Mode</h2>
		<div class="mode-options">
			<template v-for="mode in mode_options" :key="mode.id">
				<div :class="[{ selected: selected_mode === mode.id }]" class="option shadow-2" @click="selectMode(mode.id)">
					<div class="card-header" :style="{ 'background-image': 'url(' + mode.icon_url + ')' }"></div>
					<div class="content">
						<div class="name">{{ mode.name }}</div>
						<div class="description">{{ mode.description }}</div>
						<p-button v-if="selected_mode === mode.id" class="selected w-full" label="SELECTED ✓" />
						<p-button v-else label="SELECT" class="w-full" outlined />
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script lang="ts">
import { get } from 'lodash-es';
import actionBar from '../Components/ActionBar.vue';
import { useCampaignStore } from '@/stores/campaign';
import { mapWritableState } from 'pinia';

export default {
	name: 'SelectMode',
	components: {
		actionBar,
	},
	emits: ['skip', 'select'],
	props: {
		skipPromotion: Boolean,
		skipChannel: Boolean,
	},
	data() {
		return {
			mode_options: [
				{
					id: 'basic',
					icon_url: '/assets/img/icons/icon-basic.jpg',
					name: 'Basic',
					description:
						'Quick and easy to set up with powerful targeting tools that satisfy the needs of most lead buyers.',
				},
				{
					id: 'advanced',
					icon_url: '/assets/img/icons/icon-advanced.jpg',
					name: 'Advanced',
					description:
						'Everything you get with Basic, plus advanced options like bid modifiers. Ideal for experienced buyers looking to maximize performance.',
				},
			],
			selected_mode: 'basic',
		};
	},
	computed: {
		...mapWritableState(useCampaignStore, ['campaign']),
	},
	methods: {
		prev() {
			if (this.skipChannel && this.skipPromotion) {
				return 'select_product';
			} else if (this.skipChannel) {
				return 'select_promotion';
			}
			return 'select_channel';
		},
		next() {
			if (this.selected_mode) {
				this.campaign.mode = this.selected_mode;

				// Add bids for location and filters for basic
				if (this.selected_mode === 'basic') {
					this.campaign.bids.push({
						name: 'Basic Location Targeting',
						type: 'location',
						match: [
							{
								target: {
									path: 'lead.state',
								},
								strategy: 'one_of',
								comparator: {
									value: [],
								},
							},
						],
						method: 'multiply',
						amount: 1,
						status: 'active',
						finalize: false,
						custom_minimum_bid: false,
						minimum_bid: 0,
						dq_reason: 'Location excluded from targeting',
					});
				}

				return 'edit_campaign';
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'No Mode Set',
					detail: 'Please select an editing mode.',
					life: 3000,
				});
				return null;
			}
		},
		selectMode(value) {
			this.selected_mode = value;
			this.$emit('select');
		},
	},
	async mounted() {
		this.selected_mode = this.campaign.mode;
		this.$emit('select');
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

h2 {
	font-size: var(--font-size-h4);
}

.select-mode {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.mode-options {
	display: flex;
	flex: 0 0 auto;
	flex-wrap: wrap;
	gap: 1rem;
	margin: 1rem 0;

	.mobile({
		justify-content: center;
	});

	.option {
		background: var(--white);
		border-radius: 5px;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-right: 20px;
		max-width: 320px;
		min-height: 320px;
		text-align: center;
		transition: all 150ms ease-out;

		.card-header {
			background-position: 50% 50%;
			background-size: cover;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
			height: 200px;
			width: 100%;
		}

		.content {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			justify-content: space-between;
			padding: 40px;
		}

		.name {
			font-size: 1.125rem;
			font-weight: bold;
			margin: 0 0 1rem;
		}

		.description {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
			line-height: 1.5;
			margin-bottom: 1rem;
		}

		&.selected {
			box-shadow: 0 0 0 3px var(--color-b) !important;
		}
	}
}

.no-vertical-msg {
	align-items: center;
	border: 3px dashed var(--gray-15);
	color: var(--gray-35);
	display: flex;
	height: 100%;
	line-height: 1.35em;
	padding: 40px;
}
</style>
