<template>
	<div v-if="editor">
		<div class="toolbar">
			<button
				@click="editor.chain().focus().toggleBold().run()"
				:disabled="!editor.can().chain().focus().toggleBold().run()"
				:class="{ 'is-active': editor.isActive('bold') }"
				v-tooltip.top="'Bold'"
			>
				<alt-icon type="format_bold" size="18px" />
			</button>
			<button
				@click="editor.chain().focus().toggleItalic().run()"
				:disabled="!editor.can().chain().focus().toggleItalic().run()"
				:class="{ 'is-active': editor.isActive('italic') }"
				v-tooltip.top="'Italic'"
			>
				<alt-icon type="format_italic" size="18px" />
			</button>
			<button
				@click="editor.chain().focus().toggleStrike().run()"
				:disabled="!editor.can().chain().focus().toggleStrike().run()"
				:class="{ 'is-active': editor.isActive('strike') }"
				v-tooltip.top="'Strike'"
			>
				<alt-icon type="format_strikethrough" size="18px" />
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().setParagraph().run()"
				:class="{ 'is-active': editor.isActive('paragraph') }"
				v-tooltip.top="'Paragraph'"
			>
				<alt-icon type="format_paragraph" size="18px" />
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
				:class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
				v-tooltip.top="'Heading 1'"
			>
				H1
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
				:class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
				v-tooltip.top="'Heading 2'"
			>
				H2
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
				:class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
				v-tooltip.top="'Heading 3'"
			>
				H3
			</button>
			<button
				@click="editor.chain().focus().toggleBulletList().run()"
				:class="{ 'is-active': editor.isActive('bulletList') }"
				v-tooltip.top="'Bullet List'"
			>
				<alt-icon type="format_list_bulleted" size="18px" />
			</button>
			<button
				@click="editor.chain().focus().toggleOrderedList().run()"
				:class="{ 'is-active': editor.isActive('orderedList') }"
				v-tooltip.top="'Numbered List'"
			>
				<alt-icon type="format_list_numbered" size="18px" />
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().setTextAlign('left').run()"
				:class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
				v-tooltip.top="'Align Left'"
			>
				<alt-icon type="format_align_left" size="18px" />
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().setTextAlign('center').run()"
				:class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
				v-tooltip.top="'Align Center'"
			>
				<alt-icon type="format_align_center" size="18px" />
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().setTextAlign('right').run()"
				:class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
				v-tooltip.top="'Align Right'"
			>
				<alt-icon type="format_align_right" size="18px" />
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().setTextAlign('justify').run()"
				:class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
				v-tooltip.top="'Justify'"
			>
				<alt-icon type="format_align_justify" size="18px" />
			</button>
			<button
				v-if="advanced"
				@click="editor.chain().focus().setHorizontalRule().run()"
				v-tooltip.top="'Horizontal Rule'"
			>
				<alt-icon type="horizontal_rule" size="18px" />
			</button>
			<button v-if="advanced" @click="addImage">
				<alt-icon type="image" size="18px" />
			</button>
			<button v-if="advanced" @click="editor.chain().focus().setHardBreak().run()" v-tooltip.top="'Hard Break'">
				<alt-icon type="keyboard_return" size="18px" />
			</button>
			<button
				@click="editor.chain().focus().undo().run()"
				:disabled="!editor.can().chain().focus().undo().run()"
				v-tooltip.top="'Undo'"
			>
				<alt-icon type="undo" size="18px" />
			</button>
			<button
				@click="editor.chain().focus().redo().run()"
				:disabled="!editor.can().chain().focus().redo().run()"
				v-tooltip.top="'Redo'"
			>
				<alt-icon type="redo" size="18px" />
			</button>
		</div>
		<editor-content :editor="editor" />
	</div>
</template>

<script lang="ts">
import { Editor, EditorContent } from '@tiptap/vue-3';
import { Color } from '@tiptap/extension-color';
import Image from '@tiptap/extension-image';
import ListItem from '@tiptap/extension-list-item';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Paragraph from '@tiptap/extension-paragraph';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Link from '@tiptap/extension-link';

export default {
	name: 'Wysiwyg',
	components: {
		EditorContent,
	},
	props: {
		modelValue: {
			type: String,
			default: '',
		},
		advanced: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			editor: null,
		};
	},
	watch: {
		modelValue(value) {
			// HTML
			const isSame = this.editor.getHTML() === value;

			if (isSame) {
				return;
			}

			this.editor.commands.setContent(value, false);
		},
	},
	methods: {
		addImage() {
			const url = window.prompt('Image URL');
			if (url) {
				this.editor.chain().focus().setImage({ src: url }).run();
			}
		},
	},
	mounted() {
		this.editor = new Editor({
			extensions: [
				Color.configure({ types: [TextStyle.name, ListItem.name] }),
				Image.extend({
					addAttributes() {
						return {
							...this.parent?.(),
							style: {
								parseHTML: (element) => element.style?.cssText || null,
							},
							src: {
								default: null,
								renderHTML(attributes) {
									return {
										src: attributes.src,
									};
								},
							},
							width: {
								default: null,
								renderHTML: (attributes) => {
									return {
										width: attributes.width,
									};
								},
							},
							alt: {
								default: null,
								renderHTML: (attributes) => {
									return {
										alt: attributes.alt,
									};
								},
							},
						};
					},
					renderHTML(props) {
						return ['img', props.HTMLAttributes];
					},
				}),
				TextAlign.configure({
					types: ['heading', 'paragraph'],
				}),
				TextStyle.configure({ types: [ListItem.name] }),
				StarterKit.configure({
					paragraph: false,
				}),
				Paragraph.extend({
					addAttributes() {
						return {
							...this.parent?.(),
							style: {
								parseHTML: (element) => element.style?.cssText || null,
							},
						};
					},
					renderHTML(props) {
						return ['p', props.HTMLAttributes, 0];
					},
				}),
				Table.extend({
					addAttributes() {
						return {
							...this.parent?.(),
							style: {
								parseHTML: (element) => element.style?.cssText || null,
							},
						};
					},
					renderHTML(props) {
						return ['table', props.HTMLAttributes, 0];
					},
				}),
				TableHeader.extend({
					addAttributes() {
						return {
							...this.parent?.(),
							style: {
								parseHTML: (element) => element.style?.cssText || null,
							},
						};
					},
					renderHTML(props) {
						return ['th', props.HTMLAttributes, 0];
					},
				}),
				TableRow.extend({
					addAttributes() {
						return {
							...this.parent?.(),
							style: {
								parseHTML: (element) => element.style?.cssText || null,
							},
						};
					},
					renderHTML(props) {
						return ['tr', props.HTMLAttributes, 0];
					},
				}),
				TableCell.extend({
					addAttributes() {
						return {
							...this.parent?.(),
							style: {
								parseHTML: (element) => element.style?.cssText || null,
							},
						};
					},
					renderHTML(props) {
						return ['td', props.HTMLAttributes, 0];
					},
				}),
				Link.configure({
					openOnClick: false,
				}).extend({
					addAttributes() {
						return {
							...this.parent?.(),
							style: {
								parseHTML: (element) => element.style?.cssText || null,
							},
						};
					},
					renderHTML(props) {
						return ['a', props.HTMLAttributes, 0];
					},
				}),
			],
			content: this.modelValue,
			onUpdate: () => {
				// HTML
				this.$emit('update:modelValue', this.editor.getHTML());
			},
		});
	},
	beforeUnmount() {
		this.editor.destroy();
	},
};
</script>

<style lang="less">
.toolbar {
	margin-top: 1rem;
	max-width: 100%;
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	background-color: var(--gray-10);
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	border: 1px solid #ced4da;
	button {
		padding: 0.5rem;
		border: none;
		background-color: transparent;
		cursor: pointer;
		&.is-active {
			background-color: var(--gray-20);
		}
	}
}
/* Basic editor styles */
.tiptap {
	color: #333;
	background-color: var(--white);
	border: 1px solid #ced4da;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	border-top: 0;
	margin: 0 0 1rem 0;
	padding: 0.5rem 0.75rem;
	max-width: 100%;

	&:focus-visible {
		outline: 0;
	}

	:first-child {
		margin-top: 0;
	}

	img {
		display: block;
		height: auto;
		margin: 1.5rem 0;
		max-width: 100%;

		&.ProseMirror-selectednode {
			outline: 3px solid var(--purple);
		}
	}

	/* List styles */
	ul,
	ol {
		padding: 0 1rem;
		margin: 1.25rem 1rem 1.25rem 0.4rem;

		li p {
			margin-top: 0.25em;
			margin-bottom: 0.25em;
		}
	}

	ol {
		list-style-type: decimal;
	}

	ul {
		list-style-type: disc;
	}

	/* Heading styles */

	h1 {
		font-size: 1.4rem;
		margin-bottom: 1rem;
		text-wrap: pretty;
		line-height: 1.1;
	}

	h2 {
		font-size: 1.2rem;
		margin-bottom: 1rem;
		text-wrap: pretty;
		line-height: 1.1;
	}

	h3 {
		font-size: 1.1rem;
		margin-bottom: 1rem;
		text-wrap: pretty;
		line-height: 1.1;
	}

	hr {
		border: none;
		border-top: 1px solid black;
		margin: 1rem 0;
	}
}
</style>
