<template>
	<div id="accounts" class="view-content">
		<div class="page-heading">
			<h1>Accounts</h1>
			<breadcrumb :model="breadcrumb_items" />
		</div>
		<gutter size="20px" />
		<AccountList />
	</div>
</template>

<script lang="ts">
import AccountList from './List.vue';

export default {
	components: {
		AccountList,
	},
	data() {
		return {
			breadcrumb_items: [],
		};
	},
	mounted() {
		if (this.$route.params.mpid) {
			this.breadcrumb_items = [
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Accounts',
				},
			];
		} else {
			this.breadcrumb_items = [
				{
					label: 'Accounts',
				},
			];
		}
	},
};
</script>
