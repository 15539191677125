<template>
	<div class="view-content">
		<div class="page-heading">
			<h1>Sources Report</h1>
			<breadcrumb :model="breadcrumbs" />
		</div>
		<query-form v-model:query="query" :loading="loading" @apply="getSourcesReport" @abort="clearQuery">
			<template #display>
				<div class="display-value">
					<div class="label">Date Range:</div>
					<div class="value">{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}</div>
				</div>
				<div class="display-value">
					<div class="label">Verticals:</div>
					<div class="value">{{ displaySelected(query.vertical_id, vertical_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Business Units:</div>
					<div class="value">{{ displaySelected(query.business_unit, business_unit_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Products:</div>
					<div class="value">{{ displaySelected(query.product, product_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">States:</div>
					<div class="value">{{ displaySelected(query.state, state_options) }}</div>
				</div>
				<div class="display-value">
					<div class="label">Include Call Center:</div>
					<div class="value">{{ query.include_cc ? 'Yes' : 'No' }}</div>
				</div>
			</template>
			<template #utils>
				<p-button
					v-tooltip.top="'Refresh'"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="getSourcesReport(true)"
				/>
			</template>
			<template #form="form">
				<row>
					<column max-width="450px">
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<date-range v-model="form.query.date_range" hide-labels show-time />
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Verticals:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.vertical_id"
											:options="vertical_options"
											option-label="label"
											option-value="value"
											placeholder="Select verticals"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="300px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Products:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.product"
											:options="product_options"
											option-label="label"
											option-value="value"
											placeholder="Select products"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">Business Units:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="form.query.business_unit"
											:options="business_unit_options"
											option-label="label"
											option-value="value"
											placeholder="Select business units"
										/>
									</div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column max-width="300px">
						<div class="control-group">
							<div class="inner">
								<label class="control-label">States:</label>
								<div class="controls">
									<div class="field">
										<p-multi-select
											v-model="query.state"
											:options="state_options"
											option-label="label"
											option-value="value"
											placeholder="Select states"
											filter
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="control-group">
							<div class="inner">
								<label class="control-label">&nbsp;</label>
								<div class="controls">
									<div class="field fit">
										<p-checkbox input-id="include_cc" v-model="form.query.include_cc" binary />
									</div>
									<div class="field"><label for="include_cc">Include CC Purchases</label></div>
								</div>
							</div>
						</div>
					</column>
					<gutter size="40px" />
					<column> </column>
				</row>
			</template>
		</query-form>
		<div class="rollup-metrics">
			<metric label="Leads Generated" :value="rollupLeadsGenerated" is-number />
			<metric label="Leads Sold" :value="rollupLeadsSold" is-number />
			<metric label="Percent Sold" :value="rollupPercentSold" is-percentage />
			<metric label="Revenue" :value="rollupRevenue" is-currency />
			<metric label="RPL" :value="rollupRPL" is-currency />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table
				:value="sources"
				ref="sources_table"
				data-key="source_id"
				class="sources-table"
				v-model:filters="table_filters"
				:global-filter-fields="['source_id']"
				sort-field="revenue"
				:sort-order="-1"
			>
				<template #empty>
					<div class="dim">No Data</div>
				</template>
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #header>
					<p-input-text
						v-model="table_filters['global'].value"
						placeholder="Search Sources"
						autocomplete="off"
						style="min-width: 300px"
					/>
				</template>
				<p-column header="Source" sortable field="source_id">
					<template #body="row">
						<strong>
							<router-link :to="`sources/${row.data.source_id}/hour`">{{
								row.data.source_id || 'Unknown'
							}}</router-link>
						</strong>
						<div v-if="row.data.source_id" class="sub-value">{{ sourceHash(row.data.source_id) }}</div>
					</template>
				</p-column>
				<p-column header="Leads Generated">
					<template #body="row">{{ numberFormat(row.data.leads_generated) }}</template>
				</p-column>
				<p-column header="Leads Sold">
					<template #body="row">{{ numberFormat(row.data.leads_sold) }}</template>
				</p-column>
				<p-column header="Percent Sold">
					<template #body="row">{{ percentage(row.data.percent_sold, 2) }}</template>
				</p-column>
				<p-column header="Revenue" sortable field="revenue">
					<template #body="row">{{ currency(row.data.revenue) }}</template>
				</p-column>
				<p-column header="RPL" sortable field="rpl">
					<template #body="row">{{ currency(row.data.rpl) }}</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script>
import { sumBy } from 'lodash-es';
import dayjs from 'dayjs';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import metric from '@/components/widgets/Metric.vue';
import { capitalize, currency, displaySelected, formatDate, numberFormat, percentage, sourceHash } from '@/lib/Filters';
import { reportSourcesOverview } from '@GQL';
import STATES from '@/lib/Data/states.json';
import { FilterMatchMode } from 'primevue/api';

export default {
	name: 'CustomersReport',
	components: {
		dateRange,
		queryForm,
		metric,
	},
	data() {
		// Round current time to the nearest 5 minutes
		const minute = Math.floor(dayjs().minute() / 5) * 5;

		return {
			breadcrumbs: [
				{
					label: 'Sources Report',
				},
			],
			loading: false,
			query: {
				date_range: [dayjs().startOf('day').toDate(), dayjs().minute(minute).second(0).toDate()],
				vertical_id: [],
				product: [],
				business_unit: [],
				state: [],
				include_cc: true,
			},
			table_filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			sources: [],
			vertical_options: [
				{
					label: 'Health Insurance',
					value: 'health_insurance',
				},
				{
					label: 'Medicare',
					value: 'medicare',
				},
			],
			business_unit_options: [
				{
					label: 'Owned and Operated',
					value: 'OO',
				},
				{
					label: 'Partner Network',
					value: 'PN',
				},
			],
			product_options: [
				{
					label: 'Exclusive Data Leads',
					value: 'exclusive_data',
				},
				{
					label: 'Shared Data Leads',
					value: 'shared_data',
				},
				{
					label: 'Calls',
					value: 'exclusive_call',
				},
				{
					label: 'Live Transfers',
					value: 'exclusive_live_transfer',
				},
				{
					label: 'Premium Listings',
					value: 'exclusive_ad',
				},
			],
			state_options: STATES,
		};
	},
	computed: {
		rollupLeadsGenerated() {
			return sumBy(this.sources, 'leads_generated');
		},
		rollupLeadsSold() {
			return sumBy(this.sources, 'leads_sold');
		},
		rollupPercentSold() {
			return this.rollupLeadsSold / this.rollupLeadsGenerated;
		},
		rollupRevenue() {
			return sumBy(this.sources, 'revenue');
		},
		rollupRPL() {
			return this.rollupRevenue / this.rollupLeadsGenerated;
		},
	},
	methods: {
		capitalize,
		currency,
		displaySelected,
		formatDate,
		numberFormat,
		percentage,
		sourceHash,
		clearQuery() {
			this.loading = false;
		},
		async getSourcesReport(refresh) {
			this.loading = true;

			try {
				const result = await reportSourcesOverview(this.query, refresh);
				this.sources = result;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get sources',
					detail: err.message,
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
	async mounted() {
		await this.getSourcesReport();
	},
};
</script>

<style scoped lang="less">
.rollup-metrics {
	font-weight: bold;
	display: flex;
	gap: 20px;

	.metric {
		flex: 1 0;
	}
}

:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

:deep(.nil) {
	color: #f88a7d;
	font-weight: bold;
}
</style>
