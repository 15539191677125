import { $GQL } from '@GQL/index';

/**
 * Returns all the marketplaces, works with the list vue which should do front end filtering
 */
export async function getLTRecordings(phone: string) {
	const query = `
        query GetLTCallRecordings($phone: String!){
            getLTCallRecordings(phone: $phone)
        }
    `;

	const results = await $GQL.request(query, { phone });
	return results.getLTCallRecordings;
}
