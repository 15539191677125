import { $GQL } from '@GQL/index';
import { ReportRequestParams } from '@nextgenleads/marketplace-driver';

export async function reportRequest(params: ReportRequestParams): Promise<String> {
	const mutation = `
        mutation ReportRequest($params: ReportRequestParams!) {
            reportRequest(params: $params) {
                success
                message 
            }
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.reportRequest;
}
