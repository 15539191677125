import { $GQL } from '@GQL/index';

export async function reportLiveTransferReturns(params: { date_range: [string, string] }, refresh?: boolean) {
	const query = `
        query ReportLiveTransferReturns($params: JSONObject!) {
            reportLiveTransferReturns(params: $params) {
				rows
				row_count
			}
        }
    `;

	const no_cache = refresh ? ['reportLiveTransferReturns'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportLiveTransferReturns;
}
