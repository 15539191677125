import { $GQL } from '@GQL/index';

export async function reportFollowUpsForAccountManager(params: { account_manager_id: string; due_at: Date }) {
	const query = `
        query ReportFollowUpsForAccountManager($params: JSONObject!) {
            reportFollowUpsForAccountManager(params: $params) {
            	row_count
            	rows
            	metrics
            }
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportFollowUpsForAccountManager;
}
