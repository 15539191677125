import { $GQL } from '@GQL';

export async function createDuplicatesForChildrenAccounts(data: { child_accounts: string[]; template_id: string }) {
	const mutation = `
        mutation CreateDuplicatesForChildrenAccounts($data: JSONObject!,) {
			createDuplicatesForChildrenAccounts(data: $data)
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.createDuplicatesForChildrenAccounts;
}
