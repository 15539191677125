<template>
	<div class="lt-script">
		<div class="lt-script-heading">Greeting (Read when answering a call)</div>
		<div class="lt-script-content">
			<div class="lt-script-icon">
				<icon type="comment-text-outline" size="24px" />
			</div>
			<div class="lt-script-body">
				<template v-if="callType === 'outbound'">
					<p>Hello, is <strong>(LEAD NAME)</strong> available?</p>
					<p><strong>No</strong> - Schedule callback</p>
					<!--          NON - OE -->
					<!--					<p>-->
					<!--						Hi, <strong>[[ LEAD NAME ]]</strong>, this is {{ $root.sessionStore.user.first_name }} from FirstQuote-->
					<!--						Insurance. I'm calling to follow up on your online request for Insurance Quotes. I see you're looking for-->
					<!--						<strong>[[ VERTICAL ]]</strong> insurance. Is that correct?-->
					<!--					</p>-->

					<p>
						Hello, <strong>[[ LEAD NAME ]]</strong>, this is {{ $root.sessionStore.user.first_name }} from FirstQuote
						Insurance. With Open Enrollment in full swing, I'm reaching out about your recent request for
						<strong>[[ VERTICAL ]]</strong> insurance quotes. Does that sound correct?
					</p>
					<p><strong>If the lead's answer is NO - select the correct vertical</strong></p>

					<p>
						Great! I'm going to ask you a couple of questions that'll help me connect you with a licensed insurance
						agent who will provide options for affordable plans in your area.
					</p>
				</template>
				<template v-else>
					<p>
						Hi there! You've reached <strong>{{ $root.sessionStore.user.first_name }}</strong> with First Quote
						Insurance. Who do I have the pleasure of speaking with today?
					</p>
					<!--          NON - OE -->
					<!--					<p>-->
					<!--						Thanks for calling, <strong>[[ LEAD NAME ]]</strong>. My job is to connect you with a licensed insurance-->
					<!--						agent who can help find affordable plans in your area.-->
					<!--					</p>-->
					<p>
						Thanks for calling, <strong>[[ LEAD NAME ]]</strong>. It is Open Enrollment and my job is to connect you
						with a licensed insurance agent who can help fund affordable plans in your area.
					</p>
					<p>I see you’re looking for <strong>[[ VERTICAL ]] </strong> insurance. Is that correct?</p>

					<p>
						<strong>If the lead's answer is NO - select the correct vertical.</strong>
					</p>
					<p>
						I will go through a couple of questions to find the right agent, then I will connect you and drop off the
						line so you can receive your quote.
					</p>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HealthInsuranceGreeting',
	props: {
		callType: {
			type: String,
			default: 'outbound',
		},
	},
	data() {
		return {};
	},
};
</script>

<style lang="less"></style>
