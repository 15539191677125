import { $GQL } from '@GQL/index';

export async function reportLiveTransferLeaderboard(
	params: { date_range: [string, string]; limit?: boolean },
	refresh?: boolean
) {
	const query = `
        query ReportLiveTransferLeaderboard($params: JSONObject!) {
            reportLiveTransferLeaderboard(params: $params) {
				rows
				row_count
			}
        }
    `;

	const no_cache = refresh ? ['reportLiveTransferLeaderboard'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportLiveTransferLeaderboard;
}
