import { $GQL } from '@GQL';
import type { SourceThrottleConfig } from '@nextgeneleads/source-driver';

export async function getSourceThrottleConfigById(id: string): Promise<SourceThrottleConfig> {
	const request = `
        query GetSourceThrottleConfig($id: ID!) {
			sourceThrottleConfig(id: $id) {
				id
				source_id
				vertical_id
				product
				interval
				limit
				enabled
				enable_max_pings
				max_pings
				enable_max_posts
				max_posts
			}
        }
    `;

	return (await $GQL.request(request, { id })).sourceThrottleConfig;
}
