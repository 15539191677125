import { $GQL } from '@GQL/index';
import type { DNC } from '@nextgenleads/dnc-driver';

export async function updateDNC(id: string, data: Partial<DNC>): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateDNC($id: ID!, $data: JSONObject!) {
            updateDNC(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateDNC;
}
