import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';
import type { Campaign } from '@nextgenleads/marketplace-driver';

export async function getCampaigns(
	filters: QueryFilters,
	dated_params: QueryFilters,
	refresh?: boolean
): Promise<Partial<Campaign>[]> {
	const query = `
        query GetCampaigns($filters: [[Any]]!, $dated_params: [[Any]]!) {
            campaigns(filters: $filters) {
                id
                name
                vertical_id_targeting
                product_targeting
				location_targeting_mode
				state_targeting
				zip_targeting
				mode
				custom_minimum_bid
				minimum_bid
                default_bid
				enable_maximum_bid
				maximum_bid
                status
                bid_type
                promotion {
                	description
                }
				budgets {
					enabled
					period
					type
					limit
				}
				flags {
					lock {
						enabled
						roles
					}
				}
				ping_post {
					enabled
					integration_id
				}
                spend(params: $dated_params)
                purchaseCount(params: $dated_params)
                canBeDeleted
				budgetWarning
				isThrottled
            }
        }
    `;

	const no_cache = refresh ? ['campaigns'] : undefined;

	const response = await $GQL.request(query, { filters, dated_params, no_cache });
	return response.campaigns;
}
