<template>
	<div class="api-key-form">
		<p-card class="api-key-card">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Name:</label>
						<div class="controls">
							<div class="field">
								<p-input-text v-model="api_key.name" class="key-input flex-1" placeholder="Name" />
								<div v-if="v$.api_key.name.$error" class="validation-error">
									{{ v$.api_key.name.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="$route.params.api_key_id" class="control-group">
					<div class="inner">
						<label class="control-label" for="api-key">API Key:</label>
						<div class="controls">
							<div class="field">
								<div class="p-inputgroup">
									<p-input-text
										id="api-key"
										v-model="api_key.key"
										class="key-input flex-1"
										disabled
										:pt="{
											root: { class: 'border-gray-500 surface-100 text-black-alpha-90 opacity-90' },
										}"
									/>
									<div class="p-inputgroup-addon">
										<icon type="lock" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="mpid">MPID:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="mpid"
									v-model="api_key.mpid"
									:options="marketplace_options"
									placeholder="Select MPID (or leave blank)"
									:option-label="(data) => `${data.label} (${data.value})`"
									option-value="value"
									showClear
								/>
								<div class="sub-value">Null for global</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="owner-id">Owner ID:</label>
						<div class="controls">
							<div class="field">
								<p-input-text
									v-model="api_key.owner_id"
									class="key-input flex-1"
									placeholder="Owner ID (Account/Vendor)"
								/>
								<div class="sub-value">Account this key belongs to (Account or Vendor ID)</div>
								<div v-if="v$.api_key.owner_id.$error" class="validation-error">
									{{ v$.api_key.owner_id.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="role-id">Role ID:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="role-id"
									v-model="api_key.role_id"
									:options="role_options"
									placeholder="Select Role ID"
									option-label="value"
									option-value="value"
								/>
								<div v-if="v$.api_key.role_id.$error" class="validation-error">
									{{ v$.api_key.role_id.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field-row align-start gap-20">
								<div class="field">
									<label class="control-label" for="whitelisted-ips">Whitelisted IPs:</label>
									<InputList id="whitelisted-ips" v-model="api_key.whitelisted_ips" />
								</div>
								<div class="field">
									<label class="control-label req" for="allowed-request-methods">Allowed Request Methods:</label>
									<p-multi-select
										v-model="api_key.allowed_request_methods"
										id="allowed-request-methods"
										:options="http_request_options"
										option-label="label"
										option-value="value"
										placeholder="Select Allowed Request Methods"
										class="w-full"
									>
									</p-multi-select>
									<div v-if="v$.api_key.allowed_request_methods.$error" class="validation-error">
										{{ v$.api_key.allowed_request_methods.$errors[0].$message }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="api-data">Data:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="api-data" v-model.parse="api_key.data" />
								<div class="sub-value">
									Data Options
									<icon
										type="open-in-new"
										size="14px"
										class="cursor-pointer"
										style="color: var(--color-b)"
										@click.prevent="data_options_modal = true"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="status">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="api_key.status"
									:options="status_options"
									placeholder="Select Status"
									option-label="label"
									option-value="value"
								/>
								<div v-if="v$.api_key.status.$error" class="validation-error">
									{{ v$.api_key.status.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<gutter size="20px" />
			</template>
			<template #footer>
				<div class="flex justify-content-between align-items-center">
					<p-button severity="secondary" label="Cancel" text @click.prevent="$router.push({ path: '/api-keys/' })" />
					<p-button icon="pi pi-check" label="Save API Key" @click.prevent="submit()" />
				</div>
			</template>
		</p-card>
		<p-dialog
			v-model:visible="data_options_modal"
			modal
			header="Data Options"
			:style="{ width: '50vw' }"
			:dismissable-mask="true"
		>
			<p>Data is an object of this type</p>
			<highlightjs autodetect :code="dataDefs" />
		</p-dialog>
	</div>
</template>

<script lang="ts">
import pButton from 'primevue/button';
import pCard from 'primevue/card';
import pDialog from 'primevue/dialog';
import pDropdown from 'primevue/dropdown';
import InputList from '@/components/forms/InputList.vue';
import log from '@/lib/Log';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { initAPIKeyForm, insertAPIKey, updateAPIKey } from '@GQL';
import { ulid } from 'ulid';
import { http_request_options } from '@/lib/Options';
import 'highlight.js/styles/atom-one-dark.css';

const status_options = [
	{ label: 'Active', value: 'active' },
	{ label: 'Disabled', value: 'disabled' },
	{ label: 'Archived', value: 'archived' },
];

export default {
	name: 'EditAPIKey',
	components: {
		pButton,
		pCard,
		pDialog,
		pDropdown,
		InputList,
	},
	inject: ['mq'],
	async setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			status_options,
			http_request_options,
			role_options: [],
			marketplace_options: [],
			api_key: {
				name: null,
				mpid: null,
				id: this.$route.params.api_key_id || ulid(),
				key: '',
				owner_id: null,
				role_id: null,
				whitelisted_ips: [],
				allowed_request_methods: [],
				data: {},
				status: 'active',
			},
			data_options_modal: false,
			dataDefs: `data: z.object({
				source_id: z.string(),
				vendor_id: z.string(),
				vertical_id: z.string()
			})
			.catchall(z.any())
			.partial()
			.nullish(),`,
		};
	},
	async mounted() {
		const init_result = await initAPIKeyForm(this.$route.params.api_key_id || null);
		this.role_options = init_result.role_options;
		if (init_result.marketplace_options) {
			this.marketplace_options = init_result.marketplace_options;
		}
		if (init_result.apiKey) {
			Object.assign(this.api_key, init_result.apiKey);
		}
	},
	methods: {
		async submit() {
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					let result;
					if (this.$route.params.api_key_id) {
						result = await updateAPIKey(this.api_key.id, this.api_key);
					} else {
						result = await insertAPIKey(this.api_key);
					}
					if (result) {
						this.$toast.add({
							summary: this.$route.params.api_key_id ? 'Successfully updated API Key' : 'Successfully created API Key',
							life: 3000,
						});
						// DO NAVIGATION
						this.$router.back();
					}
				} catch (err) {
					log.trace(err);
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to insert or update API Key',
						life: 3000,
					});
				}
			}
		},
	},
	validations() {
		return {
			api_key: {
				name: {
					required: helpers.withMessage('Name required.', required),
				},
				owner_id: {
					required: helpers.withMessage('Owner ID required.', required),
				},
				role_id: {
					required: helpers.withMessage('Role ID required.', required),
				},
				allowed_request_methods: {
					required: helpers.withMessage('At least one request method required.', required),
					minLength: minLength(1),
				},
				status: {
					required: helpers.withMessage('Status required.', required),
				},
			},
		};
	},
};
</script>

<style lang="less">
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';

.api-key-form {
	label {
		margin-bottom: 0.5rem;
	}

	max-width: 800px;

	.option {
		border: 1px solid var(--gray-50);
		border-radius: 4px;
		display: flex;
		font-size: var(--font-size-sm);
		gap: 10px;
		padding: 10px;

		.description {
			color: var(--gray-50);
			line-height: 1.3;
		}

		&.active {
			border-color: var(--color-b);

			label {
				color: var(--color-b);
			}
		}
	}
}
</style>
