<template>
	<div id="queues-list" class="view-content">
		<div class="page-heading">
			<h1>Queues</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-button icon="pi pi-plus" label="Add Queue" @click.prevent="$router.push(`${$route.path}/new`)" />
		<gutter size="20px" />
		<div class="card padded">
			<p-data-table ref="dt" :loading="loading" :rows="10" scrollable :value="queues">
				<template #loading>
					<line-loader :show="loading" />
				</template>
				<template #empty>
					<div class="dim">No results</div>
				</template>
				<p-column class="" field="product" header="Queue" sortable sortField="name">
					<template #body="queue">
						<div class="name">
							<router-link :to="{ path: `${$route.path}/${queue.data.id}` }">{{ queue.data.name }}</router-link>
						</div>
						<div class="sub-value">{{ queue.data.id }}</div>
					</template>
				</p-column>
				<p-column field="status" header="Status" sortable sortField="status">
					<template #body="queue">
						{{ capitalize(queue.data.status) }}
					</template>
				</p-column>
				<p-column class="align-center" header="Tools">
					<template #body="row">
						<div class="toolset">
							<div class="tool">
								<p-button
									v-tooltip.top="'Edit'"
									aria-label="Edit"
									@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
								>
									<template #icon>
										<icon type="square-edit-outline" size="20px" />
									</template>
								</p-button>
							</div>
							<div class="tool">
								<p-button v-tooltip.top="'Duplicate'" aria-label="Edit" @click="duplicateQueue($event, row.data.id)">
									<template #icon>
										<icon type="content-copy" size="20px" />
									</template>
								</p-button>
							</div>
							<delete-action message="Are you sure you want to delete this queue?" @delete="deleteQueue(row.data.id)" />
						</div>
					</template>
				</p-column>
			</p-data-table>
		</div>
	</div>
</template>

<script>
/**
 */
import { getQueues } from '@GQL';
import log from '@/lib/Log';
import { phoneFormat } from '@/lib/Filters';
import capitalize from 'lodash/capitalize';
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'QueuesList',
	components: {
		deleteAction,
	},
	data() {
		return {
			breadcrumb_items: [],
			queues: [],
			loading: false,
		};
	},
	async mounted() {
		this.loading = true;
		try {
			this.queues = await getQueues();
			log.trace('Queue', this.queues);
		} catch (err) {
			this.$toast.add({
				severity: 'error',
				summary: 'Unable to get Queue List',
				detail: err.response.errors[0].message,
			});
		} finally {
			this.loading = false;
		}
		if (this.$route.params.mpid) {
			this.breadcrumb_items.push(
				{
					label: 'Marketplaces',
					route: '/marketplaces',
				},
				{
					label: this.$route.params.mpid,
					route: `/marketplaces/manage/${this.$route.params.mpid}/accounts`,
				},
				{
					label: 'Queues',
				}
			);
		} else {
			this.breadcrumb_items.push({
				label: 'Queues',
			});
		}
	},
	methods: {
		phoneFormat,
		capitalize,
		async duplicateQueue(event, queue_id) {
			this.$router.push({
				path: `${this.$route.path}/new`,
				query: {
					duplicate: queue_id,
				},
			});
		},
	},
};
</script>

<style lang="less">
.sub {
	color: var(--gray-50);
	margin-top: 0.5rem;
}
</style>
