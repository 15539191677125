<template>
	<div id="campaign-list" class="view-content">
		<div class="page-heading flex justify-content-between">
			<div>
				<h1>Campaigns</h1>
				<breadcrumb :items="items" />
			</div>
			<div v-if="isUnverified" class="verified-msg">
				<p-message severity="warn" :closable="false">
					Your account currently has some daily spend limits. Contact your account manager to get this account verified
					and to remove all the restrictions
				</p-message>
			</div>
		</div>
		<List />
	</div>
</template>

<script lang="ts">
import List from './List.vue';
export default {
	name: 'CampaignListPage',
	components: {
		List,
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
			items: [{ label: 'Campaigns', route: `/campaigns` }],
		};
	},
	computed: {
		isUnverified() {
			if (this.$root.sessionStore.isAccountUser && !this.$root.sessionStore.hasVerifiedAccount) {
				return !this.$root.sessionStore.hasValidAccountPhone;
			}
			return false;
		},
	},
};
</script>

<style lang="less">
.verified-msg {
	color: var(--red-600);
	font-weight: bold;
	font-style: italic;
	font-size: 1.1em;
	display: flex;
	justify-content: center;
	align-items: center;

	.p-message {
		margin: 0 0 1rem;
	}
}
</style>
