import SignUpPagesList from '@/views/SignUpPages/List/Page.vue';
import SignUpPageRecord from '@/views/SignUpPages/Record/Page.vue';

export default [
	{
		path: 'signup-pages',
		name: 'SignUpPagesList',
		component: SignUpPagesList,
		meta: {
			title: 'Sign-Up Pages',
			permissions: 'admin.signup_pages',
		},
	},
	{
		path: 'signup-pages/new',
		name: 'CreateSignUpPage',
		component: SignUpPageRecord,
		meta: {
			new: true,
			title: 'New Sign-Up Page',
			permissions: 'admin.signup_pages',
		},
	},
	{
		path: 'signup-pages/:signup_page_id',
		name: 'EditSignUpPage',
		component: SignUpPageRecord,
		meta: {
			title: 'Edit Sign-Up Page',
			permissions: 'admin.signup_pages',
		},
	},
];
