<template>
	<div class="shipper-settings">
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Options:</label>
				<div class="controls">
					<div class="field">
						<code-editor v-model.parse="shipperSettings.options" />
						<div class="sub-value">Authorization/Headers will need to be filled here if required</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ShipperSettingsZoho',
	props: {
		modelValue: {
			type: Object,
			default() {
				return {
					options: {},
				};
			},
		},
	},
	computed: {
		shipperSettings: {
			get() {
				return this.modelValue;
			},
			set(new_value) {
				this.$emit('update:modelValue', new_value);
			},
		},
	},
};
</script>

<style lang="less"></style>
