import { $GQL } from '@GQL/index';

export async function getLeadDetail(id: string, mpid?: string) {
	const purchase_filters = [[`lead_id = '${id}'`]];
	if (mpid) {
		purchase_filters[0].push(`mpid = '${mpid}'`);
	}

	const query = `
        query GetLeadDetail($id: ID! $purchase_filters: [[Any]]) {
			lead(id: $id) {
				id
				mpid
				ulid
				vendor_id
				vendor_lead_id
				product
				vertical_id
				first_name
				last_name
				street_1
				street_2
				city
				state
				zip
				county
				lat
				lng
				email
				phone
				data
				meta
				dnc
				leadid_token
				trustedform_id
				trustedform_cert_url
				trustedform_share_url
				elid
				spoken_language
				tcpa
				permanent_id
				session_id
				source_id
				source_hash
				sub_id
				sub_id_hash
				source_tags
				business_unit
				source_type
				source_segments
				site_id
				click_network
				click_token
				tags
				duplicate
				spam
				test
				cost
				created_at
				modified_at
			}
			purchases(filters: $purchase_filters) {
				id
				account {
					id
					name
				}
				campaign {
					id
					name
				}
				mpid
				product
				bid_type
				tags
				price
				original_price
				status
				created_at
			}
        }
    `;

	const result = await $GQL.request(query, { id, purchase_filters });
	return result;
}
