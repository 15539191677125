<template>
	<div class="modifier buff-bid">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Buff Bid</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this modifier?"
				tooltip="Remove Modifier"
				@delete="removeModifier"
			/>
		</div>
		<div class="description">
			Adds $1000 to the sorting bid of a campaign x percent of the time. The percentage is set in the campaign.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'BuffBidModifier',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeModifier() {
			this.$emit('remove');
		},
	},
};
</script>
