<template>
	<div class="settings-form">
		<h2>Account Owners</h2>
		<p-fieldset legend="Round Robin">
			<p>
				Select the users you want to include in the round robin account owner assignment when a new account signs up.
				Only users with permissions for this marketplace will be available for assignment.
			</p>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Health Insurance:</label>
					<div class="controls">
						<div class="field">
							<p-multi-select
								v-model="account_owners.health_insurance"
								:options="account_owner_options"
								option-label="label"
								option-value="value"
								filter
								placeholder="Select All Round Robin Participants"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Medicare:</label>
					<div class="controls">
						<div class="field">
							<p-multi-select
								v-model="account_owners.medicare"
								:options="account_owner_options"
								option-label="label"
								option-value="value"
								filter
								placeholder="Select All Round Robin Participants"
							/>
						</div>
					</div>
				</div>
			</div>
		</p-fieldset>
		<div class="actions">
			<p-button label="Save" icon="pi pi-check" @click="updateSetting()" />
			<gutter size="20px" />
			<p-button label="Reset" text @click="resetSetting()" />
		</div>
	</div>
</template>

<script>
import { cloneDeep, sortBy } from 'lodash-es';
import { getSettings, getUsersList, updateSetting } from '@GQL';
import { useMarketplaceStore } from '@/stores/marketplace';
import { mapStores } from 'pinia';

export default {
	name: 'AccountOwnersSettings',
	props: {
		localSettingsList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			account_owners: {
				health_insurance: [],
				medicare: [],
			},
			default_account_owners: [],
			account_owner_options: [],
			overrides: [],
		};
	},
	computed: {
		mpid() {
			return this.marketplaceStore.mpid || this.$root.appStore.mpid;
		},
		...mapStores(useMarketplaceStore),
	},
	methods: {
		async getSetting() {
			const settings_result = await getSettings(['account_owners'], this.mpid);
			this.account_owners = settings_result.settings.account_owners;
			this.default_account_owners = cloneDeep(this.account_owners);
		},
		async getAccountOwnerOptions() {
			const result = await getUsersList(
				[[`role_id->>'${this.mpid}' IN ('mp_admin', 'mp_manager')`, `email LIKE '%nextgenleads.com'`]],
				['users']
			);

			if (result) {
				this.account_owner_options = sortBy(
					result.map((user) => {
						return {
							label: `${user.first_name} ${user.last_name}`,
							value: user.id,
						};
					}),
					['label']
				);
			}
		},
		async updateSetting() {
			const settings_result = await updateSetting(
				'account_owners',
				{
					data: this.account_owners,
				},
				this.mpid
			);
			if (settings_result.id === 'account_owners') {
				this.$toast.add({
					severity: 'success',
					summary: 'Successfully saved account owners settings',
					life: 3000,
				});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
				});
			}
		},
		resetSetting() {
			this.account_owners = cloneDeep(this.default_account_owners);
		},
	},
	async mounted() {
		await this.getSetting();
		await this.getAccountOwnerOptions();
	},
};
</script>

<style scoped lang="less">
:deep(*) {
	.p-inputnumber.short {
		max-width: 100px;
	}

	.p-inputnumber-input {
		text-align: right;
	}

	.field.caption {
		align-items: center;
		display: flex;
		height: 40px;
	}
}

.field {
	margin-bottom: 0;
}
</style>
