import { $GQL } from '@GQL/index';

export async function reportSourceLiveTransferVelocityBySubId(
	source_id: string,
	params: { date_range: [string, string]; compare: any },
	refresh: boolean
) {
	const query = `
        query ReportSourceLiveTransferVelocityBySubId($source_id: String!, $params: JSONObject!) {
            reportSourceLiveTransferVelocityBySubId(source_id: $source_id, params: $params)
        }
    `;

	const no_cache = refresh ? ['reportSourceLiveTransferVelocityBySubId'] : undefined;
	const results = await $GQL.request(query, { source_id, params, no_cache });
	return results.reportSourceLiveTransferVelocityBySubId;
}
