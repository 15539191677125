<template>
	<div id="sockets" class="view-content">
		<div class="page-heading">
			<h1>Socket Test</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<gutter size="20px" />
		<div class="card padded">
			<p>Test if any socket event listener is working by testing from this page</p>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Room:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="room" placeholder="Socket Room (target user_id, role, account_id, mpid, etc.)" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Event:</label>
					<div class="controls">
						<div class="field">
							<p-input-text v-model="event" placeholder="Socket Event Name" />
						</div>
					</div>
				</div>
			</div>
			<div class="control-group">
				<div class="inner">
					<label class="control-label">Publish Data:</label>
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse="publish_data" />
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-content-end">
				<p-button label="Test Socket" @click="socketPublish" />
			</div>
			<download-file />
		</div>
	</div>
</template>

<script lang="ts">
import { publishSocketMessage } from '@GQL';
import DownloadFile from '@/components/widgets/DownloadFile.vue';
export default {
	name: 'SocketTest',
	components: {
		DownloadFile,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Socket Testing' }],
			room: '',
			event: '',
			publish_data: {},
		};
	},
	methods: {
		async socketPublish() {
			if (this.room) {
				return await publishSocketMessage({
					room: this.room,
					event: this.event,
					data: this.publish_data,
				});
			} else {
				return await publishSocketMessage({
					event: this.event,
					data: this.publish_data,
				});
			}
		},
	},
	// mounted() {
	// 	this.$root.sessionStore.addSocketListener('toast', (data) => {
	// 		this.socket_listener_results = data;
	// 	});
	// },
	// beforeUnmount() {
	// 	this.$root.sessionStore.removeSocketListener('toast');
	// },
};
</script>
