<template>
	<div id="leads-report" class="view-content">
		<div class="page-heading">
			<h1>All Leads</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<List />
	</div>
</template>

<script lang="ts">
import List from './List.vue';
export default {
	name: 'AllPurchasesReport',
	components: {
		List,
	},
	data() {
		return {
			breadcrumb_items: [],
		};
	},
	mounted() {
		if (this.$route.path.split('/').includes('reports')) {
			this.breadcrumb_items.push({
				label: 'Reports',
				route: '/reports/leads',
			});
		} else {
			this.breadcrumb_items.push({
				label: 'Purchases',
				route: '/purchases',
			});
		}
	},
};
</script>
