import { $GQL } from '@GQL/index';

export async function reportSourcesOverview(
	params: {
		date_range: [string, string];
		vertical_id: string[];
		product: string[];
		business_unit: string[];
		state: string[];
		bid_type: string[];
	},
	refresh: boolean
) {
	const query = `
        query ReportSourcesOverview($params: JSONObject!) {
            reportSourcesOverview(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportSourcesOverview'] : undefined;
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportSourcesOverview;
}
