import { $GQL } from '@GQL/index';

export async function reportAccountActivity(params: { account_id: string }) {
	const query = `
        query ReportAccountVelocity($account_id: ID!, $params: JSONObject!) {
            reportAccountVelocity(params: $params)
            reportCampaignActivity(params: $params) {
				rows
				row_count
			}
			account(id: $account_id) {
				id
				name
				support {
					disposition	
					account_manager_id
					account_manager
					{
						id
						first_name
						last_name
					}
					date_of_return
					status
					kpi_targets {
						data_cpa
						call_cpa
						live_transfer_cpa
						products_sold
					}
				}
				created_at
				balance {
					actual
				}
				settings {
					timezone
				}
				contact {
					email
				}
				signup_data {
				    primary_vertical
				    phone_validation {
				        activity_score
				        carrier
				        line_type
				    }
					products
					agency_size
					referred_from
                }
                signup_source {
                    source_id
                    s1
                    s2
                }
				lastActivity
				lastContact
				lastPurchase
				primary_user {
					first_name
					last_name
					email
					phone
				}
				status
			}
        }
    `;

	const results = await $GQL.request(query, {
		params,
		account_id: params.account_id,
	});
	return results;
}
