import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getAccountsForTransfers(filters: QueryFilters) {
	const query = `
    query GetAccountsForTransfers($filters: [[Any]]) {
        accounts(filters: $filters) {
          id
          name
          balance {
            actual
          }
          is_parent
          primary_user {
            first_name
            last_name
          }
        }
      }`;

	return (await $GQL.request(query, { filters, no_cache: ['accounts'] })).accounts;
}
