import { $GQL } from '@GQL/index';

interface GetSettingsRes {
	settings: {
		[key: string]: {
			id: string;
			mpid: string | null;
			data: any;
		};
	};
}

export async function getSettings(
	paths: string[],
	mpid: string | null = null,
	refresh?: boolean
): Promise<GetSettingsRes> {
	const request = `
        query GetSettings($paths: [ID]!, $mpid: String) {
			settings(paths: $paths, mpid: $mpid)
        }
    `;

	const no_cache = refresh ? ['settings'] : undefined;

	return await $GQL.request(request, { paths, mpid, no_cache });
}
