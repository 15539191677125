<template>
	<p-button icon="pi pi-plus" label="Add Qualifier" @click="openModal" />
	<p-dialog
		v-model:visible="show_modal"
		class="add-qualifier-modal"
		modal
		:dismissable-mask="true"
		style="width: 400px"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="shape-square-rounded-plus" size="24px" style="color: var(--color-b)" />
				<strong>Add Build Step</strong>
			</div>
		</template>
		<div class="qualifier-options">
			<div v-for="qualifier in availableQualifiers" class="qualifier-option" @click="addQualifier(qualifier.value)">
				<div class="content-container flex align-items-center">
					<div>
						<div class="name">{{ qualifier.name }}</div>
						<div class="sub-value">{{ qualifier.description }}</div>
					</div>
				</div>
			</div>
			<p-message v-if="!availableQualifiers.length === 0" :closable="false">
				There are no other available qualifiers to add.
			</p-message>
		</div>
		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Cancel" text @click="show_modal = false" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
import { cloneDeep, map } from 'lodash-es';
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';

export default {
	name: 'QualifierSelector',
	emits: ['addQualifier'],
	components: {
		pDialog,
		pMessage,
	},
	props: {
		auctionConfig: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			show_modal: false,
			qualifier_options: [
				{
					name: 'Account Not Interested',
					description: "Disqualify a campaign if the company has the lead's phone number in their private DNC list.",
					value: {
						script: 'AccountNotInterested',
					},
				},
				{
					name: 'Auction Attempt Limit',
					description:
						'Disqualify a campaign from the auction if the auction attempt exceeds the limit set on the campaign or company.',
					value: {
						script: 'AuctionAttemptsLimit',
						settings: {
							minimum_attempts: 1,
							max_lead_age_for_reclaim: 30,
						},
					},
				},
				{
					name: 'Balance',
					description: "Disqualify a campaign if the account's available balance is not sufficient to bid.",
					value: {
						script: 'Balance',
					},
				},
				{
					name: 'Bid Type',
					description: 'Disqualify a campaign if it has a bid type that is not allowed by this auction.',
					value: {
						script: 'BidType',
						settings: {
							allowed_bid_types: ['exclusive'],
						},
					},
				},
				{
					name: 'Budget',
					description: 'Disqualify a campaign if any campaign or account-level budget has been reached.',
					value: {
						script: 'Budget',
					},
				},
				{
					name: 'Cancel Rate',
					description: 'Disqualify a campaign if it has a cancel rate that exceeds that max allowable rate.',
					value: {
						script: 'CancelRate',
						settings: {
							max_rate: 0.15,
						},
					},
				},
				{
					name: 'Channels',
					description: 'Disqualify a campaign if it is not targeting the channel of the lead.',
					value: {
						script: 'Channels',
					},
				},
				{
					name: 'Concurrent Call Limit',
					description: 'Disqualify a campaign if the concurrent call limit on the campaign has already been reached.',
					value: {
						script: 'ConcurrentCallLimit',
					},
					products: ['call'],
				},
				{
					name: 'Custom Filter Match',
					description: 'Disqualify a campaign if it does not match the specified custom filter.',
					value: {
						script: 'CustomFilterMatch',
						settings: {
							match: [
								[
									{
										target: {
											path: null,
										},
										strategy: 'equals',
										comparator: {
											value: null,
										},
									},
								],
							],
						},
					},
				},
				// {
				// 	name: 'Custom',
				// 	description:
				// 		'A custom qualifyier that will disqualify campaigns using a script that is specified in the settings.',
				// 	value: {
				// 		script: 'Custom',
				// 		settings: {
				// 			script: '',
				// 			settings: {},
				// 		},
				// 	},
				// },
				{
					name: 'Explicit Campaign Match',
					description: "Disqualify a campaign if it doesn't explicitly match one of the specified campaign IDs.",
					value: {
						script: 'ExplicitCampaignMatch',
						settings: {
							campaign_ids: [],
						},
					},
				},
				{
					name: 'Filters',
					description: 'Disqualify a campaign if the lead does not match the specified filters.',
					value: {
						script: 'Filters',
					},
				},
				{
					name: 'Premium Listing',
					description: 'Disqualify a campaign if it has a premium listing attached.',
					value: {
						script: 'PremiumListing',
						settings: {
							invert: false,
						},
					},
				},
				{
					name: 'Previously Purchased',
					description: 'Disqualify a campaign if an associated account has already purchased a matching lead.',
					value: {
						script: 'PreviouslyPurchased',
						settings: {
							match_account: true,
							match_parents: false,
							match_groups: false,
							use_email_in_dedup: false,
							table: 'purchases',
							look_back_window_days: 30,
						},
					},
				},
				{
					name: 'Promotion',
					description:
						'Disqualify a campaign if the promotion is not active or it is using a promotion that is not included in the specified list.',
					value: {
						script: 'Promotion',
						settings: {
							promotion_ids: [],
						},
					},
				},
				{
					name: 'Schedule',
					description: 'Disqualify a campaign if it has a schedule that is currently off.',
					value: {
						script: 'Schedule',
					},
				},
				{
					name: 'Source Tags',
					description:
						'Disqualify a campaigns with one of the specified support statuses if the lead contains one of the specified tags.',
					value: {
						script: 'SourceTags',
						settings: {
							statuses: ['new', 'threshold_pending'],
							tags: [],
						},
					},
				},
				{
					name: 'Throttling',
					description: 'Disqualify a campaign if it recently purchased a lead and the throttle timer is still active.',
					value: {
						script: 'Throttling',
					},
				},
				{
					name: 'Undialable',
					description: 'Disqualify a campaign if it is missing a forwarding phone number.',
					value: {
						script: 'Undialable',
					},
					products: ['call'],
				},
				{
					name: 'USHA Validation',
					description:
						'Disqualify a campaign that requires USHA validation if the USHA validation for this lead did not pass.',
					value: {
						script: 'USHAValidation',
					},
				},
			],
		};
	},
	computed: {
		availableQualifiers() {
			return this.qualifier_options.filter((option) => {
				const not_included = !this.auctionConfig.qualifiers.some((qualifier) => {
					if (option.value.script === 'Custom') {
						return false;
					}
					return qualifier.script === option.value.script;
				});

				let allowed_for_product = true;
				if (option.products && !option.products.includes(this.auctionConfig.product)) {
					allowed_for_product = false;
				}

				let allowed_for_vertical = true;
				if (option.verticals && !option.verticals.includes(this.auctionConfig.vertical)) {
					allowed_for_vertical = false;
				}

				return not_included && allowed_for_product && allowed_for_vertical;
			});
		},
	},
	methods: {
		openModal() {
			this.show_modal = true;
		},
		addQualifier(value) {
			this.$emit('addQualifier', cloneDeep(value));
			this.show_modal = false;
		},
	},
};
</script>

<style scoped lang="less">
.qualifier-option {
	border: 1px solid var(--gray-20);
	border-radius: 3px;
	margin-bottom: 1rem;
	padding: 1rem;

	&:hover {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-lighter);
		cursor: pointer;
	}

	.name {
		font-weight: bold;
		margin-bottom: 0.5em;
	}

	.sub-value {
		line-height: 1.35em;
	}
}
</style>
