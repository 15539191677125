<template>
	<div v-tooltip.bottom="hasPaymentMethods ? '' : `You cannot add funds until you add payment method`">
		<p-button
			v-if="show_button"
			:disabled="!hasPaymentMethods"
			icon="pi pi-plus"
			label="Add Funds"
			@click.prevent="openAddFundsModal"
		/>

		<p-dialog
			v-model:visible="funds_modal_open"
			class="add-funds-modal"
			:modal="true"
			:dismissable-mask="true"
			style="width: 500px"
		>
			<loader :show="loading" />
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<icon type="cash" size="24px" style="color: var(--color-b)" />
					<strong>Add Funds</strong>
				</div>
			</template>
			<template v-if="payment_method_options.length === 0">
				<p-message :closable="false">
					You will need to add a payment method to your account before adding funds.
				</p-message>
			</template>
			<template v-else>
				<div class="flex-flex-column">
					<div class="balance" :class="fundingClass">
						<label for="current_balance"> Current Balance: </label>
						<div name="current_balance" class="balance">
							{{ currency(current_balance) }}
						</div>
					</div>
					<gutter size="20px" />
					<div class="control-group">
						<div class="inner">
							<div class="controls">
								<div class="field">How much would you like to add?</div>
								<div class="field smallish">
									<p-input-currency v-model="add_balance" name="add_balance" placeholder="200.00" />
								</div>
							</div>
						</div>
					</div>
					<div v-if="v$.add_balance.$error" class="spaced validation-error">
						{{ v$.add_balance.$errors[0].$message }}
					</div>
					<div class="control-group">
						<div class="inner">
							<label class="control-label">Payment Method:</label>
							<div class="controls">
								<div class="field">
									<p-dropdown
										v-model="payment_method_id"
										name="payment_method"
										:options="payment_method_options"
										option-label="label"
										option-value="value"
										placeholder="Select Payment Method"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-content-end">
					<p-button label="Cancel" text @click="funds_modal_open = false" />
					<p-button v-if="payment_method_options.length > 0" label="Add Funds" @click="addFunds" />
				</div>
			</template>
		</p-dialog>
		<PendingCampaignsModal ref="pending_campaigns_modal" @select="setPendingCampaignStatus" />
	</div>
</template>

<script lang="ts">
import pDialog from 'primevue/dialog';
import pMessage from 'primevue/message';
import { currency } from '@/lib/Filters';
import { depositFunds, getPaymentMethodsAsOptions, getBalances, getCampaigns, updateCampaign } from '@GQL';
import PendingCampaignsModal from '@/views/Campaigns/Modals/PendingCampaigns.vue';
import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { get, set } from 'lodash-es';
import { asyncForEach } from 'modern-async';
import log from '@/lib/Log';

export default {
	name: 'AddFundsModal',
	components: {
		pDialog,
		pMessage,
		PendingCampaignsModal,
	},
	emits: ['refresh'],
	props: {
		account_id: {
			type: String,
			default: '',
		},
		show_button: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	data() {
		return {
			loading: false,
			funds_modal_open: false,
			current_balance: 0,
			add_balance: 0,
			payment_method_options: [],
			payment_method_id: '',
			pending_campaign_ids: [],
			pending_campaign_status: 'active',
		};
	},
	computed: {
		hasPaymentMethods() {
			return this.payment_method_options.length > 0;
		},
		fundingClass() {
			return {
				funded: this.current_balance > 100,
				low: this.current_balance <= 100,
			};
		},
		accountId() {
			if (this.account_id !== '') {
				return this.account_id;
			} else {
				return (
					this.$route.params.account_id ||
					this.$route.params.parent_account_id ||
					this.$root.sessionStore.user.account_id ||
					('mpid' in this.$route.params ? `${this.$route.params.mpid}-000-000` : `${this.$root.appStore.mpid}-000-000`)
				);
			}
		},
	},
	validations() {
		return {
			add_balance: {
				initial_min: helpers.withMessage(
					'An initial deposit of at least $200 is required for new accounts',
					(value) => {
						if (
							!this.$root.sessionStore.isAdminUser &&
							!get(this.$root.sessionStore, 'account.support.first_fund_date', false)
						) {
							if (value < 200) {
								return false;
							}
						}
						return true;
					}
				),
				standard_min: helpers.withMessage('A minimum deposit of at least $25 is required', (value) => {
					if (!this.$root.sessionStore.isAdminUser) {
						if (value < 25) {
							return false;
						}
					}
					return true;
				}),
			},
		};
	},
	watch: {
		async funds_modal_open(new_value) {
			if (new_value === true) {
				const result = await getBalances(this.accountId);
				this.current_balance = result.actual;
			}
		},
	},
	async mounted() {
		this.payment_method_options = await getPaymentMethodsAsOptions(this.accountId);
		this.current_balance = (await getBalances(this.accountId)).actual;
	},
	methods: {
		currency,
		async reset() {
			this.add_balance = 0;
			this.payment_method_id = '';
			this.payment_method_options = await getPaymentMethodsAsOptions(this.accountId, true);
		},
		async openAddFundsModal(add_balance_amount?: number) {
			await this.reset();
			this.v$.$reset();
			this.funds_modal_open = true;
			if (add_balance_amount && typeof add_balance_amount === 'number') {
				this.add_balance = add_balance_amount;
			}
		},
		async addFunds() {
			this.loading = true;
			// take the payment_method_id and make sale request....
			const is_valid = await this.v$.$validate();
			try {
				if (is_valid) {
					const has_pending_campaigns = await this.checkForPendingCampaigns();

					if (!has_pending_campaigns) {
						await this.completeAddFunds();
					}
				}
			} catch (err) {
				log.error('Add Funds Error', 'error', { page: 'AddFundsModal', error: err });
				this.$toast.add({
					severity: 'error',
					summary: 'Transaction was not saved',
					detail: err.response.errors[0]?.message || err.message,
				});
			} finally {
				this.loading = false;
			}
		},
		async completeAddFunds() {
			try {
				this.loading = true;
				const response = await depositFunds({
					payment_method_id: this.payment_method_id,
					account_id: this.accountId,
					user_id: this.$root.sessionStore.user.id,
					amount: this.add_balance,
				});

				if ('txn_id' in response) {
					if (
						this.$root.sessionStore.isAccountUser &&
						!get(this.$root.sessionStore, 'account.support.first_fund_date')
					) {
						set(this.$root.sessionStore, 'account.support.first_fund_date', new Date());
					}

					this.$toast.add({
						severity: 'success',
						summary: 'Funds have been added',
						life: 3000,
					});
				} else {
					throw new Error('Txn failed');
				}

				let enabled_campaigns = 0;
				let errors = 0;
				await asyncForEach(this.pending_campaign_ids, async (campaign_id) => {
					try {
						await updateCampaign(campaign_id, {
							status: this.pending_campaign_status,
						});
					} catch (err) {
						errors++;
					}
				});

				this.$emit('refresh');
				this.v$.$reset();
				this.funds_modal_open = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Transaction was not saved',
					detail: err.response.errors[0]?.message || err.message,
				});
			} finally {
				this.loading = false;
			}
		},
		async checkForPendingCampaigns() {
			const campaigns = await getCampaigns([[`account_id = '${this.accountId}' AND status = 'pending'`]], [[]]);
			if (campaigns.length > 0) {
				this.pending_campaign_ids = campaigns.map((campaign) => {
					return campaign.id;
				});
				this.$refs.pending_campaigns_modal.open();
				return true;
			}
			return false;
		},
		async setPendingCampaignStatus(value) {
			this.pending_campaign_status = value;
			this.$nextTick(async () => {
				await this.completeAddFunds();
			});
		},
	},
};
</script>

<style scoped lang="less">
.add-label {
	font-size: var(--font-size-sm);
}

.label {
	flex: 1;
	font-size: var(--font-size-sm);

	.description {
		color: var(--gray-50);
		line-height: 1.3;
		margin: 8px 0;
	}
}

.smallish {
	max-width: 150px;
}

.balance {
	align-items: center;
	border-radius: 5px;
	display: flex;
	font-size: var(--font-size-sm);
	font-weight: bold;
	justify-content: space-between;
	padding: 10px;

	&.funded {
		background: #eaf5dc;
		border: 1px solid #bade8d;
	}

	&.low {
		background: rgb(238 0 0 / 10%);
		border: 1px solid var(--red);
	}
}

.spaced {
	margin: 10px 0 20px;
	text-align: right;
}
</style>
