import PromotionsList from '@/views/Promotions/List/Page.vue';
import PromotionRecord from '@/views/Promotions/Record/Page.vue';

export default [
	{
		path: 'promotions/new',
		name: 'CreatePromotion',
		component: PromotionRecord,
		meta: {
			new: true,
			title: 'New Promotion',
			permissions: 'admin.promotions',
		},
	},
	{
		path: 'promotions/:promotion_id',
		name: 'EditPromotion',
		component: PromotionRecord,
		meta: {
			title: 'Edit Promotion',
			permissions: 'admin.promotions',
		},
	},
	{
		path: 'promotions',
		name: 'PromotionsList',
		component: PromotionsList,
		meta: {
			title: 'Promotions',
			permissions: 'admin.promotions',
		},
	},
];
