import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';
import type { Network } from '@nextgenleads/source-driver';

export async function getNetworks(filters?: QueryFilters, no_cache?: string[]): Promise<Network[]> {
	const query = `
        query GetNetworks($filters: [[Any]]) {
            networks(filters: $filters) {
                id
                name
                url
                contacts {
					name
					email
					phone
					type
				}
            }
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.networks;
}
