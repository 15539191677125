<template>
	<div id="form-capture_config">
		<p-card class="capture-config-card">
			<template #content>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="name">Name:</label>
						<div class="controls">
							<div class="field">
								<p-input-text id="name" v-model="capture_config.name" class="p-inputtext" placeholder="Name" />
								<div v-if="v$.capture_config.name.$error" class="validation-error">
									{{ v$.capture_config.name.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="description">Description:</label>
						<div class="controls">
							<div class="field">
								<p-textarea
									id="description"
									v-model="capture_config.description"
									class="p-inputtext w-full"
									placeholder="Enter additional description"
									style="min-height: 4rem"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="owner_id">Vendor ID:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									v-model="capture_config.vendor_id"
									option-label="label"
									option-value="value"
									:options="vendor_options"
									placeholder="Select vendor"
								/>
								<div v-if="v$.capture_config.vendor_id.$error" class="validation-error">
									{{ v$.capture_config.vendor_id.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="method">Method:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="method"
									v-model="capture_config.method"
									option-label="label"
									option-value="value"
									:options="method_options"
									placeholder="Select method"
								/>
								<div v-if="v$.capture_config.method.$error" class="validation-error">
									{{ v$.capture_config.method.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">
							<label class="control-label req" for="product-type">Product Type:</label>
						</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="product-type"
									v-model="capture_config.product"
									option-label="label"
									option-value="value"
									:options="product_type_options"
									placeholder="Select product type"
								/>
								<div v-if="v$.capture_config.product.$error" class="validation-error">
									{{ v$.capture_config.product.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="vertical">Vertical:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="vertical"
									v-model="capture_config.vertical_id"
									option-label="label"
									option-value="value"
									:options="vertical_options"
									placeholder="Select vertical"
								/>
								<div v-if="v$.capture_config.vertical_id.$error" class="validation-error">
									{{ v$.capture_config.vertical_id.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label" for="source-ids">Source IDs:</label>
						<div class="controls">
							<div class="field">
								<p-chips add-on-blur id="source-ids" v-model="capture_config.source_ids" separator="," />
								<div class="sub-value">Add source_ids here to handle leads from specific sources</div>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Filter Match:</label>
						<div class="controls">
							<div class="field">
								<code-editor id="body" v-model.lazy="filterMatch" />
							</div>
						</div>
					</div>
				</div>
				<p-fieldset legend="Build Steps">
					<div class="build-steps" v-sortable="sortable_options">
						<template v-for="build_step in capture_config.build_steps" :key="build_step.id">
							<component
								:is="build_step.script"
								v-model="build_step.settings"
								@remove="removeBuildStep(build_step.id)"
							/>
						</template>
					</div>
					<build-step-selector
						:build-steps="capture_config.build_steps"
						:method="capture_config.method"
						@addStep="appendBuildStep"
					/>
				</p-fieldset>
				<gutter size="20px" />
				<div class="control-group">
					<div class="inner">
						<label class="control-label req" for="status">Status:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown
									id="status"
									v-model="capture_config.status"
									option-label="label"
									option-value="value"
									:options="active_status_options"
									placeholder="Select status"
								/>
								<div v-if="v$.capture_config.status.$error" class="validation-error">
									{{ v$.capture_config.status.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
			<template #footer>
				<div class="flex justify-content-between mt-2">
					<p-button text @click="$router.back()">CANCEL</p-button>
					<p-button icon="pi pi-check" label="Save Changes" @click="save" />
				</div>
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import Sortable from 'sortablejs';
import { insertCaptureConfig, updateCaptureConfig, getCaptureConfigById } from '@GQL';
import { active_status_options, product_type_options, vertical_options } from '@/lib/Options';
import { required, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useSessionStore } from '@/stores/session';
import { cloneDeep, merge, omit, remove } from 'lodash-es';
import { ulid } from 'ulid';
import pChips from 'primevue/chips';
import pTextarea from 'primevue/textarea';
import log from '@/lib/Log';
import codeEditor from '@/components/forms/CodeEditor.vue';
import buildStepSelector from './BuildSteps/BuildStepSelector.vue';
import applyScripts from './BuildSteps/applyScripts.vue';
import auditTokens from './BuildSteps/auditTokens.vue';
import checkDNC from './BuildSteps/checkDNC.vue';
import checkDuplicates from './BuildSteps/checkDuplicates.vue';
import checkSpam from './BuildSteps/checkSpam.vue';
import checkTests from './BuildSteps/checkTests.vue';
import checkVendorPermissions from './BuildSteps/checkVendorPermissions.vue';
import createPingCallRecord from './BuildSteps/createPingCallRecord.vue';
import delay from './BuildSteps/delay.vue';
import forwardToPartners from './BuildSteps/forwardToPartners.vue';
import normalizeData from './BuildSteps/normalizeData.vue';
import normalizePII from './BuildSteps/normalizePII.vue';
import normalizeSource from './BuildSteps/normalizeSource.vue';
import processAuctionLogs from './BuildSteps/processAuctionLogs.vue';
import processPingBids from './BuildSteps/processPingBids.vue';
import replyWithAuctionResult from './BuildSteps/replyWithAuctionResult.vue';
import replyWithAuctionWinners from './BuildSteps/replyWithAuctionWinners.vue';
import replyWithBids from './BuildSteps/replyWithBids.vue';
import replyWithLegacyPingResponse from './BuildSteps/replyWithLegacyPingResponse.vue';
import replyWithLegacyPostResponse from './BuildSteps/replyWithLegacyPostResponse.vue';
import replyWithLTBids from './BuildSteps/replyWithLTBids.vue';
import replyWithStatus from './BuildSteps/replyWithStatus.vue';
import saveLeadToDB from './BuildSteps/saveLeadToDB.vue';
import saveLeadToRedis from './BuildSteps/saveLeadToRedis.vue';
import savePingToDB from './BuildSteps/savePingToDB.vue';
import savePostToDB from './BuildSteps/savePostToDB.vue';
import sendToAuction from './BuildSteps/sendToAuction.vue';
import sendToDialer from './BuildSteps/sendToDialer.vue';
import sendToQueue from './BuildSteps/sendToQueue.vue';
import 'highlight.js/styles/atom-one-dark.css';

export default {
	components: {
		codeEditor,
		pChips,
		pTextarea,
		buildStepSelector,
		applyScripts,
		auditTokens,
		checkDNC,
		checkDuplicates,
		checkSpam,
		checkTests,
		checkVendorPermissions,
		createPingCallRecord,
		delay,
		forwardToPartners,
		normalizeData,
		normalizePII,
		normalizeSource,
		processAuctionLogs,
		processPingBids,
		replyWithAuctionResult,
		replyWithAuctionWinners,
		replyWithBids,
		replyWithLegacyPingResponse,
		replyWithLegacyPostResponse,
		replyWithLTBids,
		replyWithStatus,
		saveLeadToDB,
		saveLeadToRedis,
		savePingToDB,
		savePostToDB,
		sendToAuction,
		sendToDialer,
		sendToQueue,
	},
	setup() {
		return {
			sessionStore: useSessionStore(),
			v$: useVuelidate(),
		};
	},
	data() {
		const default_capture_config = {
			mpid: this.$route.params.mpid || this.$root.appStore.mpid, // read from route + userstore.role.mpid
			name: '',
			description: '',
			vendor_id: '',
			vertical_id: '',
			product: '',
			method: '',
			source_ids: [],
			build_steps: [],
			status: '',
		};

		return {
			capture_config: cloneDeep(default_capture_config),
			product_type_options,
			vertical_options,
			method_options: [
				{ label: 'Ping', value: 'ping' },
				{ label: 'Post', value: 'post' },
				{ label: 'Submit', value: 'submit' },
			],
			vendor_options: [
				{
					value: 'nextgenleads',
					label: 'NextGen Leads',
				},
			], // to be async grab from gql in the future
			active_status_options,
			build_step_modal: false,
			loading: false,
			sortable_options: {
				draggable: '.build-step',
				handle: '.move-handle',
			},
		};
	},
	computed: {
		filterMatch: {
			get() {
				try {
					return JSON.stringify(this.capture_config.match, null, 2);
				} catch (err) {
					return '';
				}
			},
			set(new_value) {
				try {
					if (new_value) {
						this.capture_config.match = JSON.parse(new_value);
					}
				} catch (err) {
					this.capture_config.match = null;
				}
			},
		},
	},
	validations() {
		return {
			capture_config: {
				name: {
					required: helpers.withMessage('A name is required.', required),
				},
				product: {
					required: helpers.withMessage('A product is required.', required),
				},
				method: {
					required: helpers.withMessage('Method is required.', required),
				},
				vendor_id: {
					required: helpers.withMessage('Vendor ID is required.', required),
				},
				vertical_id: {
					required: helpers.withMessage('A vertical is required.', required),
				},
				status: {
					required: helpers.withMessage('Status is required.', required),
				},
			},
		};
	},
	methods: {
		appendBuildStep(value) {
			value.id = ulid();
			this.capture_config.build_steps.push(value);
		},
		removeBuildStep(id) {
			remove(this.capture_config.build_steps, (step) => {
				return step.id === id;
			});
		},
		sortValues(old_index, new_index) {
			const build_step = this.capture_config.build_steps.splice(old_index, 1)[0];
			this.capture_config.build_steps.splice(new_index, 0, build_step);
		},
		async save() {
			this.loading = true;
			const is_valid = await this.v$.$validate();
			if (is_valid) {
				try {
					let result;
					const new_capture_config = cloneDeep(this.capture_config);
					new_capture_config.build_steps = new_capture_config.build_steps.map((build_step) => {
						delete build_step.id;
						return build_step;
					});

					if (this.$route.meta.new) {
						result = await insertCaptureConfig(new_capture_config);
					} else {
						result = await updateCaptureConfig(new_capture_config.id, omit(new_capture_config, ['id']));
					}
					log.trace('Res', result);

					if (result) {
						this.$toast.add({
							severity: 'success',
							summary: 'CaptureConfig was updated',
							life: 3000,
						});
						this.$router.back();
						return;
					}
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save changes',
						life: 3000,
					});
				} finally {
					this.loading = false;
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'A field is invalid',
					life: 3000,
				});
				this.loading = false;
			}
		},
	},
	async mounted() {
		if (this.$route.params.capture_config_id || (this.$route.meta.new && this.$route.query.capture_config_id)) {
			this.loading = true;
			const result = await getCaptureConfigById(
				this.$route.params.capture_config_id || this.$route.query.capture_config_id
			);
			this.capture_config = { ...result };

			// Map IDs onto the build steps
			this.capture_config.build_steps = this.capture_config.build_steps.map((build_step) => {
				build_step.id = ulid();
				return build_step;
			});

			if (this.$route.meta.new) {
				this.capture_config.name += ' (Copy)';
			}

			this.loading = false;
		}
	},
	directives: {
		sortable: {
			beforeMount(el, binding, vnode) {
				let options = merge({}, binding.value, {
					onEnd: (event) => {
						binding.instance.sortValues(event.oldIndex, event.newIndex);
					},
				});

				vnode.sortable = Sortable.create(el, options);
			},
		},
	},
};
</script>

<style scoped lang="less">
.capture-config-card {
	max-width: 800px;
}

.build-step {
	background-color: white;
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	box-shadow: 0 3px 0 rgb(0 0 0 / 10%);
	margin-bottom: 1rem;
	position: relative;

	:deep(.title) {
		align-items: center;
		background-color: var(--color-b-lightest);
		border-bottom: 1px solid var(--gray-15);
		display: flex;
		flex-direction: row;
		height: 50px;

		.move-handle {
			cursor: ns-resize;
			text-align: center;
			width: 50px;
		}

		.name {
			flex: 1 0;
			font-weight: bold;
		}

		.delete {
			margin-right: 10px;
		}
	}

	:deep(.description) {
		color: var(--gray-50);
		line-height: 1.35em;
		padding: 20px;
	}

	:deep(.step-settings) {
		padding: 0 20px 20px;

		h3 {
			font-size: 1rem;
			font-weight: bold;
			margin-bottom: 0.5em;
		}
	}
}
</style>
