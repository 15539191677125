import { $GQL } from '@GQL/index';

export async function getLocalOverrideSettingsList(id: string): Promise<string[]> {
	const query = `
        query GetLocalOverrideSettingsList($id: ID!) {
            localOverrideSettingsList(id: $id)
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.localOverrideSettingsList;
}
