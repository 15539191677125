<template>
	<div id="live-transfer-start" class="view-content">
		<div class="page-heading">
			<h1>Live Transfer Workstation</h1>
		</div>
		<gutter size="20px" />
		<div id="live-transfer-leads-list">
			<query-form v-model:query="query" :loading="loading" @apply="getLiveTransferEventHistory">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.date_range, 'ddd, MMM DD, YYYY') }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="getLiveTransferEventHistory(true)"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range
												:rangeLimit="{
													days: 62,
												}"
												v-model="form.query.date_range"
												hide-labels
												show-time
											/>
										</div>
									</div>
								</div>
							</div>
						</column>
						<gutter size="20px" />
						<column>
							<div class="control-group">
								<div class="inner">
									<label class="control-label">Lead ID:</label>
									<div class="controls">
										<div class="field">
											<p-input-text v-model="form.query.lead_id" />
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<gutter size="20px" />
			<div class="card padded">
				<p-data-table ref="dt" :loading="loading" :rows="50" scrollable :value="rows" key="refreshKey" data-key="id">
					<template #loading>
						<line-loader :show="loading" />
					</template>
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column header="Started On">
						<template #body="transfer">
							<div class="capture-date">
								{{ formatDate(new Date(transfer.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
							</div>
							<div class="sub-value">{{ timeAgo(transfer.data.created_at) }}</div>
						</template>
					</p-column>
					<p-column header="Vertical" class="column-align-center">
						<template #body="transfer">
							<vertical-icon :vertical-id="transfer.data.vertical_id" size="30px" />
						</template>
					</p-column>
					<p-column header="Lead">
						<template #body="transfer">
							{{ phoneFormat(transfer.data.lead_phone) }}
							<div class="sub-value">{{ transfer.data.lead_name }} | {{ transfer.data.lead_id }}</div>
						</template>
					</p-column>
					<p-column header="Agent">
						<template #body="transfer">
							{{ transfer.data.agent_name }}
						</template>
					</p-column>
					<p-column header="Events">
						<template #body="transfer">
							<div @click="openModal(transfer.data)" class="clickable">
								{{ title(transfer.data.final_event) }} ({{ transfer.data.events.length }})
								<icon type="information" class="mr-2" size="18px" />
							</div>
						</template>
					</p-column>
				</p-data-table>
				<p-paginator :rows="query.pagination.page_size" :total-records="total_records" @page="handlePageChange" />
			</div>
		</div>

		<p-dialog
			v-model:visible="show_modal"
			modal
			header="Conversions by Sub Id"
			:style="{ width: '55vw' }"
			:dismissable-mask="true"
		>
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<strong>History of {{ selected_history.lead_name }} by Agent: {{ selected_history.agent_name }}</strong>
				</div>
			</template>
			<div>
				<p-data-table :value="selected_history.events">
					<template #empty>
						<div class="dim">No results</div>
					</template>
					<p-column header="Event Created At">
						<template #body="event">
							<div class="capture-date">
								{{ formatDate(new Date(event.data.created_at), 'YYYY-MM-DD | h:mm:ssa') }}
							</div>
							<div class="sub-value">{{ timeAgo(event.data.created_at) }}</div>
						</template>
					</p-column>
					<p-column header="Action">
						<template #body="event">
							{{ title(event.data.event_type) }}
						</template>
					</p-column>
					<p-column header="Reason">
						<template #body="event">
							<div v-if="event.data.event_type === 'completed_transfer'">
								Sold To <strong> {{ event.data.display_name || 'Not Found' }} </strong>
								<div class="sub-value">Buyer Phone: {{ phoneFormat(event.data.buyer_phone) }}</div>
							</div>
							<div v-else-if="event.data.event_type.indexOf('Purchase') > -1">
								<template v-if="event.data.event_type.indexOf('complete') > -1">
									Price: {{ currency(get(event, 'data.price', 0)) }}
								</template>
								<template v-else>
									{{ event.data.reason }}
								</template>
							</div>
							<div v-else-if="event.data.event_type !== 'unsold_transfer'" v-tooltip.top="event.data.note">
								{{ event.data.reason }}
								<div class="sub-value">Buyer Phone: {{ phoneFormat(event.data.buyer_phone) }}</div>
							</div>
							<div v-else>N/A</div>
						</template>
					</p-column>
					<p-column header="Buyer">
						<template #body="event">
							<div v-if="event.data.event_type === 'unsold_transfer'">N/A</div>
							<div v-else>
								{{ event.data.account_id }}
								<div class="sub-value">{{ event.data.display_name }}</div>
							</div>
						</template>
					</p-column>
				</p-data-table>
			</div>
		</p-dialog>
	</div>
</template>

<script lang="ts">
import { endOfDay, startOfDay } from 'date-fns';
import dateRange from '@/components/forms/DateRange.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import {
	formatDate,
	displaySelected,
	currency,
	verticalName,
	phoneFormat,
	capitalize,
	timeAgo,
	title,
} from '@/lib/Filters';
import type { FilterParams, GetListParams } from '@nextgenleads/db';
import { reportLiveTransferEventHistory } from '@GQL';
import { get } from 'lodash';

export default {
	name: 'LiveTransferEventHistory',
	components: {
		dateRange,
		queryForm,
		verticalIcon,
	},
	data() {
		return {
			loading: false,
			show_modal: false,
			selected_history: {},
			query: {
				date_range: [startOfDay(new Date()), endOfDay(new Date())],
				lead_id: '',
				pagination: {
					page_size: 50,
					page: 0,
				},
			},
			rows: [],
			total_records: 0,
		};
	},
	async mounted() {
		await this.getLiveTransferEventHistory();
	},
	methods: {
		get,
		title,
		capitalize,
		currency,
		formatDate,
		displaySelected,
		phoneFormat,
		timeAgo,
		verticalName,
		openModal(data) {
			this.selected_history = data;
			this.show_modal = true;
		},
		handlePageChange(data) {
			// data has a page field
			this.query.pagination.page = data.page;
			return this.getLiveTransferEventHistory();
		},
		async getLiveTransferEventHistory(refresh = false) {
			this.loading = true;
			try {
				const query_params = {
					date_range: [this.query.date_range[0].toISOString(), this.query.date_range[1].toISOString()],
					pagination: this.query.pagination,
				};
				if (this.query.lead_id.length === 11) {
					query_params.lead_id = this.query.lead_id;
				}

				const result = await reportLiveTransferEventHistory(query_params);
				this.rows = result.rows;
				this.total_records = result.row_count;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get the transfer records',
					life: 6500,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="less" scoped>
.clickable {
	&:hover {
		cursor: pointer;
		color: var(--text-color-secondary);
	}
}
</style>
