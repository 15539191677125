<template>
	<div id="marketplace-table">
		<p-data-table
			paginator
			:always-show-paginator="false"
			:rows="15"
			data-key="id"
			scrollable
			:value="sortedMarketplaces"
			:loading="loading"
		>
			<template #empty>
				<div class="dim">No Marketplaces found.</div>
			</template>
			<template #loading>
				<line-loader :show="loading" />
			</template>
			<p-column field="name" header="Name" sortable sortField="name">
				<template #body="row">
					<router-link :to="`/marketplaces/manage/${row.data.id}/accounts`">
						{{ row.data.name }}
					</router-link>
				</template>
			</p-column>
			<p-column field="id" header="MPID" class="column-align-center" sortable sortField="id">
				<template #body="row">
					{{ row.data.id }}
				</template>
			</p-column>
			<p-column field="owner_id" header="MP Owner" sortable sortField="owner.first_name">
				<template #body="row">
					{{ row.data.owner.first_name + ' ' + row.data.owner.last_name }}
				</template>
			</p-column>
			<p-column field="status" header="Status" class="column-align-center" sortable sortField="status">
				<template #body="row">
					{{ capitalize(row.data.status) }}
				</template>
			</p-column>
			<p-column header="Tools" class="align-center">
				<template #body="row">
					<div class="toolset">
						<div class="tool">
							<p-button
								v-tooltip.top="'Manage'"
								aria-label="Manage"
								@click="$router.push({ path: `${$route.path}/manage/${row.data.id}/accounts` })"
							>
								<template #icon>
									<icon type="open-in-new" size="20px" />
								</template>
							</p-button>
						</div>
						<div class="tool">
							<p-button
								v-tooltip.top="'Edit'"
								aria-label="Edit"
								@click="$router.push({ path: `${$route.path}/${row.data.id}` })"
							>
								<template #icon>
									<icon type="square-edit-outline" size="20px" />
								</template>
							</p-button>
						</div>
					</div>
				</template>
			</p-column>
		</p-data-table>
	</div>
</template>
<script lang="ts">
import { $GQL } from '@GQL';
import pDataTable from 'primevue/datatable';
import pColumn from 'primevue/column';
import { get, sortBy, capitalize } from 'lodash-es';
import { mapStores } from 'pinia';
import { useSessionStore } from '@/stores/session';

export default {
	name: 'MarketplacesList',
	components: {
		pDataTable,
		pColumn,
	},
	data() {
		return {
			loading: false,
			marketplaces: [],
			user_names: {},
		};
	},
	computed: {
		...mapStores(useSessionStore),
		sortedMarketplaces() {
			return sortBy(this.marketplaces, ['name']);
		},
	},
	async mounted() {
		this.getList();
	},
	methods: {
		capitalize,
		async getUserName(user_id: string) {
			// from the internal users, find
			return get(this.user_names, user_id, 'User Not Found');
		},
		async getList() {
			const query = `
				query GetMarketplacesForList {
					marketplaces {
						id
						name
						owner {
							first_name
							last_name
						}
						status
					}
				}
			`;
			this.loading = true;
			try {
				this.marketplaces = (await $GQL.request(query)).marketplaces;
			} catch (err) {
				this.$toast.add({
					summary: 'Unable to get list',
					severity: 'error',
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
