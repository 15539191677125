<template>
	<!-- Customer Facing Version -->
	<div id="campaign-stats" class="view-content">
		<line-loader :show="loading" />
		<div class="page-heading">
			<h1>Campaign Stats{{ campaign_stats.campaign.name ? `: ${campaign_stats.campaign.name}` : '' }}</h1>
			<breadcrumb :items="breadcrumbs" />
		</div>
		<div>
			<p-button label="Edit Campaign" @click="$router.push($route.path.replace('/reports', ''))">
				<template #icon>
					<div class="icon-wrapper button-icon">
						<icon type="pencil-box-outline" size="20px" />
					</div>
				</template>
			</p-button>
		</div>
		<gutter size="20px" />
		<div>
			<query-form v-model:query="query" :loading="loading" @apply="getCampaignStats()">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">
							{{ formatDate(query.date_range, 'ddd, MMM D, YYYY') }}
						</div>
					</div>
				</template>
				<template #utils>
					<p-button
						v-tooltip.top="'Refresh'"
						icon="pi pi-refresh"
						aria-label="Refresh"
						class="mr-2"
						@click="refreshReports"
					/>
				</template>
				<template #form="form">
					<row>
						<column>
							<div class="font-bold pb-2" style="font-size: var(--font-size-base)">Date Range:</div>
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field xl">
											<date-range v-model="form.query.date_range" show-time />
										</div>
									</div>
								</div>
							</div>
						</column>
					</row>
				</template>
			</query-form>
			<row>
				<column>
					<div class="card">
						<div class="card-heading padded">
							<div class="flex align-items-center">
								<icon type="chart-line" size="20px" />
								<h2>Leads and Spend</h2>
							</div>
						</div>
						<div class="padded">
							<line-chart :chart-data="leadsAndSpendChartData" :custom-options="chart_options" />
						</div>
					</div>
				</column>
			</row>
			<gutter size="20px" />
			<TabView lazy>
				<TabPanel header="State Competition">
					<StateCompetition :query="query" />
				</TabPanel>
				<TabPanel header="Disqualifications">
					<DQReasons :query="query" />
				</TabPanel>
				<TabPanel header="Recent Auctions">
					<RecentAuctions :query="query" />
				</TabPanel>
			</TabView>
		</div>
	</div>
</template>

<script lang="ts">
import dateRange from '@/components/forms/DateRange.vue';
import lineChart from '@/components/charts/Line.vue';
import queryForm from '@/components/widgets/QueryForm.vue';
import { currency, formatDate, numberFormat, percentage, timeAgo } from '@/lib/Filters';
import { getCampaignStats, getRecentAuctions } from '@GQL';
import dayjs from 'dayjs';
import { round, sumBy } from 'lodash-es';
import pTimeline from 'primevue/timeline';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import DQReasons from '../Sections/DQReasons.vue';
import StateCompetition from '../Sections/StateCompetition.vue';
import RecentAuctions from '../Sections/RecentAuctions.vue';

export default {
	name: 'AccountCampaignReport',
	components: {
		StateCompetition,
		DQReasons,
		RecentAuctions,
		dateRange,
		lineChart,
		pTimeline,
		queryForm,
		TabPanel,
		TabView,
	},
	data() {
		return {
			breadcrumbs: [
				{ label: 'Campaigns', route: '/campaigns' },
				{ label: `Campaign`, route: `/campaigns/${this.$route.params.campaign_id}` },
				{ label: 'Stats' },
			],
			loading: false,
			query: {
				date_range: [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
			},
			campaign_stats: {
				leads_and_spend: [],
				campaign: {
					name: '',
				},
			},
			recent_auctions: [],
			chart_options: {
				responsive: true,
				plugins: {
					tooltip: {
						callbacks: {
							label: function (tooltip) {
								if (tooltip.dataset.label.indexOf(' Leads Purchased') > -1) {
									return `${tooltip.dataset.label}: ${tooltip.raw}`;
								}
								return `${tooltip.dataset.label}: ${currency(tooltip.raw)}`;
							},
						},
					},
				},
				scales: {
					y: {
						type: 'linear',
						display: true,
						position: 'left',
						min: 0,
						ticks: {
							callback: function (value) {
								return value;
							},
						},
					},
					y_spend: {
						type: 'linear',
						display: true,
						position: 'right',
						min: 0,
						ticks: {
							callback: function (value) {
								return currency(value);
							},
						},
					},
				},
				layout: {
					padding: 30,
				},
			},
		};
	},
	computed: {
		leadsAndSpendChartData() {
			return {
				labels: this.campaign_stats.leads_and_spend.map((row) => {
					return dayjs(row.date).format('MMM D');
				}),
				datasets: [
					{
						label: ' Leads Purchased',
						data: this.campaign_stats.leads_and_spend.map((row) => {
							return row.leads;
						}),
						fill: false,
						pointStyle: 'circle',
						pointBackgroundColor: '#FFF',
						pointRadius: 6,
						pointBorderWidth: 3,
						pointHoverRadius: 15,
						yAxisID: 'y',
					},
					{
						label: ' Spend',
						data: this.campaign_stats.leads_and_spend.map((row) => {
							return row.spend;
						}),
						fill: false,
						pointStyle: 'circle',
						pointBackgroundColor: '#FFF',
						pointRadius: 6,
						pointBorderWidth: 3,
						pointHoverRadius: 15,
						yAxisID: 'y_spend',
					},
					{
						label: ' Cost Per Lead',
						data: this.campaign_stats.leads_and_spend.map((row) => {
							return row.rpl;
						}),
						fill: false,
						pointStyle: 'circle',
						pointBackgroundColor: '#FFF',
						pointRadius: 6,
						pointBorderWidth: 3,
						pointHoverRadius: 15,
						yAxisID: 'y_spend',
					},
				],
			};
		},
	},
	async mounted() {
		await this.getCampaignStats();
	},
	methods: {
		currency,
		formatDate,
		numberFormat,
		percentage,
		round,
		timeAgo,
		async getCampaignStats(refresh = false) {
			this.loading = true;
			try {
				let no_cache = [];
				if (refresh) {
					no_cache = ['reportCampaignStats'];
				}

				const campaign_stats = await getCampaignStats(
					{
						campaign_id: this.$route.params.campaign_id,
						date_range: this.query.date_range,
					},
					no_cache
				);

				this.campaign_stats = campaign_stats;
				this.loading = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get campaign stats',
					detail: 'Please contact dev support',
				});
			}
			this.loading = false;
		},
		async refreshReports() {
			await this.getCampaignStats(true);
		},
	},
};
</script>
