<template>
	<div class="voice-route-step">
		<div class="header move-handle">
			<div class="header-left" @click="expanded = !expanded">
				<div class="flex align-items-center gap-10">
					<icon type="dots-vertical" size="24px" />
					<strong>Play</strong> <span class="sub-value">Play an audio file</span>
				</div>
				<div>
					<div v-if="!expanded" class="expand-icon">
						<icon type="arrow-expand" size="20px" />
					</div>
					<div v-if="expanded" class="expand-icon">
						<icon type="arrow-collapse" size="20px" />
					</div>
				</div>
			</div>
			<div class="header-right">
				<div class="delete-icon">
					<icon type="delete" size="20px" @click="deleteStep" />
				</div>
			</div>
		</div>
		<div v-if="expanded" class="content">
			<div class="step-options">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Audio File:</label>
						<div class="controls gap-10">
							<div class="field">
								<p-input-text v-model="localRouteStep.params.url" />
							</div>
							<div class="field fit">
								<custom-upload
									folder="ngl4-call-audio"
									accept="audio/*"
									@success="localRouteStep.params.url = $event.data[0].location"
								/>
							</div>
						</div>
						<div v-if="localRouteStep.params.url" class="controls">
							<div class="field">
								<audio controls><source :src="localRouteStep.params.url" /></audio>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label">Play Digit Audio:</label>
								<p-input-text
									v-model="localRouteStep.params.options.digits"
									placeholder="Enter digits to play audio for..."
								/>
							</div>
							<div class="field xs">
								<label class="control-label">Play Audio:</label>
								<div class="p-inputgroup">
									<p-input-number v-model="localRouteStep.params.options.loop" class="input-align-right" />
									<div class="p-inputgroup-addon loose">times</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import customUpload from '@/components/forms/CustomUpload.vue';

export default {
	name: 'VoiceRoutePlayHandler',
	emits: ['update', 'delete'],
	components: {
		customUpload,
	},
	props: {
		routeStep: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			default_config: {
				handler: 'Play',
				params: {
					url: '',
					options: {
						digits: '',
						loop: 1,
					},
				},
			},
			expanded: false,
		};
	},
	computed: {
		localRouteStep: {
			get() {
				return this.routeStep;
			},
			set(new_value) {
				this.$emit('update', new_value);
			},
		},
	},
	methods: {
		deleteStep(event) {
			this.$confirm.require({
				target: event.currentTarget,
				group: 'voice_route_builder',
				message: 'Are you sure you want to delete this route step?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: 'Delete Route Step',
				rejectLabel: 'Cancel',
				accept: async () => {
					this.$emit('delete');
				},
			});
		},
	},
};
</script>
