import { $GQL } from '@GQL/index';
import type { Account } from '@nextgenleads/marketplace-driver';

export async function cancelPurchase(purchase_id: string, data?: Record<string, unknown>) {
	const mutation = `
        mutation CancelPurchase($id: ID!, $data: JSONObject) {
            cancelPurchase(id: $id, data: $data)
        }
    `;

	const result = await $GQL.request(mutation, { id: purchase_id, data });
	return result.cancelPurchase;
}
