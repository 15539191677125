<template>
	<div>
		<p-button label="Convert by Phone" @click="openModal">
			<template #icon>
				<icon type="phone-check" size="20px" />
				<gutter size="10px" />
			</template>
		</p-button>
		<p-dialog v-model:visible="show_modal" class="modal" modal style="max-width: 760px">
			<loader :show="loading" />
			<template #header>
				<div class="flex gap-2 align-content-center align-items-center">
					<strong>Convert by Phone</strong>
				</div>
			</template>
			<div class="conversion-form">
				<p>Enter a list of phone numbers to convert that are separated by commas, spaces, or returns.</p>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Phone Numbers:</label>
						<div class="controls">
							<div class="field">
								<p-textarea v-model="phone_numbers" />
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Disposition:</label>
						<div class="controls">
							<div class="field">
								<p-dropdown v-model="disposition" :options="disposition_options" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<div class="flex align-items-center justify-content-between">
					<p-button label="Cancel" text @click="show_modal = false" />
					<p-button
						label="Submit Conversions"
						:disabled="loading"
						:loading="loading"
						icon="pi pi-check"
						@click="submitConversions"
					/>
				</div>
			</template>
		</p-dialog>
	</div>
</template>
<script>
import { convertByPhone } from '@GQL';

export default {
	name: 'ConvertByPhoneModal',
	emits: ['success'],
	data() {
		return {
			loading: false,
			phone_numbers: null,
			disposition: 'Sold',
			disposition_options: [
				'New',
				'Contacted - Working',
				'Contacted - Quoted',
				'Contacted - Unqualified/Unsold',
				'Attempting Contact',
				'Sold',
				'Invalid Contact Info',
			],
			show_modal: false,
		};
	},
	methods: {
		openModal() {
			this.phone_numbers = null;
			this.disposition = 'Sold';
			this.show_modal = true;
		},
		async submitConversions() {
			this.loading = true;

			// Turn the phone numbers into an array
			let phone_numbers = this.phone_numbers;
			phone_numbers = phone_numbers.replace(/\D/g, '');
			phone_numbers = phone_numbers.match(/\d{10}/g);

			// Get the account_id
			const account_id = this.$route.params.account_id || this.$root.sessionStore.user.account_id;

			try {
				const result = await convertByPhone({
					account_id,
					disposition: this.disposition,
					phone_numbers,
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Conversions Updated Successfully',
					detail: `Inserted: ${result.inserted}, Updated: ${result.updated}, Ignored: ${result.ignored}`,
					life: 8000,
				});
				this.$emit('success');
				this.show_modal = false;
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to Process Conversions',
					detail: 'Please try again or contact support',
					life: 5000,
				});
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
