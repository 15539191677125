import JobConfigsList from '@/views/JobConfigs/List/Page.vue';
import JobConfigRecordPage from '@/views/JobConfigs/Record/Page.vue';

export default [
	{
		path: 'job-configs/new',
		name: 'CreateJobConfig',
		component: JobConfigRecordPage,
		meta: {
			new: true,
			parent_menu: 'Job Service',
			title: 'New Job Config',
			permissions: 'admin.job_configs',
		},
	},
	{
		path: 'job-configs/:job_config_id',
		name: 'EditJobConfig',
		component: JobConfigRecordPage,
		meta: {
			parent_menu: 'Job Service',
			title: 'Edit Job Config',
			permissions: 'admin.job_configs',
		},
	},
	{
		path: 'job-configs',
		name: 'JobConfigsList',
		component: JobConfigsList,
		meta: {
			parent_menu: 'Job Service',
			title: 'Job Configs',
			permissions: 'admin.job_configs',
		},
	},
];
