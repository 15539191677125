<template>
	<div id="inner-wrapper" class="private">
		<p-confirm-dialog group="default" />
		<sidebar
			:menu-collapsed="menuCollapsed"
			:menu-hidden="menu_hidden"
			:collapsed-active="collapsed_active"
			@mobile-toggle="handleMobileToggle"
		/>
		<main :class="{ 'opacity-50': !menu_hidden && mq.phone }">
			<toolbar
				ref="toolbar"
				:menu_collapsed="menu_collapsed"
				@menu-collapse-toggle="handleMenuToggle"
				@mobile-menu-toggle="handleMobileToggle"
			/>
			<div id="view" ref="view" @click="handleBodyClick">
				<router-view v-slot="{ Component, route }">
					<transition :name="route.meta.transition || 'drop-fade'" mode="out-in">
						<component :is="Component" />
					</transition>
				</router-view>
			</div>
		</main>
		<notifications-drawer @dismiss-notification="handleDismissNotification" />
		<socket-toast />
	</div>
</template>

<script lang="ts">
import pToast from 'primevue/toast';
import sidebar from '@/components/ui/Sidebar.vue';
import toolbar from '@/components/ui/Toolbar.vue';
import pConfirmDialog from 'primevue/confirmdialog';
import notificationsDrawer from '@/components/widgets/Notifications/Drawer.vue';
import socketToast from '@/components/elements/SocketToast.vue';
import { useSessionStore } from '@/stores/session';

export default {
	name: 'PrivateWrapper',
	components: {
		pConfirmDialog,
		pToast,
		sidebar,
		toolbar,
		notificationsDrawer,
		socketToast,
	},
	inject: ['mq'],
	data() {
		return {
			menu_collapsed: false,
			menu_hidden: true,
			collapsed_active: false,
		};
	},
	computed: {
		menuCollapsed() {
			if (this.$route.meta.nested_marketplace) {
				return true;
			}
			return this.menu_collapsed;
		},
	},
	methods: {
		handleBodyClick() {
			if (this.mq.phone === true && this.menu_hidden === false) {
				this.menu_hidden = !this.menu_hidden;
			}
		},
		handleMenuToggle() {
			this.menu_collapsed = !this.menu_collapsed;
			this.collapsed_active = false;
		},
		handleMobileToggle() {
			this.menu_collapsed = false;
			this.collapsed_active = false;
			this.menu_hidden = !this.menu_hidden;
		},
		handleDismissNotification(id) {
			this.$refs.toolbar.$refs.notificationwidget.removeNotification(id);
		},
	},
	created() {
		const sessionStore = useSessionStore();
		if (sessionStore.isAccountUser) {
			const zendesk = document.createElement('script');
			zendesk.setAttribute('id', 'ze-snippet');
			zendesk.setAttribute(
				'src',
				'https://static.zdassets.com/ekr/snippet.js?key=b409efbc-83f5-4d63-8722-732f60b83265'
			);
			document.head.appendChild(zendesk);
		}
	},
};
</script>
