import { $GQL } from '@GQL/index';

export async function getCallLogById(id: string) {
	const query = `
        query GetCallLogById($id: ID!) {
            callLog(id: $id) {
                id
				provider_id
				provider_call_id
				provider_phone_id
				provider_account_id
				phone_type
				lead_id
				data_lead_id
				call_type
				source_id
				sub_id
				duration
				buyer_duration
				recording_url
				ngl_phone
				lead_phone
				events
				cost
				errors
				data
				gathered
				started_at
				ended_at
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.callLog;
}
