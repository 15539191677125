<template>
	<div id="edit-role" class="view-content">
		<div class="page-heading">
			<h1>{{ heading }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<AuctionConfigForm />
	</div>
</template>

<script lang="ts">
import AuctionConfigForm from './Form.vue';
export default {
	name: 'AuctionConfigsEdit',
	components: {
		AuctionConfigForm,
	},
	data() {
		const is_create = this.$route.name.indexOf('AuctionConfigCreate') > -1 ? true : false;
		return {
			is_create,
			heading: is_create ? 'Create AuctionConfig' : 'Edit AuctionConfig',
			breadcrumb_items: [
				{ label: 'Auction Configs', route: '/auction-configs' },
				{ label: is_create ? 'Create AuctionConfig' : 'Edit AuctionConfig' },
			],
			loading: false,
			tableKey: 0,
		};
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
