<template>
	<div id="account-performance-report" class="view-content">
		<line-loader :show="loading" />
		<div class="page-heading">
			<h1>Account Overview</h1>
			<div v-if="account.parentAccount && account.parentAccount.name" class="sub-value">
				Sub-account of {{ account.parentAccount.name }}
			</div>
		</div>
		<div class="page-content">
			<query-form v-model:query="query" :loading="loading" @apply="getData" @abort="clearQuery">
				<template #display>
					<div class="display-value">
						<div class="label">Date Range:</div>
						<div class="value">{{ formatDate(query.date_range, 'ddd, MMM D, YYYY') }}</div>
					</div>
				</template>
				<template #form="form">
					<row>
						<column max-width="500px">
							<div class="control-group">
								<div class="inner">
									<div class="controls">
										<div class="field">
											<date-range v-model="form.query.date_range" hide-labels show-time />
										</div>
									</div>
								</div>
							</div>
						</column>
						<column></column>
					</row>
				</template>
			</query-form>
			<row>
				<column :span="3">
					<row class="metrics">
						<column>
							<metric label="Total Spend" :value="metrics.total_spend || 0" is-currency />
						</column>
						<gutter size="20px" />
						<column>
							<metric label="Leads Purchased" :value="metrics.total_purchases || 0" />
						</column>
						<gutter size="20px" />
						<column>
							<metric label="Account Balance" is-currency :value="account.balance.actual" />
						</column>
						<gutter size="20px" />
						<column>
							<metric label="Account ID" :value="accountId" />
						</column>
					</row>
					<gutter size="20px" />
					<row>
						<div class="column">
							<div class="card">
								<div class="card-heading padded">
									<icon type="chart-line" size="24px" /> <strong>Account Activity</strong>
								</div>
								<div class="padded">
									<line-chart :chart-data="leadsAndSpendChartData" :custom-options="chart_options" />
								</div>
							</div>
						</div>
					</row>
				</column>
				<gutter size="20px" />
				<column>
					<div id="account-details" class="card">
						<div class="card-heading padded">
							<icon type="face-agent" size="24px" />
							<strong>Account Support</strong>
						</div>
						<div class="padded">
							<div v-if="account.support.account_manager.first_name" class="line-item">
								<div class="label">Account Manager:</div>
								<div class="value">
									{{ account.support.account_manager.first_name }} {{ account.support.account_manager.last_name }}
								</div>
								<div class="support-contact-info">
									<div class="support-phone">
										<icon type="phone" size="18px" />
										<a :href="`tel:${account.support.account_manager.phone}`">{{
											phoneFormat(account.support.account_manager.phone)
										}}</a>
									</div>
									<div class="support-email">
										<icon type="email" size="18px" />
										<a :href="`mailto:${account.support.account_manager.email}`">{{
											account.support.account_manager.email
										}}</a>
									</div>
								</div>
							</div>

							<div class="line-item">
								<div class="label">Last Purchase:</div>
								<div v-if="account.lastPurchase" class="line-item">
									<div class="value">{{ formatDate(new Date(account.lastPurchase), 'MMM D, YYYY') }}</div>
								</div>
								<div v-else>
									<div class="value">No Purchases</div>
								</div>
							</div>
							<div class="line-item">
								<div class="label">Customer Since:</div>
								<div class="value">{{ formatDate(new Date(account.created_at), 'MMM D, YYYY') }}</div>
							</div>
						</div>
					</div>
					<gutter size="20px" />
					<template v-if="$root.sessionStore.isAdminUser">
						<NoteList :account_id="this.accountId" />
						<gutter size="20px" />
					</template>
					<AccountOfferComponent :account_id="this.accountId" />
				</column>
			</row>
		</div>
	</div>
</template>

<script lang="ts">
import { currency, formatDate, displayLabel, percentage, phoneFormat } from '@/lib/Filters';
import { reportAccountOverview } from '@GQL';
import { forIn, groupBy, startCase, uniq } from 'lodash-es';
import { mapStores } from 'pinia';
import { useSessionStore } from '@/stores/session';
import dateRange from '@/components/forms/DateRange.vue';
import lineChart from '@/components/charts/Line.vue';
import NoteList from '@/views/Notes/List/Component.vue';
import pChart from 'primevue/chart';
import pColumnGroup from 'primevue/columngroup';
import queryForm from '@/components/widgets/QueryForm.vue';
import log from '@/lib/Log';
import AccountOfferComponent from './AccountOfferComponent.vue';
import dayjs from 'dayjs';

export default {
	name: 'AccountPerformanceReport',
	components: {
		dateRange,
		pChart,
		queryForm,
		lineChart,
		NoteList,
		pColumnGroup,
		AccountOfferComponent,
	},
	data() {
		return {
			loading: false,
			query: {
				date_range: [dayjs().subtract(7, 'day').startOf('day').toDate(), dayjs().endOf('day').toDate()],
			},
			account: {
				id: null,
				balance: {
					actual: 0,
				},
				support: {
					account_manager: {
						first_name: '',
						last_name: '',
					},
				},
				parentAccount: {
					name: null,
				},
				spend: 0,
				created_at: new Date(),
			},
			notifications: [],
			metrics: {
				total_spend: 0,
				total_purchases: 0,
			},
			leads_and_spend: [],
			chart_options: {
				responsive: true,
				plugins: {
					tooltip: {
						callbacks: {
							label: function (tooltip) {
								if (tooltip.dataset.label.indexOf('Spend') > -1) {
									return `${tooltip.dataset.label}: ${currency(tooltip.raw)}`;
								}
								return `${tooltip.dataset.label}: ${tooltip.raw}`;
							},
						},
					},
				},
				scales: {
					y: {
						type: 'linear',
						display: true,
						position: 'left',
						min: 0,
						ticks: {
							callback: function (value) {
								return value;
							},
						},
					},
					y_spend: {
						type: 'linear',
						display: true,
						position: 'right',
						min: 0,
						ticks: {
							callback: function (value) {
								return currency(value);
							},
						},
					},
				},
				layout: {
					padding: 30,
				},
			},
		};
	},
	computed: {
		leadsAndSpendChartData() {
			const labels = uniq(
				this.leads_and_spend.map((row) => {
					return dayjs(row.date).format('ddd, MMM D');
				})
			);
			const datasets = [];

			const grouped_data = groupBy(this.leads_and_spend, (row) => {
				return startCase(row.vertical_id) + ' ' + startCase(row.product);
			});

			forIn(grouped_data, (values, label) => {
				datasets.push({
					label: `${label}: Leads`,
					data: values.map((row) => {
						return +row.leads;
					}),
					fill: false,
					pointStyle: 'circle',
					pointBackgroundColor: '#FFF',
					pointRadius: 6,
					pointBorderWidth: 3,
					pointHoverRadius: 15,
					yAxisID: 'y',
				});
				datasets.push({
					label: `${label}: Spend`,
					data: values.map((row) => {
						return +row.spend;
					}),
					fill: false,
					pointStyle: 'circle',
					pointBackgroundColor: '#FFF',
					pointRadius: 6,
					pointBorderWidth: 3,
					pointHoverRadius: 15,
					yAxisID: 'y_spend',
				});
			});

			return {
				labels,
				datasets,
			};
		},
		accountId() {
			if ('account_id' in this.$route.params) {
				return this.$route.params.account_id;
			} else {
				return this.sessionStore.user.account_id;
			}
		},
		...mapStores(useSessionStore),
	},
	async mounted() {
		await this.getData();
	},
	methods: {
		currency,
		displayLabel,
		formatDate,
		percentage,
		phoneFormat,
		clearQuery() {
			this.loading = false;
		},
		async getData() {
			this.loading = true;
			await this.getOverviewData();
			this.loading = false;
		},
		async getOverviewData() {
			try {
				const result = await reportAccountOverview({
					...this.query,
					account_id: this.accountId,
				});
				this.metrics = result.reportAccountOverview.metrics;
				this.leads_and_spend = result.reportAccountOverview.leads_and_spend;
				this.account = result.account;
			} catch (err) {
				log.error(err);
			}
		},
	},
};
</script>

<style scoped lang="less">
#account-details {
	.line-item {
		line-height: 1.35em;
		margin-bottom: 1em;

		&:last-child {
			margin-bottom: 0;
		}

		.label {
			color: var(--gray-50);
			font-size: var(--font-size-sm);
		}

		.value {
			font-weight: bold;
		}
	}

	.support-contact-info {
		font-size: 0.875rem;
		margin-top: 0.5em;

		.mdi-icon {
			margin-right: 10px;
		}
	}

	.support-phone,
	.support-email {
		align-items: center;
		display: flex;

		a {
			display: block;
		}
	}
}

.metrics-link {
	margin-top: 10px;
}

:deep(.p-datatable) {
	thead {
		tr {
			border-bottom: 1px solid var(--gray-15);

			th.left-bordered {
				border-left: 1px solid var(--gray-15);
			}
		}
	}

	.left-bordered {
		border-left: 1px dashed var(--gray-15);
	}
}

.card h2 {
	font-size: 1.25rem;
}

.chart-heading {
	gap: 20px;

	h2 {
		margin: 0;
	}
}

.product-label {
	font-size: 1.125rem;
}

.velocity-stats {
	align-items: flex-start;
	color: var(--gray-80);

	.value {
		text-align: right;

		.current {
			font-size: 1.25rem;
			font-weight: bold;
		}

		.velocity {
			align-items: center;
			color: var(--gray-35);
			display: inline-flex;
			margin-top: 5px;

			.mdi-icon {
				margin-right: 5px;
			}
		}
	}

	.delta {
		background-color: var(--gray-25);
		border-radius: 3px;
		padding: 4px 5px;
		text-align: right;

		&.up {
			background-color: var(--green);
		}

		&.down {
			background-color: var(--red);
		}

		.percentage {
			color: white;
			font-size: 0.75rem;
			line-height: 1em;
		}
	}
}
</style>
