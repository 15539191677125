<template>
	<div class="build-step reply-with-bids">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Reply with Auction Winners</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">
			Return a list of winners of the lead auction. This step MUST be preceeded by the &quot;Send To Auctions&quot;
			step.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'ReplyWithAuctionResult',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				include_winners: true,
				include_revenue: true,
			},
		},
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
