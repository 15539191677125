<template>
	<div class="row" :style="gapStyle">
		<slot></slot>
	</div>
</template>

<script lang="ts">
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Row',
	props: {
		gap: {
			type: String,
			default: '0',
		},
	},
	computed: {
		gapStyle() {
			return {
				gap: this.gap,
			};
		},
	},
};
</script>
