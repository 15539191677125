import { $GQL } from '@GQL/index';
import { type QueryFilters } from '@nextgenleads/db';

export async function getShipperTemplatesAsOptions(filters: QueryFilters) {
	const query = `
        query GetShipperTemplatesAsOptions($filters: [[Any]]) {
            shipperTemplates(filters: $filters) {
                id
                name
                type
                settings
                data_map
                label
				cancellations {
					enabled
                    pattern
				}
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.shipperTemplates.map((row) => {
		return {
			value: row.id,
			...row,
		};
	});
}
