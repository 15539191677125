import { $GQL } from '@GQL/index';

export async function getLiveTransferLead(lead_id: String, vertical_id: String) {
	const query = `
        query GetLiveTransferLead($lead_id: ID!, $vertical_id: String) {
        	liveTransferLead(lead_id: $lead_id, vertical_id: $vertical_id) {
				data
				first_name
				last_name
				id
				phone
				email
				state
				city
				zip
				vertical_id
				created_at
				spoken_language
			}
        }
    `;

	const result = await $GQL.request(query, { lead_id, vertical_id });
	return result.liveTransferLead;
}
