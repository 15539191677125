import CampaignList from '@/views/Campaigns/List/Page.vue';
import EditCampaign from '@/views/Campaigns/Record/Page.vue';
import CampaignReports from '@/views/Campaigns/Reports/Campaign/Page.vue';
import AccountCampaignReport from '@/views/Campaigns/Reports/Campaign/Account/Page.vue';
import CampaignPurchaseReport from '@/views/Campaigns/Reports/Purchase/Page.vue';
import CampaignTemplateList from '@/views/Campaigns/List/TemplateList.vue';

export default [
	{
		path: 'campaigns/new',
		name: 'CreateCampaign',
		component: EditCampaign,
		meta: {
			new: true,
			title: 'Create Campaign',
			permissions: 'campaigns',
		},
	},
	{
		path: 'campaigns/:campaign_id',
		name: 'EditCampaign',
		component: EditCampaign,
		meta: {
			title: 'Edit Campaign',
			permissions: 'campaigns',
			transition: 'zoom',
		},
	},
	{
		path: 'campaigns/:campaign_id/reports',
		name: 'CampaignReports',
		component: CampaignReports,
		meta: {
			title: 'Campaign Reports',
			permissions: 'admin.campaign_reports',
			transition: 'zoom',
		},
	},
	{
		path: 'campaigns/:campaign_id/stats',
		name: 'AccountCampaignReport',
		component: AccountCampaignReport,
		meta: {
			title: 'Campaign Stats',
			permissions: 'account_reports',
			transition: 'zoom',
		},
	},
	{
		path: 'campaigns/:campaign_id/purchase-report',
		name: 'CampaignPurchaseReport',
		component: CampaignPurchaseReport,
		meta: {
			title: 'Campaign Purchase Report',
			permissions: 'purchases',
			transition: 'zoom',
		},
	},
	{
		path: 'campaigns',
		name: 'CampaignList',
		component: CampaignList,
		meta: {
			title: 'Campaign List',
			permissions: 'campaigns',
		},
	},
	{
		path: 'campaign-templates/new',
		name: 'CreateCampaignTemplate',
		component: EditCampaign,
		meta: {
			new: true,
			template_mode: true,
			title: 'Create Campaign Template',
			permissions: 'campaign_templates',
		},
	},
	{
		path: 'campaign-templates/:campaign_id',
		name: 'EditCampaignTemplate',
		component: EditCampaign,
		meta: {
			template_mode: true,
			title: 'Edit Campaign Template',
			permissions: 'campaign_templates',
		},
	},
	{
		path: 'campaign-templates',
		name: 'CampaignTemplateList',
		component: CampaignTemplateList,
		meta: {
			title: 'Campaign Templates',
			permissions: 'campaign_templates',
		},
	},
];
