<template>
	<div class="campaign-toolbar">
		<template v-for="section in availableSections" :key="section.type">
			<template v-if="sessionStore.isAdminUser || !section.admin">
				<a
					v-tooltip.right="section.label"
					class="icon"
					:class="{ active: section.type === open_section || activeSections.includes(section.type) }"
					@click.prevent="openSection(section.type)"
				>
					<icon :type="section.icon" size="24px" />
				</a>
			</template>
		</template>
	</div>
</template>

<script lang="ts">
import { useCampaignStore } from '@/stores/campaign';
import { useSessionStore } from '@/stores/session';
import { mapStores } from 'pinia';

export default {
	name: 'CampaignToolbar',
	props: {
		activeSections: {
			type: Array,
		},
	},
	emits: ['openSection'],
	data() {
		return {
			open_section: '',
			sections: [
				{
					label: 'Bid and Budgets',
					type: 'bids',
					icon: 'hand-coin-outline',
				},
				{
					label: 'Location Targeting',
					type: 'location',
					icon: 'map-marker-circle',
				},
				{
					label: `Lead Attribute Filtering`,
					type: 'targeting',
					icon: 'bullseye-arrow',
				},
				{
					label: 'Schedule',
					type: 'schedule',
					icon: 'calendar-clock',
				},
				{
					label: 'Purchase Throttling',
					type: 'throttling',
					icon: 'speedometer-slow',
				},
				{
					label: 'Premium Listings',
					type: 'premium_listings',
					icon: 'medal-outline',
				},
				{
					label: 'Lead Delivery',
					type: 'delivery',
					icon: 'cube-send',
				},
				{
					label: 'Custom Filtering/Bidding',
					type: 'custom_filtering',
					icon: 'code-block-tags',
					admin: true,
				},
				{
					label: 'Ping/Post Settings',
					type: 'pingpost',
					icon: 'lan-connect',
					admin: true,
				},
				{
					label: 'Campaign Flags',
					type: 'flags',
					icon: 'order-bool-ascending-variant',
					admin: true,
				},
				{
					label: 'Campaign Version History',
					type: 'versions',
					icon: 'history',
					admin: true,
				},
				{
					label: 'Expand All',
					type: 'expand',
					icon: 'arrow-expand-vertical',
				},
				{
					label: 'Collapse All',
					type: 'collapse',
					icon: 'format-vertical-align-center',
				},
			],
		};
	},
	computed: {
		availableSections() {
			let sections = this.sections;
			if (!this.sessionStore.isAdminUser) {
				sections = sections.filter((section) => {
					return !['custom_filtering', 'pingpost', 'flags', 'versions'].includes(section.type);
				});
			}
			if (
				this.campaignStore.campaign.product_targeting !== 'data' ||
				this.campaignStore.campaign.bid_type === 'shared'
			) {
				sections = sections.filter((section) => {
					return !['premium_listings'].includes(section.type);
				});
			}
			return sections;
		},
		...mapStores(useCampaignStore, useSessionStore),
	},
	methods: {
		openSection(type) {
			if (type === 'expand') {
				const all_sections = Array.from(Array(this.availableSections.length).keys());
				this.$emit('openSection', all_sections);
			} else if (type === 'collapse') {
				this.$emit('openSection', []);
			} else {
				const section_index = this.availableSections.findIndex((section) => {
					return section.type === type;
				});
				this.$emit('openSection', section_index);
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

.campaign-toolbar {
	background: var(--white);
	border-radius: 4px;
	border: 1px solid #dee2e6;
	display: flex;
	flex-direction: column;
	padding: 10px;
	position: sticky;
	text-align: center;
	top: 0;
	width: 60px;

	a.icon {
		align-items: center;
		border-radius: 4px;
		color: #aaa;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		height: 40px;
		justify-content: center;
		margin-bottom: 5px;
		text-decoration: none;
		transition: 0.3s;
		width: 40px;

		&:hover {
			color: var(--color-b);
		}

		&.active {
			background: var(--color-b-lightest);
			color: var(--color-b);

			img {
				height: 24px;
				width: 24px;
			}
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
</style>
