import { createRouter, createWebHistory } from 'vue-router';
import { isEqual } from 'lodash-es';
import { useAppStore } from '@/stores/app';
import { useSessionStore } from '@/stores/session';
import Public from '@/views/Public.vue';
import Private from '@/views/Private.vue';
import admin_root_routes from './admin-root';
import marketplace_root_routes from './marketplace-root';
import login_routes from './login';
import legal_routes from './legal';
import domainMap from '@/lib/Data/subdomains.json';
import log from '@/lib/Log';
import { $GQL } from '@GQL';

const is_admin_app = import.meta.env.VITE_UI === 'admin';
const mpid =
	import.meta.env.VITE_MPID ||
	domainMap[window.location.hostname.split('.')[0].toLowerCase()] ||
	window.location.hostname.split('.')[0].toUpperCase();

let nested_routes;
if (is_admin_app) {
	nested_routes = admin_root_routes;
} else {
	nested_routes = marketplace_root_routes;
}

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'Private',
			component: Private,
			meta: {
				admin: is_admin_app,
				mpid,
			},
			children: [...nested_routes],
			async beforeEnter(to) {
				// This makes the redirect from V3 work
				if (to.query.temp_token) {
					$GQL.setTempToken(to.query.temp_token);
					delete to.query.temp_token;
					router.push(to);
				}

				// Initialize the user
				const sessionStore = useSessionStore();
				if (!sessionStore.isInitialized) {
					try {
						await sessionStore.initialize();
					} catch (err) {
						const appStore = useAppStore();
						appStore.setFlashMessage({
							severity: 'error',
							summary: 'You have been logged out',
							detail: 'Your auth token has expired',
							life: 3000,
						});
						if (err.response && err.response.status === 401) {
							return { path: `/login`, query: { path: to.path } };
						}
						log.error('Session.initialize', {
							error: err,
							data: {
								token: sessionStore.token,
								session: sessionStore.user,
								role: sessionStore.role,
								account: sessionStore.account,
							},
						});
					}
				}

				return true;
			},
		},
		{
			path: '/',
			name: 'Public',
			component: Public,
			meta: {
				admin: is_admin_app,
				mpid,
			},
			children: [...login_routes, ...legal_routes],
		},
	],
});

router.beforeResolve(async (to, from, next) => {
	const sessionStore = useSessionStore();

	// Check for private authenticated route
	if (!to.meta.public) {
		// Check for permissions
		if (to.meta.permissions && !sessionStore.hasPermission('UI.' + to.meta.permissions)) {
			return false;
		}
	}

	// Set the title
	if (to.meta.title) {
		document.title = to.meta.title + ' | NextGen Leads';
	}

	// Handle redirects
	if (to.name === 'Home') {
		const isParentAccountAdmin: boolean =
			sessionStore.isParentAccount ||
			sessionStore.isParentAccountAdmin ||
			sessionStore.user.role_id[mpid] === 'parent_account_admin';

		if (to.params.parent_account_id || isParentAccountAdmin) {
			return next('/reports/parent-account-overview');
		}

		if (sessionStore.isMarketplaceUser) {
			return next('/reports/marketplace-overview');
		}

		if (sessionStore.isAdminUser) {
			const role = sessionStore.user.role_id['$NG'];
			if (['live_transfer_agent', 'live_transfer_agent_manager'].indexOf(role) > -1) {
				return next('/lt-workstation');
			} else {
				return next('/reports/velocity/data');
			}
		}

		return next('/campaigns');
	}

	return next();
});

router.afterEach((to, from) => {
	if (to.name === from.name && !isEqual(to.params, from.params)) {
		router.go(0);
	}
});

router.onError((err) => {
	return { path: '/login' };
});

export default router;
