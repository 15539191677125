<template>
	<div class="p-inputgroup p-input-currency">
		<div class="p-inputgroup-addon">$</div>
		<p-input-number
			v-model="localValue"
			mode="decimal"
			:max-fraction-digits="2"
			:min-fraction-digits="2"
			v-bind="$attrs"
		/>
	</div>
</template>

<script>
import pInputNumber from 'primevue/inputnumber';

export default {
	name: 'InputCurrency',
	emits: ['update:modelValue'],
	components: {
		pInputNumber,
	},
	props: {
		modelValue: {
			type: Number,
			default: 0,
		},
	},
	computed: {
		localValue: {
			get() {
				return this.modelValue;
			},
			set(new_value) {
				this.$emit('update:modelValue', new_value);
			},
		},
	},
};
</script>
