<template>
	<p-dialog v-model:visible="show_modal" modal style="max-width: 400px">
		<template #header>
			<div class="flex align-items-center gap-10">
				<icon type="swap-horizontal" size="24px" />
				<strong>Transfer Phone Number</strong>
			</div>
		</template>
		<p>
			Select a sub-account below to transfer the phone number <strong>{{ phoneFormat(phone.phone) }}</strong> to...
		</p>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Current Sub-Account:</label>
				<div class="controls">
					<div class="field">
						<p-input-text v-model="currentAccountName" disabled />
					</div>
				</div>
			</div>
		</div>
		<div class="control-group">
			<div class="inner">
				<label class="control-label">Transfer to Sub-Account:</label>
				<div class="controls">
					<div class="field">
						<p-dropdown
							v-model="to_sub_account"
							:options="sub_account_options"
							option-label="label"
							option-value="value"
							placeholder="Select a Sub-Account"
						/>
					</div>
				</div>
			</div>
		</div>
		<template #footer>
			<p-button @loading="transfer_loader" label="Transfer Phone Number" @click="transferPhone()" />
		</template>
	</p-dialog>
</template>

<script>
import pDialog from 'primevue/dialog';
import { getSubAccountsAsOptions, transferPhoneNumber } from '@GQL';
import { phoneFormat } from '@/lib/Filters';

export default {
	name: 'SelectSubAccountModal',
	components: {
		pDialog,
	},
	data() {
		return {
			phone: null,
			provider_phone_id: null,
			provider_account_id: null,
			show_modal: false,
			sub_account_options: [],
			to_sub_account: null,
			transfer_loader: false,
		};
	},
	computed: {
		currentAccountName() {
			const current_account = this.sub_account_options.find((option) => {
				return option.value === this.phone.provider_account.provider_account_id;
			});

			return current_account.label || 'Unknown';
		},
	},
	methods: {
		phoneFormat,
		async open(phone) {
			this.sub_account_options = await getSubAccountsAsOptions([[`provider_id = 'twilio'`]]);
			if (phone) {
				this.phone = phone;
				this.show_modal = true;
			}
		},
		async transferPhone() {
			this.transfer_loader = true;
			try {
				const result = await transferPhoneNumber(
					this.phone.provider_phone_id,
					this.phone.provider_account.provider_account_id,
					this.to_sub_account
				);
				const new_provider_account = this.sub_account_options.find((sub_account) => {
					return sub_account.value === this.to_sub_account;
				});
				this.$emit('updateRow', {
					id: this.phone.id,
					provider_account: {
						id: new_provider_account.id,
						name: new_provider_account.label,
						provider_account_id: new_provider_account.value,
					},
				});
				this.$toast.add({
					severity: 'success',
					summary: 'Phone Number Transferred',
					detail: 'The phone number was transferred successfully',
					life: 3000,
				});
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to complete phone purchase',
					detail: err.messaage,
					life: 5000,
				});
			} finally {
				this.transfer_loader = false;
				this.show_modal = false;
			}
		},
	},
};
</script>

<style lang="less" scoped></style>
