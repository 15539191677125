<template>
	<div id="edit-signup-page" class="view-content">
		<div class="page-heading">
			<h1>{{ heading }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<SignUpPageForm />
	</div>
</template>

<script lang="ts">
import SignUpPageForm from './Form.vue';
export default {
	name: 'SignUpPageEdit',
	components: {
		SignUpPageForm,
	},
	data() {
		const is_create = this.$route.name === 'CreateRole' ? true : false;
		return {
			is_create,
			heading: is_create ? 'Create Sign-Up Page' : 'Edit Sign-Up Page',
			breadcrumb_items: [
				{ label: 'Sign-Up Pages', route: '/signup-pages' },
				{ label: is_create ? 'Create Sign-Up Page' : 'Edit Sign-Up Page' },
			],
			loading: false,
			tableKey: 0,
		};
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/themes/default';
@import (reference) '@/styles/responsive';
</style>
