<template>
	<div id="promotions-form" class="view-content">
		<div class="page-heading">
			<h1>{{ header }}</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<p-card class="promotion-form-content">
			<template #content>
				<PromotionForm />
			</template>
		</p-card>
	</div>
</template>

<script lang="ts">
import PromotionForm from '@/views/Promotions/Record/Form.vue';

export default {
	name: 'PromotionRecordPage',
	components: {
		PromotionForm,
	},
	data() {
		return {
			home: { label: 'Home', route: '/' },
			breadcrumb_items: [],
		};
	},
	created() {
		let prev_route_items = this.$route.path.split('/');
		prev_route_items.pop();
		let prev_route = prev_route_items.join('/');

		if (this.$route.params.mpid) {
			this.$route.meta.new
				? this.breadcrumb_items.push(
						{ label: 'Marketplace Promotions', route: prev_route },
						{ label: 'New Marketplace Promotion' }
					)
				: this.breadcrumb_items.push(
						{ label: 'Marketplace Promotions', route: prev_route },
						{ label: 'Edit Marketplace Promotion' }
					);
		} else {
			this.$route.meta.new
				? this.breadcrumb_items.push(
						{ label: 'Global Promotions', route: prev_route },
						{ label: 'New Global Promotion' }
					)
				: this.breadcrumb_items.push(
						{ label: 'Global Promotions', route: prev_route },
						{ label: 'Edit Global Promotion' }
					);
		}
	},
	computed: {
		header() {
			if (this.$route.params.mpid) {
				return this.$route.meta.new ? 'New Marketplace Promotion' : 'Edit Marketplace Promotion';
			} else {
				return this.$route.meta.new ? 'New Global Promotion' : 'Edit Global Promotion';
			}
		},
	},
};
</script>

<style lang="less" scoped>
.promotion-form-content {
	max-width: 760px;
}
</style>
