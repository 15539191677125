<template>
	<nav :class="{ collapsed: menuCollapsed }">
		<template v-for="nav_item in navigation" :key="nav_item.label">
			<div
				v-if="$root.sessionStore.hasPermission([`SidebarMenu.${nav_item.permissions}`])"
				:class="['nav-item-wrapper', { active: $route.matched.some((r) => r.path === nav_item.route) }]"
			>
				<div
					class="nav-item"
					:class="{ 'is-open': current_menu === nav_item.label }"
					@click="handleMenuClick(nav_item.label)"
				>
					<div class="nav-icon">
						<div class="icon-wrapper">
							<alt-icon :type="nav_item.icon" />
						</div>
					</div>
					<div class="nav-label">{{ nav_item.label }}</div>
					<div v-if="nav_item.children && nav_item.children.length > 0" class="nav-expand">
						<div class="icon-wrapper">
							<icon
								:class="{
									rotate:
										current_menu === nav_item.label ||
										$route.matched.some((r) => r.meta.parent_menu === nav_item.label),
								}"
								type="chevron-right"
								size="24px"
							/>
						</div>
					</div>
				</div>
				<div
					v-if="
						current_menu === nav_item.label ||
						$route.matched.some((r) => r.meta.parent_menu === nav_item.label) ||
						menuCollapsed
					"
					class="sub-nav"
				>
					<div class="sub-nav-title">{{ nav_item.label }}</div>
					<div v-if="nav_item.children" class="sub-nav-items">
						<template v-for="sub_nav_item in nav_item.children" :key="sub_nav_item.label">
							<div
								v-if="
									$root.sessionStore.hasPermission([`SidebarMenu.${nav_item.permissions}.${sub_nav_item.permissions}`])
								"
								class="nav-item"
							>
								<router-link :to="prefixRoute(sub_nav_item.route)">
									<div class="nav-label">{{ sub_nav_item.label }}</div>
								</router-link>
							</div>
						</template>
						<template
							v-if="nav_item.label === 'Marketplaces'"
							v-for="marketplace_option in $root.appStore.options.marketplaces"
							:key="marketplace_option.id"
						>
							<div v-if="$root.sessionStore.user.role_id[marketplace_option.value]" class="nav-item">
								<router-link :to="{ path: `/marketplaces/manage/${marketplace_option.value}/accounts` }">
									<div class="nav-label">{{ marketplace_option.label }}</div>
								</router-link>
							</div>
						</template>
					</div>
				</div>
			</div>
		</template>
	</nav>
</template>

<script lang="ts">
export default {
	name: 'SidebarMenu',
	props: {
		menuCollapsed: Boolean,
		mpid: String,
	},
	data() {
		return {
			current_menu: null,
		};
	},
	computed: {
		navigation() {
			return this.$root.sessionStore.getNavigation(this.mpid);
		},
	},
	mounted() {},
	methods: {
		handleMenuClick(name) {
			const menu = this.navigation.find((row) => {
				return row.label === name;
			});

			// Menu item has a link, otherwise open sub-nav
			if (menu.route) {
				this.$router.replace({ path: this.prefixRoute(menu.route) });
			} else {
				this.openMenu(name);
			}
		},
		isActive(nav) {
			const output = this.$route.name === this.get(nav, 'route.name', '');
			const matching_id = !this.get(nav, 'route.params.id', false) || this.$route.params.id === nav.route.params.id;
			return output && matching_id;
		},
		isActiveParent(nav) {
			const output = this.$route.meta.parent_menu === nav.label;
			return output;
		},
		openMenu(menu_name) {
			if (this.current_menu === menu_name) {
				this.current_menu = false;
			} else {
				this.current_menu = menu_name;
			}
		},
		prefixRoute(route) {
			if (this.mpid) {
				return `/marketplaces/manage/${this.mpid}` + route;
			}
			return route;
		},
	},
};
</script>

<style scoped lang="less">
@import (reference) '@/styles/responsive';

.router-link-active .nav-label {
	background-color: rgb(255 255 255 / 10%);
	border-radius: 3px;
	padding-left: 15px;
}

.marketplace,
#marketplace-wrapper {
	.router-link-active .nav-label {
		background-color: rgb(0 0 0 / 5%);
	}
}

nav {
	color: var(--gray-05);
	flex: 1 0;
	overflow-y: auto;
	padding: 20px;
	z-index: 1000;

	.nav-item-wrapper {
		border-bottom: 1px solid var(--gray-70);
		position: relative;
		width: 100%;

		&:last-child {
			border-bottom: none;
		}

		&.active {
			font-weight: bold;
		}
	}

	.nav-icon {
		margin-top: -2px;
	}

	.nav-item {
		align-items: center;
		cursor: pointer;
		display: flex;
		flex-direction: row;
		height: 50px;
		justify-content: flex-start;
		width: 100%;

		&:hover {
			color: var(--color-b);

			.nav-icon {
				color: var(--color-b);
			}
		}
	}

	.nav-label {
		flex: 1 0;
		font-size: var(--font-size-sm);
		padding-left: 5px;
	}

	.nav-expand {
		margin-top: -2px;
	}

	.sub-nav {
		padding-bottom: 10px;

		.sub-nav-title {
			color: var(--gray-50);
			display: none;
			font-size: 0.75rem;
			font-weight: bold;
			height: --gray-500px;
			line-height: 50px;
			padding: 0 20px 0 25px;
			text-transform: uppercase;
		}

		> .sub-nav-items > .nav-item {
			height: 40px;
			padding-left: 40px;

			a {
				color: var(--gray-25);
				height: 40px;
				line-height: 40px;
				width: 100%;

				&:hover {
					color: var(--color-b);
					text-decoration: none !important;
				}
			}
		}
	}

	&.collapsed {
		margin-top: 45px;
		overflow: visible;
		padding: 0;
		position: relative;

		.nav-label,
		.nav-expand,
		.sub-nav {
			display: none;
		}

		.sub-nav {
			background-color: var(--gray-80);
			border-radius: 5px;
			left: 50px;
			min-width: 250px;
			padding-bottom: 0;
			position: absolute;
			top: -3px;

			.sub-nav-title {
				display: block;
			}

			> .sub-nav-items {
				border-top: 1px solid var(--gray-70);
				padding: 10px 0;

				> .nav-item {
					padding-left: 0;

					.nav-label {
						display: block;
						width: 100%;
					}

					&:last-of-type {
						margin-bottom: 10px;
					}
				}
			}
		}

		.nav-item-wrapper {
			border: 0;
			height: 40px;
			line-height: 40px;

			.nav-item {
				overflow: visible;
				position: relative;

				a {
					color: var(--gray-15);
					padding: 0 20px;
					width: 100%;

					&:hover {
						color: var(--color-b);
						text-decoration: none;
					}
				}
			}

			&:hover .sub-nav {
				display: block;
			}
		}

		> .nav-item-wrapper {
			display: flex;
			height: 50px;
			justify-content: center;
			width: 60px;

			.nav-item {
				width: auto;
			}
		}
	}
}

#marketplace-wrapper nav,
body.marketplace nav {
	color: var(--gray-50);

	.nav-item-wrapper {
		border-bottom: 1px solid var(--gray-05);
	}

	.nav-item {
		height: 49px;
	}

	.sub-nav > .sub-nav-items > .nav-item {
		height: 40px;
	}

	.nav-icon .icon-wrapper {
		color: var(--color-a);
	}

	.nav-expand .icon-wrapper {
		color: var(--gray-35);
	}

	.sub-nav > .sub-nav-items > .nav-item a {
		color: var(--gray-45);
	}
}
</style>
