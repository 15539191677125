import { $GQL } from '@GQL/index';
import type { ProcessDNCCsvParams } from '@nextgenleads/job-driver';

export async function processDNCCsv(params: ProcessDNCCsvParams): Promise<{ id: string }> {
	const mutation = `
        mutation ProcessDNCCsv($params: JSONObject!) {
            processDNCCsv(params: $params) {
                id
                name
            }
        }
    `;
	const result = await $GQL.request(mutation, { params });
	return result.processDNCCsv;
}
