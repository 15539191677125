<template>
	<div>
		<h2>Shipper Email Templates</h2>
		<p-fieldset legend="Shipper Email Template Map">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field">
							<code-editor v-model.parse="shipper_email_templates" />
						</div>
					</div>
				</div>
			</div>
			<div class="flex justify-content-between">
				<p-button label="Reset" text @click="getSetting()" />
				<p-button label="Save" icon="pi pi-check" @click="save()" />
			</div>
		</p-fieldset>
	</div>
</template>
<script lang="ts">
import { getSettings, updateSetting } from '@GQL';
import { cloneDeep } from 'lodash-es';
export default {
	name: 'ShipperEmailTemplates',
	emits: ['refresh'],
	data() {
		return {
			shipper_email_templates: [],
			dirty: false,
		};
	},
	async mounted() {
		await this.getSetting();
	},
	methods: {
		async save() {
			try {
				const settings_result = await updateSetting(
					'shipper_email_templates',
					{
						data: this.shipper_email_templates,
					},
					'$NG',
					true
				);
				this.dirty = false;
				if (settings_result.id === 'shipper_email_templates') {
					this.$toast.add({
						severity: 'success',
						summary: 'Successfully saved Shipper Email Template Settings',
						life: 5000,
					});
				} else {
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to save settings',
						life: 7000,
					});
				}
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to save settings',
					life: 7000,
				});
			} finally {
				this.loading = false;
			}
		},
		async getSetting() {
			this.loading = true;
			try {
				// only ever global
				const settings_result = await getSettings(['shipper_email_templates'], '$NG', true);
				this.shipper_email_templates = cloneDeep(settings_result.settings.shipper_email_templates);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Unable to get Shipper Email Template settings',
					life: 7000,
				});
			}
		},
	},
};
</script>
