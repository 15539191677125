import { $GQL } from '@GQL/index';
import log from '@/lib/Log';

export async function publishSocketMessage(params: { room?: string; event: string; data?: any }): Promise<Boolean> {
	const mutation = `
        mutation PublishSocketMessage($params: JSONObject!) {
            publishSocketMessage(params: $params)
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.publish;
}
