import { $GQL } from '@GQL/index';
import type { Integration } from '@nextgenleads/pingpost-driver';

export async function insertIntegration(data: Omit<Integration, 'id' | 'created_at'>): Promise<{ id: string }> {
	const mutation = `
        mutation InsertIntegration($data: JSONObject!) {
            insertIntegration(data : $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.insertIntegration;
}
