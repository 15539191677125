import { $GQL } from '@GQL/index';

export interface GetV3PurchaseReq {
	date_range: [string, string]; // YYYY-MM-DD format
	company_id: string;
	vertical_id: string[];
	auction_type: string[];
	state: string[];
	status: string[];
	page: number;
}
export async function getV3Purchases(params: GetV3PurchaseReq): Promise<unknown[]> {
	const query = `
        query GetV3Purchases($params: JSONObject!) {
            getV3Purchases(params: $params)
        }
    `;
	const result = await $GQL.request(query, { params });
	return result.getV3Purchases;
}
