// --------------------------------------------------
// FILTER: ageFormat
// Use date-fns to format a dob string to age
// --------------------------------------------------

// Import dependencies
import { formatDuration, intervalToDuration, parseISO } from 'date-fns';

export function dobToAge(dob: string) {
	if (parseISO(dob)) {
		return formatDuration(intervalToDuration({ start: parseISO(dob), end: new Date() }), {
			format: ['years', 'months'],
			delimiter: ', ',
		});
	} else {
		throw Error('Not valid ISO date string');
	}
}
