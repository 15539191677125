<template>
	<div class="tool">
		<p-button
			v-tooltip.top="'Email Receipt'"
			aria-label="Email Receipt"
			@click="email_receipt_modal_open = !email_receipt_modal_open"
		>
			<template #icon>
				<icon type="receipt-text-send-outline" size="20px" />
			</template>
		</p-button>
	</div>
	<p-dialog
		v-model:visible="email_receipt_modal_open"
		class="email-receipt-modal"
		:modal="true"
		:dismissable-mask="true"
		:pt="{
			header: { class: 'print-hidden' },
			closeButtonIcon: { class: 'print-hidden' },
			footer: { class: 'print-hidden' },
		}"
	>
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="receipt-text-send-outline" size="24px" style="color: #27a69a" />
				<strong>Email Transaction Receipt</strong>
			</div>
		</template>
		<div class="receipt">
			<table style="border: none; width: 600px">
				<tbody>
					<tr style="border: none">
						<td style="color: #27a69a; padding-bottom: 10px">
							<h1>NextGen Leads Receipt</h1>
						</td>
					</tr>
					<tr style="border: none">
						<td style="border: none; border-bottom: 2px solid #27a69a; padding-bottom: 10px">
							<table>
								<tbody>
									<tr style="border: none">
										<td style="border: none; color: #7f858b; font-size: 16px; width: 300px">
											{{ account_name ? 'BILL TO' : '' }}
										</td>
										<td style="border: none; color: #7f858b; font-size: 16px; padding: 10px; width: 100px">ID #</td>
										<td style="border: none; font-size: 14px; padding: 10px; padding-right: 0; width: 200px">
											{{ transaction.id }}
										</td>
									</tr>
									<tr style="border: none">
										<td style="border: none; font-size: 14px">
											<strong>{{ account_name ? account_name : '' }}</strong>
										</td>
										<td style="border: none; color: #7f858b; font-size: 16px; padding: 10px; padding-right: 0">DATE</td>
										<td style="border: none; font-size: 14px; padding: 10px; padding-right: 0; text-align: right">
											{{ formatDate(transaction.created_at, 'YYYY-MM-DD &bull; h:mm:ssa') }}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr style="border: none">
						<td style="border: none; color: #7f858b; padding-bottom: 5px; padding-top: 20px">PAYMENT METHOD</td>
					</tr>
					<tr style="border: none">
						<td style="border: none; font-size: 14px; padding-bottom: 30px; padding-top: 10px">
							<strong>{{
								`${transaction.payment_method.name_on_card} (${transaction.payment_method.last_four})`
							}}</strong>
						</td>
					</tr>
					<tr style="border: none">
						<td style="border: none">
							<table style="border: none">
								<tbody>
									<tr style="background-color: #d1eeee; border: none; color: #008080">
										<td style="border: none; padding: 10px; width: 500px">DESCRIPTION<br /></td>
										<td style="border: none; width: 100px">AMOUNT</td>
									</tr>
									<tr style="border: none">
										<td style="border: none; padding: 10px">{{ capitalize(transaction.type) }}</td>
										<td style="border: none; padding: 10px">{{ currency(transaction.amount) }}</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
					<tr style="border: none">
						<td style="border: none">
							<table style="border-top: 1px dotted #d1eeee">
								<tbody>
									<tr>
										<td>&nbsp;</td>
									</tr>
									<tr>
										<td style="border: none; padding: 10px"></td>
										<td style="border: none; color: #7f858b; padding: 10px">START BALANCE</td>
										<td style="border: none; padding: 10px">{{ currency(transaction.start_balance) }}</td>
									</tr>
									<tr style="border: none">
										<td style="border: none; padding: 10px"></td>
										<td style="border: none; color: #7f858b; padding: 10px">AMOUNT</td>
										<td style="border: none; padding: 10px">{{ currency(transaction.amount) }}</td>
									</tr>
									<tr style="border: none">
										<td style="border: none; padding: 10px; width: 300px"></td>
										<td style="border: none; color: #7f858b; padding: 10px; width: 200px">CURRENT BALANCE</td>
										<td style="border: none; padding: 10px; width: 100px">
											{{ currency(transaction.end_balance) }}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<gutter size="20px" />
			<p-fieldset legend="Email Recipients">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Email addresses: </label>
						<div class="controls">
							<div class="field">
								<p-chips v-model="recipients" separator="," placeholder="Separate by comma or by hitting enter" />
								<div v-if="v$.recipients.$errors.length > 0" class="validation-error">
									{{ v$.recipients.$errors[0].$message }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</p-fieldset>
		</div>
		<template #footer>
			<p-button icon="pi pi-send" label="Email Receipt" @click.prevent="sendEmailReceipt" />
		</template>
	</p-dialog>
</template>

<script lang="ts">
import { formatDate, currency, capitalize } from '@/lib/Filters';
import pDialog from 'primevue/dialog';
import { getAccountById, sendEmail } from '@GQL';
import { useVuelidate } from '@vuelidate/core';
import { helpers, email } from '@vuelidate/validators';

export default {
	name: 'EmailReceipt',
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	components: {
		pDialog,
	},
	props: {
		transaction: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			account_name: null,
			email_receipt_modal_open: false,
			template_data: {},
			recipients: [],
		};
	},
	methods: {
		formatDate,
		currency,
		capitalize,
		generateEmailTemplateData() {
			return {
				account_id: this.$route.params.account_id,
				transaction_id: this.transaction.id,
				date: new Date(this.transaction.created_at),
				account_name: this.account_name,
				payment_method:
					`${this.transaction.payment_method.name_on_card} (${this.transaction.payment_method.last_four})` || 'Card',
				name_on_card: this.transaction.payment_method.name_on_card || 'Unknown Name',
				amount: this.transaction.amount,
				new_total: this.transaction.end_balance,
				start_total: this.transaction.start_balance,
				description: capitalize(this.transaction.type),
			};
		},
		async sendEmailReceipt() {
			// EM template IDs
			const em_templates = {
				refund_receipt: '01HH07N6V852R46WSQ591XMBSW',
				deposit_receipt: '01HH07FWV2900AHYNC6AZR72RG',
			};

			// Format recipients
			let formatted_recipients = this.recipients.map((address) => {
				return {
					name: address,
					email: address,
				};
			});

			// Get EM Templates
			const template_to_send =
				this.transaction.type === 'deposit' ? em_templates.deposit_receipt : em_templates.refund_receipt;

			// Format params
			let email_params = {
				recipients: formatted_recipients,
				template: {
					id: template_to_send,
					data: this.template_data,
				},
				account_id: this.$route.params.account_id || this.$root.sessionStore.account?.id,
				sender_user_id: this.$root.sessionStore.user.id,
			};

			const is_valid = await this.v$.$validate();
			if (is_valid) {
				// Send Email Receipt
				try {
					await sendEmail(email_params);
					this.$toast.add({
						severity: 'success',
						summary: 'Sent Email Receipt',
						life: 3000,
					});
				} catch (err) {
					this.$toast.add({
						severity: 'error',
						summary: 'Error Sending Email',
						detail: err.response.errors[0]?.message || err.message,
						life: 3000,
					});
				}
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Invalid Field',
					detail: 'Please enter a valid email addresses',
					life: 3000,
				});
			}
		},
	},
	async mounted() {
		if ('account_id' in this.$route.params) {
			const account = await getAccountById(this.$route.params.account_id);
			this.account_name = account.name;
		}
		this.template_data = this.generateEmailTemplateData();
	},
	validations: {
		recipients: {
			valid_email: helpers.withMessage('Please enter only valid email addresses', (value) => {
				return value.every((v) => {
					return email.$validator(v);
				});
			}),
		},
	},
};
</script>

<style lang="less">
@media print {
	.print-hidden {
		display: none !important;
		visibility: hidden !important;
	}
}
</style>
