import { $GQL } from '@GQL/index';

export async function previewAdContent(template_id: string | null, data: any, vertical_id: string, mpid: string) {
	const query = `
        query PreviewAdContent($template_id: ID, $data: JSONObject!, $vertical_id: String, $mpid: String) {
            previewAdContent(template_id: $template_id, data: $data, vertical_id: $vertical_id, mpid: $mpid)
        }
    `;

	const result = await $GQL.request(query, { template_id, data, vertical_id, mpid });
	return result.previewAdContent;
}
