<template>
	<div v-if="previousValue" class="trend" :class="{ 'trending-up': trendingUp, 'trending-down': trendingDown }">
		<icon v-if="inverseMetric ? trendingDown : trendingUp" type="trending-up" size="18px" />
		<icon v-if="inverseMetric ? trendingUp : trendingDown" type="trending-down" size="18px" />
	</div>
</template>

<script lang="ts">
import { currency } from '@/lib/Filters';
export default {
	props: {
		value: {
			type: Number,
			required: true,
		},
		previousValue: {
			type: Number,
		},
		inverseMetric: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		trendingUp() {
			if (this.inverseMetric) {
				return this.value < this.previousValue;
			} else {
				return this.value > this.previousValue;
			}
		},
		trendingDown() {
			if (this.inverseMetric) {
				return this.value > this.previousValue;
			} else {
				return this.value < this.previousValue;
			}
		},
	},
	methods: {
		currency,
	},
};
</script>

<style scoped lang="less">
.panel {
	height: 100%;
	padding: 1.5rem;
	position: relative;
}

.top {
	align-items: center;
	display: flex;
	justify-content: space-between;
}

.label {
	color: var(--color-b);
	font-size: var(--font-size-sm);
	line-height: 1.3;
	max-width: 20ch;
	text-transform: uppercase;
}

.trend {
	color: var(--gray-35);
	font-size: var(--font-size-sm);
	margin-left: 5px;
}

.value {
	font-size: 1.75rem;
	font-weight: bold;
	margin-top: 20px;
}

.compare-value {
	color: var(--gray-35);
	margin-top: 10px;

	> * {
		vertical-align: middle;
	}

	.mdi-icon {
		margin-right: 5px;
	}
}

.trending-up {
	color: var(--green);
}

.trending-down {
	color: var(--red);
}
</style>
