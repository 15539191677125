<template>
	<p-dialog v-model:visible="show_modal" class="" :modal="true" style="max-width: 720px">
		<template #header>
			<div class="flex gap-2 align-content-center align-items-center">
				<icon type="credit-card-plus" size="24px" style="color: var(--color-b)" />
				<strong>Report Account {{ display_name }}</strong>
			</div>
		</template>
		<div>
			<p>Please use this form to report issues related to this buyer to an account manager.</p>
			<div class="control-group">
				<div class="inner">
					<label class="control-label req">Issue Details:</label>
					<div class="controls">
						<div class="field">
							<p-textarea v-model="notes" />
						</div>
					</div>
				</div>
				<p class="validation-error" v-if="show_error">A detailed message is required to report this account</p>
			</div>
		</div>

		<template #footer>
			<div class="flex justify-content-end">
				<p-button label="Cancel" text @click="show_modal = false" />
				<p-button label="Submit" @click="confirm" />
			</div>
		</template>
	</p-dialog>
</template>

<script>
export default {
	emits: ['report'],
	props: {
		display_name: {
			type: String,
			default() {
				return '';
			},
		},
	},
	data() {
		return {
			notes: '',
			show_modal: false,
			show_error: false,
		};
	},
	methods: {
		open() {
			this.show_error = false;
			this.notes = '';
			this.show_modal = true;
		},
		confirm() {
			if (this.notes.length < 10) {
				this.show_error = true;
				this.$toast.add({
					severity: 'error',
					summary: 'A detailed explanation is needed for the report',
					life: 6000,
				});
			} else {
				// validate...
				this.$emit('report', {
					notes: this.notes,
				});
				this.show_modal = false;
			}
		},
	},
};
</script>
