<template>
	<div class="build-step check-vendor-permissions">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Check Vendor Permissions</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">
			Check if the vendor submitting the lead has permissions to do so for this Marketplace.
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'CheckVendorPermissions',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
