import EmailTemplateRecord from '@/views/EmailTemplates/Record/Page.vue';
import EmailTemplatesList from '@/views/EmailTemplates/List/Page.vue';

export default [
	{
		path: 'email-templates/new',
		name: 'CreateEmailTemplate',
		component: EmailTemplateRecord,
		meta: {
			new: true,
			title: 'New Email Template',
			permissions: 'admin.email_templates',
		},
	},
	{
		path: 'email-templates/:email_template_id',
		name: 'EditEmailTemplate',
		component: EmailTemplateRecord,
		meta: {
			title: 'Edit Email Template',
			permissions: 'admin.email_templates',
		},
	},
	{
		path: 'email-templates',
		name: 'EmailTemplatesList',
		component: EmailTemplatesList,
		meta: {
			title: 'Email Templates',
			permissions: 'admin.email_templates',
		},
	},
];
