import { $GQL } from '@GQL';

// Hardly a need to filter, just use quick filters if anything
export async function getRecentAMPhoneLogActivity(params: {
	date_range: [Date, Date];
	account_id: String;
	refresh?: boolean;
}) {
	const query = `
        query GetRecentAMPhoneLogActivity($params: JSONObject!) {
            recentAMPhoneLogActivity(params: $params) {
                in_text_count
                in_call_count
                out_text_count
                out_call_count
                email_count
                gmail_count
                call_logs 
            }
        }
    `;

	const request = await $GQL.request(query, { params });
	return request.recentAMPhoneLogActivity;
}
