import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getAdForApprovals(filters?: QueryFilters, no_cache?: string[]) {
	const query = `
        query GetAdForApprovals($filters: [[Any]]){
            ads(filters: $filters) {
                id
				vertical_id
				account_id
				account {
					id
					name
					mpid
				}
				name
				template_id
				template {
					id
					name
				}
				type
				data
				approval_status
				rejected_reason
				approved_by
				approver {
					id
					first_name
					last_name
				}
				status
				created_at
				modified_at
            }
        }
    `;

	const result = await $GQL.request(query, { filters, no_cache });
	return result.ads;
}
