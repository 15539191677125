import { $GQL } from '@GQL/index';
import type { RefundParams, RefundRes } from '@nextgenleads/banker-driver';

export async function refund(params: RefundParams): Promise<RefundRes> {
	const mutation = `
        mutation Refund($params: RefundParams!) {
            refund(params: $params) {
                current_balance
            }
        }
    `;
	const result = await $GQL.request(mutation, { params });
	return result.refund;
}
