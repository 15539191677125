<template>
	<div class="voice-route-step">
		<div class="header move-handle">
			<div class="header-left" @click="expanded = !expanded">
				<div class="flex align-items-center gap-10">
					<icon type="dots-vertical" size="24px" />
					<strong>Menu</strong> <span class="sub-value">Redirect a caller via a menu</span>
				</div>
				<div>
					<div v-if="!expanded" class="expand-icon">
						<icon type="arrow-expand" size="20px" />
					</div>
					<div v-if="expanded" class="expand-icon">
						<icon type="arrow-collapse" size="20px" />
					</div>
				</div>
			</div>
			<div class="header-right">
				<div class="delete-icon">
					<icon type="delete" size="20px" @click="deleteStep" />
				</div>
			</div>
		</div>
		<div v-if="expanded" class="content">
			<div class="step-options">
				<div v-if="!routeStep.params.say" class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field" @click="addSay()">
								<div class="add-button">
									<icon type="plus" size="20px" />
									<span>Add an AI voice message (this will remove any audio files)</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="!routeStep.params.play" class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field" @click="addPlay()">
								<div class="add-button">
									<icon type="plus" size="20px" />
									<span>Play an audio file (this will remove any AI voice messages)</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="routeStep.params.say" class="control-group flex align-items-start">
					<div class="inner flex-1">
						<label class="control-label">Say Something:</label>
						<div class="controls">
							<div class="field">
								<p-textarea
									v-model="localRouteStep.params.say.message"
									auto-resize
									rows="3"
									style="min-height: 100px"
								/>
							</div>
						</div>
						<div class="controls gap-10">
							<div class="field">
								<p-dropdown
									v-model="localRouteStep.params.say.options.voice"
									:options="voice_options"
									option-label="label"
									option-value="value"
									placeholder="Select Voice"
								/>
							</div>
						</div>
					</div>
					<div class="remove-button" style="margin-top: 10px" v-tooltip.top="'Remove AI Voice Message'">
						<div class="icon-wrapper error" @click="removeSay()">
							<icon type="minus-circle" size="20px" />
						</div>
					</div>
				</div>
			</div>
			<div class="step-options">
				<div v-if="routeStep.params.play" class="control-group">
					<div class="inner">
						<label class="control-label">Play Audio File:</label>
						<div class="controls gap-10">
							<div class="field">
								<p-input-text v-model="localRouteStep.params.play.url" />
							</div>
							<div class="field fit">
								<custom-upload
									folder="ngl4-call-audio"
									accept="audio/*"
									@success="localRouteStep.params.play.url = $event.data[0].location"
								/>
							</div>
							<div class="field fit" v-tooltip.top="'Remove Audio File'">
								<div class="icon-wrapper remove-button" @click="removePlay()">
									<icon type="minus-circle" size="20px" />
								</div>
							</div>
						</div>
						<div v-if="localRouteStep.params.play.url" class="controls">
							<div class="field">
								<audio controls><source :src="localRouteStep.params.play.url" /></audio>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="step-options">
				<p-fieldset legend="Menu Options">
					<p>Please enter the sub-route and step to redirect to for the given inputs...</p>
					<div class="menu-choices" v-sortable="sortable_options">
						<div v-for="(choice, index) in menu_choices" class="menu-choice" :key="choice.id">
							<div class="control-group">
								<div class="inner">
									<div class="controls gap-10">
										<div class="field fit">
											<icon type="dots-vertical" size="20px" />
										</div>
										<div class="field xxxs">
											<p-input-number v-model="choice.input" disabled class="input-style input-align-center" />
										</div>
										<div class="field">
											<p-input-text v-model="choice.sub_route" placeholder="Sub-Route" />
										</div>
										<div class="field xxs">
											<p-input-number v-model="choice.step" placeholder="Step" class="input-align-center" />
										</div>
										<div class="field fit">
											<div
												class="icon-wrapper remove-button"
												v-tooltip.top="'Remove Menu Option'"
												@click="removeMenuOption(index)"
											>
												<icon type="minus-circle" size="20px" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="add-button" @click="addMenuOption()"><icon type="plus" size="20px" /> Add Menu Option</div>
					</div>
				</p-fieldset>
			</div>
			<div class="step-options">
				<div class="control-group">
					<div class="inner">
						<div class="controls gap-20">
							<div class="field">
								<label class="control-label">Input Field:</label>
								<p-input-text
									v-model="localRouteStep.params.input_field"
									placeholder="Save input to this field in the call data..."
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field fit">
								<p-checkbox input-id="loop" binary v-model="localRouteStep.params.loop" />
							</div>
							<div class="field caption">
								<label class="checkbox" for="loop">Loop Continuously</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="step-options">
				<div v-if="!routeStep.params.options" class="control-group">
					<div class="inner">
						<div class="controls">
							<div class="field" @click="addOptions()">
								<div class="add-button">
									<icon type="plus" size="20px" />
									<span>Add custom gather options for Twilio</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="control-group flex align-items-start">
					<div class="inner flex-1">
						<label class="control-label">Gather Options:</label>
						<div class="controls gap-10 align-items-start">
							<div class="field"><code-editor v-model.parse="localRouteStep.params.options" /></div>
						</div>
					</div>
					<div class="remove-button" style="margin-top: 10px" v-tooltip.top="'Remove Twilio Options'">
						<div class="icon-wrapper error" @click="removeOptions()">
							<icon type="minus-circle" size="20px" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Sortable from 'sortablejs';
import { cloneDeep, has, isEqual, merge, uniqueId } from 'lodash-es';
import customUpload from '@/components/forms/CustomUpload.vue';
import codeEditor from '@/components/forms/CodeEditor.vue';
import voice_options from '@/lib/Data/voice_options.json';

export default {
	name: 'VoiceRouteMenuHandler',
	emits: ['update', 'delete'],
	components: {
		customUpload,
		codeEditor,
	},
	props: {
		routeStep: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			default_config: {
				handler: 'Menu',
				params: {
					say: {
						message: '',
						options: {
							voice: 'Google.en-US-Standard-H',
						},
					},
					input_field: null,
					choices: [],
					loop: true,
				},
			},
			voice_options,
			expanded: false,
			sortable_options: {
				draggable: '.menu-choice',
				handle: '.menu-choice',
			},
			menu_choices: [],
		};
	},
	computed: {
		localRouteStep: {
			get() {
				return this.routeStep;
			},
			set(new_value) {
				this.$emit('update', new_value);
			},
		},
	},
	watch: {
		routeStep: {
			handler(new_value, old_value) {
				if (!isEqual(new_value.params.choices, old_value?.params?.choices)) {
					new_value.params.choices.forEach((new_choice, index) => {
						const old_choice = cloneDeep(this.menu_choices[index]);
						if (!old_choice) {
							this.menu_choices.push({
								id: uniqueId,
								...new_choice,
							});
						} else {
							this.menu_choices[index] = {
								...old_choice,
								...new_choice,
							};
						}
					});

					// Remove extra items
					if (new_value.params.choices.length < old_value?.params?.choices?.length) {
						this.menu_choices = this.menu_choices.slice(0, new_value.params.choices.length);
					}
				}
			},
			immediate: true,
			deep: true,
		},
		menu_choices: {
			handler(new_value) {
				// Remove the ID
				const choices = cloneDeep(new_value).map((choice) => {
					delete choice.id;
					return choice;
				});
				const route_step = cloneDeep(this.routeStep);
				route_step.params.choices = choices;
				this.$emit('update', route_step);
			},
			deep: true,
		},
	},
	methods: {
		uniqueId,
		addSay() {
			if (!has(this.routeStep.params, 'say')) {
				const new_route = cloneDeep(this.routeStep);
				new_route.params.say = {
					message: '',
					options: {
						voice: 'en-US-Standard-H',
					},
				};
				this.$emit('update', new_route);
				this.$nextTick(() => {
					this.removePlay();
				});
			}
		},
		removeSay() {
			if (has(this.routeStep.params, 'say')) {
				const new_route = cloneDeep(this.routeStep);
				delete new_route.params.say;
				this.$emit('update', new_route);
			}
		},
		addPlay() {
			if (!has(this.routeStep.params, 'play')) {
				const new_route = cloneDeep(this.routeStep);
				new_route.params.play = {
					url: '',
					options: {
						digits: '',
						loop: 1,
					},
				};
				this.$emit('update', new_route);
				this.$nextTick(() => {
					this.removeSay();
				});
			}
		},
		removePlay() {
			if (has(this.routeStep.params, 'play')) {
				const new_route = cloneDeep(this.routeStep);
				delete new_route.params.play;
				this.$emit('update', new_route);
			}
		},
		addMenuOption() {
			if (this.menu_choices.length < 9) {
				this.menu_choices.push({
					id: uniqueId(),
					input: this.menu_choices.length + 1,
					sub_route: null,
					step: 0,
				});
			}
		},
		removeMenuOption(index) {
			this.menu_choices.splice(index, 1);
			this.menu_choices = this.menu_choices.map((choice, index) => {
				choice.input = index + 1;
				return choice;
			});
		},
		sortMenuOptions(old_index, new_index) {
			const choice = this.menu_choices.splice(old_index, 1)[0];
			this.menu_choices.splice(new_index, 0, choice);
			this.menu_choices = this.menu_choices.map((choice, index) => {
				choice.input = index + 1;
				return choice;
			});
		},
		addOptions() {
			if (!has(this.routeStep.params, 'options')) {
				const new_route = cloneDeep(this.routeStep);
				new_route.params.options = {};
				this.$emit('update', new_route);
			}
		},
		removeOptions() {
			if (has(this.routeStep.params, 'options')) {
				const new_route = cloneDeep(this.routeStep);
				delete new_route.params.options;
				this.$emit('update', new_route);
			}
		},
		deleteStep(event) {
			this.$confirm.require({
				target: event.currentTarget,
				group: 'voice_route_builder',
				message: 'Are you sure you want to delete this route step?',
				icon: 'pi pi-exclamation-circle',
				acceptClass: 'p-button-danger',
				acceptIcon: 'pi pi-trash',
				acceptLabel: 'Delete Route Step',
				rejectLabel: 'Cancel',
				accept: async () => {
					this.$emit('delete');
				},
			});
		},
	},
	directives: {
		sortable: {
			beforeMount(el, binding, vnode) {
				let options = merge({}, binding.value, {
					onEnd: (event) => {
						binding.instance.sortMenuOptions(event.oldIndex, event.newIndex);
					},
				});
				vnode.sortable = Sortable.create(el, options);
			},
		},
	},
};
</script>

<style lang="less" scoped>
.field.xxxs {
	flex: 0 0 40px;
}

.menu-choice {
	border: 1px solid var(--gray-20);
	border-radius: 5px;
	margin-bottom: 0.5em;
	padding: 0.625em;
}

:deep(.input-style input) {
	background-color: var(--gray-25) !important;
	border-color: var(--gray-40) !important;
	color: black !important;
}

.add-button {
	align-items: center;
	border: 1px dashed var(--gray-15);
	border-radius: 3px;
	color: var(--gray-50);
	cursor: default;
	display: flex;
	flex-direction: row;
	gap: 10px;
	height: 2.5em;
	padding: 0 1em;

	&:hover {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-light);
		color: var(--color-b);
	}
}
</style>
