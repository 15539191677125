<template>
	<div id="channels-list" class="view-content">
		<div class="page-heading">
			<h1>Channels</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<ChannelList />
	</div>
</template>

<script>
import ChannelList from './List.vue';

export default {
	name: 'ChannelsList',
	components: {
		ChannelList,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Channels' }],
		};
	},
};
</script>
