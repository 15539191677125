import { $GQL } from '@GQL';

export async function deleteSource(id: string) {
	const mutation = `
        mutation DeleteSource($id: ID!,) {
			deleteSource(id: $id) {
				source_id
				status
			}
        }
    `;

	const result = await $GQL.request(mutation, { id });
	return result.deleteSource;
}
