import { $GQL } from '@GQL/index';
import type { Lead } from '@nextgenleads/lead-driver';

export async function updateLead(id: string, data: Partial<Lead>) {
	const mutation = `
        mutation UpdateLead($id: ID!, $data: JSONObject!) {
            updateLead(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateLead;
}
