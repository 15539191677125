import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function getBlockedNumbers(filters: QueryFilters) {
	const query = `
        query GetBlockedNumbers($filters: [[Any]]) {
            blockedNumbers(filters: $filters) {
                phone
				reason
				user_id
				user {
					id
					first_name
					last_name
				}
				created_at
            }
        }
    `;

	const result = await $GQL.request(query, { filters });
	return result.blockedNumbers;
}
