<template>
	<div id="sources-list" class="view-content">
		<div class="page-heading">
			<h1>Sources</h1>
			<breadcrumb :items="breadcrumb_items" />
		</div>
		<SourceList />
	</div>
</template>

<script>
import SourceList from './List.vue';

export default {
	name: 'SourcesList',
	components: {
		SourceList,
	},
	data() {
		return {
			breadcrumb_items: [{ label: 'Sources' }],
		};
	},
};
</script>
