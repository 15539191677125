<template>
	<div :class="['attribute-section filter', { active: custom_bid_tobacco }]">
		<div class="control-group">
			<div class="inner">
				<div class="controls">
					<div class="field fit">
						<p-checkbox v-model="custom_bid_tobacco" input-id="tobacco" binary />
					</div>
					<gutter size="10px" />
					<div class="field">
						<label class="large-label" for="tobacco">
							<div>Tobacco Users</div>
							<div class="sub-value">Match leads that are tobacco users</div>
						</label>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TobaccoAttribute',
	emits: ['update'],
	data() {
		return {
			custom_bid_tobacco: false,
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
				});
			},
			deep: true,
		},
	},
	methods: {
		loadAttribute(custom_bid) {
			const tobacco_user_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.tobacco_user';
			});
			if (tobacco_user_match) {
				this.custom_bid_tobacco = true;
			}
		},
		saveAttribute() {
			if (this.custom_bid_tobacco) {
				return {
					target: {
						path: 'lead.data.tobacco_user',
					},
					strategy: 'equals',
					comparator: {
						value: true,
					},
				};
			}
			return null;
		},
	},
};
</script>
