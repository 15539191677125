import { $GQL } from '@GQL/index';

export async function runLTAuction(data: { lead_id: string; vertical_id: string }) {
	const mutation = `
        mutation RunLTAuction($data: JSONObject!) {
            runLTAuction(data: $data) {
                status
                details {
                    bids
                }
            }
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.runLTAuction;
}
