import { $GQL } from '@GQL/index';
import { sortBy, groupBy } from 'lodash-es';
import type { QueryFilters } from '@nextgenleads/db';

/**
 * This function is to return users as options based on the mpid of the user or just all NGL users
 * @param params
 */
export async function getUsersAsOptions(filters?: QueryFilters) {
	const query = `
        query GetUsersAsOptions($filters: [[Any]]) {
            users(filters: $filters) {
                id
                first_name
                last_name
                account_id
                account {
                  name
                }
            }
        }
    `;

	const results = await $GQL.request(query, { filters });

	const users = results.users;
	const grouped = groupBy(users, 'account_id');
	const final_options = [];
	Object.keys(grouped).forEach((key) => {
		let group = key; //?
		if (group === 'null') {
			group = 'Internal';
		} else {
			const account_name = grouped[key][0].account.name;
			group = account_name + ` (${key})`;
		}
		const unsorteditems = grouped[key].map((row) => {
			return {
				value: row.id,
				label: row.first_name + ' ' + row.last_name,
			};
		});
		final_options.push({
			label: group,
			items: sortBy(unsorteditems, ['label']),
		});
	});

	return sortBy(final_options, ['label']);
}
