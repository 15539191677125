import { $GQL } from '@GQL/index';
import type { Integration } from '@nextgenleads/pingpost-driver';

export async function getIntegrationById(id: string): Promise<Omit<Integration, 'created_at' | 'modified_at'>> {
	const query = `
        query GetIntegrationById($id: ID!) {
            integration(id: $id) {
                id
                account_id
                created_by
                ping_enabled
                ping_settings 
                post_enabled
                post_settings
                options
            }
        }
    `;

	const result = await $GQL.request(query, { id });
	return result.integration;
}
