<template>
	<div class="lt-question">
		<div class="lt-question-heading">
			<div class="lt-question-icon">
				<icon type="comment-text-outline" size="24px" />
			</div>
			<div class="lt-question-body">
				<p>
					Does anyone that you plan to have on this policy have a pre-existing condition such as diabetes or heart
					issues?
				</p>
				<p class="sub-value">When in doubt, mark YES</p>
			</div>
		</div>
		<div class="lt-question-content">
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-10" style="margin-bottom: 0">
						<div class="field fit">
							<p-radio-button input-id="preexisting_condition_true" v-model="preexisting_condition" :value="true" />
						</div>
						<div class="field">
							<label for="preexisting_condition_true" class="radio-label"
								>Yes, someone on the plan may have a pre-existing condition</label
							>
						</div>
					</div>
					<div class="controls gap-10">
						<div class="field fit">
							<p-radio-button input-id="preexisting_condition_false" v-model="preexisting_condition" :value="false" />
						</div>
						<div class="field">
							<label for="preexisting_condition_false" class="radio-label"
								>No, nobody on the plan has a pre-existing condition</label
							>
						</div>
					</div>
				</div>

				<div v-if="v$.preexisting_condition.$error" class="validation-error">
					{{ v$.preexisting_condition.$errors[0].$message }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get } from 'lodash-es';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

export default {
	name: 'LTQuestion_PreexistingCondition',
	emits: ['update'],
	props: {
		lead: {
			type: Object,
			required: true,
		},
	},
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	computed: {
		preexisting_condition: {
			get() {
				return get(this.lead, 'data.has_medical_condition', null);
			},
			set(new_value) {
				this.$emit('update', {
					data: {
						has_medical_condition: new_value,
					},
				});
			},
		},
	},
	validations() {
		return {
			preexisting_condition: {
				required: helpers.withMessage('This question is required', required),
			},
		};
	},
};
</script>
