import { $GQL } from '@GQL/index';
import type { QueryFilters } from '@nextgenleads/db';

export async function reportAdminLiveTransfers(
	params: { date_range: [string, string]; filters: QueryFilters; page?: number },
	refresh?: boolean
) {
	const query = `
        query ReportAdminLiveTransfers($params: JSONObject!) {
            reportAdminLiveTransfers(params: $params) {
				metrics
				rows
				row_count
			}
        }
    `;

	const no_cache = refresh ? ['reportAdminLiveTransfers'] : [];
	const results = await $GQL.request(query, { params, no_cache });
	return results.reportAdminLiveTransfers;
}
