import { $GQL } from '@GQL/index';
import type { GetListParams } from '@nextgenleads/db';

export async function reportLiveTransferEventHistory(params: {
	data_range: [string, string];
	lead_id?: string;
	pagination: { page: number; page_size: number };
}) {
	const query = `
        query ReportLiveTransferEventHistory($params: JSONObject!) {
            reportLiveTransferEventHistory(params: $params) {
				rows
				row_count
			}
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportLiveTransferEventHistory;
}
