<template>
	<div class="recent-auctions">
		<row>
			<div class="w-full flex justify-content-end align-items-center">
				<p-button
					v-tooltip.top="'Refresh'"
					label="Refresh Results"
					icon="pi pi-refresh"
					aria-label="Refresh"
					class="mr-2"
					@click="refreshReports"
				/>
			</div>
			<gutter size="20px" />
		</row>
		<div class="flex align-items-center justify-content-center w-full">
			<p-progress-spinner
				v-if="loading"
				class="spinner"
				style="height: 64px; width: 64px; margin: 0 auto"
				:pt="{ circle: { style: { stroke: 'var(--color-a)', strokeWidth: 2 } } }"
			/>
		</div>
		<p-message v-if="filtered_auctions.length === 0 && loaded" :closable="false">
			This campaign has not participated in any recent auctions.
		</p-message>
		<div v-if="!loading && loaded" class="flex">
			<p-timeline :value="filtered_auctions">
				<template #opposite="row">
					<div class="opposite">
						<div class="recent-auction-date">
							{{ formatDate(row.item.created_at, 'YYYY-MM-DD') }}<br />
							{{ formatDate(row.item.created_at, 'h:mm:ss A') }}
						</div>
						<div class="time-ago">{{ timeAgo(row.item.created_at) }}</div>
					</div>
				</template>
				<template #content="row">
					<div class="content">
						<div v-if="$root.sessionStore.isAdminUser" class="lead-id">
							<template v-if="row.item.lead_id === 'D-PING-LEAD'">PING AUCTION</template>
							<router-link v-else :to="`/leads/${row.item.lead_id}`">{{ row.item.lead_id }}</router-link>
							<a
								class="auction-link"
								href="javascript:void(0)"
								v-tooltip.top="'View Auction Details'"
								@click="
									$refs.auction_modal.open(row.item.lead_id, {
										auction_log_id: row.item.auction_log_id,
									})
								"
							>
								<icon class="ml-1" type="open-in-app" size="18px" />
							</a>
						</div>
						<div v-else>
							<router-link v-if="row.item.winner" :to="`/leads/${row.item.lead_id}`">{{
								row.item.lead_id
							}}</router-link>
							<strong v-else-if="row.item.dq_reason">DISQUALIFIED</strong>
							<span v-else>OUTBID</span>
						</div>
						<div class="bid">
							<template v-if="row.item.dq_reason"
								><div class="trimmed">{{ row.item.dq_reason }}</div></template
							>
							<template v-else>Bid: {{ currency(row.item.bid) }}</template>
						</div>
						<div class="outcome">
							<div v-if="row.item.winner"><icon type="check" size="18px" /> Won Auction</div>
							<div v-else-if="row.item.dq_reason"><icon class="red" type="cancel" size="18px" /> Disqualified</div>
							<div v-else><icon class="yellow" type="close" size="18px" /> Lost</div>
						</div>
					</div>
				</template>
			</p-timeline>
			<auction-modal ref="auction_modal" />
		</div>
	</div>
</template>

<script lang="ts">
import { getRecentAuctions } from '@GQL';
import AuctionModal from '@/views/Leads/panels/modals/AuctionModal.vue';
import pProgressSpinner from 'primevue/progressspinner';
import pTimeline from 'primevue/timeline';
import { formatDate, timeAgo, currency } from '@/lib/Filters';

const plReasons = ['Premium listings are not valid in this auction', 'Only premium listings are valid in this auction'];

const internalReasons = [
	'Sorted out of contention',
	'Premium listings are not valid in this auction',
	'Only premium listings are valid in this auction',
	'Promotion does not match current conditions',
	'Campaign promotion is not included in the auction configuration',
	'Promotion is not within start & end date',
	'Campaign promotion is no longer exists',
	'Campaign does not match source tags',
];

export default {
	name: 'CampaignStatsRecentAuctions',
	data() {
		return {
			hidden_reasons: this.$root.sessionStore.admin ? plReasons : internalReasons,
			recent_auctions: [],
			loading: false,
			loaded: false,
		};
	},
	components: {
		AuctionModal,
		pProgressSpinner,
		pTimeline,
	},
	props: {
		query: {
			type: Object,
			required: true,
		},
	},
	async mounted() {
		await this.getRecentAuctions();
	},
	computed: {
		filtered_auctions() {
			return this.recent_auctions.filter((auction) => !this.hidden_reasons.includes(auction.dq_reason));
		},
	},
	methods: {
		formatDate,
		timeAgo,
		currency,
		async getRecentAuctions(refresh = false) {
			try {
				this.loading = true;
				let no_cache = [];
				if (refresh) {
					no_cache = ['reportRecentAuctions'];
				}
				const recent_auctions = await getRecentAuctions(
					{
						campaign_id: this.$route.params.campaign_id,
						date_range: this.query.date_range,
					},
					no_cache
				);
				this.recent_auctions = recent_auctions;
			} catch (err) {
				console.error(err);
				this.$toast.add({
					severity: 'error',
					summary: 'Failed to get recent auctions',
					detail: 'Try again or contact dev support',
				});
			} finally {
				this.loading = false;
				this.loaded = true;
			}
		},
		async refreshReports() {
			await this.getRecentAuctions(true).catch((err) => {});
		},
	},
};
</script>

<style lang="less" scoped>
.recent-auctions {
	:deep(.p-message) {
		margin: 0 0;
	}

	:deep(.p-timeline-event-opposite) {
		flex: none;
		min-width: 130px;
	}

	// :deep(.p-timeline-event-content) {
	// 	flex: 3 0;
	// }

	.opposite {
		color: var(--gray-50);
		font-size: 0.875em;
		line-height: 1.35em;
	}

	.time-ago {
		color: var(--gray-35);
		font-size: 0.75em;
	}

	.content {
		font-size: 0.875em;
		line-height: 1.35em;

		flex-wrap: wrap;

		.lead-id {
			font-weight: bold;
		}

		.outcome div {
			align-items: flex-start;
			display: flex;
			gap: 5px;

			.mdi-icon {
				color: var(--green);
			}

			.yellow {
				color: var(--yellow);
			}

			.red {
				color: var(--red);
			}
		}
	}

	.trimmed {
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		text-wrap: nowrap;
		width: 100%;
	}
}
</style>
