import { $GQL } from '@GQL';

export async function deletePromotion(id: string) {
	const mutation = `
        mutation DeletePromotion($id: String!) {
			deletePromotion(id: $id) {
				id
			}
        }
    `;

	const result = await $GQL.request(mutation, { id });
	return result.deletePromotion;
}
