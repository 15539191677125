import { $GQL } from '@GQL/index';
import log from '@/lib/Log';
import type { GetListParams } from '@nextgenleads/db';

export async function getOfferList(params: GetListParams) {
	const query = `
    query GetOfferList($params: GetListParams!){
        offerList(params: $params) {
			row_count
            rows {
                id
                name
                trigger
                required_funding
                credit_amount
				max_credit_amount
                credit_type
                status
            }
        }
    }       
    `;

	const result = await $GQL.request(query, {
		params,
	});

	return result.offerList;
}
