import { $GQL } from '@GQL/index';

export async function reportParentAccountOverview(
	params: { parent_account_id: string; vertical_id: string[]; product: string[] },
	refresh: boolean
) {
	const query = `
        query ReportParentAccountOverview($params: JSONObject!) {
            reportParentAccountOverview(params: $params)
        }
    `;

	const no_cache = refresh ? ['reportParentAccountOverview'] : undefined;
	const results = await $GQL.request(query, {
		params,
		no_cache,
	});
	return results.reportParentAccountOverview;
}
