<template>
	<p-avatar
		v-if="user && user.avatar_url"
		shape="circle"
		:image="user.avatar_url"
		:size="size"
		:style="`background: ${userColor};`"
	/>
	<p-avatar
		v-else-if="user && (user.first_name || user.last_name) && !user.avatar_url"
		shape="circle"
		:label="initials(name)"
		:size="size"
		:style="`background: ${userColor}; color: ${fontColor}`"
	/>
	<p-avatar
		v-else
		shape="circle"
		icon="pi pi-user"
		:size="size"
		:style="`background: ${userColor}; color: ${fontColor}`"
	/>
</template>

<script lang="ts">
import pAvatar from 'primevue/avatar';
import { initials } from '@/lib/Filters';
export default {
	name: 'UserAvatar',
	components: {
		pAvatar,
	},
	props: {
		user: Object,
		size: String,
	},
	data() {
		const random_color = [
			'#0d6efd',
			'#198754',
			'#d9a406',
			'#0baccc',
			'#b62b70',
			'#20c997',
			'#fd7e14',
			'#6f42c1',
			'#bb2d3b',
			'#7c8ea7',
		];

		return {
			userColor: 'var(--color-b)' || random_color[Math.floor(Math.random() * random_color.length)],
		};
	},
	computed: {
		name() {
			if (this.user.first_name || this.user.last_name) {
				return `${this.user.first_name} ${this.user.last_name}`;
			}
			return 'User';
		},
		fontColor() {
			let hexcolor = this.userColor;
			// If a leading # is provided, remove it
			if (hexcolor.slice(0, 1) === '#') {
				hexcolor = hexcolor.slice(1);
			}

			// If a three-character hexcode, make six-character
			if (hexcolor.length === 3) {
				hexcolor = hexcolor
					.split('')
					.map(function (hex) {
						return hex + hex;
					})
					.join('');
			}

			// Convert to RGB value
			var r = parseInt(hexcolor.substr(0, 2), 16);
			var g = parseInt(hexcolor.substr(2, 2), 16);
			var b = parseInt(hexcolor.substr(4, 2), 16);

			// Get YIQ ratio
			var yiq = (r * 299 + g * 587 + b * 114) / 1000;

			// Check contrast
			return yiq >= 128 ? 'black' : 'white';
		},
	},
	methods: {
		initials,
	},
};
</script>

<style lang="less">
.p-avatar {
	border: 1px solid var(--white);
}
</style>
