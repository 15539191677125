<template>
	<div id="live-transfer-start" class="view-content">
		<div class="page-heading">
			<h1>Live Transfer Workstation</h1>
		</div>
		<gutter size="20px" />
		<div class="card">
			<div class="greeting">
				<div class="flex gap-20">
					<p-select-button v-model="call_type" :options="call_type_options" option-label="label" option-value="value" />
				</div>
				<gutter size="20px" />
				<component :is="greeting" :call-type="call_type" />
			</div>
			<div class="search">
				<div class="control-group">
					<div class="inner">
						<label class="control-label">Enter a lead ID or phone number to look up an existing lead...</label>
						<div class="controls gap-10">
							<div class="field">
								<div class="p-inputgroup">
									<p-input-text class="large" v-model="query" autofocus />
									<p-button class="large" icon="pi pi-times" severity="secondary" @click="resetSearch()" />
								</div>
							</div>
							<div class="field fit">
								<p-button class="large" icon="pi pi-search" label="Search" />
							</div>
						</div>
					</div>
				</div>

				<gutter size="10px" />

				<p-button
					icon="pi pi-plus"
					v-if="query && query.length >= 10"
					label="Create New Lead"
					@click="createNewLead()"
				/>
			</div>
			<div v-if="leads.length" class="results">
				<h2>Matching Results</h2>
				<div v-for="lead in sortedLeads" class="result" @click="selectLead(lead)">
					<div class="vertical-id">
						<vertical-icon :key="lead.id" :vertical-id="lead.vertical_id" hide-tooltip size="40px" />
					</div>
					<div class="lead-info">
						<div class="name">{{ lead.first_name }} {{ lead.last_name }}</div>
						<div class="details">
							<div class="lead-id">
								<icon type="account-box" size="18px" />
								{{ lead.id }}
							</div>
							<div class="location">
								<icon type="map-marker" size="18px" />
								{{ lead.city }}, {{ lead.state }}
							</div>
							<div class="phone">
								<icon type="phone" size="18px" />
								{{ phoneFormat(lead.phone) }}
							</div>
						</div>
					</div>
					<div class="gap"></div>
					<div class="action">
						<icon type="open-in-new" size="24px" />
					</div>
				</div>
			</div>
		</div>
		<p-dialog ref="vertical_modal" v-model:visible="show_vertical_modal" modal dismissable-mask>
			<template #header>
				<div class="flex align-items-center gap-10">
					<icon type="format-list-checks" size="24px" />
					<strong>Select a Vertical to Continue</strong>
				</div>
			</template>
			<div class="vertical-button health_insurance" tabindex="0" @click="openTransferForm('health_insurance')">
				<div class="icon">
					<vertical-icon vertical-id="health_insurance" />
				</div>
				<div class="label">Continue as Health Insurance</div>
				<div v-if="selected_lead.vertical_id === 'health_insurance'" class="current-vertical">
					<icon type="check-decagram" size="24px" />
				</div>
			</div>
			<div class="vertical-button medicare" tabindex="0" @click="openTransferForm('medicare')">
				<div class="icon">
					<vertical-icon vertical-id="medicare" />
				</div>
				<div class="label">Continue as Medicare</div>
				<div v-if="selected_lead.vertical_id === 'medicare'" class="current-vertical">
					<icon type="check-decagram" size="24px" />
				</div>
			</div>
		</p-dialog>
	</div>
</template>

<script>
import { sortBy, uniqBy } from 'lodash-es';
import verticalIcon from '@/components/elements/VerticalIcon.vue';
import { phoneFormat } from '@/lib/Filters';
import { getLeadsForLTW } from '@GQL';
import healthInsuranceGreeting from '../Verticals/HealthInsurance/Greeting.vue';

export default {
	name: 'LiveTransferStart',
	components: {
		verticalIcon,
		healthInsuranceGreeting,
	},
	data() {
		return {
			query: this.$route.query.query,
			leads: [],
			selected_lead: null,
			vertical_id: 'health_insurance',
			call_type: 'outbound',
			call_type_options: [
				{ label: 'Outbound', value: 'outbound' },
				{ label: 'Inbound', value: 'inbound' },
			],
			show_vertical_modal: false,
		};
	},
	computed: {
		sortedLeads() {
			const sorted_leads = sortBy(this.leads, (lead) => {
				return -lead.created_at;
			});

			return uniqBy(sorted_leads, 'vertical_id');
		},
		greeting() {
			// CURRENTLY NO DIFFERENCE
			if (this.vertical_id === 'health_insurance') {
				return healthInsuranceGreeting;
			} else {
				return healthInsuranceGreeting;
			}
		},
	},
	watch: {
		query(new_value) {
			this.cleanSearch(new_value);
		},
	},
	async mounted() {
		if (this.$route.query.query) {
			await this.cleanSearch(this.$route.query.query);
		}
	},
	methods: {
		phoneFormat,
		async cleanSearch(new_value) {
			// Check if it is a phone number
			const phone_query = new_value.replace(/\D/g, '');

			if (phone_query.length > 9) {
				this.getLeads(phone_query);
			}

			// Check if it is a lead_id
			const lead_id_query = new_value.replace(/[^A-Z0-9\-]/g, '');
			const lead_id_pattern = /^D|A|C|T-[A-Z0-9]{4}-[A-Z0-9]{4}$/;
			if (lead_id_pattern.test(lead_id_query)) {
				this.getLeads(lead_id_query);
			}
		},
		async getLeads(input) {
			const search_term = input.trim();
			const result = await getLeadsForLTW({
				filters: [`(id = '${search_term}' OR phone = '${search_term}') AND product IN ('data', 'live_transfer')`],
			});

			this.leads = result.rows;
			this.$router.push({
				query: {
					query: search_term,
				},
			});
		},
		resetSearch() {
			this.query = '';
			this.leads = [];
		},
		async selectLead(lead) {
			this.selected_lead = lead;
			this.show_vertical_modal = true;
		},
		async openTransferForm(vertical_id) {
			const lead_route = {
				name: 'LiveTransferForm',
				params: {
					lead_id: this.selected_lead.id,
					vertical_id: vertical_id,
				},
			};
			this.$router.push(lead_route);
		},
		async createNewLead() {
			const lead_route = {
				name: 'LiveTransferCreate',
				query: {
					vertical_id: this.vertical_id,
				},
			};

			if (this.query.replace(/\D/g, '').length === 10) {
				lead_route.query.phone = this.query.replace(/\D/g, '');
			}

			this.$router.push(lead_route);
		},
	},
};
</script>

<style lang="less" scoped>
.greeting {
	padding: 30px;
}

.search,
.results {
	border-top: 1px solid var(--gray-10);
	padding: 30px;
}

.result-heading {
	align-items: center;
	display: flex;
	gap: 20px;

	h2 {
		margin-bottom: 0;
	}
}

input.large,
button.large {
	font-size: 1.375em;
	height: 2.5em;
	padding: 0 0.625em;
}

.result {
	align-items: center;
	background-color: var(--gray-05);
	border: 2px solid var(--gray-15);
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	font-size: 0.875em;
	gap: 20px;
	margin-top: 1em;
	padding: 20px;

	.vertical-id {
		position: relative;

		.alert-icon {
			align-items: center;
			background-color: var(--red);
			border-radius: 15px;
			bottom: -10px;
			border: solid 2px white;
			color: white;
			display: flex;
			height: 30px;
			justify-content: center;
			position: absolute;
			right: -10px;
			width: 30px;
		}
	}

	.name {
		font-weight: bold;
		font-size: 1.375em;
		margin-bottom: 0.5em;
	}

	.details {
		color: var(--gray-50);
		display: flex;
		flex-direction: row;
		gap: 20px;

		.mdi-icon {
			color: var(--teal);
		}
	}

	.gap {
		flex: 1 0;
	}

	.action {
		color: var(--teal);
	}

	&:hover {
		background-color: var(--color-b-lightest);
		border-color: var(--color-b-lighter);
	}
}

.vertical-button {
	align-items: center;
	border: 2px solid var(--gray-15);
	border-radius: 5px;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	gap: 20px;
	margin-top: 1em;
	padding: 20px;

	.label {
		font-size: 1.125rem;
		font-weight: bold;
	}

	.current-vertical {
		text-align: right;
		color: var(--color-a);
		flex: 1 0;
	}

	&:hover {
		background-color: var(--color-b-lightest);
		border: 2px solid var(--color-b-lighter);
	}

	&:first-child {
		margin-top: 0;
	}
}
</style>
