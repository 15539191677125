<template>
	<div class="attribute-section" :class="{ active: enable_income_filter }">
		<div class="header">
			<div class="title"><label class="clickable-label" for="income-switch">Target by Household Income</label></div>
			<div class="switch">
				<p-input-switch v-model="enable_income_filter" input-id="income-switch" />
			</div>
		</div>
		<div v-if="enable_income_filter" class="filter">
			<div class="control-group">
				<div class="inner">
					<div class="controls">
						<div class="field fit text-align-center">
							<div class="slider-value">
								{{ incomeValue(custom_bid_income[0]) }}
							</div>
						</div>
						<div class="field slider">
							<p-slider
								v-model="custom_bid_income"
								class="slider"
								:max="180000"
								:min="0"
								:step="1000"
								range
								@slideend="sortIncome"
							/>
						</div>
						<div class="field fit text-align-center">
							<div class="slider-value">
								{{ incomeValue(custom_bid_income[1]) }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { currency } from '@/lib/Filters';
import pSlider from 'primevue/slider';

export default {
	name: 'HouseholdIncomeAttribute',
	components: {
		pSlider,
	},
	emits: ['update'],
	data() {
		return {
			enable_income_filter: false,
			custom_bid_income: [0, 180000],
		};
	},
	watch: {
		$data: {
			handler(new_value, old_value) {
				this.$emit('update', {
					attribute: this.$options.name,
					value: this.saveAttribute(),
				});
			},
			deep: true,
		},
	},
	methods: {
		incomeValue(value) {
			if (value === 0) {
				return 'No Min';
			} else if (value === 180000) {
				return 'No Max';
			}
			return currency(value, 0);
		},
		sortIncome() {
			if (this.custom_bid_income[1] < this.custom_bid_income[0]) {
				this.custom_bid_income.sort();
			}
		},
		loadAttribute(custom_bid) {
			const income_match = custom_bid.match.find((condition) => {
				return condition.target.path === 'lead.data.income';
			});
			if (income_match) {
				if (income_match.comparator.value[1] === 999999999) income_match.comparator.value[1] = 180000;

				this.enable_income_filter = true;
				this.custom_bid_income = income_match.comparator.value;
			}
		},
		saveAttribute() {
			const income_range = this.custom_bid_income.slice();
			if (income_range[1] === 180000) {
				income_range[1] = 999999999;
			}

			if (this.enable_income_filter) {
				return {
					target: {
						path: 'lead.data.income',
					},
					strategy: 'in_range',
					comparator: {
						value: income_range,
					},
				};
			}
			return null;
		},
	},
};
</script>

<style scoped lang="less">
.slider-value {
	width: 70px !important;
}
</style>
