<template>
	<div>Email Type</div>
</template>

<script>
export default {
	name: 'ShipperSettingsEmail',
	props: {
		shipperSettings: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {};
	},
};
</script>

<style lang="less"></style>
