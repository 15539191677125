import { defineStore } from 'pinia';
import { universalSearch } from '@GQL';
import { useSessionStore } from '@/stores/session';
import { filter, map } from 'lodash-es';

type lead = {
	id: string;
	name: string;
	email: string;
	link: string;
};

type account = {
	id: string;
	name: string;
	email: string;
	link: string;
};

type user = {
	id: string;
	name: string;
};

type campaign = {
	id: string;
	name: string;
	link: string;
};

type sourcehash = {
	hash: string;
	source: string;
	link: string;
};

type navigation = {
	name: string;
	path: string;
	link: string;
};

type setting = {
	name: string;
	path: string;
	setting: string;
	link: string;
};

interface searchState {
	leads: lead[];
	users: user[];
	accounts: account[];
	campaigns: campaign[];
	// sourcehashes: sourcehash[];
	// navigation: navigation[];
	// settings: setting[];
}

export const useSearchStore = defineStore('search', {
	state: (): searchState => {
		const sessionStore = useSessionStore();
		return {
			role_id: sessionStore.role.id,
			account_id: sessionStore.user.account_id,
			leads: [],
			accounts: [],
			users: [],
			campaigns: [],
		};
	},
	getters: {
		fullSearchResults(state) {
			return [
				...state.leads,
				...state.accounts,
				...state.campaigns,
				...state.users,
				// ...state.sourcehashes,
				// ...state.navigation,
				// ...state.settings,
			];
		},
	},
	actions: {
		fetchSearchResults(search_query) {
			// Pattern check, GraphQL query, filter settings and results
		},
		async newSearch(term: string) {
			function transformData(data: Object, app_level = 'admin') {
				const sessionStore = useSessionStore();
				let transformed = {};
				switch (data.type) {
					case 'lead':
						transformed = {
							...data.data,
							id: data.id,
							link: `/leads/${data.id}`,
						};
						break;
					case 'account':
						let link_path;
						if (data.data.is_parent) {
							link_path = `/reports/parent-account-overview`;
						} else {
							link_path = sessionStore.isAdminUser ? `/reports/account-activity` : `/reports/account-overview`;
						}
						transformed = {
							...data.data,
							id: data.id,
							link: link_path,
						};
						if (!sessionStore.isAccountUser) {
							transformed.link = data.data.is_parent
								? `/parent-accounts/${data.id}${transformed.link}`
								: `/accounts/manage/${data.id}${transformed.link}`;
						}
						break;
					case 'user':
						transformed = {
							...data.data,
							id: data.id,
							link: `/users`,
						};
						if (data.data.account_id && !sessionStore.isAccountUser) {
							transformed.link = `/marketplaces/manage/${data.data.account_id.slice(0, 3)}/accounts/manage/${data.data.account_id}/users`;
						}
						break;
				}
				return transformed;
			}

			const res = await universalSearch(term, this.role_id, this.account_id);
			this.$state.leads = map(filter(res, { type: 'lead' }), transformData);
			this.$state.accounts = map(filter(res, { type: 'account' }), transformData);
			this.$state.users = map(filter(res, { type: 'user' }), transformData);
			return res;
		},
	},
});
