<template>
	<div class="mdi-icon" :style="iconSize">
		<svg :class="{ spinning: loading }" viewBox="0 0 24 24" fill="currentColor">
			<path :d="iconSVG" />
		</svg>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { camelCase } from 'lodash-es';

const props = defineProps({
	loading: {
		type: Boolean,
	},
	size: {
		type: String,
		default: '1em',
	},
	type: String,
});

const icon_name = camelCase(`mdi-${props.type}`);
const { [icon_name]: iconPath, mdiLoading: loadingIcon } = await import('@mdi/js');

const iconSVG = computed(() => {
	if (props.loading) {
		return loadingIcon;
	}
	return iconPath;
});

const iconSize = computed(() => {
	return {
		height: props.size,
		width: props.size,
	};
});
</script>

<style scoped lang="less">
@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.spinning {
	animation-duration: 500ms;
	animation-iteration-count: infinite;
	animation-name: spin;
	animation-timing-function: linear;
}

.rotate {
	transform: rotate(90deg);
}

.mdi-icon {
	display: inline-block;
	transition: all 100ms linear;
	vertical-align: middle;
}
</style>
