<template>
	<p-card id="new-password-form">
		<template #header>
			<div class="flex w-full p-2 justify-content-center align-items-center text-center">
				<img class="logo" src="/assets/img/logos/ngl-logo.svg" alt="NextGen Leads Logo" />
			</div>
		</template>
		<template #title>
			<h2>Set your new password here...</h2>
		</template>
		<template #subtitle>
			<p>Enter your new password below. We'll let you know if it's secure enough to keep your data.</p>
		</template>
		<template #content>
			<form>
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<div class="p-inputgroup">
											<span class="p-inputgroup-addon">
												<alt-icon type="lock" size="1.25rem" />
												<label class="visually-hidden" for="new_password">New Password</label>
											</span>
											<p-password
												id="new_password"
												v-model="new_password"
												placeholder="New Password"
												toggle-mask
												strong-regex="^(?=.*[a-z])(?=.*[A-Z])(?=.*[!-\/:-@[-`{-~])(?=.*[0-9])(?=.{8,})"
											>
												<template #header>
													<div class="mb-2 font-bold">Pick a password</div>
												</template>
												<template #footer>
													<p-divider />
													<p class="mt-2">Password requirements</p>
													<ul class="pl-2 ml-2 mt-0" style="font-size: var(--font-size-sm); line-height: 1.5">
														<li>At least one lowercase</li>
														<li>At least one uppercase</li>
														<li>At least one numeric</li>
														<li>At least one special character</li>
														<li>Minimum 8 characters</li>
													</ul>
												</template>
											</p-password>
										</div>
										<div v-if="v$.new_password.$error" class="validation-error">
											{{ v$.new_password.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
				<gutter size="20px" />
				<row>
					<column>
						<div class="control-group">
							<div class="inner">
								<div class="controls">
									<div class="field">
										<div class="p-inputgroup">
											<span class="p-inputgroup-addon">
												<label class="visually-hidden" for="confirm-password">Confirm Password</label>
												<alt-icon type="check" size="1.25rem" />
											</span>
											<p-password
												id="confirm-password"
												v-model="confirm_password"
												class="flex-1"
												:feedback="false"
												aria-labelledby="password"
												placeholder="Confirm Password"
												@keypress.enter="setPassword"
											/>
										</div>
										<div v-if="v$.confirm_password.$error" class="validation-error">
											{{ v$.confirm_password.$errors[0].$message }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</column>
				</row>
			</form>
		</template>
		<template #footer>
			<div class="flex flex-column justify-content-center w-full mt-2">
				<p-button class="mb-2" label="SET NEW PASSWORD" @click="setPassword()" />
				<p-button text label="CANCEL" @click.prevent="$emit('changeForm', 'LoginForm')" />
			</div>
		</template>
	</p-card>
</template>

<script lang="ts">
import pCard from 'primevue/card';
import pDivider from 'primevue/divider';
import pButton from 'primevue/button';
import pPassword from 'primevue/password';
import { useVuelidate } from '@vuelidate/core';
import { required, sameAs, helpers } from '@vuelidate/validators';
import { resetPassword } from '@GQL';
import { passwordStrength } from '@/lib/Utils/passwordStrength';

export default {
	name: 'SetPasswordForm',
	components: {
		pCard,
		pButton,
		pDivider,
		pPassword,
	},
	emits: ['changeForm'],
	setup() {
		return { v$: useVuelidate() };
	},
	data() {
		return {
			new_password: '',
			confirm_password: '',
		};
	},
	methods: {
		async setPassword() {
			const is_valid = await this.v$.$validate();
			if (!is_valid) return;
			try {
				const result = await resetPassword(this.$route.params.token, this.new_password, this.confirm_password);
				const account_email = result.email;
				this.$toast.add({
					severity: 'success',
					summary: 'Password Set',
					detail: `Successfully set password.`,
					life: 3000,
				});
				// Go back to login with updated user
				this.$router.replace({
					path: '/login',
					query: {
						loginEmail: account_email,
					},
				});
				this.$emit('changeForm', 'LoginForm', account_email);
			} catch (err) {
				this.$toast.add({
					severity: 'error',
					summary: 'Password Set Error',
					detail: 'Please contact support for assistance',
					life: 3000,
				});
			}
		},
	},
	validations() {
		return {
			new_password: { required, passwordStrength },
			confirm_password: { required, sameAsPassword: sameAs(this.new_password) },
		};
	},
};
</script>

<style lang="less">
h2 {
	margin-bottom: 1rem;
}

#new-password-form {
	border-radius: 20px;
	box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
	padding: 30px 30px 10px;
	width: 450px;

	.logo {
		max-height: 200px;
		max-width: 100%;
	}

	.p-card-footer {
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
}
</style>
