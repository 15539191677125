import { $GQL } from '@GQL/index';

export async function fetchReportRequestFields(report_name: string) {
	const query = `
        query FetchReportRequestFields($report_name: String!) {
            fetchReportRequestFields(report_name: $report_name) {
                fields,
                vertical_fields,
                message
            }
        }
    `;

	const result = await $GQL.request(query, { report_name });
	return result;
}
