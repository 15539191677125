import { $GQL } from '@GQL/index';
import type { Channel } from '@nextgenleads/source-driver';

export async function insertChannel(data: Omit<Channel, 'created_at'>) {
	const mutation = `
        mutation InsertChannel($data: JSONObject!) {
            insertChannel(data: $data) {
                id
            }
        }
    `;
	return await $GQL.request(mutation, { data });
}
