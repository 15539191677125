/**
 * ACCOUNT STORE
 * Contains data relating to the current nested account (or active account for non-admin)
 */

import { cloneDeep } from 'lodash-es';
import { defineStore } from 'pinia';
import { getAccountById, getBalances } from '@/lib/GQL';

const default_account = {
	id: null,
	mpid: null,
	name: '',
	internal: false,
	support: {
		account_manager_id: null,
		status: null,
		category: null,
		date_of_return: null,
		kpi_targets: {
			data_cpa: 0,
			call_cpa: 0,
			live_transfer_cpa: 0,
			products_sold: '',
		},
	},
	settings: {
		timezone: null,
		budgets: [],
		credit_terms: {
			enabled: false,
			limit: 0,
			limit_enabled: false,
		},
	},
	tags: [],
	status: 'new',
	primary_user_id: null,
	contact: {
		state: null,
		phone: null,
		email: null,
	},
	created_by: null,
	display_name: '',
	group_ids: [],
	signup_source: {
		source_id: null,
		internal_referral: false,
	},
	verified: false,
	is_parent: false,
	parent_account_id: null,
};

export const useAccountStore = defineStore('account', {
	state: () => {
		return {
			account: cloneDeep(default_account),
			balance: 0,
		};
	},
	getters: {
		accountId(): string | null {
			if (this.account) {
				return this.account.id;
			}
			return null;
		},
	},
	actions: {
		$reset() {
			this.account = cloneDeep(default_account);
		},
		async getAccount(account_id: string) {
			this.account = await getAccountById(account_id);
		},
		async getBalance() {
			const account_id = this.accountId;
			if (account_id) {
				const balances = await getBalances(account_id);
				this.balance = balances.actual;
			}
		},
	},
});
