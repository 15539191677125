<template>
	<div class="campaign-steps">
		<div class="step-container">
			<SelectVendor ref="select_vendor" v-if="step === 'select_vendor'" @skip="next" @select="disable_next = false" />
			<SelectVertical ref="select_vertical" v-if="step === 'select_vertical'" @select="disable_next = false" />
			<SelectProduct ref="select_product" v-else-if="step === 'select_product'" @select="disable_next = false" />
			<SelectPromotion
				ref="select_promotion"
				v-else-if="step === 'select_promotion'"
				@skipPromotion="skip_promotion = true"
				@skip="next"
				@select="disable_next = false"
			/>
			<SelectChannel
				ref="select_channel"
				v-else-if="step === 'select_channel'"
				@skipChannel="skip_channel = true"
				:skip-promotion="skip_promotion"
				@skip="next"
				@select="disable_next = false"
			/>
			<SelectMode
				ref="select_mode"
				v-else-if="step === 'select_mode'"
				:skip-promotion="skip_promotion"
				:skip-channel="skip_channel"
				@skip="next('skip_mode', 'edit_campaign')"
				@select="disable_next = false"
			/>
			<EditCampaign ref="edit_campaign" v-else-if="step === 'edit_campaign'" />
		</div>
		<action-bar :step="step" :disable-next="disable_next" @prev="prev" @next="next" />
	</div>
</template>

<script>
import { get } from 'lodash-es';
import actionBar from './Components/ActionBar.vue';
import SelectVendor from './Steps/SelectVendor.vue';
import SelectVertical from './Steps/SelectVertical.vue';
import SelectProduct from './Steps/SelectProduct.vue';
import SelectPromotion from './Steps/SelectPromotion.vue';
import SelectChannel from './Steps/SelectChannel.vue';
import SelectMode from './Steps/SelectMode.vue';
import EditCampaign from './Steps/EditCampaign.vue';
import { useCampaignStore } from '@/stores/campaign';
import { mapStores } from 'pinia';

export default {
	components: {
		actionBar,
		SelectVendor,
		SelectVertical,
		SelectProduct,
		SelectPromotion,
		SelectChannel,
		SelectMode,
		EditCampaign,
	},
	data() {
		return {
			step: this.startStep(),
			skip_vendor: false,
			skip_channel: false,
			skip_promotion: false,
			skip_mode: false,
			disable_next: true,
		};
	},
	computed: {
		...mapStores(useCampaignStore),
	},
	methods: {
		startStep() {
			if (this.$route.meta.new) {
				return 'select_vendor';
			}
			return 'edit_campaign';
		},
		prev() {
			const prev_step = this.$refs[this.step].prev();
			if (prev_step) this.step = prev_step;
		},
		next(skip_prop) {
			if (skip_prop) this[skip_prop] = true;
			let next_step = this.$refs[this.step].next();

			if (next_step === 'select_mode') {
				// Skip this step if buyer does not have advanced mode enabled
				if (!this.$root.sessionStore.isAdminUser) {
					const has_advanced_mode = get(this.$root.sessionStore, 'user.account.settings.allow_advanced_mode', false);
					if (!has_advanced_mode) {
						this.campaignStore.campaign.mode = 'basic';

						this.campaignStore.campaign.bids.push({
							name: 'Basic Location Targeting',
							type: 'location',
							match: [
								{
									target: {
										path: 'lead.state',
									},
									strategy: 'one_of',
									comparator: {
										value: [],
									},
								},
							],
							method: 'multiply',
							amount: 1,
							status: 'active',
							finalize: false,
							custom_minimum_bid: false,
							minimum_bid: 0,
							dq_reason: 'Location excluded from targeting',
						});

						this.skip_mode = true;
						next_step = 'edit_campaign';
					}
				}
			}

			if (next_step) {
				this.step = next_step;
				this.disable_next = true;
			}
		},
		updateFlag(event) {
			this[event.flag] = event.value;
		},
	},
};
</script>

<style lang="less" scoped>
.campaign-steps {
	flex: 1 0;
}

.step-container {
	display: flex;
	flex-direction: column;
	height: calc(100% - 80px);
}

.step {
	flex: 1 0;
}
</style>
