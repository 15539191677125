<template>
	<div class="content-attribute-filter">
		<template v-if="availableAttributeOptions.length === 0">
			<p-message :closable="false">There are no filter options available for the current campaign.</p-message>
		</template>
		<template v-else>
			<p>
				Target specific lead attributes such as income or household size. More targeting means less volume, so use these
				settings carefully.
			</p>
			<div class="filters">
				<template v-for="attribute_component in availableAttributeOptions" :key="attribute_component">
					<component
						:ref="(el) => (attribute_refs[attribute_component] = el)"
						:is="attribute_component"
						:mode="campaignStore.campaign.mode"
						@update="updateAttributeBid"
					/>
				</template>
			</div>
		</template>
	</div>
</template>

<script lang="ts">
import { forIn, get } from 'lodash-es';
import deleteAction from '@/components/widgets/DeleteAction.vue';
import pDialog from 'primevue/dialog';
import pSlider from 'primevue/slider';
import { useCampaignStore } from '@/stores/campaign';
import { mapStores } from 'pinia';
import AffordabilityAttribute from '../Attributes/Affordability.vue';
import AgeAttribute from '../Attributes/Age.vue';
import HasMedicalConditionAttribute from '../Attributes/HasMedicalCondition.vue';
import HouseholdIncomeAttribute from '../Attributes/HouseholdIncome.vue';
import HouseholdSizeAttribute from '../Attributes/HouseholdSize.vue';
import InterestInMedicareAttribute from '../Attributes/InterestInMedicare.vue';
import MedicalConditionsAttribute from '../Attributes/MedicalConditions.vue';
import MedicareAgeAttribute from '../Attributes/MedicareAge.vue';
import MedicareMinimumAgeAttribute from '../Attributes/MedicareMinimumAge.vue';
import MedicareEnrollmentAttribute from '../Attributes/MedicareEnrollment.vue';
import PregnantAttribute from '../Attributes/Pregnant.vue';
import SpokenLanguageAttribute from '../Attributes/SpokenLanguage.vue';
import TobaccoAttribute from '../Attributes/Tobacco.vue';
import { ulid } from 'ulid';

const default_attribute_bid = {
	id: null,
	name: '',
	type: 'attributes',
	match: [],
	method: 'multiply',
	amount: 1,
	custom_minimum_bid: false,
	minimum_bid: 0,
	finalize: false,
	status: 'active',
	dq_message: 'Lead excluded from targeting by attribute filters',
};

const attribute_options = {
	health_insurance: {
		data: [
			'AgeAttribute',
			'HouseholdSizeAttribute',
			'HouseholdIncomeAttribute',
			'MedicalConditionsAttribute',
			'PregnantAttribute',
		],
		call: [],
		live_transfer: [
			'SpokenLanguageAttribute',
			'AgeAttribute',
			'HasMedicalConditionAttribute',
			'AffordabilityAttribute',
		],
		ad: [],
	},
	medicare: {
		data: ['MedicareAgeAttribute', 'MedicareEnrollmentAttribute'],
		call: [],
		live_transfer: ['SpokenLanguageAttribute', 'MedicareMinimumAgeAttribute', 'MedicareEnrollmentAttribute'],
		ad: [],
	},
};

export default {
	name: 'AttributeFilteringContent',
	components: {
		deleteAction,
		pDialog,
		pSlider,
		AffordabilityAttribute,
		AgeAttribute,
		HasMedicalConditionAttribute,
		HouseholdIncomeAttribute,
		HouseholdSizeAttribute,
		InterestInMedicareAttribute,
		MedicalConditionsAttribute,
		MedicareAgeAttribute,
		MedicareEnrollmentAttribute,
		MedicareMinimumAgeAttribute,
		PregnantAttribute,
		SpokenLanguageAttribute,
		TobaccoAttribute,
	},
	data() {
		return {
			attribute_options,
			attribute_refs: {},
			attribute_matches: {},
		};
	},
	computed: {
		availableAttributeOptions() {
			return get(
				this.attribute_options,
				`${this.campaignStore.campaign.vertical_id_targeting}.${this.campaignStore.campaign.product_targeting}`,
				''
			);
		},
		hasAttributeBids() {
			return this.activeCustomBids.length + this.pausedCustomBids.length > 0;
		},
		...mapStores(useCampaignStore),
	},
	watch: {
		availableAttributeOptions: {
			handler(new_value) {
				if (new_value && new_value.length > 0) {
					const ref_interval = setInterval(() => {
						if (Object.keys(this.attribute_refs).length === this.availableAttributeOptions.length) {
							forIn(this.attribute_refs, (attribute_ref, attribute_key) => {
								// Find the custom_bid for the attribute
								const custom_bid = this.campaignStore.campaign.bids.find((attribute_bid) => {
									return attribute_bid.type === 'attributes' && attribute_bid.name === `${attribute_key} Filter`;
								});

								if (custom_bid) {
									attribute_ref.loadAttribute(custom_bid);
								}
							});
							clearInterval(ref_interval);
						}
					}, 100);
				}
			},
			immediate: true,
		},
	},
	methods: {
		updateAttributeBid(event) {
			// Remove the attribute bid
			if (event.value === null) {
				const custom_bid = this.campaignStore.campaign.bids.find((attribute_bid) => {
					return attribute_bid.type === 'attributes' && attribute_bid.name === `${event.attribute} Filter`;
				});

				if (custom_bid) {
					this.campaignStore.deleteBid(custom_bid.id);
				}
			}

			// Add/update the attribute bid
			else {
				const match = event.value;

				// Invert all matches for basic
				if (event.invert_basic !== false) {
					match.invert = true;
				}

				const custom_bid = this.campaignStore.campaign.bids.find((attribute_bid) => {
					return attribute_bid.type === 'attributes' && attribute_bid.name === `${event.attribute} Filter`;
				});

				if (custom_bid) {
					custom_bid.match = [match];
				} else {
					this.campaignStore.addBid({
						bid_id: ulid(),
						name: `${event.attribute} Filter`,
						type: 'attributes',
						match: [match],
						method: 'multiply',
						amount: 0,
						status: 'active',
						finalize: false,
						custom_minimum_bid: false,
						minimum_bid: 0,
						dq_message: `Lead excluded from targeting by attribute filters: ${event.attribute}`,
					});
				}
			}
		},
	},
};
</script>

<style lang="less" scoped>
@import (reference) '@/styles/responsive';

:deep(.attribute-section) {
	background: var(--gray-05);
	border: 1px solid var(--gray-20);
	border-radius: 3px;
	margin-bottom: 1rem;

	&:last-of-type {
		margin-bottom: 0;
	}

	.field.slider {
		padding: 0 2em;
	}

	.slider-value {
		width: 40px;
	}

	.large-label {
		font-size: 1rem;
		line-height: 1.15em;

		.sub-value {
			color: var(--gray-50);
			font-weight: normal;
		}
	}

	.header {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 1rem;

		.title {
			font-weight: bold;

			.clickable-label {
				cursor: pointer;
				font-size: var(--font-size-base);
				margin-bottom: 0;
			}
		}
	}

	&.active {
		background: var(--white);

		.header {
			border-bottom: 1px solid var(--gray-15);
		}
	}

	&.filter,
	.filter {
		padding: 1rem;
	}
}

p {
	font-size: var(--font-size-sm);
	line-height: 1.5;
}
</style>
