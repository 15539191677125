import { $GQL } from '@GQL';

export async function resetCancelRate(account_id: string) {
	const mutation = `
        mutation ResetCancelRate($account_id: String!) {
            resetCancelRate(account_id: $account_id)
        }
  `;

	const result = await $GQL.request(mutation, { account_id });
	return result.resetCancelRate;
}
