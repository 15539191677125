import { $GQL } from '@GQL';
import type { Lead } from '@nextgenleads/lead-driver';
import type { SendPostRes } from '@nextgenleads/pingpost-driver';

export async function sendPostOnly(params: { integration_id: string; test_lead: Lead }): Promise<SendPostRes> {
	const mutation = `
        mutation SendPostOnly($params: SendPostOnlyReq!) {
            sendPostOnly(params: $params)
        }
    `;

	const result = await $GQL.request(mutation, { params });
	return result.sendPostOnly;
}
