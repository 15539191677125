import { $GQL } from '@GQL/index';

export async function syncLocalWithTwilio() {
	const mutation = `
        mutation SyncLocalWithTwilio {
            syncLocalWithTwilio
        }
    `;

	const result = await $GQL.request(mutation);
	return result.syncLocalWithTwilio;
}
