import { $GQL } from '@GQL/index';

export async function getAvailablePhoneNumbers(area_code?: string, capabilities?: string[]) {
	const query = `
        query GetAvailablePhoneNumbers($area_code: String, $capabilities: [String]){
            availablePhoneNumbers(area_code: $area_code, capabilities: $capabilities) {
            	name
				phone
				city
				state
				zip
				capabilities
            }
        }
    `;

	const result = await $GQL.request(query, { area_code, capabilities });
	return result.availablePhoneNumbers;
}
