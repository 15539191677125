import SourcesListView from '@/views/Sources/List/Page.vue';
import SourceRecordFormPage from '@/views/Sources/Record/Page.vue';
import SourceThrottleConfigListPage from '@/views/SourceThrottleConfigs/List/Page.vue';
import SourceThrottleConfigEditPage from '@/views/SourceThrottleConfigs/Record/Page.vue';
import ChannelsListView from '@/views/Channels/List/Page.vue';
import ChannelRecordFormPage from '@/views/Channels/Record/Page.vue';
import NetworksListView from '@/views/Networks/List/Page.vue';
import NetworkRecordFormPage from '@/views/Networks/Record/Page.vue';
import vendors_routes from './vendors';

export default [
	{
		path: 'sources',
		name: 'SourcesList',
		component: SourcesListView,
		meta: {
			parent_menu: 'Source Service',
			title: 'Sources',
			permissions: 'admin.sources',
		},
	},
	{
		path: 'sources/new',
		name: 'SourceCreate',
		component: SourceRecordFormPage,
		meta: {
			new: true,
			parent_menu: 'Source Service',
			title: 'New Source',
			permissions: 'admin.sources',
		},
	},
	{
		path: 'sources/:source_id',
		name: 'SourceEdit',
		component: SourceRecordFormPage,
		meta: {
			parent_menu: 'Source Service',
			title: 'Edit Source',
			permissions: 'admin.sources',
		},
	},
	{
		path: 'source-throttle-configs',
		name: 'SourceThrottleConfigsList',
		component: SourceThrottleConfigListPage,
		meta: {
			parent_menu: 'Source Service',
			title: 'Source Throttle Configs',
			permissions: 'admin.source_throttle_configs',
		},
	},
	{
		path: 'source-throttle-config/new',
		name: 'SourceThrottleConfigCreate',
		component: SourceThrottleConfigEditPage,
		meta: {
			new: true,
			parent_menu: 'Source Service',
			title: 'New Source Throttle Config',
			permissions: 'admin.source_throttle_config',
		},
	},
	{
		path: 'source-throttle-config/:source_throttle_config_id',
		name: 'SourceThrottleConfigEdit',
		component: SourceThrottleConfigEditPage,
		meta: {
			parent_menu: 'Source Service',
			title: 'Edit Source Throttle Config',
			permissions: 'admin.source_throttle_config',
		},
	},
	{
		path: 'channels',
		name: 'ChannelsList',
		component: ChannelsListView,
		meta: {
			title: 'Channels',
			parent_menu: 'Source Service',
		},
	},
	{
		path: 'channels/new',
		name: 'ChannelCreate',
		component: ChannelRecordFormPage,
		meta: {
			new: true,
			parent_menu: 'Source Service',
			title: 'Channels',
			permissions: 'admin.channels',
		},
	},
	{
		path: 'channels/:channel_id',
		name: 'ChannelEdit',
		component: ChannelRecordFormPage,
		meta: {
			parent_menu: 'Source Service',
			title: 'Edit Channel',
			permissions: 'admin.channels',
		},
	},
	{
		path: 'networks',
		name: 'NetworksList',
		component: NetworksListView,
		meta: {
			parent_menu: 'Source Service',
			title: 'Networks',
			permissions: 'admin.networks',
		},
	},
	{
		path: 'networks/new',
		name: 'CreateNetwork',
		component: NetworkRecordFormPage,
		meta: {
			new: true,
			parent_menu: 'Source Service',
			title: 'New Network',
			permissions: 'admin.networks',
		},
	},
	{
		path: 'networks/:network_id',
		name: 'UpdateNetwork',
		component: NetworkRecordFormPage,
		meta: {
			title: 'Edit Network',
			permissions: 'admin.networks',
		},
	},
	...vendors_routes,
];
