import { $GQL } from '@GQL';

/**
 * The data here is the following based on the Resolver in Marketplace
 * const zShipByDataRequest = z.object({
 * campaign_id: z.string().ulid(),
 * lead_id: z.string().optional(),
 * lead_override_data: z.record(z.string(), z.unknown()),
 * campaign_override_data: z.record(z.string(), z.unknown()),
 * purchase_override_data: z.record(z.string(), z.unknown()),
 * shipper: zShipper
 * });
 *
 * type ShipByDataRequest = z.infer<typeof zShipByDataRequest>;
 * @param data
 */
export async function shipByData(data: unknown): Promise {
	const mutation = `
        mutation ShipByData($data: JSONObject!) {
            shipByData(data: $data)
        }
    `;

	const result = await $GQL.request(mutation, { data });
	return result.shipByData;
}
