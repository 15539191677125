import { $GQL } from '@GQL/index';

export async function reportAccountManagerWorkstationOverview(params: { date_range: [Date, Date]; refresh?: boolean }) {
	const query = `
        query ReportAccountManagerWorkstationOverview($params: JSONObject!) {
            reportAccountManagerWorkstationOverview(params: $params) {
            	row_count
            	rows
            }
        }
    `;

	const results = await $GQL.request(query, { params });
	return results.reportAccountManagerWorkstationOverview;
}
