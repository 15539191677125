export default function (current_value: number, compare_value: number, accuracy = 0.05, invert = false) {
	let class_name = 'trending-neutral';
	const value = current_value / (compare_value || 0);

	if (value > 1 + accuracy) {
		class_name = 'trending-up';
	} else if (value < 1 - accuracy) {
		class_name = 'trending-down';
	}
	if (invert && class_name !== 'neutral') {
		if (class_name === 'trending-up') {
			class_name = 'trending-down';
		} else {
			class_name = 'trending-up';
		}
	}
	return class_name;
}
