import { $GQL } from '@GQL/index';
import type { SourceThrottleConfig } from '@nextgenleads/source-driver';

export async function updateSourceThrottleConfig(
	id: string,
	data: Partial<SourceThrottleConfig>
): Promise<{ id: string }> {
	const mutation = `
        mutation UpdateSourceThrottleConfig($id: ID!, $data: JSONObject!) {
            updateSourceThrottleConfig(id: $id, data: $data) {
                id
            }
        }
    `;

	const result = await $GQL.request(mutation, { id, data });
	return result.updateSourceThrottleConfig;
}
