<template>
	<div class="build-step check-dnc">
		<div class="title">
			<div class="move-handle">
				<icon type="dots-vertical" size="24px" />
			</div>
			<div class="name">Check DNC</div>
			<delete-action
				text
				rounded
				icon="close"
				message="Are you sure you want to remove this build step?"
				tooltip="Remove Build Step"
				@delete="removeBuildStep"
			/>
		</div>
		<div class="description">
			Flag or reject a lead if the phone number appears as a hard DNC or is on the soft DNC list within the past x
			number of days.
		</div>
		<div class="step-settings">
			<h3>Settings</h3>
			<div class="control-group">
				<div class="inner">
					<div class="controls gap-20">
						<div class="field">
							<label class="control-label">Lookback:</label>
							<div class="p-inputgroup">
								<p-input-number v-model="modelValue.lookback" />
								<div class="p-inputgroup-addon">days</div>
							</div>
						</div>
						<div class="field">
							<label class="control-label">Action:</label>
							<p-dropdown
								v-model="modelValue.action"
								:options="action_options"
								option-label="label"
								option-value="value"
								placeholder="Select Action"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import deleteAction from '@/components/widgets/DeleteAction.vue';

export default {
	name: 'CheckDNC',
	emits: ['remove'],
	components: {
		deleteAction,
	},
	props: {
		modelValue: {
			type: Object,
			default: {
				lookback: 90,
				action: 'flag',
			},
		},
	},
	data() {
		return {
			action_options: [
				{ label: 'Flag', value: 'flag' },
				{ label: 'Reject', value: 'reject' },
			],
		};
	},
	methods: {
		removeBuildStep() {
			this.$emit('remove');
		},
	},
};
</script>
